import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: boolean;
    token: any;
    open: boolean;
    selectedItemData: any;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class EducationCenterIntermediateWebController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    getSelectedTopicDetailsApiCallId: string = '';
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            loading: false,
            token: localStorage.getItem('token') || "",
            open: false,
            selectedItemData: null
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {

        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.getSelectedTopicDetailsApiCallId) {
                this.setState({ loading: false });
                if (responseJson) {
                    this.setState({
                        selectedItemData: responseJson.data
                    })
                }
            }
        }        
        // Customizable Area End
    }

    async componentDidMount() {
        // Customizable Area Start
        this.getSelectedTopicDetails();
        // Customizable Area End
    }


    // Customizable Area Start

    getSelectedTopicDetails = () => {
        this.setState({ loading: true });
        let topicId = window.location.search.split("?topicId=")[1];
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getSelectedTopicDetailsApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_categories/education_centers/" + topicId);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    // Customizable Area End
}

import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import { withStyles, WithStyles, StyleRules } from "@material-ui/core/styles";

interface MyComponentProps extends WithStyles<typeof styles> {}

const styles: StyleRules = {
    loginDialog: {
        paddingBottom: '16px'
    },
    dialogTitle: {
        textAlign: 'center', 
        color: 'red', 
        fontWeight: 'bold'
    },
    homeButton: {
        backgroundColor: 'rgba(1,162,78,255)',
        color: '#ffffff',
        cursor: 'pointer',
        padding: '8px 20px',
        margin: '0px 10px',
        whiteSpace: 'nowrap',
        border: 'none',
        borderRadius: '10px',
        textAlign: 'center',
    },
    loginButton: {
        backgroundColor: 'rgba(1,162,78,255)',
        color: '#ffffff',
        cursor: 'pointer',
        padding: '8px 20px',
        margin: '0px 10px',
        border: 'none',
        borderRadius: '10px',
        textAlign: 'center'    
    },
    dialogActions: {
        justifyContent: 'center', 
        marginBottom: 16
    }
}

const LoginAlertDialogWeb:React.FC<MyComponentProps>  = ({ classes }) => {
    return (
        <Dialog style={{ minHeight: "185px" }} data-testid="close-dialog" open={!localStorage.getItem("token")} aria-labelledby="delete-confirmation-dialog">
            <DialogTitle className={classes.dialogTitle}>
                Login Alert
            </DialogTitle>
            <DialogContent style={{ textAlign: 'center', maxWidth:300, paddingTop: 0 }}>
                <Typography>In order to access this page you have to log in.</Typography>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <button 
                className={classes.homeButton} 
                style={{ background: localStorage.getItem('appTheme') == 'darkTheme' ? '#363333' : '#181a19' }} 
                onClick={(e: any) => { 
                    if (window.location.pathname === '/ScreenerWeb'){
                        window.location.href = '/ScreenerWeb'; 
                    } else {
                        window.location.href = '/';
                    }
                }}
                >
                    {window.location.pathname === '/ScreenerWeb' ? 'Back To Screener' :'Go To Home'}
                </button>
                <button className={classes.loginButton} onClick={(e: any) => {
                        window.location.href = '/EmailAccountLoginWeb';
                }}>Login</button>
            </DialogActions>
        </Dialog>
    )
}

export default  withStyles(styles)(LoginAlertDialogWeb);
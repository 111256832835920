import React from "react";
// Customizable Area Start
import PortfolioWebController, {
    Props
} from "./PortfolioController.web";

import {
    withStyles, StyleRules
} from "@material-ui/core/styles";

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import AddIcon from '@material-ui/icons/Add';
import ExploreIcon from '@material-ui/icons/Explore';
import FormatBoldIcon from '@material-ui/icons/FormatBold';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from '@material-ui/core/TextField';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import InputAdornment from '@material-ui/core/InputAdornment';

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import { Line } from 'react-chartjs-2';

import Loader from "../../../components/src/Loader.web";
import LoginAlertDialogWeb from "../../../components/src/LoginAlertDialog.web";
import { removePlusMinusSymbol } from "../../../components/src/chartUtils.web";
// Customizable Area End

const styles: StyleRules = {
    // Customizable Area Start
    root: {
        overflowX: 'hidden',
        // marginRight: '3px',
        padding: '18px',
        height: '100vh'
    },
    container: {
        // backgroundColor: '#ffffff',
        borderRadius: '8px',
        padding: '22px'
    },
    headerButton: {
        margin: '5px 0px 0px 12px',
        borderRadius: '8px',
        textTransform: "none",
        whiteSpace: 'nowrap'
    },
    removeBorder: {
        '& .MuiTableCell-root': {
            borderBottom: 'none'
        }
    },
    fullWidth: {
        marginBottom: '15px',
        '& .MuiFormControl-fullWidth': {
            width: '100%'
        },
        '& .MuiSelect-selectMenu': {
            textAlign: 'left'
        }
    },
    portfolioButtons: {
        backgroundColor: 'rgba(1,162,78,255)',
        color: '#ffffff',
        cursor: 'pointer',
        padding: '15px 38px',
        border: 'none',
        borderRadius: '10px',
        textAlign: 'center',
        margin: '15px 0px',
        fontWeight: 'bold'
    }
    // Customizable Area End
}
// Customizable Area Start
const dipSchema = Yup.object().shape({
    dip_number: Yup.string()
        .required('Trigger percentage is required.'),
    crypto_id: Yup.string()
        .required('Asset name is required.'),
});

const addNewAssetSchema = Yup.object().shape({
    crypto_id: Yup.string()
        .required('Asset name is required.'),
    holding_qty: Yup.string()
        .required('Holding quantity is required.'),
    buy_price: Yup.string()
        .required('Average buy price is required.'),
});
// Customizable Area End
export class PortfolioWeb extends PortfolioWebController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    customDipNumberField = (values: any, handleBlur: any, handleChange: any) => {
        const { classes } = this.props;
        return (
            <Field
                validateOnBlur
                validateOnChange
                name="custom_dip_number"
                render={({ field, form }: any) => (
                    <TextField
                        label={"Enter trigger % here"}
                        name={"custom_dip_number"}
                        variant="filled"
                        type="number"
                        value={values.custom_dip_number}
                        fullWidth
                        InputProps={{ disableUnderline: true }}
                        error={
                            Boolean(form.errors.custom_dip_number && form.touched.custom_dip_number)
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={
                            form.errors.custom_dip_number &&
                            form.touched.custom_dip_number &&
                            String(form.errors.custom_dip_number)
                        }
                    />
                )}
            />
        )
    }

    addAssetDialog = () => {
        const { classes } = this.props;
        return (
            <Dialog
                open={this.state.addNewAsset}
                onClose={() => this.closeNewAsset()}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="customized-dialog-title" style={{ padding: '16px 24px 0px' }}>
                    <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '20px', textAlign: 'center' }}>
                        {this.state.selectedAsset == null ? `Add New Asset` : `Edit Asset`}
                    </Typography>
                </DialogTitle>
                <DialogContent
                    style={{
                        maxWidth: "700px",
                        width: "300px",
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <div>
                        <Formik
                            initialValues={{
                                crypto_id: this.state.selectedAsset?.attributes?.crypto_id || '', holding_qty: this.state.selectedAsset?.attributes?.holding_qty || 0,
                                buy_price: this.state.selectedAsset?.attributes?.buy_price || 0, crypto_type: this.state.selectedAsset?.attributes?.crypto_type || 'BTC',
                                dip_alert: this.state.selectedAsset?.attributes?.set_dip_alert?.data?.attributes?.id,
                                custom_dip_number: 0
                            }}
                            data-testid="addAssetForm"
                            enableReinitialize
                            validationSchema={addNewAssetSchema}
                            onSubmit={(values: any, { resetForm, setSubmitting, setStatus }) => {
                                this.addOrUpdateAsset(values);
                                Object.keys(values).forEach(key => (values[key] = ""));
                                resetForm({ values: values });
                                setStatus(true);
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                setFieldValue,
                            }) => (
                                <Form onSubmit={handleSubmit}>
                                    <div className={classes.fullWidth}>
                                        <Field
                                            validateOnBlur
                                            validateOnChange
                                            name="crypto_id"
                                            render={({ field, form }: any) => (
                                                <TextField
                                                    id="crypto_id"
                                                    select
                                                    label="Asset Name"
                                                    value={values.crypto_id}
                                                    onChange={(event) => {
                                                        setFieldValue("crypto_id", event.target.value);
                                                        const cryptoObj = this.state.addAssetsList.find((item: any) => item.id == event.target?.value);
                                                        //setFieldValue('buy_price', cryptoObj.price);
                                                        this.setState({
                                                            symbolType: cryptoObj.symbol
                                                        })
                                                    }}
                                                    name={"crypto_id"}
                                                    margin="normal"
                                                    fullWidth
                                                    variant="filled"
                                                    InputProps={{ disableUnderline: true }}
                                                    InputLabelProps={{ style: { color: '#8f92a1' } }}
                                                    disabled={this.state.selectedAsset == null ? false : true}
                                                    error={
                                                        Boolean(form.errors.crypto_id && form.touched.crypto_id)
                                                    }
                                                    helperText={
                                                        form.errors.crypto_id && form.touched.crypto_id && String(form.errors.crypto_id)
                                                    }
                                                >
                                                    {this.state.addAssetsList.map((asset: any) => (
                                                        <MenuItem key={asset.id} value={asset.id}>
                                                            {asset.name}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            )}
                                        />
                                    </div>
                                    <div className={classes.fullWidth}>
                                        <Field
                                            validateOnBlur
                                            validateOnChange
                                            name="buy_price"
                                            render={({ field, form }: any) => (
                                                <TextField
                                                    label={"Enter Buying Price"}
                                                    name={"buy_price"}
                                                    variant="filled"
                                                    value={values.buy_price}
                                                    type="text"
                                                    // disabled
                                                    fullWidth
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        startAdornment: <InputAdornment position="start">
                                                            {`$`}
                                                        </InputAdornment>
                                                    }}
                                                    InputLabelProps={{ style: { color: '#8f92a1' } }}
                                                    error={
                                                        Boolean(form.errors.buy_price && form.touched.buy_price)
                                                    }
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={
                                                        form.errors.buy_price &&
                                                        form.touched.buy_price &&
                                                        String(form.errors.buy_price)
                                                    }
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className={classes.fullWidth}>
                                        <Field
                                            validateOnBlur
                                            validateOnChange
                                            name=""
                                            render={({ field, form }: any) => (
                                                <TextField
                                                    label={"Enter Holding Quantity"}
                                                    name={"holding_qty"}
                                                    variant="filled"
                                                    type="number"
                                                    value={values.holding_qty}
                                                    fullWidth
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        endAdornment: <InputAdornment position="end">
                                                            {this.state.symbolType}
                                                        </InputAdornment>
                                                    }}
                                                    InputLabelProps={{ style: { color: '#8f92a1' } }}
                                                    error={
                                                        Boolean(form.errors.holding_qty && form.touched.holding_qty)
                                                    }
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    helperText={
                                                        form.errors.holding_qty &&
                                                        form.touched.holding_qty &&
                                                        String(form.errors.holding_qty)
                                                    }
                                                />
                                            )}
                                        />
                                    </div>
                                    {this.state.selectedAsset != null && values.dip_alert != 0 && <div className={classes.fullWidth}>
                                        <Field
                                            validateOnBlur
                                            validateOnChange
                                            name="dip_alert"
                                            render={({ field, form }: any) => (
                                                <TextField
                                                    id="dip_alert"
                                                    select
                                                    label="Dip Alert"
                                                    value={values.dip_alert}
                                                    onChange={(event) => {
                                                        setFieldValue("dip_alert", event.target.value);
                                                    }}
                                                    name={"dip_alert"}
                                                    margin="normal"
                                                    fullWidth
                                                    variant="filled"
                                                    InputProps={{ disableUnderline: true }}
                                                    error={
                                                        Boolean(form.errors.dip_alert && form.touched.dip_alert)
                                                    }
                                                    helperText={
                                                        form.errors.dip_alert &&
                                                        form.touched.dip_alert &&
                                                        String(form.errors.dip_alert)
                                                    }
                                                >
                                                    {this.state.selectedAsset?.attributes?.dip_alert_list?.data.map((alert: any) => (
                                                        <MenuItem key={alert.id} value={alert?.attributes?.id}>
                                                            {`${alert?.attributes?.dip_number} %`}
                                                        </MenuItem>
                                                    ))}
                                                    <MenuItem key={0} value={0}>
                                                        {`Custom`}
                                                    </MenuItem>
                                                </TextField>
                                            )}
                                        />
                                    </div>}
                                    {this.state.selectedAsset != null && values.dip_alert == 0 &&
                                        <div className={classes.fullWidth}>
                                            {this.customDipNumberField(values, handleBlur, handleChange)}
                                        </div>
                                    }
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                        <button type="button" className={classes.portfolioButtons} style={{ background: '#181a19', marginRight: '10px' }} onClick={(e: any) => this.closeNewAsset()}>Cancel</button>
                                        <button type="submit" className={classes.portfolioButtons}>Save</button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </DialogContent>
            </Dialog>)
    }

    dipAlertDialog = () => {
        const { classes } = this.props;
        return (
            <Dialog
                open={this.state.dipAlert}
                onClose={() => this.closeDipAlert()}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="customized-dialog-title" style={{ padding: '16px 24px 0px' }}>
                    <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '20px', textAlign: 'center' }}>
                        {`Dip Alert`}
                    </Typography>
                </DialogTitle>
                <DialogContent
                    style={{
                        maxWidth: "500px",
                        width: "500px",
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <div>
                        <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '14px', textAlign: 'justify', color: '#989ca2', width: '400px' }}>
                            {`You will receive an alert once the price of a particular asset drops by trigger % point`}
                        </Typography>
                        <Formik
                            initialValues={{
                                dip_number: 5,
                                custom_dip_number: 0,
                                crypto_id: 0,
                            }}
                            data-testid="dipAlertForm"
                            enableReinitialize
                            validationSchema={dipSchema}
                            onSubmit={(values: any, { resetForm, setSubmitting, setStatus }) => {
                                this.addOrUpdateDip(values);
                                Object.keys(values).forEach(key => (values[key] = ""));
                                resetForm({ values: values });
                                setStatus(true);
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                setFieldValue,
                            }) => (
                                <Form onSubmit={handleSubmit}>
                                    {values.dip_number != 0 && <div className={classes.fullWidth}>
                                        <Field
                                            validateOnBlur
                                            validateOnChange
                                            name="dip_number"
                                            render={({ field, form }: any) => (
                                                <TextField
                                                    id="dip_number"
                                                    select
                                                    label="Enter trigger % point"
                                                    value={values.dip_number}
                                                    onChange={handleChange("dip_number")}
                                                    name={"dip_number"}
                                                    margin="normal"
                                                    fullWidth
                                                    variant="filled"
                                                    InputProps={{ disableUnderline: true }}
                                                    error={
                                                        Boolean(form.errors.dip_number && form.touched.dip_number)
                                                    }
                                                    helperText={
                                                        form.errors.dip_number &&
                                                        form.touched.dip_number &&
                                                        String(form.errors.dip_number)
                                                    }
                                                >
                                                    {this.state.triggerPercentList.map((trigger: any, key: any) => (
                                                        <MenuItem key={key} value={trigger}>
                                                            {trigger == 0 ? 'Custom' : `${trigger} %`}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            )}
                                        />
                                    </div>}
                                    {values.dip_number == 0 &&
                                        <div className={classes.fullWidth}>
                                            {this.customDipNumberField(values, handleBlur, handleChange)}

                                        </div>
                                    }
                                    <div className={classes.fullWidth}>
                                        <Field
                                            validateOnBlur
                                            validateOnChange
                                            name="crypto_id"
                                            render={({ field, form }: any) => (
                                                <TextField
                                                    id="crypto_id"
                                                    select
                                                    label="Choose asset"
                                                    value={values.crypto_id}
                                                    onChange={(event) => {
                                                        setFieldValue("crypto_id", event.target.value);
                                                    }}
                                                    name={"crypto_id"}
                                                    margin="normal"
                                                    fullWidth
                                                    variant="filled"
                                                    InputProps={{ disableUnderline: true }}
                                                    error={
                                                        Boolean(form.errors.crypto_id && form.touched.crypto_id)
                                                    }
                                                    helperText={
                                                        form.errors.crypto_id &&
                                                        form.touched.crypto_id &&
                                                        String(form.errors.crypto_id)
                                                    }
                                                >
                                                    {this.state.addAssetsList.map((asset: any) => (
                                                        <MenuItem key={asset.id} value={asset.id}>
                                                            {asset.name}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            )}
                                        />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                        <button type="button" className={classes.portfolioButtons} style={{ background: '#181a19', marginRight: '10px' }} onClick={(e: any) => this.closeDipAlert()}>Cancel</button>
                                        <button type="submit" className={classes.portfolioButtons}>Set Alert</button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </DialogContent>
            </Dialog>)
    }

    actionMenu() {
        return (
            <Menu
                id="simple-menu"
                anchorEl={this.state.anchorEl}
                open={Boolean(this.state.anchorEl)}
                onClose={this.handleIconClose}
                className={this.props.classes.menu}
            >
                <MenuItem onClick={(event: any) => this.setState({ addNewAsset: true, anchorEl: null })}>
                    <ListItemIcon>
                        <EditIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit"
                        style={{
                            fontFamily: "Plus Jakarta Text",
                            fontSize: "12px",
                            color: "#0E0E0E",
                            textTransform: "capitalize",
                            letterSpacing: 0,
                        }}>
                        Edit
                    </Typography>
                </MenuItem>
                <MenuItem
                    onClick={(e) => {
                        this.deleteAssetDetails();
                    }}
                >
                    <ListItemIcon>
                        <DeleteOutlineIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit"
                        style={{
                            fontFamily: "Plus Jakarta Text",
                            fontSize: "12px",
                            color: "#0E0E0E",
                            textTransform: "capitalize",
                            letterSpacing: 0,
                        }}>
                        Delete
                    </Typography>
                </MenuItem>
            </Menu>
        )
    }

    noDataMessage = () => {
        const { loading, assestsList } = this.state;
        let message;
        if (!loading) {
            if (assestsList.length === 0) {
                message = "No Data Found";
            } else if (!assestsList[0].attributes?.crypto_details) {
                message = "Api is not responding. Please try again after sometime";
            }
        return (
            <TableRow>
                <TableCell align="center" colSpan={7}>
                    <Typography style={{ fontWeight: 'bold' }}>{message}</Typography>
                </TableCell>
            </TableRow>
        )
        }
    }

    assetsTable = () => {
        const { classes } = this.props;
        return (
            <div style={{ margin: '26px 0px' }}>
                <Typography variant="h5" style={{ fontWeight: 'bold', padding: '12px 0px' }}>
                    {`Assets`}
                </Typography>
                <TableContainer component={Paper} elevation={0}>
                    <Table aria-label="top-scans-one" size="small" className={classes.removeBorder} style={{ backgroundColor: localStorage.getItem('appTheme') == 'darkTheme' ? '#232429' : '#f4f8f6'}}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" style={{ fontSize: '13px', fontWeight: 'bold', whiteSpace: 'nowrap' }}>Name</TableCell>
                                <TableCell align="center" style={{ fontSize: '13px', fontWeight: 'bold', whiteSpace: 'nowrap' }}>Price</TableCell>
                                <TableCell align="center" style={{ fontSize: '13px', fontWeight: 'bold', whiteSpace: 'nowrap' }}>24H</TableCell>
                                <TableCell align="center" style={{ fontSize: '13px', fontWeight: 'bold', whiteSpace: 'nowrap' }}>Holding</TableCell>
                                <TableCell align="center" style={{ fontSize: '13px', fontWeight: 'bold', whiteSpace: 'nowrap', width: '100px' }}>Avg Buy Price</TableCell>
                                <TableCell align="left" style={{ fontSize: '13px', fontWeight: 'bold', whiteSpace: 'nowrap' }}>Profit/Loss</TableCell>
                                <TableCell align="left" style={{ fontSize: '13px', fontWeight: 'bold', whiteSpace: 'nowrap' }}>Dip Alert</TableCell>
                                <TableCell align="center" style={{ fontSize: '13px', fontWeight: 'bold', whiteSpace: 'nowrap' }}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.assestsList.map((row: any) => {
                                if (row.attributes?.crypto_details?.length > 0) {
                                    return (
                                        <TableRow key={row.id} data-testid={row.id} style={{ backgroundColor: this.state.selectedAssetListObj?.id == row.id ? localStorage.getItem('appTheme') == 'darkTheme' ? '#33373C' : '#f7f7f7' : localStorage.getItem('appTheme') == 'darkTheme' ? '#333742' : '#ffffff' }}
                                            onClick={(event: any) => {
                                                this.setState({
                                                    selectedAssetListObj: row
                                                }, () => {
                                                    this.getGraphData();
                                                });
                                            }}
                                        >
                                            <TableCell align="left">
                                                <CardHeader
                                                    avatar={
                                                        <Avatar
                                                            src={row.attributes.crypto_details[0].image_url}
                                                            alt="Remy Sharp"
                                                            style={{ width: '32px', height: '32px' }}
                                                        >
                                                        </Avatar>
                                                    }
                                                    title={
                                                        <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                            {row.attributes.crypto_details[0].name}
                                                        </Typography>
                                                    }
                                                    style={{ padding: '0px' }}
                                                />
                                            </TableCell>
                                            <TableCell align="center">
                                                <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                    {row.attributes.crypto_details[0].price}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="center">
                                                <div
                                                    style={{ display: 'flex', alignItems: 'center', marginLeft: '-5px' }}
                                                >
                                                    {row.attributes.crypto_details[0].percent_change_24h.charAt(0) === "-" ? <ArrowDropDownIcon style={{ color: '#f1a48a', fontSize: '28px', fontWeight: 'bold' }} /> : <ArrowDropUpIcon style={{ color: '#51bf86', fontSize: '28px', fontWeight: 'bold' }} />}
                                                    <Typography
                                                        gutterBottom
                                                        variant="body2"
                                                        component="div"
                                                        style={{ color: row.attributes.crypto_details[0].percent_change_24h.charAt(0) === "+" ? '#51bf86' : '#f1a48a', fontWeight: 'bold' }}
                                                    >
                                                    {removePlusMinusSymbol(row.attributes.crypto_details[0].percent_change_24h)}
                                                    </Typography>
                                                </div>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                    {`${row.attributes?.holding_qty} (${row.attributes.crypto_details[0].symbol})`}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                    {`${row.attributes?.buy_price}`}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="left">
                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                                    <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '14px', marginLeft: '9px' }}>
                                                        {Number((Number(row.attributes.crypto_details[0].price.replace("$", "").replace(",", "")) - Number(row.attributes?.buy_price.replace("$", "").replace(",", ""))) * Number(row?.attributes?.holding_qty)).toFixed(2)}
                                                    </Typography>
                                                    <div style={{ display: 'flex', alignItems: "center", justifyContent: 'center', whiteSpace: 'nowrap' }}>
                                                        {Number((Number(row.attributes.crypto_details[0].price.replace("$", "").replace(",", "")) - Number(row.attributes?.buy_price.replace("$", "").replace(",", ""))) * Number(row?.attributes?.holding_qty) / 100).toFixed(2).charAt(0) === "-" ? <ArrowDropDownIcon style={{ color: '#f1a48a', fontSize: '28px', fontWeight: 'bold' }} /> : <ArrowDropUpIcon style={{ color: '#51bf86', fontSize: '28px', fontWeight: 'bold' }} />}
                                                        <Typography
                                                            gutterBottom
                                                            variant="body2"
                                                            component="div"
                                                            style={{ color: Number((Number(row.attributes.crypto_details[0].price.replace("$", "").replace(",", "")) - Number(row.attributes?.buy_price.replace("$", "").replace(",", ""))) * Number(row?.attributes?.holding_qty) / 100).toFixed(2).charAt(0) === "-" ? '#f1a48a' : '#51bf86', fontWeight: 'bold' }}
                                                        >
                                                            {`${Number((Number(row.attributes.crypto_details[0].price.replace("$", "").replace(",", "")) - Number(row.attributes?.buy_price.replace("$", "").replace(",", ""))) * Number(row?.attributes?.holding_qty) / 100).toFixed(2)} %`}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                    {`${row.attributes?.set_dip_alert?.data?.attributes?.dip_number | 0} %`}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="center">
                                                <IconButton
                                                    onClick={(e) => {
                                                        this.handleIconClick(e, row);
                                                    }}
                                                >
                                                    <MoreVertIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>)
                                } 
                            })}
                           { this.noDataMessage()}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        )
    }
    
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { buttonGroupOne, buttonGroupTwo } = this.state;
        const backgroundval = localStorage.getItem('appTheme') == 'darkTheme' ? '#232429' : '#f4f8f6';
        const buttonGroupStyle: any = { textTransform: "none", backgroundColor: localStorage.getItem('appTheme') == 'darkTheme' ? '#484848' : '#e0e0e0', border: 'none', color: localStorage.getItem('appTheme') == 'darkTheme' ? '#ffffff' : '#000000' };
        const buttonGroupSelectedStyle: any = { textTransform: "none", backgroundColor: '#ffffff', border: localStorage.getItem('appTheme') == 'darkTheme' ? '5px solid #484848' : '5px solid #e0e0e0' };

        return (
            <Paper className={classes.root} data-testid='portfolioPaper' elevation={0} square style={{ backgroundColor: backgroundval }}>
                {this.state.loading && <Loader loading={this.state.loading} />}
                {!this.state.token && <LoginAlertDialogWeb />}
                <Grid container direction="row" spacing={2}>
                    <Grid item xs={12} md={10} className={classes.container}>
                        <Paper elevation={3} style={{ padding: '10px 20px' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div style={{ display: 'column' }}>
                                    <Typography gutterBottom variant="body2" component="div" style={{ color: '#c0c4c3' }}>
                                        {`Current Balance`}
                                    </Typography>
                                    <Typography gutterBottom variant="body2" component="div" style={{ fontSize: '22px', fontWeight: 'bold' }}>
                                        {this.state.selectedAssetListObj && this.state.selectedAssetListObj.attributes?.crypto_details?.length && this.state.selectedAssetListObj.attributes.crypto_details[0]?.price}
                                    </Typography>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        {this.state.selectedAssetListObj && this.state.selectedAssetListObj.attributes?.crypto_details?.length > 0 &&
                                            <Typography gutterBottom variant="body2" component="div" style={{ color: this.state.selectedAssetListObj.attributes.crypto_details[0]?.percent_change_24h.charAt(0) === "+" ? '#51bf86' : '#f1a48a', fontWeight: 'bold' }}>
                                                {removePlusMinusSymbol(this.state.selectedAssetListObj.attributes.crypto_details[0]?.percent_change_24h)}
                                            </Typography>}
                                        <span style={{ marginLeft: '20px' }}></span>
                                        <Typography gutterBottom variant="body2" component="div" style={{ color: '#c0c4c3', fontWeight: 'bold' }}>
                                            {`24h`}
                                        </Typography>
                                    </div>
                                </div>
                                <div>
                                    <Button
                                        variant="contained"
                                        // color="primary"
                                        data-testid="dipAlert"
                                        className={classes.headerButton}
                                        style={{ backgroundColor: backgroundval, color: localStorage.getItem('appTheme') == 'darkTheme' ? '#ffffff' : '#252525' }}
                                        disableElevation
                                        onClick={(event: any) => this.setState({ dipAlert: true })}
                                    // disabled={this.state.assestsList.length == 0 ? true : false}
                                    >
                                        Dip Alert
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        data-testid="addNewAsset"
                                        className={classes.headerButton}
                                        startIcon={<AddIcon />}
                                        style={{ backgroundColor: '#499e5a' }}
                                        disableElevation
                                        onClick={(event: any) => this.setState({ addNewAsset: true })}
                                    >
                                        Add New
                                    </Button>
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '5px', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                <ButtonGroup data-testid="chartType" disableElevation variant="contained" style={{ marginTop: '10px', flexWrap: 'wrap' }} >
                                    <Button style={buttonGroupOne == 'chart' ? buttonGroupSelectedStyle : buttonGroupStyle} onClick={() => this.buttonGroupCategory('groupOne', 'chart')}>Chart</Button>
                                    <Button style={buttonGroupOne == 'allocation' ? buttonGroupSelectedStyle : buttonGroupStyle} onClick={() => this.buttonGroupCategory('groupOne', 'allocation')}>Allocation</Button>
                                    <Button style={buttonGroupOne == 'statistics' ? buttonGroupSelectedStyle : buttonGroupStyle} onClick={() => this.buttonGroupCategory('groupOne', 'statistics')}>Statistics</Button>
                                </ButtonGroup>
                                <ButtonGroup data-testid="timeFrame" disableElevation variant="contained" style={{ marginTop: '10px', flexWrap: 'wrap' }} >
                                    <Button style={buttonGroupTwo == '24h' ? buttonGroupSelectedStyle : buttonGroupStyle} onClick={() => this.buttonGroupCategory('groupTwo', '24h')}>24H</Button>
                                    <Button style={buttonGroupTwo == '7d' ? buttonGroupSelectedStyle : buttonGroupStyle} onClick={() => this.buttonGroupCategory('groupTwo', '7d')}>7D</Button>
                                    <Button style={buttonGroupTwo == '30d' ? buttonGroupSelectedStyle : buttonGroupStyle} onClick={() => this.buttonGroupCategory('groupTwo', '30d')}>30D</Button>
                                    <Button style={buttonGroupTwo == '90D' ? buttonGroupSelectedStyle : buttonGroupStyle} onClick={() => this.buttonGroupCategory('groupTwo', '90D')}>90D</Button>
                                    <Button style={buttonGroupTwo == 'all' ? buttonGroupSelectedStyle : buttonGroupStyle} onClick={() => this.buttonGroupCategory('groupTwo', 'all')}>ALL</Button>
                                </ButtonGroup>
                            </div>
                            <div style={{ marginTop: '45px' }}>
                                {this.state.lineChartData.labels.length > 0 && <Line options={this.state.lineOptions} data={this.state.lineChartData} redraw={true} style={{ maxHeight: '300px' }} />}
                            </div>
                            {this.assetsTable()}
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={2} className={classes.container} style={{ maxHeight: '85px', width: '220px', maxWidth: '400px' }}>
                        <Paper elevation={3}>
                            <CardHeader
                                avatar={
                                    <Avatar
                                        alt="Remy Sharp"
                                        style={{ width: '32px', height: '32px', backgroundColor: '#f78b27' }}
                                    >
                                        {``}
                                    </Avatar>
                                }
                                title={
                                    <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                                        {`My Main Portfolio`}
                                    </Typography>
                                }
                                subheader={`${this.state.avgSum} $`}
                                style={{ padding: '8px 12px' }}
                            />
                        </Paper>
                    </Grid>
                </Grid>
                {this.state.dipAlert && this.dipAlertDialog()}
                {this.state.addNewAsset && this.addAssetDialog()}
                {this.actionMenu()}
            </Paper>
        )
        // Customizable Area End
    }
}
export default withStyles(styles)(PortfolioWeb);

export const activeDerivatives = require("../assets/activeDerivatives.png");
export const activeFunda = require("../assets/activeFunda.png");
export const activeIndicators = require("../assets/activeIndicators.png");
export const activePriceAction = require("../assets/activePriceAction.png");
export const activeVolume = require("../assets/activeVolume.png");
export const derivatives = require("../assets/derivatives.png");
export const funda = require("../assets/funda.png");
export const indicators = require("../assets/indicators.png");
export const priceAction = require("../assets/priceAction.png");
export const volume = require("../assets/volume.png");
export const Delete = require("../assets/delete.png");
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");

// Customizable Area Start
import React from "react";
import {
  ActivityIndicator,
  Image,
  ImageBackground,
  Platform,
  ScrollView,
  StatusBar,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import {
  heightPercentageToDP,
  widthPercentageToDP
} from "react-native-responsive-screen";
import SubHeader from "../../../components/src/SubHeader";
import { theme } from "../../../framework/src/theme";
import { bellIcon, circleIcon, crossIcon } from "./assets";

import PushnotificationsController, {
  Props,
} from "./PushnotificationsController";
import HelperClass from "../../../components/src/HelperClass";
import Scale from "../../../components/src/Scale";
import moment from "moment";
// Customizable Area End

export default class Pushnotifications extends PushnotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderNotificationRow = (notification: any) => {
    return (
      <TouchableOpacity
        testID={'notificationItem'}
        activeOpacity={0.7}
        key={notification.id}
        // onPress={() => this.handleRedirection(notification)}
      >
        <View style={[styles.notificationRowContainer,{ backgroundColor: HelperClass.getDarkMode() === false ? 
            theme.light.headeBg : theme.dark.headeBg}]}>
          <View style={styles.notificationRowHeaderContainer}>
            <View style={styles.notificationRowTitleContainer}>
              <Image style={styles.bellIcon} source={bellIcon} />
              <Text style={{marginLeft: 11,
    fontSize: 16,
    
    maxWidth:Scale(200),
    color: HelperClass.getDarkMode() === false ? 
            "#000": "#FFF"
    }}>
              {notification.attributes.created_at
                ? notification.attributes.headings
                : "N/A"}
            </Text>
            </View>
            <Text style={[styles.notificationDate,{color: HelperClass.getDarkMode() === false ? 
            "#000": "#FFF",maxWidth:Scale(70),}]}>
              {notification.attributes.created_at
                ? moment(notification.attributes.created_at).format("HH:mm:ss/DD-MM-YYYY")
                : "N/A"}
            </Text>
          </View>
          <View style={styles.notificationRowBodyContainer}>
            <Text style={[styles.notificationBody,{color: HelperClass.getDarkMode() === false ? 
            "#000" : "#FFF"}]}>
              {
                notification.attributes?.contents ?? "N/A"
              }
            </Text>
          </View>
        </View>
      </TouchableOpacity>
    );
  };

  renderNoNotification() {
    return (
      <View style={styles.noNotificationContainer}>
        <ImageBackground
          style={styles.noNotificationCircleContainer}
          source={circleIcon}
        >
          <TouchableOpacity onPress={()=>this.props.navigation.goBack()}>
          <Image style={styles.noNotificationCrossIcon} source={crossIcon} />
          </TouchableOpacity>
        </ImageBackground>
        <Text style={[styles.noNotificationSorryText,{color:this.state.darkMode === false ? "black" : "green"}]}>Sorry!</Text>
        <Text style={[styles.noNotificationText,{color:this.state.darkMode === false ? "black" : "green"}]}>No Notifications</Text>
      </View>
    );
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    // alert(this.context.mode+"modemodemode")
    const { loading, notifications } = this.state;
    console.log("[Pushnotifications::render] notifications=", notifications);
    if (loading) {
      return (
        <View style={styles.activityIndicatorContainer}>
          <ActivityIndicator size={50} color="#7266d0" />
        </View>
      );
    }

    return (
      //Merge Engine DefaultContainer
      <View style={[styles.screenContainer,{backgroundColor: this.state.darkMode === false ? theme.light.headeBg : theme.dark.headeBg}]}>
        <StatusBar
          barStyle='dark-content' backgroundColor={this.state.darkMode === false ? theme.light.headeBg : theme.dark.headeBg}
        />
        {/* @ts-ignore */}
         <SubHeader
                title={"Notifications"}
                onPress={() => this.props.navigation.goBack()}
              
            />
        {notifications && notifications.length > 0 ? (
          <ScrollView
            keyboardShouldPersistTaps="always"
            contentContainerStyle={[styles.container,{backgroundColor: HelperClass.getDarkMode() === false ? 
            theme.light.headeBg : theme.dark.headeBg}]}
            style={{}}
          >
            {/* <View style={{width:"100%",backgroundColor: this.context.mode === 'light' ? 
            theme.light.headeBg : theme.dark.headeBg}} > */}
            {notifications.map((notification) =>
              this.renderNotificationRow(notification)
            )}
            {/* </View> */}
           
          </ScrollView>
        ) : (
          this.renderNoNotification()
        )}
      </View>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  screenContainer: {
    flex: 1,
    backgroundColor: "#F7F7F7",
  },
  activityIndicatorContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#ffffff",
  },
  container: {
    flexGrow: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    // backgroundColor: "#ffffffff",
  },
  notificationRowContainer: {
    marginBottom: 15,
    borderRadius: 15,
    borderWidth: 1,
    paddingLeft: 16,
    paddingRight: 13,
    paddingVertical: 10,
    minHeight: 80,
    borderColor: "#e4e4e4",
  },
  notificationRowHeaderContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  notificationRowBodyContainer: {
    paddingRight: 30,
    marginTop: 6,
  },
  notificationRowTitleContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  bellIcon: {
    width: 14,
    height: 14,
  },
  notificationTitle: {
    marginLeft: 11,
    fontSize: 12,
    color: "#000000",
    fontWeight: "700"
  },
  notificationDate: {
    marginLeft: 11,
    fontSize: 12,
  
    opacity: 0.3
  },
  notificationBody: {
    lineHeight: 20,
    fontSize: 12,
   
    opacity: 0.7,
  },
  noNotificationContainer: {
    height: heightPercentageToDP("80%"),
    justifyContent: "center",
    alignItems: "center",
  },
  noNotificationCircleContainer: {
    height: 72,
    width: 72,
    justifyContent: "center",
    alignItems: "center",
  },
  noNotificationCrossIcon: {
    width: 25,
    height: 25,
    
  },
  noNotificationSorryText: {
    marginTop: 12,
    fontSize: 16,
    color: "#2f2a2b",
    fontWeight: "700"
  },
  noNotificationText: {
    marginTop: 6,
    fontSize: 12,
    color: "#2f2a2b",
  },
  continueShoppingText: {
    fontSize: 14,
  },
  continueShoppingBtnContainer: {
    width: widthPercentageToDP("90%"),
    marginTop: 18,
    padding: 0,
    justifyContent: "center",
    alignItems: "center",
  },
});
// Customizable Area End

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  status: string;
  updateStatus: string;
  enable: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class DisableAdsController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  apiDisableAdCallId: string = "";
  apiupdateDisableAdCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      status: "",
      updateStatus: "",
      enable: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.apiDisableAdCallId) {
        if(responseJson) { 
          const adsStatus = responseJson.data[0].attributes.disable_ads;
          await setStorageData('DisableAds', JSON.stringify(adsStatus))
          this.setState({ 
            status: adsStatus ? "Your Paid Member. Ads will not be displayed to you." :
             "Your not Paid Member. Ads will be displayed to you.",
            enable: adsStatus,
          });
        }
        if(errorReponse) { 
          this.setState({ 
            status: "Try again later",             
          }) 
        }
      }
      if (apiRequestCallId === this.apiupdateDisableAdCallId) {
        if(responseJson) { 
          const adsStatus = responseJson.data.attributes.disable_ads;
          this.setState({ 
            updateStatus: "Your subscription has been updated successfully",
            enable: adsStatus,
          }, () => {
            this.showAlert(
              "Membership status update",
              this.state.updateStatus,
            );
          })
          this.checkAdDisable();
        }
        if(errorReponse) { 
          this.setState({ 
            status: "Try again later",             
          }) 
        }
      }
    }
    // Customizable Area End

  }

  // Customizable Area Start
  async componentDidMount() {
    this.checkAdDisable()
  }

  checkAdDisable = async () => {
    this.setState({ status: "It will take few seconds. Please wait" })
    const userToken = await getStorageData('userToken');
    const userId = await getStorageData('userId');
    const header = {
      "Content-Type": configJSON.adDisableApiContentType,
      token: userToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDisableAdCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.adsDisableAPiEndMethod + userId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.adsDisableAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  updateAdsStatus = async () => {
    const userToken = await getStorageData('userToken');
    const userId = await getStorageData('userId');
    const header = {
      "Content-Type": configJSON.updateAdsDisableApiContentType,
      token: userToken,
    };

    const httpBody = {
      "disable_ads" : !this.state.enable
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiupdateDisableAdCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateAdsDisableAPiEndMethod  + userId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateAdsDisableAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  // Go to Home page 
  goToDisableAdHomeScreen = () => {
    const goToHomeMessage: Message = new Message(
      getName(MessageEnum.NavigationDisableAdHomeScreenMessage)
    );
    goToHomeMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(goToHomeMessage);
  };
  // Customizable Area End
  
}

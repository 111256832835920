Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.apiMethodTypePost = "POST";
exports.apiMethodTypeDelete = "DELETE";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.brandAPiEndPoint = "catalogue/brands";
exports.saveFiltersApiEndPoint = "/bx_block_filter_items/saved_filters";
exports.saveMultipleFilterEndPoint = "/bx_block_filter_items/multi_saved_filters"
exports.filterbrandAPiEndPoint = "filter_items/filtering?q[brand_id]=";
exports.singleCategoryAPiEndPoint = "filter_items/filtering?q[category_id]=";
exports.cataloguesAPiEndPoint = "filter_items/filtering?";
exports.selectOptionTxt = "Select Volume Option";
exports.multiSaveFilterApi = "/bx_block_filter_items/multi_saved_filters/apply_filters"
exports.SaveBtn = "Apply";
exports.SaveRunBtn = "Save & Apply";
exports.FiltersTxt = "Filters";

//Indicator
exports.simpleMovingArr = ["Price Above Simple Moving Average",
  "Price Below Simple Moving Average",
  "Price Crossed Above Simple Moving Average",
  "Price Crossed Below Simple Moving Average",
  "Price Bounced Up From Simple Moving Average",
  "Price Bounced Down From Simple Moving Average",
  "2 Moving Average Crossovers"]
exports.exponentialMovingArr = ["Price Above Exponential Moving Average",
  "Price Below Exponential Moving Average",
  "Price Crossed Above Exponential Moving Average",
  "Price Crossed Below Exponential Moving Average",
  "Price Bounced Up From Exponential Moving Average",
  "Price Bounced Down From Exponential Moving Average",
  "2 Moving Average Crossovers"]
//Fundamental
exports.RankApiEndPoint="/bx_block_dashboard/get_ranks?limit=";
exports.getMultipleSavedFilter="/bx_block_filter_items/multi_saved_filters"
exports.CategoriesApiEndPoint="/bx_block_dashboard/category_wise_crypto?crypto_category_id=";
exports.GetCatApiEndPoint="/bx_block_dashboard/categories_list";
exports.SocialEngamentApiEndPoint="/bx_block_dashboard/social_engagement_list";
exports.SocialEngBySymbolApiEndPoint="/bx_block_dashboard/social_engagement_list?symbol="
exports.MarketCapApiEndPoint="/bx_block_dashboard/market_cap?";
//Price api
exports.priceUpApiEndPoint=(name,page,frameValue,labelName,parcentageValue)=>`/bx_block_dashboard/gain_loss/${name}?page=${page}&time_frame=${frameValue}&${labelName}=${parcentageValue}`;

exports.highLowNearHigh=(name,page)=>`/bx_block_dashboard/high_low/${name}?page=${page}`;
exports.breakOutDownApiEnd=(name,page)=>`/bx_block_dashboard/breakout_down_all?time_period=${name}&limit=10&start=${page}`;
exports.supportResistance=(name,page,option,time_frame)=>`/bx_block_dashboard/support_resistance/${name}?limit=10&page=${page}&option=${option}&time_frame=${time_frame}`;
exports.getIndicatorAPi=(indicatorResultpage)=>`/bx_block_dashboard/indicators/dummy?page=${indicatorResultpage}`

//indicatorAPi

exports.getMovingApi=(indicatorResultpage,mvgPeriod,Mavg,option,mvgTimeFrame)=>`/bx_block_dashboard/indicators/moving_average?page=${indicatorResultpage}&period=${mvgPeriod}&moving_average=${Mavg}&option=${option}&time_frame=${mvgTimeFrame}`
exports.getRSIAPi=(indicatorResultpage,UrlIndicatorPath,timeFameIndLabel,periodInd,Fnamedata,lengthInd)=>`/bx_block_dashboard/indicators/${UrlIndicatorPath.toLowerCase()}?page=${indicatorResultpage}&time_frame=${timeFameIndLabel}&period=${periodInd}&option=${Fnamedata}&level=${lengthInd}`
exports.getCCIAPI = (indicatorResultpage, cciTimeFrame, ccilabel, cciPeriod) => {
  let apiEndpoint = `/bx_block_dashboard/indicators/cci?page=${indicatorResultpage}&time_frame=${cciTimeFrame}&option=${ccilabel}`;
  if (cciPeriod) {
    apiEndpoint += `&period=${cciPeriod}`;
  }
  return apiEndpoint;
};
exports.getMacdAPI = (indicatorResultpage, macdTimeFrame, option, macdPeriod) => {
  let apiEndpoint = `/bx_block_dashboard/indicators/macd?page=${indicatorResultpage}&time_frame=${macdTimeFrame}&option=${option}`;
  if (macdPeriod) {
    apiEndpoint += `&period=${macdPeriod}`;
  }
  return apiEndpoint;
};
exports.getAdx = (indicatorResultpage, adxtimeframe, adxlableoption, adxPeriod, adxLevel) => {
  let apiEndpoint = `/bx_block_dashboard/indicators/adx?page=${indicatorResultpage}&time_frame=${adxtimeframe}&option=${adxlableoption}&period=${adxPeriod}`;
  if (adxLevel) {
    apiEndpoint += `&level=${adxLevel}`;
  }
  return apiEndpoint;
};

exports.getObv=(indicatorResultpage ,ObvtimeFrame,obvlableOption,periodObv)=>`/bx_block_dashboard/indicators/obv?page=${indicatorResultpage}&time_frame=${ObvtimeFrame}&option=${obvlableOption}&trending_period=${periodObv}`
exports.getCmf=(indicatorResultpage,cmfTieFrame,cmflabelOption,cmfPeriod)=>{
  let apiEndPoint= `/bx_block_dashboard/indicators/cmf?page=${indicatorResultpage}&time_frame=${cmfTieFrame}&option=${cmflabelOption}`
  if(cmfPeriod){
    apiEndPoint +=`&period=${cmfPeriod}`
  }
  return apiEndPoint;
}
exports.getBollinger=(indicatorResultpage,bollingerTimeFrame,BollingerLabelOption)=>`/bx_block_dashboard/indicators/bollinger_bands?page=${indicatorResultpage}&time_frame=${bollingerTimeFrame}&option=${BollingerLabelOption}`
exports.getAtr=(indicatorResultpage,atrTimeFrame,atrLableOption,atrPeriod,atrtrending)=>`/bx_block_dashboard/indicators/atr?page=${indicatorResultpage}&time_frame=${atrTimeFrame}&option=${atrLableOption}&period=${atrPeriod}&trending_period=${atrtrending}`
exports.getGraphApiEndPoint=(crypto_id,interval)=>`/bx_block_dashboard/cryptocurrency_chart?id=${crypto_id}&interval=${interval}`
//VolumeApi
// exports.getVolumeApi=(volumeFilter,volumeLength,volumeUpPeriod)=>`/bx_block_dashboard/${volumeFilter}?page=1&length=${volumeLength}`
exports.getVolumeApi=(indicatorResultpage,volumeFilter,volumeLength,volumeUpPeriod)=>`/bx_block_dashboard/volume_up?value=${volumeFilter}&page=${indicatorResultpage}&time_frame=${volumeUpPeriod}&length=${volumeLength}`
exports.getAVGVolumeApi=(indicatorResultpage,volumeFilter ,avgVolumeMaxValue,avgVolumeMinValue,priceFrom ,volumeUpPeriod)=>`/bx_block_dashboard/volume_up?value=${volumeFilter}&page=${indicatorResultpage}&time_frame=${volumeUpPeriod}&from=${avgVolumeMinValue}000000000&to=${avgVolumeMaxValue}000000000&order=${priceFrom}`
exports.getBullishDoubleBottom=()=>`/bx_block_dashboard/reversal_continuation/bullish_reversals/double_bottom_pattern?page=1&time_frame=7d`
// Customizable Area End

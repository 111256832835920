import React from "react";
import NoSubscriptionDialogWeb from "./NoSubscriptionDialogWeb";

export const setChartData = (points: any) => {
    let chartPriceData: any = []
    let chartVolumeData: any = []
    let chartMarketCapData: any = []
    if (points) {
        Object.keys(points).forEach(function (key, index) {
            chartPriceData.push({ 'time': Number(key), 'value': points[key].v[0] });
        });
        Object.keys(points).forEach(function (key, index) {
            chartVolumeData.push({ 'time': Number(key), 'value': points[key].v[1] });
        });
        Object.keys(points).forEach(function (key, index) {
            chartMarketCapData.push({ 'time': Number(key), 'value': points[key].v[2] });
        });
    }
    return { chartPriceData, chartVolumeData, chartMarketCapData }
}

export const removePlusMinusSymbol = (value: string) => {
    return value.replace("+", "").replace("-", "");
}

export const fundamentalObjects = (values: any, cat: any) => {
    const rank = [10, 50].includes(parseInt(values.rank)) ? values.rank : 0;
    const customRankVal = [10, 50].includes(parseInt(values.rank)) ? 0 : values.rank;
    const funValues = { rank, customRankVal, category: cat?.name, marketCapitalizationFrom: 
    values.market_cap_min, marketCapitalizationTo: values.market_cap_max };
    return funValues;
}

export const volumeObjects = (values: any) => {
    const volValues = { volumeOption: setVolumeOption(values.volume_option), avgVolumnSlider: [values.volume_from / 1000000000, 
        values.volume_to / 1000000000], volumeValue: values.volume_order, volumnPeriod: values.volume_period,
        volumnInputLength: values.volume_length };
    return volValues;
}

const setVolumeOption = (volumeOption: string) => {
    let option;
    if (!isNaN(parseInt(volumeOption))) {
        option = parseInt(volumeOption);
    } else {
        if (volumeOption === 'volume_average') {
            option = 1;
        }
        if (volumeOption === 'up') {
            option = 2;
        }
        if (volumeOption === 'down') {
            option = 3;
        }
    }
    return option;
}

export const priceActionObjects = (values: any) => {
    const paValues = { priceOptionCategory: isNaN(values.price_action_main_option) ? priceActionMainOptionsReverse[values.price_action_main_option] : parseInt(values.price_action_main_option), 
        priceOptionSubCategoryOne: priceActionOptions(values.price_action_option, values.price_action_suboption),
        priceActionTimeFrame: values.price_action_timeframe, priceActionPercentage: values.gainloss_percentage, priceActionLength: values.gainloss_percentage,
        priceActionHighLowSubCategory: capitalizeText(values.price_action_option.replaceAll("_", " ")), priceActionBreakSubCategory: capitalizeText(values.price_action_option), 
        priceActionSupportSubCategory: capitalizeText(values.price_action_option.replaceAll("_", " ")), priceActionNearBreakdownSubCategory: values.price_action_suboption,
        priceActionStandardSubCategory: values.price_action_suboption.replace("_", " "), priceActionStandardChannelSubCategory: values.price_action_option,
        priceActionStandardTimeOptionSubCategory: values.price_action_timeframe, priceActionReversalSubCategory: values.price_action_option,
        priceActionReversalBottomSubCategory: values.price_action_suboption, priceActionReversalTopSubCategory: values.price_action_suboption,
        priceActionReversalTimeOptionSubCategory: values.price_action_timeframe,
        priceActionContinuationSubCategory: values.price_action_suboption, priceActionCandlesSticksSubCategory: values.price_action_option,
        priceActionCandlesBullishSubCategory: values.price_action_suboption, priceActionCandlesBearishSubCategory: values.price_action_suboption,
        priceActionCandlesOthersPatternsSubCategory: values.price_action_suboption, priceActionCandleStickTimeOptionSubCategory: values.price_action_timeframe
    }
    return paValues;
}

export const priceActionCustomObjects = (values: any) => {
    const paValues = { priceOptionCategory: isNaN(values.price_action_main_option) ? priceActionMainOptionsReverse[values.price_action_main_option] : parseInt(values.price_action_main_option), 
        priceOptionSubCategoryOne: priceActionOptions(values.price_action_option, values.price_action_suboption),
        priceActionTimeFrame: values.price_action_timeframe, priceActionPercentage: values.gainloss_percentage, priceActionLength: values.gainloss_percentage,
        priceActionHighLowSubCategory: capitalizeText(values.price_action_option.replaceAll("_", " ")), priceActionBreakSubCategory: capitalizeText(values.price_action_option), 
        priceActionSupportSubCategory: capitalizeText(values.price_action_option.replaceAll("_", " ")), priceActionNearBreakdownSubCategory: values.price_action_suboption,
        priceActionStandardSubCategory: values.price_action_suboption.replace("_", " "), priceActionStandardChannelSubCategory: values.price_action_option,
        priceActionStandardTimeOptionSubCategory: values.price_action_timeframe, priceActionReversalSubCategory: values.price_action_suboption,
        priceActionReversalBottomSubCategory: values.price_action_option, priceActionReversalTopSubCategory: values.price_action_option,
        priceActionReversalTimeOptionSubCategory: values.price_action_timeframe,
        priceActionContinuationSubCategory: values.price_action_option, priceActionCandlesSticksSubCategory: values.price_action_suboption,
        priceActionCandlesBullishSubCategory: values.price_action_option, priceActionCandlesBearishSubCategory: values.price_action_option,
        priceActionCandlesOthersPatternsSubCategory: values.price_action_option, priceActionCandleStickTimeOptionSubCategory: values.price_action_timeframe
    }
    return paValues;
}

export const indicatorObjects = (values:any, selectedIndicator:any) => {
    const indiValues = {
        selectedIndicatorsForFilter: [selectedIndicator],
        rsiSubCategoryOption: indicatorSelectedOption(values.indicator_screen_option, selectedIndicator),
        rsiPeriod: values.indicator_period,
        rsiRadioGroup: values.indicator_above_below,
        rsiLevel: values.indicator_level_centerline,
        centerLine: values.indicator_level_centerline,
        rsiTimeFrame: values.indicator_time_frame,
        rsiDivergenceOption: values.indicator_divergence_crossover,
        mfiSubCategoryOption: indicatorSelectedOption(values.indicator_screen_option, selectedIndicator),
        mfiPeriod: values.indicator_period,
        mfiRadioGroup: values.indicator_above_below,
        mfiLevel: values.indicator_level_centerline,
        mfiTimeFrame: values.indicator_time_frame,
        mfiDivergenceOption: values.indicator_divergence_crossover,
        stochasticSubCategoryOption: indicatorSelectedOption(values.indicator_screen_option, selectedIndicator),
        stochasticPeriod: values.indicator_period,
        stochasticRadioGroup: values.indicator_above_below,
        stochasticLevel: values.indicator_level_centerline,
        stochasticTimeFrame: values.indicator_time_frame,
        stochasticDivergenceOption: values.indicator_divergence_crossover,
        cciSubCategoryOption: indicatorSelectedOption(values.indicator_screen_option, selectedIndicator),
        cciPeriod: values.indicator_period,
        cciRadioGroup: values.indicator_above_below,
        cciTimeFrame: values.indicator_time_frame,
        cciDivergenceOption: values.indicator_divergence_crossover,
        movingAverageSubCategoryOption: movingAverageSubCategoryOption(values.indicator_moving_average_type),
        simpleMovingAverageSubCategoryOption: indicatorSelectedOption(values.indicator_screen_option, selectedIndicator),
        exponentialMovingAverageSubCategoryOption: indicatorSelectedOption(values.indicator_screen_option, selectedIndicator),
        movingAveragePeriod: values.indicator_period,
        movingAverageTimeFrame: values.indicator_time_frame,
        movingAverageCrossoverOption: values.indicator_divergence_crossover,
        macdSubCategoryOption: indicatorSelectedOption(values.indicator_screen_option, selectedIndicator),
        macdRadioGroup: values.indicator_above_below,
        macdTrendingRadioGroup: values.indicator_trending_up_down,
        macdPeriod: values.indicator_period,
        macdDivergenceOption: values.indicator_divergence_crossover,
        macdTimeFrame: values.indicator_time_frame,
        adxSubCategoryOption: indicatorSelectedOption(values.indicator_screen_option, selectedIndicator),
        adxPeriod: values.indicator_period,
        adxRadioGroup: values.indicator_above_below,
        adxLevel: values.indicator_level_centerline,
        adxTimeFrame: values.indicator_time_frame,
        obvSubCategoryOption: indicatorSelectedOption(values.indicator_screen_option, selectedIndicator),
        obvLevel: values.indicator_period,
        obvDivergenceOption: values.indicator_divergence_crossover,
        obvTimeFrame: values.indicator_time_frame,
        cmfSubCategoryOption: indicatorSelectedOption(values.indicator_screen_option, selectedIndicator),
        cmfPeriod: values.indicator_period,
        cmfTimeFrame: values.indicator_time_frame,
        cmfDivergenceOption: values.indicator_divergence_crossover,
        bollingerBandsSubCategoryOption: indicatorSelectedOption(values.indicator_screen_option, selectedIndicator),
        bollingerBandsRadioGroup: values.indicator_above_below,
        bollingerBandsTimeFrame: values.indicator_time_frame,
        atrSubCategoryOption: indicatorSelectedOption(values.indicator_screen_option, selectedIndicator),
        atrPeriod: values.indicator_period,
        atrTrendingPeriod: values.indicator_trending_period,
        atrTimeFrame: values.indicator_time_frame
    }
    return indiValues;
}

export const priceActionMainOptions: Record<number, string> = { 1: 'gain_loss', 2: 'high_low', 3: 'breakout_breakdown', 4: 'support_resistance',
5: 'standard_patterns', 6: 'reversal_patterns', 7: 'candelsticks'};

export const priceActionMainOptionsReverse: Record<string, string>  = Object.entries(priceActionMainOptions).reduce((acc: Record<string, string>, [key, value]) => (acc[value] = key, acc), {})

export const capitalizeText = (sentence: string) => {
    const words = sentence.split(" ");
    return words.map((word) => { 
        return word[0].toUpperCase() + word.substring(1); 
    }).join(" ");
}

const movingAverageSubCategoryOption = (indicator_moving_average_type: any) => {
    let value = indicator_moving_average_type;
    if(isNaN(indicator_moving_average_type)) {
        value = indicator_moving_average_type === "simple" ? 1 : 2;
    }
    return parseInt(value);
}

export const priceActionCustomOption = (priceOption: string) => {
    let option;
    switch (priceOption) {
        case 'Price Up %':
        case 'Price Down %':
            option = 'price_up';
            break;
        case 'Price Trending Up':
            option = 'trending_up';
            break;
        case 'Price Trending Down':
            option = 'trending_down';
            break;
        case 'Near All Time High':
            option = 'near_all_time_high';
            break;
        case 'Near All Time Low':
            option = 'near_all_time_low';
            break;
        case 'At All Time High':
            option = 'at_all_time_high';
            break;
        case 'At All Time Low':
            option = 'at_all_time_low';
            break;
        case 'Near 52 Weeks High':
            option = 'near_52_weeks_high';
            break;
        case 'Near 52 Weeks Low':
            option = 'near_52_weeks_low';
            break;
        case 'Near Past Week High':
            option = 'near_past_week_high';
            break;
        case 'Near Past Week Low':
            option = 'near_past_week_low';
            break;
        case 'All Time High Breakout':
            option = 'all time high breakout';
            break;
        case 'All Time Low Breakdown':
            option = 'all time low breakdown';
            break;
        case '52 Weeks High Breakout':
            option = '52 weeks high breakout';
            break;
        case '52 Weeks Low Breakdown':
            option = '52 weeks low breakdown';
            break;
        case 'Past Week High Breakout':
            option = 'past week high breakout';
            break;
        case 'Past Week Low Breakdown':
            option = 'past week low breakdown';
            break;   
        case 'Standard Support':
            option = 'standard_support'
            break;
        case 'Fibonacci Support':
            option = 'fibonacci_support'
            break;
        case 'Standard Resistance':
            option = 'standard_resistance'
            break;
        case 'Fibonacci Resistance':
            option = 'fibonacci_resistance'
            break;
        case 'Fibonacci Resistance':
            option = 'fibonacci_resistance'
            break;
        default:
            option = priceOption;
    }
    return option;

}

export const priceActionCustomSubOption = (priceOption: string) => {
    let suboption = "";
    if (priceOption === 'Price Up %') suboption = "up";
    if (priceOption === 'Price Down %') suboption = "down";
    return suboption;
}

export const priceActionOptions = (priceOption: string, priceSuboption: string) => {
    let option;
    switch (priceOption) {
        case 'price_up':
            option = priceSuboption === 'up' ? 'Price Up %' : 'Price Down %';
            break;
        case 'trending_up':
            option = 'Price Trending Up';
            break;
        case 'trending_down':
            option = 'Price Trending Down';
            break;        
        default:
            option = priceOption;
    }
    return option;
}

export const indicatorScreenOption = (optionList: any, option: any) => optionList.find((c: any) => c.id === parseInt(option)).name.toLowerCase();

export const adxOption = (values: any) => {
    let option;
    switch (values.adxSubCategoryOption) {
        case 1:
            option = values.adxRadioGroup === 'above' ? 'positive_di above selected level' : 'positive_di below selected level';
            break;
        case 2:
            option = values.adxRadioGroup === 'above' ? 'positive_di cross above selected level' : 'positive_di cross below selected level';
            break;
        case 3:
            option = values.adxRadioGroup === 'above' ? 'positive_di cross above negative_di' : 'positive_di cross below negative_di';
            break;
        case 4:
            option = values.adxRadioGroup === 'above' ? 'positive_di above negative_di' : 'positive_di below negative_di';
            break;
        case 5:
            option = values.adxRadioGroup === 'above' ? 'negative_di above selected level' : 'negative_di above selected level';
            break;
        case 6:
            option = values.adxRadioGroup === 'above' ? 'negative_di cross above selected level' : 'negative_di cross below selected level';
            break;
        case 7:
            option = values.adxRadioGroup === 'above' ? 'negative_di cross above positive_di' : 'negative_di cross below positive_di';
            break;
        case 8:
            option = values.adxRadioGroup === 'above' ? 'negative_di above positive_di' : 'negative_di below positive_di';
            break;
        case 9:
            option = values.adxRadioGroup === 'above' ? 'adx above selected level' : 'adx below selected level';
            break;
        case 10:
            option = values.adxRadioGroup === 'above' ? 'adx cross above selected level' : 'adx cross below selected level';
            break;
        case 11:
            option = 'adx trending up';
            break;
        case 12:
            option = 'adx trending down';
            break;
        }
    return option;
}

export const macdOption = (values: any, option: any) => {
    let macdScreenOption = option;
    if ([1, 2].includes(values.macdSubCategoryOption)) {
        macdScreenOption = macdScreenOption.replace("(0)", "").split(" ");
        macdScreenOption.splice(macdScreenOption.length - 1, 0, values.macdSubCategoryOption ===1 ? "is " + values.macdRadioGroup : values.macdRadioGroup);
        macdScreenOption = macdScreenOption.join(" ");
    }
    if (values.macdSubCategoryOption === 3) {
        macdScreenOption = macdScreenOption.split(" ");
        macdScreenOption.splice(macdScreenOption.length - 2, 0, values.macdRadioGroup);
        macdScreenOption = macdScreenOption.join(" ");
    }
    if (values.macdSubCategoryOption === 4) {
        macdScreenOption = `macd line crossed ${values.macdRadioGroup} signal line`
    }
    if (values.macdSubCategoryOption === 5) {
        macdScreenOption = macdScreenOption + " " + values.macdTrendingRadioGroup;
    }
    if (values.macdSubCategoryOption === 6) {
        macdScreenOption = "divergence " + values.macdDivergenceOption;
    }
    return macdScreenOption;
}

const indicatorSelectedOption = (option: any, mainOption: number) => {
    let selectedOption;
    if (isNaN(option)) {
        switch (option) {
            case 'rsi above overbought level':
            case 'rsi below overbought level':
            case 'mfi above overbought level':
            case 'mfi below overbought level':
            case 'cci above plus 100':
            case 'atr trending up':
            case 'k above overbought level':
            case 'k below overbought level':
            case 'macd is above centerline':
            case 'macd is below centerline':
            case 'positive_di above selected level':
            case 'positive_di below selected level':
            case 'on balance volume trending up':
            case 'chaikin money flow above 0':
            case 'coin price near upper band':
            case 'price above simple moving average':
            case 'price above exponential moving average':
            case 'cmf above 0':
                selectedOption = 1;
                break;
            case 'rsi above oversold level':
            case 'rsi below oversold level':
            case 'mfi above oversold level':
            case 'mfi below oversold level':
            case 'cci below minus 100':
            case 'atr trending down':
            case 'k above oversold level':
            case 'k below oversold level':
            case 'macd crossed above centerline':
            case 'macd crossed below centerline':
            case 'positive_di cross above selected level':
            case 'positive_di cross below selected level':
            case 'on balance volume trending down':
            case 'chaikin money flow below 0':
            case 'coin price near lower band':
            case 'price below simple moving average':
            case 'price below exponential moving average':
            case 'cmf below 0':
                selectedOption = 2;
                break;
            case 'cross above overbought level':
            case 'cross below overbought level':
            case 'cci cross above plus 100':
            case 'cci cross below plus 100':
            case 'k cross above overbought level':
            case 'k cross below overbought level':
            case 'macd line is above signal line':
            case 'macd line is below signal line':
            case 'positive_di cross above negative_di':
            case 'positive_di cross below negative_di':
            case 'chaikin money flow crossed above 0':
            case 'coin price near middle band':
            case 'price crossed above simple moving average':
            case 'price crossed above exponential moving average':
                selectedOption = 3;
                break;
            case 'cross above oversold level':
            case 'cross below oversold level':
            case 'cci cross above minus 100':
            case 'cci cross below minus 100':
            case 'k cross above oversold level':
            case 'k cross below oversold level':
            case 'macd line crossed above signal line':
            case 'macd line crossed below signal line':
            case 'positive_di above negative_di':
            case 'positive_di below negative_di':
            case 'chaikin money flow crossed below 0':
            case 'coin price crossed above middle band':
            case 'coin price crossed below middle band':
            case 'price crossed below simple moving average':
            case 'price crossed below exponential moving average':
                selectedOption = 4;
                break;
            case 'cross above center line':
            case 'cross below center line':
            case 'cci cross above 0':
            case 'cci cross below 0':
            case 'k cross above centerline':
            case 'k cross below centerline':
            case 'macd trending up':
            case 'macd trending down':
            case 'negative_di above selected level':
            case 'negative_di below selected level':
            case 'chaikin money flow trending up':
            case 'coin price crossed above upper band':
            case 'coin price crossed below upper band':
            case 'price bounced up from simple moving average':
            case 'price bounced up from exponential moving average':
                selectedOption = 5;
                break;
            case 'k cross above d':
            case 'k cross below d':
            case 'negative_di cross above selected level':
            case 'negative_di cross below selected level':
            case 'chaikin money flow trending down':
            case 'coin price crossed above lower band':
            case 'coin price crossed below lower band':
            case 'price bounced down from simple moving average':
            case 'price bounced down from exponential moving average':
                selectedOption = 6;
                break;
            case 'divergence bullish':
            case 'divergence bearish':
                selectedOption = 6;
                if (mainOption === 3)
                    selectedOption = 8;
                if (mainOption === 9)
                    selectedOption = 7;
                if (mainOption === 8)
                    selectedOption = 3;
                break;
            case 'negative_di cross above positive_di':
            case 'negative_di cross below positive_di':
            case 'bollinger band squeeze':
            case 'bullish crossovers':
            case 'bearish crossovers':
                selectedOption = 7;
                break;
            case 'negative_di above positive_di':
            case 'negative_di below positive_di':
                selectedOption = 8;
                break;
            case 'adx above selected level':
            case 'adx below selected level':
                selectedOption = 9;
                break;
            case 'adx cross above selected level':
            case 'adx cross below selected level':
                selectedOption = 10;
                break;
            case 'adx trending up':
                selectedOption = 11;
                break;
            case 'adx trending down':
                selectedOption = 12;
                break;
        }
    } else {
        selectedOption = parseInt(option);
    }
    return selectedOption;
}

export const basicPlanAccessibility = () => {
  let showAlert:boolean = false;
  if (localStorage.getItem("currentPlan")) {
      const planDetails = JSON.parse((localStorage.getItem("currentPlan") as any));
      const plans = Object.entries(planDetails);
      plans.forEach(([accessName, accessValue ]) => {
          if (newsPageAccessibility(accessName, accessValue)) {
              showAlert = true;
              return;
          }
          premiumDiscordCommunity(accessName, accessValue);
          if (showAlert) return;
      })
  } else {
    showAlert = ['/NewsPortalWeb', '/ScreenerWeb', '/EducationCenterIntermediateWeb'].includes(window.location.pathname);
  }
  if (showAlert) {
    return <NoSubscriptionDialogWeb 
    message="You don't have access to this page. To enjoy complete access, please subscribe to the Pro Plan" 
    buttonURL="/" 
    buttonText="Go To Home"/>
  }
}

export const newsPageAccessibility = (accessName: string, accessValue: any) => accessName === 'premium_newsletter' && accessValue === 'No' && window.location.pathname === '/NewsPortalWeb';

const eduPageAccessibility = (accessName: string, accessValue: any) => accessName === 'course_certificates' && accessValue === 'No' && window.location.pathname === '/EducationCenterLandingWeb';

const premiumDiscordCommunity = (accessName: string, accessValue: any) => accessName === 'premium_discord_community' && accessValue === 'No' && window.location.pathname === '/';

export const currentPlan = () => {
  let plan = localStorage.getItem('currentPlan');
  return plan && JSON.parse(plan);
}

export const timeFrameList = [
  { id: 1, name: '1 hr', value: '1h', disabled: false },
  { id: 2, name: '2 hr', value: '2h', disabled: false },
  { id: 3, name: '3 hr', value: '3h', disabled: false },
  { id: 4, name: '4 hr', value: '4h', disabled: false },
  { id: 5, name: '6 hr', value: '6h', disabled: false },
  { id: 6, name: '12 hr', value: '12h', disabled: false },
  { id: 7, name: '1 day', value: '1d', disabled: false },
  { id: 8, name: '2 days', value: '2d', disabled: false },
  { id: 9, name: '3 days', value: '3d', disabled: false },
  { id: 10, name: '7 days', value: '7d', disabled: false },
  { id: 11, name: '14 days', value: '14d', disabled: false },
  { id: 12, name: '15 days', value: '15d', disabled: false },
  { id: 13, name: '30 days', value: '30d', disabled: false },
  { id: 14, name: '60 days', value: '60d', disabled: false },
  { id: 15, name: '90 days', value: '90d', disabled: false },
  { id: 16, name: '365 days', value: '365d', disabled: false },
]

export const subscriptionPlanDetailList = [
    { id: 1, label: 'ad_free_experience', features: 'Ad Free Experience', basic: 'No', pro: 'Yes' },
    { id: 2, label: 'custom_screen_saves', features: 'Custom Screen Saves', basic: '3', pro: 'Unlimited' },
    { id: 3, label: 'time_frames', features: 'Time Frames', basic: '5 mins, 1 hour & 1 day', pro: 'All' },
    { id: 4, label: 'result_per_screen', features: 'Result Per Screen ', basic: '3', pro: 'All' },
    { id: 5, label: 'filters', features: 'Filters', basic: 'Limited', pro: 'Unlimited' },
    { id: 6, label: 'coins_in_watchlist', features: 'Coins in Watchlist', basic: '5', pro: 'Unlimited' },
    { id: 7, label: 'virtual_loyalty_tokens', features: 'Virtual Tokens', basic: 'Limited', pro: 'Unlimited' },
    { id: 8, label: 'lessons_per_module', features: 'Lessons Per Module', basic: 'No', pro: 'Yes' },
    { id: 9, label: 'course_certificates', features: 'Course Certificates', basic: 'No', pro: 'Yes' },
    { id: 10, label: 'premium_discord_community', features: 'Premium Discord Community', basic: 'No', pro: 'Yes' },
    { id: 11, label: 'premium_newsletter', features: 'Premium Newsletter', basic: 'No', pro: 'Yes' }
]

export const getCreditCardType = (cardNumber: string) => {
    const cardPatterns = {
        Visa: /^4/,
        Mastercard: /^5[1-5]/,
        Amex: /^3[47]/,
        Discover: /^6(?:011|5)/,
    };
    
    for (const [type, pattern] of Object.entries(cardPatterns)) {
        if (pattern.test(cardNumber)) {
            return type;
        }
    }
    return undefined;
}

export const countries = [
    { "name": "Afghanistan", "code": "AF" },
    { "name": "Albania", "code": "AL" },
    { "name": "Algeria", "code": "DZ" },
    { "name": "Andorra", "code": "AD" },
    { "name": "Angola", "code": "AO" },
    { "name": "Antigua and Barbuda", "code": "AG" },
    { "name": "Argentina", "code": "AR" },
    { "name": "Armenia", "code": "AM" },
    { "name": "Australia", "code": "AU" },
    { "name": "Austria", "code": "AT" },
    { "name": "Azerbaijan", "code": "AZ" },
    { "name": "Bahamas", "code": "BS" },
    { "name": "Bahrain", "code": "BH" },
    { "name": "Bangladesh", "code": "BD" },
    { "name": "Barbados", "code": "BB" },
    { "name": "Belarus", "code": "BY" },
    { "name": "Belgium", "code": "BE" },
    { "name": "Belize", "code": "BZ" },
    { "name": "Benin", "code": "BJ" },
    { "name": "Bhutan", "code": "BT" },
    { "name": "Bolivia", "code": "BO" },
    { "name": "Bosnia and Herzegovina", "code": "BA" },
    { "name": "Botswana", "code": "BW" },
    { "name": "Brazil", "code": "BR" },
    { "name": "Brunei", "code": "BN" },
    { "name": "Bulgaria", "code": "BG" },
    { "name": "Burkina Faso", "code": "BF" },
    { "name": "Burundi", "code": "BI" },
    { "name": "Cabo Verde", "code": "CV" },
    { "name": "Cambodia", "code": "KH" },
    { "name": "Cameroon", "code": "CM" },
    { "name": "Canada", "code": "CA" },
    { "name": "Central African Republic", "code": "CF" },
    { "name": "Chad", "code": "TD" },
    { "name": "Chile", "code": "CL" },
    { "name": "China", "code": "CN" },
    { "name": "Colombia", "code": "CO" },
    { "name": "Comoros", "code": "KM" },
    { "name": "Congo (Congo-Brazzaville)", "code": "CG" },
    { "name": "Costa Rica", "code": "CR" },
    { "name": "Croatia", "code": "HR" },
    { "name": "Cuba", "code": "CU" },
    { "name": "Cyprus", "code": "CY" },
    { "name": "Czechia (Czech Republic)", "code": "CZ" },
    { "name": "Democratic Republic of the Congo", "code": "CD" },
    { "name": "Denmark", "code": "DK" },
    { "name": "Djibouti", "code": "DJ" },
    { "name": "Dominica", "code": "DM" },
    { "name": "Dominican Republic", "code": "DO" },
    { "name": "Ecuador", "code": "EC" },
    { "name": "Egypt", "code": "EG" },
    { "name": "El Salvador", "code": "SV" },
    { "name": "Equatorial Guinea", "code": "GQ" },
    { "name": "Eritrea", "code": "ER" },
    { "name": "Estonia", "code": "EE" },
    { "name": "Eswatini (fmr. Swaziland)", "code": "SZ" },
    { "name": "Ethiopia", "code": "ET" },
    { "name": "Fiji", "code": "FJ" },
    { "name": "Finland", "code": "FI" },
    { "name": "France", "code": "FR" },
    { "name": "Gabon", "code": "GA" },
    { "name": "Gambia", "code": "GM" },
    { "name": "Georgia", "code": "GE" },
    { "name": "Germany", "code": "DE" },
    { "name": "Ghana", "code": "GH" },
    { "name": "Greece", "code": "GR" },
    { "name": "Grenada", "code": "GD" },
    { "name": "Guatemala", "code": "GT" },
    { "name": "Guinea", "code": "GN" },
    { "name": "Guinea-Bissau", "code": "GW" },
    { "name": "Guyana", "code": "GY" },
    { "name": "Haiti", "code": "HT" },
    { "name": "Honduras", "code": "HN" },
    { "name": "Hungary", "code": "HU" },
    { "name": "Iceland", "code": "IS" },
    { "name": "India", "code": "IN" },
    { "name": "Indonesia", "code": "ID" },
    { "name": "Iran", "code": "IR" },
    { "name": "Iraq", "code": "IQ" },
    { "name": "Ireland", "code": "IE" },
    { "name": "Israel", "code": "IL" },
    { "name": "Italy", "code": "IT" },
    { "name": "Jamaica", "code": "JM" },
    { "name": "Japan", "code": "JP" },
    { "name": "Jordan", "code": "JO" },
    { "name": "Kazakhstan", "code": "KZ" },
    { "name": "Kenya", "code": "KE" },
    { "name": "Kiribati", "code": "KI" },
    { "name": "Kosovo", "code": "XK" },
    { "name": "Kuwait", "code": "KW" },
    { "name": "Kyrgyzstan", "code": "KG" },
    { "name": "Laos", "code": "LA" },
    { "name": "Latvia", "code": "LV" },
    { "name": "Lebanon", "code": "LB" },
    { "name": "Lesotho", "code": "LS" },
    { "name": "Liberia", "code": "LR" },
    { "name": "Libya", "code": "LY" },
    { "name": "Liechtenstein", "code": "LI" },
    { "name": "Lithuania", "code": "LT" },
    { "name": "Luxembourg", "code": "LU" },
    { "name": "Madagascar", "code": "MG" },
    { "name": "Malawi", "code": "MW" },
    { "name": "Malaysia", "code": "MY" },
    { "name": "Maldives", "code": "MV" },
    { "name": "Mali", "code": "ML" },
    { "name": "Malta", "code": "MT" },
    { "name": "Marshall Islands", "code": "MH" },
    { "name": "Mauritania", "code": "MR" },
    { "name": "Mauritius", "code": "MU" },
    { "name": "Mexico", "code": "MX" },
    { "name": "Micronesia", "code": "FM" },
    { "name": "Moldova", "code": "MD" },
    { "name": "Monaco", "code": "MC" },
    { "name": "Mongolia", "code": "MN" },
    { "name": "Montenegro", "code": "ME" },
    { "name": "Morocco", "code": "MA" },
    { "name": "Mozambique", "code": "MZ" },
    { "name": "Myanmar (formerly Burma)", "code": "MM" },
    { "name": "Namibia", "code": "NA" },
    { "name": "Nauru", "code": "NR" },
    { "name": "Nepal", "code": "NP" },
    { "name": "Netherlands", "code": "NL" },
    { "name": "New Zealand", "code": "NZ" },
    { "name": "Nicaragua", "code": "NI" },
    { "name": "Niger", "code": "NE" },
    { "name": "Nigeria", "code": "NG" },
    { "name": "North Korea", "code": "KP" },
    { "name": "North Macedonia (formerly Macedonia)", "code": "MK" },
    { "name": "Norway", "code": "NO" },
    { "name": "Oman", "code": "OM" },
    { "name": "Pakistan", "code": "PK" },
    { "name": "Palau", "code": "PW" },
    { "name": "Palestine State", "code": "PS" },
    { "name": "Panama", "code": "PA" },
    { "name": "Papua New Guinea", "code": "PG" },
    { "name": "Paraguay", "code": "PY" },
    { "name": "Peru", "code": "PE" },
    { "name": "Philippines", "code": "PH" },
    { "name": "Poland", "code": "PL" },
    { "name": "Portugal", "code": "PT" },
    { "name": "Qatar", "code": "QA" },
    { "name": "Romania", "code": "RO" },
    { "name": "Russia", "code": "RU" },
    { "name": "Rwanda", "code": "RW" },
    { "name": "Saint Kitts and Nevis", "code": "KN" },
    { "name": "Saint Lucia", "code": "LC" },
    { "name": "Saint Vincent and the Grenadines", "code": "VC" },
    { "name": "Samoa", "code": "WS" },
    { "name": "San Marino", "code": "SM" },
    { "name": "Sao Tome and Principe", "code": "ST" },
    { "name": "Saudi Arabia", "code": "SA" },
    { "name": "Senegal", "code": "SN" },
    { "name": "Serbia", "code": "RS" },
    { "name": "Seychelles", "code": "SC" },
    { "name": "Sierra Leone", "code": "SL" },
    { "name": "Singapore", "code": "SG" },
    { "name": "Slovakia", "code": "SK" },
    { "name": "Slovenia", "code": "SI" },
    { "name": "Solomon Islands", "code": "SB" },
    { "name": "Somalia", "code": "SO" },
    { "name": "South Africa", "code": "ZA" },
    { "name": "South Korea", "code": "KR" },
    { "name": "South Sudan", "code": "SS" },
    { "name": "Spain", "code": "ES" },
    { "name": "Sri Lanka", "code": "LK" },
    { "name": "Sudan", "code": "SD" },
    { "name": "Suriname", "code": "SR" },
    { "name": "Sweden", "code": "SE" },
    { "name": "Switzerland", "code": "CH" },
    { "name": "Syria", "code": "SY" },
    { "name": "Tajikistan", "code": "TJ" },
    { "name": "Tanzania", "code": "TZ" },
    { "name": "Thailand", "code": "TH" },
    { "name": "Timor-Leste", "code": "TL" },
    { "name": "Togo", "code": "TG" },
    { "name": "Tonga", "code": "TO" },
    { "name": "Trinidad and Tobago", "code": "TT" },
    { "name": "Tunisia", "code": "TN" },
    { "name": "Turkey", "code": "TR" },
    { "name": "Turkmenistan", "code": "TM" },
    { "name": "Tuvalu", "code": "TV" },
    { "name": "Uganda", "code": "UG" },
    { "name": "Ukraine", "code": "UA" },
    { "name": "United Arab Emirates", "code": "AE" },
    { "name": "United Kingdom", "code": "GB" },
    { "name": "United States of America", "code": "US" },
    { "name": "Uruguay", "code": "UY" },
    { "name": "Uzbekistan", "code": "UZ" },
    { "name": "Vanuatu", "code": "VU" },
    { "name": "Vatican City (Holy See)", "code": "VA" },
    { "name": "Venezuela", "code": "VE" },
    { "name": "Vietnam", "code": "VN" },
    { "name": "Yemen", "code": "YE" },
    { "name": "Zambia", "code": "ZM" },
    { "name": "Zimbabwe", "code": "ZW" }
  ]
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import AsyncStorage from "@react-native-async-storage/async-storage";
import { CommonContext } from "../../../framework/src/CommonContextProvider";
import { theme } from "../../../framework/src/theme";
import { countries } from "../../../components/src/countryList";
import moment from "moment";
import { BackHandler } from "react-native";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  route: any;
  // data: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  subscriptions: any;
  data: any;
  subscriptionArray: any;
  subscriptionList: any;
  selectedPlan: any;
  costType: string;
  isSubscriptionSelected: boolean;
  openModal: boolean;
  modalDescription: any;
  isRefresh: boolean;
  cardModal: boolean;
  subscriptionId: any;
  currentSubscription: any;
  cancelSubscriptionId: any;
  cancelSuccessModal: boolean;
  currentSubLoader: boolean;
  subscriptionListLoader: boolean;
  isAutoRenew: boolean;
  checkbox: boolean;
  searchCountryText: any;
  visitedCountry: any;
  selectedCountry: string;
  expMonth: any;
  expYear: any;
  showMonthList: boolean;
  ShowYearList: boolean;
  selectedMonth: any;
  selectedYear: any;
  companyName: string;
  address: string;
  city: string;
  zipCode: string;
  VATnum: string;
  canrdNum: string;
  cardHolderName: string;
  cvv: string;
  cardData: any;
  deleteModal: boolean;
  deleteaddressId: any;
  selectedAddress: any;
  showForm: boolean;
  okModal: boolean;
  fullNameError: boolean
  fullNameErrorMsg: string
  addressError: boolean
  addressErrorMsg: string
  cityError: boolean
  cityErrorMsg: string
  zipError: boolean
  zipErrorMsg: string
  countryError: boolean
  countryErrorMsg: string
  cardError: boolean
  cardErrorMsg: string
  cardNameError: boolean
  cardNameErrorMsg: string
  monthError: boolean
  monthErrorMsg: string
  yearError: boolean
  yearErrorMsg: string
  cvvError: boolean
  cvvErrorMsg: string
  successModal: boolean
  failModal: boolean
  year: any
  submitted: boolean
  cvvLabel: string
  cardNoLabel: string;
  transactionId: string
  isreferred: boolean
  planName : string

  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CustomisableusersubscriptionsController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  scrollview_ref: any;
  static contextType = CommonContext;
  getListCallId: any;
  subscriptions: any;
  subscribe: any;
  cancelSubscription: any;
  noSubscription: any;
  currentSubscription: any;
  getSubscriptionsListCallId: any;
  apiCardDetailsCallId: any;
  apicreateSubscriptionCallId: any;
  getcurrentSubscriptionoCallId: any;
  cancelSubscriptionCallId: any;
  cancelSubscriptionApiCallId: string = "";
  getCardsApiCallId: string = "";
  deleteBillingInformationApiCallId: string = "";
  backHandler: any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      subscriptionId: "",
      token: "",
      subscriptions: [],
      data: null,
      subscriptionArray: [],
      subscriptionList: [],
      selectedPlan: {},
      costType: 'monthly',
      isSubscriptionSelected: false,
      openModal: false,
      modalDescription: "",
      isRefresh: false,
      cardModal: false,
      currentSubscription: {},
      cancelSubscriptionId: "",
      cancelSuccessModal: false,
      currentSubLoader: true,
      subscriptionListLoader: true,
      isAutoRenew: false,
      checkbox: false,
      searchCountryText: "",
      visitedCountry: countries,
      selectedCountry: "",
      expMonth: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
      expYear: this.getYears(),
      showMonthList: false,
      ShowYearList: false,
      selectedMonth: "",
      selectedYear: "",
      companyName: "",
      address: "",
      city: "",
      zipCode: "",
      VATnum: "",
      canrdNum: "",
      cardHolderName: "",
      cvv: "",
      cardData: [],
      deleteModal: false,
      deleteaddressId: "",
      selectedAddress: "",
      showForm: false,
      okModal: false,
      fullNameError: false,
      fullNameErrorMsg: "",
      addressError: false,
      addressErrorMsg: '',
      cityError: false,
      cityErrorMsg: '',
      zipError: false,
      zipErrorMsg: '',
      countryError: false,
      countryErrorMsg: "",
      cardError: false,
      cardErrorMsg: "",
      monthError: false,
      monthErrorMsg: "",
      yearError: false,
      yearErrorMsg: "",
      cardNameError: false,
      cardNameErrorMsg: "",
      cvvError: false,
      cvvErrorMsg: "",
      successModal: false,
      failModal: false,
      year: '',
      submitted: false,
      cvvLabel: 'cvv is required',
      cardNoLabel: '',
      transactionId: '',
      isreferred: false,
      planName :""
    };
    this.subscriptions = configJSON.subscriptions;
    this.subscribe = configJSON.subscribe;
    this.cancelSubscription = configJSON.cancelSubscription;
    this.noSubscription = configJSON.noSubscription;
    this.getCards()
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const data = message.getData(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      this.setState({ data: data });
    }

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      switch (apiRequestCallId) {
        case this.getListCallId:
          this.setState({
            subscriptionListLoader: false,
            subscriptionList: responseJson?.data
          });
          break;
        case this.getSubscriptionsListCallId:
          this.setState({
            subscriptionListLoader: false,
            subscriptionArray: responseJson
          });
          break;
        case this.apicreateSubscriptionCallId:
          this.handleSubscriptionResponse(responseJson)


          break;
        case this.cancelSubscriptionApiCallId:

          this.setState({
            currentSubLoader: false,
            cancelSuccessModal: true,
            modalDescription: "You have cancelled successfully",

          });
          this.getcurrentSubscription();
          break;
        case this.getcurrentSubscriptionoCallId:
          AsyncStorage.setItem("userInfo", responseJson.data.attributes.subscription_plan.data.attributes.subscription_type);
          if (responseJson.data) {
            this.setState({
              currentSubLoader: false,
              currentSubscription: responseJson?.data,
              cancelSubscriptionId: responseJson.data.attributes.subscription_plan.data.id,
              isreferred: responseJson.data.attributes.referred,
            });
          }
          else {
            // alert(JSON.stringify(responseJson))
            this.setState({ currentSubLoader: false })
            this.parseApiErrorResponse(responseJson);
          }

          break;
        case this.cancelSubscriptionCallId:
          this.setState({
            currentSubLoader: false,
            cancelSuccessModal: true,
            modalDescription: "You have cancelled subscription successfully"
          });
          break;
        case this.getCardsApiCallId:
          this.manageGetCardsResponse(responseJson);
          break;
        case this.deleteBillingInformationApiCallId:
          const filteredAddresses = this.state.cardData?.filter((address: any) => address.attributes?.id !== this.state.deleteaddressId);
          this.setState({
            cardData: filteredAddresses,
            currentSubLoader: false,
            deleteModal: false,
            cardModal: true,
            modalDescription: "Billing details deleted successfully"
          });
          if (this.state.cardData.length > 0) {
            this.setState({ showForm: false })
          } else if (this.state.cardData.length === 0) {
            this.setState({ showForm: true })
          }
          break;
        default:
          // alert(JSON.stringify(responseJson))
          this.setState({ currentSubLoader: false })
          this.parseApiErrorResponse(responseJson);
      }


    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const id = this.props.route?.params?.id
    const planData = this.props.route?.params?.planData
    const planName = this.props.route?.params?.planData?.attributes.name
    this.setState({
      subscriptionId: id,
      selectedPlan: planData,
      planName :planName
    })
    this.backHandler = BackHandler.addEventListener('hardwareBackPress', this.handleBackPress);
    this.props.navigation.addListener('focus', () => {
      this.setState({ currentSubLoader: true })
      this.getSubscriptionsList();
      this.getcurrentSubscription();
      this.fetchSubscriptionsLists();
      this.getCards()
    })
    this.getcurrentSubscription();
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  fetchSubscriptionsList = () => {
    // Customizable Area Start
    // Customizable Area End
  };

  gotoSubDetailScreen(item:any) {
    // Customizable Area Start
    
    // Customizable Area End
  }

  // Customizable Area Start
  async componentWillUnmount() {
    this.backHandler.remove();
  }

  onClickViewAllFet(item: any) {
    this.setState({
      subscriptionId: item.id
    })
    this.props.navigation.navigate('SubscriptionDetails')


  }
  getDiscount() {
    const { isreferred, selectedPlan, } = this.state;   
    let discount: string = '0.0';
    if (isreferred) {
      discount = selectedPlan?.attributes?.billing_cycle === 'monthly' ? selectedPlan.attributes.monthly_discount :
        selectedPlan?.attributes?.yearly_discount;
  }

    return parseFloat(discount).toFixed(1);
  }
  getReferredPrice() {
    const { isreferred, selectedPlan } = this.state;
    let price: string = selectedPlan?.attributes?.price;

    if (isreferred) {
      price = selectedPlan?.attributes?.billing_cycle === 'monthly' ? selectedPlan.attributes.referred_monthly_price :
        selectedPlan?.attributes?.referred_yearly_price;
    }

    return parseFloat(price).toFixed(1);
  }

  navigateToContent = () => {
    this.props.navigation.navigate("SubscribeContent")
  }

  navigateToDashboard = () => {
    this.props.navigation.navigate("Dashboard")
  }
  navigateToList = () => {
    this.props.navigation.navigate("SubscriptionDetails")
  }

  onPlanSelect = (plan: any) => {
    let finded = {};
    if (plan?.monthly_cost || plan?.yearly_cost) {
      finded = this.state.subscriptionList.find((ele: any) => ele.attributes.billing_cycle === this.state.costType);
    } else {
      finded = this.state.subscriptionList.find((ele: any) => ele.attributes.name === plan.name);
    }
    this.props.navigation.navigate("SubscribeContent", {
      planData: finded
    })
  }
  fetchSubscriptionsLists = async () => {
    const token = await AsyncStorage.getItem("userToken")
    const header = {
      token: token,
      "Content-Type": configJSON.subscriptionApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getListCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSubscriptionAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSubscriptionAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getSubscriptionsList = async () => {

    let token = await AsyncStorage.getItem("userToken");
    const header = {
      token: token,
      "Content-Type": configJSON.subscriptionApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSubscriptionsListCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSubscriptionDetailAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSubscriptionAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  onPressCancelSubscription = async () => {
    this.setState({
      openModal: false
    });
    let token = await AsyncStorage.getItem("userToken");
    const header = {
      token: token,
      "Content-Type": configJSON.subscriptionApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.cancelSubscriptionCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteAPiUrl(this.state.cancelSubscriptionId)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  onselectSubscription = (item: any) => {

  }

  async getCardData() {
    this.setState({ openModal: false })
    let token = await AsyncStorage.getItem("userToken");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.cancelSubscriptionApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_custom_user_subs/subscription_plans/cancel_subscription");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  async createSubscription(data: { plan_id: string, subscription_type: string }) {
    let token = await AsyncStorage.getItem("userToken");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };
    const httpBody = data;
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apicreateSubscriptionCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_custom_user_subs/subscription_plans/subscribe");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  addSubscription = () => {

  };

  onClickNo = () => {
  };
  onNavigateToCardScreen() {
    this.setState({ cardModal: !this.state.cardModal });
    this.props.navigation.navigate('SavedCards')
  }

  onClickCardNo = () => {
    this.setState({ cardModal: !this.state.cardModal });
  };
  onokClick = () => {
    this.setState({ okModal: !this.state.okModal });
  };
  onClickCardOk = () => {
    this.setState({ cardModal: !this.state.cardModal });
    this.props.navigation.navigate('UserSubscription')
  };
  onClickCardOks = () => {
    this.setState({ cardModal: !this.state.cardModal });
  };

  onCancelOk = () => {
    this.setState({ cancelSuccessModal: false, cardModal: false });
  };
  deleteSubscription = () => {
    this.setState({
      modalDescription: "After this action, your plan will be changed to basic and you will loose some of the features",
      openModal: true
    });
  };


  getcurrentSubscription = async () => {
    this.setState({
      currentSubLoader: true
    })
    let token = await AsyncStorage.getItem("userToken");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getcurrentSubscriptionoCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCurrentSubscription
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSubscriptionAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  createOrUpdateBillingInformation = () => {
    if (this.state.showForm) {
      let billingData = {
        plan_id: this.state.selectedPlan?.id,
        subscription_type: this.state.selectedPlan?.attributes?.subscription_type,
        card: {
          name: this.state.cardHolderName,
          number: this.state.canrdNum.replace(/\s/g, ''),
          month: this.state.selectedMonth?.toString().padStart(2, '0'),
          year: this.state.selectedYear?.toString(),
          cvv: this.state.cvv,
          is_primary: false
        },
        billing_address: {
          address: this.state.address,
          city: this.state.city,
          state: "",
          country: this.state.selectedCountry,
          postal_code: this.state.zipCode,
          country_code: countries.find((country: { name: string, code: string }) => country?.name === this.state.selectedCountry)?.code,
          company_name: this.state.companyName,
          vat_number: this.state.VATnum
        },
        existing_card_id: "",
        card_exist: false,
        save_card_and_billing_address: this.state.checkbox
      }

      this.createSubscription(billingData);


    } else {

      let billingData = {
        plan_id: this.state.selectedPlan?.id,
        subscription_type: this.state.selectedPlan?.attributes?.subscription_type,
        card: {
          name: this.state.selectedAddress?.attributes?.name,
          number: this.state.selectedAddress?.attributes?.card_number,
          month: this.state.selectedAddress?.attributes?.month,
          year: this.state.selectedAddress?.attributes?.year,
          cvv: this.state.selectedAddress?.attributes?.cvv,
          is_primary: this.state.selectedAddress?.attributes?.is_primary
        },
        billing_address: {
          address: this.state.selectedAddress?.attributes?.address,
          city: this.state.selectedAddress?.attributes?.city,
          state: this.state.selectedAddress?.attributes?.state,
          country: this.state.selectedAddress?.attributes?.country,
          postal_code: this.state.selectedAddress?.attributes?.postal_code,
          country_code: this.state.selectedAddress?.attributes?.country_code,
          company_name: this.state.selectedAddress?.attributes?.company_name,
          vat_number: this.state.selectedAddress?.attributes?.vat_number
        },
        existing_card_id: "",
        card_exist: false,
        save_card_and_billing_address: this.state.checkbox
      }
      this.createSubscription(billingData);
    }


  }
  scrollToEnd = () => {
    this.scrollview_ref?.scrollToEnd({
      animated: true,
    });

  };
  textColor = () => {
    if (this.context.mode === "light") {
      return theme.light.textColor
    }
    return theme.dark.textColor
  }
  getColorTheme = (mode: any, color1: any, color2: any) => {
    return this.context.mode === mode ? color1 : color2
  }
  toggleFilter() {
    this.setState({ checkbox: !this.state.checkbox })
  }
  oncountrySelect(item: any) {
    this.setState({ searchCountryText: "", selectedCountry: item?.name })
  }
  getYears() {
    const currentYear: number = moment().year();
    let yearsArr: number[] = [];
    for (let year: number = currentYear; year < currentYear + 50; year++) {
      yearsArr.push(year);
    }
    return yearsArr;
  }
  async getCards() {
    let token = await AsyncStorage.getItem("userToken");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCardsApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_savedcards/cards");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  manageGetCardsResponse = (responseJson: any) => {

    if (responseJson?.data?.length > 0) {
      let tempArr = responseJson?.data.filter((item: any) => !item?.attributes?.is_primary) || [];
      if (responseJson?.data.find((item: any) => item?.attributes?.is_primary) != undefined)
        tempArr.unshift(responseJson?.data.find((item: any) => item?.attributes?.is_primary))
      this.setState({
        cardData: tempArr
      }, () => this.setState({ selectedAddress: this.state.cardData[0]?.attributes?.id }))
    } else {
      this.setState({ showForm: true })
    }
  }
  async removeBillingInformation() {
    let token = await AsyncStorage.getItem("userToken");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteBillingInformationApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_savedcards/cards/" + this.state.deleteaddressId);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "DELETE");
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  deleteAddressModal(id: any) {
    this.setState({
      deleteaddressId: id,
      currentSubLoader: false,
      deleteModal: true,
      modalDescription: "You have subscribed successfully"
    });
  }


  cityfocus = () => {
    this.setState({
      cityError: false
    })
  }
  zipValidation = () => {
    if (this.state.zipCode === "") {
      this.setState({
        zipError: true,
        zipErrorMsg: "Please enter Zip Code"
      });
    }
  };
  zipfocus = () => {
    this.setState({
      zipError: false
    })
  }

  countryfocus = () => {
    this.setState({
      countryError: false
    })
  }
  // 
  cardNumValidation = () => {
    let reg = /^\d{4}\s?\d{4}\s?\d{4}\s?\d{4}$/
    if (this.state.canrdNum === "") {
      this.setState({
        cardError: true,
        cardErrorMsg: "Please enter valid Card Number"
      });
    }
    if (!reg.test(this.state.canrdNum)) {
      this.setState({
        cardError: true,
        cardErrorMsg: "Please enter valid Card Number"
      });

    }
  };

  cardnameValidation = () => {
    if (this.state.cardHolderName === "") {
      this.setState({
        cardNameError: true,
        cardNameErrorMsg: "Please enter Name"
      });
    }
  };







  renderAllMonths = () => {
    const months = [];
    for (let month = 1; month <= 12; month++) {
      const label = month.toString().padStart(2, '0');
      months.push({ label, value: month });
    }

    return months;
  };
  generateMonths = () => {
    const { year } = this.state;
    const currentYearLastTwoDigits = new Date().getFullYear().toString();
    const currentMonth = new Date().getMonth() + 1;
    const months = [];
    for (let month = 1; month <= 12; month++) {

      if (parseInt(year) > parseInt(currentYearLastTwoDigits) || (parseInt(year) === parseInt(currentYearLastTwoDigits) && month >= currentMonth)) {
        const monthLabel = month < 10 ? `0${month}` : month.toString();
        months.push({ label: monthLabel, value: month });
      }

    }
    return months;
  };

  generateYears = () => {
    const years = [];
    const currentYear = new Date().getFullYear();
    for (let year = currentYear; year < currentYear + 50; year++) {
      years.push({ label: `${year.toString()}`, value: year.toString() });
    }
    return years;
  };
  addCardData = async () => {

    const { address, selectedCountry, selectedMonth, selectedYear, canrdNum, cvv, zipCode, cardHolderName, city } = this.state;


    let validCardNumber = this.isCreditCardValid(canrdNum);
    let isValidcvv = this.cvvValidation(cvv)
    if (this.state.showForm) {
      if (selectedYear && selectedMonth && address && selectedCountry && canrdNum && cvv && zipCode && cardHolderName && city && validCardNumber && isValidcvv) {
        this.createOrUpdateBillingInformation()
      }

    }
    else if (!this.state.selectedAddress?.attributes?.name) {
      this.setState({ okModal: true, modalDescription: "Please select address and card or Add new" })
    } else {
      this.createOrUpdateBillingInformation()
    }


  }

  handleSubscriptionResponse(responseJson: any) {

    this.getcurrentSubscription()

    if (responseJson.message) {
      this.setState({ successModal: true })

      if (responseJson.paypal_subscription && responseJson.paypal_subscription.status === 'ACTIVE')
        this.setState({ transactionId: responseJson.paypal_subscription.id });
    } else {
      if (responseJson.error) {

        this.setState({ failModal: true })
      }
    }

  }
  cvvValidation(cvv: any) {
    if (cvv.length === 0) {
      this.setState({ cvvError: true, cvvErrorMsg: configJSON.CVVErrorMessage })
      return false
    } else if (cvv.length != 3) {
      this.setState({ cvvError: true, cvvErrorMsg: configJSON.CVVInvalidMessage })
      return false
    } else {
      return true
    }
  }
  isCreditCardValid(cardNumberVal: any) {
    return this.validateCreditCard(cardNumberVal);
  }
  validateCreditCard(cardNum: any) {
    const cardNumber = cardNum.replace(/ /g, '');

    if (cardNumber === '') {
      this.setState({ cardNoLabel: configJSON.cardNoRequireMessage });
      this.setState({ cardError: true });
      return false;
    }

    if (cardNumber[0] === '0') {
      this.setState({ cardNoLabel: configJSON.cardNoValidationLabel2 });
      this.setState({ cardError: true });
      return false;
    }

    if (cardNumber.length !== 16) {
      this.setState({ cardNoLabel: configJSON.cardNoValidationLabel });
      this.setState({ cardError: true });
      return false;
    }

    if (!/^\d+$/.test(cardNumber)) {
      this.setState({ cardError: true });
      return false;
    }
    this.setState({ cardError: false });
    return true;
  }
  handleBackPress = () => {
    return true;
  };

  // Customizable Area End
}

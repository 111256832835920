import React from "react";
// Customizable Area Start
import  LoyaltySystemControllerWeb, { Props } from "./LoyaltySystemController.web";
import {
    withStyles, StyleRules
} from "@material-ui/core/styles";
import Loader from "../../../components/src/Loader.web";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tyopgraphy from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import LoginAlertDialogWeb from "../../../components/src/LoginAlertDialog.web";
import { isEmpty } from "../../../framework/src/Utilities";
import { LoyaltyHistory } from "../../../components/src/types";
// Customizable Area End

const styles: StyleRules = {
    // Customizable Area Start
    root: {
        padding: "20px 100px 50px 100px",
        '@media (max-width: 768px)': {
            padding: "20px 35px 20px 35px"
        }
    },
    table: {
        border: '1px solid #e7e7e7',
        borderCollapse: 'collapse'
    },
    balanceBlock: {
        backgroundColor: '#01a24e',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        color: '#ffffff'
    },
    inviteButton: {
        backgroundColor: '#01a24e',
        color: '#ffffff',
        cursor: 'pointer',
        padding: '5px 30px',
        margin: '0px 10px',
        border: 'none',
        borderRadius: '20px',
        textAlign: 'center',
        fontSize: '16px',
        textTransform: 'none',
        "&:hover": {
            backgroundColor: '#01a24e',
            color: '#ffffff',    
        }
    },
    redeemButton: {
        backgroundColor: '#ffffff',
        color: '#01a24e',
        cursor: 'pointer',
        margin: '30px 0 20px 0',
        border: 'none',
        borderRadius: '20px',
        textAlign: 'center',
        fontSize: '16px',
        textTransform: 'none'
    },
    fontBold: {
        fontWeight: 'bold'
    }
    // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export class LoyaltySystemWeb extends LoyaltySystemControllerWeb {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { token, loyaltyPoints, totalBalance, historyError, userProfile } = this.state;

        return (
            <Paper data-testid="loyaltySystemPaper" className={classes.root} elevation={0} square style={{ backgroundColor: localStorage.getItem('appTheme') == 'darkTheme' ? '#232429' : '#FFFFFF' }}>
                <Loader loading={this.state.loading} />
                {isEmpty(token) && <LoginAlertDialogWeb />}
                <Tyopgraphy variant="h5" className={classes.fontBold} style={{ margin: '20px 0 20px -12px'}}>
                    <ArrowBackIcon data-testid="backArrow" style={{ verticalAlign: 'middle' }} onClick={() => this.handleBack()} /> Your Rewards</Tyopgraphy>
                <Grid container spacing={2} style={{ border: '1px solid #999A9A'}}>
                    <Grid item sm={9} xs={12} style={{ backgroundColor: localStorage.getItem('appTheme') == 'darkTheme' ? '#232429' : '#EEFDF7' }}>
                        <Tyopgraphy variant="h6">{userProfile?.attributes.pending_referrals_count} Pending Referrals</Tyopgraphy>
                        <Tyopgraphy>{userProfile?.attributes.total_pending_referrals_points} Points awaiting</Tyopgraphy>
                        <Tyopgraphy variant="h6" style={{ marginTop: 40, marginBottom: 20 }}>Refer & Earn More Points
                        <Button data-testid="sendInviteBtn" variant="contained" className={classes.inviteButton} disableElevation onClick={() =>  this.handleNavigation('ReferralsWeb')}>Send Invite</Button></Tyopgraphy>
                    </Grid>
                    <Grid item sm={3} xs={12} className={classes.balanceBlock}>
                        <Tyopgraphy variant="h5">Total Balance</Tyopgraphy>
                        <Tyopgraphy data-testid="totalBalance">{totalBalance?.total_loyalty_points} Points</Tyopgraphy>
                        <Button className={classes.redeemButton} variant="contained" disableElevation>Redeem Points</Button>
                    </Grid>
                </Grid>
                <Tyopgraphy variant="h5" className={classes.fontBold} style={{ margin: '20px 0 20px -10px' }}>Points History</Tyopgraphy>
                <Grid container spacing={2}>
                    <Grid item sm={12} xs={12} style={{ width: "100%", padding: 0 }}>
                        <TableContainer elevation={0} component={Paper}>
                            <Table className={classes.table} aria-label="loyalty table">
                                <TableHead>
                                <TableRow>
                                    <TableCell className={classes.fontBold}>TRANSACTION DETAILS</TableCell>
                                    <TableCell className={classes.fontBold} align="center">DATE</TableCell>
                                    <TableCell className={classes.fontBold} align="center">REWARD POINTS</TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                    { !isEmpty(historyError) && 
                                    <TableRow>
                                        <TableCell colSpan={3}>
                                            <Tyopgraphy data-testid="noData" style={{ textAlign: 'center', fontSize: '18px', fontWeight: 'bold' }}>{ historyError }</Tyopgraphy>
                                        </TableCell>
                                    </TableRow>
                                    }
                                    { loyaltyPoints.map((point: LoyaltyHistory, loyaltyIdx: number ) => {
                                        return <TableRow data-testid={`points${loyaltyIdx}`} key={loyaltyIdx}>
                                                <TableCell className={classes.fontBold}>{point.use_case}</TableCell>
                                                <TableCell className={classes.fontBold} align="center">{point.date}</TableCell>
                                                <TableCell className={classes.fontBold} style={{ color: point.reward_points.toString().charAt(0) === '-' ? '#f44336' : '#01a24e' }} align="center">
                                                    {point.reward_points.toString().charAt(0) === '-' ? point.reward_points : `+${point.reward_points}`}
                                                </TableCell>
                                            </TableRow>
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Paper>

        )
        // Customizable Area End
    }
}

export default withStyles(styles)(LoyaltySystemWeb);

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { toast } from 'react-toastify';
import React from "react";
import moment from "moment";
import { countries, subscriptionPlanDetailList } from "../../../components/src/chartUtils.web";

interface BillingInfoProps {
    companyName: string;
    address:  string;
    city:  string;
    zipCode: string;
    country: string;
    vatNumber: string;
    cardNumber: string;
    cardHolderName: string;
    expiryMonth: string;
    expiryYear: string;
    cvv: string;
}
export interface SubscriptionPlan {
    id: string;
    type: string;
    attributes: SubscriptionPlanAttributes;
}
  
export interface SubscriptionPlanAttributes {
    name: string;
    price: string;
    description: string;
    billing_cycle: string | null;
    subscription_type: string;
    free_trial_period: number;
}

export interface CardDetails {
    id: string;
    type: string;
    attributes: CardDetailsAttributes;
}

export interface CardDetailsAttributes {
    id: number | string,
    name: string,
    card_number: string,
    year: number | string,
    month: number | string,
    cvv: string,
    is_primary: boolean,
    address: string,
    city: string,
    state: string,
    country: string,
    postal_code: string,
    country_code: string,
    company_name: string,
    vat_number: string,
    created_at: string,
    updated_at: string
}

export interface TableDataProps {
    id: number; 
    label: string; 
    features: string; 
    basic: string; 
    pro: string;
}
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: boolean;
    subscriptionDetails: any;
    token: string;
    selectedSubscription: any;
    tableData: Array<TableDataProps>;
    freeTrialData: any;
    steps: Array<string>;
    activeStep: number;
    expMonth: Array<string>;
    expYear: Array<string | number>;
    subscriptionPlans: Array<SubscriptionPlan>;
    subscriptionPlansDetail: any;
    billingCycle: string;
    userProfile: any;
    subscriptionStatus: string;
    cardDetails: Array<CardDetails>;
    saveCardAndBilling: boolean;
    addNew: boolean;
    selectedAddress: number| string | null;
    transactionId: string;
    showConfirmation: boolean;
    selectedInformationId: number | string | null
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class SubscribeWebController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    tableRef: React.RefObject<HTMLDivElement>;
    getSubscriptionApiCallId: string = "";
    createSubscriptionApiCallId: string="";
    getSubscriptionPlansApiCallId: string="";
    getSubscriptionPlanDetailApiCallId: string="";
    getProfileApiCallId: string="";
    getCardsApiCallId: string="";
    deleteBillingInformationApiCallId: string="";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            loading: false,
            token: localStorage.getItem('token') || "",
            subscriptionDetails: [],
            selectedSubscription: null,
            expMonth: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
            expYear: this.getYears(),
            tableData: subscriptionPlanDetailList,
            freeTrialData: [],
            activeStep: 0,
            steps: this.getSteps(),
            subscriptionPlans: [],
            subscriptionPlansDetail: null,
            billingCycle: "monthly",
            userProfile: null,
            subscriptionStatus: 'pending',
            cardDetails: [],
            saveCardAndBilling: false,
            addNew: false,
            selectedAddress: null,
            transactionId: '',
            showConfirmation: false,
            selectedInformationId: null
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        this.tableRef = React.createRef();
        // Customizable Area End
    }

    async receive(from: string, message: Message) {


        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            
            switch (apiRequestCallId) {
                case this.createSubscriptionApiCallId:
                    this.setState({ loading: false });
                    this.handleSubscriptionResponse(responseJson);
                    break;
                case this.getSubscriptionPlanDetailApiCallId:
                    this.setState({ loading: false, subscriptionPlansDetail: responseJson });
                    break;
                case this.getSubscriptionPlansApiCallId:
                    this.setState({ loading: false, subscriptionPlans: responseJson.data });
                    break;
                case this.getProfileApiCallId:
                    this.setState({ loading: false, userProfile: responseJson.data });
                    break;
                case this.getCardsApiCallId:
                    this.setState({ loading: false, cardDetails: responseJson.data, addNew: responseJson.data.length === 0 ,selectedAddress: responseJson.data[0]?.id });
                    break;
                case this.deleteBillingInformationApiCallId:
                    this.setState({ loading: false, showConfirmation: false });
                    toast.success("Billing details deleted successfully");
                    this.getCards();
                    break;
            }
    
            runEngine.debugLog("API Message Recived", message);
        }
        // Customizable Area End
    }
    async componentDidMount() {
        // Customizable Area Start
        this.getSubscriptionPlansDetail();
        if (this.state.token != "") {
            this.getSubscriptionPlans();
            this.getProfile();
            this.getCards();
        }
        // Customizable Area End
    }
    // Customizable Area Start
    scrollToTable = () => {
        if(this.tableRef.current){
            this.tableRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }

    handleSaveAddress() {
        this.setState({ saveCardAndBilling: !this.state.saveCardAndBilling });
    }

    handleExistingAddress(cardId: number | string) {
        this.setState({ selectedAddress: cardId });
    }
 
    handleSubscriptionResponse(responseJson: {message: string, error: string, paypal_subscription: {id: string, status: string}}) {
        let subscriptionStatus = this.state.subscriptionStatus;
        const { selectedSubscription, subscriptionPlansDetail } = this.state;
        if (responseJson.message) {
            toast.success(responseJson.message);
            selectedSubscription && localStorage.setItem('currentPlan', JSON.stringify(subscriptionPlansDetail[selectedSubscription.subscription_type]) )
            subscriptionStatus = 'success';
            if (responseJson.paypal_subscription && responseJson.paypal_subscription.status === 'ACTIVE')
                this.setState({ transactionId: responseJson.paypal_subscription.id });
        } else {
        if (responseJson.error) {
            toast.error(responseJson.error);
            subscriptionStatus = 'failure';
        }
        }
        this.setState({ activeStep: 2, subscriptionStatus })
     }
 
     handleBillingCycle = (event: React.MouseEvent<HTMLElement>, newBillingCycle: string) => {
         const { billingCycle } =  this.state;
         this.setState({ billingCycle: newBillingCycle || billingCycle });
     }
 
     joinTrialPlan() {
         this.scrollToTable();
         const { userProfile, subscriptionPlans } = this.state;
         const freeTrialPlan = subscriptionPlans.find((plan: SubscriptionPlan) => plan.attributes.subscription_type === 'free_trial');
         const subscriptionPlan = userProfile.attributes.subscription_plan?.data?.attributes;
        if (subscriptionPlan && subscriptionPlan.subscription_type === 'pro') {
            toast.error(`you have already subscribed to ${subscriptionPlan.billing_cycle} pro plan`);
            return;
        }
        if (subscriptionPlan && subscriptionPlan.subscription_type === 'free_trial') {
            toast.error(`you are already using free trial`);
            return;
        }
        if (!userProfile.attributes.has_taken_free_trial && freeTrialPlan) {
            this.setState({ activeStep: 1, selectedSubscription: { id: freeTrialPlan.id, ...freeTrialPlan.attributes } });
            this.scrollToTable();
         }
     }
 
     startBasicPlan() {
        this.navigateToScreen('LandingPageWeb');
     }
 
     startProPlan() {
        const { subscriptionPlans, userProfile, billingCycle } = this.state;
        const proPlan = subscriptionPlans.find((plan: SubscriptionPlan) => plan.attributes.subscription_type === 'pro' && plan.attributes.billing_cycle === billingCycle);
        const subscriptionPlan = userProfile.attributes.subscription_plan?.data?.attributes;
        if (subscriptionPlan && subscriptionPlan.subscription_type === 'pro') {
            toast.error(`you have already subscribed to ${subscriptionPlan.billing_cycle} pro plan`);
            return;
        }
        if (proPlan) {
            this.setState({ activeStep: 1, selectedSubscription: { id: proPlan.id, ...proPlan.attributes } });            this.scrollToTable();
        } else {
            toast.error('Pro plan is not exists');
        }
     }
 
     createSubscription(data: { plan_id: string, subscription_type: string}) {
         this.setState({ loading: true });
         const header = { "Content-Type": "application/json", token: this.state.token };
         const httpBody = data;
         const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
         this.createSubscriptionApiCallId = requestMessage.messageId;
         requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_custom_user_subs/subscription_plans/subscribe");
         requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
         requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
         requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
         runEngine.sendMessage(requestMessage.id, requestMessage);
     }
 
     getSteps () {
         return ['Choose Plan', 'Payment Details', 'Confirm & Pay'];
     }      
 
     getYears() {
         const currentYear: number = moment().year();
         let yearsArr: number[]= [];
         for (let year: number = currentYear; year < currentYear + 50; year++) {
             yearsArr.push(year);
         }
         return yearsArr;
     }
     
     getSubscriptionPlans() {
         this.setState({ loading: true });
         const header = { "Content-Type": "application/json", token: this.state.token };
         const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
         this.getSubscriptionPlansApiCallId = requestMessage.messageId;
         requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_custom_user_subs/subscription_plans");
         requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
         requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
         runEngine.sendMessage(requestMessage.id, requestMessage);
     }

    getProfile() {
        this.setState({ loading: true });
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getProfileApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "account_block/accounts/show");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
 
    getSubscriptionPlansDetail() {
         this.setState({ loading: true });
         const header = { token: this.state.token };
         const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
         this.getSubscriptionPlanDetailApiCallId = requestMessage.messageId;
         requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_custom_user_subs/subscriptions/subscription_plan_details");
         requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
         requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
         runEngine.sendMessage(requestMessage.id, requestMessage);
     }
  
    createOrUpdateBillingInformation = (values: BillingInfoProps) => {
        const { selectedSubscription, saveCardAndBilling, selectedAddress } = this.state;
        let billingData = {
            plan_id: selectedSubscription.id,
            subscription_type: selectedSubscription.subscription_type,
            card: {
                name: values.cardHolderName,
                number: values.cardNumber.replace(/\s/g, ''),
                month: values.expiryMonth.toString().padStart(2, '0'),
                year: values.expiryYear.toString(),
                cvv: values.cvv,
                is_primary: false
            },
            billing_address: {
                address: values.address,
                city: values.city,
                state: "",
                country: values.country,
                postal_code: values.zipCode,
                country_code: countries.find((country: {name: string, code: string}) => country.name === values.country )?.code,
                company_name: values.companyName,
                vat_number: values.vatNumber
            },    
            existing_card_id: "",
            card_exist: false,
            save_card_and_billing_address: saveCardAndBilling
        }
        if (selectedAddress) {
            billingData = {...billingData, existing_card_id: selectedAddress.toString(), card_exist: true }
        } 
        this.createSubscription(billingData);
    }

    removeBillingInformation() {
        this.setState({ loading: true });
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.deleteBillingInformationApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_savedcards/cards/" + this.state.selectedInformationId);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "DELETE");
        runEngine.sendMessage(requestMessage.id, requestMessage);
        
    }

    removeBillingInformationConfirmation(bllingId: number | string) {
        this.setState({ showConfirmation: true, selectedInformationId: bllingId });
    }

    handleClose() {
        this.setState({ showConfirmation: false });
    }

    handleTryAgain() {
        this.setState({ activeStep: 0 });
        this.componentDidMount();
    }

    getCards() {
        this.setState({ loading: true });
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getCardsApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_savedcards/cards");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    changePlan() {
      this.setState({ activeStep: 0 });
      this.scrollToTable();
    }

    navigateToScreen = (path: string) => {
      let toNavigate = new Message(getName(MessageEnum.NavigationMessage));
      toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), path);
      toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(toNavigate);
    }

    handleAddNew(addNew: boolean) {
        this.setState({ addNew, selectedAddress: addNew ? null : this.state.cardDetails[0]?.id });
    }

    getDiscount () {
        const { userProfile, selectedSubscription } = this.state;
        let discount: string = '0.0';
        if (userProfile.attributes.referred) {
            if (selectedSubscription.subscription_type === "pro") {
                discount = selectedSubscription.billing_cycle === 'monthly' ? selectedSubscription.monthly_discount : 
                selectedSubscription.yearly_discount;
            }
        }

        return parseFloat(discount).toFixed(1);
    }

    getTotalAmount () {
        const { userProfile, selectedSubscription } = this.state;
        let totalAmount: string = selectedSubscription.price;

        if (userProfile?.attributes?.referred) {
            if (selectedSubscription?.subscription_type === "pro") {
                totalAmount = selectedSubscription?.billing_cycle === 'monthly' ? selectedSubscription?.referred_monthly_price :
                    selectedSubscription?.referred_yearly_price;
            }
        }
        return parseFloat(totalAmount).toFixed(1);
    }

    getRegularPrice () {
        const { selectedSubscription } = this.state;
        const price = selectedSubscription.price;
        return parseFloat(price).toFixed(1);

    }
    // Customizable Area End
 }

import { StyleSheet, View, Text, Dimensions } from "react-native";
import React,{useContext} from 'react'
import SelectDropdown from 'react-native-select-dropdown';
import { COLORS } from "../../framework/src/Globals";
import CustomText from "./text";
import { CommonContext } from "../../framework/src/CommonContextProvider";
const { height, width } = Dimensions.get("screen");

const CustomDropDown = (props: any) => {
    const mode = useContext(CommonContext);
    const { data,value, ref, onSelect, defaultButtonText, pricecustom,buttonTextAfterSelection,
         rowTextForSelection, buttonStyle, buttonTextStyle, renderDropdownIcon } = props
         console.warn(defaultButtonText+"defaultButtonText"+JSON.stringify(mode))
    return (
        <SelectDropdown
        // defaultValue={value}
            data={data}
            ref={ref}
            onSelect={onSelect}
            defaultButtonText={defaultButtonText}
            buttonTextAfterSelection={buttonTextAfterSelection}
            rowTextForSelection={rowTextForSelection}
            buttonStyle={buttonStyle}
            buttonTextStyle={buttonTextStyle}
            renderDropdownIcon={renderDropdownIcon}
            dropdownIconPosition={"right"}
            dropdownStyle={styles.dropdownStyle}
            // @ts-ignore
            rowStyle={[styles.rowStyle,{backgroundColor: mode?.mode === 'light' ? 
            COLORS.white : "#3C3F50"}]}
            // @ts-ignore
            rowTextStyle={[styles.rowTextStyle,{color:mode?.mode === 'light' ? COLORS.black : "#fff"}]}
            renderCustomizedButtonChild={(selectedItem: any, index: any) => 
            <View style={{paddingLeft:8}} ><CustomText style={styles.selectedTxt}>
                {selectedItem ? pricecustom? selectedItem.name :selectedItem: defaultButtonText}</CustomText></View>
                }
            dropdownOverlayColor="transparent"
            {...props}
        />
    )
}

export default CustomDropDown

const styles = StyleSheet.create({
    dropdownStyle: { backgroundColor: "#EFEFEF", borderRadius: 10 },
    rowStyle: {
        borderWidth:0,
        paddingHorizontal: 15
    },
    rowTextStyle: { color: "#444", textAlign: "left", fontSize: 16, fontFamily: 'PlusJakartaText-Regular' },
    textStyle: {
        marginTop: 5,
        marginLeft: 15,
        color: 'gray'
    },
    selectedTxt: {
        fontSize: 16,
        fontFamily: 'PlusJakartaText-Regular',
        color: "#9BA39E"
    }
})
Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "RichTextEditor5";
exports.labelBodyText = "RichTextEditor5 Body";
exports.labelHeader = "News";
exports.btnExampleTitle = "CLICK ME";
exports.newsByTopic = topic =>
  `/bx_block_dashboard/news_by_topic?topic=${topic}`;
// Customizable Area End
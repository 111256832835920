import React from 'react';
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { cryptoLogo } from "../../email-account-login/src/assets";
import { toast } from 'react-toastify';
import './ToastProvider.web.css';
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: boolean;
    token: any;
    userProfile: any;
    editProfileFlag: boolean;
    subscriptionDetails: any;
    experience: any;
    selectedFile: any;
    confirmDeleteAccount: boolean;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class ProfileWebController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    fileUploadRef: any;
    getProfileApiCallId: string="";
    getSubscriptionApiCallId: string="";
    editProfileApiCallId: string="";
    cancelSubscriptionApiCallId: string="";
    deleteAccountApiCallId: string="";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // Customizable Area End
        this.fileUploadRef = React.createRef();
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];
        this.state = {
            // Customizable Area Start
            loading: false,
            token: localStorage.getItem('token') || "",
            userProfile: {},
            editProfileFlag: false,
            experience: [0, 1, 2, 3, 4, 5],
            selectedFile: null,
            subscriptionDetails: [],
            confirmDeleteAccount: false
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {

        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            runEngine.debugLog("API Message Recived", message);

            switch (apiRequestCallId) {
                case this.getProfileApiCallId:
                    this.setProfile(responseJson);
                    break;
                case this.editProfileApiCallId:
                    this.setEditProfile(responseJson);
                    break;
                case this.deleteAccountApiCallId:
                    if (responseJson.message) {
                        this.setState({ loading: false, confirmDeleteAccount: false });
                        toast.success(responseJson.message);
                        setTimeout(() => {
                            localStorage.clear();
                            window.location.href = '/';
                            localStorage.setItem('appTheme', 'ligthTheme');
                        }, 2000);
                    }
                    break;
                default:
                    console.log("default");
            }
        }
        // Customizable Area End
    }
    async componentDidMount() {
        // Customizable Area Start
        if (this.state.token != "") {
            this.getProfile();
        }
        // Customizable Area End
    }
    // Customizable Area Start
    setProfile(responseJson: any) {
        this.setState({ loading: false });
        if (responseJson.data) {
            this.setState({
                userProfile: responseJson.data
            }, () => {
                if (this.state.userProfile?.attributes?.image != null) {
                    this.urlToObject(this.state.userProfile?.attributes?.image);
                } else {
                    this.urlToObject(cryptoLogo);
                }
            })
        } else {
            if (responseJson.errors) {
                if (responseJson.errors[0].token) {
                    toast.error(responseJson.errors[0].token);
                }
            }
        }
    }

    setEditProfile(responseJson: any ) {
        this.setState({ loading: false, editProfileFlag: false });
        if (responseJson.data) {
            this.getProfile();
            toast.success("user profile updated successfully");
        } else {
            if (responseJson.errors) {
                toast.error(responseJson.errors[0]);
            } else {
                toast.error("user profile updating failed ");
            }
        }
    }

    confirmDeleteAccount = () => {
        this.setState({ confirmDeleteAccount: true });
    }

    fileChange(event: any) {
        const file = event.target.files[0];
        var userProfile = { ...this.state.userProfile }
        userProfile.attributes.image = URL.createObjectURL(event.target.files[0])
            this.setState({ userProfile: userProfile, selectedFile: file });
    }

    getProfile() {
        this.setState({ loading: true });
        const header = { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getProfileApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "account_block/accounts/show");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    editProfileForm = (values: any) => {
        console.log(values);
        this.setState({ loading: true });
        const formData = new FormData();
        formData.append('data[fullname]', values?.fullname);
        formData.append('data[experience]', values?.experience);
        formData.append('data[address]', values?.address);
        formData.append('data[image]', this.state.selectedFile);
        const header = { token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.editProfileApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "account_block/accounts/update");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    urlToObject = async (url: any) => {
        const response = await fetch(url);
        // here image is url/location of image
        const blob = await response.blob();
        const file = new File([blob], 'image.jpg', { type: blob.type });
        if (file != undefined && file != null) {
            this.setState({
                selectedFile: file
            }, () => {
                console.log(this.state.selectedFile);
                // debugger;
            });
        }
    }

    deleteAccount = () => {
        this.setState({ loading: true });
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.deleteAccountApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "account_block/delete_account");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "DELETE");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Customizable Area End
}

Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.getSubscriptionAPiEndPoint =
  "/bx_block_custom_user_subs/subscription_plans";
exports.getSubscriptionDetailAPiEndPoint =
  "/bx_block_custom_user_subs/subscriptions/subscription_plan_details";
  exports.createSubscriptionAPiEndPoint =
  "/bx_block_custom_user_subs/subscription_plans/subscribe";
exports.getSubscriptionAPiMethod = "GET";
exports.subscriptionApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "customisableusersubscriptions";
exports.labelBodyText = "customisableusersubscriptions Body";
exports.postAPiMethod = "POST";
exports.deleteAPiMethod = "DELETE";
exports.btnExampleTitle = "CLICK ME";
exports.subscriptions = "My Subscription";
exports.chooseSubscription = "Choose from the list of subscriptions";
exports.subscriptionDetailsScreenName = "SubscriptionDetails";
exports.goldCoinImageUrl =
  "https://akm-img-a-in.tosshub.com/sites/btmt/images/stories/goldcoin_660_102616075839.jpg";
exports.currencySymbol = "₹";
exports.zero = "0";
exports.subscribed = "Subscribed";
exports.subscribe = "Subscribe";
exports.subscriptionDetails = "Subscription Details";
exports.confirmSubscription = "Confirm Subscription";
exports.noSubscriptionAvailable = "No subscriptions available";
exports.cardDetailsUrl = "/bx_block_savedcards/cards";
exports.createSubscriptions = "/bx_block_custom_user_subs/user_subscriptions";
exports.getCurrentSubscription = "/account_block/accounts/show";
exports.cancelSubscription = "Are you sure you want to cancel your current plan?";
exports.noSubscription = "No Subscription";
exports.currentSubscription = "Current Subscription:";
exports.headTextSub="Hey there!";
exports.textFirstSub= "As one of our early members, we've got a special treat for you. How does 3 months of Kryptokia Pro for FREE sound?";
exports.textSecondSub= "Dive into premium research, get top-tier insights, and enjoy priority support - all on us. Just head over to the subscription section, pick Kryptokia Pro, and you're all set. No charges, we promise!";
exports.textThirdSub= "It's our little way of saying thanks for being awesome and joining us on this crypto journey.";
exports.textFourthSub= "Happy exploring!";
exports.textFourthSub= "Cheers,";
exports.textFifthSub= "The Kryptokia Team";
exports.exploreTxt = " Explore Now"
exports.deleteAPiUrl = id =>
  `/bx_block_custom_user_subs/user_subscriptions/${id}`;
exports.yearErrorMessage = "Year is required";
exports.CVVErrorMessage = "CVV is required";
exports.CVVInvalidMessage = "CVV is Invalid";
exports.monthErrorMessage = "Month is required";
exports.cardInvalidMessage = "Card number is invalid";
exports.cardNoRequireMessage = "Card number is required";
exports.cardNoValidationLabel="Minimum card number length is 16";
exports.cardNoValidationLabel2="Card number cannot start with zero";
// Customizable Area End

import React from "react";
// Customizable Area Start
import ForgotPasswordWebController, {
    Props
} from "./ForgotPasswordController.web";

import {
    withStyles, StyleRules
} from "@material-ui/core/styles";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import Loader from "../../../components/src/Loader.web";

import "./ForgotPassword.web.css";

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import { cryptoLogo } from "./assets";
// Customizable Area End

const styles: StyleRules = {
    // Customizable Area Start
    root: {
        height: '100vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: localStorage.getItem('appTheme') == 'ligthTheme' ? 'rgba(244,248,246,255)' : '#232429',
        fontFamily: 'Plus Jakarta Text'
    },
    resetPasswordContainer: {
        position: 'relative',
        backgroundColor: localStorage.getItem('appTheme') == 'ligthTheme' ? 'rgba(255,255,255,255)' : '#333742',
        boxShadow: '0 0 60px 5px rgba(0, 0, 0, 0.2)',
        width: '500px',
        padding: '35px',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '12px',
        flexDirection: 'column',
        display: 'flex',
        margin: '0px 10px'
    },
    resetPasswordimageContainer: {
        width: '85px',
        position: 'absolute',
        height: '85px',
        border: '8px solid #e7e7e7',
        overflow: 'hidden',
        top: 0,
        left: '50%',
        borderRadius: '50%',
        transform: 'translate(-50%, -50%)'
    },
    resetPasswordbody: {
        display: 'flex',
        margin: '10px',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    resetPasswordsubtitle: {
        fontWeight: 'bold',
        color: '#a1b0a9',
    },
    resetPasswordcustomInputGroup: {
        width: '100%',
        position: 'relative',
    },
    resetPasswordcustomInput: {
        padding: '4%',
        width: '85%',
        borderRadius: '15px',
        outline: 'none',
        color: '#8f92a1',
        margin: '2% 0%',
        border: 'none',
        paddingLeft: '38px',
        background: 'rgba(244,248,246,255)',
    },  
    customResetPasswordInput: {
        width: "73%",
        padding: "4%",
        margin: "3% 0%",
        borderRadius: "15px",
        background: "rgba(244,248,246,255)",
        color: "#8f92a1",
        border: "none",
        outline: "none",
        paddingLeft: "38px",
        paddingRight: "38px"
    },
    resetPasswordButton: {
        backgroundColor: 'rgba(1,162,78,255)',
        color: '#ffffff',
        cursor: 'pointer',
        padding: '8px 20px',
        border: 'none',
        borderRadius: '10px',
        fontFamily: 'Plus Jakarta Text',
        fontSize: '15px',
        fontWeight: 'bold',
        textAlign: 'center',
        width: '100%',
        display: 'block',
        margin: '2% auto'
    },
    error: {
        fontSize: '0.9vw',
        marginLeft: '3%',
        color: 'rgb(255, 20, 20)',
        fontWeight: 'bold',
        width: '201px'
    },
    resetVisibilityIcon: {
      height: '18px',
      width: '18px'
    }
    // Customizable Area End
}

// Customizable Area Start
const resetPasswordSchema = Yup.object().shape({
    old_password: Yup
        .string()
        .required('Temporary password is required.'),
    new_password: Yup
        .string()
        .min(8, 'Minimum Password length is 8.')
        .max(16, 'Maximum Password length is 16')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~#^()<>{}@$!%*?&])[A-Za-z\d~#^()<>{}@$!%*?&]{8,}$/,
            'Password must contain atleast a capital letter, a lowercase letter, a number and a special character.'
        )
        .required('Password is required.'),
    confirm_password: Yup
        .string()
        .min(8, 'Minimum Confirm Password length is 8.')
        .max(16, 'Maximum Confirm Password length is 16')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~#^()<>{}@$!%*?&])[A-Za-z\d~#^()<>{}@$!%*?&]{8,}$/,
            'Confirm Password must contain atleast a capital letter, a lowercase letter, a number and a special character.'
        )
        .required('Confirm Password is required.'),
});
// Customizable Area End
export class ResetPasswordWeb extends ForgotPasswordWebController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                {this.state.loading && <Loader loading={this.state.loading} />}
                <div className={classes.resetPasswordContainer}>
                    <Button
                        variant="contained"
                        data-testid="back-btn-to-forget"
                        style={{ background: 'rgba(1,162,78,255)', color: '#ffffff', textTransform: 'none', marginBottom: '10px', top: '15px', left: '15px', position: 'absolute' }}
                        onClick={() => window.history.back()}
                    > <ArrowBackIcon />
                    </Button>
                    <div className={classes.resetPasswordimageContainer}>
                        <img src={cryptoLogo} alt="profile-image" style={{ width: '100%', height: '100%' }} />
                    </div>
                    <div className={classes.resetPasswordbody}>
                        <span style={{ fontSize: '22px', fontWeight: 'bold', padding: '10px', whiteSpace: 'nowrap' }}>Reset Password</span>
                        <span className={classes.resetPasswordsubtitle}>Please create your new password !</span>
                    </div>
                    <Formik
                        initialValues={{ old_password: '', new_password: '', confirm_password: '' }}
                        validationSchema={resetPasswordSchema}
                        onSubmit={(values) => {
                            console.log(values);
                            this.resetPasswordClick(values);
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue,
                        }) => (
                            <Form onSubmit={handleSubmit} >
                                <div>
                                    <div className={classes.resetPasswordcustomInputGroup}>
                                        <span
                                            className={'left-icon password-icon'}
                                        />
                                        <Field
                                            name="old_password"
                                            type={'text'}
                                            id="old_password"
                                            placeholder='Enter temporary password'
                                            className={classes.resetPasswordcustomInput}
                                        />
                                    </div>
                                    {/* Applies the proper error message from validateSchema when the user has
                          clicked the element and there is an error, also applies the .error-message
                          CSS class for styling */}
                                    {touched.old_password && errors.old_password ? (
                                        <div className={classes.error}>{errors.old_password}</div>
                                    ) : null}
                                </div>
                                <div>
                                    <div className={classes.resetPasswordcustomInputGroup}>
                                        <span
                                            className={'left-icon password-icon'}
                                        />
                                        <Field
                                            name="new_password"
                                            type={this.state.showPass ? 'text' : 'password'}
                                            id="new_password"
                                            placeholder='Choose new password'
                                            className={classes.customResetPasswordInput}
                                        />
                                        <span
                                            className="right-icon"
                                            onClick={() => this.togglePassword()}
                                            data-testid="togglePassword">
                                            {this.state.showPass ? <VisibilityOffIcon className={classes.resetVisibilityIcon} /> : <VisibilityIcon className={classes.resetVisibilityIcon} />}
                                        </span>
                                    </div>
                                    {/* Applies the proper error message from validateSchema when the user has
                          clicked the element and there is an error, also applies the .error-message
                          CSS class for styling */}
                                    {values.new_password || touched.new_password && errors.new_password ? (
                                        <div className={classes.error}>{errors.new_password}</div>
                                    ) : null}
                                </div>
                                <div>
                                    <div className={classes.resetPasswordcustomInputGroup}>
                                        <span
                                            className={'left-icon password-icon'}
                                        />
                                        <Field
                                            name="confirm_password"
                                            type={this.state.showConfirmPass ? 'text' : 'password'}
                                            id="confirm_password"
                                            placeholder='Confirm password'
                                            className={classes.customResetPasswordInput}
                                        />
                                        <span
                                            className="right-icon"
                                            onClick={() => this.toggleConfirmPassword()}
                                            data-testid="toggleConfirmPassword">
                                            {this.state.showConfirmPass ? <VisibilityOffIcon className={classes.resetVisibilityIcon} /> : <VisibilityIcon className={classes.resetVisibilityIcon} />}
                                        </span>
                                    </div>
                                    {/* Applies the proper error message from validateSchema when the user has
                          clicked the element and there is an error, also applies the .error-message
                          CSS class for styling */}
                                    {values.confirm_password || touched.confirm_password && errors.confirm_password ? (
                                        <div className={classes.error}>{errors.confirm_password}</div>
                                    ) : null}
                                </div>
                                <button type="submit" className={classes.resetPasswordButton}>Reset Password</button>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        )
        // Customizable Area End
    }
}

export default withStyles(styles)(ResetPasswordWeb);

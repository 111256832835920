export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const protfolio = require("../assets/protfolio.png");
export const logo = require("../assets/logo.png");
export const profile = require("../assets/profile.png");
export const manageCards = require("../assets/manageCards.png");
export const contactUs = require("../assets/contactUs.png");
export const watchList = require("../assets/watchList.png");
export const orderSummery = require("../assets/orderSummery.png");
export const userIcon = require("../assets/userIcon.png");
export const mySubscription = require("../assets/mySubscription.png");
export const logOut = require("../assets/logout.png");
export const EdiCenter=require('../assets/EduCentre.png');
export const News=require('../assets/News.png');
export const Screener=require('../assets/Screener.png');
export const Subscribe=require('../assets/Subscribe.png')
export const modeIcon=require('../assets/modeIcon.png')
export const referral=require('../assets/ReferIcon.png')
export const loyalty=require('../assets/LoyaltyRewards.png')






import React, { Component } from "react";
import { createChart, isBusinessDay } from 'lightweight-charts';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Box from '@material-ui/core/Box';
import { withStyles, StyleRules } from "@material-ui/core/styles";
import Loader from "./Loader.web";
interface Props {
    classes: any;
    selectedData: any;
    chartPriceData: any;
    chartVolumeData: any;
    chartMarketCapData: any;
    getGraphData: any;
    loading: boolean;
    showHighLowPoints?: any;
    datapoints?: any;
}
interface S {
    token: any;
    loading: boolean;
    chartTimeFrame: any;
    chartType: any;
    isChartToolTipVisible: any;
    chartToolTipLeft: any;
    chartToolTipTop: any;
    tooltipPrice: any;
    tooltipDate: any;
    tooltipTime: any;
    watchlistCrypto: any;
    currentSelectedWatchlistCryproData: any;
    watchlistAreaSeries: any;
    watchlistLineSeries: any;
    watchlistVolumeSeries: any;
    darkMode: any

}

const styles: StyleRules = {
    paper: {
        border: '2px solid #0000001f',
        position: 'relative',
        height: 450
    },
    '@media (max-width: 550px)': {
        paper: {
            height: 530
        }
    },
    '@media (max-width: 450px)': {
        paper: {
            height: 670
        },
    },
    '@media (max-width: 375px)': {
        paper: {
            height: 710
        },
    },
}

export const ToggleTimeFrame = ({ value, handleChange }: any) => {
    return (
        <ToggleButtonGroup style={{ flexWrap: 'wrap' }} size="small" value={value} exclusive onChange={handleChange} >
            {
                ['1D', '7D', '1M', '3M', '1Y', 'YTD', 'ALL'].map((item) => (
                    <ToggleButton style={{ marginTop: '5px' }} value={item} key={item}>
                        <Typography>
                            {item}
                        </Typography>
                    </ToggleButton>
                ))
            }
        </ToggleButtonGroup>

    )
}

class CryptoChart extends Component<Props, S> {
    watchlistChartRef: any;
    watchlistChartConatinerRef: any;
    watchlistGraphsApiCallId: any;

    constructor(props: Props) {
        super(props);
        this.watchlistChartRef = React.createRef();
        this.watchlistChartConatinerRef = React.createRef();
        this.state = {
            token: localStorage.getItem('token') || "",
            loading: false,
            chartTimeFrame: '',
            chartType: '',
            isChartToolTipVisible: false,
            chartToolTipLeft: 12,
            chartToolTipTop: 12,
            tooltipPrice: '',
            tooltipDate: '',
            tooltipTime: '',
            watchlistCrypto: [],
            currentSelectedWatchlistCryproData: '',
            watchlistAreaSeries: null,
            watchlistLineSeries: null,
            watchlistVolumeSeries: null,
            darkMode: localStorage.getItem('appTheme') || ''
        };

    }

    resizeChart = () => {
        const chartContainer = this.watchlistChartConatinerRef.current;
        const width = chartContainer.clientWidth;
        const height = chartContainer.clientHeight;

        if (this.watchlistChartRef.current) {
            this.watchlistChartRef.current.applyOptions({
                width,
                height,
            });
            this.watchlistChartRef.current.timeScale().fitContent();
        }
    };

    async componentDidMount() {
        this.setState({
            chartTimeFrame: '1D',
            chartType: 'Price',
        })
        this.props.getGraphData(this.props.selectedData.id, "1D")
        window.addEventListener('resize', this.resizeChart);
    }

    async componentWillUnmount() {
        window.removeEventListener('resize', this.resizeChart);
    }

    handleUpdateChartType = (event: any, newAlignment: any) => {
        if (newAlignment !== this.state.chartType) {
            this.setState({
                chartType: newAlignment || this.state.chartType
            })
        }
    }

    handleUpdateTimeFramme = (event: any, newAlignment: any) => {
        if (newAlignment !== this.state.chartTimeFrame) {
            this.setState({
                chartTimeFrame: newAlignment || this.state.chartTimeFrame
            })
        }
    }

    async componentDidUpdate(prevProps: any, prevState: any) {
        if (localStorage.getItem('appTheme') !== prevState.darkMode) {

            this.setState({
                darkMode: localStorage.getItem('appTheme')
            })

            this.watchlistChartRef.current.applyOptions({
                layout: {
                    backgroundColor: this.state.darkMode == 'ligthTheme' ? '#FFFFFF' : '#333742',
                    textColor: this.state.darkMode == 'ligthTheme' ? '#191919' : '#b4c1bb',
                },
                grid: {
                    horzLines: {
                        color: this.state.darkMode == 'ligthTheme' ? '#eee' : ''
                    },
                    vertLines: {
                        color: this.state.darkMode == 'ligthTheme' ? '#ffffff' : ''
                    }
                },
            });
        }

        if (
            this.props.selectedData !== '' &&
            (
                prevState.chartTimeFrame !== this.state.chartTimeFrame ||
                prevProps.selectedData.id !== this.props.selectedData?.id
            )) {
            this.props.getGraphData(this.props.selectedData.id, this.state.chartTimeFrame)
        }

        if (
            prevProps.chartPriceData !== this.props.chartPriceData ||
            prevProps.chartVolumeData !== this.props.chartVolumeData ||
            prevState.chartType !== this.state.chartType
        ) {
            // Clear the price data before get new
            if (this.state.watchlistAreaSeries) {
                this.watchlistChartRef.current.removeSeries(this.state.watchlistAreaSeries)
                this.setState({
                    watchlistAreaSeries: null,
                })
            }

            // Clear the volume data before get new
            if (this.state.watchlistVolumeSeries) {
                this.watchlistChartRef.current.removeSeries(this.state.watchlistVolumeSeries)
                this.setState({
                    watchlistVolumeSeries: null,
                })
            }

            // Clear the market cap data before get new
            if (this.state.watchlistLineSeries) {
                this.watchlistChartRef.current.removeSeries(this.state.watchlistLineSeries)
                this.setState({
                    watchlistLineSeries: null,
                })
            }
            // Creating chart
            if (this.watchlistChartConatinerRef?.current && !this.watchlistChartConatinerRef?.current?.childElementCount) {
                this.watchlistChartRef.current = createChart(this.watchlistChartConatinerRef.current, {
                    width: this.watchlistChartConatinerRef.current.clientWidth,
                    height: this.watchlistChartConatinerRef.current.clientHeight,
                    leftPriceScale: {
                        scaleMargins: {
                            top: 0.2,
                            bottom: 0.2,
                        },
                        visible: true,
                        borderVisible: false,
                    },
                    rightPriceScale: {
                        visible: false,
                    },
                    layout: {
                        backgroundColor: localStorage.getItem('appTheme') == 'ligthTheme' ? '#FFFFFF' : '#333742',
                        textColor: localStorage.getItem('appTheme') == 'ligthTheme' ? '#191919' : '#b4c1bb',
                    },
                    watermark: {
                        visible: true,
                        fontSize: 20,
                        horzAlign: 'right',
                        vertAlign: 'top',
                        color: '#b4c1bb',
                        text: 'Kryptokia',
                    },
                    grid: {
                        horzLines: {
                            color: localStorage.getItem('appTheme') == 'ligthTheme' ? '#eee' : ''
                        },
                        vertLines: {
                            color: localStorage.getItem('appTheme') == 'ligthTheme' ? '#ffffff' : ''
                        }
                    },
                    timeScale: {
                        timeVisible: true,
                        secondsVisible: false,
                        borderVisible: false
                    },
                })
            }

            // Adding price data to chart
            if (this.state.chartType === 'Price' && this.props.chartPriceData.length > 0) {
                let areaSeries = this.watchlistChartRef.current.addBaselineSeries({
                    baseValue: { type: 'price', price: this.props.chartPriceData[0].value },
                    topLineColor: 'rgba( 38, 166, 154, 1)',
                    topFillColor1: 'rgba( 38, 166, 154, 0.28)',
                    topFillColor2: 'rgba( 38, 166, 154, 0.05)',
                    bottomLineColor: 'rgba( 239, 83, 80, 1)',
                    bottomFillColor1: 'rgba( 239, 83, 80, 0.05)',
                    bottomFillColor2: 'rgba( 239, 83, 80, 0.28)'
                });

                this.setState({
                    watchlistAreaSeries: areaSeries,
                }, () => {
                    this.state.watchlistAreaSeries.setData(this.props.chartPriceData);
                })
                this.watchlistChartRef.current.timeScale().fitContent()

                // Highlight the high and low prices
                if (this.props.showHighLowPoints && this.props.datapoints?.points) {
                    const parseDateString = (dateString: string) => {
                        const dateParts = dateString.split('-');
                        if (dateParts.length === 3) {
                            const day = parseInt(dateParts[0]);
                            const month = parseMonth(dateParts[1]);
                            const year = 2000 + parseInt(dateParts[2]); // Assuming 2000s for years like '23'

                            return { year, month, day };
                        }
                        return null; // Handle invalid date format
                    }

                    const parseMonth = (monthString: string) => {
                        const months: Record<string, number> = {
                            'Jan': 1, 'Feb': 2, 'Mar': 3, 'Apr': 4,
                            'May': 5, 'Jun': 6, 'Jul': 7, 'Aug': 8,
                            'Sep': 9, 'Oct': 10, 'Nov': 11, 'Dec': 12
                        };
                        return months[monthString];
                    }

                    const top_first_timestamp = parseDateString(this.props.datapoints?.points.top_first_timestamp || this.props.datapoints?.points.upper_first_timestamp || this.props.datapoints?.points.first_high_timestamp)
                    const top_last_timestamp = parseDateString(this.props.datapoints?.points.top_last_timestamp || this.props.datapoints?.points.upper_last_timestamp || this.props.datapoints?.points.first_low_timestamp || this.props.datapoints?.points.third_low_timestamp)
                    const bottom_first_timestamp = parseDateString(this.props.datapoints?.points.bottom_first_timestamp || this.props.datapoints?.points.lower_first_timestamp || this.props.datapoints?.points.second_high_timestamp)
                    const bottom_last_timestamp = parseDateString(this.props.datapoints?.points.bottom_last_timestamp || this.props.datapoints?.points.lower_last_timestamp || this.props.datapoints?.points.second_low_timestamp)

                    areaSeries.setMarkers([
                        { time: top_first_timestamp, position: 'aboveBar', shape: 'circle', color: 'rgba( 38, 166, 154, 1)', text: 'Top First' },
                        { time: top_last_timestamp, position: 'belowBar', shape: 'circle', color: 'rgba( 239, 83, 80, 1)', text: 'Top Last' },
                        { time: bottom_first_timestamp, position: 'aboveBar', shape: 'circle', color: 'rgba( 38, 166, 154, 1)', text: 'Bottom First' },
                        { time: bottom_last_timestamp, position: 'belowBar', shape: 'circle', color: 'rgba( 239, 83, 80, 1)', text: 'Bottom Last' },
                    ]);
                } else if (this.props.showHighLowPoints && !this.props.datapoints.points) {
                    if (this.state.chartTimeFrame == '1D') {
                        areaSeries.createPriceLine({
                            price: this.props.datapoints.one_day.high,
                            color: 'green', // Color for the high price line
                            lineWidth: 2,
                        });

                        areaSeries.createPriceLine({
                            price: this.props.datapoints.one_day.low,
                            color: 'red', // Color for the low price line
                            lineWidth: 2,
                        });
                    } else if (this.state.chartTimeFrame == '1Y') {
                        areaSeries.createPriceLine({
                            price: this.props.datapoints.one_year.high,
                            color: 'green', // Color for the high price line
                            lineWidth: 2,
                        });

                        areaSeries.createPriceLine({
                            price: this.props.datapoints.one_year.low,
                            color: 'red', // Color for the low price line
                            lineWidth: 2,
                        });
                    }
                }
            }

            // Adding market cap data to chart
            if (this.state.chartType === 'Market Cap') {
                let lineSeries = this.watchlistChartRef.current.addLineSeries({ color: '#3861FB' });
                this.setState({
                    watchlistLineSeries: lineSeries,
                }, () => this.state.watchlistLineSeries.setData(this.props.chartMarketCapData))
            }

            // Adding volume data to chart
            if (this.watchlistChartRef.current) {
                let volumeSeries = this.watchlistChartRef.current.addHistogramSeries({
                    color: '#bec1cf',
                    lineWidth: 2,
                    priceFormat: {
                        type: 'volume',
                    },
                    overlay: true,
                    scaleMargins: {
                        top: 0.8,
                        bottom: 0,
                    },
                });
                this.setState({
                    watchlistVolumeSeries: volumeSeries,
                }, () => this.state.watchlistVolumeSeries.setData(this.props.chartVolumeData))
            }
            // Tool Tip
            let toolTipWidth = 180;
            let toolTipHeight = 80;
            let toolTipMargin = 15;

            if (this.watchlistChartRef.current) {
                this.watchlistChartRef.current.subscribeCrosshairMove((param: any) => {
                    if (!param.time || param.point.x < 0 || param.point.x > 800 || param.point.y < 0 || param.point.y > 400) {
                        this.setState({
                            isChartToolTipVisible: false,
                        })
                        return;
                    }

                    let dateStr = isBusinessDay(param.time)
                        ? (param.time.year + '-' + param.time.month + '-' + param.time.day)
                        : new Date(param.time * 1000).toLocaleDateString();

                    let timeStr = isBusinessDay(param.time)
                        ? (param.time.year + '-' + param.time.month + '-' + param.time.day)
                        : new Date(param.time * 1000).toLocaleTimeString();

                    let price = param.seriesPrices.get(this.state.watchlistAreaSeries)

                    this.setState({
                        isChartToolTipVisible: true,
                        tooltipDate: dateStr,
                        tooltipTime: timeStr,
                        tooltipPrice: price?.toFixed(6)
                    })

                    var y = param.point.y;

                    var left = param.point.x + toolTipMargin;
                    if (left > 800 - toolTipWidth) {
                        left = param.point.x - toolTipMargin - toolTipWidth;
                    }

                    var top = y + toolTipMargin;
                    if (top > 400 - toolTipHeight) {
                        top = y - toolTipHeight - toolTipMargin;
                    }
                    this.setState({
                        chartToolTipTop: top,
                        chartToolTipLeft: left,
                    })
                });
            }
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <Paper square variant="outlined" className={classes.paper}>
                <Box m={2} style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                    <Box style={{ marginBottom: '8px' }} >
                        <ToggleButtonGroup size="small" value={this.state.chartType} exclusive onChange={this.handleUpdateChartType} >
                            {
                                ['Price', 'Market Cap'].map((item) => (
                                    <ToggleButton value={item}>
                                        <Typography>
                                            {item}
                                        </Typography>
                                    </ToggleButton>
                                ))
                            }
                        </ToggleButtonGroup>
                    </Box>
                    <Box style={{ marginBottom: '8px' }} >
                        <ToggleTimeFrame value={this.state.chartTimeFrame} handleChange={this.handleUpdateTimeFramme} />
                    </Box>
                </Box>
                {this.props.loading ? <Loader loading={this.props.loading} /> : <div style={{ height: '75%', width: '100%' }} ref={this.watchlistChartConatinerRef} >
                </div>}
                {this.state.isChartToolTipVisible &&
                    <div className={classes.floatingTooltip} style={{ top: `${this.state.chartToolTipTop}px`, left: `${this.state.chartToolTipLeft}px` }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <Typography style={{ fontSize: 12, fontWeight: 'bold' }}>{this.state.tooltipDate}</Typography>
                            <Typography style={{ fontSize: 11, color: '#657982' }}>{this.state.tooltipTime}</Typography>
                        </div>
                        <div style={{ display: "flex", flexDirection: 'column', marginTop: 8 }}>
                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography style={{ fontSize: 12, color: '#657982', marginRight: 10 }}>Price:</Typography>
                                <Typography style={{ fontSize: 12, fontWeight: 'bold' }}>{this.state.tooltipPrice}</Typography>
                            </Box>
                        </div>
                    </div>
                }
            </Paper>
        )
    }

}

export default withStyles(styles)(CryptoChart);
import React from "react";
// Customizable Area Start
import TermsAndConditionController, {
    Props
} from "./TermsAndConditionController.web";

import {
    withStyles, StyleRules
} from "@material-ui/core/styles";

import Paper from '@material-ui/core/Paper';
import Grid from "@material-ui/core/Grid";
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import Button from '@material-ui/core/Button';

import Typography from '@material-ui/core/Typography';
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

const styles: StyleRules = {
    // Customizable Area Start
    root: {
        padding: "10px 22px 0px",
    },
    containor: {
        padding: "30px 50px 10px",
        '@media (max-width: 600px)': {
            padding: '10px'
        }
    },
    mainTitle: {
        fontWeight: 'bold',
        fontSize: '24px',
        display: 'flex',
        justifyContent: 'center'
    },
    title: {
        fontFamily: 'Plus Jakarta Text',
        fontWeight: 'bold',
        display: 'flex',
        width: '100%'
    },
    description: {
        fontFamily: 'Plus Jakarta Text',
        whiteSpace: 'break-spaces',
        fontSize: '16px',
        wordBreak: 'break-all',
    }
    // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export class TermsAndConditionWeb extends TermsAndConditionController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        return (
            <Paper className={classes.root} elevation={0} square data-testid='subscriptionPaper' style={{ backgroundColor: localStorage.getItem('appTheme') == 'ligthTheme' ? '#F4F8F6' : '#232429' }}>
                <Loader loading={this.state.loading} />
                <Paper className={classes.containor}>
                    <Button
                        variant="contained"
                        data-testid="terms-btn"
                        startIcon={<ArrowLeftIcon />}
                        style={{ background: 'rgba(1,162,78,255)', color: '#ffffff', textTransform: 'none', marginBottom: '10px' }}
                        onClick={() => window.location.href = "/"}
                    >
                        Back
                    </Button>
                    <Typography variant="body1" className={classes.mainTitle}>
                        {`Terms of Services`}
                    </Typography>
                    {this.state.termsAndCondition.map((_data: any, index: any) =>
                        <Grid container key={index} item xs={12} >
                            <h3 className={classes.title} >
                                {`${index+1}. ${_data.attributes.title}`}
                            </h3>
                            <pre className={classes.description} >
                                {_data.attributes?.description}
                            </pre>
                        </Grid>
                    )}
                </Paper>
            </Paper>
        )
        // Customizable Area End
    }
}

export default withStyles(styles)(TermsAndConditionWeb);

import React from "react";
// Customizable Area Start
import EducationCenterWebController, {
    Props
} from "./EducationCenterController.web";

import {
    withStyles, StyleRules
} from "@material-ui/core/styles";


import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import CardMedia from '@material-ui/core/CardMedia';
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import AddIcon from '@material-ui/icons/Add';
import Loader from "../../../components/src/Loader.web";
import RemoveIcon from '@material-ui/icons/Remove';
export const configJSON = require("./config")
import { eduBackgroundImage } from './assets';
// Customizable Area End

const styles: StyleRules = {
    // Customizable Area Start
    subRoot: {
        margin: '00px 22px',
        '@media (max-width: 450px)': {
            margin: '10px'
        }
    },
    leftContainer: {
        flexGrow: 1,
        margin: '8px',
        padding: '8px',
        borderRadius: '12px'
    },
    rightContainer: {
        flexGrow: 1,
        margin: '8px',
        borderRadius: '12px'
    },
    textContent: {
        fontFamily: 'Plus Jakarta Text',
        fontSize: '0.9em',
        textAlign: 'justify'
    },
    invalidLink: {
        display: 'flex',
        justifyContent: 'center',
        color: 'red',
        paddingBottom: '20px'
    }
    // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
export class EducationCenterWeb extends EducationCenterWebController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    hasChildren = (item: any) => {
        const { children, nodeType } = item?.attributes;

        if (children === undefined || children === null) {
            return false;
        }

        if (children.constructor !== Array) {
            return false;
        }

        if (children.length === 0 && nodeType != "root") {
            return false;
        }

        return true;
    }

    CustomMenuItem = ({ item, key }: any) => {
        let data = item.data ? item.data : item;
        return data.attributes != undefined && this.hasChildren(data) ? this.MultiLevel(data, key) : this.SingleLevel(data, key);
    };

    SingleLevel = (item: any, key: any) => {
        const { activeItem } = this.state;
        console.log(item, "item==========");
        let title = "";
        if (item.title)
            title = item?.title;
        else
            title = item?.attributes?.title
        return (
            <ListItem
                button
                disabled={item?.attributes?.disabled}
                key={key}
                data-testid={`listitem${item.id}`}
                selected={title === activeItem}
                onClick={(event: any) => {
                    this.setState({
                        activeItem: event.target.innerText,
                        selectedItemData: {
                            id: item.id ? item.id : item.attributes.id
                        }
                    }, () => {
                        this.getSelectedTopicDetails();
                    });
                }}
            >
                <ListItemText
                    primary={title}
                    primaryTypographyProps={{ style: { fontSize: '13px', color: activeItem == title ? '#6bc897' : '', marginLeft: '12px' } }}
                />
            </ListItem>
        );
    };

    MultiLevel = (item: any, key: any) => {
        const { children } = item?.attributes;
        const { educationData } = this.state;

        const handleClick = (event: any) => {
            let obj = findObject(educationData, item, item?.attributes?.title);
            this.setState({
                activeItem: event.target.innerText,
                selectedItemData: item
            }, () => {
            });
        };

        const findObject: any = (arr: any, currentObj: any, id: any) => {

            let result: any;
            for (const item of arr) {
                if (item.data) {
                    if (item?.data?.attributes?.title === id) {
                        item.data.attributes.isActive = !item?.data?.attributes?.isActive;
                        result = item;
                        break;
                    }
                } else if (item?.attributes?.title === id) {
                    item.attributes.isActive = !item?.attributes?.isActive;
                    result = item;
                    break;
                }
                if (item?.data?.attributes?.children) {
                    result = findObject(item?.data?.attributes?.children, currentObj, id);
                    if (result) break;
                }
            }
            this.setState((preState) => ({
                educationData: arr
            }), () => {
            })
            return result;
        }

        return (
            <React.Fragment>
                <ListItem key={key} disabled={item.attributes.disabled} data-testid={`multilevel${item.id}`} button onClick={handleClick}>
                    <ListItemText
                        primary={item.attributes.nodeType == 'root' ? `${key + 1}. ${item?.attributes?.title}` : item?.attributes?.title}
                        primaryTypographyProps={{ style: { fontWeight: item.attributes.nodeType == 'root' ? "bold" : "normal", color: item.attributes.nodeType == 'folder' ? '#000' : '', marginLeft: item.attributes.nodeType == 'folder' ? '12px' : '0px', fontSize: item.attributes.nodeType == 'folder' ? '13px' : '16px' } }}
                    />
                    {(() => {
                        switch (item.attributes.nodeType) {
                            case "root":
                                return item.attributes.isActive ? <RemoveIcon /> : <AddIcon />;
                            case "folder":
                                return item.attributes.isActive ? <ExpandLess /> : <ExpandMore />;
                        }
                    })()}
                </ListItem>
                <Collapse in={(() => {
                    switch (item.attributes.nodeType) {
                        case "root":
                            return item.attributes.isActive ? true : false;
                        case "folder":
                            return item.attributes.isActive ? true : false;
                    }
                })()} timeout="auto" unmountOnExit>
                    <List key={key} component="nav" disablePadding>
                        {children.map((child: any, key: any) => (
                            this.CustomMenuItem({ item: child, key: key })
                        ))}
                    </List>
                </Collapse>
            </React.Fragment>
        );
    };

    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { educationData, selectedItemData } = this.state;
        const removeTagsAndStyles = (inputString: any) => {
            const sanitizedString = inputString.replace(configJSON.removeTagsAndStylesRegex, "");
            return sanitizedString;
        };
        const sanitizedString = removeTagsAndStyles(selectedItemData?.attributes?.description || "");

        return (
            <Paper className={classes.root} elevation={0} data-testid="educationCenterPaper" square style={{ backgroundColor: localStorage.getItem('appTheme') == 'ligthTheme' ? '#F4F8F6' : '#232429' }}>
                <Loader loading={this.state.loading} />
                <div className={classes.subRoot} >
                    <Grid container direction="row" spacing={0} >
                        <Grid item xs={12} sm={4} md={3} >
                            <Paper className={classes.leftContainer} elevation={0} >
                                {
                                    educationData.map((item: any, key: any) => this.CustomMenuItem({ item, key }))
                                }
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={8} md={9} >
                            <Paper className={classes.rightContainer} elevation={0} >
                                <img src={selectedItemData?.attributes?.image == null ? eduBackgroundImage : selectedItemData?.attributes?.image} style={{ width: '100%', maxHeight: '350px' }} />
                                {selectedItemData != null &&
                                    <div style={{ padding: '22px 22px 0 22px' }}>
                                        <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '18px' }}>
                                            {selectedItemData?.attributes?.title}
                                        </Typography>
                                        <p className={classes.textContent}>
                                            <span dangerouslySetInnerHTML={{ __html: sanitizedString }}></span>
                                        </p>
                                    </div>
                                }
                                { selectedItemData?.attributes?.video_link && 
                                (this.validateVideo(selectedItemData.attributes.video_link) ?
                                <CardMedia height="400" width="100%" component="iframe" frameBorder="0" title="video" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen
                                src={selectedItemData.attributes.video_link} /> : <Typography className={classes.invalidLink}>Invalid Video Link</Typography>) }
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
            </Paper>
        )
        // Customizable Area End
    }
}

export default withStyles(styles)(EducationCenterWeb);

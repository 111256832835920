import React from "react";

import {
  Container,
  Box,
  Button,
  Typography,
  // Customizable Area Start
  Switch,
  // Customizable Area End
} from "@material-ui/core";
import DisableAdsController, {
  Props,
  configJSON,
} from "./DisableAdsController";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const themeStyle = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

export default class DisableAds extends DisableAdsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={themeStyle}>
        <Container maxWidth={"md"}>
        <Box sx={webStyle.mainWrapper}>
          <Typography variant="h6">{configJSON.labelBodyText}</Typography>
          <Typography variant="subtitle1" component="div">
            {this.state.status}
          </Typography>
        </Box>
        <Box sx={webStyle.mainWrapper}>
          <Box sx={webStyle.subscribeWrapper}>
            <Typography variant="subtitle1" component="div">
              {configJSON.labelSubscribe}
            </Typography>
            <Switch 
              data-test-id="switchStatus"
              checked={this.state.enable} 
              onChange={()=> this.updateAdsStatus() } 
            />
          </Box>
        </Box>
        <Box sx={webStyle.mainWrapper}>
          <Box
            data-test-id="buttonNextScreen"
            onClick={() => 
              this.goToDisableAdHomeScreen()
            }
            component="button"
            sx={webStyle.buttonStyle}
          >
            <Button color={"primary"}>{configJSON.btnNextScreenTitle}</Button>
          </Box>
        </Box>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "40px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "30%",
    height: "40px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  subButtonStyle: {
    marginRight: "20px",
    width: "60%",
    height: "60px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  subscribeWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "row",
    alignItems: "center",
    paddingBottom: "40px",
    background: "#fff",
    justifyContent: "space-between",
  },
};
// Customizable Area End

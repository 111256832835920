import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {
    ClassNameMap,
    ClassKeyOfStyles,
  } from '@material-ui/styles/withStyles';
import { toast } from "react-toastify";
import { isEmpty } from "../../../framework/src/Utilities";
import { RewardPoints } from "../../../components/src/types";
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes: ClassNameMap<ClassKeyOfStyles<string>>;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: boolean;
    token: string;
    socialMediaUrl: string;
    message: string;
    fullName: string;
    emailAddress: string;
    errors: { fullName: string, emailAddress: string}
    totalPoints: number | undefined
    referredPoints: number
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class ReferralsControllerWeb extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    getTotalPointsApiCallId: string="";
    getSendApiCallId: string="";
    getLoyaltyPointsApiCallId: string="";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // Customizable Area End
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            loading: false,
            token: localStorage.getItem('token') || "",
            socialMediaUrl: 'https://www.kryptokia.com',
            message: '',
            fullName: '',
            emailAddress: '',
            errors: { fullName: '', emailAddress: ""},
            totalPoints: undefined,
            referredPoints: 0
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {

        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.getTotalPointsApiCallId) {
                this.setState({ loading: false, totalPoints: responseJson.total_loyalty_points });
            }

            if (apiRequestCallId === this.getSendApiCallId) {
                this.setState({ loading: false, fullName: '', emailAddress: '', message:  responseJson.message });
                toast.success(responseJson.message);
            }

            if (apiRequestCallId === this.getLoyaltyPointsApiCallId) {
                this.handleReferredPoints(responseJson);
            }
        }
        // Customizable Area End
    }
    
    async componentDidMount() {
        // Customizable Area Start
        this.getLoyaltyPoints();
        this.getTotalPoints();
        // Customizable Area End
    }
    
    // Customizable Area Start
     validateForm () {
        let nameValid = true;
        let emailValid = true;
        const { fullName, emailAddress } = this.state;
        const newErrors = { fullName: '', emailAddress: '' };
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (isEmpty(fullName)) {
            newErrors.fullName = 'Name is required';
            nameValid = false;
        }
        if (isEmpty(emailAddress)) {
            newErrors.emailAddress = 'Email address is required';
            emailValid = false;
        } else if (!regex.test(emailAddress)) {
            newErrors.emailAddress = 'Invalid email address';
            emailValid = false;
        }
    
        this.setState({ errors: newErrors  });
        return nameValid && emailValid;
      };
    
    getSteps() {
        return ["Invite your contact to Kryptokia", 
        "Your friends will get 50% off on their subscription plan", 
        `You will earn ${this.state.referredPoints} points in your wallet`]
    }

    handleReferredPoints(responseJson: RewardPoints[]) {
        if (responseJson) {
            let referAndEarn = responseJson.find((referEarn: RewardPoints) => referEarn.use_case_name === 'refer_and_earn');
            referAndEarn && this.setState({ referredPoints: referAndEarn.points });
        }
    }

    copyText() {
        navigator.clipboard.writeText(this.state.socialMediaUrl);
    }

    handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        this.setState((prevState) => ({
            ...prevState,
            [name]: value,
            errors: {...prevState.errors, [name]: ''}
        }));
    }

    getTotalPoints() {
        this.setState({ loading: true });
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getTotalPointsApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "account_block/wallets");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getLoyaltyPoints() {
        this.setState({ loading: true });
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getLoyaltyPointsApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_loyaltysystem2/loyalty_points");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    sendInvite(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        if (this.validateForm()) {
            this.setState({ loading: true });
            const header = { token: this.state.token, "Content-Type": "application/json" };
            const httpBody = { full_name: this.state.fullName, email: this.state.emailAddress, platform: 'web' };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getSendApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_referrals/send_referral_invite");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
            runEngine.sendMessage(requestMessage.id, requestMessage);
        }
    }
    // Customizable Area End
}

import React from "react";
// Customizable Area Start
import {
    StyleSheet,
    Text,
    Image,
    TouchableOpacity,
    View,
    ScrollView,
    TouchableWithoutFeedback,
    FlatList,
    SafeAreaView,
    Dimensions, TextInput
} from "react-native";
const { height, width } = Dimensions.get("screen");

import FilteritemsController, { Props } from "./FilteritemsController";
import SelectDropdown from 'react-native-select-dropdown'
import Icon from "react-native-vector-icons/AntDesign";
// Customizable Area End
export default class Derivatives extends FilteritemsController {
    constructor(props: Props) {
        super(props);
    // Customizable Area Start
    // Customizable Area End
    }
    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <View style={styles.container}>
                <View style={{ width: width / 1.1, marginBottom: 10 }}>
                    {
                        this.state.derivativesLabel === 'Select Derivative Option' &&
                        <SelectDropdown
                            data={this.state.derivativesArray}
                            // ref={this.dropdownRef}
                            ref={input => this.dropdownRef = input}
                            // defaultValueByIndex={1}
                            // defaultValue={'Egypt'}
                            onSelect={(selectedItem, index) => {
                                // this.setState({ derivativesLabel: selectedItem, })
                                this.onChangeDerivatives(index, selectedItem);

                            }}
                            defaultButtonText={"Select Derivative Option"}
                            buttonTextAfterSelection={(selectedItem, index) => {

                                // this.onClickRank(selectedItem)
                                return selectedItem;
                            }}
                            rowTextForSelection={(item, index) => {


                                return item;
                            }}
                            buttonStyle={styles.dropdown1BtnStyle}
                            buttonTextStyle={{color: this.state.timeFameIndLabel != '' ? '#000' : 'gray' ,textAlign:"left"}}
                            renderDropdownIcon={(isOpened) => {

                                return (
                                    <>

                                        <Icon
                                            name={isOpened ? "caretup" : "caretdown"}
                                            color={"#444"}
                                            size={10}
                                        />
                                        {/* {
                                            this.state.derivativesLabel != '' &&
                                            <TouchableOpacity onPress={() => this.onClickClearDerivatives()} style={{ marginRight: 10 }}>
                                                <Icon
                                                    name={'close'}
                                                    color={"#444"}
                                                    size={15}
                                                />
                                            </TouchableOpacity>
                                        } */}
                                    </>

                                );
                            }}
                            dropdownIconPosition={"right"}
                            dropdownStyle={styles.dropdown1DropdownStyle}
                            rowStyle={styles.dropdown1RowStyle}
                            rowTextStyle={styles.dropdown1RowTxtStyle}

                        />
                    }
                    {
                        this.state.buildupArray.length != 0 && this.state.topLable === '' &&
                        <SelectDropdown
                            data={this.state.buildupArray}
                            // ref={this.dropdownRef}
                            ref={input => this.dropdownRef = input}
                            // defaultValueByIndex={1}
                            // defaultValue={'Egypt'}
                            onSelect={(selectedItem, index) => {
                                // this.setState({ derivativesLabel: selectedItem, })
                                this.onChangeDerivatives(index, selectedItem);

                            }}
                            defaultButtonText={this.state.derivativesLabel}
                            buttonTextAfterSelection={(selectedItem, index) => {

                                // this.onClickRank(selectedItem)
                                return selectedItem;
                            }}
                            rowTextForSelection={(item, index) => {


                                return item;
                            }}
                            buttonStyle={styles.dropdown1BtnStyle}
                            buttonTextStyle={{color: this.state.timeFameIndLabel != '' ? '#000' : 'gray' ,textAlign:"left"}}

                            renderDropdownIcon={(isOpened) => {

                                return (
                                    <>

                                        <Icon
                                            name={isOpened ? "caretup" : "caretdown"}
                                            color={"#444"}
                                            size={10}
                                        />
                                        {/* {
                                        this.state.derivativesLabel != '' &&
                                        <TouchableOpacity onPress={() => this.onClickClearDerivatives()} style={{ marginRight: 10 }}>
                                            <Icon
                                                name={'close'}
                                                color={"#444"}
                                                size={15}
                                            />
                                        </TouchableOpacity>
                                    } */}
                                    </>

                                );
                            }}
                            dropdownIconPosition={"right"}
                            dropdownStyle={styles.dropdown1DropdownStyle}
                            rowStyle={styles.dropdown1RowStyle}
                            rowTextStyle={styles.dropdown1RowTxtStyle}

                        />
                    }
                    {
                        this.state.topLable != '' &&
                        <View style={styles.dropdownTopViewStyle}>
                            <Text style={{ marginLeft: 10, marginTop: 10 }}>{this.state.derivativesLabel}</Text>
                            {
                                this.state.topLable==='Custom'?
                                <View style={styles.customStyle}>
                                     <TextInput
                                        placeholder={'Custom'}
                                        value={this.state.customValue}
                                        // {...this.txtInputCvv}
                                        onChangeText={text => {
                                            this.setState({
                                                customValue: text
                                            })
                                        }}
                                        autoCapitalize={'words'}
                                        keyboardType={'numeric'}
                                        // maxLength={3}
                                        // placeholderTextColor={this.context.mode === 'light' ? theme.light.placeHolderColor : theme.dark.lightGray}

                                        style={styles.customInput}
                                    /> 
                                        <TouchableOpacity onPress={() => this.onClickClearDerivatives()} style={{ marginRight: 10 }}>
                                                    <Icon
                                                        name={'close'}
                                                        color={"#444"}
                                                        size={15}
                                                    />
                                                </TouchableOpacity>
                                </View>
                                :
                                <SelectDropdown
                                data={this.state.buildupArray}
                                // ref={this.dropdownRef}
                                ref={input => this.dropdownRef = input}
                                // defaultValueByIndex={1}
                                // defaultValue={'Egypt'}
                                onSelect={(selectedItem, index) => {
                                    this.setState({ topLable: selectedItem, })
                                    this.onChangeDerivatives(index, selectedItem);

                                }}
                                defaultButtonText={this.state.topLable}
                                buttonTextAfterSelection={(selectedItem, index) => {

                                    // this.onClickRank(selectedItem)
                                    return selectedItem;
                                }}
                                rowTextForSelection={(item, index) => {


                                    return item;
                                }}
                                buttonStyle={styles.dropdownTopBtnStyle}
                                buttonTextStyle={{color: this.state.timeFameIndLabel != '' ? '#000' : 'gray' ,textAlign:"left"}}
                                renderDropdownIcon={(isOpened) => {

                                    return (
                                        <>

                                            <Icon
                                                name={isOpened ? "caretup" : "caretdown"}
                                                color={"#444"}
                                                size={10}
                                            />
                                            {
                                                this.state.topLable != '' &&
                                                <TouchableOpacity onPress={() => this.onClickClearDerivatives()} style={{ marginRight: 10 }}>
                                                    <Icon
                                                        name={'close'}
                                                        color={"#444"}
                                                        size={15}
                                                    />
                                                </TouchableOpacity>
                                            }
                                        </>

                                    );
                                }}
                                dropdownIconPosition={"right"}
                                dropdownStyle={styles.dropdown1DropdownStyle}
                                rowStyle={styles.dropdown1RowStyle}
                                rowTextStyle={styles.dropdown1RowTxtStyle}

                            />
                            }
                           

                        </View>
                    }

                    {
                        this.state.isPutCallRation &&
                        <View style={{ marginTop: 10 }}>
                            <View style={styles.maketCapStyle}>
                                <Text style={styles.marketCapText}>{"Put Call Ratio"}</Text>
                                <TouchableOpacity
                                    onPress={() => this.setState({ isPutCallRation: false, derivativesLabel: 'Select Derivative Option' })}
                                    style={{ marginRight: 10 }}>
                                    <Icon
                                        name={'close'}
                                        color={"#444"}
                                        size={15}

                                    />
                                </TouchableOpacity>

                            </View>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 10 }}>

                                <View style={[styles.fromTextInput,]}>
                                    {
                                        this.state.putCallRatioFrom != '' &&
                                        <Text style={styles.subText}>{"Form"}</Text>

                                    }
                                    <TextInput
                                        placeholder={'From'}
                                        value={this.state.putCallRatioFrom}
                                        // {...this.txtInputCvv}
                                        onChangeText={text => {
                                            this.setState({
                                                putCallRatioFrom: text
                                            })
                                        }}
                                        autoCapitalize={'words'}
                                        keyboardType={'numeric'}
                                        maxLength={3}
                                        // placeholderTextColor={this.context.mode === 'light' ? theme.light.placeHolderColor : theme.dark.lightGray}

                                        style={{ textAlign: 'center', fontWeight: 'bold', }}
                                    />
                                </View>
                                <View style={[styles.fromTextInput,]}>
                                    {
                                        this.state.putCallRatioTo != '' &&
                                        <Text style={styles.subText}>{"To"}</Text>

                                    }
                                    <TextInput
                                        placeholder={'To'}
                                        value={this.state.putCallRatioTo}
                                        // {...this.txtInputCvv}
                                        onChangeText={text => {
                                            this.setState({
                                                putCallRatioTo: text
                                            })
                                        }}
                                        autoCapitalize={'words'}
                                        keyboardType={'numeric'}
                                        maxLength={3}
                                        // placeholderTextColor={this.context.mode === 'light' ? theme.light.placeHolderColor : theme.dark.lightGray}

                                        style={{ textAlign: 'center', fontWeight: 'bold', }}
                                    />
                                </View>
                            </View>

                        </View>
                    }
                </View>
            </View>
            // Customizable Area End
        )
    }
}



const styles = StyleSheet.create({
    // Customizable Area Start
    container: {
        flex: 1,
        alignItems: 'center'
    },
    dropdown1BtnStyle: {
        width: width / 1.1,
        height: 60,
        backgroundColor: "#F7F7F7",
        borderRadius: 8,
        borderWidth: 1,
        borderColor: "#5BAA69",
    },
    dropdown1BtnTxtStyle: { color: "gray", textAlign: "left" },
    dropdown1DropdownStyle: { backgroundColor: "#EFEFEF" },
    dropdown1RowStyle: {
        backgroundColor: "#EFEFEF",
        borderBottomColor: "#C5C5C5",
    },
    dropdown1RowTxtStyle: { color: "#444", textAlign: "left" },
    fromTextInput: {
        backgroundColor: '#F7F7F7',
        borderRadius: 10,
         width: width / 2.3,
        height: height / 13,
         textAlign: 'center', 
        justifyContent: 'center', 
          alignItems: 'center',
        padding: 13,
        marginTop: 10
    },
    marketCapText: {
        marginLeft: 10,
        // textAlign: 'center',
        fontSize: 16,
        fontWeight: 'bold'
    },
    subText: {
        color: 'gray',
    },
    maketCapStyle: {
        backgroundColor: '#F7F7F7',
        height: height / 13,
        borderRadius: 10,
        // marginRight:10,
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'center'
    },

    dropdownTopBtnStyle: {
        width: width / 1.1,
        // height: 50,
        backgroundColor: "#F7F7F7",
        // borderRadius: 8,
        // borderWidth: 1,
        // borderColor: "#5BAA69",
    },
    dropdownTopViewStyle: {
        width: width / 1.1,
        height: height / 10,
        backgroundColor: '#F7F7F7',
        borderRadius: 10
    },
    customStyle:{
        width: width / 1.1,
        backgroundColor: '#F7F7F7',
        flexDirection:'row',alignItems:'center'

    },
    customInput:{
        width: width / 1.2,
        marginLeft:5
    }
    // Customizable Area End
})
Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LoyaltySystem2";
exports.labelBodyText = "LoyaltySystem2 Body";
exports.loyaltypoints = "account_block/wallets"
exports.loyaltypointsdetails = "bx_block_loyaltysystem2/loyalty_points_histories"
exports.accountsshow = "account_block/accounts/show"


exports.btnExampleTitle = "CLICK ME";
// Customizable Area End
import React from "react";
// Customizable Area Start
import OrderSummaryWebController, {
    Props
} from "./OrderSummaryController.web";

import {
    withStyles, StyleRules
} from "@material-ui/core/styles";

import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import CloseIcon from '@material-ui/icons/Close';

import moment from "moment";

import Loader from "../../../components/src/Loader.web";
import LoginAlertDialogWeb from "../../../components/src/LoginAlertDialog.web";
// Customizable Area End

const styles: StyleRules = {
    // Customizable Area Start
    root: {
        height: '100vh'
    },
    removeBorder: {
        '& .MuiTableCell-root': {
            borderBottom: 'none'
        }
    },
    tableHeader: {
        fontSize: '14px',
        fontWeight: 'bold',
        color: '#b1bdb6'
    },
    tableCell: {
        fontSize: '16px',
        fontWeight: 'bold',
        // color: '#373838'
    }
    // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export class OrderSummaryWeb extends OrderSummaryWebController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        return (
            <Paper elevation={0} square className={classes.root} style={{backgroundColor: localStorage.getItem('appTheme') == 'ligthTheme' ? '#F4F8F6': '#232429'}}>
                {this.state.loading && <Loader loading={this.state.loading} />}
                {!this.state.token && <LoginAlertDialogWeb />}
                <div style={{ marginTop: '15px', width: '75%', margin: '15px 13%' }}>
                    <TableContainer component={Paper} elevation={3} style={{ padding: "16px 0" }}>
                        <h2 style={{ marginLeft: '14px', fontFamily: 'Plus Jakarta Text', fontWeight: 'bold' }}>Order Summary</h2>
                        <Table aria-label="top-scans-one" size="small" className={classes.removeBorder}>
                            <TableHead style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
                                <TableRow>
                                    <TableCell align="left" className={classes.tableHeader}>Subscription Type</TableCell>
                                    <TableCell align="center" className={classes.tableHeader}>Date</TableCell>
                                    <TableCell align="center" className={classes.tableHeader}>Time</TableCell>
                                    <TableCell align="center" className={classes.tableHeader}>Payment Method</TableCell>
                                    <TableCell align="left" className={classes.tableHeader}>Price</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.orderSummaryData.map((row: any, key: any) => (
                                    <TableRow key={row.id}>
                                        <TableCell component="th" scope="row" align="left" className={classes.tableCell}>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <span>{row.attributes.subscription.data.attributes.name}</span>
                                                <span style={{ marginTop: '5px' }}>
                                                    <span style={{ color: '#b1beb7' }}>Qty: </span>
                                                    <span>
                                                        <CloseIcon style={{ fontSize: '12px', color: '#373838' }} />
                                                    </span>
                                                    {row.attributes.qty}
                                                </span>
                                            </div>
                                        </TableCell>
                                        <TableCell align="center" className={classes.tableCell}>
                                            {moment(row.attributes.created_at).format("ll")}
                                        </TableCell>
                                        <TableCell align="center" className={classes.tableCell}>
                                            {moment(row.attributes.created_at).format('LT')}
                                        </TableCell>
                                        <TableCell align="center" className={classes.tableCell}>
                                            {row.attributes.payment_method}
                                        </TableCell>
                                        <TableCell align="left" className={classes.tableCell}>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <span style={{ color: '#1fad63' }}>{row.attributes.subscription.data.attributes.price}</span>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Paper>
        )
        // Customizable Area End
    }
}
export default withStyles(styles)(OrderSummaryWeb);

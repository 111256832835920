import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Collapse from "@material-ui/core/Collapse";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { colors } from '@material-ui/core';
import AccountCircleTwoToneIcon from '@material-ui/icons/AccountCircleTwoTone';
import CachedIcon from '@material-ui/icons/Cached';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import ViewAgendaIcon from '@material-ui/icons/ViewAgenda';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';

import { Angry, newCryptoLogo, cryptoLogoDark, liteIcContact, liteIcManageC, liteIcMood, liteIcRewards, liteIcOrderS, liteIcPortF, liteIcProfile, liteIcWatchL, menuIcContactU, menuIcRefferal, menuIcDarkMood, menuIcManageC, menuIcOrderS, menuIcPortF, menuIcProfile, menuIcSub, menuIcWatchL, liteIcRefferal, subscriptionImage, subscriptionSVG, SubSv, menuIcLogout, menuIcRewards } from "../../blocks/landingpage/src/assets";
import Brightness4SharpIcon from '@material-ui/icons/Brightness4Sharp';

import Switch from '@material-ui/core/Switch';
import { CustomThemeContext } from "../../framework/src/CustomThemeProvider.web";
import Icon from '@material-ui/core/Icon';
import SvgIcon from '@material-ui/core/SvgIcon';
import { toast } from "react-toastify";

//  import  subscription  from '../../blocks/landingpage/src/assets/My-SubscriptionConvert.svg';

//import { ReactComponent as subscription } from '../../blocks/landingpage/assets/My_Subscription.svg';


interface myProps {
}

const useStyles = makeStyles((theme) => ({

    root: {
        justifyContent: "left",
        width: "350px",
        paddingTop: "20px",
        [theme.breakpoints.down(450)]: {
            width: "250px"
        }
    },
    drawerLogoContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '20px 15px'
    },
    forLogo: {
        width: '15%'
    },
    saLogo: {
        height: '100px',
        width: '100px',
        marginLeft: '15%',
        [theme.breakpoints.down('sm')]: {
            marginLeft: '0%'
        },
        [theme.breakpoints.down(425)]: {
            marginLeft: '-50%'
        },
    },
    drawer: {
    },
    item: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0,
    },
    selectedMenuItem: {
        background: 'none !important',
        whiteSpace: 'nowrap'
    },
    button: {
        // color: colors.blueGrey[800],
        padding: '10px 8px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
    },
    btnRoot: {
        paddingLeft: "25px",
        justifyContent: "left !important"
    },
    subMenu: {
        paddingLeft: "50px !important",
    },
    typography: {
        "fontFamily": `"Plus Jakarta Text", sans-serif`,
    },
    toastCSS: {
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            width: '50%',
        },
        [theme.breakpoints.between('sm', 'md')]: {
            width: '70%',
        },
    },
}));

export default function Sidebar(props: myProps) {

    const classes = useStyles();
    const context = useContext(CustomThemeContext);
    const [open, setOpen] = useState(false);
    const [menu, setMenu] = useState<any>({});
    const [activeMenuItem, setActiveMenuItem] = useState(localStorage.getItem('token') != null ? "My Profile (My Subscriptions)" : "Guest");

    const CustomRouterLink = React.forwardRef((props: any, ref: any) => (
        <div ref={ref} style={{ flexGrow: 1 }}>
            <RouterLink {...props} />
        </div>
    ));

    const handleClick = (item: any) => {
        let newData: any = { ...menu, [item]: !menu[item] };
        console.log("NEW", newData);
        setMenu(newData);
    }

    const toogleDrawer = (flag: any) => {
        setOpen(flag);
    }

    React.useEffect(() => {
        if (!localStorage.getItem('token')) {
            menuItems.splice(7,2)
            menuItems.splice(4,2)
            menuItems.splice(1,1)
        }
    }, []);

    const handleMenu = (children: any, level = 0) => {
        return children != undefined && children.map(({ children, name, url, icon, icon2, iconLite }: any) => {
            console.log("name");
            if (!children) {
                return (
                    <List component="div" disablePadding key={name}>
                        <ListItem
                            className={clsx({
                                [classes.item]: true,
                                [classes.selectedMenuItem]: Boolean(name == activeMenuItem) ? true : false
                            })}
                            disableGutters
                            style={{ padding: "0px" }}
                            key={name}
                            selected={Boolean(name == activeMenuItem) ? true : false}
                            onClick={(e: any) => {
                                setActiveMenuItem(e.target?.innerText);
                                toogleDrawer(false);
                            }}
                        >
                            {localStorage.getItem('appTheme') == 'darkTheme' ? <Button
                                className={clsx({
                                    [classes.btnRoot]: true,
                                    [classes.button]: true,
                                    [classes.subMenu]: level
                                })}
                                component={CustomRouterLink}
                                to={url}
                                startIcon={name == activeMenuItem ? icon2 : icon}
                                style={{ color: name == activeMenuItem ? '#41b97b' : 'none' }}
                            >
                                {name} {(name == activeMenuItem) && <hr style={{ marginLeft: '10px', width: '30%' }} />}
                            </Button>
                                // lite mode ....
                                : <Button
                                    className={clsx({
                                        [classes.btnRoot]: true,
                                        [classes.button]: true,
                                        [classes.subMenu]: level
                                    })}
                                    component={CustomRouterLink}
                                    to={url}
                                    startIcon={name == activeMenuItem ? icon2 : iconLite}
                                    style={{ color: name == activeMenuItem ? '#41b97b' : 'none' }}
                                >
                                    {name} {(name == activeMenuItem) && <hr style={{ marginLeft: '10px', width: '30%' }} />}
                                </Button>



                            }
                        </ListItem>
                    </List>
                )
            }
            return (
                <div key={name}>
                    <ListItem
                        className={classes.item}
                        disableGutters
                        key={name}
                        onClick={() => handleClick(name)}
                    >
                        <Button
                            className={clsx({
                                [classes.btnRoot]: true,
                                [classes.button]: true,
                                [classes.subMenu]: level
                            })}
                            startIcon={icon}>
                            <span style={{ textAlign: 'left', width: '72%' }}>{name}</span> {menu[name] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </Button>
                    </ListItem>
                    <Collapse
                        in={(menu[name]) ? true : false}
                        timeout="auto"
                        unmountOnExit
                    >
                        {handleMenu(children, 1)}
                    </Collapse>
                </div>
            )
        })
    }

    const toggleSwitch = (event: any) => {
        const { checked } = event.currentTarget;
        console.log(checked);
        if (checked) {
            context?.setTheme('darkTheme');
        } else {
            context?.setTheme('ligthTheme');
        }
        // console.log("contect mode>>",context.mode)
    }

    const onImgClick = () => {
        window.location.href = "/"
    }

    return (
        <Paper elevation={0} style={{ backgroundColor: localStorage.getItem('appTheme') === 'darkTheme' ? '#232429' : '#F4F8F6' }}>
            <IconButton aria-label="menu" onClick={(e) => setOpen(true)}>
                <MenuIcon />
            </IconButton>
            <Drawer
                anchor="left"
                className={classes.drawer}
                open={open}
                onClose={() => toogleDrawer(false)}
            >
                <div className={clsx(classes.root)}>
                    <div className={classes.drawerLogoContainer}>
                        <IconButton aria-label="close" onClick={(e) => toogleDrawer(false)}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                        <div className={classes.forLogo}>
                            <img src={localStorage.getItem('appTheme') == 'ligthTheme' ? newCryptoLogo : cryptoLogoDark} alt="Poster" className={classes.saLogo} onClick={onImgClick} />
                        </div>
                    </div>
                    <List {...props}>
                        {handleMenu(menuItems)}
                        <ListItem
                            className={classes.item}
                            disableGutters
                            key={"Theme"}
                            onClick={() => handleClick(name)}
                        >
                            <Button
                                className={clsx({
                                    [classes.btnRoot]: true,
                                    [classes.button]: true,
                                    [classes.subMenu]: 0
                                })}
                                startIcon={localStorage.getItem('appTheme') == 'darkTheme' ? <img src={menuIcDarkMood} height={22} width={22} /> : <img src={liteIcMood} height={22} width={22} />}>
                                <span style={{ textAlign: 'left', width: '72%' }}>{"Dark Mode"}</span>
                                <Switch
                                    checked={localStorage.getItem('appTheme') == 'darkTheme' ? true : false}
                                    onChange={(event: any) => toggleSwitch(event)}
                                    name="isPrimary"
                                    color="primary"
                                />
                            </Button>
                        </ListItem>
                        {localStorage.getItem('token') != null && <ListItem
                            className={classes.item}
                            disableGutters
                            key={"Theme"}
                            onClick={(e: any) => {
                                toast.success('User logged out successfully', { className: classes.toastCSS });
                                setTimeout(() => {
                                    localStorage.clear();
                                    window.location.href = '/';
                                    localStorage.setItem('appTheme', 'ligthTheme');
                                }, 2000);
                                setActiveMenuItem(e.target?.innerText);
                                toogleDrawer(false);
                            }}
                        >
                            <Button
                                className={clsx({
                                    [classes.btnRoot]: true,
                                    [classes.button]: true,
                                    [classes.subMenu]: 0
                                })}
                                startIcon={localStorage.getItem('appTheme') == 'darkTheme' ? <img style={{ filter: "invert(100%) sepia(93%) saturate(27%) hue-rotate(33deg) brightness(107%)" }} src={menuIcLogout} height={22} width={22} /> : <img src={menuIcLogout} height={22} width={22} />}>
                                <span style={{ textAlign: 'left', width: '72%' }}>{"Log out"}</span>
                            </Button>
                        </ListItem>}
                    </List>
                </div>
            </Drawer>
        </Paper>
    )
}

const menuItems = [
    {
        "name": localStorage.getItem('token') != null ? "My Profile (My Subscriptions)" : "Guest",
        "url": "/ProfileWeb",
        "icon": <img src={menuIcProfile} style={{ filter: "invert(100%) sepia(93%) saturate(27%) hue-rotate(33deg) brightness(107%)" }} height={22} width={22} />,
        "iconLite": <img src={liteIcProfile} height={22} width={22} />,
        "icon2": <img src={menuIcProfile} style={{ filter: "invert(56%) sepia(63%) saturate(365%) hue-rotate(96deg) brightness(95%) contrast(98%)" }} height={22} width={22} />,
    },
    {
        "name": "My Subscriptions",
        "url": "/SubscriptionWeb",
        "icon": <img src={menuIcSub} style={{ filter: "invert(0%) sepia(0%) saturate(7500%) hue-rotate(279deg) brightness(99%) contrast(107%)" }} height={22} width={22} />,
        "iconLite": <img src={SubSv} height={22} width={22} />,
        "icon2": <img src={menuIcSub} style={{ filter: "invert(56%) sepia(63%) saturate(365%) hue-rotate(96deg) brightness(95%) contrast(98%)" }} height={22} width={22} />,

    },
    {
        "name": "Portfolio",
        "url": "/PortfolioWeb",
        "icon": <img src={menuIcPortF} height={22} width={22} />,
        "iconLite": <img src={liteIcPortF} height={22} width={22} />,
        "icon2": <img src={liteIcPortF} style={{ filter: "invert(56%) sepia(63%) saturate(365%) hue-rotate(96deg) brightness(95%) contrast(98%)" }} height={22} width={22} />,
    },
    {
        "name": "Watchlist",
        "url": "/WatchListWeb",
        "icon": <img src={menuIcWatchL} height={22} width={22} />,
        "iconLite": <img src={liteIcWatchL} height={22} width={22} />,
        "icon2": <img src={menuIcWatchL} style={{ filter: "invert(56%) sepia(63%) saturate(365%) hue-rotate(96deg) brightness(95%) contrast(98%)" }} height={22} width={22} />,
    },
    {
        "name": "Manage Cards",
        "url": "/ManageCardsWeb",
        "icon": <img src={menuIcManageC} height={22} width={22} />,
        "iconLite": <img src={liteIcManageC} height={22} width={22} />,
        "icon2": <img src={menuIcManageC} style={{ filter: "invert(56%) sepia(63%) saturate(365%) hue-rotate(96deg) brightness(95%) contrast(98%)" }} height={22} width={22} />,
    },
    {
        "name": "Order summary",
        "url": "/OrderSummaryWeb",
        "icon": <img src={menuIcOrderS} height={22} width={22} />,
        "iconLite": <img src={liteIcOrderS} height={22} width={22} />,
        "icon2": <img src={menuIcOrderS} style={{ filter: "invert(56%) sepia(63%) saturate(365%) hue-rotate(96deg) brightness(95%) contrast(98%)" }} height={22} width={22} />,
    },
    {
        "name": "Contact us",
        "url": "/ContactUsWeb",
        "icon": <img src={menuIcContactU} height={22} width={22} />,
        "iconLite": <img src={liteIcContact} height={22} width={22} />,
        "icon2": <img src={menuIcContactU} style={{ filter: "invert(56%) sepia(63%) saturate(365%) hue-rotate(96deg) brightness(95%) contrast(98%)" }} height={22} width={22} />,
    },
    {
        "name": "Refer a Friend",
        "url": "/ReferralsWeb",
        "icon": <img src={menuIcRefferal} height={22} width={22} />,
        "iconLite": <img src={liteIcRefferal} height={22} width={22} />,
        "icon2": <img src={menuIcRefferal} style={{ filter: "invert(56%) sepia(63%) saturate(365%) hue-rotate(96deg) brightness(95%) contrast(98%)" }} height={22} width={22} />,
    },
    {
        "name": "Loyalty Rewards",
        "url": "/LoyaltySystemWeb",
        "icon": <img src={menuIcRewards} height={24} width={24} />,
        "iconLite": <img src={liteIcRewards} height={24} width={24} />,
        "icon2": <img src={menuIcRewards} style={{ filter: "invert(56%) sepia(63%) saturate(365%) hue-rotate(96deg) brightness(95%) contrast(98%)" }} height={22} width={22} />,
    }
    // {
    //     "name": "Membership Status",
    //     "url": "/DisableAds",
    //     "icon": <img src={menuIcOrderS} height={22} width={22}/>,
    //     "iconLite": <img src={liteIcOrderS} height={22} width={22}/>,
    //     "icon2": <img src={menuIcOrderS} style={{filter:"invert(56%) sepia(63%) saturate(365%) hue-rotate(96deg) brightness(95%) contrast(98%)"}} height={22} width={22}/>,
    // },
    // {
    //     "name": "Child43",
    //     "icon": <ContactMailIcon />,
    //     "children": [
    //         {
    //             "name": "Child431",
    //             "url": "/child431",
    //             "icon": <ContactMailIcon />
    //         }
    //     ]
    // }
]

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { toast } from 'react-toastify';
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    forgotPasswordConfirmForm: boolean;
    resetPasswordForm: boolean;
    loading: boolean;
    email: string;
    token: any;
    showPass: boolean;
    showConfirmPass: boolean;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class ForgotPasswordWebController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    emailAccountOtpApiCallId: string = "";
    emailAccountResetApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            forgotPasswordConfirmForm: false,
            resetPasswordForm: false,
            loading: false,
            email: "",
            token: "",
            showPass: false,
            showConfirmPass: false,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {

        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            runEngine.debugLog("API Message Recived", message);

            if (apiRequestCallId === this.emailAccountOtpApiCallId) {
                this.setState({ loading: false });
                if (responseJson?.data) {
                    localStorage.setItem('resetPasswordToken', responseJson?.meta?.token);
                    this.setState({
                        forgotPasswordConfirmForm: true,
                        token: responseJson?.meta?.token
                    });
                } else {
                    if (responseJson?.errors) {
                        if (responseJson?.errors[0]?.otp) {
                            toast.error(responseJson?.errors[0]?.otp);
                        }
                    } else {
                        toast.error("Reset password having some issue");
                    }
                }
            } else if (apiRequestCallId === this.emailAccountResetApiCallId) {
                this.setState({ loading: false });
                if (responseJson?.data || responseJson?.message) {
                    toast.success("Reset password successfully");
                    setTimeout(() => {
                        this.navigationLinkPressed("EmailAccountLoginWeb");
                    }, 2000);
                } else {
                    if (responseJson.errors) {
                        if (responseJson.errors[0].token) {
                            toast.error(responseJson.errors[0].token);
                        } else if (responseJson.errors[0].profile) {
                            toast.error(responseJson.errors[0].profile);
                        }
                    } else {
                        toast.error("Reset password having some issue");
                    }
                }
            }
        }
        // Customizable Area End
    }
    
    async componentDidMount() {
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    navigationLinkPressed = (path: string) => {
        let to = new Message(getName(MessageEnum.NavigationMessage));
        to.addData(getName(MessageEnum.NavigationTargetMessage), path);
        to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(to);
    }

    togglePassword = () => {
        this.setState((previousState) => ({
            showPass: !previousState.showPass
        }));
    };

    toggleConfirmPassword = () => {
        this.setState((previousState) => ({
            showConfirmPass: !previousState.showConfirmPass
        }));
    };

    forgotButtonClick(values: any) {
        this.setState({ loading: true, email: values.email });
        console.log(values);
        const header = { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*" };
        const data = {
            attributes: {
                "email": values.email
            }
        };
        const httpBody = { data: data };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.emailAccountOtpApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_forgot_password/otps");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    resetPasswordClick(values: any) {
        if (values.new_password == values.confirm_password) {
            this.setState({ loading: true });
            console.log(values);
            const header = { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*" };
            const data = {
                "old_password": values.old_password,
                "new_password": values.new_password,
                "token": localStorage.getItem('resetPasswordToken')
            };
            const httpBody = { data: data };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.emailAccountResetApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_forgot_password/passwords");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
            runEngine.sendMessage(requestMessage.id, requestMessage);
        } else {
            toast.error("New password and confirm password should be same");
            return;
        }
    }
    // Customizable Area End
}

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { AsyncStorage,Linking ,Alert} from 'react-native'
import { CommonContext } from "../../../framework/src/CommonContextProvider";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  name: string;
  email: string;
  phoneNumber: string;
  comments: string;
  enableField: boolean;
  token: string;
  contactUsList: any;
  activeId: number;
  activeName: string;
  activeEmail: string;
  activePhoneNumber: string;
  activeDescription: string;
  activeCreatedAt: string;
  isVisible: boolean;
  isFocusedSubje: boolean;
  isFocusedEmail: boolean;
  isFocuseMessage: boolean;
  subject: string;
  messages: string;
  emailError: boolean;
  subjectError: boolean;
  commentError: boolean;
  errrEmailLable: string;
  subjectErrorLable: string;
  errEmailValid: string;
  errCommentLable: string;
  errSubjetLable: string;

  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ContactusController extends BlockComponent<Props, S, SS> {
  contactUsApiCallId: any;
  deleteContactApiCallId: any;
  addContactApiCallId: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      name: "",
      email: "",
      phoneNumber: "",
      comments: "",
      enableField: false,
      token: "",
      contactUsList: [],
      activeId: 0,
      activeName: "",
      activeEmail: "",
      activePhoneNumber: "",
      activeDescription: "",
      activeCreatedAt: "",
      isVisible: false,
      isFocusedSubje: false,
      isFocusedEmail: false,
      isFocuseMessage: false,
      subject: "",
      messages: "",
      emailError: false,
      subjectError: false,
      commentError: false,
      errrEmailLable: "",
      errEmailValid: "",
      subjectErrorLable: "",
      errCommentLable: "",
      errSubjetLable: ""

    };


    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    // Customizable Area End
  }

// Customizable Area Start
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };
// Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });

    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
     
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      runEngine.debugLog("API Message Recived", message);

      if (responseJson && apiRequestCallId) {
      
        if (apiRequestCallId === this.addContactApiCallId) {
          if(!responseJson.errors)
          {
            Alert.alert(
              "Submit",
              "Your have successfully sent message",
              [
                {
                  text: "OK",
                  onPress: () =>
                  this.props.navigation.navigate('Dashboard')
                }
              ],
              { cancelable: false }
            );
          }else{
            this.parseApiErrorResponse(responseJson);
          }
       
        }
      }
      else{
         this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  txtNameProps = {
    onChangeText: (text: string) => {
      this.setState({ name: text });

      //@ts-ignore
      this.txtNameProps.value = text;
    }
  };
  txtInputSubjectProps = {
    onChangeText: (text: string) => {
      this.setState({ subject: text });

      //@ts-ignore
      this.txtInputSubjectProps.value = text;
    }
  };
  txtEmailProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtEmailProps.value = text;
    }
  };
  txtPhoneNumberProps = {
    onChangeText: (text: string) => {
      this.setState({ phoneNumber: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    }
    // keyboardType: "phone-pad"
  };
  txtCommentsProps = {
    onChangeText: (text: string) => {
      this.setState({ comments: text });

      //@ts-ignore
      this.txtCommentsProps.value = text;
    }
  };

  addQuery = () => {
    this.props.navigation.navigate("AddContactus");
  };

  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  setModal = (item: any) => {
    this.setState({
      activeId: item.id,
      activeName: item.attributes.name,
      activeEmail: item.attributes.email,
      activeDescription: item.attributes.description,
      activePhoneNumber: item.attributes.phone_number,
      activeCreatedAt: item.attributes.created_at,
      isVisible: !this.state.isVisible
    });
  };

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail = (Email: string) => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(Email) === false) {
      return false;
    } else {
      return true;
    }
  };

  addQueryApi = async () => {
    const { email, subject, comments, emailError, commentError, subjectError } = this.state;
    if (this.isStringNullOrBlank(email) && this.isStringNullOrBlank(comments) && this.isStringNullOrBlank(subject)) {
      this.setState({
        subjectError: !subjectError,
        commentError: !commentError,
        emailError: !emailError,
        errrEmailLable: configJSON.errrEmail,
        errCommentLable: configJSON.messageErrorLable,
        subjectErrorLable: configJSON.subjectErrorLable

      })
    }
    else if (this.isStringNullOrBlank(subject)) {
      this.setState({
        subjectError: !subjectError,
        subjectErrorLable: configJSON.subjectErrorLable


      })
    }
   
    else if (this.isStringNullOrBlank(email)) {
      this.setState({
        emailError: !emailError,
        errrEmailLable: configJSON.errrEmail

      })
    }
    else if (!this.isValidEmail(this.state.email.trim())) {
      this.setState({ emailError: !emailError, errrEmailLable: configJSON.errorEmailNotValid })
    }
    else if (this.isStringNullOrBlank(comments)) {
      this.setState({ commentError: !commentError, errCommentLable: configJSON.messageErrorLable })
    }
    else {
      let token = await AsyncStorage.getItem("userToken");
      let data = {
          data:{
          name: subject.trim(),
          email: email.trim(),
          description: comments.trim()
          }
      };
      const header = {
        "Content-Type": configJSON.contactUsApiContentType,
        token: token
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addContactApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getContactUsAPiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  deleteContactUs = (id: number) => {
    const header = {
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteContactApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContactUsAPiEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getContactUsList = (token: string) => {
    const header = {
      "Content-Type": configJSON.contactUsApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.contactUsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContactUsAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  onClickCall=()=>{
    Linking.openURL(`tel:+919876543210`)
  }
  static contextType=CommonContext

  // Customizable Area End
}

import React, { Component } from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

interface Props {
    classes: any;
    stockData: any;
    title: string;
    handleTimeFrameClick: any;
    getMoreStocks: any;
    showMore: boolean;
    selectedTimeFrame: string;
    strengthColor: string;
}

interface S {

}

class TopScansWeb extends Component <Props, S> {

    constructor (props: Props) {
        super(props);
    }

    render () {
        const { classes, stockData, title, handleTimeFrameClick, getMoreStocks, showMore, selectedTimeFrame, strengthColor } = this.props;

        return (
            <Paper square elevation={4} className={classes.topScanContainer} style={{ minHeight: '350px', padding: '8px', boxShadow: localStorage.getItem('appTheme') == 'ligthTheme' ? 'none' : '' }}>
                <Typography variant="body1" className={classes.mainTitle} style={{ fontSize: '12px', marginBottom: '16px', marginTop: '8px' }}>
                    {`Top Coins By Tech Strength`}
                </Typography>
                <Divider style={{ margin: '0px', padding: '0px' }} />
                <ul className={classes.topScanUnorderedList}>
                    <li>
                        <Typography data-testid='topscan' variant="body1" className={`${classes.mainTitle}`} style={{  cursor: 'pointer' }} id="topscan">
                            {title} Coins
                        </Typography>
                    </li>
                </ul>
                <ul className={classes.topScanUnorderedListButton}>
                    <li>
                        <Button data-testid={`${title.toLowerCase()}-hourly`} size="small" variant="contained" className={`${classes.buttonStyle} ${selectedTimeFrame ==="hourly" ? classes.topScanTableButton : undefined}`} id="hourly" onClick={handleTimeFrameClick}>
                            HOURLY
                        </Button>
                        <Button data-testid={`${title.toLowerCase()}-daily`} size="small" variant="contained" className={`${classes.buttonStyle} ${selectedTimeFrame === "daily" ? classes.topScanTableButton : undefined}`} id="daily" onClick={handleTimeFrameClick}>
                            Daily
                        </Button>
                        <Button data-testid={`${title.toLowerCase()}-weekly`} size="small" variant="contained" className={`${classes.buttonStyle} ${selectedTimeFrame ==="weekly" ? classes.topScanTableButton : undefined}`} id="weekly" onClick={handleTimeFrameClick}>
                            WEEKLY
                        </Button>
                        <Button data-testid={`${title.toLowerCase()}-monthly`} size="small" variant="contained" className={`${classes.buttonStyle} ${selectedTimeFrame ==="monthly" ? classes.topScanTableButton : undefined}`} id="monthly" onClick={handleTimeFrameClick}>
                            MONTHLY
                        </Button>
                        <Button data-testid={`${title.toLowerCase()}-3month`} size="small" variant="contained" className={`${classes.buttonStyle} ${selectedTimeFrame ==="3month" ? classes.topScanTableButton : undefined}`} id="3month" onClick={handleTimeFrameClick}>
                            3MONTH
                        </Button>
                    </li>
                </ul>
                <div style={{ marginTop: '15px' }}>
                    {stockData && stockData.length > 0 ? (
                        <TableContainer component={Paper} elevation={0}>
                            <Table aria-label="top-scans-one" size="small" className={classes.removeBorder}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left" style={{ fontSize: '16px', fontWeight: 'bold' }}>Symbol</TableCell>
                                        <TableCell align="left" style={{ fontSize: '16px', fontWeight: 'bold' }}>Name</TableCell>
                                        <TableCell align="left" style={{ fontSize: '16px', fontWeight: 'bold' }}>Price</TableCell>
                                        <TableCell align="left" style={{ fontSize: '16px', fontWeight: 'bold' }}>Strength</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {stockData.length > 0 && stockData.map((row: any, key: any) => (
                                        <TableRow key={row.id}>
                                            <TableCell component="th" scope="row">
                                                <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                    {row.symbol}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                    {row.name}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                    ${row.price?.toFixed(2)}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="left" style={{ fontWeight: 'bold' }}>
                                                <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                    {`Strong `} <span style={{ color: strengthColor }}>{row.strength}</span>
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                { !stockData.length && 
                                        <TableRow>
                                            <TableCell colSpan={4} align="center">No Data Found</TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>) : (
                        <Typography align="center" variant="body2" style={{ margin: '20px' }}>
                            No data found
                        </Typography>
                    )}
                </div>
                {stockData.length > 0 && <button type="button" data-testid="moreStocksBtn" className={classes.loginButton} style={{ float: 'right', marginTop: '10px', marginRight: '30px', backgroundColor: 'rgba(1,162,78,255)', color: '#ffffff' }} onClick={getMoreStocks}>{showMore ? 'Less...' : 'More...'}</button>}
            </Paper>
        )
    }
}

export default TopScansWeb;
//@ts-nocheck
import React, { Component,FunctionComponent,useContext, useEffect, useState } from "react";
import { StyleSheet, Text, View, Image ,TextInput,Dimensions,TouchableOpacity} from "react-native";
import { COLORS } from "../../framework/src/Globals";
import Icon from 'react-native-vector-icons/Entypo'
import { theme } from "../../framework/src/theme";
import { CommonContext } from "../../framework/src/CommonContextProvider";
import CustomText from "./text";
import HelperClass from "./HelperClass";

const {height,width}=Dimensions.get('screen')

interface Props {
  onPress: any;
title: string;
testID?:string;
}

interface S {
}

interface SS {
}

const SubHeader: FunctionComponent<Props> = (props) => {
  const [darkmode,setDarkmode] = useState(false)
  useEffect(()=>{
setDarkmode(HelperClass.getDarkMode())
  })
    return (
      <View style={{backgroundColor:darkmode==true? '#000' : '#fff',...styles.headerStyle}}>
        <View style={{ flex: 1 }}>
          <TouchableOpacity
            onPress={props.onPress}
            style={styles.backArrowView}
            testID={props.testID}
          >
            <Icon name="chevron-small-left" size={30} color={COLORS.green} />
          </TouchableOpacity>
        </View>

        <View style={{flex:6, alignItems: 'center' }}>
          <CustomText adjustsFontSizeToFit style={styles.contactText}>{props.title}</CustomText>
        </View>
        <View style={{ flex: 1 }} />
      </View>
    );
  
}
export default SubHeader
const styles = StyleSheet.create({
    headerStyle:{
        flexDirection:'row',
        alignItems:'center',
        justifyContent:'space-between',
        paddingHorizontal:15,
        paddingVertical:10,
      },
      backArrowView:{
        elevation:5,
        // flex:0.5,
        height:30,
        width:30,
        borderRadius:15,
        backgroundColor:COLORS.white,
        alignItems:'center',justifyContent:'center'
      },
      contactText:{
        color:COLORS.green,
       fontFamily:'PlusJakartaText-Bold',
        fontSize:20,letterSpacing:0.5,
      }
});

import React from 'react';
import Paper from "@material-ui/core/Paper";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import { priceActionMainOptionsReverse, removePlusMinusSymbol } from './chartUtils.web';
import { BearishBullishDoublePattern, BearishBullishHeadShoulderPattern, BearishBullishTriplePattern, ContinuationPatterns, PriceActionTableHeadings } from './PriceActionTableView.web';

const CustomFilterScreener = (props: any) => {

    let firstCoin = props.state.customFilterCoins[0];
    
    const fundamentalHeaders = () => {
    return ['Category']
   }
   
    const volumeHeaders = (filterData: any) => {
        const filter = filterData.attributes[Object.keys(filterData.attributes)[0]];
        const headings=[filter.volume_option === 'volume_averge' ? 'Volume Average' : 'Last Volume']
        return headings;
    }

    const priceActionHeaders = (filterData: any) => {
        const filter = filterData.attributes[Object.keys(filterData.attributes)[0]];
        let headings:string[] = [];
        switch (filter.price_action_main_option) {
            case 'gain_loss':
                headings = gainLossHeadings(filterData);
                break;
            case 'high_low':
                headings = highLowHeadings(filterData);
                break;
            case 'support_resistance':
                headings = supportResistanceHeadings(filterData);
                break;
            case 'reversal_patterns':
                headings = reversalHeading(filterData);
                break;
            case 'candelsticks':
                headings = ['Up Trend', 'Down Trend']
                break;
        }
        return headings
    }

    const highLowHeadings = (filterData: any) => {
        const filter = filterData.attributes[Object.keys(filterData.attributes)[0]];
        const dataReceived = filter.price_action_option;
        let headings:string[] = [];
        if (dataReceived === 'near_all_time_high' || dataReceived === 'near_all_time_low') {
            headings = headings.concat([`Near All Time \nHigh`, `Near All Time \nHigh Date`, `Near All Time \nLow`, `Near All Time \nLow Date`, `Near All Time \nPrice Change`])
        }
        if (dataReceived === 'at_all_time_high' || dataReceived === 'at_all_time_low') {
            headings = headings.concat([`At All Time \nHigh`, `At All Time \nHigh Date`, `At All Time \nLow`, `At All Time \nLow Date`, `At All Time \nPrice Change`])
        }
        if (dataReceived === 'near_52_weeks_high' || dataReceived === 'near_52_weeks_low') {
            headings = headings.concat([`Near 52 Weeks \nHigh`, `Near 52 Weeks \nHigh Date`, `Near 52 Weeks \nLow`, `Near 52 Weeks \nLow Date`, `Near 52 Weeks \nPrice Change`])
        }
        if ((dataReceived === 'near_past_week_high' || dataReceived === 'near_past_week_low')) {
            headings = headings.concat([`Near Past Weeks \nHigh`, `Near Past Weeks \nHigh Date`, `Near Past Weeks \nLow`, `Near Past Weeks \nLow Date`, `Near Past Weeks \nPrice Change`])
        }
        return headings;
    }

    const gainLossHeadings = (filterData: any) => {
        const filter = filterData.attributes[Object.keys(filterData.attributes)[0]];
        const dataReceived = filter.price_action_option;
        let headings:string[] = [];
        dataReceived === "price_up" && headings.push("Gain %");
        dataReceived === "price_down" && headings.push("Fall %");
        (dataReceived === "trending_up" || dataReceived === "trending_down") && headings.push("Min Price Trending Day");
        return headings;
    }

    const supportResistanceHeadings = (filterData: any) => {
        const filter = filterData.attributes[Object.keys(filterData.attributes)[0]];
        let headings: string[] = [filter.price_action_suboption.replace('_', " ").concat(" Price")];
        return headings;
    }

    const reversalHeading = (filterData: any) => {
        let headings:string[] = [];
        const filter = filterData.attributes[Object.keys(filterData.attributes)[0]];
        const dataReceived = filter.price_action_option;

        if (dataReceived === 'double_bottom_pattern')
            headings = headings.concat([`First Bottom/\n Date`, `Mid Point/\n Date`, `Second Bottom/\n Date`])
        if (dataReceived === 'triple_bottom_pattern')
            headings = headings.concat([`First Bottom/\n Date`, `First Mid Point/\n Date`, `Second Bottom/\n Date`, `Second Mid Point/\n Date`, `Third Bottom/\n Date`])
        if (dataReceived === 'double_top_pattern')
            headings = headings.concat([`First Top/\n Date`, `Mid Point/\n Date`, `Second Top/\n Date`]);
        if (dataReceived === 'triple_top_pattern')
            headings = headings.concat([`First Top/\n Date`, `First Mid Point/\n Date`, `Second Top/\n Date`, `Second Mid Point/\n Date`, `Third Top/\n Date`]) 
        if (dataReceived === 'inverted_head_shoulder_pattern' || filter.price_action_option  === 'head_shoulder_pattern')
            headings = headings.concat([`Shoulder 1/\n Date`, `First Mid Point/\n Date`, `Head/\n Date`, `Second Mid Point/\n Date`, `Shoulder 2/\n Date`])
        if (dataReceived === "continuation patterns")
            headings = headings.concat([`Top First Point/\n Date`, `Top Last Point/\n Date`, `Bottom First Point/\n Date`, `Bottom Last Point/\n Date`]);
        headings = headings.concat(['Market Cap']);
        return headings;
    }

    const indicatorHeaders = (filterData: any) => {
        let headings:string[] = [];
        const filter = filterData.attributes[Object.keys(filterData.attributes)[0]];

        switch (filter.indicator_main_option.toLowerCase()) {
            case "rsi":
                headings.push('Relative Strength Index');
                break;
            case "mfi":
                headings.push('Money Flow Index');
                break;
            case "stochastic":
                headings.push('Stochastic');
                break;
            case "cci":
                headings.push('Commodity Channel Index');
                break;
            case "moving_average":
                headings.push('Moving Average');
                break;
            case "macd":
                headings.push('Moving Average Convergence Divergence');
                break;
            case "adx":
                headings.push('Average Directional Index');
                break;
            case "obv":
                headings.push('On Balance Volume');
                break;
            case "cmf":
                headings.push('Chaikin Money Flow');
                break;
            case "bollinger_bands":
                headings.push('Band');
                break;
            case "atr":
                headings.push('ATR Line');
                break;
        }
        return headings;
    }

    const prepareHeaders = (props: any) => {
        const filter = props.state.currentCustomFilter;
        let headers = ['Name', 'Price'];
        const funHeaders:any = firstCoin.fundamental_data ? fundamentalHeaders() : [];
        const volumeHeader:any = firstCoin.volume_data ? volumeHeaders(filter) : [];
        const priceActionHeader:any = firstCoin.price_action_data ? priceActionHeaders(filter) : [];
        const indiHeader:any = firstCoin.indicator_data ? indicatorHeaders(filter) : [];
        headers = headers.concat(funHeaders).concat(volumeHeader).concat(priceActionHeader).concat(indiHeader);
        if (firstCoin?.volume_data || firstCoin?.price_action_data || firstCoin?.indicator_data) {
            headers = headers.concat(['24h High', '24h Low']);
        }
        if (firstCoin?.volume_data || firstCoin?.indicator_data) {
            headers = headers.concat(['Volume', 'Circulation']);
        }
        headers.push('Market Cap');
        headers = [...new Set(headers)];
        return (<PriceActionTableHeadings headings={headers} classes={props.classes} />)
    }

    const displayHighLow = (coin: any) => {
        let keys = Object.keys(coin);
        return ((keys.includes('volume_data') || keys.includes('price_action_data') || keys.includes('indicator_data'))
            && <>
            <TableCell>
                <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                    {(coin.volume_data || coin.price_action_data || coin.indicator_data).one_day?.high}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                    {(coin.volume_data || coin.price_action_data || coin.indicator_data).one_day?.low}
                </Typography>
            </TableCell>
            </> )
    }

    const displaySeparateColumns = (coin: any) => {
        const filter = props.state.currentCustomFilter;
        let columns: any[] = [];
        if (coin.fundamental_data) {
            const fundamentalData = coin.fundamental_data;
            columns.push(<TableCell key={fundamentalData.id} align="left">
                <Typography data-testid={`openCategory${fundamentalData.id+1}`} gutterBottom variant="body2" component="a" style={{ fontWeight: 'bold', fontSize: '12px', cursor: 'pointer', textDecoration: 'none', color: 'rgba(1,162,78,255)' }} onClick={() => {
                    props.openCategoryPopup(coin);
                }}>
                    {`View Category`}
                </Typography>
            </TableCell>)
        }
        if (coin.volume_data) {
            const volumeData = coin.volume_data;
            columns.push(<TableCell align="left">
                {volumeData['volume_average'] &&
                <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                    {volumeData.volume_average}
                </Typography>}
                {volumeData['last_volume'] &&
                <Typography gutterBottom variant="body2" data-testid="lastVolume" component="div" style={{ fontWeight: 'bold', fontSize: '12px', cursor: 'pointer', textDecoration: 'underline', color: 'rgba(1,162,78,255)' }} onClick={(event: any) => {
                    if (typeof volumeData['last_volume'] !== 'number')
                        props.openVolumeUpAndDownPopup(volumeData['last_volume']);
                }}>
                    {volumeData.last_volume[volumeData.last_volume.length-1].volume}
                </Typography>}
            </TableCell>)
        }
        if (coin.price_action_data) {
            columns.push(pricaActionColumns(filter, coin));
        }
        if (coin.indicator_data) {
            const indicatorData = coin.indicator_data;
            columns.push(<>
            <TableCell align="left">
            {indicatorData['relative_strength_index'] &&
                <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                    {indicatorData.relative_strength_index.toFixed(6)}
                </Typography>}
            {indicatorData['money_flow_index'] &&
                <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                    {indicatorData.money_flow_index.toFixed(6)}
                </Typography>}
            {indicatorData['stochastic_value'] &&
                <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                    {indicatorData.stochastic_value.toFixed(6)}
                </Typography>}
            {indicatorData['commodity_channel_index'] &&
                <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                    {indicatorData.commodity_channel_index.toFixed(6)}
                </Typography>}
            {indicatorData['simple_moving_average'] &&
                <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                    {indicatorData.simple_moving_average.toFixed(6)}
                </Typography>}
            {indicatorData['exponential_moving_average'] &&
                <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                    {indicatorData.exponential_moving_average.toFixed(6)}
                </Typography>}
            {indicatorData['moving_average_convergence_divergence'] &&
                <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                    {indicatorData.moving_average_convergence_divergence.toFixed(6)}
                </Typography>}
            {indicatorData['average_directional_index'] &&
                <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px', wordBreak: 'normal' }}>
                    {indicatorData.average_directional_index.map((v: number) => v.toFixed(6)).join(',\n')}
                </Typography>}
            {indicatorData['on_balance_volume'] &&
                <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px', wordBreak: 'normal' }}>
                    {indicatorData.on_balance_volume.map((v: number) => v.toFixed(6)).join(",\n")}
                </Typography>}
            {indicatorData['chaikin_money_flow'] &&
                <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px', wordBreak: 'normal' }}>
                    {
                        indicatorData.chaikin_money_flow.some((v: any) => v === null)
                            ? 'NA'
                            : indicatorData.chaikin_money_flow
                                .map((v: any) => (v !== null ? v.toFixed(6) : 'NA'))
                                .join(',\n')
                    }
                </Typography>}
            {indicatorData['bollinger_band'] &&
                <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', whiteSpace: 'nowrap' }}>
                        <span>Lower - {indicatorData.bollinger_band.lower_band.toFixed(6)}</span>
                        <span>Middle - {indicatorData.bollinger_band.middle_band.toFixed(6)}</span>
                        <span>Upper - {indicatorData.bollinger_band.upper_band.toFixed(6)}</span>
                    </div>
                </Typography>}
            {indicatorData['average_true_range'] &&
                <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px', wordBreak: 'normal' }}>
                    {indicatorData.average_true_range.map((v: string) => Number(v).toFixed(6)).join(',\n')}
                </Typography>
            }
        </TableCell></>)
        }
        return columns;
    }

    const rowObject = (coin: any) => {
        if (coin.fundamental_data)
            return coin.fundamental_data;
        if (coin.volume_data)
            return coin.volume_data;
        if (coin.price_action_data)
            return coin.price_action_data;
        if (coin.indicator_data)
            return coin.indicator_data;
    }

    const headers = firstCoin && prepareHeaders(props);

    const pricaActionColumns = (filterData: any, coin: any) => {
        const filter = filterData.attributes[Object.keys(filterData.attributes)[0]];
        let columns:any = [];
        let option = filter.price_action_main_option;
        let row = coin.price_action_data;
        const { classes } = props;
        switch (parseInt(priceActionMainOptionsReverse[option])) {
            case 1:
                columns.push(<TableCell align='left'>
                    <Typography
                        gutterBottom
                        variant="body2"
                        component="div"
                        style={{
                            fontWeight: "bold",
                            fontSize: "12px",
                        }}
                    >
                        { ['price_up', 'price_down'].includes(filter.price_action_option) && row.percent_change}
                        { ['trending_up', 'trending_down'].includes(filter.price_action_option) && row.min_price_trending_days}
                    </Typography>
                </TableCell>)
                break;
            case 2:
                columns.push( <>
                <TableCell align="left">
                    <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                        {`${row.highs_lows?.high}`}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                        {`${row.highs_lows?.high_timestamp}`}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                        {`${row.highs_lows?.low}`}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                        {`${row.highs_lows?.low_timestamp}`}
                    </Typography>
                </TableCell>
                <TableCell align="left">
                    <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                        {`${row.highs_lows?.price_change}`}
                    </Typography>
                </TableCell>
                </>)
                break;
            case 4:
                columns.push(<TableCell>
                    <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                        {row['NEAR_S1'] && `${row['NEAR_S1']}`}
                        {row['NEAR_S2'] && `${row['NEAR_S2']}`}
                        {row['NEAR_S3'] && `${row['NEAR_S3']}`}
                        {row['NEAR_R1'] && `${row['NEAR_R1']}`}
                        {row['NEAR_R2'] && `${row['NEAR_R2']}`}
                        {row['NEAR_R3'] && `${row['NEAR_R3']}`}
                        {row['BREAKDOWN_S1'] && `${row['BREAKDOWN_S1']}`}
                        {row['BREAKDOWN_S2'] && `${row['BREAKDOWN_S2']}`}
                        {row['BREAKDOWN_S3'] && `${row['BREAKDOWN_S3']}`}
                        {row['BREAKDOWN_R1'] && `${row['BREAKDOWN_R1']}`}
                        {row['BREAKDOWN_R2'] && `${row['BREAKDOWN_R2']}`}
                        {row['BREAKDOWN_R3'] && `${row['BREAKDOWN_R3']}`}
                    </Typography>
                </TableCell>)
                break;
            case 6:
                columns.push(<>
                     {
                        filter.price_action_option === 'double_top_pattern'
                        && <BearishBullishDoublePattern first={row.points.first_high} firstTimestamp={row.points.first_high_timestamp}
                            mid={row.points.second_low} midTimestamp={row.points.second_low_timestamp} second={row.points.second_high}
                            secondTimestamp={row.points.second_high_timestamp} classes={classes} />
                    }
                    {
                        filter.price_action_option === 'double_bottom_pattern'
                        && <BearishBullishDoublePattern first={row.points.first_low} firstTimestamp={row.points.first_low_timestamp}
                            mid={row.points.first_high} midTimestamp={row.points.first_high_timestamp} second={row.points.second_low}
                            secondTimestamp={row.points.second_low_timestamp} classes={classes} />
                    }
                    {
                        filter.price_action_option === 'triple_bottom_pattern'
                        && <BearishBullishTriplePattern first={row.points.first_low} firstTimestamp={row.points.first_low_timestamp}
                            firstMid={row.points.first_high} firstMidTimestamp={row.points.first_high_timestamp}
                            second={row.points.second_low} secondTimestamp={row.points.second_low_timestamp}
                            secondMid={row.points.second_high} secondMidTimestamp={row.points.second_high_timestamp}
                            third={row.points.third_low} thirdTimestamp={row.points.third_low_timestamp} classes={classes} />
                    }
                    {
                        filter.price_action_option === 'triple_top_pattern'
                        && <BearishBullishTriplePattern first={row.points.first_high} firstTimestamp={row.points.first_high_timestamp}
                            firstMid={row.points.second_low} firstMidTimestamp={row.points.second_low_timestamp}
                            second={row.points.second_high} secondTimestamp={row.points.second_high_timestamp}
                            secondMid={row.points.third_low} secondMidTimestamp={row.points.third_low_timestamp}
                            third={row.points.third_high} thirdTimestamp={row.points.third_high_timestamp} classes={classes} />
                    }
                    {
                        filter.price_action_option === 'head_shoulder_pattern' &&
                        <BearishBullishHeadShoulderPattern
                            shoulder1={row.points.first_high} shoulder1Timestamp={row.points.first_high_timestamp}
                            firstMid={row.points.second_low} firstMidTimestamp={row.points.second_low_timestamp}
                            head={row.points.second_high} headTimestamp={row.points.second_high_timestamp}
                            secondMid={row.points.third_low} secondMidTimestamp={row.points.third_low_timestamp}
                            shoulder2={row.points.third_high} shoulder2Timestamp={row.points.third_high_timestamp}
                            classes={classes}
                        />
                    }
                    {
                        filter.price_action_option === 'inverted_head_shoulder_pattern'
                        &&
                        <BearishBullishHeadShoulderPattern
                            shoulder1={row.points.first_low} shoulder1Timestamp={row.points.first_low_timestamp}
                            firstMid={row.points.first_high} firstMidTimestamp={row.points.first_high_timestamp}
                            head={row.points.second_low} headTimestamp={row.points.second_low_timestamp}
                            secondMid={row.points.second_high} secondMidTimestamp={row.points.second_high_timestamp}
                            shoulder2={row.points.third_low} shoulder2Timestamp={row.points.third_low_timestamp}
                            classes={classes}
                        />
                    }
                    {
                        (row.points['upper_first']) &&
                        <ContinuationPatterns
                            upper_first={row.points.upper_first} upper_first_timestamp={row.points.upper_first_timestamp}
                            upper_last={row.points.upper_last} upper_last_timestamp={row.points.upper_last_timestamp}
                            lower_first={row.points.lower_first} lower_first_timestamp={row.points.lower_first_timestamp}
                            lower_last={row.points.lower_last} lower_last_timestamp={row.points.lower_last_timestamp}
                            classes={classes}
                        />
                    }
                    {
                        (row.points['top_first']) &&
                        <ContinuationPatterns
                            upper_first={row.points.top_first} upper_first_timestamp={row.points.top_first_timestamp}
                            upper_last={row.points.top_last} upper_last_timestamp={row.points.top_last_timestamp}
                            lower_first={row.points.bottom_first} lower_first_timestamp={row.points.bottom_first_timestamp}
                            lower_last={row.points.bottom_last} lower_last_timestamp={row.points.bottom_last_timestamp}
                            classes={classes}
                        />
                    }
                </>)
                break;
            case 7:
                columns.push(<>
                    <TableCell align="left">
                        <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                            {`${row.uptrend}`}
                        </Typography>
                    </TableCell>
                    <TableCell align="left">
                        <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                            {`${row.downtrend}`}
                        </Typography>
                    </TableCell>
                </>)
                break;
        }
        return columns;
    }

    return (
        <>
        <Paper>
           { firstCoin &&
            <TableContainer style={{maxHeight: "650px"}}>
                <Table stickyHeader>
                    {headers}
                    <TableBody>
                        { props.state.customFilterCoins.map((coin:any, index: number) => (
                        <TableRow key={index}>
                            <TableCell>{index+1}</TableCell>
                            <TableCell>
                                <CardHeader
                                    avatar={
                                        <Avatar
                                            src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${(rowObject(coin)).id}.png`}
                                            alt={coin.name}
                                            style={{ width: '32px', height: '32px', cursor: 'pointer' }}
                                            onClick={() => props.handleMorePopup(rowObject(coin))}
                                        >
                                        </Avatar>
                                    }
                                    title={
                                        <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                                            {coin.name}
                                        </Typography>
                                    }
                                    subheader={
                                        <Typography gutterBottom variant="body2" component="div" style={{ color: '#c6cfca', fontWeight: 'bold', fontSize: '12px' }}>
                                            {coin.symbol ? coin.symbol : coin.name.split(" ").pop()}
                                        </Typography>
                                    }
                                    style={{ padding: '0px' }}
                                />
                            </TableCell>
                            <TableCell>
                                { <div style={{ display: 'flex', flexDirection: 'column', whiteSpace: 'nowrap' }}>
                                    <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                                        {coin.price}
                                    </Typography>
                                    <div style={{ display: 'flex', alignItems: "center" }}>
                                        {coin.percent_change_24h.toString().charAt(0) === "-" ? <ArrowDropDownIcon style={{ color: '#E86A3F', fontSize: '12px', fontWeight: 'bold' }} /> : <ArrowDropUpIcon style={{ color: '#01A24E', fontSize: '12px', fontWeight: 'bold' }} />}
                                        <Typography
                                            gutterBottom
                                            variant="body2"
                                            component="div"
                                            style={{ color: coin.percent_change_24h.toString().charAt(0) === "-" ? '#E86A3F' : '#01A24E', fontWeight: 'bold',  fontSize: '12px' }}
                                        > 
                                            {removePlusMinusSymbol(coin.percent_change_24h) + " (24H)"}
                                        </Typography>
                                    </div>
                                </div>}
                            </TableCell>
                            {displaySeparateColumns(coin)}
                            { displayHighLow(coin)}
                            { (coin.volume_data?.id || coin.indicator_data?.id ) && <>
                                <TableCell>
                                    <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                                        {coin['volume_data'] ? coin['volume_data'].volume : coin['indicator_data'].volume }
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                                    {coin['volume_data'] ? coin['volume_data'].circulation : coin['indicator_data'].circulation }
                                    </Typography>
                                </TableCell></>}
                            <TableCell>
                                <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                                    {coin.market_cap}
                                </Typography>
                            </TableCell>
                            <TableCell align="left">
                                <Typography gutterBottom variant="body2" data-testid="moreData" component="a" style={{ fontWeight: 'bold', fontSize: '12px', cursor: 'pointer', textDecoration: 'underline', color: 'rgba(1,162,78,255)' }} onClick={(event: any) => {
                                    props.handleMorePopup(rowObject(coin));
                                }}>
                                    {`More`}
                                </Typography>
                            </TableCell>
                        </TableRow>))}
                    </TableBody>
                </Table>
            </TableContainer>}
        </Paper>
        { !firstCoin && <Typography style={{ textAlign: 'center', fontSize: '18px', fontWeight: 'bold' }}>No Data Found</Typography>}
        </>)
}

export default CustomFilterScreener;
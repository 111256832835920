import React from "react";
// Customizable Area Start
import SubscribeToProController, {
    Props
} from "./SubscribeToProController.web";

import {
    withStyles, StyleRules
} from "@material-ui/core/styles";

import { subscribeToPro, screenerPng, newsPng, education, playStoreImg } from './assets';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { MembershipTier } from "../../../components/src/types";
// Customizable Area End

const styles: StyleRules = {
    // Customizable Area Start
    textStyle: {
        fontSize: "20px",
        fontWeight: "bold",
        padding: "3% 10.5%",
        marginLeft:"15px"
    },
    root: {
        // overflowX: 'hidden',
        // height: '100vh',
        padding: "00px 22px",
        '@media (max-width: 600px)': {
            padding: '10px'
        }
    },
    featureTable: {
      '& .MuiTableBody-root': {
        '& .MuiTableRow-root': {
          '&:nth-of-type(odd)': {
            backgroundColor: localStorage.getItem('appTheme') === 'darkTheme' ? '#ffffff17' : '#eefff7',
          },
        }
      },
      '& .MuiTableCell-root': {
          borderBottom: 'none',
      },
      '& .MuiTableRow-head': {
        backgroundColor: 'rgba(1,162,78,255)',
        fontWeight: 'bold',
        '& .MuiTableCell-head': {
          borderRight: '1px solid rgba(224, 224, 224, 1)',
          color: '#ffffff',
          '&:first-child': {
            borderLeft: '1px solid rgba(224, 224, 224, 1)',
          }
        }
      },
      '& .MuiTableCell-body': {
        '& .MuiTypography-root': {
          fontSize: '16px'
        },
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        '&:first-child': {
          borderLeft: '1px solid rgba(224, 224, 224, 1)',
        }
      },            
    },
    // Customizable Area End
}

export class SubscribeToProWeb extends SubscribeToProController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { subscriptionPlansDetail } = this.state;
        const backgroundColorValue = localStorage.getItem('appTheme') == 'ligthTheme' ? '#F4F8F6' : '#232429'

        return (
            <Paper className={classes.root} data-testid='subscribeToProPaper' square elevation={0} style={{ backgroundColor: backgroundColorValue }}>
                <Paper>
                <Grid container spacing={2} style={{ padding: '0% 10%' }}>
                    <Grid container item xs={12} sm={6} direction="column" alignItems="center" justifyContent="center">
                        <div style={{ display: 'flex', flexDirection: 'row', height: '400px', justifyContent: 'center', marginBottom: '8%' }}>
                            <img  src={subscribeToPro} style={{ height: '100%', marginTop: 20  }} />
                        </div>
                        <Typography variant="h5" style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '12px', fontStyle: 'italic' }}>
                            {`Kya aapne crypto kiya?`}
                        </Typography>
                        <Button data-testid='checkoutProFeature' variant="outlined" size="small" color="primary" style={{ color: '#01A24E', borderColor: '#01A24E', fontSize: '12px', textTransform: 'none' }} onClick={() => this.scrollToTable()}>
                            Checkout Pro Features
                        </Button>
                    </Grid>
                    <Grid container item xs={12} sm={6} direction="column">
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '10%' }}>
                            <Typography variant="h5" style={{ fontSize: '28px', fontWeight: 'bold' }}>
                                {`WELCOME TO`}
                            </Typography>
                            <Typography variant="h5" style={{ fontSize: '28px', fontWeight: 'bold', marginBottom: '18px' }}>
                                {`KRYPTOKIA`}
                            </Typography>
                            <Typography variant="h5" style={{ fontSize: '13px', fontWeight: 'bold', color: 'rgb(162 165 165)', marginBottom: '12px' }}>
                                {`India's first crypto screening app`}
                            </Typography>
                            <Button data-testid='subscribeToPro' variant="contained" color="primary" style={{ backgroundColor: '#01A24E', color: '#ffffff', marginTop: '5%' }} onClick={(event: any) => {
                                window.location.href = "/SubscribeWeb";
                            }}>
                                SUBSCRIBE TO PRO
                            </Button>
                            <img src={playStoreImg} style={{ height: '60px', margin: '10px 40px 0px 0px' }} />
                        </div>
                    </Grid>
                </Grid>
                <Typography variant="h5" className={classes.textStyle}>
                    {`One Stop Solution for Crypto Traders / Investors`}
                </Typography>
                <Grid container direction="row" alignItems="center" justifyContent="center" spacing={4} style={{ padding: '0% 10%' }}>
                    <Grid item container direction="column" xs spacing={2} alignItems="center" justifyContent="center" style={{ maxWidth: 'none'}} >
                        <Card>
                            <CardContent>
                                <img src={screenerPng} style={{ width: '250px' }} />
                            </CardContent>
                            <CardActions data-testid='screenerBtn' style={{ justifyContent: 'center', backgroundColor: backgroundColorValue, cursor: 'pointer' }} onClick={(event: any) => {
                                window.location.href = "/ScreenerWeb";
                            }}>
                                <Typography variant="h5" style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                    {`Screener`}
                                </Typography>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item container direction="column" xs spacing={2} alignItems="center" justifyContent="center" style={{ maxWidth: 'none'}} >
                        <Card>
                            <CardContent>
                                <img src={newsPng} style={{ width: '250px' }} />
                            </CardContent>
                            <CardActions data-testid='newsPortalBtn' style={{ justifyContent: 'center', backgroundColor: backgroundColorValue, cursor: 'pointer' }} onClick={(event: any) => {
                                window.location.href = "/NewsPortalWeb";
                            }}>
                                <Typography variant="h5" style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                    {`News Portal`}
                                </Typography>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item container direction="column" xs spacing={2} alignItems="center" justifyContent="center" style={{ maxWidth: 'none'}} >
                        <Card>
                            <CardContent>
                                <img src={education} style={{ width: '250px' }} />
                            </CardContent>
                            <CardActions data-testid='educationCenterBtn' style={{ justifyContent: 'center', backgroundColor: backgroundColorValue, cursor: 'pointer' }} onClick={(event: any) => {
                                window.location.href = "/EducationCenterLandingWeb";
                            }}>
                                <Typography variant="h5" style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                    {`Education Center`}
                                </Typography>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ padding: '5% 8%' }}>
                    {this.state.cardsData.map((_data: any, index: any) => (
                        <Grid container key={index} item xs={12} sm={6} direction="column" >
                            <Card elevation={0} style={{ display: 'flex', minHeight: '300px', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', backgroundColor: backgroundColorValue }}>
                                <CardHeader
                                    title={
                                        <Typography variant="h5" style={{ fontSize: '22px', fontWeight: 'bold' }}>
                                            {_data.title}
                                        </Typography>
                                    }
                                />
                                <CardContent>
                                    <img src={_data.image} style={{ marginBottom: '30px', color: '#01A24E', width: '42px' }} />
                                    <Typography variant="h5" style={{ fontSize: '13px', fontWeight: 'bold', marginBottom: '4px' }}>
                                        {_data.description}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
                <Grid container spacing={2} style={{ padding: '0% 6.6% 2%' }} id="checkPro">
                    <TableContainer component={Paper} elevation={2} ref={this.tableRef}>
                        <Table aria-label="top-scans-one" size="small" className={classes.featureTable}>
                            <TableHead style={{ backgroundColor: backgroundColorValue, height: '55px' }}>
                                <TableRow>
                                    <TableCell align="left" style={{ fontSize: '16px', fontWeight: 'bold' }}>FEATURES</TableCell>
                                    <TableCell align="left" style={{ fontSize: '16px', fontWeight: 'bold' }}>BASIC</TableCell>
                                    <TableCell align="left" style={{ fontSize: '16px', fontWeight: 'bold' }}>PRO</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {subscriptionPlansDetail && this.state.tableData.map((rowData: any) => (
                                  <TableRow key={rowData.id}>
                                    <TableCell align="left">
                                      <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold' }}>
                                          {rowData.features}
                                      </Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                      <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold' }}>
                                          {subscriptionPlansDetail.basic && subscriptionPlansDetail.basic[rowData.label as keyof MembershipTier]}
                                      </Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                      <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold' }}>
                                          {subscriptionPlansDetail.pro && subscriptionPlansDetail.pro[rowData.label as keyof MembershipTier] }
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid container spacing={2} style={{ padding: '0% 6.6% 2%' }}>
                    <Grid container item xs={12} direction="column" alignItems="center" justifyContent="center" style={{backgroundColor: backgroundColorValue, padding: '30px'}}>
                        <Typography variant="h5" style={{ fontSize: '16px', fontWeight: 'bold' }}>
                            {`Don't have a Kryptokia Pro Membership yet?`}
                        </Typography>
                        <Button data-testid='subscribeBtn' variant="contained" color="primary" style={{ backgroundColor: '#01A24E', color: '#ffffff', marginTop: '2%', textTransform: "none" }} onClick={(event: any) => {
                                window.location.href = "/SubscribeWeb";
                            }}>
                            Subscribe Now
                        </Button>
                    </Grid>
                </Grid>
                </Paper>
            </Paper>
        )
        // Customizable Area End
    }
}

export default withStyles(styles)(SubscribeToProWeb);

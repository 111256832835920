Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

// Customizable Area Start
exports.loginEmailEndPoint="/bx_block_login/logins/"
exports.errorEmailNotValid = "Email not valid.";
exports.errorPasswordNotValid = "Password not valid.";
exports.placeHolderEmail = "Enter your email";
exports.placeHolderPassword = "Enter your password";
exports.placeHolderFullName="Enter full name";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.labelTitle = "Login";
exports.labelEmailErrorValid="Please enter valid email id";
exports.labelEmailError="Please enter email id";
exports.labelPasswordError="Please enter password";
exports.labelPasswordRegError="Passwords should contain three of the four character types: Uppercase letters: A-Z. Lowercase letters: a-z. Numbers: 0-9."
exports.labelFullNameError="Please enter full name";
exports.getUser = "/account_block/accounts/show"

exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";
exports.forgotPasswordText = "Forgot password ?";
// Customizable Area End
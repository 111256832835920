import React from "react";
// Customizable Area Start
import SearchChartWebController, {
    Props
} from "./SearchChartController.web";

import {
    withStyles, StyleRules
} from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import Box from '@material-ui/core/Box';
import Loader from "../../../components/src/Loader.web";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CryptoChart from "../../../components/src/CryptoChart.web";
import "chartjs-plugin-datalabels";
import {
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel
} from '@material-ui/core';
import { isEmpty } from "../../../framework/src/Utilities";
// Customizable Area End

const styles: StyleRules = {
    // Customizable Area Start
    root: {
        padding: '22px',
    },
    dialogContent: {
        height: '300px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        maxWidth: '310px',
        minWidth: '310px'
    },
    addButton: {
        backgroundColor: "rgba(1,162,78,255)",
        color: "#ffffff",
        cursor: "pointer",
        padding: "8px 20px",
        border: "none",
        borderRadius: "10px",
        fontSize: "12px",
        textAlign: "center",
        fontWeight: 'bold'
    },
    searchBar: {
        position: 'relative',
        margin: '0px 10px'
    },
    icon: {
        height: '18px',
        width: '18px',
        backgroundSize: '18px',
        position: 'absolute',
        backgroundRepeat: 'no-repeat',
        cursor: 'pointer',
        padding: '8px',
        zIndex: 9,
        color: 'rgba(1,162,78,255)'
    },
    inputField: {
        border: '1px solid #d1d1d1',
        outline: 'none',
        paddingLeft: '28px',
        borderRadius: '4px',
        boxShadow: 'inset 1px 2px 8px rgb(0 0 0 / 7%)',
        "& .MuiInputBase-input": {
            fontFamily: `"Plus Jakarta Text", sans-serif`,
            fontSize: '14px'
        }
    },
    saveBtn: {
        backgroundColor: "rgba(1,162,78,255)",
        color: "#ffffff",
        cursor: "pointer",
        padding: "8px 20px",
        border: "none",
        borderRadius: "10px",
        fontSize: "12px",
        textAlign: "center",
        '&:disabled': {
            pointerEvents: 'none',
            color: '#C6CFCA'
        }
    },
    cancelBtn: {
        backgroundColor: "#000000",
        color: "#ffffff",
        cursor: "pointer",
        padding: "8px 20px",
        border: "none",
        borderRadius: "10px",
        fontSize: "12px",
        textAlign: "center",
    },
    radioBtn: {
        '& .MuiFormControlLabel-root': {
            marginTop: "16px",
            width: "100%",
        },
        '& .MuiFormControlLabel-label': {
            width: "100%",
            fontSize: "12px",
        },
        '& .MuiRadio-colorSecondary.Mui-checked': {
            color: 'green'
        },
    },
    dialogTitle: {
        display: 'flex',
        justifyContent: 'center',
        textAlign: "center",
        padding: '16px 24px 0px'
    }
    // Customizable Area End
}
// Customizable Area Start

// Customizable Area End
export class SearchChartWeb extends SearchChartWebController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    AddDialog = () => {
        const { classes } = this.props;
        return (
            <Dialog open={this.state.searchChartDialog} className={classes.addDialog}>
                <DialogTitle className={classes.dialogTitle} >
                    Search Assets
                    <div className={classes.searchBar}>
                        <div>
                            <SearchIcon className={classes.icon} />
                            <InputBase
                                placeholder="Search by asset name"
                                className={classes.inputField}
                                value={this.state.searchKeyword}
                                onChange={this.handleSearchKeywordInputChange}
                                style={{ width: '100%' }}
                                autoFocus
                            />
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    {this.state.addDialogErrors && <Typography variant="subtitle2" paragraph color="error">{this.state.addDialogErrors}</Typography>}
                    {this.state.setDialogLoader && <Loader loading={this.state.setDialogLoader} />}
                    {!this.state.setDialogLoader && this.state.searchedCrypto.length === 0 && <Typography style={{ textAlign: 'center', fontSize: '18px', fontWeight: 'bold' }} >
                        No Data Found
                    </Typography> }
                    <FormControl component="fieldset" className={classes.radioBtn}>
                        <FormLabel component="legend"></FormLabel>
                        <RadioGroup name='searched_crypto' data-testid="select-crypto-radiogroup" value={this.state.selectedCrypto} onChange={this.handleChange}>
                            {this.state.searchedCrypto.map((item: any) => (
                                <FormControlLabel key={item.id} checked={item.id.toString() === this.state.selectedCrypto.toString()}
                                    value={item.id} control={<Radio />} label={
                                        <Grid container style={{ fontWeight: "bold" }} spacing={2}>
                                            <Grid item xs={8} style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                <Avatar style={{ height: 32, width: 32, marginRight: 5 }} src={item.image_url} />
                                                <Typography variant="inherit">{item.name}</Typography>
                                            </Grid>
                                            <Grid item xs={4} style={{ display: "flex", alignItems: "center" }}>
                                                <Typography variant="inherit">${Number(item.price).toFixed(2)}</Typography>
                                            </Grid>
                                        </Grid>
                                    } />
                            ))}
                        </RadioGroup>
                    </FormControl>

                </DialogContent>
                <DialogActions style={{ justifyContent: "center" }}>
                    <button
                        className={classes.cancelBtn}
                        data-testid="cancel-add-dialog"
                        onClick={() => this.setState({ searchChartDialog: false, addDialogErrors: null })}
                    >
                        Cancel
                    </button>
                    <button
                        className={classes.saveBtn}
                        data-testid="save-crypto-dialog-btn"
                        disabled={isEmpty(this.state.searchedCrypto)}
                        onClick={() => this.getCryptoDetail(this.state.selectedCrypto)}
                    >
                        Save
                    </button>
                </DialogActions>
            </Dialog>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        return (
            <Paper className={classes.root} data-testid='chartPaper' square style={{ backgroundColor: localStorage.getItem('appTheme') == 'ligthTheme' ? '#F4F8F6' : '#232429' }}>
                <Loader loading={this.state.loading} />
                <Paper style={{ padding: '22px' }}>
                    <Box style={{ display: "flex", justifyContent: "space-between", marginBottom: '16px' }}>
                        <Typography variant="h6" style={{ fontWeight: 900, marginLeft: 15 }}>
                            {this.state.cryptoData?.name} Chart
                        </Typography>
                        <button type="button" data-testid="add-watchlist-btn" className={classes.addButton} onClick={this.handleSearchChart}>Search Coins</button>
                        {this.state.searchChartDialog && this.AddDialog()}
                    </Box>
                    { this.state.noData && <Typography style={{ fontSize: '18px', textAlign: 'center', fontWeight: 'bold' }}>No Data Found</Typography> }
                    {this.state.cryptoData && <CryptoChart selectedData={this.state.cryptoData}
                        chartPriceData={this.state.watchlistPriceChartData}
                        chartVolumeData={this.state.watchlistVolumeChartData}
                        chartMarketCapData={this.state.watchlistMarketCapData}
                        getGraphData={this.getGraphData}
                        classes={classes} 
                        loading={this.state.chartLoading}
                        />}
                </Paper>
            </Paper>
        )
        // Customizable Area End
    }
}

export default withStyles(styles)(SearchChartWeb);
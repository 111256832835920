import React from "react";
// Customizable Area Start
import SubscribeWebController, {
  CardDetails,
    CardDetailsAttributes,
    Props, SubscriptionPlan
} from "./SubscribeController.web";

import {
    withStyles, StyleRules, createStyles, Theme
} from "@material-ui/core/styles";
import {
  ClassNameMap,
  ClassKeyOfStyles,
} from '@material-ui/styles/withStyles';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Box as MuiBox } from '@material-ui/core';
import Checkbox from "@material-ui/core/Checkbox";
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Form, Formik, Field, FieldProps, FormikErrors, FormikTouched } from "formik";
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import * as Yup from "yup";
import LoginAlertDialogWeb from "../../../components/src/LoginAlertDialog.web";
import { paypalImage, confirmImg } from "./assets";
import Divider from '@material-ui/core/Divider';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import { capitalizeText, countries, getCreditCardType } from "../../../components/src/chartUtils.web";
import Autocomplete, { AutocompleteRenderInputParams} from '@material-ui/lab/Autocomplete';
import moment from "moment";
import Radio from '@material-ui/core/Radio';
import Loader from "../../../components/src/Loader.web";
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { isEmpty } from "../../../framework/src/Utilities";

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: 'rgba(1,162,78,255)',
      color: theme.palette.common.white,
      fontWeight: 'bold',
      borderRight: '1px solid rgba(224, 224, 224, 1)'
    },
    body: {
      fontSize: 16,
      borderRight: '1px solid rgba(224, 224, 224, 1)',
      '&:first-child': {
        borderLeft: '1px solid rgba(224, 224, 224, 1)',
      }
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: localStorage.getItem('appTheme') === 'darkTheme' ? '#ffffff17' : '#eefff7',
        },
    },
  }),
)(TableRow);

export const billingInformationSchema = Yup.object().shape({
  companyName: Yup.string()
    .required('Company Name is required'),
  address: Yup.string()
    .required('Address is required'),
  city: Yup.string()
    .required('City is required'),
  zipCode: Yup.string()
    .required('Zip Code is required')
    .matches(/^\d*$/g, 'Enter only numbers'),
  country: Yup.string()
    .required('Country is required'),
  cardHolderName: Yup.string()
    .required('Name is required'),
  cardNumber: Yup.string()
    .matches(/^\d{4}\s?\d{4}\s?\d{4}\s?\d{4}$/, 'Invalid Card Number')
    .required('Card Number is required'),
  cvv: Yup.string()
    .matches(/^\d*$/g, 'Enter only numbers')
    .required('CVV is required'),
  expiryMonth: Yup.string()
    .required('Month is required'),
  expiryYear: Yup.string()
      .required('Year is required'),
}).test('expiryMonth', 'Select future month', (values: BillingInfoProps) => {
  const { expiryMonth, expiryYear } = values;
  if (expiryMonth && expiryYear) {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
    if (expiryYear === currentYear.toString() && expiryMonth < currentMonth.toString().padStart(2, '0')) {
      throw new Yup.ValidationError('Select future month', values, 'expiryMonth');
    }
  }
  return true;
});

interface BillingInfoProps {
  companyName: string;
  address:  string;
  city:  string;
  zipCode: string;
  country: string;
  vatNumber: string;
  cardNumber: string;
  cardHolderName: string;
  expiryMonth: string;
  expiryYear: string;
  cvv: string;
}

interface DeleteDialogProps {
  classes: ClassNameMap<ClassKeyOfStyles<string>>;
  open: boolean,
  handleClose: () => void;
  removeBillingInformation: () => void;
}

const DeleteConfirmationDialogWeb:React.FC<DeleteDialogProps> = ({ classes, handleClose, open, removeBillingInformation }) => {
  const bgColor = localStorage.getItem('appTheme') == 'darkTheme' ? '#363333' : '#dadada';
  const color = localStorage.getItem('appTheme') == 'darkTheme' ? '#FFFFFF' : '#000000';
  return (
      <Dialog style={{ minHeight: "185px" }} data-testid="close-dialog" open={open} aria-labelledby="delete-confirmation-dialog">
          <DialogContent className={classes.dialogTitle}>
              Are you sure you want to delete billing information? 
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
              <button
              className={classes.discardButton}
              data-testid="cancelDeleteBtn"
              style={{ backgroundColor: bgColor, color: color }} 
              onClick={handleClose}
              >
                  Discard
              </button>
              <button 
              className={classes.confirmButton} data-testid="confirmDeleteBtn"
              onClick={removeBillingInformation}>Confirm</button>
          </DialogActions>
      </Dialog>
  )
}

// Customizable Area End

const styles: StyleRules = {
    // Customizable Area Start
    root: {
        padding: "00px 22px",
        '@media (max-width: 600px)': {
            padding: '10px'
        },
        '& .MuiTypography-root': {
            fontWeight: 'bold',
        },
        '& .MuiInputBase-input': {
            border: '1px solid #7fd0a6',
            paddingTop: '10px',
            paddingBottom: '10px'
        },
        '& .MuiCheckbox-root': {
            color: '#7fd0a6'
        }
    },
    continueBtn: {
        background: 'rgba(1,162,78,255)',
        color: '#ffffff', 
        textTransform: 'none',
        fontSize: '16px',
        borderRadius: '20px',
        "&:hover": {
            background: 'rgba(1,162,78,255)'
        }
    },
    billingCycleToggle: {
      borderRadius: '30px',
      padding: '5px',
      textTransform: 'none',
      fontSize: '16px',
      "&.Mui-selected": {
        backgroundColor: 'transparent',
        "& .MuiToggleButton-label": {
          background: 'rgba(1,162,78,255)',
          color: '#ffffff', 
          borderRadius: '30px',
          padding: '5px 15px'
        }
      }
    },
    getStartedBtn: {
        color: 'rgba(1,162,78,255)',
        textTransform: 'none',
        fontSize: '16px',
        borderRadius: '20px',
    },
    tryAgainBtn: {
      color: 'rgba(1,162,78,255)',
      textTransform: 'none',
      fontSize: '16px',
      borderRadius: '20px',
      border: '2px solid rgba(1,162,78,255)',
      padding: '5px 50px'
    },
    billingFormBox: {
        border: "1px solid #c7cbc9",
        padding: "10px 10px 10px 20px",
        backgroundColor: '#f4f8f6',
    },
    heading: {
        display: 'flex',
        alignItems: "center",
        justifyContent: 'space-between',
        margin: 0
    },
    changePlanBtn: {
        textTransform: 'none',
        fontSize: '12px',
        color: '#4a75e4',
        padding: 0
    },
    stepper: {
      "& .MuiStepIcon-root.MuiStepIcon-active": {
        color: 'rgba(1,162,78,255)'
      },
      "& .MuiStepIcon-completed": {
        color: 'rgba(1,162,78,255)'
      },
      "& .MuiStepConnector-line": {
        borderWidth: '3px'
      },
      "& .MuiStepConnector-active": {
        "& .MuiStepConnector-line": {
          borderColor: 'rgba(1,162,78,255)',
        }
      },
      "& .MuiStepConnector-completed": {
        "& .MuiStepConnector-line": {
          borderColor: 'rgba(1,162,78,255)',
        }
      },
      "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
        fontSize: '16px'
      },
      "& .MuiStepLabel-label.MuiStepLabel-active": {
        fontWeight: 'bold',
        fontSize: '16px'
      }
    },
    paymentImage: {
        height: '64px'
    },
    divider: {
        margin: '0 -10px 0 -20px'
    },
    expDate: {
        marginBottom: '-15px',
        "@media (max-width: 600px)": {
            marginBottom: 0
        }
    },
    confirmationBox: {
      display: 'flex', 
      flexDirection: 'column', 
      margin: '20px auto', 
      alignItems: 'center', 
      textAlign: 'center'
    },
    checkboxTxt: {
      fontSize: 16,
      "@media (max-width: 768px)": {
        fontSize: 14
    }
    },
    countryDropdown: {
      "& .MuiInputBase-input": {
        border: 'none',
        padding: 0
      },
      "& .MuiAutocomplete-inputRoot[class*='MuiFilledInput-root']": {
        padding: '1px 0',
        borderRadius: 'unset',
        border: '1px solid #7fd0a6',
      }
    },
    addEditBtn: {
      marginLeft: 20,
      marginTop: 10,
      border: '2px solid #01A24E',
      color: '#01A24E',
      fontWeight: 'bold',
      borderRadius: 'unset',
      textTransform: 'none'
    },
    addBackBtn: {
      marginLeft: 8,
      marginBottom: 15
    },
    dialogTitle: {
      textAlign: 'center',
      fontSize: 16,
      '& .MuiTypography-root': {
          fontWeight: 'bold'
      },
  },
  discardButton: {
    backgroundColor: '#dadada',
    color: '#000000',
    cursor: 'pointer',
    padding: '10px 90px',
    margin: '0px 10px',
    whiteSpace: 'nowrap',
    border: 'none',
    borderRadius: '20px',
    textAlign: 'center',
    fontSize: '16px',
    '@media (max-width: 768px)': {
        padding: '10px 20px',
    }
  },
  confirmButton: {
    backgroundColor: 'rgba(1,162,78,255)',
    color: '#ffffff',
    cursor: 'pointer',
    padding: '10px 90px',
    margin: '0px 10px',
    border: 'none',
    borderRadius: '20px',
    textAlign: 'center',
    fontSize: '16px',
    '@media (max-width: 768px)': {
        padding: '10px 20px',
    }
  },
  dialogActions: {
    justifyContent: 'center', 
    marginBottom: 16
  },
  discountTextStyle: {
    fontWeight: "bold",
    marginRight: "5px",
    padding: "5px"
  }
    // Customizable Area End
}

export class SubscribeWeb extends SubscribeWebController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    existingDataBlock() {
      const { classes } = this.props;
      const { cardDetails, selectedAddress, addNew } = this.state;

      const displayData = (cardData: CardDetailsAttributes) => {
        let cardNumber = "", expiry, companyName = "", billingInfo = "";
        if (cardData) {
          const masked = '****' + cardData.card_number.slice(12);
          cardNumber = masked.replace(/(.{4})/g, '$1 ').trim();
          expiry = moment(cardData.year.toString() + cardData.month.toString().padStart(2, '0'), 'YYYYMM').format('MMM YYYY')
          companyName = cardData.company_name ?? "";
          let addressArr:Array<string> = [];
          cardData.address && addressArr.push(cardData.address);
          cardData.city &&  addressArr.push(cardData.city);
          cardData.country &&  addressArr.push(cardData.country);
          cardData.postal_code &&  addressArr.push(cardData.postal_code);
          billingInfo = addressArr.join(", ");
        }
        return { cardNumber, expiry, companyName, billingInfo };
      }

      return (
        <Grid container xs={12} sm={12} spacing={2}>
          {!addNew && cardDetails.map((cardDetail: CardDetails) => {
            const formattedData = displayData(cardDetail.attributes);
            return (<Grid style={{ display: 'flex' }} item xs={12} sm={12} key={cardDetail.id}>
              <Radio
                data-testid={`existingCard${cardDetail.id}`}
                checked={selectedAddress?.toString() === cardDetail.id.toString()}
                style={{ color: '#04a14e'}}
                onChange={() => this.handleExistingAddress(cardDetail.id)}
                value={cardDetail.id}
                name="billing-info"
                inputProps={{ 'aria-label': cardDetail.id }}
              />
              {cardDetail.attributes.company_name && <Grid item xs={12} sm={12}>
                <Typography>{cardDetail.attributes.company_name} {cardDetail.attributes.vat_number}</Typography>
                <Typography style={{fontWeight: 'normal'}}>{formattedData.billingInfo}</Typography>
              </Grid>}
              <Grid item xs={12} sm={12}>
                <Typography>{getCreditCardType(cardDetail.attributes.card_number)} {formattedData.cardNumber}</Typography>
                <Typography style={{fontWeight: 'normal'}}>Expires in {formattedData.expiry}</Typography>
              </Grid>
              {isEmpty(cardDetail.attributes.company_name) && <Grid item xs={12} sm={12} />}
              <Grid item xs={12} sm={12}>
                <IconButton data-testid={`deleteCard${cardDetail.id}`} aria-label="delete" title="delete" onClick={() => this.removeBillingInformationConfirmation(cardDetail.id)}> 
                  <DeleteIcon htmlColor={ localStorage.getItem("appTheme") === "darkTheme" ? "#ffffff" :"#000000"} />
                </IconButton>
              </Grid>
            </Grid>)
          })}
          {!addNew && 
            <Grid item xs={12} sm={12}><Button data-testid="addNew" className={classes.addEditBtn} variant="outlined" onClick={() => this.handleAddNew(true)}>+ Add New</Button></Grid>}
          { addNew && cardDetails.length > 0 && <Button className={`${classes.addEditBtn} ${classes.addBackBtn}`} variant="outlined" data-testid="addBackBtn" onClick={() => this.handleAddNew(false)}>
            Use Existing Cards</Button>}
        </Grid>
      )
    }

    planList() {
        const { classes } = this.props;
        const { subscriptionPlansDetail, billingCycle, userProfile, subscriptionPlans } = this.state;
        const freeTrial = subscriptionPlans.find((plan: SubscriptionPlan) => plan.attributes.subscription_type === 'free_trial')
       
        return (
            <TableContainer component={Paper} elevation={0}>
                <Table aria-label="top-scans-one" size="small">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="left" >FEATURES</StyledTableCell>
                              <StyledTableCell align="center">BASIC</StyledTableCell>
                              <StyledTableCell align="center">PRO</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {subscriptionPlansDetail && this.state.tableData.map((rowData: { id: number, label: string, features: string, basic: string, pro: string },
) => (
                            <StyledTableRow key={rowData.id}>
                                <StyledTableCell align="left">
                                    <Typography style={{ fontWeight: 'bold'}} gutterBottom variant="body1" component="div">
                                        {rowData.features}
                                    </Typography>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    <Typography style={{ fontWeight: 'bold'}} gutterBottom variant="body1" component="div">
                                        {subscriptionPlansDetail.basic && subscriptionPlansDetail.basic[rowData.label]}
                                    </Typography>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    <Typography style={{ fontWeight: 'bold'}} gutterBottom variant="body1" component="div">
                                        {subscriptionPlansDetail.pro && subscriptionPlansDetail.pro[rowData.label]}
                                    </Typography>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                        <StyledTableRow>
                          <StyledTableCell align="left">
                            <ToggleButtonGroup
                              value={billingCycle}
                              exclusive
                              data-testid="billingCycleToggle"
                              onChange={this.handleBillingCycle}                       
                                  aria-label="text alignment"
                            >
                              <ToggleButton data-testid="monthlyToggle" className={classes.billingCycleToggle} value="monthly" aria-label="montly">
                                Monthly
                              </ToggleButton>
                              <ToggleButton data-testid="yearlyToggle" className={classes.billingCycleToggle} value="yearly" aria-label="yearly">
                                Annualy
                              </ToggleButton>
                            </ToggleButtonGroup>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <Typography style={{ fontWeight: 'bold'}} gutterBottom variant="body1" component="div">
                                {subscriptionPlansDetail && subscriptionPlansDetail.basic?.cost}
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {this.renderAmount()}
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <StyledTableCell>
                                <Button data-testid="joinFreeTrial" disabled={userProfile && userProfile.attributes.has_taken_free_trial} className={classes.getStartedBtn} variant="outlined" onClick={() => this.joinTrialPlan()}>Join {freeTrial?.attributes?.free_trial_period} Days Free Trial</Button>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                <Button data-testid="getStarted" className={classes.getStartedBtn} variant="outlined" onClick={() => this.startBasicPlan()}>Get Started</Button>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                <Button data-testid="continuePro" className={classes.continueBtn} variant="outlined" onClick={() => this.startProPlan()}>Continue</Button>
                            </StyledTableCell>
                        </StyledTableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    renderAmount = () => {
      const { classes } = this.props;
      const { subscriptionPlansDetail, billingCycle} = this.state;
      return(
        <>
          {
            this.state.billingCycle === "yearly"
              ?
              <>
                <del>{subscriptionPlansDetail && (subscriptionPlansDetail.pro || {})[`${billingCycle}_cost`]}</del>
                <span className={classes.discountTextStyle}>
                  {subscriptionPlansDetail?.pro?.yearly_discounted_cost}
                </span>
              </>
              :
              <>
                <del>{subscriptionPlansDetail && (subscriptionPlansDetail.pro || {})[`${billingCycle}_cost`]}</del>
                <span className={classes.discountTextStyle}>
                  {subscriptionPlansDetail?.pro?.monthly_discounted_cost}
                </span>
              </>
          }
        </>
      )
    }

    orderSummary(backgroundColorValue: string) {
      const { classes } = this.props;
      const { selectedSubscription } = this.state;

      return (
        <Grid item xs={12} sm={4}>
            <MuiBox className={classes.billingFormBox} style={{ backgroundColor: backgroundColorValue }}>
              <Grid container xs={12} sm={12} style={{ padding: '5px 0', backgroundColor: backgroundColorValue  }}>
                <Grid item xs={8} sm={8}>
                    <Typography>Order Summary</Typography>
                    {selectedSubscription && <Typography>{selectedSubscription.name} Plan</Typography>}
                </Grid>
                <Grid item xs={4} sm={4}>
                  <Button data-testid="changePlan" className={classes.changePlanBtn} onClick={() => this.changePlan()}>Change Plan</Button>
                </Grid>
              </Grid>
              <Divider className={classes.divider} />
              <Grid container xs={12} sm={12} style={{ padding: '10px 0'}}>
                <Grid item xs={8} sm={8}>
                  <Typography>Regular Price</Typography>
                </Grid>
                <Grid item xs={4} sm={4}>
                  ${this.getRegularPrice()}
                </Grid>
              </Grid>
              <Grid container xs={12} sm={12} style={{color: 'red', padding: '10px 0'}}>
                <Grid item xs={8} sm={8}>
                  <Typography>Discount</Typography>
                </Grid>
                <Grid item xs={4} sm={4}>
                  ${this.getDiscount()}
                </Grid>
              </Grid>
              <Grid container xs={12} sm={12} style={{ padding: '10px 0'}}>
                <Grid item  xs={8} sm={8}>
                  <Typography>Tax & GST</Typography>
                </Grid>
                <Grid item sm={4}>
                  $0.0
                </Grid>
              </Grid>
              <Grid container xs={12} sm={12} style={{ borderTop: '1px solid #c7cbc9', padding: '10px 0'}}>
                <Grid item xs={8} sm={8}>
                  <Typography>Total Amount</Typography>
                </Grid>
                <Grid item xs={4} sm={4}>
                  ${this.getTotalAmount()}
                </Grid>
              </Grid>
              <Button fullWidth data-testid="payAmountBtn" variant="outlined" type="submit" className={classes.continueBtn}>Pay ${this.getTotalAmount()}</Button>                             
            </MuiBox>
        </Grid>
      )
    }

    cardForm(values: BillingInfoProps,
      handleChange: (event: React.ChangeEvent<HTMLInputElement> ) => void, 
      handleBlur: ( event: React.FocusEvent<HTMLInputElement>) => void ) {

        const { classes } = this.props;
        const expirationMargin = (errors: FormikErrors<BillingInfoProps>, touched: FormikTouched<BillingInfoProps>) => {
          if (errors) {
            if ((!!errors.expiryMonth && touched.expiryMonth) || (!!errors.expiryYear && touched.expiryYear)) {
              return '-8px';
            }
            if (!!errors.cvv && touched.cvv) {
              return '-26px';
            }
          }
          return '-18px';
        }

      return (
        <div style={{ width: '100%', marginTop: '10px'}}>
          <Grid container xs={12} sm={12} spacing={2}>                                    
            <Grid item xs={6} sm={6}>
                <Typography>Card Number</Typography>
                <Field
                    validateOnBlur
                    validateOnChange
                    name="cardNumber"
                    render={({ field, form }: FieldProps<BillingInfoProps>) => (
                        <TextField
                            hiddenLabel
                            margin="none"
                            name={"cardNumber"}
                            value={values.cardNumber}
                            variant="filled"
                            inputProps={{ maxLength: 19 }}
                            InputProps={{ disableUnderline: true }}
                            InputLabelProps={{ color: 'primary' }}
                            error={
                                Boolean(form.errors.cardNumber && form.touched.cardNumber)
                            }
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              const cardNumber = event.target.value;
                              const formattedValue = cardNumber.replace(/\D/g, '').replace(/(\d{4})(?=\d)/g, '$1 '); 
                              form.setFieldValue(field.name, formattedValue);
                            }}
                            onBlur={handleBlur}
                            helperText={
                                form.errors.cardNumber &&
                                form.touched.cardNumber &&
                                String(form.errors.cardNumber)
                            }
                            style={{ width: '100%', marginBottom: Boolean(form.errors.cardNumber && form.touched.cardNumber) ? 0 : '10px'}}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={6} sm={6}>
                <Typography>Card Holder Name</Typography>
                <Field
                    validateOnBlur
                    validateOnChange
                    name="cardHolderName"
                    render={({ field, form }: FieldProps<BillingInfoProps>) => (
                        <TextField
                            hiddenLabel
                            margin="none"
                            name={"cardHolderName"}
                            value={values.cardHolderName}
                            variant="filled"
                            InputProps={{ disableUnderline: true }}
                            InputLabelProps={{ color: 'primary' }}
                            error={
                                Boolean(form.errors.cardHolderName && form.touched.cardHolderName)
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={
                                form.errors.cardHolderName &&
                                form.touched.cardHolderName &&
                                String(form.errors.cardHolderName)
                            }
                            style={{ marginBottom: Boolean(form.errors.cardHolderName && form.touched.cardHolderName) ? 0 : '10px', width: '100%' }}
                        />
                    )}
                />
            </Grid>
          </Grid>
          <Grid container xs={12} sm={12} spacing={2}>
              <Grid container item xs={6} sm={6} spacing={1}>
                <Grid item xs={12} sm={12}>
                  <Typography>Expiration Date</Typography>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Field
                    validateOnBlur
                    validateOnChange
                    name="expiryMonth"
                    render={({ field, form }: FieldProps<BillingInfoProps>) => (
                      <TextField
                          id="month"
                          name="expiryMonth"
                          select
                          hiddenLabel
                          margin="none"
                          value={values.expiryMonth}
                          onChange={handleChange}
                          fullWidth
                          variant="filled"
                          InputProps={{ disableUnderline: true }}
                          SelectProps={{
                              MenuProps: {
                              anchorPosition: {
                                  top: 245,
                                  left: 500
                              },
                              },
                          }}
                          onBlur={handleBlur}
                          helperText={
                              (form.errors.expiryMonth &&
                              form.touched.expiryMonth &&
                              String(form.errors.expiryMonth))
                          }
                          error={
                              Boolean((form.errors.expiryMonth && form.touched.expiryMonth))
                          }
                          style={{ marginTop: expirationMargin(form.errors, form.touched)}}
                      >
                          {this.state.expMonth.map((month: string) => (
                              <MenuItem key={month} value={month}>
                                  {month}
                              </MenuItem>
                          ))}
                      </TextField>
                    )} />
                  </Grid>
                <Grid item xs={6} sm={6}>
                  <Field
                      validateOnBlur
                      validateOnChange
                      name="expiryYear"
                      render={({ field, form }: FieldProps<BillingInfoProps>) => (
                        <TextField
                            id="year"
                            name="expiryYear"
                            select
                            hiddenLabel
                            margin="none"
                            value={values.expiryYear}
                            onChange={handleChange}
                            fullWidth
                            variant="filled"
                            InputProps={{ disableUnderline: true }}
                            helperText={
                                form.errors.expiryYear &&
                                form.touched.expiryYear &&
                                String(form.errors.expiryYear)
                            }
                            error={
                                Boolean(form.errors.expiryYear && form.touched.expiryYear)
                            }
                            style={{ marginTop: expirationMargin(form.errors, form.touched) }}
                        >
                            {this.state.expYear.map((year: string | number) => (
                                <MenuItem key={year} value={year}>
                                    {year}
                                </MenuItem>
                            ))}
                        </TextField>
                    )} /></Grid>
              </Grid>
              <Grid item xs={4} sm={4} style={{ marginLeft: 8}}>
                  <Typography>CVV</Typography>
                  <Field
                      validateOnBlur
                      validateOnChange
                      name="cvv"
                      render={({ field, form }: FieldProps<BillingInfoProps>) => (
                          <TextField
                              name={"cvv"}
                              margin="none"
                              hiddenLabel
                              value={values.cvv}
                              variant="filled"
                              InputProps={{ disableUnderline: true }}
                              InputLabelProps={{ color: 'primary' }}
                              inputProps={{ maxLength: 3 }}
                              error={
                                  Boolean(form.errors.cvv && form.touched.cvv)
                              }
                              onChange={handleChange}
                              onBlur={handleBlur}
                              helperText={
                                  form.errors.cvv &&
                                  form.touched.cvv &&
                                  String(form.errors.cvv)
                              }
                              style={{ marginBottom: Boolean(form.errors.cvv && form.touched.cvv) ? 0 : '10px', width: '100%' }}
                          />
                      )}
                  />
              </Grid>
          </Grid>
          <Grid container xs={12} sm={12} style={{ display: 'flex', alignItems: 'center'}}>
            <Checkbox style={{ paddingLeft: 0 }}  data-testid="saveCard" color="default" onChange={() => this.handleSaveAddress()} name="name" value={this.state.saveCardAndBilling} />
            <span className={classes.checkboxTxt}>Save address and card for future payments</span>
          </Grid>
        </div>
      )
    }

    paymentForm(backgroundColorValue: string) {
      const { classes } = this.props;
      const { addNew, selectedAddress } = this.state;

      const marginBottom = (value: boolean) => {
        return value ? 0 : '10px';
      }

      const isCompanyNameError = (companyNameError: FormikErrors<BillingInfoProps>, companyNameTouched: FormikTouched<BillingInfoProps>) => Boolean(companyNameError.companyName && companyNameTouched.companyName)
      const isAddressError = (addressError: FormikErrors<BillingInfoProps>, addressTouched: FormikTouched<BillingInfoProps>) => Boolean(addressError.address && addressTouched.address)
      const isCityError = (cityNameError: FormikErrors<BillingInfoProps>, cityTouched: FormikTouched<BillingInfoProps>) => Boolean(cityNameError.city && cityTouched.city)
      const isZipcodeError = (zipcodeError: FormikErrors<BillingInfoProps>, zipcodeTouched: FormikTouched<BillingInfoProps>) => Boolean(zipcodeError.zipCode && zipcodeTouched.zipCode)
      const isCountryError = (countryNameError: FormikErrors<BillingInfoProps>, countryTouched: FormikTouched<BillingInfoProps>) => Boolean(countryNameError.country && countryTouched.country)
      const isVatNumberError = (vatNumberError: FormikErrors<BillingInfoProps>, vatNumberTouched: FormikTouched<BillingInfoProps>) => Boolean(vatNumberError.vatNumber && vatNumberTouched.vatNumber)

      return (
        <Grid container spacing={2}>
          <Formik
            initialValues={{
                companyName: "",
                address:  "",
                city:  "",
                zipCode: "",
                country: "",
                vatNumber: "",
                cardNumber: "",
                cardHolderName: "",
                expiryMonth: "",
                expiryYear: "",
                cvv: ""
            }}
            validationSchema={!selectedAddress && billingInformationSchema}
            onSubmit={(values) => {
                this.createOrUpdateBillingInformation(values);
            }}
        >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit
            }) => (
                <Form style={{ width: '100%' }} onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item  xs={12} sm={8} spacing={2}>
                      <MuiBox className={classes.billingFormBox} style={{ backgroundColor: backgroundColorValue}}>
                        <Grid container xs={12} sm={12} style={{ padding: '10px 0' }}>
                            <Grid item>
                                <Typography className={classes.heading}>BILLING INFORMATION</Typography>
                            </Grid>
                        </Grid>
                        <Divider className={classes.divider} />
                        <Grid container xs={12} sm={12} style={{ padding: '10px 0' }}>
                          { this.existingDataBlock() }
                          {addNew && <>
                            <Grid container xs={12} sm={12} spacing={2}>
                                <Grid item xs={6} sm={6}>
                                  <Typography>Company Name</Typography>
                                    <Field
                                        validateOnBlur
                                        validateOnChange
                                        name="companyName"
                                        render={({ field, form }: FieldProps<BillingInfoProps>) => (
                                            <TextField
                                                hiddenLabel
                                                name={"companyName"}
                                                margin="none" 
                                                value={values.companyName}
                                                variant="filled"
                                                InputProps={{ disableUnderline: true }}
                                                error={
                                                  isCompanyNameError(form.errors, form.touched)
                                                }
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                helperText={
                                                    form.errors.companyName &&
                                                    form.touched.companyName &&
                                                    String(form.errors.companyName)
                                                }
                                                style={{ width: '100%', marginBottom: marginBottom(isCompanyNameError(form.errors, form.touched)) }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                  <Typography>Address</Typography>
                                    <Field
                                        validateOnBlur
                                        validateOnChange
                                        name="address"
                                        render={({ field, form }: FieldProps<BillingInfoProps>) => (
                                          <>
                                            <TextField
                                              hiddenLabel
                                              name={"address"}
                                              value={values.address}
                                              variant="filled"
                                              margin="none"
                                              InputProps={{ disableUnderline: true }}
                                              InputLabelProps={{ color: 'primary' }}
                                              error={
                                                  isAddressError(form.errors, form.touched)
                                              }
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              helperText={
                                                  form.errors.address &&
                                                  form.touched.address &&
                                                  String(form.errors.address)
                                              }
                                              style={{ width: '100%', marginBottom: marginBottom(isAddressError(form.errors, form.touched)) }}
                                            />
                                          </>
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container xs={12} sm={12} spacing={2}>
                                <Grid item xs={6} sm={6}>
                                  <Typography>City</Typography>
                                    <Field
                                        validateOnBlur
                                        validateOnChange
                                        name="city"
                                        render={({ field, form }: FieldProps<BillingInfoProps>) => (
                                          <>
                                            <TextField
                                                hiddenLabel
                                                name={"city"}
                                                margin="none"
                                                value={values.city}
                                                variant="filled"
                                                InputProps={{ disableUnderline: true }}
                                                InputLabelProps={{ color: 'primary' }}
                                                error={
                                                    isCityError(form.errors, form.touched)
                                                }
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                helperText={
                                                    form.errors.city &&
                                                    form.touched.city &&
                                                    String(form.errors.city)
                                                }
                                                style={{ width: '100%', marginBottom: marginBottom(isCityError(form.errors, form.touched)) }}
                                            />
                                          </>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                  <Typography>Zip Code</Typography>
                                    <Field
                                        validateOnBlur
                                        validateOnChange
                                        name="zipCode"
                                        render={({ field, form }: FieldProps<BillingInfoProps>) => (
                                          <>
                                            <TextField
                                                hiddenLabel
                                                margin="none"
                                                name={"zipCode"}
                                                value={values.zipCode}
                                                variant="filled"
                                                InputProps={{ disableUnderline: true }}
                                                InputLabelProps={{ color: 'primary' }}
                                                error={
                                                    isZipcodeError(form.errors, form.touched)
                                                }
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                helperText={
                                                    form.errors.zipCode &&
                                                    form.touched.zipCode &&
                                                    String(form.errors.zipCode)
                                                }
                                                style={{ marginBottom: marginBottom(isZipcodeError(form.errors, form.touched)), width: '100%' }}
                                            />
                                          </>
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container xs={12} sm={12} spacing={2}>
                                <Grid item xs={6} sm={6}>
                                    <Typography>Country</Typography>
                                    <Field
                                        validateOnBlur
                                        validateOnChange
                                        name="Country"
                                        render={({ field, form }: FieldProps<BillingInfoProps>) => (
                                          <Autocomplete
                                            options={countries}
                                            getOptionLabel={(option: { name: string, code: string}) => option.name}
                                            className={classes.countryDropdown}
                                            disableListWrap
                                            onChange={(event, newValue) => {
                                              form.setFieldValue('country', newValue ? newValue.name : '', true);
                                            }}
                                            onBlur={handleBlur}
                            
                                            renderInput={(params: AutocompleteRenderInputParams) => 
                                              <TextField
                                              {...params} 
                                              hiddenLabel
                                              margin="none"
                                              name={"country"}
                                              value={values.country}
                                              variant="filled"
                                              InputProps={{ ...params.InputProps, disableUnderline: true }}
                                              InputLabelProps={{ ...params.InputLabelProps, color: 'primary'}}
                                              error={
                                                  isCountryError(form.errors, form.touched)
                                              }
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              helperText={
                                                  form.errors.country &&
                                                  form.touched.country &&
                                                  String(form.errors.country)
                                              }
                                              style={{ width: '100%', marginBottom: marginBottom(isCountryError(form.errors, form.touched)), marginRight: '15px' }}
                                            />
                                            }
                                          />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <Typography>VAT Number</Typography>
                                    <Field
                                        validateOnBlur
                                        validateOnChange
                                        name="vatNumber"
                                        render={({ field, form }: FieldProps<BillingInfoProps>) => (
                                            <TextField
                                                hiddenLabel
                                                margin="none"
                                                name={"vatNumber"}
                                                value={values.vatNumber}
                                                variant="filled"
                                                InputProps={{ disableUnderline: true }}
                                                InputLabelProps={{ color: 'primary' }}
                                                error={
                                                    isVatNumberError(form.errors, form.touched)
                                                }
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                helperText={
                                                    form.errors.vatNumber &&
                                                    form.touched.vatNumber &&
                                                    String(form.errors.vatNumber)
                                                }
                                                style={{ marginBottom: marginBottom(isVatNumberError(form.errors, form.touched)), width: '100%' }}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography className={classes.heading}>PAYMENT DETAILS
                                    <img className={classes.paymentImage} src={paypalImage} />
                                </Typography>
                            </Grid>
                            <Divider className={classes.divider} />
                            {this.cardForm(values, handleChange, handleBlur)}
                          </>}
                        </Grid>
                      </MuiBox>
                    </Grid>
                    { this.orderSummary(backgroundColorValue)}
                  </Grid>
                </Form>
              )}
          </Formik>
        </Grid>
      )
    }

    confirmationPage() {
      const { selectedSubscription, userProfile, transactionId } = this.state;
      const { classes } = this.props;
      
      return (
        <div className={classes.confirmationBox}>
          <Typography variant="h5">
            { selectedSubscription.subscription_type === 'pro' ?
             `Congrats! Your ${selectedSubscription?.name} Plan is now active` : `Let's get started, ${capitalizeText(userProfile.attributes?.fullname)}`  }
          </Typography>
          <Typography variant="body1" style={{ width: '55%', margin: '10px 0' }}>
          { selectedSubscription.subscription_type === 'pro' ? `Payment successfully completed! You're now a member of Kryptokia, you can start using our platform` :
          `Today is the start of your free trial, We're excited to show you our award-winning & user friendly software!` }
          </Typography>
          <Typography variant="body1" style={{ color: '#a9a9a9'}}>
            { selectedSubscription.subscription_type === 'pro' ? `Transaction Id: ${transactionId}` : `We'll remind you before your free trial ends`}
          </Typography>
          {selectedSubscription.subscription_type === 'free_trial' && <Typography variant="body1">Auto renew is on, you can cancel anytime</Typography>}
          <img src={confirmImg} style={{  marginTop: 20, marginBottom: 20 }}/>
          <Button data-testid="gotoDashboardBtn" className={classes.continueBtn} variant="contained" onClick={() => this.navigateToScreen('LandingPageWeb')}>Go To Dashboard</Button>
        </div>
      )
    }

    failurePage() {
      const { classes } = this.props;
      return (
        <div className={classes.confirmationBox}>
          <ErrorOutlineOutlinedIcon color="error" style={{ fontSize: 120, marginBottom: 20 }} />
          <Typography variant="body1" style={{ marginBottom: 10 }}>
            Sorry, we weren't able to complete your payment at this time. Please try again later
          </Typography>
          <Typography style={{ display: 'flex', alignItems: 'center', margin: '10px 0 40px 0', color: '#a9a9a9' }} variant="body1">If you continue to encounter problems, please contact <Button color="primary" variant="text" style={{ fontSize: '16px', textTransform: 'none' }}>customer support </Button></Typography>
          <Button data-testid="tryAgainBtn" className={classes.tryAgainBtn} variant="outlined" onClick={() => this.handleTryAgain() }>Try Again</Button>
        </div>
      )
    }
    
    // Customizable Area End
    render() {
      // Customizable Area Start
      const { classes } = this.props;
      const { steps, activeStep, subscriptionStatus, showConfirmation } = this.state;
      const backgroundColorValue = localStorage.getItem('appTheme') == 'ligthTheme' ? '#F4F8F6' : '#232429'

      return (
        <Paper className={classes.root} data-testid='subscribePaper' square elevation={0} style={{ backgroundColor: backgroundColorValue }}>
          <Paper>
            {!this.state.token && <LoginAlertDialogWeb />}
            {this.state.loading && <Loader loading={this.state.loading} />}
            {showConfirmation && <DeleteConfirmationDialogWeb open={showConfirmation} removeBillingInformation={() => this.removeBillingInformation()} handleClose={() => this.handleClose()} classes={classes} /> }
            <Grid container ref={this.tableRef} spacing={2} style={{position: 'relative', padding: '0% 6.6% 2%' }} id="checkPro">
              {activeStep == 1 && <KeyboardBackspaceIcon style={{ cursor: 'pointer', position: 'absolute', top: 24, color: 'green' }} data-testid="backBtn" onClick={() => this.setState({ activeStep: 0 })}/>}
              <Stepper className={classes.stepper} style={{ width: '100%' }} activeStep={activeStep} alternativeLabel>
                  {steps.map((label: string) => (
                  <Step style={{color: 'green'}} key={label}>
                      <StepLabel style={{fontWeight: 'bold' }}>{label}</StepLabel>
                  </Step>
                  ))}
              </Stepper>
              { activeStep === 0 && this.planList() }
              { activeStep === 1 && this.paymentForm(backgroundColorValue) }
              { activeStep === 2 && subscriptionStatus === 'success' && this.confirmationPage() }
              { activeStep === 2 && subscriptionStatus === 'failure' && this.failurePage() }
            </Grid>
        </Paper>
      </Paper>
      )
      // Customizable Area End
    }
}

export default withStyles(styles)(SubscribeWeb);

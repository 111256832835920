import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    Loading: boolean;
    token: any;
    mainContent: any;
    privacyPolicy: any;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class PrivacyPolicyController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    getPrivacyPolicyApiCallId: string="";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            Loading: false,
            token: localStorage.getItem('token') || "",
            mainContent: "",
            privacyPolicy: []
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.getPrivacyPolicyApiCallId) {
                this.setState({ Loading: false });
                if (responseJson.data) {
                    const mainContent = responseJson.data.find((d: any) => d.attributes.title === 'Privacy Policy');
                    const policyPoints = responseJson.data.filter((d: any) => d.attributes.title !== 'Privacy Policy');
                    this.setState({
                        mainContent,
                        privacyPolicy: policyPoints
                        
                    })                    
                }
            }

        }
        // Customizable Area End
    }

    async componentDidMount() {
        // Customizable Area Start
        this.getPrivacyPolicy();
        // Customizable Area End
    }
    // Customizable Area Start
    getPrivacyPolicy() {
        this.setState({ Loading: true });
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getPrivacyPolicyApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_terms_and_conditions/privacy_policies");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Customizable Area End
}

// Customizable Area Start
import { createTheme } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';

// Normal or default theme
const ligthTheme = createTheme({
    typography: {
        fontFamily: ['"Plus Jakarta Text"', 'sans-serif'].join(',')
       },
    palette: {
        type: 'light',
        // primary: {
        //     main: '#556cd6',
        // },
        // secondary: {
        //     main: '#cc4444',
        // },
        // error: {
        //     main: red.A400,
        // },
        // background: {
        //     default: '#f5f5f5',
        // }
    },
    overrides: {
        
    },
})

// Dark theme
const darkTheme = createTheme({
    typography: {
        fontFamily: ['"Plus Jakarta Text"', 'sans-serif'].join(',')
       },
    palette: {
        type: 'dark',
        primary: {
            main: '#499e5a',
            light: 'rgb(81, 91, 95)',
            dark: 'rgb(26, 35, 39)',
            contrastText: '#ffffff',
        },
        // secondary: {
        //     main: '#FFB74D',
        //     light: 'rgb(255, 197, 112)',
        //     dark: 'rgb(200, 147, 89)',
        //     contrastText: 'rgba(0, 0, 0, 0.87)',
        // },
        // error: {
        //     main: red.A400,
        // }
    },
    overrides: {
        MuiPaper: {
            root: {
                backgroundColor: '#333742', // Set your desired background color here
            },
        },
        MuiDrawer: {
            paper: {
                backgroundColor: '#232429'
            }
        }
    },
});

const themes: any = {
    ligthTheme,
    darkTheme
}

export default function getTheme(theme: any) {
    return themes[theme]
}
// Customizable Area End
import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  FlatList,
  Dimensions,
  TextInput
} from "react-native";
import MultiSlider from "@ptomasroos/react-native-multi-slider";
import Icon from "react-native-vector-icons/AntDesign";
import { Props } from "./FilteritemsController";
import FilteritemsController from "./FilteritemsController";
import CustomDropDown from "../../../components/src/CustomDropDown";
import CustomText from "../../../components/src/text";
import HelperClass from "../../../components/src/HelperClass";
import { theme } from "../../../framework/src/theme";
import NewDropDown from "../../../components/src/NewDropDown";

const { height, width } = Dimensions.get("screen");
// Customizable Area End
export default class Volumes extends FilteritemsController {
  // Customizable Area Start
  static multislider: any;
  static MultiSlider: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const volumPeriodUp = (selectedItem: string) => {
      this.setState({ volumeUpPeriod: selectedItem })
    }
    const volumPeriodDown = (selectedItem: string) => {
      this.setState({ volumeDownPeriod: selectedItem })
    }
    const periodObject = {
      "Volume Trending Up": volumPeriodUp,
      "Volume Trending Down": volumPeriodDown,
      "Average Volume":volumPeriodUp,
    }
    const volumLengthUp = (selectedItem: string) => {
      this.setState({ volumeUpPeriodLength: selectedItem })
    }
    const volumLengthDown = (selectedItem: string) => {
      this.setState({ volumeDownPeriodLength: selectedItem })
    }
    const lengthObject = {
      "Volume Trending Up": volumLengthUp,
      "Volume Trending Down": volumLengthDown,
    }
   const changeVolume =(selected:any)=>{
    this.setState({ defaultVolumeButtonText: selected })
    this.onChangeVolumes(0, selected) 
   }
   this.props.check && this.props?.value?.avgVolumeMinValue && this.setState({avgVolumeMinValue:  this.props?.value?.avgVolumeMinValue,})
   this.props.check && this.props?.value?.avgVolumeMaxValue && this.setState({avgVolumeMaxValue:  this.props?.value?.avgVolumeMaxValue,})
   this.props.check && this.props?.value?.priceFrom && this.setState({  priceFrom: this.props?.value?.priceFrom})
   this.props.check && this.props?.value?.volumeOption && changeVolume(this.props?.value?.volumeOption)
   //@ts-ignore
   this.props.check && this.props?.value?.period && periodObject[this.props?.value?.volumeOption](this.props?.value?.period)
   //@ts-ignore
   this.props.check && this.props?.value?.length && lengthObject[this.props?.value?.volumeOption](this.props?.value?.length)
    // Customizable Area Start
  }
  renderDropDownIcon = (isOpened: boolean) => {
    return (
      <View style={{ paddingRight: 15 }}>
        <Icon
          name={isOpened ? "caretup" : "caretdown"}
          color={"#444"}
          size={14}
        />
      </View>
    )
  }
  selectDropdown = () => {
    return (
      <>
            <NewDropDown placeholder={this.state.volumeUpPeriod != '' ? this.state.volumeUpPeriod : "Period"}                         
                        data={ this.state.volumePeriodList}
                        placeHolderName={'Time Frame'}
                        isBasic={this.state.isBasic}
                        Upgrade = {this.screennavigate}
                        onSelect={(selectedItem: any, index: any) => {
                          this.setState({ volumeUpPeriod: selectedItem }, () => {
                            this.props.VolumePicker(this.state.defaultVolumeButtonText, this.state.avgVolumeMinValue, this.state.avgVolumeMaxValue, this.state.priceFrom, this.state.volumeUpPeriodLength, this.state.volumeUpPeriod, this.state.volumeDownPeriod, this.state.volumeDownPeriodLength)
                          })
                          this.onChangeTrendingUP(index, selectedItem);
                        }}
                            width={width} mode={this.context.mode} />
        </>
    )
  }

  onPriceRangePress = (item: any) => {
    let arr = this.state.priceFrom;
    this.setState({
      priceFrom: arr.map((el: any) => ({
        ...el,
        isSelected: el.id === item.id ? !el.isSelected : false
      }))
    }, () => {
      this.props.VolumePicker(this.state.defaultVolumeButtonText, this.state.avgVolumeMinValue, this.state.avgVolumeMaxValue, this.state.priceFrom, this.state.volumeUpPeriodLength, this.state.volumeUpPeriod, this.state.volumeDownPeriod, this.state.volumeDownPeriodLength)

    });
  };

  priceRangeFrom = ({ item }: any) => {
    return (
      <TouchableOpacity
        style={styles.lowtoHighView}
        onPress={() => {
          this.props.onPriceRangePress(this.state.priceFrom,item)
          this.onPriceRangePress(item)
        }}
      >
        <View
          style={[
            styles.radioBtn,{backgroundColor:this.context.mode === 'light' ? theme.light.headeBg : theme.dark.headeBg}
          ]}
        >
          {item.isSelected && <View style={styles.innerCircle} />}
        </View>
        <CustomText style={styles.priceFromTxt}>{item.name}</CustomText>
      </TouchableOpacity>
    );
  };
  onchangedata() {
    this.props.onClick('hii')
  }
  volumeClickFxn = () => {
    return (
      <View testID="volumeClickFxn">{this.state.avgVolumeLabel === '' &&
        <CustomDropDown

          data={this.state.volumeArray}
          ref={this.dropdownRef}
          onSelect={(selectedItem: any, index: any) => {
            this.setState({ defaultVolumeButtonText: selectedItem }, () => {
              this.props.onClickVolume("volume")
              this.props.VolumePicker(selectedItem, this.state.avgVolumeMinValue, this.state.avgVolumeMaxValue, this.state.priceFrom, this.state.volumeUpPeriodLength, this.state.volumeUpPeriod, this.state.volumeDownPeriod, this.state.volumeDownPeriodLength)
            })
            this.onChangeVolumes(index, selectedItem);
          }}
          defaultButtonText={'Select Volume Option'}
          buttonTextAfterSelection={(selectedItem: any, index: any) => {
            return selectedItem;
          }}
          rowTextForSelection={(item: any) => {
            return item;
          }}
          buttonStyle={[styles.dropdown1BtnStyle,{backgroundColor: this.context.mode === 'light' ? theme.light.headeBg : theme.dark.headeBg }]}
          buttonTextStyle={{ color: this.state.timeFameIndLabel != '' ? '#000' : 'gray', textAlign: "left" }}
          renderDropdownIcon={(isOpened: boolean) => {
            return this.renderDropDownIcon(isOpened);
          }}
        />}
      </View>
    )
  }

  avgVolumeLengthFxn = () => {
    return (
      <View testID="avgVolumeLengthFxn">
        {this.state.avgVolumeLabel.length != 0 &&
          <CustomDropDown
            data={this.state.volumeArray}
            ref={this.dropdownRef}
            // defaultValueByIndex={1}
            // defaultValue={'Egypt'}
            onSelect={(selectedItem: any, index: any) => {
              this.setState({ defaultVolumeButtonText: selectedItem }, () => {
                this.props.onClickVolume("volume")
                this.props.VolumePicker(selectedItem, this.state.avgVolumeMinValue, this.state.avgVolumeMaxValue, this.state.priceFrom, this.state.volumeUpPeriodLength, this.state.volumeUpPeriod, this.state.volumeDownPeriod, this.state.volumeDownPeriodLength)
              })
              this.onChangeVolumes(index, selectedItem);
            }}
            defaultButtonText={this.state.avgVolumeLabel}
            buttonTextAfterSelection={(selectedItem: any) => {
              return selectedItem;
            }}
            rowTextForSelection={(item: any) => {
              return item;
            }}
            buttonStyle={[styles.dropdownIndustryStyle,{ backgroundColor: this.context.mode === 'light' ? theme.light.headeBg : theme.dark.headeBg }]}
            buttonTextStyle={{ color: this.state.timeFameIndLabel != '' ? '#000' : 'gray', textAlign: "left" }}
            renderDropdownIcon={(isOpened: boolean) => {

              return (
                <View style={{ paddingRight: 15, flexDirection: "row" }}>
                  {
                    this.state.avgVolumeLabel.length != 0 &&
                    <TouchableOpacity style={styles.closeIcon} onPress={() => this.onClocseVolume()}>
                      <Icon
                        name={'close'}
                        color={"#444"}
                        size={14}
                      />
                    </TouchableOpacity>
                  }
                  <Icon
                    name={isOpened ? "caretup" : "caretdown"}
                    color={"#444"}
                    size={14}
                  />
                </View>

              );
            }}


          />}
      </View>
    )
  }

  avgVolumeSelectedFxn = () => {
    return (
      this.state.avgVolumeSelected && (
        <View style={styles.avgVolView} testID="avgVolumeSelectedFxn">
          <View style={styles.sliderView}>
            <MultiSlider
              selectedStyle={styles.selectedMarkerStyle}
              unselectedStyle={
                {
                  backgroundColor: this.context.mode === 'light' ? theme.light.headeBg : theme.dark.headeBg 
                }
              }
              trackStyle={styles.trackMarkerStyle}
              markerStyle={styles.markerStyle}
              sliderLength={width - 44}
              values={[
                this.state.avgVolumeMinValue,
                this.state.avgVolumeMaxValue
              ]}
              min={0}
              max={100}
              //  enableLabel={true}
              // step={1}
              containerStyle={styles.containerMarker}
              onValuesChangeFinish={values => {
                this.setState({
                  avgVolumeMinValue: values[0],
                  avgVolumeMaxValue: values[1]
                }, () => {

                  this.props.VolumePicker(this.state.defaultVolumeButtonText, this.state.avgVolumeMinValue, this.state.avgVolumeMaxValue, this.state.priceFrom, this.state.volumeUpPeriodLength, this.state.volumeUpPeriod, this.state.volumeDownPeriod, this.state.volumeDownPeriodLength)
                });
              }}
            // testID={"MultiSlider"} 
            />
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: -10 }}>
              <CustomText style={styles.rangeText}>{"$" + this.state.avgVolumeMinValue}{this.state.avgVolumeMinValue > 0 && <CustomText style={{ marginLeft: 3 }}>Bn</CustomText>}</CustomText>
              <CustomText style={styles.rangeText}>{"$" + this.state.avgVolumeMaxValue}<CustomText style={{ marginLeft: 3 }}>Bn</CustomText></CustomText>
            </View>
          </View>
          <View style={styles.avgDropdownStyle}>
            {this.selectDropdown()}
          </View>
          <View style={styles.sliderView}>
            <FlatList
              data={this.state.priceFrom}
              renderItem={this.priceRangeFrom}
              extraData={this.context.mode}
            />
          </View>
        </View>
      )
    )
  }

  periodCustom = () => {
    return (
      <View style={{
        marginTop: this.state.volumeUpPeriod != '' ? 20 : 15,
        backgroundColor: this.props.mode == 'light' ? "#F7F7F7" : '#000',
        // height:this.state.volumeUpPeriod !=''?40:40,
        marginBottom: 10
      }}
        testID="periodCustom"
      >
        {
          this.state.volumeUpPeriod != '' &&
          <Text style={[styles.textStyle,{color:this.props.mode == 'light' ? "#000" : '#FFF',}]}>{'Period'}</Text>
        }
        {
          this.state.volumeUpPeriod === 'Custom' ?
            <View>
              <TextInput
                style={{
                  height: 50,
                  // borderWidth: 1,
                  // borderColor: "green",
                  // borderRadius: 8,
                  paddingLeft: 10,
                  fontSize: 18,
                  backgroundColor: "#F7F7F7"
                }}
                testID="volumePeriodUpCustom"
                keyboardType="numeric"
                placeholder="Your Custom Period here..."
                onChangeText={text =>
                  this.setState({ volumeUpPeriodCustomValue: text }, () => {

                    // this.props.VolumePicker(this.state.defaultVolumeButtonText,this.state.avgVolumeMinValue,this.state.avgVolumeMaxValue,this.state.volumeUpPeriodLength,this.state.volumeUpPeriodCustomValue,this.state.volumeDownPeriod)
                  })
                }
              />
            </View>
            :
            this.selectDropdown()
        }

      </View>
    )
  }
  avgVolumeUpFxn = () => {
    return (
      this.state.volumeTrendUpSelected && (
        <>
          {this.periodCustom()}
          {/* {this.state.volumeUpPeriodCustom && (
            <View>
              <TextInput
                style={{
                  height: 50,
                  borderWidth: 1,
                  borderColor: "green",
                  borderRadius: 8,
                  paddingLeft: 20,
                  fontSize: 18,
                  backgroundColor: "#F7F7F7"
                }}
                keyboardType="numeric"
                placeholder="Your Custom Period here..."
                onChangeText={text =>
                  this.setState({ volumeUpPeriodCustomValue: text })
                }
              />
            </View>
          )} */}
          <View style={{
            marginTop: this.state.volumeUpPeriodLength != '' ? 20 : 15,
            backgroundColor: this.props.mode == 'light' ? "#F7F7F7" : '#000',
            // height:this.state.volumeUpPeriod !=''?40:40,

          }}>
            {this.state.volumeUpPeriodLength != '' &&
              <Text style={[styles.textStyle,{color:this.props.mode == 'light' ? "#000" : '#FFF',}]}>{"Length"}</Text>
            }
            <TextInput
              style={{
                height: 50,
                borderWidth: this.state.volumeUpPeriodLength != '' ? 0 : 1,
                borderColor: this.state.volumeUpPeriodLength != '' ? "#F7F7F7" : "green",
                borderRadius: 8,
                backgroundColor: this.props.mode=="light"?"white":"black",
                paddingLeft: 20,
                fontSize: 18,
                color:this.props.mode=== 'light' ? 
                "#000": "#FFF"
              }}
              testID="volumePeriodUp"
              keyboardType="numeric"
              placeholder={ "Length"}
              value={this.state.volumeUpPeriodLength.toString()}
              placeholderTextColor={this.props.mode=="light"?"#858585":"white"}
              onChangeText={text =>
                this.setState({ volumeUpPeriodLength: text }, () => {
                  this.props.VolumePicker(this.state.defaultVolumeButtonText, this.state.avgVolumeMinValue, this.state.avgVolumeMaxValue, this.state.priceFrom, this.state.volumeUpPeriodLength, this.state.volumeUpPeriod, this.state.volumeDownPeriod, this.state.volumeDownPeriodLength)

                })
              }
            />
          </View>
        </>
      )
    )
  }

  customVolume = () => {
    return (
      <View style={{
        marginTop: this.state.volumeDownPeriod != '' ? 20 : 15,
        backgroundColor: this.props.mode == 'light' ? "#F7F7F7" : '#000',
        // height:this.state.volumeUpPeriod !=''?40:40,
        marginBottom: 10
      }}
        testID="customVolume">
        {
          this.state.volumeDownPeriod != '' &&
          <Text style={[styles.textStyle,{color:this.props.mode == 'light' ? "#000" : '#FFF'}]}>{'Period'}</Text>
        }
        {
          this.state.volumeDownPeriod === 'Custom' &&
            <View>
              <TextInput
                style={{
                  height: 50,
                  paddingLeft: 10,
                  fontSize: 18,
                  backgroundColor: "#F7F7F7"
                }}
                testID="volumePeriodDownCustom"
                keyboardType="numeric"
                placeholder="Your Custom Period here..."
                onChangeText={text =>
                  this.setState({ volumeUpPeriodCustomValue: text })
                }
              />
            </View>
        }
        { this.state.volumeDownPeriod !== 'Custom' &&
            <NewDropDown placeholder={this.state.volumeDownPeriod != '' ? this.state.volumeDownPeriod : "Period"}                         
            data={ this.state.volumePeriodList}
            testID={'VolumePicker'}
            placeHolderName={'Time Frame'}
            Upgrade = {this.screennavigate}
            isBasic={this.state.isBasic}
            onSelect={(selectedItem: any, index: any) => {
                  this.setState({ volumeDownPeriod: selectedItem }, () => {
  
                    this.props.VolumePicker(this.state.defaultVolumeButtonText, this.state.avgVolumeMinValue, this.state.avgVolumeMaxValue, this.state.priceFrom, this.state.volumeUpPeriodLength, this.state.volumeUpPeriod, this.state.volumeDownPeriod, this.state.volumeDownPeriodLength)
                  })
                  this.onChangeTrendingDown(index, selectedItem);
  
                }}
                width={width} mode={this.context.mode} />
           


        }
      </View>
    )
  }

  avgVolumeDown = () => {
    return (
      this.state.volumeTrendDownSelected && (
        <>
          {this.customVolume()}

          <View style={{
            marginTop: this.state.volumeDownPeriodLength != '' ? 20 : 15,
            backgroundColor: this.props.mode == 'light' ? "#F7F7F7" : '#000',
            // height:this.state.volumeUpPeriod !=''?40:40,

          }}>
            {this.state.volumeDownPeriodLength != '' &&
              <Text style={[styles.textStyle,{color:this.props.mode == 'light' ? "#000" : '#FFF',}]}>{"Length"}</Text>
            }
            <TextInput
              style={{
                height: 50,
                borderWidth: this.state.volumeDownPeriodLength != '' ? 0 : 1,
                borderColor: this.state.volumeDownPeriodLength != '' ? "#F7F7F7" : "green",
                borderRadius: 8,
                backgroundColor: this.props.mode=="light"?"white":"black",
                paddingLeft: 20,
                fontSize: 18,
                color:this.props.mode=== 'light' ? 
                "#000": "#FFF"
              }}
              testID="volumePeriodDown"
              keyboardType="numeric"
              placeholder={"Length"}
              value={this.state.volumeDownPeriodLength.toString()}
              placeholderTextColor={"#858585"}
              onChangeText={text =>
                this.setState({ volumeDownPeriodLength: text }, () => {

                  this.props.VolumePicker(this.state.defaultVolumeButtonText, this.state.avgVolumeMinValue, this.state.avgVolumeMaxValue, this.state.priceFrom, this.state.volumeUpPeriodLength, this.state.volumeUpPeriod, this.state.volumeDownPeriod, this.state.volumeDownPeriodLength)
                })
              }
            />
          </View>
        </>
      )
    )
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <View style={styles.container}>
        <View style={{ width: width / 1.1, marginBottom: 10 }}>

          {this.volumeClickFxn()}
          {this.avgVolumeLengthFxn()}
          {this.avgVolumeSelectedFxn()}
          {this.avgVolumeUpFxn()}
          {this.avgVolumeDown()}
        </View>
        <View style={{flexDirection:"row",marginLeft:"auto",marginRight:"auto"}} >
          <TouchableOpacity
                testID="btnExample"
                onPress={
                  ()=>{
                    this.props.save()
                  }
                }
                style={[styles.saveBtn,{marginRight:15}]}>
                <CustomText style={styles.saveTxt}>{this.SaveBtn}</CustomText>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={
                  ()=>{
                    this.props.saveApply()
                  }
              }
                style={styles.saveBtn}>
                <CustomText style={styles.saveTxt}>Save & Apply</CustomText>
              </TouchableOpacity> 
  
          </View>
      </View>
      // Customizable Area End
    );
  }
}

const styles = StyleSheet.create({
  // Customizable Area Start
  saveBtn: {  marginTop: 6 ,height:35,width:100,backgroundColor:"green",alignItems:"center",justifyContent:"center"},
  saveTxt: { fontSize: 15, color: "black" },
  container: {
    flex: 1,
    alignItems: "center"
  },
  dropdown1BtnStyle: {
    width: width / 1.1,
    height: 60,
    
    borderRadius: 8,
    borderWidth: 1,
    borderColor: "#fff"
  },
  dropdown1RowTxtStyle: { color: "#444", textAlign: "left" },
  plusBtn: {
    color: "#5F966C",
    fontSize: 18
  },
  innerCircle: {
    width: 12,
    height: 12,
    borderRadius: 6,
    backgroundColor: 'green',
  },
  avgVolView: {},
  sliderView: {},
  lowtoHighView: {
    height: 45,
    flexDirection: "row",
    paddingLeft: 20,
    alignItems: "center"
  },
  radioBtn: {
    height: 24,
    width: 24,
    borderRadius: 24,
    // backgroundColor: "#f7f8f7",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    right: 15
  },
  priceFromTxt: {
    fontSize: 14,
    color: "#000",
    fontFamily: 'PlusJakartaText-Regular',
  },
  selectedMarkerStyle: {
    backgroundColor: "#4B9F57"
  },
  unselectedMarkerStyle: {
    backgroundColor: "#E7EFF5"
  },
  trackMarkerStyle: {
    height: 4
  },
  markerStyle: {
    backgroundColor: "#76E57E",
    marginTop: 3,
    height: 20,
    width: 20
  },
  containerMarker: {
    alignItems: "center",
  },
  dropdownIndustryStyle: {
    width: width / 1.1,
    height: 60,
    borderRadius: 8,
    // backgroundColor: "#F7F7F7",
  },
  closeIcon: {
    marginRight: 10
  },
  textStyle: {
    marginTop: 5,
    marginLeft: 15,
    color: 'gray',
    fontFamily: 'PlusJakartaText-Regular',
  },
  rangeText: {
    color: '#000',
    fontSize: 14,
    fontFamily: 'PlusJakartaText-Regular',
  },
  avgDropdownStyle: {
    marginBottom: 10,
    marginTop: 10
  },
  // Customizable Area End
});



export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const backIcon = require("../assets/backIcon.png");
export const threed_mockup1 = require("../assets/threed_mockup1.png");
export const threed_mockup2 = require("../assets/threed_mockup2.png");
export const rsi = require("../assets/1.png");
export const news = require("../assets/2.png");
export const eduCenter = require("../assets/3.png");
export const noAds = require("../assets/no_ads.png");
export const unlimited = require("../assets/unlimited1.jpg");
export const bell = require("../assets/bell.png");
export const Dipchart = require("../assets/Dipchart.png");
export const Community = require("../assets/Community.png");
export const token = require("../assets/token.png");
export const Paypal_logo = require("../assets/Paypal_logo.png");











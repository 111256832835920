import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { removePlusMinusSymbol } from './chartUtils.web';
import Avatar from '@material-ui/core/Avatar';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

interface Props {
    classes: any;
    title: string;
    urlParam: string;
    testId: string;
    noMessageText: string;
    disabled: boolean;
    data: any;
}

const DataCard: React.FC<Props> = ({ classes, title, urlParam, testId, noMessageText, disabled, data })  => {
    return (
        <Card className={classes.dataCard}>
            <CardHeader
                style={{ marginTop: '5px' }}
                title={<Typography component="div" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                    {title}
                </Typography>}
                action={<Button className={classes.viewAllBtn}
                    onClick={() => window.location.href = `/CryptoAssets?type=${urlParam}`}
                    data-testid={testId}
                    disabled={disabled}
                > View All
                </Button>} />
            <Divider style={{ height: '1px' }} />
            <CardContent>
                {data !== null ? [...data].splice(0, 5).map((element: any, index: number) =>
                    <div key={index} className={classes.dataCardContent} onClick={() => window.location.href = `/CryptoDetailWeb/${element.id}`} >
                        <div style={{ display: 'flex' }} >
                            <div style={{ marginRight: '10px' }} >
                                <Avatar src={element.image_url} style={{ height: '30px', width: '30px' }} aria-label="recipe" />
                            </div>
                            <div>
                                <Typography className={classes.dataCardTitle} >
                                    {element.name}
                                </Typography>
                                <Typography variant="body2" style={{ color: '#c6cfca', fontWeight: 'bold', fontSize: '10px' }}>
                                    {element.symbol}
                                </Typography>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }} >
                            <Typography className={classes.dataCardTitle} >
                                {element.price.split('$').join('')} USD
                            </Typography>
                            <Typography variant="body2" style={{ display: 'flex', alignItems: 'center', color: element.percentage_change_24h.toString().charAt(0) === "-" ? '#E86A3F' : '#01A24E', fontWeight: 'bold', fontSize: '10px' }}>
                                {element.percentage_change_24h.toString().charAt(0) === "-" ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                                {removePlusMinusSymbol(element.percentage_change_24h)}
                            </Typography>
                        </div>
                    </div>
                ) : (
                    <div className={classes.dataCardContent} >
                        <Typography style={{ textAlign: 'center', fontWeight: 'bold' }} >
                            {noMessageText}
                        </Typography>
                    </div>
                )}
            </CardContent>
        </Card>
    )
}

export default DataCard;
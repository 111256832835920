import React from "react";
// Customizable Area Start
import SubscriptionController, {
    Props
} from "./SubscriptionController.web";

import {
    withStyles, StyleRules
} from "@material-ui/core/styles";

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { paypalImage } from "../../landingpage/src/assets";
import Switch from '@material-ui/core/Switch';
import Radio from '@material-ui/core/Radio';
import Loader from "../../../components/src/Loader.web";
import moment from "moment";
import ConfirmationDialogWeb from "../../../components/src/ConfirmationDialog.web";
import { SubscriptionPlanAttributes } from "../../landingpage/src/SubscribeController.web";
// Customizable Area End

const styles: StyleRules = {
    // Customizable Area Start
    root: {
        padding: "10px 22px 50px",
        overflowX: 'hidden'
    },
    container: {
        padding: "30px 50px 10px",
        height: '100vh',
        '@media (max-width: 600px)': {
            padding: '10px'
        }
    },
    mainTitle: {
        fontSize: '14px',
        margin: '0 20px',
        display: 'flex',
        alignItems: 'center',
    },
    trialText: {
        fontSize: "14px",
        margin: "0 20px",
        display: "flex",
        alignItems: "center",
        marginTop:"10px"
    },
    mainTitleBtn: { 
        paddingLeft: 0,
        textTransform: "none", 
        textDecoration: "underline",
        fontSize:"14px",
        fontWeight:"bold"
    },
    subscribeBtn: {
        background: 'rgba(1,162,78,255)',
        color: '#ffffff', 
        textTransform: 'none',
        marginTop: '20px',
        marginBottom: '10px',
        fontSize: '16px',
        borderRadius: '20px',
        "&:hover": {
            background: 'rgba(1,162,78,255)'
        }
    },
    accountDashboard: {
        textTransform: 'none',
        color: 'rgba(1,162,78,255)',
        fontSize: '16px',
        "&:hover": {
            background: 'none'
        },
    },
    switchRoot: {
        width: 42,
        height: 26,
        padding: 0,
        margin: 8,
      },
      switchBase: {
        padding: 1,
        '&$checked': {
          transform: 'translateX(16px)',
          color: '#ffffff',
          '& + $track': {
            backgroundColor: '#52d869',
            opacity: 1,
            border: 'none',
          },
        },
        '&$focusVisible $thumb': {
          color: '#52d869',
          border: '6px solid #fff',
        },
      },
      thumb: {
        width: 24,
        height: 24,
      },
      track: {
        borderRadius: 26 / 2,
        border: `1px solid #999999`,
        backgroundColor: '#FAFAFA',
        opacity: 1,
        height: 'auto'
      },
      checked: {
      },
      focusVisible: {},
    // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
export class SubscriptionWeb extends SubscriptionController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    currentPlan(): SubscriptionPlanAttributes | undefined {
        const { userProfile } = this.state;
        let currentPlan;
        if (userProfile && userProfile.attributes && userProfile.attributes.subscription_plan && 
        userProfile.attributes.subscription_plan.data && userProfile.attributes.subscription_plan.data.attributes) {
            currentPlan = userProfile.attributes.subscription_plan.data.attributes;
        }
        return currentPlan;
    }

    planDescription() {
        const currentPlan = this.currentPlan();
        const { userProfile } = this.state;
        let planText = "";
        if (userProfile && currentPlan) {
            if (currentPlan.subscription_type === "pro") {
                planText = `Your subscription will automatically renew on ${moment(userProfile.attributes.subscription_end_date).format('MMMM Do, YYYY')}. We'll send you an email reminder 30 days before
                the renewal date.`
            }
            if (currentPlan.subscription_type === "basic") {
                planText = `To enjoy unlimited benefits, please upgrade to Pro`
            }
            if (currentPlan.subscription_type === "free_trial") {
                planText = `Your free trial will end on ${moment(userProfile.attributes.trial_end_date).format('MMMM Do, YYYY')}. To continue enjoying the Pro benefits. Please enable the auto renew
                otherwise your plan will be changed to basic.`
            }
        }
        return planText;
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { userProfile, showConfirmation } = this.state;
        const currentPlan = this.currentPlan();
        return (
            <Paper className={classes.root} elevation={0} square data-testid='subscriptionPaper' style={{ backgroundColor: localStorage.getItem('appTheme') == 'darkTheme' ? '#232429' : '#FFFFFF' }}>
                {this.state.loading && <Loader loading={this.state.loading} />}
                {showConfirmation && <ConfirmationDialogWeb open={showConfirmation} cancelSubscription={() => this.cancelSubscription()} handleClose={() => this.handleClose()} /> }
                <Paper elevation={0} style={{ backgroundColor: localStorage.getItem('appTheme') == 'darkTheme' ? '#232429' : '#ffffff', padding: '0 40px' }}>
                  
                    {currentPlan &&
                        <Paper  elevation={0} style={{ border: '1px solid #cfd9d5', backgroundColor: localStorage.getItem('appTheme') == 'darkTheme' ? '#363333' : '#eefff8'}}>
                            <div>
                                <Typography variant="h6" className={classes.mainTitle} style={{ fontSize: '20px', fontWeight: 'bold', padding: '20px 0'}}>Manage Subscription</Typography>
                                <Divider />
                                <Typography variant="body1" className={classes.mainTitle} style={{ marginTop: 20, fontWeight: 'bold'}}>
                                    YOUR CURRENT PLAN
                                </Typography>
                                <Typography className={classes.mainTitle}style={{fontSize: '18px', fontWeight: 'bold'}}>
                                    {currentPlan.subscription_type === "free_trial" ? `Pro Plan (Free Trial)` : `${currentPlan.name} Plan`}
                                </Typography>
                                <Typography variant="body1" className={classes.trialText}>
                                    {this.planDescription()}
                                </Typography>
                                <Typography variant="body1" className={classes.mainTitle}>
                                    <Button className={classes.mainTitleBtn} data-testid="view-other-plans" onClick={() => this.handleNavigation('SubscribeWeb')}>View other plans</Button>
                                </Typography>
                                <Divider />
                                <Typography variant="body1" className={classes.mainTitle} style={{ marginTop: '20px', marginBottom: '20px' }}>
                                    Would you like to <Button data-testid="cancelSubscriptionBtn" variant="text" style={{ color: currentPlan.subscription_type === "basic" ? '#04a14e' : 'red', textDecoration: 'underline', textTransform: 'none' }} onClick={() => this.handleCancelSubscription(currentPlan.subscription_type)}>
                                        {currentPlan.subscription_type === "basic" ? 'Upgrade your subscription?' : 'Cancel your subscription?'}
                                        </Button>
                                </Typography>
                                <Divider />
                                {currentPlan.subscription_type !== 'basic' && <>
                                    <Typography variant="body1" className={classes.mainTitle} style={{ fontWeight: 'bold', marginTop: 20}}>
                                        AUTO RENEW
                                    </Typography>
                                    {userProfile && <Typography variant="body1" className={classes.mainTitle} style={{ justifyContent: 'space-between', fontSize: '18px', marginBottom: 20}}>
                                        Your subscription will renew on {moment(currentPlan.name.toLowerCase() === 'free trial' ? userProfile.attributes.trial_end_date : userProfile.attributes.subscription_end_date).format('MMMM Do, YYYY')}
                                        <Switch disableRipple
                                            focusVisibleClassName={classes.focusVisible}
                                            checked={currentPlan.subscription_type === 'pro'}
                                            classes={{
                                                root: classes.switchRoot,
                                                switchBase: classes.switchBase,
                                                thumb: classes.thumb,
                                                track: classes.track,
                                                checked: classes.checked,
                                        }}/>
                                    </Typography>}
                                    <Divider />
                                </>}
                                <Typography variant="body1" className={classes.mainTitle} style={{ fontWeight: 'bold', marginTop: 20}}>
                                    PAYMENT METHOD
                                </Typography>
                                <Typography variant="body1" className={classes.mainTitle} style={{ marginBottom: 20, display: 'flex', alignItems: 'center'}}>
                                <Radio
                                    checked={true}
                                    data-testid="auto-renew-toggle"
                                    style={{ color: '#04a14e', paddingLeft: 0 }}
                                    onChange={() => {}}
                                    value="paypal"
                                    name="radio-button-demo"
                                    inputProps={{ 'aria-label': 'paypal' }}
                                />Paypal <img src={paypalImage} height={40} />
                                </Typography>
                            </div>
                        </Paper>
                    }
                </Paper>
            </Paper>
        )
        // Customizable Area End
    }
}

export default withStyles(styles)(SubscriptionWeb);

// Customizable Area Start
//@ts-nocheck
import React from 'react';
import {
  Dimensions,
  FlatList,
  Image,
  Modal,
  Platform,
  StatusBar,
  StyleSheet,
  Switch,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  ScrollView,
  View
} from "react-native";
import Icon from 'react-native-vector-icons/AntDesign';
import SubHeader from "../../../components/src/SubHeader";
import CustomText from "../../../components/src/text";
import { COLORS } from "../../../framework/src/Globals";
import { theme } from "../../../framework/src/theme";
import SavedCardsController, {
  Props
} from "./SavedCardsController";
import { circle, deleteIcon, editIcon } from "./assets";
const { height, width } = Dimensions.get('screen')
// Customizable Area End

export default class SavedCards extends SavedCardsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const { navigation } = this.props;
    this.displayCardList()
    this._unsubscribe = navigation.addListener("focus", () => {
      this.displayCardList()
    });
  }
  async componentWillUnmount() {
    this._unsubscribe();
  }

  getNewColor = () => {
    return '#396936';
  }

  renderItem = (item: any) => {
    return (
      <TouchableOpacity 
       testID='OtherCards'
       activeOpacity={1} >
        <>
          {item.index === 0 && <CustomText
          testID='context'
           style={[styles.primaryText, {
            color:
              this.context.mode === "light" ? "#000" : "#FFF"
          }]}>{"Other Cards"}</CustomText>}

          <View
            style={[styles.OtherView, { backgroundColor: this.getNewColor() }]}>
            <View style={styles.subView}>
              <View style={{ flex: 1 }}>
                <CustomText style={styles.nameCard}>{"name"}</CustomText>
                <View>
                  <CustomText style={[styles.nameCard, { color: '#fff', textTransform: 'none' }]}>{item?.item?.attributes?.name}</CustomText>
                </View>
              </View>
              <View style={{ flexDirection: 'row' }}>
                <Image source={circle} style={{ height: 30, width: 30, resizeMode: 'contain' }} />
              </View>
            </View>
            <View>
              <CustomText style={[styles.nameCard, { color: '#fff', fontSize: 20, fontWeight: 'bold' }]}>{item?.item?.attributes?.card_number ? ".... .... ...." + item.item.attributes.card_number.toString().slice(-4) : ".... .... .... ...."}</CustomText>
            </View>
            <View style={{ flexDirection: 'row', marginTop: '5%', }}>
              <View style={{ flex: 0.3 }}>
                <CustomText style={styles.nameCard}>{"exp."}</CustomText>
                <CustomText style={styles.nameCard}>{this.formatMonth(item?.item?.attributes?.month)}<CustomText>{"/"}</CustomText>{item?.item?.attributes?.year.toString().slice(-2)}</CustomText>
              </View>
              <View>
                <CustomText style={styles.nameCard}>{"cvv"}</CustomText>
                <CustomText style={[styles.dotStyle]}>{"..."}</CustomText>
              </View>
            </View>
          </View>
        </>
        <View style={[styles.subView, { marginTop: '5%' }]}>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Switch
              trackColor={{ false: "#767577", true: "#81b0ff" }}
              thumbColor={this.state.isVisible ? "#f5dd4b" : "#f4f3f4"}
              ios_backgroundColor="#3e3e3e"
              onValueChange={() => this.toggleSwitch(item.item)}
              value={item?.item?.attributes?.is_primary}
              testID='SwitchButton'
            />
            <CustomText style={{ color: '#25232E', fontFamily: 'PlusJakartaText-Regular' }}>{"Make Primary"}</CustomText>
          </View>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <TouchableOpacity
             testID='paymentBtn'
              onPress={() => this.props.navigation.navigate('Payments', { item: item.item.attributes })}
              style={{ padding: 5, marginRight: 10 }}>
              <Image source={editIcon} style={{ height: 20, width: 20, }} />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => this.setState({ isdeleteCard: true, deleteId: item.item.attributes.id })}
              style={{ padding: 5, }}>
              <Image source={deleteIcon} style={styles.iconStyle} />
            </TouchableOpacity>
          </View>
        </View>

      </TouchableOpacity>
    )
  }

  /**Delete card Alert */
  renderDeleteAssests = () => {
    return (
      <Modal
      data-testId = 'Assests'
        animationType="slide"
        transparent={true}
        visible={this.state.isdeleteCard}
        onRequestClose={() => {

          this.setState({ isdeleteCard: false })
        }}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <CustomText style={styles.deleteTextStyle}>{"Delete Card"}</CustomText>
            <CustomText style={[styles.deleteTextStyle, { color: '#000', marginVertical: 10, fontWeight: 'normal' }]}>{"Are you sure you want to delete this card?"}</CustomText>
            {/* {
             this.btnDeleteRenderView(id)
           } */}
            <View style={styles.btnMainView}>
              <TouchableOpacity
              testID='isdeleteCard'
                onPress={() => this.setState({ isdeleteCard: false })}
                style={[styles.cancelBtn, { backgroundColor: "#181A19" }]}>
                <Text style={styles.cancelText}>{"Cancel"}</Text>
              </TouchableOpacity>
              <TouchableOpacity
              testID='disableBtnID'
                // disabled={this.state.disableSavebtn}
                onPress={() => this.deleteAlert()}
                style={[styles.cancelBtn, { backgroundColor: '#489F57' }]}>
                <CustomText style={[styles.cancelText, { color: '#fff' }]}>{"Delete"}</CustomText>
              </TouchableOpacity>

            </View>
          </View>
        </View>
      </Modal>
    )
  }

  ListHeader = () => {
    const { primarArray } = this.state;
    return (
      <>
        <CustomText style={[styles.primaryText, { color: this.context.mode === "light" ? theme.light.textColor : theme.dark.textColor }]}>{"Primary Cards"}</CustomText>
        {
          primarArray !== null &&
          <>
            <View
              style={[styles.OtherView, { backgroundColor: this.getNewColor() }]}>
              <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <View style={{ flex: 1 }}>
                  <CustomText style={styles.nameCard}>{"name"}</CustomText>
                  <View>
                    <CustomText style={[styles.nameCard, { color: '#fff', textTransform: 'none' }]}>{primarArray.attributes.name}</CustomText>
                  </View>
                </View>
                <View style={{ flexDirection: 'row' }}>
                  <Image source={circle} style={{ height: 30, width: 30, resizeMode: 'contain' }} />
                </View>
              </View>
              <View>
                <CustomText style={[styles.nameCard, { color: '#fff', fontSize: 20, fontWeight: 'bold' }]}>{primarArray.attributes.card_number ? ".... .... ...." + primarArray.attributes.card_number.toString().slice(-4) : ".... .... .... ...."}</CustomText>
              </View>
              <View style={{ flexDirection: 'row', marginTop: '5%' }}>
                <View style={{ flex: 0.3 }}>
                  <CustomText style={styles.nameCard}>{"exp."}</CustomText>
                  <CustomText style={styles.nameCard}>{this.formatMonth(primarArray.attributes.month)}<CustomText>{"/"}</CustomText>{primarArray.attributes.year.toString().slice(-2)}</CustomText>
                </View>
                <View>
                  <CustomText style={styles.nameCard}>{"cvv"}</CustomText>
                  <CustomText style={styles.dotStyle}>{"..."}</CustomText>
                </View>
              </View>
            </View>
            <View style={styles.iconView}>
              <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>

                <TouchableOpacity
                testID='Payments'
                  onPress={() => this.props.navigation.navigate('Payments', { item: primarArray.attributes, primaray: "primaray" })}
                  style={{ padding: 5, marginRight: 10 }}>
                  <Image source={editIcon} style={styles.iconStyle} />
                </TouchableOpacity>
                <TouchableOpacity 
                 testID='Paymentss'
                onPress={() => this.setState({ deleteId: primarArray.attributes.id, isdeleteCard: true })}
                  style={{ padding: 5, }}>
                  <Image source={deleteIcon} style={styles.iconStyle} />
                </TouchableOpacity>

              </View>
            </View>
          </>
        }
      </>
    )
  }

  renderPrimary() {
    return (
      <>
        <CustomText style={[styles.primaryText, { color: this.context.mode === "light" ? theme.light.textColor : theme.dark.textColor }]}>{"Primary Cards"}</CustomText>
        <TouchableOpacity onPress={() => this.props.navigation.navigate('Payments', { isPrimary: "true" })} style={styles.primaryView}>
          <View style={{ alignItems: 'center' }}>
            <Icon name="plus" size={20} color={COLORS.green} />
          </View>
          <CustomText style={styles.addCardText}>{"Add Card"}</CustomText>
        </TouchableOpacity>
      </>
    )
  }

  renderView() {
    return (
      <>
        <View>
          <CustomText style={[styles.primaryText, { color: this.context.mode === "light" ? theme.light.textColor : theme.dark.textColor }]}>{"Primary Cards"}</CustomText>
          <TouchableOpacity onPress={() => this.props.navigation.navigate('Payments', { isPrimary: "true" })} style={[styles.primaryView, { backgroundColor: this.context.mode === 'light' ? '#F4F8F6' : theme.dark.highlight }]}>
            <View style={{ alignItems: 'center' }}>
              <Icon name="plus" size={20} color={COLORS.green} />
            </View>
            <CustomText style={styles.addCardText}>{"Add Card"}</CustomText>
          </TouchableOpacity>
        </View>
        <View style={{ marginTop: '15%' }}>
          <CustomText style={[styles.primaryText, { color: this.context.mode === "light" ? theme.light.textColor : theme.dark.textColor }]}>{"Secondary Cards"}</CustomText>
          <TouchableOpacity
            onPress={() => this.props.navigation.navigate('Payments')}
            disabled={this.state.isSecondary}
            style={[styles.primaryView, { backgroundColor: this.context.mode === 'light' ? '#F4F8F6' : theme.dark.highlight }]}>
            <View style={{ alignItems: 'center' }}>
              <Icon name="plus" size={20} color={COLORS.green} />
            </View>
            <CustomText style={styles.addCardText}>{"Add Card"}</CustomText>
          </TouchableOpacity>
        </View>
      </>
    )
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <View style={[styles.container, { backgroundColor: this.context.mode === 'light' ? theme.light.headeBg : theme.dark.headeBg }]}>
        <TouchableWithoutFeedback
        testID='hideKeyboard'
          onPress={() => {
            this.hideKeyboard();
          }}

        >
          {/* Merge Engine UI Engine Code */}
          <View style={{ backgroundColor: this.context.mode === 'light' ? theme.light.headeBg : '#161616' }}>
            {this.renderDeleteAssests()}
            <StatusBar
              barStyle={this.context.mode === 'light' ? 'dark-content' : 'light-content'} backgroundColor={this.context.mode === 'light' ? theme.light.headeBg : theme.dark.headeBg}

            />
            <SubHeader
              title={"Manage Cards"}
              onPress={() => this.props.navigation.goBack()}
            />
            <ScrollView style={[styles.mainView, { backgroundColor: this.context.mode === 'light' ? theme.light.highlight : '#242329' }]}>
              {/* {
                this.state.cardList.length === 0 && this.state.primarArray === null ? */}
              <>
                {
                  this.state.cardList.length > 0 && this.state.primarArray === null &&
                  this.renderPrimary()
                }
                {
                  this.state.cardList.length === 0 && this.state.primarArray === null &&
                  this.renderView()
                }
                {
                  this.state.primarArray !== null && this.state.cardList.length === 0 &&
                  <>
                    <View style={{}}>
                      {this.ListHeader()}
                    </View>
                    <View style={{ marginTop: '15%' }}>
                      <Text style={[styles.primaryText, { color: this.context.mode === "light" ? theme.light.textColor : theme.dark.textColor }]}>{"Secondary Cards"}</Text>
                      <TouchableOpacity
                        onPress={() => this.props.navigation.navigate('Payments')}
                        style={[styles.primaryView, { backgroundColor: this.context.mode === 'light' ? '#F4F8F6' : theme.dark.highlight }]}>
                        <View style={{ alignItems: 'center' }}>
                          <Icon name="plus" size={20} color={COLORS.green} />
                        </View>
                        <Text style={styles.addCardText}>{"Add Card"}</Text>
                      </TouchableOpacity>
                    </View>
                  </>
                }

              </>
              {
                this.state.cardList.length > 0 &&
                <FlatList
                testID='paymentFlatlist'
                  showsVerticalScrollIndicator={false}
                  data={this.state.cardList}
                  renderItem={this.renderItem}
                  ListHeaderComponent={this.state.primarArray ? this.ListHeader : null}
                  extraData={this.state}
                  contentContainerStyle={{
                    flexGrow: 1,
                    paddingBottom: 50
                  }}
                />
              }
              {
                this.state.cardList.length > 0 &&
                <TouchableOpacity
                  // testID={"btnExample"}
                  activeOpacity={0.7}
                  onPress={() => this.props.navigation.navigate('Payments', { value: '' })}
                  style={styles.touchableOpacityStyle}>
                  <Icon name="plus" size={20} color={COLORS.green} style={styles.floatingButtonStyle} />
                </TouchableOpacity>
              }
            </ScrollView>


          </View>
          {/* Merge Engine UI Engine Code */}
        </TouchableWithoutFeedback>
      </View>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  title: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  body: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    marginBottom: 16,
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    padding: 10,
    borderWidth: Platform.OS === "web" ? 0 : 1
  },
  bgMobileInput: {
    flex: 1
  },
  showHide: {
    alignSelf: "center"
  },
  imgShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {},
  mainView: {
    display: "flex",
    width: "100%",
    // flex: 1,
    height: height / 1.3,
    // backgroundColor: "#F4F8F5",
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    // elevation: 1,
    padding: 20,
    // alignItems: 'center',
    // marginBottom:50
  },
  primaryText: {
    fontFamily: 'PlusJakartaText-Bold',
    fontSize: 18
  },
  primaryView: {
    borderWidth: 2, borderStyle: 'dashed', borderRadius: 10,
    // borderWidth:1,
    borderColor: COLORS.green,
    height: height / 4.5,
    width: width / 1.2,
    alignContent: 'center',
    justifyContent: 'center',
    marginTop: '6%',
    backgroundColor: '#F4F8F6'
  },
  OtherView: {
    // borderWidth:2, borderStyle:'dashed',
    borderRadius: 10,
    // borderWidth:1,
    borderColor: COLORS.green,
    height: height / 4.5,
    width: width / 1.2,
    alignContent: 'center',
    justifyContent: 'center',
    marginTop: '6%',
    backgroundColor: '#F4F8F6', padding: 10
  },
  addCardText: {
    color: COLORS.green,
    textAlign: 'center',
    fontFamily: 'PlusJakartaText-Bold',
    fontSize: 16
  },
  touchableOpacityStyle: {
    position: 'absolute',
    width: 60,
    height: 60,
    alignItems: 'center',
    justifyContent: 'center',
    right: 30,
    bottom: '25%',
    backgroundColor: '#fff',
    // elevation: 10,
    borderRadius: 30,


  },
  floatingButtonStyle: {
    // resizeMode: 'contain',
    // width: 50,
    // height: 50,
    // alignContent:'center',justifyContent:'center'
    //backgroundColor:'black'
  },
  mmText: {
    fontFamily: 'PlusJakartaText-Bold'

  },
  expText: {
    fontFamily: 'PlusJakartaText-Bold'
    ,
    color: 'gray'
  },
  nameCard: {
    textTransform: 'uppercase',
    color: '#F4F8F6',
    fontSize: 16,
    fontFamily: 'PlusJakartaText-Regular'
  },
  subView: {
    flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'
  },
  iconStyle: {
    height: 20, width: 20
  },
  iconView: {
    marginTop: '5%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  dotStyle: {
    fontSize: 20, marginTop: -5, fontFamily: 'PlusJakartaText-Bold'
    , color: '#fff'
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22, backgroundColor: 'rgba(52, 52, 52, 0.8)'
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 10,
    padding: 15,
    width: width / 1.2,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    // elevation: 5
  },
  cancelBtn: {
    // backgroundColor:'green',
    height: height / 16,
    width: width / 2.8,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center', 
    // elevation: 1
  },
  cancelText: {
    fontFamily: 'PlusJakartaText-Bold',
    fontSize: 18,
    color: '#fff'
  },
  deleteTextStyle: {
    fontSize: 18,
    color: '#E76642',
    fontFamily: 'PlusJakartaText-Bold'
  },
  btnMainView: {

    flexDirection: 'row',
    justifyContent: 'space-around',
    width: width / 1.3,
    marginVertical: 20
  },
});
// Customizable Area End

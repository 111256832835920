import React from "react";
// Customizable Area Start
import WatchListWebController, {
    Props
} from "./WatchListController.web";

import {
    withStyles, StyleRules
} from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Box from '@material-ui/core/Box';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Loader from "../../../components/src/Loader.web";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CryptoChart from "../../../components/src/CryptoChart.web";
import { Line } from 'react-chartjs-2';
import "chartjs-plugin-datalabels";
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import {
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel
} from '@material-ui/core';
import LoginAlertDialogWeb from "../../../components/src/LoginAlertDialog.web";
import { isEmpty } from "../../../framework/src/Utilities";
import { removePlusMinusSymbol } from "../../../components/src/chartUtils.web";
import NoSubscriptionDialogWeb from "../../../components/src/NoSubscriptionDialogWeb";
// Customizable Area End

const styles: StyleRules = {
    // Customizable Area Start
    root: {
        padding: '10px 20px',
        height: window.location.pathname === '/ScreenerWeb' ? 'auto' : '100vh',
        overflowX: window.location.pathname === '/ScreenerWeb' ? 'auto' : 'hidden'
    },
    dialogContent: {
        height: '300px',
        maxWidth: '310px',
        minWidth: '310px'
    },

    addButton: {
        backgroundColor: "rgba(1,162,78,255)",
        color: "#ffffff",
        cursor: "pointer",
        padding: "8px 20px",
        border: "none",
        borderRadius: "10px",
        fontSize: "12px",
        textAlign: "center",
    },

    saveButton: {
        backgroundColor: "rgba(1,162,78,255)",
        color: "#ffffff",
        cursor: "pointer",
        padding: "8px 20px",
        border: "none",
        borderRadius: "10px",
        fontSize: "12px",
        textAlign: "center",
        '&:disabled': {
            pointerEvents: 'none',
            color: '#C6CFCA'
        }
    },

    cancelButton: {
        backgroundColor: "#000000",
        color: "#ffffff",
        cursor: "pointer",
        padding: "8px 20px",
        border: "none",
        borderRadius: "10px",
        fontSize: "12px",
        textAlign: "center",
    },
    radio: {
        '& .MuiFormControlLabel-root': {
            marginTop: "16px",
            width: "100%",
        },
        '& .MuiFormControlLabel-label': {
            width: "100%",
            fontSize: "12px",
        },
        '& .MuiRadio-colorSecondary.Mui-checked': {
            color: 'green'
        },
    },
    searchBase: {
        position: 'relative',
        margin: '0px 10px'
    },
    searchIcon: {
        height: '18px',
        width: '18px',
        backgroundSize: '18px',
        position: 'absolute',
        backgroundRepeat: 'no-repeat',
        cursor: 'pointer',
        padding: '8px',
        zIndex: 9,
        color: 'rgba(1,162,78,255)'
    },
    inputSearchField: {
        border: '1px solid #d1d1d1',
        outline: 'none',
        paddingLeft: '28px',
        borderRadius: '4px',
        boxShadow: 'inset 1px 2px 8px rgb(0 0 0 / 7%)',
        "& .MuiInputBase-input": {
            fontFamily: `"Plus Jakarta Text", sans-serif`,
            fontSize: '14px'
        }
    },
    dialogHeadTitle: {
        display: 'flex',
        justifyContent: 'center',
        textAlign: "center",
        padding: '16px 24px'
    }
    // Customizable Area End
}
// Customizable Area Start

// Customizable Area End
export class WatchListWeb extends WatchListWebController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.watchlistChartRef = React.createRef();
        this.watchlistChartConatinerRef = React.createRef();
        // Customizable Area End
    }

    // Customizable Area Start

    AddDialog = () => {
        const { classes } = this.props;
        return (
            <Dialog open={this.state.addToWatchlistDialog} className={classes.addDialog}>
                <DialogTitle className={classes.dialogHeadTitle} >
                    Add To Watchlist
                    <div className={classes.searchBase}>
                        <div>
                            <SearchIcon className={classes.searchIcon} />
                            <InputBase
                                placeholder="Search by asset name"
                                className={classes.inputSearchField}
                                value={this.state.searchKey}
                                onChange={this.handleSearchKeywordInputChange}
                                style={{ width: '100%' }}
                                autoFocus
                            />
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    {this.state.addDialogError && <Typography data-testid="error-block" variant="subtitle2" paragraph color="error">{this.state.addDialogError}</Typography>}
                    {this.state.setDialogLoading && <Loader loading={this.state.setDialogLoading} />}
                    { !this.state.setDialogLoading && this.state.searchedCryptoData.length === 0 && <Typography style={{ textAlign: 'center', fontSize: '18px', fontWeight: 'bold' }} >
                        No Data Found
                    </Typography> }
                    <FormControl component="fieldset" className={classes.radio}>
                        <FormLabel component="legend"></FormLabel>
                        <RadioGroup name='searched_crypto' data-testid="select-crypto-radiogroup" value={this.state.selectedCryptoData} onChange={this.handleChange}>
                            {this.state.searchedCryptoData.map((data: any) => (
                                <FormControlLabel key={data.id} checked={data.id.toString() === this.state.selectedCryptoData.toString()}
                                    value={data.id} control={<Radio />} label={
                                        <Grid container style={{ fontWeight: "bold" }} spacing={2}>
                                            <Grid item xs={8} style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                <Avatar style={{ height: 32, width: 32, marginRight: 5 }} src={data.image_url} />
                                                <Typography variant="inherit">{data.name}</Typography>
                                            </Grid>
                                            <Grid item xs={4} style={{ display: "flex", alignItems: "center" }}>
                                                <Typography variant="inherit">${Number(data.price).toFixed(2)}</Typography>
                                            </Grid>
                                        </Grid>
                                    } />
                            ))}
                        </RadioGroup>
                    </FormControl>

                </DialogContent>
                <DialogActions style={{ justifyContent: "center" }}>
                    <button
                        className={classes.cancelButton}
                        data-testid="cancel-add-dialog"
                        onClick={() => this.setState({ searchKey: '', searchedCryptoData: [], addToWatchlistDialog: false, addDialogError: null })}
                    >
                        Cancel
                    </button>
                    <button
                        className={classes.saveButton}
                        data-testid="save-crypto-dialog-btn"
                        disabled={!!isEmpty(this.state.selectedCryptoData)}
                        onClick={() => this.createWatchlistCrypto(this.state.selectedCryptoData)}
                    >
                        Save
                    </button>
                </DialogActions>
            </Dialog>
        )
    }
    RemoveDialog = () => {
        return (
            <Dialog data-testid="close-dialog" open={this.state.watchlistRemoveDialog} aria-labelledby="delete-confirmation-dialog">
                <DialogTitle style={{ textAlign: 'center', color: 'red', fontWeight: 'bold' }}>
                    Removed from watchlist
                </DialogTitle>
                <DialogContent style={{ textAlign: 'center', maxWidth: 300 }}>
                    <p>{`You have successfully removed '${this.state.removedWatchlistData.name}' from watchlist`}</p>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button
                        data-testid="confirmation-dialog"
                        style={{
                            backgroundColor: 'rgba(1,162,78,255)',
                            color: '#ffffff',
                            cursor: 'pointer',
                            padding: '8px 20px',
                            margin: '0px 10px',
                            border: 'none',
                            borderRadius: '10px',
                            textAlign: 'center',
                        }}
                        onClick={() => this.setState({ watchlistRemoveDialog: false })}
                    >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        )

    }

    noDataMessage = () => {
        const { watchlistCrypto, currentSelectedWatchlistCryproData, loading } = this.state;
        const selectedData = currentSelectedWatchlistCryproData?.attributes?.crypto_details[0];
        if (!loading) {
            let message = "";
            if ( watchlistCrypto.length === 0)
                message = "No Data Found";
            else
                message = !selectedData ? "Api is not responding. Please try again after sometime" : "";
            return (<>
                {!isEmpty(message) && 
                <TableRow>
                    <TableCell align="center" colSpan={7}>
                        <Typography style={{ fontWeight: 'bold' }}>{message}</Typography>
                    </TableCell>
                </TableRow>  
            }</>)
        }
    }

    handleLoaderAndDialog() {
        if (this.state.loading) {
            return <Loader loading={this.state.loading} />
        }
        if (this.state.limitExceed) {
            return <NoSubscriptionDialogWeb 
            message="In free plan, You have limited access to add coins in watchlist, If you want to add more coins please upgrade to pro plan"
            buttonText="Cancel"
            handleCancel={() => this.setState({ limitExceed: false })}
        />
        }
       
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const selectedData = this.state.currentSelectedWatchlistCryproData?.attributes?.crypto_details[0];
        return (
            <Paper className={classes.root} data-testid='watchListPaper' square style={{ backgroundColor: localStorage.getItem('appTheme') == 'ligthTheme' ? '#F4F8F6' : '#232429' }}>
                {isEmpty(this.state.token) && <LoginAlertDialogWeb />}
                { this.handleLoaderAndDialog()}
                {this.state.watchlistRemoveDialog && this.RemoveDialog()}
                <Grid container style={{ paddingTop: 10 }} spacing={2}>
                    <Grid item xs={12} md={6} style={{ height: '95vh' }}>
                        <Card variant="elevation" style={{ padding: 10, borderRadius: 15, maxHeight: "95%", overflowY: 'auto' }}>
                            <CardContent>
                                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Typography variant="h6" style={{ fontWeight: 900, marginLeft: 15 }}>
                                        Watchlist
                                    </Typography>
                                    {!isEmpty(this.state.token) && <button type="button" data-testid="add-watchlist-btn" className={classes.addButton} onClick={this.handleAddNew}>+ Add New</button>}
                                    {!isEmpty(this.state.token) && this.state.addToWatchlistDialog && this.AddDialog()}
                                </Box>
                                <TableContainer>
                                    <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                        <TableCell
                                            align="left"
                                            style={{ fontSize: "12px", width: "15%" }}
                                        >
                                            Name
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            style={{ fontSize: "12px", width: "15%" }}
                                        >
                                            Price
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            style={{ fontSize: "12px", width: "15%" }}
                                        >
                                            Category
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            style={{ fontSize: "12px", width: "15%" }}
                                        >
                                            Chart
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            style={{ fontSize: "12px", width: "15%" }}
                                        >
                                            Action
                                        </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                        this.state.watchlistCrypto.map((row: any, key: any) => {
                                            const detail = row.attributes.crypto_details[0];
                                            return <TableRow key={row.id} data-testid={`watchlist-${row.id}`} style={{cursor:'pointer'}} onClick={() =>
                                                this.setState({
                                                    currentSelectedWatchlistCryproData:row
                                                })
                                            }>
                                                <TableCell align="left">
                                                <CardHeader
                                                    avatar={
                                                    <Avatar
                                                        alt="Remy Sharp"
                                                        style={{
                                                        width: "32px",
                                                        height: "32px",
                                                        cursor: "pointer",
                                                        }}
                                                        src={detail.image_url}
                                                    />
                                                    }
                                                    title={
                                                    <Typography
                                                        gutterBottom
                                                        variant="body2"
                                                        component="div"
                                                        style={{
                                                        fontWeight: "bold",
                                                        fontSize: "12px",
                                                        }}
                                                    >
                                                        {`${detail.name}`}
                                                    </Typography>
                                                    }
                                                    subheader={
                                                    <Typography
                                                        gutterBottom
                                                        variant="body2"
                                                        component="div"
                                                        style={{
                                                        color: "#c6cfca",
                                                        fontWeight: "bold",
                                                        fontSize: "12px",
                                                        }}
                                                    >
                                                        {`${detail.symbol}`}
                                                    </Typography>
                                                    }
                                                    style={{ padding: "0px" }}
                                                />
                                                </TableCell>
                                                <TableCell align="left">
                                                <div
                                                    style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    }}
                                                >
                                                    <Typography
                                                    gutterBottom
                                                    variant="body2"
                                                    component="div"
                                                    style={{
                                                        fontWeight: "bold",
                                                        fontSize: "12px",
                                                    }}
                                                    >
                                                    {`${detail.price}`}
                                                    </Typography>
                                                    <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        marginLeft: "-5px",
                                                    }}
                                                    >
                                                    {detail.volume_percentage_change_24h.toString().charAt(0) === "+"
                                                    ? (
                                                        <ArrowDropUpIcon style={{ color: "#01A24E" }} />
                                                    ) : (
                                                        <ArrowDropDownIcon style={{ color: "#E86A3F" }} />
                                                    )}
                                                    <Typography
                                                        gutterBottom
                                                        variant="body2"
                                                        component="div"
                                                        style={{
                                                        fontWeight: "bold",
                                                        fontSize: "12px",
                                                        color:
                                                            detail.volume_percentage_change_24h
                                                            .toString()
                                                            .charAt(0) === "+"
                                                            ? "#01A24E"
                                                            : "#E86A3F",
                                                        }}
                                                    >
                                                        {`${removePlusMinusSymbol(detail.volume_percentage_change_24h)}`}
                                                    </Typography>
                                                    </div>
                                                </div>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Typography
                                                        gutterBottom
                                                        variant="body2"
                                                        component="div"
                                                        style={{
                                                            fontWeight: "bold",
                                                            fontSize: "12px",
                                                        }}
                                                        >
                                                            Cryptocurrency
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Line options={row.lineOptions} data={row.lineChartData} redraw={true} style={{ maxWidth: '125px' }} ></Line>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Button data-testid={`remove-watchlist-${row.id}`} onClick={(event: any) => this.handleRemoverButtonClick(event, row)} size='small' color="secondary">
                                                            Remove
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            })
                                        }
                                        { this.noDataMessage()}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6} style={{ height: '95vh' }}>
                        {selectedData && <Card variant="elevation" elevation={1} style={{ padding: 10, borderRadius: 15, height: "95%" }}>
                            <CardContent>
                                <Typography variant="h6" style={{ fontWeight: 900, marginLeft: 15 }}>
                                    Chart
                                </Typography>
                                <List>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar
                                                src={selectedData.image_url}
                                            />
                                        </ListItemAvatar>
                                        <ListItemText primary={selectedData.name} secondary={selectedData.symbol} />
                                        <ListItemSecondaryAction style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                                            <Typography variant="body1" style={{ fontSize: 14, fontWeight: 'bold' }}>
                                                {selectedData.price}
                                            </Typography>
                                            {selectedData.volume_percentage_change_24h &&
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                                                    {selectedData.volume_percentage_change_24h.toString().charAt(0) ===
                                                        "-" ? (
                                                        <ArrowDropDownIcon style={{ color: "#E86A3F" }} />
                                                    ) : (
                                                        <ArrowDropUpIcon style={{ color: "#01A24E" }} />
                                                    )}
                                                    <Typography
                                                        gutterBottom
                                                        variant="body2"
                                                        component="div"
                                                        style={{
                                                            whiteSpace: 'nowrap',
                                                            fontWeight: "bold",
                                                            fontSize: "12px",
                                                            color: selectedData.volume_percentage_change_24h
                                                                .toString()
                                                                .charAt(0) === "-"
                                                                ? "#E86A3F"
                                                                : "#01A24E",
                                                        }}
                                                    >
                                                        {removePlusMinusSymbol(selectedData.volume_percentage_change_24h)}
                                                    </Typography>
                                                </div>}
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                </List>

                                {selectedData && <CryptoChart selectedData={selectedData}
                                    chartPriceData={this.state.watchlistPriceChartData}
                                    chartVolumeData={this.state.watchlistVolumeChartData}
                                    chartMarketCapData={this.state.watchlistMarketCapData}
                                    getGraphData={this.watchlistGraphData}
                                    classes={classes}
                                    loading={this.state.graphLoader} />}
                            </CardContent>
                        </Card>}
                    </Grid>
                </Grid>
            </Paper>
        )
        // Customizable Area End
    }
}

export default withStyles(styles)(WatchListWeb);
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {
  activeFunda,
  activeIndicators,
  activePriceAction,
  activeVolume,
  funda,
  indicators,
  priceAction,
  volume
} from "./assets";
import AsyncStorage from "@react-native-async-storage/async-storage"
import { CommonContext } from "../../../framework/src/CommonContextProvider";
import { COLORS } from "../../../framework/src/Globals";
import ToastHandler from "../../../components/src/ToastHandler";
import { SavedFilter } from "../../dashboard/src/types";
import HelperClass from "../../../components/src/HelperClass";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  route?: any;
  onClick?: any;
  onClikcRank?: any;
  onClickVolume?: any;
  onPriceFilter?: any;
  onSelectTimeFrame1?: any;
  selectPeriod?: any;
  selectlengthInd1: any;
  OnClickrsiDivergence1: any;
  onSelectScreen: any;
  onClickRadio: any
  onClickBox1: any;
  check?:any;
  value?:any;
  onPriceRangePress?:any;
  save?:any;
  saveApply?:any;
  indicatorValue?:any;
  mode?:any;
  //mfi
  MFISelctedScreen: any;
  //Stochastic
  StochasticPicker: any
  //cciPicker
  cciPicker: any;
  //mvAvgPicker
  mvAvgPicker: any;
  //macdPicker
  macdPicker: any;

  //adxpicker
  adxPicker: any;
  //obvPicker
  obvPicker: any
  //cmfPicker
  cmfPicker: any
  //bolingerPicker
  BollingPicker: any
  //atrPicker
  atrPicker: any;
  // Volume trending picker
  VolumePicker?: any;

  isBasic:boolean
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  fundamentalCheck:boolean,
  volumeCheck:boolean,
  priceCheck:boolean,
  indicator:boolean,
  multipleFilterId:any,
  fundamentalISOpenedCheck:boolean,
  volumeISOpenedCheck:boolean,
  priceISOpenedCheck:boolean,
  indicatorISOpened:boolean,
  arrayHolder: any;
  getAllcatDataID:any;
  filterItems: any;
  token: string;
  multipleSaveCheck:boolean;
  multipleSaveFilterReloadCheck:boolean
  filterTypeMap : {
    "fundamental_filter_type":string,
    "volume_filter_type":string,
    "price_action_filter_type":string,
    "indicator_filter_type":string,
    "latest":string,
    "fundamentalFilterName":Array<string>,
    "volumeFilterName":string,
    "priceFilterName":string,
    "indicatorFilterName":string,
  };
  apiBodyIndicatorObject: {
    "indicator_screen_option": string,
    "indicator_period": string,
    "indicator_trending_period": string,
    "indicator_above_below": string,
    "indicator_trending_up_down": string,
    "indicator_level_centerline": string,
    "indicator_divergence_crossover": any,
    "indicator_moving_average_type": string,
    "indicator_time_frame": string
    "indicator_main_option": string,
  };
  saveFilterPopupVisible: boolean;
  showSavedFilterModal: boolean;
  savedFilters: SavedFilter[];
  filterName: string;
  displayFilterNameErrText: string;
  data: any;
  addMoreBtn: boolean;
  rankArray: any;
  fundamentalArray: any;
  defaultButtonText: any;
  addMoreFilter: boolean;
  addMore: boolean;
  industryArray: any;
  categoryArray: any;
  fundamentalArraySec: any
  transaction_count: boolean;
  transacion_value: boolean;
  categoryLabel: any;
  socialEngArray: any;
  socialEngLabel: any;
  market_capitalization: boolean;
  active_address: boolean;
  feesPaidArray: any;
  hash_rate: boolean;
  market_value_to_realized_value: boolean;
  subsocialEngLabel: any;
  net_value_transaction_ratio: boolean;
  catId: any;
  // Volumes Options
  defaultVolumeButtonText: any;
  volumeArray: any;
  volumeTrendUpSelected: boolean;
  avgVolumeMinValue: any;
  avgVolumeMaxValue: any;
  avgVolumeSelected: boolean;
  priceFrom: any;
  averageVolumeArr: any;
  volumeUpPeriod: any;
  volumePeriodList: any;
  volumeUpPeriodCustom: boolean;
  volumeUpPeriodCustomValue: any;
  volumeUpPeriodLength: any;
  RanderPageID: any;
  volumeDownPeriodCustom: any;
  volumeTrendDownSelected: boolean;
  volumeDownPeriod: any;
  volumeDownPeriodLength: any;
  market_from: any;
  market_to: any;
  activeFrom: any;
  activeTo: any;
  volumeDownPeriodCustomValue: any;
  customValue: any;
  rankLabel: any;
  subRankLabel: any;
  subIndusrtyLable: any;
  subCategoryLabel: any;
  avgVolumeLabel: string;
  industryLabel: any;
  //Derivatives picker state
  derivativesLabel: any;
  derivativesArray: any;
  isPutCallRation: boolean;
  buildupArray: any;
  topLable: any;
  putCallRatioFrom: any;
  putCallRatioTo: any


  //Price Action
  price: any;
  // arrayHolder: any;
  // categoryArray: any;
  catHolder: any;
  priceActionArray: any;
  optionsArray: any
  timeFrameArray: any;
  isParcentage: boolean;
  parcentageValue: any;
  channelArray: any;
  timeOptionArray: any;
  standardSupportArray: any;
  standardSupportLabel: any;
  timeFrameValue: any;
  isClose: boolean;
  reversalOptionArray: any;
  pickerValue: any;
  timeoptionValue: any;
  priceActiveLabel: any;
  channelValue: any;
  isPriceTrandingUP: boolean;
  reversalOptionValue: any;
  standardSupportValue: any;

  // Indicators
  indicatorsArray: any;
  selectArray: any;
  isRsiVisible: boolean;
  isRefresh: boolean;

  rsiLabel: any;
  rsiArray: any;
  periodInd: any;
  lengthInd: any;
  CrossOverArray: any;
  customInd: any;
  timeFameIndLabel: any;
  UrlIndicatorPath: any;
  timeFameIndArray: any;
  rsiCross: boolean;
  radioAbove: any;
  isDivergence: boolean;
  RsiDivergenceItem: any;
  rsiDivergencArray: any;
  isTimeFrameIdc: boolean;
  isCCI: boolean;
  movingAvgLabel: any;
  iscmf: boolean;
  movingAvgArray: any;
  derivativesArray1: any;
  selectScreenname: any
  //mfi
  mfiArray: any;
  mfiPeriod: any;
  mfiLength: any
  mfiTimeFrame: any;
  mfilabel: any;
  mfiCross: boolean;
  mfiCustome: any;
  mfiRadio: any;
  MfiDivergencArray: any;
  mfiradioAbove: any;
  isDivergenceMfi: any;
  MfiDivergenceItem: any;


  // stochastic
  stochasticArray: any;
  stochasticlabel: any;
  StocasticDivergencArray: any;
  StoDivergence: any;
  stochasticLength: any
  stochasticTimeFrame: any;
  stochasticradioAbove: any;
  stochasticCustome: any;
  StoDivergenceItem: any;
  stochasticPeriod: any;
  stochasticRadio: any;
  isDivergencestochastic: any;
  StochastickTimeFrame: any;

  //CCI
  isPeriodVisible: boolean;
  cciArray: any;
  ccilabel: any;
  cciPeriod: any;
  cciLength: any
  cciTimeFrame: any;
  cciCustome: any;
  cciRadio: any;
  CCIDivergencArray: any;
  cciradioAbove: any;
  isDivergencecci: any;
  CCIDivergence: any;
  cciDivergenceItem: any;
  // Moving Avg
  showScreens: boolean;
  showPeriod: boolean;
  mvAvgArray: any;
  mvAvglabel: any;
  mvAvgPeriod: any;
  mvAvgLength: any
  mvAvgTimeFrame: any;
  mvAvgCustome: any;
  isDivergencemvAvg: any;
  AVGMovingActive: any;
  mvAvgCrossover: any;
  mvAvgRadio: any;
  mvAvgradioAbove: any;
  crossOverValue: any;

  // MACD
  macdArray: any;
  MckDivergenceArray: any;
  macdlabel: any;
  macdPeriod: any;
  macdLength: any
  macdTimeFrame: any;
  macdDivergenceitem: any;
  macdCustome: any;
  MckDivergence: any;
  MacktrndingUpDown: any;
  macdRadio: any;
  isDivergencemacd: any;
  macdradioAbove: any;
  //ADX
  adxArray: any;
  adxlabel: any;
  adxPeriod: any;
  adxLength: any
  adxTimeFrame: any;
  adxCustome: any;
  adxRadio: any;
  adxradioAbove: any;
  isDivergenceadx: any;
  //OBV
  obvArray: any;
  obvlabel: any;
  obvPeriod: any;
  obvLength: any
  obvTimeFrame: any;
  obvCustome: any;
  isTimePeriod: boolean;
  isDivergenceobv: any;
  divergenceLable: any;
  //CMF
  cmfArray: any;
  cmflabel: any;
  cmfPeriod: any;
  cmfLength: any
  cmfTimeFrame: any;
  cmfCustome: any;
  isDivergencecmf: any;
  cmfradiosActive: any;
  cmfradio: any;
  divergenceLablecmf: any;
  cmfTimeFrameVisible: boolean;
  cmfPeriodVisible: boolean;
  //ATR
  atrArray: any;
  atrlabel: any;
  atrPeriod: any;
  atrLength: any
  atrTimeFrame: any;
  atrCustome: any;
  isDivergenceatr: any;
  divergenceLableatr: any;
  trending_period: any;
  //Bollinger Bands
  bollingerArray: any;
  bollingerlabel: any;
  bollingerPeriod: any;
  bollingerLength: any
  bollingerTimeFrame: any;
  bollingerCustome: any;
  isDivergencebollingerr: any;
  divergenceLablebollinger: any;
  bollingerRadio: boolean,


  // pass next screen
  isFilter: boolean,
  newData: any,
  nameOfFilter: any,
  parcentage: any,
  frameValue: any,
  subOption: any,
  temp: string,
  hellper: number
  isBasic: boolean
  isbasicText: string
  timeFameIndArrays:any
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class FilteritemsController extends BlockComponent<
  Props,
  S,
  SS
> {
  getProductApiCallId: any;
  // Customizable Area Start
  static contextType = CommonContext;
  dropdownRef: any;
  SaveBtn: string;
  fundamentalISOpenedCheck: boolean;
  volumeISOpenedCheck: boolean;
  priceISOpenedCheck: boolean;
  indicatorISOpened: boolean;
  SaveRunBtn: string;

  selectOptionTxt: string;
  FiltersTxt: string;
  rank10apiId: string;
  getAllcatDataID: string;
  categoriesApiId: string;
  getSavedFilterApiId: any;
  getSocailEngMentID: string;
  // getAllcatDataID:any;
  deleteFilterApiId: any;
  saveFilterApiCallId: any;
  saveMultipleFilterApiCallId:any;
  getMarketCapID: string;
  secondTextInput: any;
  socialEngBySymbolId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];
    //@ts-ignore
    this.state = {
      fundamentalISOpenedCheck:false,
  volumeISOpenedCheck:false,
  priceISOpenedCheck:false,
  indicatorISOpened:false,
      fundamentalCheck:false,
      volumeCheck:false,
      priceCheck:false,
      indicator:false,
      multipleFilterId:"",
      data: "",
      token: "",
      nameOfFilter: "",
      showSavedFilterModal: false,
      saveFilterPopupVisible: false,
      multipleSaveCheck:false,
      multipleSaveFilterReloadCheck:false,
      filterTypeMap : {
        "fundamental_filter_type":"",
        "volume_filter_type":"",
        "price_action_filter_type":"",
        "indicator_filter_type":"",
        "latest":"",
        "fundamentalFilterName":[],
        "volumeFilterName":"",
        "priceFilterName":"",
        "indicatorFilterName":"",
      },
      apiBodyIndicatorObject: {
        "indicator_screen_option": "",
        "indicator_period": "",
        "indicator_trending_period": "",
        "indicator_above_below": "",
        "indicator_trending_up_down": "",
        "indicator_level_centerline": "",
        "indicator_divergence_crossover": "",
        "indicator_moving_average_type": "",
        "indicator_time_frame": "",
        "indicator_main_option": ""
      },
      catId: '',
      subOption: '',
      newData: null,
      displayFilterNameErrText: "none",
      filterName: "",
      parcentage: null,
      frameValue: null,
      arrayHolder: [],
      fundamentalArraySec: [],
      filterItems: [
        {
          id: 1,
          active: activeFunda,
          inactive: funda,
          name: "Fundamentals",
          isSelected: false
        },
        {
          id: 2,
          active: activeVolume,
          inactive: volume,
          name: "Volumes",
          isSelected: false
        },
        {
          id: 3,
          active: activePriceAction,
          inactive: priceAction,
          name: "Price Action",
          isSelected: false
        },
        {
          id: 4,
          active: activeIndicators,
          inactive: indicators,
          name: "Indicators",
          isSelected: false
        },

      ],
      fundamentalArray: [
        "Rank", "Market Capitalization", "Category"
        // ,"Industry", "Social Engagement",
        // ,"Transaction Count", "Transaction Value",
        // "Fees Paid", "Active Address", "Hash Rate", "Net Value Transaction Ratio", "Market Value to Realized Value"
      ],

      rankArray: [],
      industryArray: [],
      categoryArray: [],
      socialEngArray: [],
      feesPaidArray: [],
      categoryLabel: '',
      socialEngLabel: '',
      subsocialEngLabel: '',
      defaultButtonText: 'Select a filter',
      defaultVolumeButtonText: "Select Volume Option",
      volumeArray: ["Average Volume", "Volume Trending Up", "Volume Trending Down"],
      market_capitalization: false,
      transaction_count: false,
      transacion_value: false,
      active_address: false,
      hash_rate: false,
      addMoreFilter: false,
      addMoreBtn: false,
      addMore: false,
      net_value_transaction_ratio: false,
      market_value_to_realized_value: false,
      avgVolumeSelected: false,
      avgVolumeMinValue: 0,
      avgVolumeMaxValue: 50,
      priceFrom: [
        {
          id: 1,
          value: "low_to_high",
          name: "Volume Low to High",
          isSelected: false
        },
        {
          id: 2,
          value: "high_to_low",
          name: "Volume High to Low",
          isSelected: false
        }
      ],
      volumeTrendUpSelected: false,
      volumeUpPeriod: "",
      volumePeriodList: [],
      volumeUpPeriodCustom: false,
      volumeUpPeriodCustomValue: "",
      volumeUpPeriodLength: "",
      RanderPageID: "",
      volumeTrendDownSelected: false,
      volumeDownPeriod: "",

      volumeDownPeriodCustomValue: "",
      volumeDownPeriodCustom: false,
      volumeDownPeriodLength: "",
      market_to: '',
      market_from: '',
      activeFrom: '',
      activeTo: '',
      customValue: '',
      rankLabel: '',
      subRankLabel: '',
      industryLabel: '',
      subIndusrtyLable: '',
      subCategoryLabel: '',
      avgVolumeLabel: '',
      //derivativesArray
      derivativesLabel: 'Select Derivative Option',
      derivativesArray: [
        "Long Buildup", "Short Buildup", "Short Covering", "Profit Booking", "Put Call Ratio"
      ],
      isPutCallRation: false,
      putCallRatioFrom: '',
      putCallRatioTo: '',
      buildupArray: [],
      topLable: '',
      //Price Action
      price: [],
      optionsArray: [],
      timeFrameArray: [],
      catHolder: [],
      priceActionArray: [
        "Gain/Loss", "High/low", "Breakouts/Breakdowns", "Support/Resistance", "Standard Patterns",
        "Reversal/Continuation Patterns", "Candlesticks"
      ],
      isParcentage: false,
      parcentageValue: '',
      standardSupportArray: [],
      standardSupportLabel: '',
      channelArray: [],
      timeOptionArray: [],
      reversalOptionArray: [],
      pickerValue: '',
      priceActiveLabel: '',
      timeFrameValue: '',
      isClose: false,
      isPriceTrandingUP: false,
      standardSupportValue: '',
      reversalOptionValue: '',
      timeoptionValue: '',
      channelValue: '',
      //indicators
      indicatorsArray: [],
      isRefresh: false,
      selectScreenname: '',
      selectArray: [],
      isRsiVisible: false,
      rsiLabel: '',
      rsiArray: [
        "RSI Above Overbought Level", "RSI Below Oversold Level", "Cross Overbought Level", "Cross Oversold Level", "Cross Centre Line", "Divergence"
      ],
      periodInd: '',
      lengthInd: '',
      timeFameIndArrays:[
        { id: 6, name: '1 hr', value: '1h' },
        { id: 7, name: '1 day', value: '1d' },
      ],
      timeFameIndArray: [
        { id: 6, name: '1 hr', value: '1h' },
        { id: 7, name: '2 hr', value: '2h' },
        { id: 8, name: '3 hr', value: '3h' },
        { id: 9, name: '4 hr', value: '4h' },
        { id: 10, name: '6 hr', value: '6h' },
        { id: 11, name: '12 hr', value: '12h' },
        { id: 12, name: '1 day', value: '1d' },
        { id: 13, name: '2 days', value: '2d' },
        { id: 14, name: '3 days', value: '3d' },
        { id: 15, name: '7 days', value: '7d' },
        { id: 16, name: '14 days', value: '14d' },
        { id: 17, name: '15 days', value: '15d' },
        { id: 18, name: '30 days', value: '30d' },
        { id: 19, name: '60 days', value: '60d' },
        { id: 20, name: '90 days', value: '90d' },
        { id: 21, name: '365 days', value: '365d' },
      ],
      CrossOverArray: [
        { label: "Bullish", value: "Bullish Crossovers" }, { label: "Bearish", value: "Bearish Crossovers" }],
      timeFameIndLabel: '',
      UrlIndicatorPath: '',
      customInd: '',
      rsiCross: false,
      radioAbove: 'Above',
      rsiDivergencArray: ["Bullish", "Bearish"],
      isDivergence: false,
      RsiDivergenceItem: '',
      isTimeFrameIdc: false,
      isCCI: false,
      movingAvgArray: [],
      movingAvgLabel: '',
      derivativesArray1: [],
      iscmf: false,
      //mfi
      mfiArray: [],
      mfilabel: '',
      mfiPeriod: '',
      mfiCross: false,
      mfiLength: '',
      mfiTimeFrame: [],
      mfiCustome: '',
      mfiRadio: false,
      mfiradioAbove: 'Above',
      isDivergenceMfi: false,
      MfiDivergenceItem: '',
      MfiDivergencArray: ["Bullish", "Bearish"],
      // stochastic
      stochasticArray: [],
      stochasticlabel: '',
      StocasticDivergencArray: ["Bullish", "Bearish"],
      StoDivergence: false,
      StoDivergenceItem: '',
      stochasticPeriod: '',
      stochasticLength: '',
      stochasticTimeFrame: '',
      stochasticCustome: '',
      stochasticRadio: false,
      stochasticradioAbove: 'Above',
      isDivergencestochastic: false,
      StochastickTimeFrame: false,

      //CCi
      isPeriodVisible: false,
      cciArray: [],
      ccilabel: '',
      cciPeriod: '',
      cciLength: '',
      cciTimeFrame: '',
      cciCustome: '',
      cciRadio: false,
      isDivergencecci: false,
      CCIDivergence: false,
      cciradioAbove: 'Above',
      cciDivergenceItem: '',
      CCIDivergencArray: ["Bullish", "Bearish"],

      //Movie Avg
      showScreens: false,
      showPeriod: true,
      mvAvgArray: [],
      mvAvglabel: '',
      mvAvgPeriod: '',
      mvAvgLength: '',
      mvAvgTimeFrame: '',
      mvAvgCustome: '',
      mvAvgradioAbove: 'Above',
      crossOverValue: '',
      mvAvgCrossover: ["Bullish Crossover"],
      mvAvgRadio: false,
      isDivergencemvAvg: false,
      AVGMovingActive: false,
      // MACD
      macdArray: [],
      MckDivergenceArray: ["Bullish", "Bearish"],
      macdDivergenceitem: '',
      macdlabel: '',
      macdPeriod: '',
      macdLength: '',
      macdTimeFrame: '',
      macdCustome: '',
      macdradioAbove: 'Above',
      macdRadio: false,
      isDivergencemacd: false,
      MckDivergence: false,
      MacktrndingUpDown: false,
      //ADX
      adxArray: [],
      adxlabel: '',
      adxPeriod: '',
      adxTimeFrame: '',
      adxCustome: '',
      adxLength: '',
      adxradioAbove: 'Above',
      adxRadio: false,
      isDivergenceadx: false,
      //OBV
      obvArray: [],
      obvlabel: '',
      obvLength: '',
      obvTimeFrame: '',
      obvCustome: '',
      obvPeriod: '',
      divergenceLable: '',
      isTimePeriod: false,
      isDivergenceobv: false,
      //CMF
      cmfArray: [],
      cmflabel: '',
      cmfLength: '',
      cmfTimeFrame: '',
      cmfPeriod: '',
      cmfCustome: '',
      divergenceLablecmf: '',
      cmfradio: 'Above',

      cmfradiosActive: true,
      isDivergencecmf: false,
      cmfTimeFrameVisible: false,
      cmfPeriodVisible: false,
      //ATR
      atrArray: [],
      atrlabel: '',
      atrPeriod: '',
      trending_period: '',
      atrLength: '',
      atrTimeFrame: '',
      atrCustome: '',
      isDivergenceatr: false,
      divergenceLableatr: '',
      //Bollinger
      bollingerArray: [],
      bollingerlabel: '',
      bollingerPeriod: '',
      bollingerTimeFrame: '',
      bollingerCustome: '',
      bollingerLength: '',
      divergenceLablebollinger: '',
      isDivergencebollingerr: false,
      bollingerRadio: false,
      isFilter: false,
      temp: '',
      averageVolumeArr: [],
      hellper: 0,
      isBasic:false
    };
    this.selectOptionTxt = configJSON.selectOptionTxt;
    this.FiltersTxt = configJSON.FiltersTxt;
    this.SaveBtn = configJSON.SaveBtn;
    this.SaveRunBtn = configJSON.SaveRunBtn;
    this.fundamentalISOpenedCheck = false;
    this.volumeISOpenedCheck = false;
    this.priceISOpenedCheck = false;
    this.indicatorISOpened = false;
    this.rank10apiId = '';
    this.categoriesApiId = '';
    this.getMarketCapID = '';

    this.getAllcatDataID = '';
    this.getSocailEngMentID = '';
    this.getPlan()
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getAllcatListFd()
    this.props.navigation.addListener("focus", () => {
      this.setState({ newData: null })
    });
    // Customizable Area Start
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      this.handleSessionResponse(message);
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.handleRestApiResponse(message);
    }
    // Customizable Area End
  }
  getListRequest = (token: any) => {
    
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area Start
  handleSessionResponse(message: Message) {
    let token = message.getData(getName(MessageEnum.SessionResponseToken));
    this.setState({ token: token });
    this.getListRequest(token);
  }

  savedFilterCancel() {
    this.setState({ showSavedFilterModal: false });
  }
  handleRestApiResponse(message: Message) {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
    if (apiRequestCallId) {
      if (responseJson.message == "Multi saved filter destroyed successfully") {
        this.getSavedFilters(true);
      return;
    }
      if (responseJson.message == "Saved filter destroyed successfully") {
          this.getSavedFilters();
        return;
      }
      switch (apiRequestCallId) {
        case this.getProductApiCallId:
          this.handleProductApiResponse(responseJson);
          break;
        case this.rank10apiId:
          this.handleRank10ApiResponse(responseJson);
          break;
        case this.getAllcatDataID:
          this.handleCategoriesApiResponse(responseJson);
          break;
          case this.categoriesApiId:
            this.handleCategoriesApiResponse(responseJson);
            break;
        case this.saveFilterApiCallId:
          this.handleSaveFilterApiResponse(responseJson);
          break;
          case this.saveMultipleFilterApiCallId:
            this.handleSaveMultipleFilterApiResponse(responseJson);
            break;
        case this.getSavedFilterApiId:
          this.handleSaveFilterApiCallResponse(responseJson.data);
          break;
        default:
      }
    }
  }
  handleSaveMultipleFilterApiResponse(responseJson:any){
    console.warn(JSON.stringify(responseJson)+"xxxx")
    this.setState({saveFilterPopupVisible:false})
    this.setState({multipleSaveCheck:false})
    ToastHandler.show("Filter Saved Successfully", false);
  }
  handleSaveFilterApiCallResponse(responseJson: SavedFilter[]) {
    if (responseJson) {
      this.setState({ savedFilters: responseJson });
    }

  }
  handleSaveFilterApiResponse(responseJson: any) {
    if (responseJson.message != "Saved filter created successfully") {
      alert(responseJson.message)
      return;
    }
    this.setState({ saveFilterPopupVisible: false },()=>{
      this.setState({ filterName: "" })
      this.onClickSave()
      ToastHandler.show("Filter Saved Successfully", false);
    })
  }
  handleProductApiResponse(responseJson: any) {
    if (responseJson && !responseJson.errors && responseJson.data) {
      this.setState({ arrayHolder: responseJson.data });
      let params = this.props.navigation.state.params;

      if (params.data.length > 0) {
        this.setState({ data: params.data });
      } else {
        //@ts-ignore
        this.showalert("Error", "No data Found");
      }
    } else {
      //@ts-ignore
      this.showalert("Error", responseJson ? responseJson : "Unknown Error");
      this.parseApiCatchErrorResponse(responseJson);
    }
  }

  handleRank10ApiResponse(responseJson: any) {
    if (!responseJson.errors) {
      if (responseJson.data.length !== 0) {
        this.props.navigation.navigate('SavePage', { data: responseJson.data });
      }
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  }

  handleCategoriesApiResponse(responseJson: any) {
    if (!responseJson.errors && responseJson?.data?.length !== 0) {
      const temp = responseJson.data.map((item: any) => ({
        name: item.name,
        id: item.id
      }));
      this.setState({ categoryArray: temp },()=>{});
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  }

  onClickFilter(d: any) {
    this.setState({ newData: d })
  }
  onClickRank(rank: any, name: any) {
    // alert(rank)
    this.setState({
      nameOfFilter: name,
      newData: rank
    })
    if(!this.state.filterTypeMap["fundamentalFilterName"].includes(name)){
      this.state.filterTypeMap["fundamentalFilterName"].push(name)
    }
   this.state.filterTypeMap["fundamental_filter_type"]="fundamental"
   this.state.filterTypeMap["latest"]="fundamentals"
   
  }

  onClikcVolume(name: string) {
    this.setState({ nameOfFilter: name })
    this.state.filterTypeMap["volumeFilterName"]= name
   this.state.filterTypeMap["volume_filter_type"]= "volume"
   this.state.filterTypeMap["latest"]="volume"
  }
  onClickMarCap = (form: any, to: any, name: any) => {
    this.setState({
      market_from: form,
      market_to: to,
      nameOfFilter: name
    })
    if(!this.state.filterTypeMap["fundamentalFilterName"].includes(name)){
      this.state.filterTypeMap["fundamentalFilterName"].push(name)
    }
    this.state.filterTypeMap["fundamental_filter_type"]="fundamental"
    this.state.filterTypeMap["latest"]="fundamentals"
  }

  onClickVolume = (name: string) => {
    this.setState({
      nameOfFilter: name
    })
    this.state.filterTypeMap["volumeFilterName"] = name
    this.state.filterTypeMap["volume_filter_type"]="volume"
    this.state.filterTypeMap["latest"]="volume"
  }
  // Price
  onPriceFilter = (frameValue: any, parcentage: any, filterName: any) => {
    var frameTime = frameValue.replace(/ /g, "")
    this.setState({
      nameOfFilter: filterName,
      frameValue: frameTime,
      parcentage: parcentage

    })
    this.state.filterTypeMap["priceFilterName"] = filterName
    this.state.filterTypeMap["price_action_filter_type"]="price_action"
    this.state.filterTypeMap["latest"]="price_action"
  }
  onPriceHighLow = (filterName: any) => {

    this.setState({
      nameOfFilter: filterName,
    })
    this.state.filterTypeMap["priceFilterName"] = filterName
    this.state.filterTypeMap["price_action_filter_type"]="price_action"
    this.state.filterTypeMap["latest"]="price_action"
  }


  onSupportResistance = (filtername: any, subfilter: any, timeFrame: any) => {
    this.setState({
      nameOfFilter: filtername,
      frameValue: timeFrame,
      subOption: subfilter
    })
    this.state.filterTypeMap["priceFilterName"] = filtername
    this.state.filterTypeMap["price_action_filter_type"]="price_action"
    this.state.filterTypeMap["latest"]="price_action"


  }
  onSupportPattern = (filtername: any, subfilter: any, timeFrame: any) => {
    this.setState({
      nameOfFilter: filtername,
      frameValue: timeFrame,
      subOption: subfilter
    })
    this.state.filterTypeMap["priceFilterName"] = filtername
    this.state.filterTypeMap["price_action_filter_type"]="price_action"
    this.state.filterTypeMap["latest"]="price_action"
  }

  onCandleStick = (filtername: any, subfilter: any, timeFram: any) => {
    // alert(timeFram+"candle")
    this.setState({
      nameOfFilter: filtername,

      subOption: subfilter,
      frameValue: timeFram,

    })
    this.state.filterTypeMap["priceFilterName"] = filtername
    this.state.filterTypeMap["price_action_filter_type"]="price_action"
    this.state.filterTypeMap["latest"]="price_action"
  }


  onSelectScreen = (filterName: any, selectindicator: any) => {

    if (filterName) {
      this.state.apiBodyIndicatorObject.indicator_screen_option = filterName;
      this.setState({
        selectScreenname: filterName,
      })
    }

  }
  onClickRadio(name: any) {
    this.state.apiBodyIndicatorObject.indicator_above_below = name;
    this.setState({ radioAbove: name })
  }
  selectPeriod = (selectPeriodind: any) => {
    this.state.apiBodyIndicatorObject.indicator_period = selectPeriodind;
    this.setState({
      periodInd: selectPeriodind,
    })
  }

  //MFI
  MFISelctedScreen = (MfiDivergenceItem: any, mfiTimeFrame: any, mfiradioAbove: any, MFperiod: any,
    MFISelScreenName: any, MFlength: any) => {
    this.setState({
      MfiDivergenceItem: MfiDivergenceItem,
      mfiradioAbove: mfiradioAbove,
      mfiTimeFrame: mfiTimeFrame,
      mfiPeriod: MFperiod,
      mfilabel: MFISelScreenName,
      mfiLength: MFlength
    })
    if (MFISelScreenName) {
      this.setState({
        apiBodyIndicatorObject: {
          "indicator_screen_option": MFISelScreenName,
          "indicator_period": MFperiod,
          "indicator_trending_period": "",
          "indicator_above_below": mfiradioAbove,
          "indicator_trending_up_down": "",
          "indicator_level_centerline": MFlength,
          "indicator_divergence_crossover": MfiDivergenceItem,
          "indicator_moving_average_type": "",
          "indicator_time_frame": mfiTimeFrame,
          "indicator_main_option": "MFI"
        }
      })
    }
  }
  //StochasticPicker
  StochasticPicker = (StoDivergenceItem: any, stochasticTimeFrame: any, stochasticradioAbove: any, stochasticPeriod: any, stochasticlabel: any, stochasticLength: any,) => {

    this.setState({
      StoDivergenceItem: StoDivergenceItem,
      stochasticradioAbove: stochasticradioAbove,
      stochasticTimeFrame: stochasticTimeFrame,
      stochasticPeriod: StoDivergenceItem === "Divergence" ? "14" : stochasticPeriod,
      stochasticlabel: stochasticlabel,
      stochasticLength: StoDivergenceItem === "Divergence" ? "80" : stochasticLength,
    })
    this.setState({
      apiBodyIndicatorObject: {
        "indicator_screen_option": stochasticlabel,
        "indicator_period": StoDivergenceItem === "Divergence" ? "14" : stochasticPeriod,
        "indicator_trending_period": "",
        "indicator_above_below": stochasticradioAbove,
        "indicator_trending_up_down": "",
        "indicator_level_centerline": StoDivergenceItem === "Divergence" ? "80" : stochasticLength,
        "indicator_divergence_crossover": StoDivergenceItem,
        "indicator_moving_average_type": "",
        "indicator_time_frame": stochasticTimeFrame,
        "indicator_main_option": "STOCHASTIC"
      }
    })
  }
  //cciPicker
  cciPicker = (cciDivergenceItem: any, cciTimeFrame: any, cciradioAbove: any, ccilabel: any, cciPeriod: any) => {
    this.setState({
      cciDivergenceItem: cciDivergenceItem,
      cciradioAbove: cciradioAbove,
      cciTimeFrame: cciTimeFrame,
      ccilabel: ccilabel,
      cciPeriod: cciPeriod
    })
    this.setState({
      apiBodyIndicatorObject: {
        "indicator_screen_option": ccilabel,
        "indicator_period": cciPeriod,
        "indicator_trending_period": "",
        "indicator_above_below": cciradioAbove,
        "indicator_trending_up_down": "",
        "indicator_level_centerline": "",
        "indicator_divergence_crossover": cciDivergenceItem,
        "indicator_moving_average_type": "",
        "indicator_time_frame": cciTimeFrame,
        "indicator_main_option": "CCI"
      }
    })
  }
  //moving AVG
  mvAvgPicker = (mvAvgTimeFrame: any, mvAvgPeriod: any, movingAvgLabel: any, mvAvglabel: any, crossOverValue: any) => {

    this.setState({
      mvAvgTimeFrame: mvAvgTimeFrame,
      mvAvgPeriod: mvAvgPeriod,
      movingAvgLabel: movingAvgLabel,
      mvAvglabel: mvAvglabel,
      crossOverValue /// change here also
    })
    this.setState({
      apiBodyIndicatorObject: {
        "indicator_screen_option": mvAvglabel,
        "indicator_period": mvAvgPeriod,
        "indicator_trending_period": "",
        "indicator_above_below": "",
        "indicator_trending_up_down": "",
        "indicator_level_centerline": "",
        "indicator_divergence_crossover": crossOverValue,
        "indicator_moving_average_type": movingAvgLabel,
        "indicator_time_frame": mvAvgTimeFrame,
        "indicator_main_option": "Moving Average"
      }
    })
  }

  macdPicker = (macdDivergenceitem: any, macdTimeFrame: any, macdradioAbove: any, macdlabel: any, macdPeriod: any) => {
    this.setState({
      macdDivergenceitem: macdDivergenceitem,
      macdradioAbove: macdradioAbove,
      macdTimeFrame: macdTimeFrame,
      macdlabel: macdlabel,
      macdPeriod: macdlabel === "MACD Divergence" ? "14" : macdPeriod,
    })

    this.setState({
      apiBodyIndicatorObject: {
        "indicator_screen_option": macdlabel,
        "indicator_period": macdlabel === "MACD Divergence" ? "14" : macdPeriod,
        "indicator_trending_period": "",
        "indicator_above_below": macdradioAbove,
        "indicator_trending_up_down": "",
        "indicator_level_centerline": "",
        "indicator_divergence_crossover": macdDivergenceitem,
        "indicator_moving_average_type": "",
        "indicator_time_frame": macdTimeFrame,
        "indicator_main_option": "MACD"
      }
    })
  }
  adxPicker = (adxTimeFrame: any, adxradioAbove: any, adxlabel: any, adxPeriod: any, adxLength: any) => {
    this.setState({
      adxTimeFrame: adxTimeFrame,
      adxradioAbove: adxradioAbove,
      adxlabel: adxlabel,
      adxPeriod: adxPeriod,
      adxLength: adxLength
    })
    this.setState({
      apiBodyIndicatorObject: {
        "indicator_screen_option": adxlabel,
        "indicator_period": adxPeriod,
        "indicator_trending_period": "",
        "indicator_above_below": adxradioAbove,
        "indicator_trending_up_down": "",
        "indicator_level_centerline": adxLength,
        "indicator_divergence_crossover": "",
        "indicator_moving_average_type": "",
        "indicator_time_frame": adxTimeFrame,
        "indicator_main_option": "ADX"
      }
    })
  }
  obvPicker = (obvTimeFrame: any, divergenceLable: any, obvlabel: any, obvPeriod: any) => {
    this.setState({
      divergenceLable: divergenceLable,
      obvTimeFrame: obvTimeFrame,
      obvlabel: obvlabel,
      obvPeriod: obvPeriod
    })
    this.setState({
      apiBodyIndicatorObject: {
        "indicator_screen_option": obvlabel,
        "indicator_period": obvPeriod,
        "indicator_trending_period": "",
        "indicator_above_below": "",
        "indicator_trending_up_down": "",
        "indicator_level_centerline": "",
        "indicator_divergence_crossover": divergenceLable,
        "indicator_moving_average_type": "",
        "indicator_time_frame": obvTimeFrame,
        "indicator_main_option": "OBV"
      }
    })
  }

  cmfPicker = (cmfTimeFrame: any, cmfradio: any, divergenceLablecmf: any, cmflabel: any, cmfPeriod: any) => {
    if (cmflabel != "") {
      this.setState({
        cmfTimeFrame: cmfTimeFrame,
        cmfradio: cmfradio,
        divergenceLablecmf: divergenceLablecmf,
        cmflabel: cmflabel,
        cmfPeriod: cmfPeriod
      })

      this.setState({
        apiBodyIndicatorObject: {
          "indicator_screen_option": cmflabel,
          "indicator_period": cmfPeriod,
          "indicator_trending_period": "",
          "indicator_above_below": cmfradio,
          "indicator_trending_up_down": "",
          "indicator_level_centerline": "",
          "indicator_divergence_crossover": divergenceLablecmf,
          "indicator_moving_average_type": "",
          "indicator_time_frame": cmfTimeFrame,
          "indicator_main_option": "CMF"
        }
      })
    }

  }
  BollingPicker = (bollingerTimeFrame: any, radioAbove: any, bollingerlabel: any) => {
    this.setState({
      bollingerTimeFrame: bollingerTimeFrame,
      radioAbove: radioAbove,
      bollingerlabel: bollingerlabel,
    })

    this.setState({
      apiBodyIndicatorObject: {
        "indicator_screen_option": bollingerlabel,
        "indicator_period": "",
        "indicator_trending_period": "",
        "indicator_above_below": radioAbove,
        "indicator_trending_up_down": "",
        "indicator_level_centerline": "",
        "indicator_divergence_crossover": "",
        "indicator_moving_average_type": "",
        "indicator_time_frame": bollingerTimeFrame,
        "indicator_main_option": "Bollinger"
      }
    })
  }
  atrPicker = (atrTimeFrame: any, atrPeriod: any, atrlabel: any, trending_period: any, clearAll?: boolean) => {
    this.setState({
      atrTimeFrame,
      atrPeriod,
      atrlabel,
      trending_period
    })

    this.setState({
      apiBodyIndicatorObject: {
        "indicator_screen_option": atrlabel,
        "indicator_period": atrPeriod,
        "indicator_trending_period": trending_period,
        "indicator_above_below": "",
        "indicator_trending_up_down": "",
        "indicator_level_centerline": "",
        "indicator_divergence_crossover": "",
        "indicator_moving_average_type": "",
        "indicator_time_frame": atrTimeFrame,
        "indicator_main_option": clearAll ? "" : "ATR"
      }
    })
  }


  // VolumerPicker 
  VolumePicker = (defaultVolumeButtonText: any, avgVolumeMinValue: any, avgVolumeMaxValue: any, priceFrom: any, volumeUpPeriodLength: any, volumeUpPeriod: any, volumeDownPeriod: any, volumeDownPeriodLength: any, randerid: any) => {
    this.setState({
      defaultVolumeButtonText: defaultVolumeButtonText,
      avgVolumeMaxValue: avgVolumeMaxValue,
      avgVolumeMinValue: avgVolumeMinValue,
      priceFrom: priceFrom,
      volumeUpPeriodLength: volumeUpPeriodLength,
      volumeUpPeriod: volumeUpPeriod,
      volumeDownPeriod: volumeDownPeriod,
      volumeDownPeriodLength: volumeDownPeriodLength
    })
  }



  indicatorSelector(item: any) {
    const { indicatorsArray } = this.state
    for (let i in indicatorsArray) {
      if (indicatorsArray[i].id === item.id) {
        if (indicatorsArray[i].isSelect === false) {
          this.setState({
            isRefresh: !this.state.isRefresh,
            nameOfFilter: '',
            selectArray: [item.name],
            hellper: 1,
            temp: item.name,
            indicatorsArray: [...this.state.indicatorsArray, { isSelect: true }]
          })
        } else {
          this.setState({
            nameOfFilter: '',
            temp: "",
            selectArray: [""],
            indicatorsArray: [...this.state.indicatorsArray, { isSelect: false }]
          })
        }
      }
    }
  }

  onClickBox1 = (item: any) => {
    this.setState({
      apiBodyIndicatorObject: {
        "indicator_screen_option": "",
        "indicator_period": "",
        "indicator_trending_period": "",
        "indicator_above_below": "",
        "indicator_trending_up_down": "",
        "indicator_level_centerline": "",
        "indicator_divergence_crossover": "",
        "indicator_moving_average_type": "",
        "indicator_time_frame": "",
        "indicator_main_option": item.name
      }
    })
    this.state.filterTypeMap["indicatorFilterName"] = "indicator"
    this.state.filterTypeMap["indicator_filter_type"]="indicator"
    this.state.filterTypeMap["latest"]="indicator"
    const { indicatorsArray } = this.state
    if (indicatorsArray.lenght !== 0) {
      this.indicatorSelector(item)
    }
  }
  selectlengthInd1 = (selectlen: any) => {
    this.state.apiBodyIndicatorObject.indicator_level_centerline = selectlen;
    this.setState({
      lengthInd: selectlen
    })
  }

  onSelectTimeFrame1 = (selectTim: any) => {
    this.state.apiBodyIndicatorObject.indicator_time_frame = selectTim;
    this.setState({
      timeFameIndLabel: selectTim
    })
  }
  OnClickrsiDivergence1 = (selectDiv: any) => {
    this.state.apiBodyIndicatorObject.indicator_level_centerline = "30";
    this.state.apiBodyIndicatorObject.indicator_divergence_crossover = selectDiv;
    this.state.apiBodyIndicatorObject.indicator_period = "14";
    this.setState({
      RsiDivergenceItem: selectDiv,
      periodInd: "14",
      lengthInd: "30"
    })
  }

  defaultVolumeButtonFxn1(multipleCheck?:any) {
    switch (this.state.defaultVolumeButtonText) {
      case "Average Volume":
        let helperVolume = "volume_average";
        let result = this.state.priceFrom.find((e: any) => e.isSelected === true);
        if (result === undefined) {
          alert("Please Select Volume");
          return false;
        }
        if(!multipleCheck){
          this.props.navigation.navigate('VolumePage', {
            RenderApi: true,
            volumeFilter: helperVolume,
            avgVolumeMaxValue: this.state.avgVolumeMaxValue,
            avgVolumeMinValue: this.state.avgVolumeMinValue,
            priceFrom: result.value,
            volumeUpPeriod: this.state.volumeUpPeriod,
            mode:this.context.mode
          });
        }
       
        break;

      case "Volume Trending Up":
        let helperVolumeUp = "up";
        if (this.state.volumeUpPeriodLength === "" || this.state.volumeUpPeriod === "") {
          alert("Please Select All filter Option");
          return false;
        }
        if(!multipleCheck){
          this.props.navigation.navigate('VolumePage', {
            RenderApi: false,
            volumeFilter: helperVolumeUp,
            VolumeLength: this.state.volumeUpPeriodLength,
            volumeUpPeriod: this.state.volumeUpPeriod,
            mode:this.context.mode
          });
        }
      
        break;

      case "Volume Trending Down":
        if (this.state.volumeDownPeriodLength === "" || this.state.volumeDownPeriod === "") {
          alert("Please Select All filter Option");
          return false;
        }
        let helperVolumeDown = "down";
        if(!multipleCheck){
          this.props.navigation.navigate('VolumePage', {
            RenderApi: false,
            volumeFilter: helperVolumeDown,
            VolumeLength: this.state.volumeDownPeriodLength,
            volumeUpPeriod: this.state.volumeDownPeriod,
            mode:this.context.mode
          });
        }
       
        break;

      default:
        // Handle default case if needed
        break;
    }
  }
  async getAllcatListFd() {
    let tok = await AsyncStorage.getItem("userToken");
    // this.setState({ isLoading: true })
    const header = {
        token: tok
    };
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllcatDataID = requestMessage.messageId;
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.GetCatApiEndPoint
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
}
  navigateFunction(options: any) {
    this.props.navigation.navigate('SavePage', options);
  }

  onClickSave(indicatprcheck?: any) {
    let CheckValidation = this.state.filterItems.find((e: any) => e.isSelected === true);
    if (CheckValidation === undefined) {
      return false;
    }
    const filterNamesArray = [this.state.filterTypeMap["fundamentalFilterName"],
    this.state.filterTypeMap["volumeFilterName"],this.state.filterTypeMap["indicatorFilterName"],
    this.state.filterTypeMap["priceFilterName"]
  ]
    const currentFilterObject:any = {
      "fundamental":filterNamesArray[0],
      "volume":filterNamesArray[1],
      "price_action":filterNamesArray[3],
      "indicator":filterNamesArray[2],
    }
    let filterName="";
    if((typeof currentFilterObject[this.state.filterTypeMap["latest"]] !== "object")){
      filterName = currentFilterObject[this.state.filterTypeMap["latest"]]
    }else if(typeof currentFilterObject[this.state.filterTypeMap["latest"]]=="object"&& currentFilterObject[this.state.filterTypeMap["latest"]].length==1){
      filterName = currentFilterObject[this.state.filterTypeMap["latest"]][0]
    }else{
      filterName = "multipleFundamental"
    }

    switch (filterName) {
      case "multipleFundamental":
        let obj :any = {"name":[]}
        currentFilterObject[this.state.filterTypeMap["latest"]]?.forEach((item:any)=>{
            if(item=="MarketCapitalization"){
              obj["from"]= this.state.market_from
              obj["name"].push(item)
              obj["to"]= this.state.market_to
              
            }
            if(item=="Rank"){
              obj["name"].push(item)
              obj["data"]=  this.state.newData
              
            }
            if(item=="Category"){
              obj["name"].push(item)
              obj["dataCategory"]=  this.state.subCategoryLabel.id
            }
        })
        this.navigateFunction({
          obj
        });
        break;
      case "MarketCapitalization":
        this.navigateFunction({
          from: this.state.market_from,
          name: filterName,
          to: this.state.market_to
        });
        break;
      case "Rank":
        case "SocialEngagement":
        this.navigateFunction({
          name: filterName,
          data: this.state.newData
        });
        break;
      case "Category":
        this.navigateFunction({
          name: filterName,
          data: this.state.subCategoryLabel.id
        });
        break;
      case "Price Up%":
      case "Price Down%":
      case "Price Trending UP":
      case "Price Trending Down":
        this.navigateFunction({
          parcentageValue: this.state.parcentage,
          frameValue: this.state.frameValue,
          name: filterName,
          parentFiltername: 'Gain/Loss'
        });
        break;
      case "Near All Time High":
      case "Near All Time Low":
      case "At All Time High":
      case "At All Time Low":
      case "Near 52 Weeks High":
      case "Near 52 Weeks Low":
      case "Near Past Week High":
      case "Near Past Week Low":
        this.navigateFunction({
          name: filterName,
          parentFiltername: 'High/Low'
        });
        break;
      case "All Time High Breakout":
      case "All Time Low Breakdown":
      case "52 Week High Breakout":
      case "52 Week Low Breakdown":
      case "Past Week High Breakout":
      case "Past Week Low Breakdown":
        this.navigateFunction({
          name: filterName,
          parentFiltername: 'Breakouts/down'
        });
        break;
      case "Standard Support":
      case "Standard Resistance":
      case "Fibonacci Support":
      case "Fibonacci Resistance":
        this.navigateFunction({
          name: filterName,
          frameValue: this.state.frameValue,
          subOption: typeof this.state.subOption == "object"?this.state.subOption?.name:this.state.subOption,
          parentFiltername: 'Support/Resistance'
        });
        break;
      case "Flat Channel":
      case "Ascending Channel":
      case "Descending Channel":
        this.navigateFunction({
          name: filterName,
          frameValue: this.state.frameValue,
          subOption: typeof this.state.subOption == "object"?this.state.subOption?.value:this.state.subOption,
          parentFiltername: 'Standard Patterns'
        });
        break;
      case "Bullish Candle Scans":
      case "Bearish Candle Scans":
      case "Other Patterns":
        this.navigateFunction({
          name: filterName,
          frameValue: this.state.frameValue,
          subOption: this.state.subOption,
          parentFiltername: 'candlestick'
        });
        break;
      case "Bullish Reversals":
      case "Bearish Reversals":
      case "Continuation Patterns":
        this.navigateFunction({
          name: filterName,
          frameValue: this.state.frameValue,
          subOption: this.state.subOption,
          parentFiltername: 'Reversal/Continuation Patterns'
        });
        break;
      case "volume":
        this.defaultVolumeButtonFxn1()
        break;
      default:
        if (this.state.selectArray.length > 0&&filterName=="indicator") {
           this.IndIcatorFuntion(indicatprcheck===true?indicatprcheck:false);
        } else if (this.state.defaultVolumeButtonText !== "Select Volume Option"&&filterName=="volume") {
           this.defaultVolumeButtonFxn1()
        }
        break;
    }
  }

  IndIcatorFuntion = (indicatprcheck?:any,multipleCheck?:any) => {
    const { selectArray } = this.state
    if (selectArray.length > 1) {
      alert("work-in-progress")
      return false
    }
    if (selectArray[0] == "RSI") {
      if (this.state.timeFameIndLabel == '' || this.state.selectScreenname == '') {
        alert("Please fill all option")
        indicatprcheck && this.setState({ saveFilterPopupVisible: false })
        return false
      }
      if (this.state.selectScreenname == "Divergence") {
        if (this.state.RsiDivergenceItem == '') {
          alert("Please fill all option")
          indicatprcheck && this.setState({ saveFilterPopupVisible: false })
          return false
        }

      }
      let rsiLabhelper = ''
      if (this.state.selectScreenname == "Cross Overbought Level" || this.state.selectScreenname == "Cross Oversold Level") {
        if (this.state.radioAbove == 'Above') {
          rsiLabhelper = 'cross above overbought level'
        } else {
          rsiLabhelper = 'cross below overbought level'
        }
      } else if (this.state.selectScreenname == "Cross Centre Line") {
        if (this.state.radioAbove == 'Above') {

          rsiLabhelper = 'cross above center line'
        } else {
          rsiLabhelper = 'cross below center line'
        }
      } else if (this.state.selectScreenname == "Divergence") {
        rsiLabhelper = "divergence" + " " + this.state.RsiDivergenceItem.toLowerCase()

      }
      else {
        rsiLabhelper = this.state.selectScreenname
      }
      if (indicatprcheck) {
        this.setState({ saveFilterPopupVisible: false })
        this.saveFilterApiCall(this.state.filterName)
      }
      if(!multipleCheck){
        this.props.navigation.navigate('Rsi', 
        { UrlIndicatorPath: selectArray[0].toLowerCase(), timeFameIndLabel: 
          this.state.timeFameIndLabel, periodInd: this.state.periodInd, Fnamedata: 
          rsiLabhelper, lengthInd: this.state.lengthInd, mode:this.context.mode})
          return false
      }
    
     
    }
    if (selectArray[0] == "MFI") {
      if (this.state.mfiTimeFrame == '' || this.state.mfilabel == '') {
        alert("Please fill all option1")
        indicatprcheck && this.setState({saveFilterPopupVisible:false})
        return false
      }
      if (this.state.mfilabel == "Divergence") {
        if (this.state.MfiDivergenceItem == '') {
          alert("Please fill all option")
          indicatprcheck && this.setState({ saveFilterPopupVisible: false })
          return false
        }
      }

      let rsiLabhelper = ""
      if (this.state.mfilabel == "Cross Overbought Level") {
        if (this.state.mfiradioAbove == 'Above') {
          rsiLabhelper = 'cross above overbought level'
        } else {
          rsiLabhelper = 'cross below overbought level'
        }
      } else if (this.state.mfilabel == "Cross Oversold Level") {

        if (this.state.mfiradioAbove == 'Above') {

          rsiLabhelper = 'cross above Oversold level'
        } else {
          rsiLabhelper = 'cross below Oversold level'
        }
      } else if (this.state.mfilabel == "Cross Centre Line") {
        if (this.state.mfiradioAbove == 'Above') {

          rsiLabhelper = 'cross above center line'
        } else {
          rsiLabhelper = 'cross below center line'
        }
      } else if (this.state.mfilabel == "Divergence") {

        rsiLabhelper = "divergence" + " " + this.state.MfiDivergenceItem.toLowerCase()
      } else {

        rsiLabhelper = String(this.state.mfilabel).toLowerCase()

      }
      if (indicatprcheck) {
        this.setState({ saveFilterPopupVisible: false })
        this.saveFilterApiCall(this.state.filterName)
      }
      if(!multipleCheck){
      this.props.navigation.navigate('Rsi', { mode:this.context.mode,UrlIndicatorPath: selectArray[0].toLowerCase(), timeFameIndLabel: this.state.mfiTimeFrame, periodInd: this.state.mfiPeriod, Fnamedata: rsiLabhelper, lengthInd: this.state.mfiLength, })
      return false
      }
    }

    if (selectArray[0] == "Stochastic") {
      if (this.state.stochasticTimeFrame == '' || this.state.stochasticlabel == '') {
        alert("Please fill all option")
        indicatprcheck && this.setState({ saveFilterPopupVisible: false })
        return false
      }
      if (this.state.stochasticlabel == "Divergence") {
        if (this.state.StoDivergenceItem == '') {
          alert("Please fill all option")
          indicatprcheck && this.setState({ saveFilterPopupVisible: false })
          return false
        }
      }
      let stocastiklabel = ""
      if (this.state.stochasticlabel == "Cross Overbought Level") {

        if (this.state.stochasticradioAbove == 'Above') {

          stocastiklabel = 'k cross above overbought level'
        } else {
          stocastiklabel = 'k cross below overbought level'
        }
      } else if (this.state.stochasticlabel == "Cross Oversold Level") {
        if (this.state.stochasticradioAbove == 'Above') {

          stocastiklabel = 'k cross above oversold level'
        } else {
          stocastiklabel = 'k cross below oversold level'
        }
      }
      else if (this.state.stochasticlabel == "%K Cross Centre Line") {
        if (this.state.stochasticradioAbove == 'Above') {

          stocastiklabel = 'k cross above centerline'
        } else {
          stocastiklabel = 'k cross below centerline'
        }
      } else if (this.state.stochasticlabel == "%K Overbought Level") {
        if (this.state.stochasticradioAbove == 'Above') {
          stocastiklabel = 'k above overbought level'
        } else {
          stocastiklabel = 'k below overbought level'
        }
      } else if (this.state.stochasticlabel == "%K Oversold Level") {
        if (this.state.stochasticradioAbove == 'Above') {
          stocastiklabel = 'k above oversold level'
        } else {
          stocastiklabel = 'k below oversold level'
        }
      } else if (this.state.stochasticlabel == "%K Cross %D") {

        if (this.state.stochasticradioAbove == 'Above') {

          stocastiklabel = 'k cross Above d'
        } else {
          stocastiklabel = 'k cross below d'
        }
      } else if (this.state.stochasticlabel == "Divergence") {

        stocastiklabel = "divergence" + " " + this.state.StoDivergenceItem.toLowerCase()
      }
      if (indicatprcheck) {
        this.setState({ saveFilterPopupVisible: false })
        this.saveFilterApiCall(this.state.filterName)
      }
      if(!multipleCheck){
      this.props.navigation.navigate('Rsi', { mode:this.context.mode,UrlIndicatorPath: selectArray[0].toLowerCase(), timeFameIndLabel: this.state.stochasticTimeFrame, periodInd: this.state.stochasticPeriod, Fnamedata: stocastiklabel, lengthInd: this.state.stochasticLength })
      return false}
    }

    if (selectArray[0] == "CCI") {
      if (this.state.cciTimeFrame == '' || this.state.ccilabel == '') {
        alert("Please fill all option")
        indicatprcheck && this.setState({ saveFilterPopupVisible: false })
        return false
      }
      if (this.state.ccilabel == "Divergence") {
        if (this.state.cciDivergenceItem == '') {
          alert("Please fill all option")
          indicatprcheck && this.setState({ saveFilterPopupVisible: false })
          return false
        }
      }
      let ccihelperLabel = ""

      if (this.state.ccilabel == "Cross +100") {
        if (this.state.cciradioAbove == 'Above') {

          ccihelperLabel = 'cci cross above plus 100'
        } else {
          ccihelperLabel = 'cci cross below plus 100'
        }
      } else if (this.state.ccilabel == "Cross -100") {
        if (this.state.cciradioAbove == 'Above') {
          ccihelperLabel = 'cci cross above minus 100'
        }else{
          ccihelperLabel = 'cci cross below minus 100'
        }
      } else if (this.state.ccilabel == "Cross 0") {
        if (this.state.cciradioAbove == 'Above') {

          ccihelperLabel = 'cci cross above 0'
        } else {
          ccihelperLabel = 'cci cross below 0'
        }
      } else if (this.state.ccilabel == "CCI Above +100") {
        ccihelperLabel = 'cci above plus 100'
      } else if (this.state.ccilabel == "CCI Below -100") {
        ccihelperLabel = 'cci below minus 100'
      } else if (this.state.ccilabel == "Divergence") {
        ccihelperLabel = "divergence" + " " + this.state.cciDivergenceItem.toLowerCase()
      }
      if (indicatprcheck) {
        this.setState({ saveFilterPopupVisible: false })
        this.saveFilterApiCall(this.state.filterName)
      }
      if(!multipleCheck){
      this.props.navigation.navigate('cci', { mode:this.context.mode,UrlIndicatorPath: selectArray[0].toLowerCase(), timeFameCCI: this.state.cciTimeFrame, cciLabel: ccihelperLabel, cciPeriod: this.state.cciPeriod })
      return false}
    }
    if (selectArray[0] == "Moving Average") {
      if (this.state.mvAvglabel === '2 Moving Average Crossovers' && (this.state.mvAvglabel === '' || this.state.movingAvgLabel === '')) {
        alert("Please fill all options");
        indicatprcheck && this.setState({saveFilterPopupVisible:false})
        return false;
      }
      if (this.state.mvAvglabel !== '2 Moving Average Crossovers' && (this.state.mvAvglabel === '' || this.state.movingAvgLabel === '' || this.state.mvAvgTimeFrame === '')) {
        alert("Please fill all options");
        indicatprcheck && this.setState({saveFilterPopupVisible:false})
        return false;
      }
      let mvghelpr = ''
      if (this.state.movingAvgLabel == 'Simple Moving Average') {
        mvghelpr = 'simple'
      } else if (this.state.movingAvgLabel == 'Exponential Moving Average') {
        mvghelpr = 'exponential'
      }
      if (indicatprcheck) {
        this.setState({ saveFilterPopupVisible: false })
        this.saveFilterApiCall(this.state.filterName)
      }
      if(!multipleCheck){
      this.props.navigation.navigate('moving', { mode:this.context.mode,UrlIndicatorPath: selectArray[0].toLowerCase(), mvgPeriod: this.state.mvAvgPeriod, Mavg: mvghelpr, option: { value: this.state.mvAvglabel == "2 Moving Average Crossovers" ? this.state.crossOverValue.value : this.state.mvAvglabel }, mvgTimeFrame: this.state.mvAvgTimeFrame })
      return false}
    }
    if (selectArray[0] == "MACD") {

      if (this.state.macdTimeFrame == '' || this.state.macdlabel == '') {
        alert("Please fill all option")
        indicatprcheck && this.setState({ saveFilterPopupVisible: false })
        return false
      }
      if (this.state.macdlabel == "Divergence") {
        if (this.state.macdDivergenceitem == '') {
          alert("Please fill all option")
          indicatprcheck && this.setState({ saveFilterPopupVisible: false })
          return false
        }

      }
      let macdhelperLabel = ""

      if (this.state.macdlabel == "MACD is Centerline(0)") {
        if (this.state.macdradioAbove == 'Above') {

          macdhelperLabel = 'macd is above centerline'
        } else {
          macdhelperLabel = 'macd is below centerline'
        }
      } else if (this.state.macdlabel == "MACD Crossed Centerline(0)") {
        if (this.state.macdradioAbove == 'Above') {

          macdhelperLabel = 'macd crossed above centerline'
        } else {
          macdhelperLabel = 'macd crossed below centerline'
        }
      } else if (this.state.macdlabel == "MACD Line is Signal Line") {
        if (this.state.macdradioAbove == 'Above') {

          macdhelperLabel = 'macd line is above signal line'
        } else {
          macdhelperLabel = 'macd line is below signal line'
        }
      } else if (this.state.macdlabel == "MACD Line Crossed Signal Line") {
        if (this.state.macdradioAbove == 'Above') {

          macdhelperLabel = 'macd line crossed above signal line'
        } else {
          macdhelperLabel = 'macd line crossed below signal line'
        }
      } else if (this.state.macdlabel == "MACD Trending Up/Down") {
        if (this.state.macdradioAbove == 'Above') {

          macdhelperLabel = 'macd trending up'
        } else {
          macdhelperLabel = 'macd trending down'
        }
      } else if (this.state.macdlabel == "MACD Divergence") {

        macdhelperLabel = "divergence" + " " + this.state.macdDivergenceitem.toLowerCase();
      }
      if (indicatprcheck) {
        this.setState({ saveFilterPopupVisible: false })
        this.saveFilterApiCall(this.state.filterName)
      }
      if(!multipleCheck){
      this.props.navigation.navigate('macd', { mode:this.context.mode,UrlIndicatorPath: selectArray[0].toLowerCase(), macdPeriod: this.state.macdPeriod, option: macdhelperLabel, macdTimeFrame: this.state.macdTimeFrame })
      return false}
    }
    if (selectArray[0] == "ADX") {
      if (this.state.adxTimeFrame == '') {
        alert("Please fill all option")
        indicatprcheck && this.setState({ saveFilterPopupVisible: false })
        return false
      }
      let adxheleprLable = ""
      if (this.state.adxlabel == "+DI Selected Level") {
        if (this.state.adxradioAbove == "Above") {
          adxheleprLable = "positive_di above selected level"
        } else {
          adxheleprLable = "positive_di below selected level"
        }
      } else if (this.state.adxlabel == "+DI Cross Selected Level") {
        if (this.state.adxradioAbove == "Above") {
          adxheleprLable = "positive_di cross above selected level"
        } else {
          adxheleprLable = "positive_di cross below selected level"
        }
      } else if (this.state.adxlabel == "+DI Cross -DI") {
        if (this.state.adxradioAbove == "Above") {
          adxheleprLable = "positive_di cross above negative_di"
        } else {
          adxheleprLable = "positive_di cross below negative_di"
        }
      } else if (this.state.adxlabel == "+DI -DI") {
        if (this.state.adxradioAbove == "Above") {
          adxheleprLable = "positive_di above negative_di"
        } else {
          adxheleprLable = "positive_di below negative_di"
        }
      }else if (this.state.adxlabel == "-DI +DI") {
        if (this.state.adxradioAbove == "Above") {
          adxheleprLable = "negative_di above positive_di"
        } else {
          adxheleprLable = "negative_di below positive_di"
        }
      }
       else if (this.state.adxlabel == "-DI Selected Level") {
        if (this.state.adxradioAbove == "Above") {
          adxheleprLable = "negative_di above selected level"
        } else {
          adxheleprLable = "negative_di below selected level"
        }
      } else if (this.state.adxlabel == "-DI Cross Selected Level") {
        if (this.state.adxradioAbove == "Above") {
          adxheleprLable = "negative_di cross above selected level"
        } else {
          adxheleprLable = "negative_di cross below selected level"
        }
      } else if (this.state.adxlabel == "-DI Cross +DI") {
        if (this.state.adxradioAbove == "Above") {
          adxheleprLable = "negative_di cross above positive_di"
        } else {
          adxheleprLable = "negative_di cross below positive_di"
        }
      } else if (this.state.adxlabel == "ADX Selected Level") {
        if (this.state.adxradioAbove == "Above") {
          adxheleprLable = "adx above selected level"
        } else {
          adxheleprLable = "adx below selected level"
        }
      } else if (this.state.adxlabel == "ADX Crossed Selected Level") {
        if (this.state.adxradioAbove == "Above") {
          adxheleprLable = "adx cross above selected level"
        } else {
          adxheleprLable = "adx cross below selected level"
        }
      } else if (this.state.adxlabel == "ADX Trending Up") {
        adxheleprLable = "adx trending up"
      } else if (this.state.adxlabel == "ADX Trending Down") {
        adxheleprLable = "adx trending down"
      }
      if (indicatprcheck) {
        this.setState({ saveFilterPopupVisible: false })
        this.saveFilterApiCall(this.state.filterName)
      }
      if(!multipleCheck){
      this.props.navigation.navigate('adx', { mode:this.context.mode,UrlIndicatorPath: selectArray[0].toLowerCase(), adxtimeframe: this.state.adxTimeFrame, adxlableoption: adxheleprLable, adxPeriod: this.state.adxPeriod, adxLevel: this.state.adxLength })
      return false}
    }

    if (selectArray[0] == "OBV") {
      if (this.state.obvlabel == '' || this.state.obvTimeFrame == '') {
        alert("Please fill all option")
        indicatprcheck && this.setState({ saveFilterPopupVisible: false })
        return false
      }
      let ObvHelper = ''
      if (this.state.obvlabel == "On Balance Volume Trending Up") {
        ObvHelper = "on balance volume trending up"
      } else if (this.state.obvlabel == "On Balance Volume Trending Down") {
        ObvHelper = "on balance volume trending down"
      } else if (this.state.obvlabel == "Divergence") {
        if (this.state.divergenceLable == "Bullish") {
          ObvHelper = "divergence bullish"
        } else {
          ObvHelper = "divergence bearish"
        }
      }
      if (indicatprcheck) {
        this.setState({ saveFilterPopupVisible: false })
        this.saveFilterApiCall(this.state.filterName)
      }
      if(!multipleCheck){
      this.props.navigation.navigate('obv', {mode:this.context.mode, UrlIndicatorPath: selectArray[0].toLowerCase(), ObvtimeFrame: this.state.obvTimeFrame, periodObv: this.state.obvPeriod, obvlableOption: ObvHelper })
      return false}
    }
    if (selectArray[0] == "CMF") {
      if (this.state.cmflabel == '') {
        alert("Please fill all option")
        indicatprcheck && this.setState({ saveFilterPopupVisible: false })
        return false
      }
      let CmfHelper = ''
      if (this.state.cmflabel == "CMF Above 0") {
        CmfHelper = "chaikin money flow above 0"
      } else if (this.state.cmflabel == "CMF Below 0") {
        CmfHelper = "chaikin money flow below 0"
      } else if (this.state.cmflabel == "CMF Crossed Above 0") {
        CmfHelper = "chaikin money flow crossed above 0"
      } else if (this.state.cmflabel == "CMF Crossed Below 0") {
        CmfHelper = "chaikin money flow crossed below 0"
      } else if (this.state.cmflabel == "CMF Trending Up") {
        CmfHelper = "chaikin money flow trending up"
      } else if (this.state.cmflabel == "CMF Trending Down") {
        CmfHelper = "chaikin money flow trending down"
      } else if (this.state.cmflabel == "Divergence") {
        if (this.state.divergenceLablecmf == "Bullish") {
          CmfHelper = "divergence bullish"
        } else {
          CmfHelper = "divergence bearish"
        }
      }
      if (indicatprcheck) {
        this.setState({ saveFilterPopupVisible: false })
        this.saveFilterApiCall(this.state.filterName)
      }
      if(!multipleCheck){
      this.props.navigation.navigate('cmf', { mode:this.context.mode,UrlIndicatorPath: selectArray[0].toLowerCase(), cmfTimeFrame: this.state.cmfTimeFrame, cmflabelOption: CmfHelper, cmfPeriod: this.state.cmfPeriod })
      return false}
    }
    if (selectArray[0] == "Bollinger Bands") {
      if (this.state.bollingerTimeFrame == '' || this.state.bollingerlabel == '') {
        alert("Please fill all option")
        indicatprcheck && this.setState({ saveFilterPopupVisible: false })
        return false
      }
      let bolingerHelper = ''
      if (this.state.bollingerlabel == "Coin Price Near Upper Band") {
        bolingerHelper = "coin price near upper band"
      } else if (this.state.bollingerlabel == "Coin Price Near Lower Band") {
        bolingerHelper = "coin price near lower band"
      } else if (this.state.bollingerlabel == "Coin Price Near Middle Band") {
        bolingerHelper = "coin price near middle band"
      } else if (this.state.bollingerlabel == "Coin Price Crossed Middle Band") {
        if (this.state.radioAbove == "Above") {
          bolingerHelper = "coin price crossed above middle band"
        } else {
          bolingerHelper = "coin price crossed below middle band"
        }

      } else if (this.state.bollingerlabel == "Coin Price Crossed Upper Band") {
        if (this.state.radioAbove == "Above") {
          bolingerHelper = "coin price crossed above upper band"
        } else {
          bolingerHelper = "coin price crossed below upper band"
        }
      } else if (this.state.bollingerlabel == "Coin Price Crossed Lower Band") {
        if (this.state.radioAbove == "Above") {
          bolingerHelper = "coin price crossed above lower band"
        } else {
          bolingerHelper = "coin price crossed below lower band"
        }
      } else if (this.state.bollingerlabel == "Bollinger Band Squeeze") {
        bolingerHelper = "bollinger band squeeze"
      }
      if (indicatprcheck) {
        this.setState({ saveFilterPopupVisible: false })
        this.saveFilterApiCall(this.state.filterName)
      }
      if(!multipleCheck){
      this.props.navigation.navigate('bollinger', { mode:this.context.mode,UrlIndicatorPath: selectArray[0].toLowerCase(), bollingerTimeFrame: this.state.bollingerTimeFrame, bollingerLableOption: bolingerHelper })
      return false}
    }
    if (selectArray[0] == "ATR") {
      if (this.state.atrTimeFrame == '' || this.state.atrPeriod == '' || this.state.atrlabel == '' || this.state.trending_period == '') {
        alert("Please fill all option")
        indicatprcheck && this.setState({ saveFilterPopupVisible: false })
        return false
      }
      if (indicatprcheck) {
        this.setState({ saveFilterPopupVisible: false })
        this.saveFilterApiCall(this.state.filterName)
      }
      if(!multipleCheck){
      this.props.navigation.navigate('atr', { mode:this.context.mode,UrlIndicatorPath: selectArray[0].toLowerCase(), atrTimeFrame: this.state.atrTimeFrame, atrPeriod: this.state.atrPeriod, atrLableOption: this.state.atrlabel.toLowerCase(), atrtrending: this.state.trending_period })
      return false}
    }
  }
  clearIndicatorvalue(filterCheck: boolean) {
    if (filterCheck) {
      this.setState({
        filterName: ""
      })
    }
    this.state.filterTypeMap["indicatorFilterName"]="";
    this.state.filterTypeMap["indicator_filter_type"]="";
    this.state.filterTypeMap["latest"] == "indicator" && this.setState({filterTypeMap:{ ...this.state.filterTypeMap, latest: ''}});
    this.setState({
      lengthInd: "",
      timeFameIndLabel: "",
      RsiDivergenceItem: "",
      periodInd: "",
      radioAbove: "",
      selectScreenname: "",
    })
    this.MFISelctedScreen("", "", "", "", "", "")
    this.StochasticPicker("", "", "", "", "", "")
    this.cciPicker("", "", "", "", "")
    this.mvAvgPicker("", "", "", "", "")
    this.macdPicker("", "", "", "", "")
    this.adxPicker("", "", "", "", "")
    this.obvPicker("", "", "", "")
    this.cmfPicker("", "", "", "", "")
    this.BollingPicker("", "", "",)
    this.atrPicker("", "", "", "", true)
  }
  caseFirst() {
    if (!this.fundamentalISOpenedCheck) {
      this.fundamentalISOpenedCheck = true;
      return;
    }
    this.clearFundamentalSavedValue(false)
    this.fundamentalISOpenedCheck = false;

  }
  caseSecond() {
    if (!this.volumeISOpenedCheck) {
      this.volumeISOpenedCheck = true;
      return;
    }
    this.clearVolumeFilterValue()
    this.volumeISOpenedCheck = false;

  }
  caseThird() {
    if (!this.priceISOpenedCheck) {
      this.priceISOpenedCheck = true;
      return;
    }
    this.clearPriceSavedValues(false)
    this.priceISOpenedCheck = false;

  }
  caseFourth() {
    if (!this.indicatorISOpened) {
      this.indicatorISOpened = true;
      return;
    }
    this.clearIndicatorvalue(false)
    this.indicatorISOpened = false;

  }
  onPressfilterItem = (item: any) => {

    let arr = this.state.filterItems;
    this.setState({
      filterItems: arr.map((el: any) => ({
        ...el,
        isSelected: el.id === item.id ? !el.isSelected : el.isSelected
      }))
    }, () => {
      if (item.id == 1) {
        this.caseFirst();
      }
      if (item.id == 2) {
        this.caseSecond();
      }
      if (item.id == 3) {
        this.caseThird();
      }
      if (item.id == 4) {
        this.caseFourth();
      }
    });

  };
  // Fundamenta start 
  onChange(index: any, selectedItem: any) {
    const { fundamentalArray } = this.state
    let item
    for (let i in fundamentalArray) {

      if (selectedItem === fundamentalArray[i]) {
        item = fundamentalArray.indexOf(selectedItem);

      }
    }
    if (item > -1) {
      fundamentalArray.splice(index, 1);

    }
    if (selectedItem === "Rank") {
      var temp = ['Top 10', 'Top 50', 'Custom']

      this.setState({ rankArray: temp, }, () => {
      })
    }
    else if (selectedItem === 'Top 10') {
      this.setState({ subRankLabel: selectedItem, addMoreBtn: true, rankLabel: 'Rank' }, () => {
        this.top10Rank(10)
      })
    }
    else if (selectedItem === 'Top 50') {
      this.setState({ subRankLabel: selectedItem, addMoreBtn: true, rankLabel: 'Rank' }, () => {
        this.top10Rank(50)
      })
    }
    else if (selectedItem === 'Custom') {
      this.setState({ subRankLabel: selectedItem, addMoreBtn: true, rankLabel: 'Rank' }, () => {
      })
    }
    else if (selectedItem === 'Industry') {
      var temp = ["indusrty1", "industry2"]
      this.setState({
        industryArray: temp,
        industryLabel: selectedItem
      })
    }
    else if (selectedItem === 'indusrty1' || selectedItem === 'industry2') {
      this.setState({
        industryLabel: 'Industry',
        addMoreBtn: true,
        subIndusrtyLable: selectedItem
      })
    }
    else if (selectedItem === 'Category') {
      this.setState({ categoryLabel: selectedItem, }, () => {
        this.getAllcatList()
      })
    }
    else if (selectedItem === 'Social Engagement') {
      var temp = ['Social Engagement1', "Social Engagement2"]
      this.setState({ socialEngLabel: selectedItem })
      this.socailEngMent()
    }
    else if (selectedItem === 'Social Engagement1' || selectedItem === 'Social Engagement2') {
      this.setState({ socialEngLabel: 'Social Engagement', addMoreBtn: true, })
    }
    else if (selectedItem === 'Market Capitalization') {
      this.setState({ market_capitalization: true, addMoreBtn: true })
    }
    else if (selectedItem === 'Transaction Count') {
      this.setState({ transaction_count: true, addMoreBtn: true })
    }
    else if (selectedItem === 'Transaction Value') {
      this.setState({ transacion_value: true, addMoreBtn: true })
    }
    else if (selectedItem === 'Fees Paid') {
      var temp = ["Top 10", "Top 50"]
      this.setState({ feesPaidArray: temp })
    }
    else if (selectedItem === 'Active Address') {
      this.setState({ active_address: true, addMoreBtn: true })
    }
    else if (selectedItem === 'Hash Rate') {
      this.setState({ hash_rate: true, addMoreBtn: true })
    }
    else if (selectedItem === 'Net Value Transaction Ratio') {
      this.setState({ net_value_transaction_ratio: true, addMoreBtn: true })
    }
    else if (selectedItem === 'Market Value to Realized Value') {
      this.setState({ market_value_to_realized_value: true, addMoreBtn: true })
    }
  }

  onClickClearDerivatives() {
    this.setState({
      rankLabel: '',
      subRankLabel: '',
      addMoreBtn: false,
      isPutCallRation: false,
      buildupArray: [],
      derivativesLabel: 'Select Derivative Option',
      topLable: '',
      defaultButtonText: '',
    })
  }
  onClickClearindustry() {
    this.setState({
      industryLabel: '',
      subIndusrtyLable: '',
      addMoreBtn: false


    })
  }

  onChangeCategories(selectedItem: any, index: any) {
    console.warn("index"+JSON.stringify(selectedItem))
    if(!this.state.filterTypeMap["fundamentalFilterName"].includes("Category")){
      this.state.filterTypeMap["fundamentalFilterName"].push("Category")
    }
   this.state.filterTypeMap["fundamental_filter_type"]="fundamental"
   this.state.filterTypeMap["latest"]="fundamentals"
    this.setState({ subCategoryLabel: selectedItem,  nameOfFilter: "Category",addMoreBtn: true, filterName:"",
       categoryLabel: 'Category',
        catId: selectedItem
      }, () => {
        console.warn("hogya")
    })
  }
  onChangeSocialEng(selectedItem: any, index: any) {

    this.setState({ subsocialEngLabel: selectedItem, addMoreBtn: true, socialEngLabel: 'Social Engagement' })

  }

  async callMarketCapApi() {
    if (this.state.market_from == '' && this.state.market_to == '') {
      alert("Please enter values")
    } else {
      const header = {
        // "Content-Type": configJSON.validationApiContentType,
        token: this.token()
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getMarketCapID = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.MarketCapApiEndPoint + `market_cap_min=${this.state.market_from}&market_cap_max=${this.state.market_to}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeGet
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  async getCategories() {
    const header = {
      // "Content-Type": configJSON.validationApiContentType,
      token: this.token()
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.categoriesApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.CategoriesApiEndPoint + this.state.catId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  token = async () => {
    const tokenData = await AsyncStorage.getItem("userToken");
    return tokenData
  }

  async top10Rank(rank: any) {
    const tokenData = await AsyncStorage.getItem("userToken");
    alert(tokenData)
    const header = {
      // "Content-Type": configJSON.validationApiContentType,
      token: this.token()
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.rank10apiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.RankApiEndPoint + rank
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }
  async getAllcatList() {
    const header = {
      // "Content-Type": configJSON.validationApiContentType,
      token: this.token()
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllcatDataID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.GetCatApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async socailEngMent() {


    const header = {
      // "Content-Type": configJSON.validationApiContentType,
      token: this.token()
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSocailEngMentID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.SocialEngamentApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  /// Delete saved filter api call
  async deleteSavedFilter(id: any,multiFilters?:any) {
    //alert("aaya")

    let endpoint
    if(multiFilters){
      endpoint = `${configJSON.saveMultipleFilterEndPoint}/${id}`
    }
    else{
      endpoint=  `${configJSON.saveFiltersApiEndPoint}/${id}`
    }
    const tokenData = await AsyncStorage.getItem("userToken");
    const header = {
      // "Content-Type": configJSON.validationApiContentType,
      token: tokenData
    };
   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeDelete
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  /// get saved filters api call 
  async getSavedFilters(mutiple?:any) {
    const tokenData = await AsyncStorage.getItem("userToken");
    const header = {
      // "Content-Type": configJSON.validationApiContentType,
      token: tokenData
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSavedFilterApiId = requestMessage.messageId;
    if(mutiple){
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.saveMultipleFilterEndPoint
      );
     
    }
    else{
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.saveFiltersApiEndPoint
      );
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  clearVolumeFilterValue = () => {
    this.state.priceFrom[0].isSelected = false
    this.state.priceFrom[1].isSelected = false
    this.setState({
      defaultVolumeButtonText: "",
      volumeUpPeriod: "",
      volumeDownPeriod: "",
volumeUpPeriodLength: "",
volumeDownPeriodLength: "",
avgVolumeMinValue: "",
avgVolumeMaxValue: "",
priceFrom :this.state.priceFrom
  })
  this.state.filterTypeMap["volumeFilterName"]=""
  this.state.filterTypeMap["volume_filter_type"]=""
  this.state.filterTypeMap["latest"]=""
 }
 clearFundamentalSavedValue = (exludeNameOfFilter:boolean)=>{
  if(exludeNameOfFilter){
    this.setState({
      newData: "",
      market_from: "",
      subCategoryLabel:"",
      market_to: "",
    });
    return;
  }
  this.setState({
    nameOfFilter: "",
    subCategoryLabel:"",
    newData: "",
    market_from: "",
    market_to: "",
  } ,() => {});
  this.state.filterTypeMap["fundamentalFilterName"].splice(0,this.state.filterTypeMap["fundamentalFilterName"].length)
  this.state.filterTypeMap["fundamental_filter_type"]=""
  this.state.filterTypeMap["latest"]=""
 }
 clearPriceSavedValues=(excludeNameOfFilter:boolean)=>{
  // alert(excludeNameOfFilter+"price")
  if(excludeNameOfFilter){
    this.state.filterTypeMap["priceFilterName"]=""
    this.setState({
      // nameOfFilter: "",
      subOption: "",
      frameValue: null,
      parcentage: "",
    })
    return;
  }
  HelperClass.setPriceMainOption("")
  this.setState({
    nameOfFilter: "",
    subOption: "",
    frameValue: null,
    parcentage: "",
  })
  this.state.filterTypeMap["priceFilterName"]=""
  this.state.filterTypeMap["price_action_filter_type"]=""
  this.state.filterTypeMap["latest"]=""
 }
  applySavedFilters(selectedObject: object, key: string,multipleFilter?:any) {
    const rank = (rank: any) => {
      this.setState({
        nameOfFilter: "Rank",
        newData: rank
      })
      if(!this.state.filterTypeMap["fundamentalFilterName"].includes("Rank")){
        this.state.filterTypeMap["fundamentalFilterName"].push("Rank")
      }
      this.state.filterTypeMap["fundamental_filter_type"]="fundamental"
      this.state.filterTypeMap["latest"]="fundamentals"
    }
    const marketMin = (from: any) => {
      this.setState({
        market_from: from,
        nameOfFilter: "MarketCapitalization"
      })
      if(!this.state.filterTypeMap["fundamentalFilterName"].includes("MarketCapitalization")){
        this.state.filterTypeMap["fundamentalFilterName"].push("MarketCapitalization")
      }
      this.state.filterTypeMap["fundamental_filter_type"]="fundamental"
      this.state.filterTypeMap["latest"]="fundamentals"
    }
    const marketMax = (to: any) => {
      this.setState({
        market_to: to,
        nameOfFilter: "MarketCapitalization"
      })
      if(!this.state.filterTypeMap["fundamentalFilterName"].includes("MarketCapitalization")){
        this.state.filterTypeMap["fundamentalFilterName"].push("MarketCapitalization")
      }
      this.state.filterTypeMap["fundamental_filter_type"]="fundamental"
      this.state.filterTypeMap["latest"]="fundamentals"
    }
    const volumeOption = (option?: string) => {
      this.state.filterTypeMap["volume_filter_type"] = "volume"
      this.setState({
        defaultVolumeButtonText: option,
      })
    }
    const volumePeriod = (option?: string, period?: string | null) => {
      if (option == "Volume Trending Up" || option == "Average Volume") {
        this.setState({
          volumeUpPeriod: period,
          volumeDownPeriod: "",
        })
        return;
      }
      this.setState({
        volumeDownPeriod: period,
        volumeUpPeriod: "",
      })
    }
    const volumeLength = (option?: string, length?: string | number) => {
      if (option == "Volume Trending Up") {
        this.setState({
          volumeUpPeriodLength: length,
        })
        return;
      }
      this.setState({
        volumeDownPeriodLength: length,
      })
    }
    const volumeOrder = (priceFrom?: any) => {
      let object = this.state.priceFrom
      object[0].isSelected = object[0].value === priceFrom
      object[1].isSelected = object[0].value !== priceFrom
      this.setState({
        priceFrom: object,
      })
    }
    const volumeAvgMin = (avgVolumeMinValue?: string | number) => {
      this.setState({
        avgVolumeMinValue: avgVolumeMinValue,
      })
    }
    const volumeAvgMax = (avgVolumeMaxValue?: string | number) => {
      this.setState({
        avgVolumeMaxValue: avgVolumeMaxValue,
      })
    }
    const rankCategory = (category:any) => {
    
      let ele = this.state.categoryArray.find((i:any)=>{
        if(i.name==category){
          return true;
        }
    })
    // this.onClickRank(ele.id, 'Category')
      this.onChangeCategories(ele,0)
      this.setState({
        nameOfFilter: "Category"
      })
    }
    const priceOption = (filterName: string) => {
      this.setState({
        nameOfFilter: filterName,
      })
      this.state.filterTypeMap["priceFilterName"] = filterName
      this.state.filterTypeMap["price_action_filter_type"]="price_action"
      this.state.filterTypeMap["latest"]="price_action"
    }
    const priceSubOption = (subfilter: object) => {
      this.setState({
        subOption: subfilter
      })
    }
    const priceFrame = (frameTime: string) => {
      this.setState({
        frameValue: frameTime,
      })
    }
    const pricePercentage = (percent: number) => {
      this.setState({
        parcentage: percent
      })
    }
    const priceMainOption = (mainOption: string) => {
      HelperClass.setPriceMainOption(mainOption)
    }
    const closeModal = () => {
      this.setState({ showSavedFilterModal: false });
    }
    const Rsi = (selectedObject: any) => {
      this.setState({
        lengthInd: selectedObject.indicator_level_centerline,
        timeFameIndLabel: selectedObject.indicator_time_frame,
        RsiDivergenceItem: selectedObject.indicator_divergence_crossover,
        periodInd: selectedObject.indicator_period,
        radioAbove: selectedObject.indicator_above_below,
        selectScreenname: selectedObject.indicator_screen_option,
      })
      // alert(JSON.stringify(selectedObject.indicator_screen_option))
      this.setState({
        apiBodyIndicatorObject: {
          "indicator_screen_option": selectedObject.indicator_screen_option,
          "indicator_period": selectedObject.indicator_period,
          "indicator_trending_period": "",
          "indicator_above_below": selectedObject.indicator_above_below,
          "indicator_trending_up_down": "",
          "indicator_level_centerline": selectedObject.indicator_level_centerline,
          "indicator_divergence_crossover": selectedObject.indicator_divergence_crossover,
          "indicator_moving_average_type": "",
          "indicator_time_frame": selectedObject.indicator_time_frame,
          "indicator_main_option": "RSI"
        }
      },)
    }
   const MFISelctedScreen = (selectedObject:any) => {
     this.setState({
       MfiDivergenceItem: selectedObject.indicator_divergence_crossover,
       mfiradioAbove: selectedObject.indicator_above_below,
       mfiTimeFrame: selectedObject.indicator_time_frame,
       mfiPeriod: selectedObject.indicator_period,
       mfilabel: selectedObject.indicator_screen_option,
       mfiLength: selectedObject.indicator_level_centerline
     })
     this.setState({
       apiBodyIndicatorObject: {
         "indicator_screen_option": selectedObject.indicator_screen_option,
         "indicator_period": selectedObject.indicator_period,
         "indicator_trending_period": "",
         "indicator_above_below": selectedObject.indicator_above_below,
         "indicator_trending_up_down": "",
         "indicator_level_centerline": selectedObject.indicator_level_centerline,
         "indicator_divergence_crossover": selectedObject.indicator_divergence_crossover,
         "indicator_moving_average_type": "",
         "indicator_time_frame": selectedObject.indicator_time_frame,
         "indicator_main_option": "MFI"
       }
     })
        
   }
   //StochasticPicker
   const StochasticPicker = (selectedObject:any) => {
    
     this.setState({
       StoDivergenceItem: selectedObject.indicator_divergence_crossover,
       stochasticradioAbove: selectedObject.indicator_above_below,
       stochasticTimeFrame: selectedObject.indicator_time_frame,
       stochasticPeriod:  selectedObject. indicator_period,
       stochasticlabel: selectedObject.indicator_screen_option,
       stochasticLength: selectedObject.indicator_level_centerline,
     })
     this.setState({
       apiBodyIndicatorObject: {
         "indicator_screen_option": selectedObject.indicator_screen_option,
         "indicator_period":  selectedObject. indicator_period,
         "indicator_trending_period": "",
         "indicator_above_below": selectedObject.indicator_above_below,
         "indicator_trending_up_down": "",
         "indicator_level_centerline":selectedObject.indicator_level_centerline,
         "indicator_divergence_crossover": selectedObject.indicator_divergence_crossover,
         "indicator_moving_average_type": "",
         "indicator_time_frame": selectedObject.indicator_time_frame,
         "indicator_main_option": "STOCHASTIC"
       }
     })
   }
   //cciPicker
   const cciPicker = (selectedObject: any) => {
    
     this.setState({
       cciDivergenceItem: selectedObject.indicator_divergence_crossover,
       cciradioAbove: selectedObject.indicator_above_below,
       cciTimeFrame: selectedObject.indicator_time_frame,
       ccilabel: selectedObject.indicator_screen_option,
       cciPeriod: selectedObject.indicator_period
     })
     this.setState({
       apiBodyIndicatorObject: {
         "indicator_screen_option": selectedObject.indicator_screen_option,
         "indicator_period": selectedObject.indicator_period,
         "indicator_trending_period": "",
         "indicator_above_below": selectedObject.indicator_above_below,
         "indicator_trending_up_down": "",
         "indicator_level_centerline": "",
         "indicator_divergence_crossover": selectedObject.indicator_divergence_crossover,
         "indicator_moving_average_type": "",
         "indicator_time_frame": selectedObject.indicator_time_frame,
         "indicator_main_option": "CCI"
       }
     })
   }
   //moving AVG
   const mvAvgPicker = (selectedObject: any) => {
    let crossoverValue = selectedObject.indicator_divergence_crossover == "Bearish"? 
    {"label":"Bearish","value":"Bearish Crossovers"}: {"label":"Bullish","value":"Bullish Crossovers"}
    // alert(JSON.stringify(selectedObject.indicator_screen_option))
     this.setState({
       mvAvgTimeFrame: selectedObject.indicator_time_frame,
       mvAvgPeriod: selectedObject.indicator_period,
       movingAvgLabel: selectedObject.indicator_moving_average_type,
       mvAvglabel: selectedObject.indicator_screen_option,
       crossOverValue : crossoverValue,
     },()=>{
      this.setState({
       apiBodyIndicatorObject: {
         "indicator_screen_option": selectedObject.indicator_screen_option,
         "indicator_period": selectedObject.indicator_period,
         "indicator_trending_period": "",
         "indicator_above_below": "",
         "indicator_trending_up_down": "",
         "indicator_level_centerline": "",
         "indicator_divergence_crossover":  crossoverValue,
         "indicator_moving_average_type": selectedObject.indicator_moving_average_type,
         "indicator_time_frame": selectedObject.indicator_time_frame,
         "indicator_main_option": "Moving Average"
       }
     })
     })
     
   }
 
   const macdPicker = (selectedObject: any) => {
    
    this.setState({
       macdDivergenceitem: selectedObject.indicator_divergence_crossover,
       macdradioAbove: selectedObject.indicator_above_below,
       macdTimeFrame: selectedObject.indicator_time_frame,
       macdlabel: selectedObject.indicator_screen_option,
       macdPeriod: selectedObject.indicator_period,
     })
     
     this.setState({
       apiBodyIndicatorObject: {
         "indicator_screen_option": selectedObject.indicator_screen_option,
         "indicator_period": selectedObject.indicator_period,
         "indicator_trending_period": "",
         "indicator_above_below": selectedObject.indicator_above_below,
         "indicator_trending_up_down": "",
         "indicator_level_centerline": "",
         "indicator_divergence_crossover": selectedObject.indicator_divergence_crossover,
         "indicator_moving_average_type": "",
         "indicator_time_frame": selectedObject.indicator_time_frame,
         "indicator_main_option": "MACD"
       }
     })
   }
   const adxPicker = (selectedObject:any) => {
    
     this.setState({
       adxTimeFrame: selectedObject.indicator_time_frame,
       adxradioAbove: selectedObject.indicator_above_below,
       adxlabel: selectedObject.indicator_screen_option,
       adxPeriod: selectedObject.indicator_period,
       adxLength: selectedObject.indicator_level_centerline
     })
     this.setState({
       apiBodyIndicatorObject: {
         "indicator_screen_option": selectedObject.indicator_screen_option,
         "indicator_period": selectedObject.indicator_period,
         "indicator_trending_period": "",
         "indicator_above_below": selectedObject.indicator_above_below,
         "indicator_trending_up_down": "",
         "indicator_level_centerline": selectedObject.indicator_level_centerline,
         "indicator_divergence_crossover": "",
         "indicator_moving_average_type": "",
         "indicator_time_frame": selectedObject.indicator_time_frame,
         "indicator_main_option": "ADX"
       }
     })
   }
   const obvPicker = (selectedObject:any) => {
    
     this.setState({
       divergenceLable: selectedObject.indicator_divergence_crossover,
       obvTimeFrame: selectedObject.indicator_time_frame,
       obvlabel: selectedObject.indicator_screen_option,
       obvPeriod: selectedObject.indicator_period
     })
     this.setState({
       apiBodyIndicatorObject: {
         "indicator_screen_option": selectedObject.indicator_screen_option,
         "indicator_period":  selectedObject.indicator_period,
         "indicator_trending_period": "",
         "indicator_above_below": "",
         "indicator_trending_up_down": "",
         "indicator_level_centerline": "",
         "indicator_divergence_crossover": selectedObject.indicator_divergence_crossover,
         "indicator_moving_average_type": "",
         "indicator_time_frame": selectedObject.indicator_time_frame,
         "indicator_main_option": "OBV"
       }
     })
   }
 
   const cmfPicker = (selectedObject:any) => {
     // selectedObject.indicator_time_frame,selectedObject.indicator_above_below,
     //selectedObject.indicator_divergence_crossover,selectedObject.indicator_screen_option,selectedObject.indicator_period
     this.setState({
       cmfTimeFrame: selectedObject.indicator_time_frame,
       cmfradio: selectedObject.indicator_above_below,
       divergenceLablecmf: selectedObject.indicator_divergence_crossover,
       cmflabel: selectedObject.indicator_screen_option,
       cmfPeriod: selectedObject.indicator_period
     })
    
     this.setState({
       apiBodyIndicatorObject: {
         "indicator_screen_option": selectedObject.indicator_screen_option,
         "indicator_period": selectedObject.indicator_period,
         "indicator_trending_period": "",
         "indicator_above_below": selectedObject.indicator_above_below,
         "indicator_trending_up_down": "",
         "indicator_level_centerline": "",
         "indicator_divergence_crossover": selectedObject.indicator_divergence_crossover,
         "indicator_moving_average_type": "",
         "indicator_time_frame": selectedObject.indicator_time_frame,
         "indicator_main_option": "CMF"
       }
     })
   }
   const BollingPicker = (selectedObject:any) => {
     this.setState({
       bollingerTimeFrame: selectedObject.indicator_time_frame,
       radioAbove: selectedObject.indicator_above_below,
       bollingerlabel: selectedObject.indicator_screen_option,
     })
     
     this.setState({
       apiBodyIndicatorObject: {
         "indicator_screen_option": selectedObject.indicator_screen_option,
         "indicator_period": "",
         "indicator_trending_period": "",
         "indicator_above_below": selectedObject.indicator_above_below,
         "indicator_trending_up_down": "",
         "indicator_level_centerline": "",
         "indicator_divergence_crossover": "",
         "indicator_moving_average_type": "",
         "indicator_time_frame": selectedObject.indicator_time_frame,
         "indicator_main_option": "Bollinger"
       }
     })
   }
   const  atrPicker = (selectedObject:any) => {
     this.setState({
       atrTimeFrame:selectedObject.indicator_time_frame,
       atrPeriod:selectedObject.indicator_period,
       atrlabel:selectedObject.indicator_screen_option,
       trending_period:selectedObject.indicator_trending_period
     })
    
     this.setState({
       apiBodyIndicatorObject: {
         "indicator_screen_option": selectedObject.indicator_screen_option,
         "indicator_period":selectedObject.indicator_period ,
         "indicator_trending_period": selectedObject.indicator_trending_period,
         "indicator_above_below": "",
         "indicator_trending_up_down": "",
         "indicator_level_centerline": "",
         "indicator_divergence_crossover": "",
         "indicator_moving_average_type": "",
         "indicator_time_frame": selectedObject.indicator_time_frame,
         "indicator_main_option": "ATR"
       }
     })
   }
    const setFundamentalCheck = ()=>{
      if(multipleFilter){
        this.setState({ fundamentalCheck:true,})
      }
      else{
        this.setState({ fundamentalCheck:true,
          volumeCheck:false,
          priceCheck:false,
          indicator:false,})
      }
     
    }
    const setPriceCheck = ()=>{
      if(multipleFilter){
        this.setState({ priceCheck:true,})
      }
      else{
        this.setState({ fundamentalCheck:false,
          volumeCheck:false,
          priceCheck:true,
          indicator:false,})
      }
     
    }
    const setVolumeCheck = ()=>{
      if(multipleFilter){
        this.setState({ volumeCheck:true,})
      }
      else{
        this.setState({ fundamentalCheck:false,
          volumeCheck:true,
          priceCheck:false,
          indicator:false,})
      }
      
    }
    const setIndicatorCheck = ()=>{
      if(multipleFilter){
        this.setState({ indicator:true,})
      }
      else{
        this.setState({ 
          fundamentalCheck:false,
          volumeCheck:false,
          priceCheck:false,
          indicator:true,
        })
      }
    
    }
    const openFundamental = () => {
      setTimeout(() => {
        let arr = this.state.filterItems;
        this.setState({
          filterItems: arr.map((el: any) => ({
            ...el,
            isSelected: el.id === 1 ? !el.isSelected : el.isSelected
          }))
        })
        this.fundamentalISOpenedCheck = true;
      }
        , 0)
    }
    const openIndicator = () => {
      setTimeout(() => {
        let arr = this.state.filterItems;
        this.setState({
          filterItems: arr.map((el: any) => ({
            ...el,
            isSelected: el.id === 4 ? !el.isSelected : el.isSelected
          }))
        })
        this.indicatorISOpened = true;
      }
        , 0)
    }
    const openVolume = () => {
      setTimeout(() => {
        let arr = this.state.filterItems;
        this.setState({
          filterItems: arr.map((el: any) => ({
            ...el,
            isSelected: el.id === 2 ? !el.isSelected : el.isSelected
          }))
        })
        this.volumeISOpenedCheck = true;
      }
        , 0)
    }
    const openPrice = () => {
      setTimeout(() => {
        let arr = this.state.filterItems;
        this.setState({
          filterItems: arr.map((el: any) => ({
            ...el,
            isSelected: el.id === 3 ? !el.isSelected : el.isSelected
          }))
        })
        this.priceISOpenedCheck = true;
      }
        , 0)
    }
    const objectOfKeys: any = {
      fundamentals: function () {
        const objectForFundamentalObjectKeys: any = {
          "rank": rank,
          "rank_category": rankCategory,
          "market_cap_min": marketMin,
          "market_cap_max": marketMax,
        }
        // alert(JSON.stringify(selectedObject)+"selectedObject")
        const length = Object.keys(selectedObject)?.length;
        let currentLoopNo: number = 0;
        setFundamentalCheck()
        for (const key in selectedObject) {
          //@ts-ignore
          if (objectForFundamentalObjectKeys.hasOwnProperty(key) && selectedObject[key]) {
            //@ts-ignore
            objectForFundamentalObjectKeys[key](selectedObject[key]);
          }
          if (currentLoopNo == length - 1) {
            closeModal();
            openFundamental();
          }
          currentLoopNo = currentLoopNo + 1;
        }
      },
      volume: function () {
        const objectForVolumeObjectKeys: any = {
          "volume_option": volumeOption,
          "volume_period": volumePeriod,
          "volume_length": volumeLength,
          "volume_order": volumeOrder,
          "volume_from": volumeAvgMin,
          "volume_to": volumeAvgMax
        }
        const length = Object.keys(selectedObject).length;
        let currentLoopNo: number = 0;
        setVolumeCheck()
        for (const key in selectedObject) {
          //@ts-ignore
          if (objectForVolumeObjectKeys.hasOwnProperty(key) && selectedObject[key]) {
            (key.includes("length") || key.includes("period")) ?
            //@ts-ignore
              objectForVolumeObjectKeys[key](selectedObject["volume_option"], selectedObject[key]) :
              //@ts-ignore
              objectForVolumeObjectKeys[key](selectedObject[key]);
          }
          if (currentLoopNo == length - 1) {
            closeModal();
            openVolume();
          }
          currentLoopNo = currentLoopNo + 1;
        }
      },
      price_action: function () {
        const objectForPriceObjectKeys: any = {
          "price_action_main_option": priceMainOption
          , "price_action_option": priceOption
          , "price_action_suboption": priceSubOption,
          "price_action_timeframe": priceFrame
          , "gainloss_percentage": pricePercentage
        }
        const length = Object.keys(selectedObject).length;
        // alert(JSON.stringify(selectedObject))
        let currentLoopNo: number = 0;
        setPriceCheck();
        for (const key in selectedObject) {
          //@ts-ignore
          if (objectForPriceObjectKeys.hasOwnProperty(key) && selectedObject[key]) {
            //@ts-ignore
            objectForPriceObjectKeys[key](selectedObject[key]);
          }
          if (currentLoopNo == length - 1) {
            closeModal();
            openPrice();
          }
          currentLoopNo = currentLoopNo + 1;
        }
      },
      indicator: function () {
        const objectForIndicator: any = {
          "RSI": Rsi,
          "MFI": MFISelctedScreen,
          "STOCHASTIC": StochasticPicker,
          "CCI": cciPicker,
          "Moving Average": mvAvgPicker,
          "MACD": macdPicker,
          "ADX": adxPicker,
          "OBV": obvPicker,
          "CMF": cmfPicker,
          "Bollinger": BollingPicker,
          "ATR": atrPicker,
        }
        setIndicatorCheck();
        closeModal();
        openIndicator();
        //@ts-ignore
        objectForIndicator[selectedObject.indicator_main_option](selectedObject)
      }
    }
    if(key){
      key == "volume" && this.setState({filterTypeMap:{...this.state.filterTypeMap,volumeFilterName:"volume"}});
      key == "indicator" && this.setState({filterTypeMap:{...this.state.filterTypeMap,indicatorFilterName:"indicator"}});
      objectOfKeys[key]();
    }
  }
  /// save filter api call 
  fundamentalSaveButton = () => {
    if
      (
        this.state.filterTypeMap["fundamentalFilterName"].includes("Rank") ||
        this.state.filterTypeMap["fundamentalFilterName"].includes("Category") ||
        this.state.filterTypeMap["fundamentalFilterName"].includes("MarketCapitalization")
      ){
        this.state.filterTypeMap["latest"]="fundamental"
        this.onClickSave();
      
        return;
      }
      ToastHandler.show("Please Select Filter",true);
  }
   fundamentalSaveandApplyButton = ()=>{
    // alert( this.state.filterTypeMap["fundamentalFilterName"])
    if
    (
      this.state.filterTypeMap["fundamentalFilterName"].includes("Rank") ||
      this.state.filterTypeMap["fundamentalFilterName"].includes("Category")&&this.state.subCategoryLabel ||
      this.state.filterTypeMap["fundamentalFilterName"].includes("MarketCapitalization")
    ){
      this.state.filterTypeMap["latest"]="fundamental"
      this.setState({filterName:""})
      this.setState({saveFilterPopupVisible:true})
      return;
    }
  }
   volumeSaveButton = ()=>{
    if (this.state.defaultVolumeButtonText !== "Select Volume Option" &&this.state.defaultVolumeButtonText !== "")
    {
      this.state.filterTypeMap["latest"]="volume"
      this.onClickSave();
      
      return;
    }
  }
   volumeSaveandApplyButton = ()=>{
    if (this.state.defaultVolumeButtonText !== "Select Volume Option" && this.state.defaultVolumeButtonText !== "")
    {
      this.state.filterTypeMap["latest"]="volume"
      this.setState({filterName:""})
      this.setState({saveFilterPopupVisible:true})
      return;
    }
  }
   priceSaveButton = ()=>{
    if (this.state.filterTypeMap["priceFilterName"]!=="")
    {
      this.state.filterTypeMap["latest"]="price_action"
      this.onClickSave()
      return;
    }
  }
   priceSaveandApplyButton = ()=>{
    if (this.state.filterTypeMap["priceFilterName"]!=="")
    {
      this.state.filterTypeMap["latest"]="price_action"
      this.setState({filterName:""})
      this.setState({saveFilterPopupVisible:true})
      return;
    }

  }
   indicatorSaveButton = ()=>{
    if ( this.state.apiBodyIndicatorObject.indicator_main_option!="")
    {
      this.state.filterTypeMap["latest"]="indicator"
      this.onClickSave()
      return;
    }
  }
   indicatorSaveandApplyButton = ()=>{
    if ( this.state.apiBodyIndicatorObject.indicator_main_option!="")
    {
      this.state.filterTypeMap["latest"]="indicator"
      this.setState({filterName:""})
      this.setState({saveFilterPopupVisible:true})
      return;
    }
  }
  async saveMultipleFilterApiCall(filtername?: string) {
    const tokenData = await AsyncStorage.getItem("userToken");
  
    const body = {
      "multi_saved_filter": {
        "filter_name": this.state.filterName,
        "fundamental_filter_type": this.state.filterTypeMap["fundamental_filter_type"],
        "volume_filter_type":this.state.filterTypeMap["volume_filter_type"],
        "price_action_filter_type": this.state.filterTypeMap["price_action_filter_type"],
        "indicator_filter_type": this.state.filterTypeMap["indicator_filter_type"],
        "rank":this.renderConditionalItem(this.state.newData,this.state.newData,""),
        "rank_category": this.renderConditionalItem(this.state.subCategoryLabel?.name,this.state.subCategoryLabel?.name,"") ,
        "market_cap_min": this.renderConditionalItem(this.state.market_from,this.state.market_from,"") ,
        "market_cap_max": this.renderConditionalItem(this.state.market_to,this.state.market_to,"") ,
        "volume_option": this.state.defaultVolumeButtonText!="Select Volume Option"?this.state.defaultVolumeButtonText:"" ,
        "volume_period": this.renderConditionalItem(this.state.volumeUpPeriod , this.state.volumeUpPeriod ) || this.renderConditionalItem(this.state.volumeDownPeriod , this.state.volumeDownPeriod) ||"",
        "volume_length": this.renderConditionalItem(this.state.volumeUpPeriodLength , this.state.volumeUpPeriodLength) || this.renderConditionalItem(this.state.volumeDownPeriodLength , this.state.volumeDownPeriodLength) || "",
        "volume_order": this.renderConditionalItem(this.state.priceFrom[0].isSelected , this.state.priceFrom[0].value) || this.renderConditionalItem(this.state.priceFrom[1].isSelected , this.state.priceFrom[1].value ) || "",
        "volume_from": this.renderConditionalItem(this.state.filterTypeMap["volume_filter_type"],this.state.avgVolumeMinValue,""),
        "volume_to": this.renderConditionalItem(this.state.filterTypeMap["volume_filter_type"],this.state.avgVolumeMaxValue,""),
        "price_action_main_option": HelperClass.getPriceMainOption()?HelperClass.getPriceMainOption():"",
        "price_action_option": this.renderConditionalItem(this.state.filterTypeMap["price_action_filter_type"] && this.state.nameOfFilter , this.state.nameOfFilter , ""),
        "price_action_suboption": this.renderConditionalItem(this.state.subOption.name ,this.state.subOption?.name , this.state.subOption),
        "price_action_timeframe": this.renderConditionalItem(this.state.frameValue && typeof this.state.frameValue == "object" , this.state.frameValue?.name, this.state.frameValue || ""),
        "gainloss_percentage": this.renderConditionalItem(this.state.parcentage , this.state.parcentage , ""),
        "indicator_screen_option": this.state.apiBodyIndicatorObject.indicator_screen_option,
        "indicator_period": this.state.apiBodyIndicatorObject.indicator_period?this.state.apiBodyIndicatorObject.indicator_period:"",
        "indicator_trending_period": this.state.apiBodyIndicatorObject.indicator_trending_period?this.state.apiBodyIndicatorObject.indicator_trending_period:"",
        "indicator_above_below": this.state.apiBodyIndicatorObject.indicator_above_below,
        "indicator_trending_up_down": this.state.apiBodyIndicatorObject.indicator_trending_up_down,
        "indicator_level_centerline":this.state.apiBodyIndicatorObject.indicator_level_centerline?this.state.apiBodyIndicatorObject.indicator_level_centerline:"",
        "indicator_divergence_crossover":typeof this.state.apiBodyIndicatorObject.indicator_divergence_crossover == "object" ?this.state.apiBodyIndicatorObject.indicator_divergence_crossover.label:this.state.apiBodyIndicatorObject.indicator_divergence_crossover,
        "indicator_moving_average_type": this.state.apiBodyIndicatorObject.indicator_moving_average_type,
        "indicator_time_frame":this.state.apiBodyIndicatorObject.indicator_time_frame,
        "indicator_main_option":this.state.apiBodyIndicatorObject.indicator_main_option,
      }
    }
    
    
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: tokenData
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.saveMultipleFilterApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.saveMultipleFilterEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(
        body
      )
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  async saveFilterApiCall(filtername: string) {
    const tokenData = await AsyncStorage.getItem("userToken");
    const filterType = this.state.filterTypeMap["latest"]
    let rankvalue = this.state.subCategoryLabel?.id == this.state.newData ? "":this.state.newData
    const body = {
      "saved_filter": {
        "filter_name": this.state.filterName,
        "filter_type": filterType,
        "rank": this.renderConditionalItem(filterType=="fundamental" && this.state.newData , rankvalue, ""),
        "rank_category": filterType=="fundamental"?this.state.subCategoryLabel?.name: "",
        "market_cap_min": filterType=="fundamental"?this.state.market_from: "",
        "market_cap_max": filterType=="fundamental"?this.state.market_to: "",
        "volume_option": filterType=="volume"?this.state.defaultVolumeButtonText: "",
        "volume_period": this.renderConditionalItem(filterType=="volume" && this.state.volumeUpPeriod , this.state.volumeUpPeriod , this.renderConditionalItem(this.state.volumeDownPeriod,this.state.volumeDownPeriod,"")),
        "volume_length": this.renderConditionalItem(filterType=="volume" && this.state.volumeUpPeriodLength , this.state.volumeUpPeriodLength , this.renderConditionalItem(this.state.volumeDownPeriodLength, this.state.volumeDownPeriodLength, '')),
        "volume_order": this.renderConditionalItem(filterType=="volume" && this.state.priceFrom[0].isSelected , this.state.priceFrom[0].value , this.renderConditionalItem(filterType=="volume" && this.state.priceFrom[1].isSelected , this.state.priceFrom[1].value,"")),
        "volume_from": filterType=="volume"?this.state.avgVolumeMinValue: "",
        "volume_to": filterType=="volume"?this.state.avgVolumeMaxValue: "",
        "price_action_main_option": filterType=="price_action"?HelperClass.getPriceMainOption(): "",
        "price_action_option": this.renderConditionalItem(filterType=="price_action" && this.state.nameOfFilter , this.state.nameOfFilter , ""),
        "price_action_suboption": this.renderConditionalItem(filterType=="price_action", this.renderConditionalItem(this.state.subOption.name , this.state.subOption.name , this.state.subOption),""),
        "price_action_timeframe": this.renderConditionalItem(filterType==="price_action", this.renderConditionalItem(this.state.frameValue , this.renderConditionalItem(typeof this.state.frameValue === "object" , this.state?.frameValue?.name , this.state.frameValue) , ""), ""),
        "gainloss_percentage": this.renderConditionalItem(filterType=="price_action" && this.state.parcentage , this.state.parcentage ,""),
        "indicator_screen_option": filterType=="indicator"?this.state.apiBodyIndicatorObject.indicator_screen_option: "",
        "indicator_period": filterType=="indicator"?this.state.apiBodyIndicatorObject.indicator_period: "",
        "indicator_trending_period":filterType=="indicator"? this.state.apiBodyIndicatorObject.indicator_trending_period: "",
        "indicator_above_below": filterType=="indicator"?this.state.apiBodyIndicatorObject.indicator_above_below: "",
        "indicator_trending_up_down": filterType=="indicator"?this.state.apiBodyIndicatorObject.indicator_trending_up_down: "",
        "indicator_level_centerline":filterType=="indicator"? this.state.apiBodyIndicatorObject.indicator_level_centerline: "",
        "indicator_divergence_crossover": this.renderConditionalItem(filterType=="indicator",this.renderConditionalItem(typeof this.state.apiBodyIndicatorObject.indicator_divergence_crossover == "object" ,this.state.apiBodyIndicatorObject.indicator_divergence_crossover.label , this.state.apiBodyIndicatorObject.indicator_divergence_crossover) , ""),
        "indicator_moving_average_type":filterType=="indicator"? this.state.apiBodyIndicatorObject.indicator_moving_average_type: "",
        "indicator_time_frame":filterType=="indicator"? this.state.apiBodyIndicatorObject.indicator_time_frame: "",
        "indicator_main_option":filterType=="indicator"?this.state.apiBodyIndicatorObject.indicator_main_option:"",
      }
    }

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: tokenData
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.saveFilterApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.saveFiltersApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(
        body
      )
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  async socailEngMentBySymbol() {
    const header = {
      // "Content-Type": configJSON.validationApiContentType,
      token: this.token()
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.socialEngBySymbolId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.SocialEngBySymbolApiEndPoint + this.state.subsocialEngLabel
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Fundamental End 

  // Volumes Filter start
  onChangeVolumes(index: any, selectedItem: any) {
    if (selectedItem === "Average Volume") {
      this.setState({
        avgVolumeSelected: true,
        volumeTrendUpSelected: false,
        volumeTrendDownSelected: false,
        avgVolumeLabel: selectedItem
      })
    } else if (selectedItem === "Volume Trending Up") {
      this.setState({
        avgVolumeSelected: false,
        volumeTrendUpSelected: true,
        volumeTrendDownSelected: false,
        avgVolumeLabel: selectedItem
      })
    } else if (selectedItem === "Volume Trending Down") {
      this.setState({
        avgVolumeSelected: false,
        volumeTrendUpSelected: false,
        volumeTrendDownSelected: true,
        avgVolumeLabel: selectedItem
      })
    }
  }
  onPriceRangePress = (priceFrom: any, item: any) => {
    let arr = priceFrom;
    this.setState({
      priceFrom: arr.map((el: any) => ({
        ...el,
        isSelected: el.id === item.id ? !el.isSelected : false
      }))
    });
  };

  onChangeTrendingUP(index: any, selectedItem: any) {
    if (selectedItem === "Custom") {
      this.setState({
        volumeUpPeriodCustom: true
      })
    } else {
      this.setState({
        volumeUpPeriodCustom: false
      })
    }
  }

  onChangeTrendingDown(index: any, selectedItem: any) {
    if (selectedItem === "Custom") {
      this.setState({
        volumeDownPeriodCustom: true
      })
    } else {
      this.setState({
        volumeDownPeriodCustom: false
      })
    }
  }
  onClocseVolume() {
    this.setState({
      avgVolumeLabel: '',
      avgVolumeSelected: false,
      volumeTrendUpSelected: false,
      volumeTrendDownSelected: false
    })
  }
  // Volumes Filter end

  /** Derivative Picker Functinality */
  //  onClickClearDerivatives() {
  //   this.setState({
  //     isPutCallRation: false,
  //     buildupArray: [],
  //     derivativesLabel: 'Select Derivative Option',
  //     topLable: ''
  //   })
  // }
  saveMultipleFilters = ()=>{
     this.saveMultipleFilterApiCall()
  }
 
  volumeCheck = ()=>{
    let check:any = true ;
    if (this.state.defaultVolumeButtonText !== "Select Volume Option" &&this.state.defaultVolumeButtonText !== ""){
       check =  this.defaultVolumeButtonFxn1(true)
    }
    return check;
  }
 
  indicatorCheck=()=>{
    let check:any = true ;
    if ( this.state.apiBodyIndicatorObject.indicator_main_option!=""){
      check =  this.IndIcatorFuntion(false,true)
    }
    return check;
  }
  onChangeDerivatives(index: any, selectedItem: any) {
    if (selectedItem === 'Long Buildup') {
      var temp = ["Top 10", "Top 50", "Custom"]
      this.setState({
        isPutCallRation: false,
        buildupArray: temp,
        derivativesLabel: 'Long Buildup'
      })

    }
    else if (selectedItem === 'Short Buildup') {
      var temp = ["Top 10", "Top 50", "Custom"]
      this.setState({
        isPutCallRation: false,
        buildupArray: temp,
        derivativesLabel: 'Short Buildup'
      })

    }
    else if (selectedItem === 'Short Covering') {
      var temp = ["Top 10", "Top 50", "Custom"]
      this.setState({
        isPutCallRation: false,
        buildupArray: temp,
        derivativesLabel: 'Short Covering'
      })

    }
    else if (selectedItem === 'Profit Booking') {
      var temp = ["Top 10", "Top 50", "Custom"]
      this.setState({
        isPutCallRation: false,
        buildupArray: temp,
        derivativesLabel: 'Profit Booking'
      })

    }
    else if (selectedItem === 'Put Call Ratio') {
      this.setState({
        isPutCallRation: true,
        derivativesLabel: '',
        buildupArray: []
      })
    }
    else if (selectedItem === 'Top 10') {
      this.setState({
        topLable: selectedItem
      }, () => {


      })
    }
    else if (selectedItem === 'Top 50') {
      this.setState({
        topLable: selectedItem
      })
    }
    else if (selectedItem === 'Custom') {
      this.setState({
        topLable: selectedItem
      })
    }

  }
  //Price Action start//

  onChangePriceAction(item: any, selectitem: any) {

    if (selectitem === "Gain/Loss") {
      var temp = ["Price Up%", "Price Down%", "Price Trending UP", "Price Trending Down"]
      this.setState({
        optionsArray: temp, standardSupportArray: [], timeFrameArray: [],

        channelArray: [],
        timeOptionArray: [],
        reversalOptionArray: [],

      })
    }
    else if (selectitem === 'Price Up%' || selectitem === 'Price Down%') {
      let temp = [
        //  "5 min", "15 min", "30 min", "1 hr", "1 day"
        "1 hr", "1 day", "1 week", "1 month",
      ]
      this.setState({ timeFrameArray: temp })
    }
    else if (selectitem === 'Price Trending UP' || selectitem === 'Price Trending Down') {
      let temp = [
        // "5 min", "15 min", "30 min", "1 hr", "1 day", "1 week", "Custom"
        "1 hr", "1 day", "1 week", "1 month",
      ]
      this.setState({ timeFrameArray: temp, isPriceTrandingUP: true })
    }
    else if (selectitem === 'High/low') {
      var temp = ["Near All Time High", "Near All Time Low", "At All Time High", "At All Time Low",]

      this.setState({
        optionsArray: temp,
        timeFrameArray: [],
        isParcentage: false,
        standardSupportArray: [],
        channelArray: [],
        timeOptionArray: [],
        reversalOptionArray: [],
        pickerValue: ''

      })

    }
    else if (selectitem === 'Breakouts/Breakdowns') {
      var temp = ["All Time High Breakout", "All Time Low Breakdown", "52 Week High Breakout", "52 Week Low Breakdown", "Past Week High Breakout", "Past Week Low Breakdown"]
      this.setState({
        optionsArray: temp,
        timeFrameArray: [],
        standardSupportArray: [],
        isParcentage: false,
        channelArray: [],
        timeOptionArray: [],
        reversalOptionArray: [],
        pickerValue: ''

      })
    }
    else if (selectitem === "Support/Resistance") {
      var temp = ["Standard Support", "Standard Resistance", "Fibonacci Support", "Fibonnaci Resistance"]
      this.setState({
        optionsArray: temp,
        timeFrameArray: [],
        isParcentage: false,
        standardSupportArray: [],
        channelArray: [],
        timeOptionArray: [],
        reversalOptionArray: [],
        pickerValue: ''
      })
    }
    else if (selectitem === 'Standard Support') {
      var temp = ["Near S1", "Near S2", "Near S3", "Brakdown S1", "Breakdown S2", "Breakdown S3"]
      this.setState({
        standardSupportArray: temp,
        standardSupportLabel: selectitem

      }, () => {

      })
    }
    else if (selectitem === 'Standard Resistance') {
      var temp = ["Near R1", "Near R2", "Near R3", "Brakdown R1", "Breakdown R2", "Breakdown R3"]
      this.setState({
        standardSupportArray: temp
      })
    }
    else if (selectitem === 'Standard Patterns') {
      var temp = ["Flat Channel", "Ascending Channel", "Descending Channel"]
      this.setState({
        optionsArray: temp,
        standardSupportArray: [],
        channelArray: [],
        timeOptionArray: [],
        reversalOptionArray: [],
        timeFrameArray: [],
        pickerValue: ''
      })
    }
    else if (selectitem === 'Flat Channel' || selectitem === 'Ascending Channel' || selectitem === 'Descending Channel') {
      var temp = ["Inside Channel", "Channel Breakout", "Channel Breakdown"];
      this.setState({
        channelArray: temp
      })

    }
    else if (selectitem === 'Inside Channel') {
      let temp = [
        // "5 min", "15 min", "30 min", "1 hr", "1 day", "1 week", "Custom"
        "1 hr", "1 day", "1 week", "1 month",
      ];
      this.setState({
        timeOptionArray: temp
      })
    }
    else if (selectitem === 'Reversal/Continuation Patterns') {
      var temp = ["Bullish Reversals", "Bearish Reversals", "Continuation Patterns"]
      this.setState({
        optionsArray: temp,
        standardSupportArray: [],
        channelArray: [],
        timeOptionArray: [],
        reversalOptionArray: [],
        timeFrameArray: [],
        pickerValue: ''
      })

    }
    else if (selectitem === 'Bullish Reversals') {
      var temp = ["Double Bottom", "Tripple Bottom", "Inverted Head & Shoulder"]
      this.setState({
        reversalOptionArray: temp
      })
    }
    else if (selectitem === 'Bearish Reversals') {
      var temp = ["Double Top", "Tripple Top", "Head & Shoulder"]
      this.setState({
        reversalOptionArray: temp
      })
    }
    else if (selectitem === 'Continuation Patterns') {
      var temp = ["Symmetrical Triangle", "Asccending Triangle", "Descending Triangle", "Rising Wedge", "Falling Wedge"]
      this.setState({
        reversalOptionArray: temp
      })
    }
    else if (selectitem === 'Candlesticks') {
      var temp = ["Bullish Candle Scans", "Bearish Candle Scans", "Other Patterns"];
      this.setState({
        optionsArray: temp,
        standardSupportArray: [],
        channelArray: [],
        timeOptionArray: [],
        reversalOptionArray: [],
        timeFrameArray: [],
        pickerValue: ''
      })
    }
    else if (selectitem === 'Bullish Candle Scans') {
      var temp = ["Bullish Marubozu", "Bullish Engulfing", "Bullish Harami", "Piercing Line", "Hammer", "Tweezer Bottoms", "Three Advancing Soldiers"]
      this.setState({
        reversalOptionArray: temp
      })
    }
    else if (selectitem === 'Bearish Candle Scans') {
      var temp = ["Bearish Marubozu", "Bearish Engulfing", "Bearish Harami", "Dark Cloud Cover", "Hanging Man", "Tweezer Tops", "Three Black Crows"]
      this.setState({
        reversalOptionArray: temp
      })
    }
    else if (selectitem === 'Other Patterns') {
      var temp = ['Spinning Top', 'Neutral Doji', 'Dragonfly Doji', 'Gravestone Doji']
      this.setState({
        reversalOptionArray: temp
      })
    }
  }

  visibleParcentage() {
    if (this.state.standardSupportLabel != '') {

      this.setState({ isParcentage: false, })

    } else {
      this.setState({ isParcentage: true, })
    }
  }
  onClickClear() {
    this.setState({
      priceActiveLabel: 'Select Price',
      standardSupportArray: [],
      channelArray: [],
      timeOptionArray: [],
      reversalOptionArray: [],
      timeFrameArray: [],
      pickerValue: '',
      isClose: true,
      optionsArray: [],
      standardSupportValue: '',
      reversalOptionValue: '',
      timeoptionValue: '',
      channelValue: '',
      isParcentage: false,
      isPriceTrandingUP: false,
      timeFrameValue: '',
      standardSupportLabel: '',
      selectArray: []

    })
  }

  //Price Action end//

  /** Indicators Functionality  start*/
  indicatorForFxn(selectArray: any, indicatorsArray: any, item: any) {
    for (let i in indicatorsArray) {
      const currentIndicator = indicatorsArray[i];

      if (currentIndicator.id === item.id) {
        if (!currentIndicator.isSelect) {
          currentIndicator.isSelect = true;
          this.setState({ temp: item.name });
        } else {
          this.setState({ hellper: 2 })
          currentIndicator.isSelect = false;
          const index = selectArray.indexOf(currentIndicator.name);
          if (index > -1) {
            selectArray.splice(index, 1);
          }
          this.setState({ temp: selectArray })
        }
      } else {
        currentIndicator.isSelect = false;
        this.setState({ temp: '' })
      }
    }
  }

  onClickBox(item: any) {
    const { indicatorsArray, selectArray } = this.state;

    if (indicatorsArray.length !== 0) {
      this.indicatorForFxn(selectArray, indicatorsArray, item)
      this.setState({
        isRefresh: !this.state.isRefresh,
        selectArray: this.state.hellper === 1 ? [this.state.temp] : this.state.temp
      });
    }


    // picker Code
    switch (item.name) {
      case 'RSI':
        this.setState({ isRsiVisible: true, rsiLabel: '', lengthInd: '', timeFameIndLabel: '', rsiCross: false, periodInd: '', RsiDivergenceItem: '', isDivergence: false,iscmf: false });
        this.props.onSelectScreen('', this.state.selectArray);
        break;
      case 'MFI':
        const mfiArray = [
          "MFI Above Overbought Level",
          "MFI Below Oversold Level",
          "Cross Overbought Level",
          "Cross Oversold Level",
          "Cross Centre Line",
          "Divergence"
        ];
        this.setState({ isRsiVisible: true, mfilabel: '', mfiArray, isDivergenceMfi: false, mfiRadio: false, mfiCross: false, mfiTimeFrame: '' });
        break;
      case 'Stochastic':
        const stochasticArray = [
          "%K Overbought Level",
          "%K Oversold Level",
          "Cross Overbought Level",
          "Cross Oversold Level",
          "%K Cross Centre Line",
          "%K Cross %D",
          "Divergence"
        ];
        this.setState({
          isRsiVisible: true,
          stochasticArray, stochasticlabel: '',
          isDivergencestochastic: false,
          stochasticRadio: false,
          StoDivergence: false,
          stochasticTimeFrame: '',
          stochasticCustome: ''
        });
        break;
      case 'CCI':
        const cciArray = [
          "CCI Above +100",
          "CCI Below -100",
          "Cross +100",
          "Cross -100",
          "Cross 0",
          "Divergence"
        ];
        this.setState({
          isRsiVisible: true,
          cciArray,
          isCCI: true,
          isRefresh: !this.state.isRefresh,
          isPeriodVisible: false,
          cciPeriod: '',
          cciRadio: false,
          isDivergencecci: false,
          cciTimeFrame: '',
          ccilabel: ''
        });
        break;
      case 'Moving Average':
        const movingAvgArray = [
          "Simple Moving Average",
          "Exponential Moving Average"
        ];
        const mvAvgArray = [
          "Price Above Simple Moving Average",
          "Price Below Simple Moving Average",
          "Price Crossed Above Simple Moving Average",
          "Price Crossed Below Simple Moving Average",
          "Price Bounced Up from Simple Moving Average",
          "Price Bounced Down from Simple Moving Average",
          "2 Moving Average Crossovers"
        ];
        this.setState({
          mvAvgArray,
          isRefresh: !this.state.isRefresh,
          isCCI: true,
          movingAvgArray,
          isDivergencemvAvg: true,
          movingAvgLabel: '',
          mvAvglabel: '',
          mvAvgPeriod: '',
          mvAvgRadio: false,
          mvAvgTimeFrame: ''
        });
        break;
      case 'MACD':
        const macdArray = [
          "MACD is Centerline(0)",
          "MACD Crossed Centerline(0)",
          "MACD Line is Signal Line",
          "MACD Line Crossed Signal Line",
          "MACD Trending Up/Down",
          "MACD Divergence"
        ];
        this.setState({
          macdArray,
          isRefresh: !this.state.isRefresh,
          isDivergencemacd: false,
          macdRadio: false,
          MckDivergence: false,
          macdTimeFrame: '',
          macdlabel: ''
        });
        break;
      case 'ADX':
        const adxArray = [
          "+DI Selected Level",
          "+DI Cross Selected Level",
          "+DI Cross -DI",
          "+DI -DI",
          "-DI Selected Level",
          "-DI Cross Selected Level",
          "-DI Cross +DI",
          "-DI +DI",
          "ADX Selected Level",
          "ADX Crossed Selected Level",
          "ADX Trending Up",
          "ADX Trending Down"
        ];
        this.setState({
          adxArray,
          isRefresh: !this.state.isRefresh,
          isDivergenceadx: false,
          adxRadio: true,
          adxlabel: '',
          adxPeriod: '',
          adxLength: '',
          adxTimeFrame: ''
        });
        break;
      case 'OBV':
        const obvArray = [
          "On Balance Volume Trending Up",
          "On Balance Volume Trending Down",
          "Divergence"
        ];
        this.setState({
          obvArray,
          isRefresh: !this.state.isRefresh,
          derivativesArray1: [],
          divergenceLable: '',
          isTimePeriod: false,
          obvPeriod: '',
          obvTimeFrame: '',
          obvlabel: ''
        });
        break;
      case 'CMF':
        const cmfArray = [
          "CMF Above 0",
          "CMF Below 0",
          "CMF Crossed Above 0",
          "CMF Crossed Below 0",
          "CMF Trending Up",
          "CMF Trending Down",
          "Divergence"
        ];
        this.setState({
          cmfArray,
          isRefresh: !this.state.isRefresh,
          iscmf: true,
          cmflabel: '',
          cmfTimeFrame: '',
          divergenceLablecmf: '',
          isDivergencecmf: false,
          cmfTimeFrameVisible: false,
          
        });
        this.props.cmfPicker('', '', '', '')
        break;
      case 'Bollinger Bands':
        const bollingerArray = [
          "Coin Price Near Upper Band",
          "Coin Price Near Lower Band",
          "Coin Price Near Middle Band",
          "Coin Price Crossed Middle Band",
          "Coin Price Crossed Upper Band",
          "Coin Price Crossed Lower Band",
          "Bollinger Band Squeeze"
        ];
        this.setState({
          bollingerArray,
          isRefresh: !this.state.isRefresh,
          iscmf: true,
          bollingerlabel: '',
          bollingerRadio: false,
          bollingerTimeFrame: '',
          timeFameIndLabel: '',
        });
        break;
      case 'ATR':
        const atrArray = [
          "ATR Trending Up",
          "ATR Trending Down"
        ];
        this.setState({
          atrArray,
          isRefresh: !this.state.isRefresh,
          atrlabel: '',
          atrPeriod: '',
          trending_period: '',
          atrTimeFrame: ''
        });
        break;
      default:
        break;
    }
  }

  onClickIndicator(index: any, selectedItem: any,) {
    this.setState({ isRefresh: !this.state.isRefresh })
    if (selectedItem === 'RSI Above Overbought Level' || selectedItem === 'RSI Below Oversold Level') {
      this.setState({
        rsiLabel: selectedItem,
        isRefresh: !this.state.isRefresh,
        rsiCross: false,
        isDivergence: true,
        isTimeFrameIdc: false,
        iscmf: false,
      })
    } else if (selectedItem === 'Cross Overbought Level' || selectedItem === 'Cross Oversold Level') {
      this.setState({
        isRefresh: !this.state.isRefresh,
        rsiLabel: selectedItem,
        iscmf: false,
        isDivergence: true,
        rsiCross: true,

      })
    }
    else if (selectedItem === 'Cross Centre Line') {
      this.setState({
        rsiLabel: selectedItem,
        isRefresh: !this.state.isRefresh,
        rsiCross: true,
        isDivergence: true,
        iscmf: false,

      })
    }
    else if (selectedItem === 'Divergence') {
      var temp = ["Bullish", "Bearish"]

      this.setState({
        rsiLabel: selectedItem,
        isRefresh: !this.state.isRefresh,
        rsiCross: false,
        isDivergence: false,
        isTimeFrameIdc: false,
        derivativesArray1: temp,
        iscmf: true,
      })
    }

    else if (selectedItem == "Price Above Simple Moving Average" || selectedItem == "Price Below Simple Moving Average" || selectedItem == "Price Crossed Above Simple Moving Average" || selectedItem == "Price Crossed Below Simple Moving Average" || selectedItem == "Price Bounced Up from Simple Moving Average" || selectedItem == "Price Bounced Down from Simple Moving Average") {
      this.setState({
        rsiLabel: selectedItem,
        isRefresh: !this.state.isRefresh,
        isDivergence: false,
        isDivergencemvAvg: false,
        AVGMovingActive: false,
        isTimeFrameIdc: false,
      })
    }

  }

  onClickMfi(index: any, selectedItem: any) {
    this.setState({ isRefresh: !this.state.isRefresh })
    if (selectedItem === 'MFI Above Overbought Level' || selectedItem === 'MFI Below Oversold Level') {
      this.setState({
        isRefresh: !this.state.isRefresh,
        mfiRadio: false,
        isDivergence: false,
        isTimeFrameIdc: true,
        isDivergenceMfi: true,
        mfiCross: false
      })
    }
    else if (selectedItem === 'Cross Overbought Level' || selectedItem === 'Cross Oversold Level' || selectedItem === 'Cross Centre Line') {
      this.setState({
        mfilabel: selectedItem,
        isRefresh: !this.state.isRefresh,
        mfiRadio: true,
        isDivergence: false,
        isDivergenceMfi: true,
      })
    }
    else if (selectedItem === 'Divergence') {
      var temp = ["Bullish"]

      this.setState({
        mfilabel: selectedItem,
        isRefresh: !this.state.isRefresh,
        mfiRadio: false,
        isDivergenceMfi: false,
        isTimeFrameIdc: false,
        derivativesArray1: temp,
        mfiCross: true,
        iscmf: false,
        isDivergence: true
      })
    }
  }
  onClickRadioMfi(name: any) {
    this.setState({ mfiradioAbove: name, isRefresh: !this.state.isRefresh })
  }

  /** onClickStochastic */
  onClickStochastic(index: any, selectedItem: any) {
    this.setState({ isRefresh: !this.state.isRefresh })
    if (selectedItem === '%K Overbought Level' || selectedItem === '%K Oversold Level') {
      // "K Above Overbought Level","K Below Overbought Level","K Above Oversold Level", "K Below Oversold Level", "K Cross Above Overbought Level", "K Cross Below Overbought Level", "K Cross Above Oversold Level","K Cross Below Oversold Level", "K Cross Above Centre Line","K Cross Below Centre Line",
      //   "K Cross Above D", "K Cross Below D",
      // ["%K Above Overbought Level", "%K Below Oversold Level", "Cross Above/Below Overbought Level", "Cross Above/Below Oversold Level", "%K Cross Above/Below Centre Line",
      // "%K Cross Above/Below %D", "Divergence"]
      this.setState({
        isRefresh: !this.state.isRefresh,
        stochasticRadio: true,
        isDivergence: false,
        isTimeFrameIdc: true,
        StoDivergence: false,
        isDivergencestochastic: true,
        StochastickTimeFrame: false,
      })
    }
    else if (selectedItem === 'Cross Overbought Level' || selectedItem === 'Cross Oversold Level') {
      this.setState({
        stochasticlabel: selectedItem,
        isRefresh: !this.state.isRefresh,
        stochasticRadio: true,
        StoDivergence: false,
        isDivergence: false,
        isDivergencestochastic: true,
        StochastickTimeFrame: false,
        isTimeFrameIdc: true
      })
    }
    else if (selectedItem === '%K Cross %D') {
      this.setState({
        stochasticlabel: selectedItem,
        StoDivergence: false,
        isRefresh: !this.state.isRefresh,
        stochasticRadio: true,
        isDivergencestochastic: false,
        isTimeFrameIdc: false,
        iscmf: false,


      })
    }
    else if (selectedItem === '%K Cross Centre Line') {
      // "K Cross Above Overbought Level", "K Cross Below Overbought Level", "K Cross Above Oversold Level","K Cross Below Oversold Level", "K Cross Above Centre Line","K Cross Below Centre Line",

      this.setState({
        StochastickTimeFrame: false,
        StoDivergence: false,
        stochasticRadio: true,
        isRefresh: !this.state.isRefresh,
        isDivergencestochastic: true,
        isTimeFrameIdc: true,
        stochasticlabel: selectedItem,
        isDivergence: false,

      })
    }
    else if (selectedItem === 'Divergence') {
      var temp = ["Bullish"]
      this.setState({
        stochasticlabel: selectedItem,
        StoDivergence: true,
        isRefresh: !this.state.isRefresh,
        stochasticRadio: false,
        isDivergencestochastic: false,
        isTimeFrameIdc: false,
        derivativesArray1: temp,
        iscmf: false,


      })
    }
  }
  //CCi
  onClickcci(index: any, selectedItem: any) {
    if (selectedItem == "Divergence") {
      var temp = ["Bullish"]

      this.setState({
        CCIDivergence: true,
        derivativesArray1: temp,
        cciRadio: false,
        cciLength: selectedItem,
        isDivergencecci: true,
        isRefresh: !this.state.isRefresh,
        isTimeFrameIdc: false,
        isPeriodVisible: false,
        iscmf: false,

      })
    }

    else if (selectedItem == "CCI Above +100" || selectedItem == "CCI Below -100") {
      this.setState({
        isRefresh: !this.state.isRefresh,
        cciRadio: false,
        isDivergencecci: false,
        CCIDivergence: false,
        isPeriodVisible: true
      })
    }
    else if (selectedItem === "Cross +100" || selectedItem === "Cross -100" || selectedItem === "Cross 0") {
      this.setState({
        isRefresh: !this.state.isRefresh,
        cciRadio: true,
        isDivergencecci: false,
        CCIDivergence: false,
        isPeriodVisible: true
      })
    }
  }

  // Movie Avg

  onClickMvArray(index: any, selectedItem: any) {

    if (selectedItem === 'Simple Moving Average') {
      this.setState({showScreens: false},()=>{
        this.setState({
          showScreens: true,
          mvAvgArray: configJSON.simpleMovingArr,
          isRefresh: !this.state.isRefresh,
          isCCI: true,
        })
      })
    }
    else if (selectedItem === 'Exponential Moving Average') {
      this.setState({showScreens: false},()=>{
        this.setState({
        showScreens: true,
        mvAvgArray: configJSON.exponentialMovingArr,
        isRefresh: !this.state.isRefresh,
        isCCI: true,
      })
      })
      
    }
    else if (selectedItem === "Price Above Simple Moving Average" || selectedItem === "Price Below Simple Moving Average" || selectedItem === "Price Crossed Above Simple Moving Average" || selectedItem === "Price Crossed Below Simple Moving Average" || selectedItem === "Price Bounced Up from Simple Moving Average" || selectedItem === "Price Bounced Down from Simple Moving Average") {
      this.setState({
        isRefresh: !this.state.isRefresh,
        isDivergencemvAvg: false,
        isTimeFrameIdc: false,
        AVGMovingActive: false
      })
    }
    else if (selectedItem === "2 Moving Average Crossovers") {
      this.setState({
        isRefresh: !this.state.isRefresh,
        isDivergencemvAvg: true,
        isTimeFrameIdc: true,
        AVGMovingActive: true,
      })

    }
    else if (selectedItem === "Bullish Crossovers" || selectedItem === "Bearish Crossovers") {
      this.setState({
        isDivergencemvAvg: false,
        isRefresh: !this.state.isRefresh,
        isCCI: true,
      })
    }
  }
  //Macd
  onClicMacdkRadio(name: any) {
    this.setState({ macdradioAbove: name, isRefresh: !this.state.isRefresh })
  }
  onClickMacd(index: any, selectedItem: any) {
    if (selectedItem == 'MACD Trending Up/Down') {
      this.setState({
        isDivergencemacd: true,
        MckDivergence: false,
        MacktrndingUpDown: true,
        macdRadio: true,
        isRefresh: !this.state.isRefresh,
        isCCI: true
      })
    }
    else if (selectedItem == 'MACD Divergence') {
      this.setState({
        isDivergencemacd: false,
        isRefresh: !this.state.isRefresh,
        isCCI: true,
        MckDivergence: true,
        MacktrndingUpDown: false,
        macdRadio: false
      })
    }
    else if (selectedItem == "MACD is Centerline(0)" || selectedItem === "MACD Crossed Centerline(0)" || selectedItem === "MACD Line is Signal Line" || selectedItem === "MACD Line Crossed Signal Line") {

      this.setState({
        isRefresh: !this.state.isRefresh,
        isDivergencemacd: false,
        MckDivergence: false,
        MacktrndingUpDown: false,
        macdRadio: true

      })
    }



  }

  //ADX
  onClickAdx(index: any, selectedItem: any) {

    if (selectedItem == "ADX Trending Up" || selectedItem == "ADX Trending Down") {
      this.setState({
        isRefresh: !this.state.isRefresh,
        isDivergenceadx: true,
        adxRadio: false
      })
    }
    else if (selectedItem === "+DI Selected Level" || selectedItem === "+DI Cross Selected Level" || selectedItem === "-DI Selected Level" || selectedItem === "-DI Cross Selected Level" || selectedItem == "ADX Selected Level") {

      this.setState({
        isRefresh: !this.state.isRefresh,
        isDivergenceadx: false,
        adxRadio: true

      })
    }
    else if (selectedItem === '+DI Cross -DI' || selectedItem === "+DI -DI" || selectedItem === "-DI Cross +DI" || selectedItem == "-DI +DI" || selectedItem == "ADX Crossed Selected Level") {
      this.setState({
        isDivergenceadx: true,
        isRefresh: !this.state.isRefresh,
        adxRadio: true
      })
    }
  }
  // OBV
  onClickobv(index: any, selectedItem: any) {

    if (selectedItem === "On Balance Volume Trending Up" || selectedItem == "On Balance Volume Trending Down") {
      this.setState({
        isRefresh: !this.state.isRefresh,
        isTimePeriod: true,
        isDivergenceobv: false,
        derivativesArray1: []
      })
    }
    else if (selectedItem === 'Divergence') {

      var temp = ["Bullish", "Bearish"]

      this.setState({
        divergenceLable: selectedItem,
        isRefresh: !this.state.isRefresh,
        isTimePeriod: false,
        isDivergenceobv: true,
        isTimeFrameIdc: false,
        derivativesArray1: temp,



      })
    }

  }
  //cmf
  onClickcmf(index: any, selectedItem: any) {

    if (selectedItem == "CMF Trending Up" || selectedItem == "CMF Trending Down") {

      this.setState({

        isRefresh: !this.state.isRefresh,
        iscmf: false,
        cmfradiosActive: true,
        isDivergencecmf: false,
        derivativesArray1: [],
        cmfTimeFrameVisible: true,
        cmfPeriodVisible: true
      })

    }
    else if (selectedItem == "CMF Above 0" || selectedItem === "CMF Below 0" || selectedItem === "CMF Crossed Above 0" || selectedItem === "CMF Crossed Below 0") {
      this.setState({

        isRefresh: !this.state.isRefresh,

        cmfradiosActive: false,
        isDivergencecmf: false,
        derivativesArray1: [],
        cmfTimeFrameVisible: false,
        cmfPeriodVisible: false

      })
    }
    else if (selectedItem === 'Divergence') {
      var temp = ["Bullish", "Bearish"]

      this.setState({
        isRefresh: !this.state.isRefresh,
        iscmf: false,
        isDivergencecmf: true,
        cmfradiosActive: true,
        isTimeFrameIdc: false,
        derivativesArray1: temp,
        cmfTimeFrameVisible: true,
        cmfPeriodVisible: false
      })
    }
  }


  //ATR
  onClickatr(index: any, selectedItem: any) {
    this.setState({ isRefresh: !this.state.isRefresh })

  }
  onClickBollinger(index: any, selectedItem: any) {
    this.setState({ isRefresh: !this.state.isRefresh })
    if (selectedItem == "Coin Price Crossed Middle Band" || selectedItem == 'Coin Price Crossed Upper Band' || selectedItem == "Coin Price Crossed Lower Band") {
      this.setState({
        isRefresh: !this.state.isRefresh,
        bollingerRadio: true
      })
    }
    else if (selectedItem == "Coin Price Near Upper Band" || selectedItem == 'Coin Price Near Lower Band' || selectedItem == "Coin Price Near Middle Band" || selectedItem == "Bollinger Band Squeeze") {

      this.setState({
        bollingerlabel: selectedItem,
        isRefresh: !this.state.isRefresh,
        bollingerRadio: false
      })
    }

  }
  getColorTheme = (mode: any, color1: any, color2: any) => {
    return this.context.mode === mode ? color1 : color2
  }

  getByCondition = (condition: any, match: any, result1: any, result2: any) => {
    return condition === match ? result1 : result2
  }

  renderConditionalItem = (condition: any, result1: any, result2?: any) => {
      return condition ? result1 : result2
  }

  colorTxt = (item: any) => {
    if (item?.isSelected) {
      return COLORS.green
    } else if (this.context.mode === 'light') {
      return COLORS.black
    }
    return COLORS.white;

  }
  async getPlan(){
    let currentPlan = await AsyncStorage.getItem("userInfo")
    if(currentPlan === "basic"){
      this.setState({isBasic:true})
    }
     this.setData()
  }
  setData(){
    if(this.state.isBasic){
      this.setState({ volumePeriodList: [
        '1 hr',
        '1 day' 
      ],
      indicatorsArray: [{ id: 1, name: "RSI", isSelect: false }, 
      { id: 5, name: "Moving Average", isSelect: false }, 
      { id: 8, name: "OBV", isSelect: false },
      { id: 11, name: "ATR", isSelect: false }],})
    } else {
      this.setState({ volumePeriodList: [
        '1 hr',
        '2 hr',
        '3 hr',
        '4 hr',
        '6 hr',
        '12 hr',
        '1 day',
        '2 days',
        '3 days',
        '7 days',
        '14 days',
        '15 days',
        '30 days',
        '60 days',
        '90 days',
        '365 days'
      ],
      indicatorsArray: [{ id: 1, name: "RSI", isSelect: false }, { id: 2, name: "MFI", isSelect: false },
      { id: 3, name: "Stochastic", isSelect: false }, { id: 4, name: "CCI", isSelect: false }, { id: 5, name: "Moving Average", isSelect: false },
      { id: 6, name: "MACD", isSelect: false }, { id: 7, name: "ADX", isSelect: false }, { id: 8, name: "OBV", isSelect: false },
      { id: 9, name: "CMF", isSelect: false }, { id: 10, name: "Bollinger Bands", isSelect: false }, { id: 11, name: "ATR", isSelect: false }],})
    }
  }
  handleNavigation = (path: string) => {
    let toNavigate = new Message(getName(MessageEnum.NavigationMessage));
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), path);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
  }
  screennavigate = () => {
    this.handleNavigation('Customisableusersubscriptions');
  }
  // Customizable Area End
}

import React from "react";
// Customizable Area Start
import ContactUsWebController, {
    Props
} from "./ContactUsController.web";

import {
    withStyles, StyleRules
} from "@material-ui/core/styles";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import LanguageIcon from '@material-ui/icons/Language';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneIcon from '@material-ui/icons/Phone';
import TextField from '@material-ui/core/TextField';

import Loader from "../../../components/src/Loader.web";
// Customizable Area End
const styles: StyleRules = {
    // Customizable Area Start
    root: {
        display: 'flex',
        justifyContent: 'center',
        fontFamily: 'Plus Jakarta Text',
        height: '100%'
    },
    profileCard: {
        width: '80%',
        marginTop: '50px',
        maxWidth: '400px',
        height: '475px',
        textAlign: 'center',
        borderRadius: '12px',
        overflow: 'hidden',
        padding: '12px',
    },
    cardTitle: {
        fontSize: '22px',
        fontWeight: 600,
        margin: '10px 0px'
    },
    iconsContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '22px 0px'
    },
    square: {
        color: '#079149',
        width: '50px',
        height: '50px',
        marginRight: '10px',
        borderRadius: '12px'
    },
    error: {
        fontSize: '0.9vw',
        fontWeight: 'bold',
        color: 'rgb(255, 20, 20)',
        textAlign: 'left',
        marginLeft: '15%',
        marginBottom: '10px'
    },
    submitButton: {
        backgroundColor: 'rgba(1,162,78,255)',
        color: '#ffffff',
        cursor: 'pointer',
        padding: '12px 20px',
        border: 'none',
        borderRadius: '10px',
        textAlign: 'center',
        width: '74%',
        display: 'block',
        margin: '2% auto',
        fontWeight: 600
    },
    fullWidth: {
        '& .MuiFormControl-fullWidth': {
            width: '75%'
        }
    },
    informationBox: {
        background: 'rgba(0, 0, 0, 0.09)',
        borderRadius: '10px',
        width: '75%',
        padding: '50px 10px'
    }
    // Customizable Area End
}
// Customizable Area Start
const contactUsSchema = Yup.object().shape({
    name: Yup.string()
        .required('Subject is required.'),
    email: Yup.string()
        .email('Please enter a valid email address.')
        .required('Email is required.')
});
// Customizable Area End
export class ContactUsWeb extends ContactUsWebController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const backgroundval = localStorage.getItem('appTheme') == 'darkTheme' ? '#232429' : '#f4f8f6';

        const getAvatarStyles = (selectedMethod: string) => {
            return {
                backgroundColor: this.state.contactMethod === selectedMethod ? 'rgba(1,162,78,255)' : backgroundval,
                color: this.state.contactMethod === selectedMethod ? '#ffffff' : '#079149'
            };
        };

        return (
            <Paper className={classes.root} data-testid='contactUsPaper' elevation={0} square style={{ backgroundColor: backgroundval }}>
                <Loader loading={this.state.loading} />
                <Paper className={classes.profileCard} elevation={3}>
                    <div className={classes.cardTitle}>Contact Us</div>
                    <div className={classes.iconsContainer}>
                        <Avatar variant="square" className={classes.square} data-testid="languageIcon" style={{ cursor: 'pointer', ...getAvatarStyles('language') }} onClick={this.handleLanguageIconClick} >
                            <LanguageIcon />
                        </Avatar>
                        <Avatar variant="square" className={classes.square} data-testid="mailIcon" style={{ cursor: 'pointer', ...getAvatarStyles('mail') }} onClick={this.handleMailIconClick} >
                            <MailOutlineIcon />
                        </Avatar>
                        <Avatar variant="square" className={classes.square} data-testid="phoneIcon" style={{ cursor: 'pointer', ...getAvatarStyles('phone') }} onClick={this.handlePhoneIconClick} >
                            <PhoneIcon />
                        </Avatar>
                    </div>
                    <div>
                        {this.state.contactMethod === "language" && (
                            <Formik
                                initialValues={{ name: '', email: '', description: '' }}
                                enableReinitialize
                                validationSchema={contactUsSchema}
                                onSubmit={(values: any, { resetForm, setSubmitting, setStatus }) => {
                                    this.contactUsButtonClick(values);
                                    Object.keys(values).forEach(key => (values[key] = ""));
                                    resetForm({ values: values });
                                    setStatus(true);
                                }}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                    setFieldValue,
                                }) => (
                                    <Form onSubmit={handleSubmit} >
                                        <div className={classes.fullWidth}>
                                            <Field
                                                validateOnBlur
                                                validateOnChange
                                                name="name"
                                                render={({ field, form }: any) => (
                                                    <TextField
                                                        label={"Enter Subject*"}
                                                        name={"name"}
                                                        value={values.name}
                                                        variant="filled"
                                                        fullWidth
                                                        InputProps={{ disableUnderline: true }}
                                                        InputLabelProps={{ style: { color: '#8f92a1' } }}
                                                        error={
                                                            Boolean(form.errors.name && form.touched.name)
                                                        }
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        helperText={
                                                            form.errors.name &&
                                                            form.touched.name &&
                                                            String(form.errors.name)
                                                        }
                                                        style={{ marginBottom: '15px' }}
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div className={classes.fullWidth}>
                                            <Field
                                                validateOnBlur
                                                validateOnChange
                                                name="email"
                                                render={({ field, form }: any) => (
                                                    <TextField
                                                        label={"Enter Email*"}
                                                        name={"email"}
                                                        value={values.email}
                                                        variant="filled"
                                                        fullWidth
                                                        InputProps={{ disableUnderline: true }}
                                                        InputLabelProps={{ style: { color: '#8f92a1' } }}
                                                        error={
                                                            Boolean(form.errors.email && form.touched.email)
                                                        }
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        helperText={
                                                            form.errors.email &&
                                                            form.touched.email &&
                                                            String(form.errors.email)
                                                        }
                                                        style={{ marginBottom: '15px' }}
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div className={classes.fullWidth}>
                                            <Field
                                                validateOnBlur
                                                validateOnChange
                                                name="description"
                                                render={({ field, form }: any) => (
                                                    <TextField
                                                        label={"Write a message..."}
                                                        name={"description"}
                                                        value={values.description}
                                                        variant="filled"
                                                        fullWidth
                                                        InputProps={{ disableUnderline: true }}
                                                        InputLabelProps={{ style: { color: '#8f92a1' } }}
                                                        multiline
                                                        rows={2}
                                                        error={
                                                            Boolean(form.errors.description && form.touched.description)
                                                        }
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        helperText={
                                                            form.errors.description &&
                                                            form.touched.description &&
                                                            String(form.errors.description)
                                                        }
                                                        style={{ marginBottom: '15px' }}
                                                    />
                                                )}
                                            />
                                        </div>
                                        <button type="submit" className={classes.submitButton}>Submit</button>
                                    </Form>
                                )}
                            </Formik>)}
                        {this.state.contactMethod === "mail" && (
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <div className={classes.informationBox} >
                                    contact@kryptokia.com
                                </div>
                            </div>
                        )}
                        {this.state.contactMethod === "phone" && (
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <div className={classes.informationBox} >
                                    +91-9876543210
                                </div>
                            </div>
                        )}
                    </div>
                </Paper>
            </Paper>
        )
        // Customizable Area End
    }
}

export default withStyles(styles)(ContactUsWeb);

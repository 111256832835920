import React from "react";
// Customizable Area Start
import EmailAccountRegistrationWebController, {
    Props
} from "./EmailAccountRegistrationController.web";

import {
    withStyles, StyleRules
} from "@material-ui/core/styles";

import { Formik, Form, Field, FormikTouched, FormikErrors } from "formik";
import * as Yup from "yup";

import Loader from "../../../components/src/Loader.web";

import "./EmailAccountRegistration.web.css";

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Button from '@material-ui/core/Button';

import { cryptoLogo } from "./assets";
import Select, { SelectProps } from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { SignupFormValues } from "../../../components/src/types";
// Customizable Area End

const styles: StyleRules = {
    // Customizable Area Start
    emailRegistrationRoot: {
        minHeight: '100vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: localStorage.getItem('appTheme') == 'darkTheme' ? '#232429' : 'rgba(244,248,246,255)' 
    },
    emailRegistrationContainer: {
        margin: '15px',
        position: 'relative',
        backgroundColor: localStorage.getItem('appTheme') == 'darkTheme' ? '#333742' : 'rgba(255,255,255,255)',
        width: '550px',
        padding: '70px 50px 40px',
        borderRadius: '12px',
        boxShadow: '0 0 60px 5px rgba(0, 0, 0, 0.2)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    emailRegistrationImageContainer: {
        width: '85px',
        height: '85px',
        overflow: 'hidden',
        border: '8px solid #e7e7e7',
        borderRadius: '50%',
        position: 'absolute',
        top: 0,
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    emailRegistrationTabs: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '12px',
        whiteSpace: 'nowrap'
    },
    emailRegistrationCustomInputGroup: {
        position: 'relative',
        width: '100%'
    },
    emailRegistrationCustomInput: {
        width: '85%',
        padding: '4%',
        margin: '3% 0%',
        borderRadius: '15px',
        background: 'rgba(244,248,246,255)',
        color: '#8f92a1',
        border: 'none',
        outline: 'none',
        paddingLeft: '38px'
    },
    emailRegistrationCustomPasswordInput: {
        width: "73%",
        padding: "4%",
        margin: "3% 0%",
        borderRadius: "15px",
        background: "rgba(244,248,246,255)",
        color: "#8f92a1",
        border: "none",
        outline: "none",
        paddingLeft: "38px",
        paddingRight: "38px"
      },
    emailRegistrationError: {
        fontSize: '12px',
        fontWeight: 'bold',
        color: 'rgb(255, 20, 20)',
        marginLeft: '3%',
        width: '201px'
    },
    emailRegistrationButton: {
        backgroundColor: 'rgba(1,162,78,255)',
        color: '#ffffff',
        cursor: 'pointer',
        padding: '15px 20px',
        border: 'none',
        borderRadius: '10px',
        fontFamily: 'Plus Jakarta Text',
        fontSize: '14px',
        fontWeight: 'bold',
        textAlign: 'center',
        width: '100%',
        display: 'block',
        margin: '28px auto 8px'
    },
    visibilityIcons: {
      height: '18px',
      width: '18px'
    },
    countryList: {
        fontSize: '12px',
    },
    countryCodeInput: {
        position: 'absolute',
        fontSize: '12px',
        left: '28px',
        top: '11px',
        color: '#000000DC',
        width: '23%',
        "&:before": {
            border: 0
        },
        "& .MuiSelect-select": {
            backgroundColor: '#f4f8f6',
            paddingRight: 14,
        },
        "& .MuiSelect-icon": {
            color: '#0000008a',
        }
    }
    // Customizable Area End
}

// Customizable Area Start
const singupSchema = Yup.object().shape({
    fullname: Yup.string()
        .required('Full name is required.')
        .matches(/^[a-zA-Z ]+$/, 'Name should not contain special characters or start with a number.'),
    email: Yup.string()
        .required('Email is required.')
        .email('Please enter a valid email address.'),
    country_code: Yup
        .string()
        .required('Country code is required.'),
    full_phone_number: Yup
        .string()
        .required('Mobile number is required.')
        .min(10, 'Minimum Mobile number length is 10')
        .max(15, 'Maximum Mobile number length is 15'),
    password: Yup
        .string()
        .min(8, 'Minimum Password length is 8.')
        .max(15, 'Maximum Password length is 15')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~#^()<>{}@$!%*?&])[A-Za-z\d~#^()<>{}@$!%*?&]{8,}$/,
            'Password must contain atleast a capital letter, a lowercase letter, a number and a special character.'
        )
        .required('Password is required.'),
    termsAndConditions: Yup.bool().oneOf([true], 'Accept Terms & Conditions is required')
});
// Customizable Area End
export class EmailAccountRegistrationWeb extends EmailAccountRegistrationWebController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    handleFieldError(fieldName: string, touched: FormikTouched<SignupFormValues>, errors: FormikErrors<SignupFormValues>) {
        const { classes } = this.props;
        return touched[fieldName as keyof SignupFormValues] && errors[fieldName as keyof SignupFormValues] ? (<div className={classes.emailRegistrationError}>{errors[fieldName as keyof SignupFormValues]}</div>) : null
    }

    tabsOptions() {
        const { classes } = this.props;
        return(
            <>
                <Button
                    variant="contained"
                    data-testid="back-btn-to-home"
                    style={{ background: 'rgba(1,162,78,255)', color: '#ffffff', textTransform: 'none', marginBottom: '10px', top: '15px', left: '15px', position: 'absolute' }}
                    onClick={() =>  this.navigationLinkPressed('LandingPageWeb') }
                > <ArrowBackIcon/>
                </Button>
                <div className={classes.emailRegistrationImageContainer}>
                    <img src={cryptoLogo} alt="profile-image" style={{ width: '100%', height: '100%', cursor: 'pointer' }} onClick={this.onImageClick} />
                </div>
                <div className={classes.emailRegistrationTabs} id="tabsContainer">
                    <button style={{ backgroundColor: localStorage.getItem('appTheme') == 'darkTheme' ?  '#333742' : 'rgba(255, 255, 255, 255)' }} data-testid="loginBtn" type="button" className={this.state.enabledForm == 'Login' ? 'tabButton active' : 'tabButton'} onClick={() => this.activeForm('Login')}>Login</button>
                    <button style={{ backgroundColor: localStorage.getItem('appTheme') == 'darkTheme' ?  '#333742' : 'rgba(255, 255, 255, 255)' }} data-testid="signupBtn" type="button" className={this.state.enabledForm == 'Signup' ? 'tabButton active' : 'tabButton'} onClick={() => this.activeForm('Signup')}>Sign Up</button>
                </div>
            </>
        )
    }
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { countryCodes } = this.state;
        return (
            <div className={classes.emailRegistrationRoot}>
                <Loader loading={this.state.loading} />
                <div className={classes.emailRegistrationContainer}>
                    {this.tabsOptions()}
                    <Formik
                        initialValues={{ fullname: '', email: '',country_code: '' ,full_phone_number: '', password: '', termsAndConditions: false }}
                        validationSchema={singupSchema}
                        onSubmit={(values) => {
                            this.signUp(values);
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue,
                        }) => (
                            <Form onSubmit={handleSubmit} >
                                <div>
                                    <div className={classes.emailRegistrationCustomInputGroup}>
                                        <span
                                            className={'left-icon profile-icon'}
                                        />
                                        <Field
                                            name="fullname"
                                            type="text"
                                            placeholder='Enter full name'
                                            className={classes.emailRegistrationCustomInput}
                                        />
                                        {/* Applies the proper error message from validateSchema when the user has
								clicked the element and there is an error, also applies the .error-message
								CSS class for styling */}
                                        { this.handleFieldError('fullname', touched, errors)}
                                    </div>
                                </div>
                                <div>
                                    <div className={classes.emailRegistrationCustomInputGroup}>
                                        <span
                                            className={'left-icon email-icon'}
                                        />
                                        <Field
                                            name="email"
                                            type="text"
                                            placeholder='Enter your email'
                                            className={classes.emailRegistrationCustomInput}
                                        />
                                        {/* Applies the proper error message from validateSchema when the user has
								clicked the element and there is an error, also applies the .error-message
								CSS class for styling */}
                                        { this.handleFieldError('email', touched, errors)}
                                    </div>
                                </div>
                                <div>
                                    <div className={classes.emailRegistrationCustomInputGroup}>
                                        <span
                                            className={'left-icon mobile-icon'}
                                        />
                                        <Select
                                            name="country_code"
                                            disableUnderline
                                            data-testid="country-code-selector"
                                            className={classes.countryCodeInput}
                                            onChange={handleChange}
                                            renderValue={(value: SelectProps['value']) => `+${value}`}
                                        >
                                            {countryCodes.length > 0 && countryCodes.map((code, index) => (
                                                <MenuItem key={index} className={classes.countryList} value={code.attributes.country_code}>
                                                    +{code.attributes.country_code} {code.attributes.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <Field
                                            name="full_phone_number"
                                            type="text"
                                            style={{ paddingLeft: 90, width: "66%" }}
                                            placeholder='Enter your mobile number'
                                            className={classes.emailRegistrationCustomInput}
                                        />
                                        {/* Applies the proper error message from validateSchema when the user has
								clicked the element and there is an error, also applies the .error-message
								CSS class for styling */}
                                        { this.handleFieldError('country_code', touched, errors)}
                                        { this.handleFieldError('full_phone_number', touched, errors)}
                                    </div>
                                </div>
                                <div>
                                    <div className={classes.emailRegistrationCustomInputGroup}>
                                        <span
                                            className={'left-icon password-icon'}
                                        />
                                        <Field
                                            name="password"
                                            type={this.state.showPass ? 'text' : 'password'}
                                            id="password"
                                            placeholder='Enter your password'
                                            className={classes.emailRegistrationCustomPasswordInput}
                                        />
                                        <span
                                            className='right-icon'
                                            onClick={() => this.togglePassword()}
                                            data-testid="togglePassword">
                                                {this.state.showPass ? <VisibilityOffIcon className={classes.visibilityIcons} /> : <VisibilityIcon className={classes.visibilityIcons} /> }
                                        </span>
                                    </div>
                                    {/* Applies the proper error message from validateSchema when the user has
                          clicked the element and there is an error, also applies the .error-message
                          CSS class for styling */}
                                    { this.handleFieldError('password', touched, errors)}
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '2% 0%' }}>
                                    <div>
                                        <Field
                                            type="checkbox"
                                            name="termsAndConditions"
                                            checked={values.termsAndConditions}
                                            className="custom-checkbox"
                                            disabled={(errors.email || errors.full_phone_number || errors.fullname || errors.password || values.email === '' || values.full_phone_number === '' || values.fullname === '' || values.password === '')}
                                        />
                                        <label data-testid="termsCondition" htmlFor="acceptTerms" className="form-check-label" onClick={() => this.navigationLinkPressed('TermsAndConditionWeb')}>Accept Terms and Conditions*</label>
                                    </div>
                                </div>
                                {/* Applies the proper error message from validateSchema when the user has
                          clicked the element and there is an error, also applies the .error-message
                          CSS class for styling */}
                                { this.handleFieldError('termsAndConditions', touched, errors)}
                                <button data-testid="signup" type="submit" className={classes.emailRegistrationButton}>Sign Up</button>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        )
        // Customizable Area End
    }
}

export default withStyles(styles)(EmailAccountRegistrationWeb);

import React, { useEffect, useState, } from 'react';
import { View, Text, TouchableOpacity, Modal, FlatList, StyleSheet, Image, } from 'react-native';
import {CheckBox} from 'react-native-elements';
import { SavedFilter } from '../../blocks/dashboard/src/types';
import CustomText from './text';



interface FilterComponentProps {
  multipleFilterCheck : boolean;
  data: SavedFilter[];
  deleteApi: (id: string) => void
  apply: (selectedObject: object, key: string, value?:any) => void
  cancel: () => void
  setmultipleFilterCheck :()=>void
  setModalData :()=>void
  setmultipleFilterId:(id:any)=>void
  // Add other necessary props
}

const FilterComponent: React.FC<FilterComponentProps> = ({setmultipleFilterId,setModalData,setmultipleFilterCheck,multipleFilterCheck, data, deleteApi, apply, cancel }) => {
  const [isFilterModalVisible, setFilterModalVisible] = useState(true);
  const [selectedFilters, setSelectedFilters] = useState<any>([]);
  const[keyName,setKeyName] = useState<string>("");
  const openFilterModal = () => setFilterModalVisible(true);
  const closeFilterModal = () => setFilterModalVisible(false);
  
    const toggleFilter = (object: any,key:string) => {
      setKeyName(key)
      const check = selectedFilters.find((item:any,index:any)=>{
        console.warn(JSON.stringify(item)+JSON.stringify(object)+"jolp")
        return item.id==object.id
      })
      if(check){
        setSelectedFilters([])
      
        return;
      }
      setSelectedFilters([object])
    
    };
    useEffect(()=>{
      // alert(JSON.stringify(data))
    })
  

  const renderItem = ({ item }: { item: any }) => {
    // console.warn(JSON.stringify(item) + "ide2")
    const key = Object.keys(item.attributes)[0]
    return (
      // <TouchableOpacity onPress={() => toggleFilter(item.id)}>
      <View style={styles.contentContainer} >
        <View style={{ flexDirection: "row", alignItems: "center" }} >
          <CheckBox onPress={() =>
            toggleFilter(item.attributes[key],key)
          } checked={selectedFilters.includes(item.attributes[key])} checkedColor='green' />
          <Text style={{ marginLeft: 10,maxWidth:150, fontSize: 14}} >
            {
        item.attributes[key]?.filter_name
            }</Text>
        </View>
        <TouchableOpacity onPress={() => {
          alert("Filter Deleted Successfully")
          if(multipleFilterCheck){
            deleteApi(
              item.attributes[key].id
            )
          }
          else{
            deleteApi(
              item.attributes[key].id
            )
          }
         
        }} style={{ height: 20, width: 20 }}>
          <Image
            source={require('./delete.png')}
            style={{ height: 20, width: 20 }}
          />
        </TouchableOpacity>

      </View>
      // </TouchableOpacity>

    )

  };

  const applyFilters = () => {
    // Handle selected filters, e.g., update state, fetch data, etc.
    console.log('Selected Filters:', selectedFilters);
    closeFilterModal();
  };
  const fundamentalMultiple = (object:any)=>{
      const fundamentalObject :any= {
        rank:"",rank_category:"",market_cap_min:"",market_cap_max:""
      }
      for(let key in object){
        if(fundamentalObject[key]!=undefined){
          fundamentalObject[key] = object[key]
        }

      }
      apply(fundamentalObject,"fundamentals",true)
      console.log(JSON.stringify(fundamentalObject)+"fundamentalObject")
  }
  const volumeMultiple = (object:any)=>{
    const volumeObject :any= {
      "volume_option": "",
      "volume_period": "",
      "volume_length": "",
      "volume_order": "",
      "volume_from": "",
      "volume_to": ""
    }
    for(let key in object){
      if(volumeObject[key]!=undefined){
        volumeObject[key] = object[key]
      }

    }
    apply(volumeObject,"volume",true)
    console.log(JSON.stringify(volumeObject)+"fundamentalObject")
  }
  const priceMultiple = (object:any)=>{
    const priceObject :any= {
      "price_action_main_option": ""
      , "price_action_option": ""
      , "price_action_suboption": "",
      "price_action_timeframe": ""
      , "gainloss_percentage": ""
    }
    for(let key in object){
      if(priceObject[key]!=undefined){
        priceObject[key] = object[key]
      }

    }
    apply(priceObject,"price_action",true)
    console.log(JSON.stringify(priceObject)+"fundamentalObject")
  }
  const indicatorMultiple = (object:any)=>{
    const indicatorObject :any= {
      "indicator_main_option": "",
      "indicator_screen_option": "",
      "indicator_period": "",
      "indicator_trending_period": "",
      "indicator_above_below": "",
      "indicator_trending_up_down": "",
      "indicator_level_centerline": "",
      "indicator_divergence_crossover": "",
      "indicator_moving_average_type": "",
      "indicator_time_frame": ""
    }
    for(let key in object){
      if(indicatorObject[key]!=undefined){
        indicatorObject[key] = object[key]
      }
    }
    apply(indicatorObject,"indicator",true)
    console.log(JSON.stringify(indicatorObject)+"fundamentalObject")
  }
  return (
    <View style={styles.container}>
      <TouchableOpacity onPress={openFilterModal}>
        <Text>Show Filters</Text>
      </TouchableOpacity>

      <Modal transparent visible={isFilterModalVisible} onRequestClose={closeFilterModal}>
        <View style={styles.modalContainer}>
          <View style={styles.modalContent}>
            <FlatList
              showsVerticalScrollIndicator={false}
              data={data}
              renderItem={renderItem}
              style={{height:400}}
              extraData={selectedFilters}
              keyExtractor={(item) => item.id}
            />
            <View style={{ flexDirection: "row", justifyContent: "space-between", padding: 10 }} >
              <TouchableOpacity
                testID="btnExample"
                onPress={() =>{
                  if(multipleFilterCheck){
                    if(keyName.includes("volume")){
                      volumeMultiple(selectedFilters[0])
                    }
                    if(keyName.includes("fundamental")){
                      fundamentalMultiple(selectedFilters[0])
                    }
                    if(keyName.includes("price")){
                      priceMultiple(selectedFilters[0])
                    }
                    if(keyName.includes("indicator")){
                      indicatorMultiple(selectedFilters[0])
                    }
                    setmultipleFilterId(selectedFilters[0].id)
                    setmultipleFilterCheck()
                    
                  }
                  else{
                    apply(selectedFilters[0],keyName)
                    setModalData()
                  }
                  
                } }
                style={[styles.saveBtn, { marginRight: 15 }]}>
                <CustomText style={styles.saveTxt}>Apply</CustomText>
              </TouchableOpacity>
              <TouchableOpacity
                testID="btnExample"
                onPress={() =>{
                  if(multipleFilterCheck){
                    setmultipleFilterCheck()
                    cancel()
                    setModalData()
                  }
                  else{
                    cancel()
                    setModalData()
                  }
                 
                }
                 
                }
                style={[styles.saveBtn, { marginRight: 15 }]}>
                <CustomText style={styles.saveTxt}>Cancel</CustomText>
              </TouchableOpacity>
            </View>

          </View>
        </View>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  saveBtn: { alignItems: "flex-end", marginTop: 6 },
  saveTxt: { fontSize: 15, color: "green" },
  filterItem: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 8,
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    width: '80%',
    padding: 16,
    backgroundColor: 'white',
    borderRadius: 8,
  },
  applyButton: {
    padding: 8,
    borderRadius: 4,
    backgroundColor: '#DDDDDD',
    marginTop: 16,
    alignItems: 'center',
  },
});

export default FilterComponent;
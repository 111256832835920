import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from 'chart.js';
import { currentPlan, setChartData } from "../../../components/src/chartUtils.web";
import { toast } from "react-toastify";
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    token: any,
    loading: any,
    chartTimeFrame: any;
    chartType: any;
    isChartToolTipVisible: any;
    chartToolTipLeft: any;
    chartToolTipTop: any;
    tooltipPrice: any;
    tooltipDate: any;
    tooltipTime: any;
    watchlistCrypto: any;
    currentSelectedWatchlistCryproData: any;
    watchlistPriceChartData: any;
    watchlistVolumeChartData: any;
    watchlistMarketCapData: any;
    watchlistAreaSeries: any;
    watchlistLineSeries: any;
    watchlistVolumeSeries: any;
    watchlistRemoveDialog: any;
    removedWatchlistData: any;
    addToWatchlistDialog: boolean;
    cryptoData: any;
    searchKey: any;
    searchedCryptoData: any;
    selectedCryptoData: any;
    lineOptions: any;
    lineChartData: any;
    addDialogError: any;
    setDialogLoading: boolean;
    graphLoader: boolean;
    addNewError: boolean;
    limitExceed: boolean;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class WatchListWebController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    watchlistChartRef: any = '';
    watchlistChartConatinerRef: any = '';
    getWathclistCryptoDataApiCallId:string = '';
    removeWathclistCryptoDataApiCallId: string = '';
    watchlistGraphsApiCallId:string = '';
    createWathclistCryptoDataApiCallId: string = '';
    getCryptoCallId: string = '';
    getSearchApiCallId: string = '';
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
         // Customizable Area End
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            token: localStorage.getItem('token') || "",
            loading: false,
            chartTimeFrame: '',
            chartType: '',
            isChartToolTipVisible: false,
            chartToolTipLeft: 12,
            chartToolTipTop: 12,
            tooltipPrice: '',
            tooltipDate: '',
            tooltipTime: '',
            watchlistCrypto:[],
            currentSelectedWatchlistCryproData:'',
            watchlistPriceChartData:[],
            watchlistVolumeChartData:[],
            watchlistMarketCapData:[],
            watchlistAreaSeries: null,
            watchlistLineSeries: null,
            watchlistVolumeSeries: null,
            watchlistRemoveDialog: false,
            removedWatchlistData: null,
            addToWatchlistDialog: false,
            cryptoData: [],
            searchKey: '',
            searchedCryptoData: [],
            selectedCryptoData: "",
            lineOptions: {
                responsive: true,
                elements: {
                    point:{
                        radius: 0
                    }
                },
                plugins: {
                    title: {
                        display: false,
                    },
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        displayColors: false,
                    }
                },
                scales: {
                    x: {
                        grid: {
                            display: false,
                            drawBorder: false
                        },
                        ticks: {
                            display: false
                        }
                    },
                    y: {
                        grid: {
                            display: false,
                            drawBorder: false
                        },
                        ticks: {
                            display: false
                        }
                    }
                }
            },
            addDialogError: null,
            setDialogLoading: false,
            lineChartData: {},
            graphLoader: false,
            addNewError: false,
            limitExceed: false,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {

        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            switch (apiRequestCallId) {
                case this.getWathclistCryptoDataApiCallId:
                    this.setState({ loading: false });
                    this.setWatchlistData(responseJson);
                    break;
                case this.watchlistGraphsApiCallId:
                    this.setState({ graphLoader: false });
                    const response = setChartData(responseJson.data?.points)
                    this.setState({
                        watchlistPriceChartData: response.chartPriceData,
                        watchlistVolumeChartData: response.chartVolumeData,
                        watchlistMarketCapData: response.chartMarketCapData
                    });      
                    break;
                case this.createWathclistCryptoDataApiCallId:
                    if (responseJson.data) {
                    this.setState({ loading: false, addToWatchlistDialog: false});
                    toast.success('Coin added to watchlist successfully')
                    this.getWatchlistCrypto();
                    }
                    if (responseJson.message) {
                        this.setState({ loading: false});
                        this.setState({ addDialogError: responseJson.message });
                    }
                    break;
                case this.removeWathclistCryptoDataApiCallId:
                    if (responseJson.message) {
                        this.setState({ loading: false, watchlistRemoveDialog: true });
                        this.getWatchlistCrypto();
                    }
                    break;    
                case this.getSearchApiCallId:
                    if (responseJson.crypto_data) {
                        this.setState({ setDialogLoading: false, searchedCryptoData: responseJson.crypto_data, addNewError: false });
                    } else if (responseJson.error) {
                        this.setState({ setDialogLoading: false, addNewError: true });
                    }
                    break;
                default:
                    break;

            }
            runEngine.debugLog("API Message Recived", message);
        }
        // Customizable Area End
    }
    async componentDidMount() {
        ChartJS.register(
            CategoryScale,
            LinearScale,
            PointElement,
            LineElement,
            Title,
            Tooltip,
            Legend,
            Filler,
        );
        // Customizable Area Start
        this.setState({
            chartTimeFrame: '1D',
            chartType: 'Price',
        })
        
        this.getWatchlistCrypto()
        // Customizable Area End
    }
    // Customizable Area Start
    setWatchlistData = (responseJson: any) => {
        if (responseJson.data && responseJson.data.length > 0) {
            let watchlist = responseJson.data.filter((d: any) => d.attributes.crypto_details.length > 0);
            watchlist.map((item: any) => {
                item.lineOptions = this.state.lineOptions;
                const detail = item.attributes.crypto_details[0];
                item.lineChartData = {
                    labels:   detail.graph_data.map((gd: any) => Number(gd.price.replace(",", "").replace("$", ""))),
                    datasets: [
                        {
                            label: '',
                            data: detail.graph_data.map((gd: any) => Number(gd.volume_24h.replace(/,/g, '').replace('$','')
                            )),
                            borderColor: this.chartColor(detail),
                            backgroundColor: this.chartColor(detail),
                            fill: false,
                            datalabels: {
                                display: false
                            }
                        }
                    ]
                }
            })
            this.setState({
                watchlistCrypto: watchlist,
                currentSelectedWatchlistCryproData: responseJson.data[0],
            })
        }
    }

    chartColor = (detail: any) => detail.volume_percentage_change_24h.charAt(0) === "-" ? '#E86A3F' : '#01A24E';
    
    getSearchedCrypto = (query: string) => {
        this.setState({ setDialogLoading: true, addDialogError: "" });
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getSearchApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_dashboard/news/search_by_keyword?keyword=" + query);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    createWatchlistCrypto = (id: any) => {
        this.setState({
            loading: true
        })
        const header = { "Content-Type": "application/json", token: this.state.token };
        const httpBody = {
            crypto_id: id.toString()
        }
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.createWathclistCryptoDataApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `/bx_block_watchlist/crypto_watchlists`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getWatchlistCrypto = () => {
        this.setState({ loading: true });
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getWathclistCryptoDataApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `/bx_block_watchlist/crypto_watchlists`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    removeFromWatchlistCrypto = (item: any) => {
        this.setState({ loading: true, removedWatchlistData: item.attributes.crypto_details[0] });
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.removeWathclistCryptoDataApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `/bx_block_watchlist/crypto_watchlists/${item.attributes.crypto_id}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "DELETE");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    watchlistGraphData = (cryptoId: any, timeFrame: any) => {
        this.setState({ graphLoader: true });
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.watchlistGraphsApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_dashboard/cryptocurrency_chart?id=${cryptoId}&interval=${timeFrame}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleRemoverButtonClick = async (event:any, row:any) => {
        event.stopPropagation();
        this.removeFromWatchlistCrypto(row);
    }

    handleSearchKeywordInputChange = (event: any) => {
        const { value } = event.target;
        this.setState({ searchKey: value, selectedCryptoData: "" });
        this.getSearchedCrypto(value);
    };

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ addDialogError: null });
        this.setState({ selectedCryptoData: event.target.value });
    }

    handleAddNew = () => {
        if(!this.state.addNewError) {
            const planAccess = currentPlan();
            if (this.state.watchlistCrypto.length >= planAccess.coins_in_watchlist) {
               this.setState({ limitExceed: true });
            } else {
                this.getSearchedCrypto("")
                this.setState({ addToWatchlistDialog: true});
            }
        } else {
            toast.error('We are having some issue. Please try later.')
        }
    }
    // Customizable Area End
}

import React from "react"
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import CardHeader from '@material-ui/core/CardHeader';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Avatar from '@material-ui/core/Avatar';
import { removePlusMinusSymbol } from "./chartUtils.web";

interface DoublePattern {
    first: number,
    firstTimestamp: string,
    mid?: number,
    midTimestamp?: string,
    second: number,
    secondTimestamp: string,
    classes: any
}

interface TriplePattern extends DoublePattern {
    firstMid: number,
    firstMidTimestamp: string,
    secondMid: number,
    secondMidTimestamp: string,
    third: number,
    thirdTimestamp: string,
}

interface HeadShoulderPattern {
    shoulder1: number,
    shoulder1Timestamp: string,
    firstMid: number,
    firstMidTimestamp: string,
    head: number,
    headTimestamp: string,
    secondMid: number,
    secondMidTimestamp: string,
    shoulder2: number,
    shoulder2Timestamp: string,
    classes: any
}

interface ContinuationPatterns {
    upper_first: number,
    upper_first_timestamp: string,
    upper_last: number,
    upper_last_timestamp: string,
    lower_first: number,
    lower_first_timestamp: string,
    lower_last: number,
    lower_last_timestamp: string,
    classes: any
}

const PriceActionTableHeadings = ({ headings, classes }: any) => {
    return (
        <TableHead>
            <TableRow>
                <TableCell  style={{ zIndex: 1, backgroundColor: localStorage.getItem('appTheme') == 'ligthTheme' ? 'rgba(255, 255, 255, 255)' : '#333742' }}>#</TableCell>
                {headings.map((heading: string) =>
                    <TableCell  style={{ zIndex: 1, backgroundColor: localStorage.getItem('appTheme') == 'ligthTheme' ? 'rgba(255, 255, 255, 255)' : '#333742' }} className={classes.tableHead} key={heading}>{heading}</TableCell>
                )}
                <TableCell  style={{ zIndex: 1, backgroundColor: localStorage.getItem('appTheme') == 'ligthTheme' ? 'rgba(255, 255, 255, 255)' : '#333742' }}></TableCell>
            </TableRow>
        </TableHead>
    )
}

const CommonFields = ({ row, handleMorePopup }: any) => {
    const data = row.crypto_data ? row.crypto_data : row
    return (<>
        <TableCell align="left">
            <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                {`${data.one_day?.high}`}
            </Typography>
        </TableCell>
        <TableCell align="left">
            <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                {`${data.one_day?.low}`}
            </Typography>
        </TableCell>
        <TableCell align="left">
            <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                {`${data.market_cap}`}
            </Typography>
        </TableCell>
        <TableCell align="left">
            <Typography gutterBottom variant="body2" data-testid="moreData" component="a" style={{ fontWeight: 'bold', fontSize: '12px', cursor: 'pointer', textDecoration: 'underline', color: 'rgba(1,162,78,255)' }} onClick={(event: any) => {
                handleMorePopup(row);
            }}>
                {`More`}
            </Typography>
        </TableCell>
    </>
    )
}

const nameColumn = (row: any, handleMorePopup: any) => {
    const data = row.crypto_data ? row.crypto_data : row
    return (<CardHeader
        avatar={
            <Avatar
                alt="Remy Sharp"
                style={{ width: '32px', height: '32px', cursor: 'pointer' }}
                src={data.image_url}
                onClick={() => handleMorePopup(row)}
            >
            </Avatar>
        }
        title={
            <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                {`${data.name}`}
            </Typography>
        }
        subheader={
            <Typography gutterBottom variant="body2" component="div" style={{ color: '#c6cfca', fontWeight: 'bold', fontSize: '12px' }}>
                {data.symbol}
            </Typography>
        }
        style={{ padding: '0px' }}
    />)
}

const NamePriceFields = ({ idx, row, handleMorePopup }: any) => {
    const data = row.crypto_data ? row.crypto_data : row
    return (
        <>
            <TableCell component="th" scope="row">
                {parseInt(idx) + 1}
            </TableCell>
            <TableCell align="left">
                {nameColumn(row, handleMorePopup)}
            </TableCell>
            <TableCell align="left">
                <div style={{ display: 'flex', flexDirection: 'column', whiteSpace: 'nowrap' }}>
                    <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                        {`${data.price}`}
                    </Typography>
                    <div
                        style={{ display: 'flex', alignItems: 'center', marginLeft: '-5px' }}
                    >
                        {data.percentage_change_24h?.toString()?.charAt(0) === "-" ?  <ArrowDropDownIcon style={{ color: '#E86A3F',  fontSize: '12px' }} /> : <ArrowDropUpIcon style={{ color: '#01A24E',  fontSize: '12px' }} />}
                        <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px', color: data.percentage_change_24h?.toString()?.charAt(0) === "-" ? '#E86A3F' : '#01A24E' }}>
                            {`${typeof(data.percentage_change_24h) === "string" ? removePlusMinusSymbol(data.percentage_change_24h) + " (24H)" : removePlusMinusSymbol(data.percentage_change_24h?.toFixed(2)) + '% (24H)'}`}
                        </Typography>
                    </div>
                </div>
            </TableCell>
        </>
    )
}

const BearishBullishDoublePattern = (props: DoublePattern) => {
    const { first, firstTimestamp, mid, midTimestamp, second, secondTimestamp, classes } = props;
    return (
        <>
            <TableCell align="left">
                <Typography gutterBottom variant="body2" component="div" className={classes.tableContent}>
                    {(first && firstTimestamp) && `$${first.toFixed(6)} /\n ${firstTimestamp}`}
                </Typography>
            </TableCell>

            <TableCell align="left">
                <Typography gutterBottom variant="body2" component="div" className={classes.tableContent}>
                    {(mid && midTimestamp) && `$${mid.toFixed(6)} /\n ${midTimestamp}`}
                </Typography>
            </TableCell>

            <TableCell align="left">
                <Typography gutterBottom variant="body2" component="div" className={classes.tableContent}>
                    {(second && secondTimestamp) && `$${second.toFixed(6)} /\n ${secondTimestamp}`}
                </Typography>
            </TableCell>
        </>
    )
}

const BearishBullishTriplePattern = (props: TriplePattern) => {
    const { first, firstTimestamp, firstMid, firstMidTimestamp, second, secondTimestamp, secondMid,
        secondMidTimestamp, third, thirdTimestamp, classes } = props;
    return (
        <>
            <TableCell align="left">
                <Typography gutterBottom variant="body2" component="div" className={classes.tableContent} >
                    {(first && firstTimestamp) && `$${first.toFixed(6)} /\n ${firstTimestamp}`}
                </Typography>
            </TableCell>
            <TableCell align="left">
                <Typography gutterBottom variant="body2" component="div" className={classes.tableContent} >
                    {(firstMid && firstMidTimestamp) && `$${firstMid.toFixed(6)} /\n ${firstMidTimestamp}`}
                </Typography>
            </TableCell>
            <TableCell align="left">
                <Typography gutterBottom variant="body2" component="div" className={classes.tableContent}>
                    {(second && secondTimestamp) && `$${second.toFixed(6)} /\n ${secondTimestamp}`}
                </Typography>
            </TableCell>
            <TableCell align="left">
                <Typography gutterBottom variant="body2" component="div" className={classes.tableContent}>
                    {(secondMid && secondMidTimestamp) && `$${secondMid.toFixed(6)} /\n ${secondMidTimestamp}`}
                </Typography>
            </TableCell>
            <TableCell align="left">
                <Typography gutterBottom variant="body2" component="div" className={classes.tableContent}>
                    {(third && thirdTimestamp) && `$${third.toFixed(6)} /\n ${thirdTimestamp}`}
                </Typography>
            </TableCell>
        </>
    )
}

const ContinuationPatterns = (props: ContinuationPatterns) => {
    const { upper_first, upper_last, lower_first, lower_last,
        upper_first_timestamp, upper_last_timestamp, lower_first_timestamp, lower_last_timestamp, classes } = props;

    return (
        <>
            <TableCell align="left">
                <Typography gutterBottom variant="body2" component="div" className={classes.tableContent}>
                    {(upper_first && upper_first_timestamp) && `$${upper_first.toFixed(6)} /\n ${upper_first_timestamp}`}
                </Typography>
            </TableCell>
            <TableCell align="left">
                <Typography gutterBottom variant="body2" component="div" className={classes.tableContent}>
                    {(upper_last && upper_last_timestamp) && `$${upper_last.toFixed(6)} /\n ${upper_last_timestamp}`}
                </Typography>
            </TableCell>
            <TableCell align="left">
                <Typography gutterBottom variant="body2" component="div" className={classes.tableContent}>
                    {(lower_first && lower_first_timestamp) && `$${lower_first.toFixed(6)} /\n ${lower_first_timestamp}`}
                </Typography>
            </TableCell>
            <TableCell align="left">
                <Typography gutterBottom variant="body2" component="div" className={classes.tableContent}>
                    {(lower_last && lower_last_timestamp) && `$${lower_last.toFixed(6)} /\n ${lower_last_timestamp}`}
                </Typography>
            </TableCell>
        </>
    )
}

const BearishBullishHeadShoulderPattern = (props: HeadShoulderPattern) => {
    const { shoulder1, shoulder1Timestamp, firstMid, firstMidTimestamp, head, headTimestamp,
        secondMid, secondMidTimestamp, shoulder2, shoulder2Timestamp, classes } = props;

    return (
        <>
            <TableCell align="left">
                <Typography gutterBottom variant="body2" component="div" className={classes.tableContent} >
                    {(shoulder1 && shoulder1Timestamp) && `$${shoulder1.toFixed(6)} /\n ${shoulder1Timestamp}`}
                </Typography>
            </TableCell>
            <TableCell align="left">
                <Typography gutterBottom variant="body2" component="div" className={classes.tableContent}>
                    {(firstMid && firstMidTimestamp) && `$${firstMid.toFixed(6)} /\n ${firstMidTimestamp}`}
                </Typography>
            </TableCell>
            <TableCell align="left">
                <Typography gutterBottom variant="body2" component="div" className={classes.tableContent}>
                    {(head && headTimestamp) && `$${head.toFixed(6)} /\n ${headTimestamp}`}
                </Typography>
            </TableCell>
            <TableCell align="left">
                <Typography gutterBottom variant="body2" component="div" className={classes.tableContent} >
                    {(secondMid && secondMidTimestamp) && `$${secondMid.toFixed(6)} /\n ${secondMidTimestamp}`}
                </Typography>
            </TableCell>
            <TableCell align="left">
                <Typography gutterBottom variant="body2" component="div" className={classes.tableContent}>
                    {(shoulder2 && shoulder2Timestamp) && `$${shoulder2.toFixed(6)} /\n ${shoulder2Timestamp}`}
                </Typography>
            </TableCell>
        </>
    )
}

export {
    PriceActionTableHeadings, CommonFields, NamePriceFields, ContinuationPatterns,
    BearishBullishDoublePattern, BearishBullishTriplePattern, BearishBullishHeadShoulderPattern
}

import React from 'react';
import { Text as RNText, TextProps } from 'react-native';

interface CustomTextProps extends TextProps {
  allowFontScaling?: boolean;
}

const CustomText: React.FC<CustomTextProps> = (props) => {
  // If allowFontScaling is not provided, set it to false by default
  const { allowFontScaling = false, ...rest } = props;

  return <RNText {...rest} allowFontScaling={allowFontScaling} />;
};

export default CustomText;

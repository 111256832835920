import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    BarController
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: boolean;
    token: any;
    CryptoCategories: any;
    CryptoData: any;
    SelectedCategoryItem: any;
    ShowMore: boolean;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class CryptoAssetTableWebController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    getSelectedCategoryCryptoApiCallId: string = '';
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            loading: false,
            token: localStorage.getItem('token') || "",
            CryptoCategories: [
                { name: 'Trending', key: 'trending_coins' },
                { name: 'Top Gainers', key: 'top_gainers' },
                { name: 'Top Losers', key: 'top_losers' },
                { name: 'Recently Added', key: 'recently_added' }
            ],
            CryptoData: [],
            ShowMore: false,
            SelectedCategoryItem: '',
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            switch (apiRequestCallId) {
                case this.getSelectedCategoryCryptoApiCallId:
                    this.setState({
                        loading: false,
                        CryptoData: responseJson.data
                    });
                    break;
            }

        }
        // Customizable Area End
    }

    async componentDidMount() {
        // Customizable Area Start
        ChartJS.register(
            CategoryScale,
            LinearScale,
            BarElement,
            BarController,
            PointElement,
            LineElement,
            Title,
            Tooltip,
            Legend,
            Filler,
            ChartDataLabels
        );
        const urlParams = new URLSearchParams(window.location.search);
        const type = urlParams.get('type');
        this.setState({
            SelectedCategoryItem: type
        })
        this.getSelectedCategoryCryptoDetails({ key: type });
        
        // Customizable Area End
    }

    // Customizable Area Start

    loadMoreData = () => {
        this.setState(() => ({
            ShowMore: true
        }));
    }

    loadLessData = () => {
        this.setState(() => ({
            ShowMore: false
        }));
    }

    getSelectedCategoryCryptoDetails = (category: any) => {
        this.setState({ loading: true, SelectedCategoryItem: category.key, ShowMore: false });
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getSelectedCategoryCryptoApiCallId = requestMessage.messageId;
        const path = "bx_block_dashboard/" + category.key;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), path);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    // Customizable Area End
}

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import AsyncStorage from '@react-native-async-storage/async-storage';
type ItemActionFunction = () => void;
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    route?: any;
    onClick?: any;
    onClikcRank?: any;
    onClickMarCap?: any;
    value?:any;
    check?:any;
    save?:any;
    saveApply?:any;
    mode?:any
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    data: any;
    token: string;
    filterItems: any;
    arrayHolder: any;
    defaultButtonText: any;
    fundamentalArray: any;
    addMoreFilter: boolean;
    addMoreBtn: boolean;
    rankArray: any;
    topText: boolean;
    addMore: boolean;
    fundamentalArraySec: any
    industryArray: any;
    categoryArray: any;
    categoryLabel: any;
    socialEngArray: any;
    socialEngLabel: any;
    market_capitalization: boolean;
    transaction_count: boolean;
    transacion_value: boolean
    feesPaidArray: any;
    active_address: boolean;
    hash_rate: boolean;
    net_value_transaction_ratio: boolean;
    market_value_to_realized_value: boolean;
    subsocialEngLabel: any;
    catId: any;
    defaultVolumeButtonText: any;
    market_from: any
    market_to: any
    transactionFrom: any
    transactionTo: any
    activeFrom: any
    activeTo: any
    transactionValueFrom: any
    transactionValueTo: any
    hashrateFrom: any
    hashrateTo: any
    nateRatioFrom: any
    nateRatioTo: any
    marketValueFrom: any
    marketValueTo: any
    customValue: any
    rankLabel: any
    subRankLabel: any
    industryLabel: any
    subIndusrtyLable: any
    subCategoryLabel: any
    avgVolumeLabel: any
    isLoading: boolean
    timeFameIndLabel: any
    backColor:string
}

interface SS {
    id: any;
}

export default class FundamentalController extends BlockComponent<
    Props,
    S,
    SS
> {
    getProductApiCallId: any;
    // Customizable Area Start
    dropdownRef: any;
    selectOptionTxt: string;
    SaveBtn: string;
    FiltersTxt: string;
    rank10apiId: string;
    categoriesApiId: string;
    getAllcatDataID: string;
    getSocailEngMentID: string;
    getMarketCapID: string;
    secondTextInput: any;
    socialEngBySymbolId: any;
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            data: "",
            token: "",
            timeFameIndLabel: '',
            arrayHolder: [],
            filterItems: '',
            catId: '',
            fundamentalArray: [
                "Rank", "Category", "Market Capitalization"
            ],
            rankArray: [],
            fundamentalArraySec: [],
            industryArray: [],
            categoryLabel: '',
            categoryArray: [],
            socialEngArray: [],
            socialEngLabel: '',
            subsocialEngLabel: '',
            market_capitalization: false,
            transaction_count: false,
            transacion_value: false,
            feesPaidArray: [],
            active_address: false,
            hash_rate: false,
            net_value_transaction_ratio: false,
            market_value_to_realized_value: false,
            defaultButtonText: 'Select a filter',
            defaultVolumeButtonText: "Select Volume Option",

            topText: false,
            addMoreFilter: false,
            addMoreBtn: false,
            addMore: false,
            market_from: '',
            market_to: '',
            transactionFrom: '',
            transactionTo: '',
            activeFrom: '',
            activeTo: '',
            transactionValueFrom: '',
            transactionValueTo: '',
            hashrateFrom: '',
            hashrateTo: '',
            nateRatioFrom: '',
            nateRatioTo: '',
            marketValueFrom: '',
            marketValueTo: '',
            customValue: '',
            rankLabel: '',
            subRankLabel: '',
            industryLabel: '',
            subIndusrtyLable: '',
            subCategoryLabel: '',
            avgVolumeLabel: '',
            isLoading: false,
            backColor:''

        };
        this.selectOptionTxt = configJSON.selectOptionTxt;
        this.FiltersTxt = configJSON.FiltersTxt;
        this.SaveBtn = configJSON.SaveBtn;

        this.rank10apiId = '';
        this.categoriesApiId = '';
        this.getAllcatDataID = '';
        this.getSocailEngMentID = '';
        this.getMarketCapID = '';
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        this.setState({backColor:this.state.timeFameIndLabel != '' ? '#000' : 'gray'})
        this.props.navigation.addListener("focus", () => {
        });
        // Customizable Area Start
    }

    setDataForApiCall = (apiRequestCallId: string, data: any) => {
        switch (apiRequestCallId) {
          case this.getAllcatDataID:
            if (data && data.length !== 0) {
              const temp = data.map((item: any) => ({
                name: item.name,
                id: item.id,
              }));
              this.setState({ categoryArray: temp }, () => {
                console.log('line 695>>', this.state.categoryArray);
              });
            }
            break;
          case this.getSocailEngMentID:
            if (data && data.length !== 0) {
              const temp = data.map((item: any) => item.name);
              this.setState({ socialEngArray: temp }, () => {
                console.log('line 695>>', this.state.socialEngArray);
              });
            }
            break;
          default:
            break;
        }
    };

    handleErrorResponse = (errorResponse: string) => {
        this.showAlert('Error', errorResponse);
        this.parseApiCatchErrorResponse(errorResponse);
    };
      
    handleProductApi = (responseJson:any,message:any)=>{
        if (responseJson && !responseJson.errors && responseJson.data) {
          this.setState({ arrayHolder: responseJson.data });
          const params = this.props.navigation.state.params;
          if (params?.data && params.data.length > 0) {
            this.setState({ data: params.data });
          } else {
            this.showAlert('Error', 'No data Found');
          }
        } else {
          const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
          this.handleErrorResponse(errorResponse);
        }
    }
      
    handleProductApiCall=(responseJson:any,message:any, apiRequestCallId:any)=>{
        if (this.getProductApiCallId !== null && this.getProductApiCallId === apiRequestCallId) {
          this.handleProductApi(responseJson,message)
        } else {
          if (!responseJson || responseJson.errors) {
            this.parseApiErrorResponse(responseJson);
          } else {
            this.setDataForApiCall(apiRequestCallId, responseJson.data);
          }
        }
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        if (getName(MessageEnum.SessionResponseMessage) === message.id) {
        const token = message.getData(getName(MessageEnum.SessionResponseToken));
        this.setState({ token });
        this.getListRequest(token);
        } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id && apiRequestCallId) {
        this.handleProductApiCall(responseJson,message,apiRequestCallId);
        }
        // Customizable Area End
    }
    getListRequest = (token: any) => {

        const header = {
            "Content-Type": configJSON.productApiContentType,
            token: token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getProductApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.productAPiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeGet
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    // Customizable Area Start
    careDownUp(isOpened:boolean){
      const data =isOpened ? "caretup" : "caretdown"
      return data
    }

    socialEngText(){
      const socialEnfVal = this.state.subsocialEngLabel !='' ? this.state.subsocialEngLabel : 'Social Engagement'
      return socialEnfVal
    }

    categoryLabelColor(){
      const catLabel = this.state.subCategoryLabel ? "#F7F7F7" : '#fff'
      return catLabel
    }

    categoryLabel(){
      const subCatLabel = this.state.subCategoryLabel !=''?this.state.subCategoryLabel:this.state.categoryLabel
      return subCatLabel
    }

    subCategoryLabel(){
      const subCatLabel = this.state.subCategoryLabel !=''?this.state.subCategoryLabel:''
      return subCatLabel
    }

    RankLabel(){
     const rankLabel = this.state.subRankLabel?this.state.subRankLabel:'Rank'
     return rankLabel
    }
    // Fundamenta start 
    onChange(index: any, selectedItem: any) {
        const { fundamentalArray } = this.state;
      
        // Remove the unnecessary loop
        const itemIndex = fundamentalArray.indexOf(selectedItem);
        if (itemIndex > -1) {
          fundamentalArray.splice(itemIndex, 1);
        }
      
        // Use object mapping for selected item actions
        const itemActions:{[key: string]: ItemActionFunction} = {
          'Rank': () => {
            const temp = ['Top 10', 'Top 50', 'Custom'];
            this.setState({ rankArray: temp });
          },
          'Top 10': () => {
            this.setState({
              subRankLabel: selectedItem,
              addMoreBtn: true,
              rankLabel: 'Rank'
            }, () => {
              this.top10RankFd(10);
              this.props.onClikcRank(10, 'Rank');
            });
          },
          'Top 50': () => {
            this.setState({
              subRankLabel: selectedItem,
              addMoreBtn: true,
              rankLabel: 'Rank'
            }, () => {
              this.top10RankFd(50);
              this.props.onClikcRank(50, 'Rank');
            });
          },
          'Custom': () => {
            this.setState({
              subRankLabel: selectedItem,
              addMoreBtn: true,
              rankLabel: 'Rank'
            });
          },
          'Industry': () => {
            const temp = ["industry1", "industry2"];
            this.setState({
              industryArray: temp,
              industryLabel: selectedItem,
            });
          },
          'Category': () => {
            this.setState({ categoryLabel: selectedItem ,isLoading:false }, () => {
              this.getAllcatListFd();
            });
          },
          'Social Engagement': () => {
            this.setState({ socialEngLabel: selectedItem });
            this.socailEngMentFd();
          },
          'Social Engagement1': () => {
            this.setState({ socialEngLabel: 'Social Engagement', addMoreBtn: true });
          },
          'Social Engagement2': () => {
            this.setState({ socialEngLabel: 'Social Engagement', addMoreBtn: true });
          },
          'Market Capitalization': () => {
            this.setState({ market_capitalization: true, addMoreBtn: true });
          },
          'Transaction Count': () => {
            this.setState({ transaction_count: true, addMoreBtn: true });
          },
          'Transaction Value': () => {
            this.setState({ transacion_value: true, addMoreBtn: true });
          },
          'Fees Paid': () => {
            const temp = ["Top 10", "Top 50"];
            this.setState({ feesPaidArray: temp });
          },
          'Active Address': () => {
            this.setState({ active_address: true, addMoreBtn: true });
          },
          'Hash Rate': () => {
            this.setState({ hash_rate: true, addMoreBtn: true });
          },
          'Net Value Transaction Ratio': () => {
            this.setState({ net_value_transaction_ratio: true, addMoreBtn: true });
          },
          'Market Value to Realized Value': () => {
            this.setState({ market_value_to_realized_value: true, addMoreBtn: true });
          }
        };
      
        // Check if the selectedItem exists in the itemActions object and execute the corresponding action
        if (itemActions[selectedItem]) {
          itemActions[selectedItem]();
        }
      }
      

    onClickClearDerivativesFd() {
        this.setState({
            rankLabel: '',
            subRankLabel: '',
            addMoreBtn: false,
            defaultButtonText: '',
        })
    }
    onClickClearindustry() {
        this.setState({
            industryLabel: '',
            subIndusrtyLable: '',
            addMoreBtn: false
        })
    }

    onChangeCategories(selectedItem: any, index: any,relodFilter?:boolean) {
      let id:any;
      if(!relodFilter){
        if(this.state?.categoryArray){
           id = this.state?.categoryArray[index]?.id
        }
        else{
          id = undefined
        }
        this.setState({ subCategoryLabel: selectedItem.name, addMoreBtn: true, categoryLabel: 'Category', catId: id }, () => {
      })
      return;
      }
      this.setState({ subCategoryLabel: selectedItem.name, addMoreBtn: true, categoryLabel: 'Category', catId: id }, () => {
    })
        

    }
    onChangeSocialEng(selectedItem: any, index: any) {

        this.setState({ subsocialEngLabel: selectedItem, addMoreBtn: true, socialEngLabel: 'Social Engagement' }, () => {
            this.props.onClikcRank(this.state.subsocialEngLabel, "SocialEngagement")
        })

    }

    tokenFd = async () => {
        return AsyncStorage.getItem("userToken");
    }
    async top10RankFd(rank: any) {
      const tokenData = await AsyncStorage.getItem("userToken");
        const header = {
            token: tokenData
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.rank10apiId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.RankApiEndPoint + rank
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeGet
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true
    }
    async getAllcatListFd() {
        this.setState({ isLoading: true })
        const header = {
            token: this.tokenFd()
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getAllcatDataID = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.GetCatApiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeGet
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    async socailEngMentFd() {
        this.setState({ isLoading: true })
        const header = {
            token: this.tokenFd()
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getSocailEngMentID = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.SocialEngamentApiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeGet
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Fundamental End 
    getColorTheme = (mode: any, color1: any, color2: any) => {
      return this.props.mode === mode ? color1 : color2
    }
    getByCondition = (condition: any, match: any, result1: any, result2: any) => {
      return condition === match ? result1 : result2
    }
    renderByCondition = (condition: any, result1: any, result2?: any) => {
      return condition ? result1 : result2 || null
    }
    // Customizable Area End
}

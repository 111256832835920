import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from 'react-toastify';
// Customizable Area Start
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: boolean;
    token: any;
    assestsList: any;
    anchorEl: any;
    selectedAsset: any;
    triggerPercentList: any;
    selectedDip: any;
    dipAlert: boolean;
    addAlertList: any;
    dipAlertList: any;
    loadingArray: any;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class DipAlertWebController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    getPortfolioCryptosApiCallId: string = "";
    getDipApiCallId: string = "";
    addOrUpdateDipApiCallId: string = "";
    deleteAlertApiCallId: string = '';
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            loading: true,
            token: localStorage.getItem('token') || "",
            assestsList: [],
            anchorEl: null,
            selectedAsset: null,
            triggerPercentList: [5, 0],
            selectedDip: null,
            dipAlert: false,
            addAlertList: [],
            dipAlertList: [],
            loadingArray: []
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {

        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.getPortfolioCryptosApiCallId) {
                if (responseJson?.data) {
                    this.setState({
                        addAlertList: responseJson?.data
                    })
                }
            } else if (apiRequestCallId === this.getDipApiCallId) {
                const newArr = this.state.loadingArray.filter((item: any) => item !== 'getDip')
                this.setState({ loadingArray: newArr });
                if (responseJson?.data) {
                    this.setState({
                        dipAlertList: responseJson?.data
                    });
                }
            } else if (apiRequestCallId === this.addOrUpdateDipApiCallId) {
                const newArr = this.state.loadingArray.filter((item: any) => item !== 'addUpdateDip')
                this.setState({ loadingArray: newArr, selectedAsset: null, dipAlertList: [] });
                if (responseJson?.data) {
                    toast.success("Dip Alert details saved successfully");
                    this.getDipDetails();
                } else {
                    toast.error("Failed to save dip alert details");
                }
            } else if (apiRequestCallId === this.deleteAlertApiCallId) {
                const newArr = this.state.loadingArray.filter((item: any) => item !== 'deleteDip')
                this.setState({ loadingArray: newArr, anchorEl: null, selectedAsset: null, dipAlertList: [] });
                if (responseJson?.message) {
                    toast.success(responseJson?.message);
                    this.getDipDetails();
                } else {
                    if (responseJson?.errors) {
                        toast.error(responseJson?.errors[0]);
                    } else {
                        toast.error("Failed to delete asset details");
                    }
                }
            }
        }
        // Customizable Area End
    }
    // Customizable Area Start
    async componentDidMount() {
        this.getDipDetails();
        this.getPortfolioCryptosDetails();
    }

    getDipDetails = () => {
        this.setState({ loadingArray: [...this.state.loadingArray, 'getDip'] });
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getDipApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_watchlist2/dip_alerts");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    closeDipAlert = () => {
        this.setState({
            dipAlert: false,
            selectedAsset: null
        })
    }

    addOrUpdateDip = (values: any) => {
        this.setState({ loadingArray: [...this.state.loadingArray, 'addUpdateDip'], dipAlert: false });
        const header = { "Content-Type": "application/json", "token": this.state.token };
        const httpBody = {
            data: {
                dip_number: values.dip_number != 5 ? values.custom_dip_number : values.dip_number,
                crypto_id: values.crypto_id,
            }
        };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.addOrUpdateDipApiCallId = requestMessage.messageId;
        if (this.state.selectedAsset == null) {
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_watchlist2/dip_alerts");
        } else {
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_watchlist2/dip_alerts/" + this.state.selectedAsset.id);
        }
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getPortfolioCryptosDetails() {
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getPortfolioCryptosApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_dashboard/portfolio_cryptos");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    deleteAlertDetails() {
        this.setState({ loadingArray: [...this.state.loadingArray, 'deleteDip'] });
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.deleteAlertApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_watchlist2/dip_alerts/" + this.state.selectedAsset?.id);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "DELETE");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleMenuIconClick = (event: any, row: any) => {
        this.setState({
            anchorEl: event.currentTarget,
            selectedAsset: row
        });
    };

    handleMenuIconClose = () => {
        this.setState({
            anchorEl: null,
            selectedAsset: null
        });
    };
    // Customizable Area End
}

import React from "react";
// Customizable Area Start
import {
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
    FlatList,
    Dimensions, TextInput
} from "react-native";
const { width } = Dimensions.get("screen");
import FilteritemsController, { Props } from "./FilteritemsController";
import NewDropDown from '../../../components/src/NewDropDown';
import SelectDropdown from 'react-native-select-dropdown'
import Icon from "react-native-vector-icons/AntDesign"
import CustomText from "../../../components/src/text";
import { theme } from "../../../framework/src/theme";
// Customizable Area End
export default class  Indicators extends FilteritemsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        const RsiFunc = (object: any) => {
            const setLabel = (selectedItem: any) => {
                // alert(selectedItem)
                this.setState({ rsiLabel: selectedItem, })
                this.onClickIndicator(0, selectedItem);
            }
            const setPeriod = (text: any) => {
                this.setState({
                    periodInd: text,
                })
            }
            const setradio = (radio: any) => {
                this.setState({ radioAbove: radio, })
            }
            const setLength = (text: any) => {
                this.setState({
                    lengthInd: text,
                })
            }
            const setTime = (selectedItem: any) => {
                this.setState({
                    timeFameIndLabel: selectedItem,
                })
            }
            const setDivergence = (selectedItem: any) => {
                this.setState({ RsiDivergenceItem: selectedItem })
            }
            const rsiObject: any = {
                "indicator_screen_option": setLabel,
                "indicator_period": setPeriod,
                "indicator_trending_period": function () { },
                "indicator_above_below": setradio,
                "indicator_trending_up_down": function () { },
                "indicator_level_centerline": setLength,
                "indicator_divergence_crossover": setDivergence,
                "indicator_moving_average_type": function () { },
                "indicator_time_frame": setTime,
                "indicator_main_option": function () { }
            }
            this.onClickBox(this.state.indicatorsArray[0])
            this.props.onClickBox1(this.state.indicatorsArray[0])
            // alert(JSON.stringify(this.props.indicatorValue))
            for (const key in object) {
                if (rsiObject.hasOwnProperty(key) && object[key]) {
                    rsiObject[key](object[key]);
                }
            }
        }
        const MfiFunc = (object: any) => {
            const setMfiLabel = (selectedItem: any) => {
                this.setState({ mfilabel: selectedItem }, () => { })
                this.onClickMfi(0, selectedItem);
            }
            const setMfiPeriod = (text: any) => {
                this.setState({
                    mfiPeriod: text,
                })
            }
            const setMfiradio = (radio: any) => {
                this.setState({ mfiradioAbove: radio, })
            }
            const setMfiLength = (text: any) => {
                this.setState({ mfiLength: text, })
            }
            const setMfiTime = (selectedItem: any) => {
                this.setState({ mfiTimeFrame: selectedItem, })
            }
            const setMfiDivergence = (selectedItem: any) => {
                this.setState({ MfiDivergenceItem: selectedItem, })
            }
            const mfiObject: any = {
                "indicator_screen_option": setMfiLabel,
                "indicator_period": setMfiPeriod,
                "indicator_above_below": setMfiradio,
                "indicator_level_centerline": setMfiLength,
                "indicator_divergence_crossover": setMfiDivergence,
                "indicator_time_frame": setMfiTime,
            }
            this.onClickBox(this.state.indicatorsArray[1])
            this.props.onClickBox1(this.state.indicatorsArray[1])
            for (const key in object) {
                if (mfiObject.hasOwnProperty(key) && object[key]) {
                    mfiObject[key](object[key]);
                }
            }
        }
        const StocFunc = (object: any) => {
            const setLabel = (selectedItem: any) => {
                this.setState({ stochasticlabel: selectedItem })
                this.onClickStochastic(0, selectedItem);
            }
            const setPeriod = (text: any) => {
                this.setState({
                    stochasticPeriod: text,
                })
            }
            const setradio = (radio: any) => {
                this.setState({ stochasticradioAbove: radio })
            }
            const setLength = (text: any) => {
                this.setState({
                    stochasticLength: text,
                })
            }
            const setTime = (selectedItem: any) => {
                this.setState({ stochasticTimeFrame: selectedItem, })
            }
            const setDivergence = (selectedItem: any) => {
                this.setState({ StoDivergenceItem: selectedItem, })
            }
            const stochasticLabel: any = {
                "indicator_screen_option": setLabel,
                "indicator_period": setPeriod,
                "indicator_above_below": setradio,
                "indicator_level_centerline": setLength,
                "indicator_divergence_crossover": setDivergence,
                "indicator_time_frame": setTime,
            }
            this.onClickBox(this.state.indicatorsArray[2])
            this.props.onClickBox1(this.state.indicatorsArray[2])
            for (const key in object) {
                if (stochasticLabel.hasOwnProperty(key) && object[key]) {
                    stochasticLabel[key](object[key]);
                }
            }
        }
        const CciFunc = (object: any) => {
            const setLabel = (selectedItem: any) => {
                this.setState({ ccilabel: selectedItem })
                this.onClickcci(0, selectedItem);
            }
            const setPeriod = (text: any) => {
                this.setState({
                    cciPeriod: text,
                })
            }
            const setradio = (radio: any) => {
                this.setState({
                    cciradioAbove: radio,
                })
            }
            const setTime = (selectedItem: any) => {
                this.setState({ cciTimeFrame: selectedItem, })
            }
            const setDivergence = (selectedItem: any) => {
                this.setState({ cciDivergenceItem: selectedItem, })
            }
            const cciObject: any = {
                "indicator_screen_option": setLabel,
                "indicator_period": setPeriod,
                "indicator_above_below": setradio,
                "indicator_divergence_crossover": setDivergence,
                "indicator_time_frame": setTime,
            }
            this.onClickBox(this.state.indicatorsArray[3])
            this.props.onClickBox1(this.state.indicatorsArray[3])
            for (const key in object) {
                if (cciObject.hasOwnProperty(key) && object[key]) {
                    cciObject[key](object[key]);
                }
            }
        }
        const AvgFunc = (object: any) => {
            const setavglabel = (selectedItem: any) => {
                this.setState({ mvAvglabel: selectedItem })
                this.onClickMvArray(0, selectedItem);
            }
            const setmovingAvgLabel = (selectedItem: any) => {
                this.setState({ movingAvgLabel: selectedItem })
                this.onClickMvArray(0, selectedItem);
            }

            const setPeriod = (text: any) => {
                this.setState({
                    mvAvgPeriod: text,
                })
            }
            const setLength = (text: any) => {
                this.setState({ crossOverValue: text })
            }
            const setTime = (selectedItem: any) => {
                this.setState({ mvAvgTimeFrame: selectedItem, })
            }
            const avgObject: any = {
                "indicator_screen_option": setavglabel,
                "indicator_period": setPeriod,
                "indicator_divergence_crossover": setLength,
                "indicator_moving_average_type": setmovingAvgLabel,
                "indicator_time_frame": setTime,
            }
            this.onClickBox(this.state.indicatorsArray[4])
            this.props.onClickBox1(this.state.indicatorsArray[4])
            for (const key in object) {
                if (avgObject.hasOwnProperty(key) && object[key]) {
                    avgObject[key](object[key]);
                }
            }
        }
        const MacdFunc = (object: any) => {
            const setLabel = (selectedItem: any) => {
                this.setState({ macdlabel: selectedItem })
                this.onClickMacd(0, selectedItem);
            }
            const setPeriod = (text: any) => {
                this.setState({
                    macdPeriod: text,
                })
            }
            const setradio = (radio: any) => {
                this.setState({ macdradioAbove: radio, })
            }

            const setTime = (selectedItem: any) => {
                this.setState({ macdTimeFrame: selectedItem, })
            }
            const setDivergence = (selectedItem: any) => {
                this.setState({ macdDivergenceitem: selectedItem, })
            }
            const macdObject: any = {
                "indicator_screen_option": setLabel,
                "indicator_period": setPeriod,
                "indicator_trending_period": "",
                "indicator_above_below": setradio,
                "indicator_trending_up_down": "",
                "indicator_level_centerline": "",
                "indicator_divergence_crossover": setDivergence,
                "indicator_moving_average_type": "",
                "indicator_time_frame": setTime,
                "indicator_main_option": ""
            }
            this.onClickBox(this.state.indicatorsArray[5])
            this.props.onClickBox1(this.state.indicatorsArray[5])
            for (const key in object) {
                if (macdObject.hasOwnProperty(key) && object[key]) {
                    macdObject[key](object[key]);
                }
            }
        }
        const AdxFunc = (object: any) => {
            const setLabel = (selectedItem: any) => {
                this.setState({ adxlabel: selectedItem })
                this.onClickAdx(0, selectedItem);
            }
            const setPeriod = (text: any) => {
                this.setState({
                    adxPeriod: text,
                })
            }
            const setradio = (radio: any) => {
                this.setState({ adxradioAbove: radio })
            }
            const setLength = (text: any) => {
                this.setState({
                    adxLength: text,
                })
            }
            const setTime = (selectedItem: any) => {
                this.setState({ adxTimeFrame: selectedItem, })
            }
            const adxObject: any = {
                "indicator_screen_option": setLabel,
                "indicator_period": setPeriod,
                "indicator_trending_period": "",
                "indicator_above_below": setradio,
                "indicator_trending_up_down": "",
                "indicator_level_centerline": setLength,
                "indicator_divergence_crossover": "",
                "indicator_moving_average_type": "",
                "indicator_time_frame": setTime,
                "indicator_main_option": ""
            }
            this.onClickBox(this.state.indicatorsArray[6])
            this.props.onClickBox1(this.state.indicatorsArray[6])
            for (const key in object) {
                if (adxObject.hasOwnProperty(key) && object[key]) {
                    adxObject[key](object[key]);
                }
            }
        }
        const ObvFunc = (object: any) => {
            const setLabel = (selectedItem: any) => {
                this.setState({ obvlabel: selectedItem, })
                this.onClickobv(0, selectedItem);
            }
            const setPeriod = (text: any) => {
                this.setState({
                    obvPeriod: text,
                })
            }
            const setTime = (selectedItem: any) => {
                this.setState({ obvTimeFrame: selectedItem, })
            }
            const setDivergence = (selectedItem: any) => {
                this.setState({ divergenceLable: selectedItem, })
            }
            const obvObject: any = {
                "indicator_screen_option": setLabel,
                "indicator_period": setPeriod,
                "indicator_trending_period": "",
                "indicator_above_below": "",
                "indicator_trending_up_down": "",
                "indicator_level_centerline": "",
                "indicator_divergence_crossover": setDivergence,
                "indicator_moving_average_type": "",
                "indicator_time_frame": setTime,
                "indicator_main_option": ""
            }
            this.onClickBox(this.state.indicatorsArray[7])
            this.props.onClickBox1(this.state.indicatorsArray[7])
            for (const key in object) {
                if (obvObject.hasOwnProperty(key) && object[key]) {
                    obvObject[key](object[key]);
                }
            }
        }
        const CmfFunc = (object: any) => {
            const setlabel = (selectedItem: any) => {
                this.setState({ cmflabel: selectedItem, })
                this.onClickcmf(0, selectedItem);
            }
            const setDivergence = (selectedItem: any) => {
                // alert("ki")
                this.setState({ divergenceLablecmf: selectedItem, })
            }
            const setPeriod = (text: any) => {
                this.setState({
                    cmfPeriod: text,
                })
            }
            const setTime = (selectedItem: any) => {
                this.setState({ cmfTimeFrame: selectedItem, })
            }
            const cmfObject: any = {
                "indicator_screen_option": setlabel,
                "indicator_period": setPeriod,
                "indicator_trending_period": function () { },
                "indicator_above_below": function () { },
                "indicator_trending_up_down": function () { },
                "indicator_level_centerline": function () { },
                "indicator_divergence_crossover": setDivergence,
                "indicator_moving_average_type": function () { },
                "indicator_time_frame": setTime,
                "indicator_main_option": function () { }
            }
            this.onClickBox(this.state.indicatorsArray[8])
            this.props.onClickBox1(this.state.indicatorsArray[8])
            for (const key in object) {
                if (cmfObject.hasOwnProperty(key) && object[key]) {
                    cmfObject[key](object[key]);
                }
            }
        }
        const BollingerFunc = (object: any) => {
            const setLabel = (selectedItem: any) => {
                this.setState({ bollingerlabel: selectedItem })
                this.onClickBollinger(0, selectedItem);
            }
            const setPeriod = (text: any) => {
                this.setState({
                    bollingerPeriod: text,
                })
            }
            const setradio = (radio: any) => {
                // alert(radio+"      kop")
                this.setState({ radioAbove: radio })
            }
            const setTime = (selectedItem: any) => {
                this.setState({ bollingerTimeFrame: selectedItem, })
            }
            const bollingerObject: any = {
                "indicator_screen_option": setLabel,
                "indicator_period": setPeriod,
                "indicator_trending_period": "",
                "indicator_above_below": setradio,
                "indicator_trending_up_down": "",
                "indicator_level_centerline": "",
                "indicator_divergence_crossover": "",
                "indicator_moving_average_type": "",
                "indicator_time_frame": setTime,
                "indicator_main_option": ""
            }
            this.onClickBox(this.state.indicatorsArray[9])
            this.props.onClickBox1(this.state.indicatorsArray[9])
            for (const key in object) {
                if (bollingerObject.hasOwnProperty(key) && object[key]) {
                    bollingerObject[key](object[key]);
                }
            }
        }






        const AtrFunc = (object: object) => {
            const setMfiLabel = (selectedItem: any) => {
                this.setState({ atrlabel: selectedItem })
                this.onClickatr(0, selectedItem);
            }
            const setMfiPeriod = (text: any) => {
                this.setState({
                    atrPeriod: text,
                })
            }

            const setMfiTime = (selectedItem: any) => {
                this.setState({ atrTimeFrame: selectedItem })
            }
            const setMfiTrending = (selectedItem: any) => {
                this.setState({
                    trending_period: selectedItem,
                }, () => { })
            }
            const atrObject: any = {
                "indicator_screen_option": setMfiLabel,
                "indicator_period": setMfiPeriod,
                "indicator_trending_period": setMfiTrending,
                "indicator_above_below": function () { },
                "indicator_trending_up_down": function () { },
                "indicator_level_centerline": function () { },
                "indicator_divergence_crossover": function () { },
                "indicator_moving_average_type": function () { },
                "indicator_time_frame": setMfiTime,
                "indicator_main_option": function () { }
            }
            this.onClickBox(this.state.indicatorsArray[10])
            this.props.onClickBox1(this.state.indicatorsArray[10])
            for (const key in object) {
                //@ts-ignore
                if (atrObject.hasOwnProperty(key) && object[key]) {
                    //@ts-ignore
                    atrObject[key](object[key]);
                }
            }
        }
        const indicatorTypeobject = {
            "RSI": RsiFunc,
            "MFI": MfiFunc,
            "STOCHASTIC": StocFunc,
            "CCI": CciFunc,
            "Moving Average": AvgFunc,
            "MACD": MacdFunc,
            "ADX": AdxFunc,
            "OBV": ObvFunc,
            "CMF": CmfFunc,
            "Bollinger": BollingerFunc,
            "ATR": AtrFunc,
        }
        //@ts-ignore
        this.props.check && this.props.indicatorValue && indicatorTypeobject[this.props.indicatorValue.indicator_main_option](this.props.indicatorValue)
        // Customizable Area Start
    }
    renderItem = ({ item, index }: any) => {
        return (
            <>
                <View style={style.rowStyle}>
                    <TouchableOpacity
                    testID="onClickBox"
                        onPress={() => { this.onClickBox(item), this.props.onClickBox1(item) }}
                        style={[style.boxStyle, { backgroundColor: this.getByCondition(item.isSelect, true, '#499E5A', '#fff') }]}>
                        {
                            this.renderConditionalItem(
                                item.isSelect,
                                <Icon
                                    name="check"
                                    size={15}
                                    color={'#fff'}
                                />)
                        }
                    </TouchableOpacity>
                    <Text style={[style.nameStyle, { color: this.getByCondition(item.isSelect, true, '#499E5A', '#9DA4A2') }]}>{item.name}</Text>
                </View>
                {
                    this.renderConditionalItem(item.isSelect, this.pickerSwithc(item.name,this.state.isBasic))
                }
            </>
        )
    }
    pickerSwithc(name: any,isBasic:boolean) {
        if(isBasic){
            switch (name) {
                case "RSI":
                    return this.rsiRnderPicke()
                case "Moving Average":
                    return this.mvAvgRnderPicke()
    
                case "OBV":
                    return this.obvRender()
    
                case "ATR":
                    return this.atrRender()
                default:
            }
        }else{
            switch (name) {
                case "RSI":
                    return this.rsiRnderPicke()
                case "MFI":
                    return this.mfiRnderPicke()
                case "Stochastic":
                    return this.stochasticRnderPicke()
                case "CCI":
                    return this.cciRnderPicke()
                case "Moving Average":
                    return this.mvAvgRnderPicke()
                case "MACD":
                    return this.macdRnderPicke()
                case "ADX":
                    return this.adxRender()
                case "OBV":
                    return this.obvRender()
                case "CMF":
                    return this.cmfRender()
                case "Bollinger Bands":
                    return this.bollingerRender()
                case "ATR":
                    return this.atrRender()
                default:
                    alert("NUMBER NOT FOUND");
            }
        }
    
    }
    // rsi picker functions
    rsiPeriodPicker = () => {
        return (
            this.state.isDivergence &&
            <View style={[style.fromTextInput, { backgroundColor: this.getColorTheme('light', "#F7F7F7", "#000") }]}>
                {
                    this.state.periodInd != '' &&
                    <Text style={[style.subText, { color: this.getColorTheme('light', "gray", "white") }]}>{'Period'}</Text>

                }
                <TextInput
                testID="toString"
                    placeholder={'Period'}
                    placeholderTextColor={this.getColorTheme('light', "gray", "white")}
                    value={this.state.periodInd.toString()}
                    onChangeText={text => {
                        this.setState({
                            periodInd: text,
                            isRefresh: !this.state.isRefresh
                        })
                    }}

                    onEndEditing={() => this.props.selectPeriod(this.state.periodInd)}
                    autoCapitalize={'words'}
                    keyboardType={'numeric'}
                    style={{
                        fontWeight: 'bold', color: this.getColorTheme('light', "#000", "white"), backgroundColor:
                            this.getColorTheme('light', "#FFF", "#000")
                    }}
                />
            </View>
        )
    }

    rsiAboveBelowPick = () => {
        return (
            this.state.rsiCross &&
            <>
                <View style={{ marginTop: 15 }} />
                <View style={{ marginTop: 15, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: width / 1.1 }}>
                    <View style={style.radioView}>
                        <View style={style.radioViewBtn}>
                            <TouchableOpacity 
                            testID="backgroundColor"
                            style={[style.radioBtn, { backgroundColor: this.getByCondition(this.state.radioAbove, 'Above', '#4D9C5A', '#F6F6F6') }]}
                                onPress={() => this.setState({ radioAbove: 'Above', isRefresh: !this.state.isRefresh },
                                    () => {
                                        this.props.onClickRadio(this.state.radioAbove, this.state.isRefresh)
                                    })}
                            >
                            </TouchableOpacity>
                        </View>
                        <Text style={style.radioTtext}>{"Above"}</Text>
                    </View>
                    <View style={style.radioView}>
                        <View style={style.radioViewBtn}>
                            <TouchableOpacity
                            testID="radioAbove"
                                onPress={() => this.setState({ radioAbove: 'Below', isRefresh: !this.state.isRefresh }, () => {
                                    this.props.onClickRadio(this.state.radioAbove, this.state.isRefresh)
                                })}
                                style={[style.radioBtn, { backgroundColor: this.getByCondition(this.state.radioAbove, 'Below', '#4D9C5A', '#F6F6F6') }]}>
                            </TouchableOpacity>
                        </View>
                        <Text style={style.radioTtext}>{"Below"}</Text>
                    </View>
                </View>
                <View style={{ marginTop: 15 }} />
            </>
        )
    }
    rsiCenterLinePick = () => {
        return (
            this.state.isDivergence &&
            <>
                <View style={[style.fromTextInput,
                { backgroundColor: this.getColorTheme('light', "#F7F7F7", "#000") }]}>
                    {
                        this.state.lengthInd != '' &&
                        <Text style={[style.subText, {
                            color: this.getColorTheme('light', "gray", "white")
                        }]}>{this.state.rsiLabel == 'Cross Above/Below Centre Line' || this.state.rsiLabel == 'Cross Centre Line' ? 'Centre Line' : 'Level'}</Text>

                    }
                    <TextInput
                        placeholderTextColor={this.getColorTheme('light', "gray", "white")}
                        placeholder={this.state.rsiLabel == 'Cross Above/Below Centre Line' || this.state.rsiLabel == 'Cross Centre Line' ? 'Centre Line' : 'Level'}
                        value={this.state.lengthInd.toString()}
                        // {...this.txtInputCvv}
                        onChangeText={text => {
                            this.setState({
                                lengthInd: text,
                                isRefresh: !this.state.isRefresh
                            })
                        }}
                        autoCapitalize={'words'}
                        keyboardType={'numeric'}
                        onEndEditing={() => this.props.selectlengthInd1(this.state.lengthInd)}
                        style={{
                            fontWeight: 'bold',
                            color: this.getColorTheme('light', "#000", "white"),
                            backgroundColor: this.getColorTheme('light', "#FFF", "#000")
                        }}
                    />
                </View>
            </>
        )
    }
    rsiShowDivergence = () => {
        return (
            this.state.iscmf &&
            <View
                style={{
                    marginTop: 10,
                    backgroundColor: this.state.RsiDivergenceItem != '' ? this.getColorTheme("light", '#F7F7F7', "#000") :
                        this.getColorTheme("light", '#fff', theme.dark.secondaryColor)
                }}
            >
                {
                    this.state.RsiDivergenceItem == '' ? null :
                        <Text style={[style.subText, { marginLeft: 15, color: this.getColorTheme("light", "#000", "#FFF") }]}>{"Divergence"}</Text>
                }
                <NewDropDown placeholder={this.state.RsiDivergenceItem != '' ? this.state.RsiDivergenceItem : "Divergence"}
                 Upgrade={this.screennavigate}
                 isBasic={this.state.isBasic}
                 testID={'iscmf'}
                    data={this.state.rsiDivergencArray}
                    onSelect={(selectedItem: any, index: any) => {
                        this.setState({ RsiDivergenceItem: selectedItem, isRefresh: !this.state.isRefresh }, () => {
                            this.props.OnClickrsiDivergence1(this.state.RsiDivergenceItem, this.state.isRefresh)
                        })
                    }}
                    width={width} mode={this.context.mode} />

            </View>
        )
    }
    rsiShowTimeFrame = () => {
        return (
            !this.state.isTimeFrameIdc &&
            <View
                style={{
                    marginTop: 10,
                    // height:this.state.timeFameIndLabel != ''?80:50
                    backgroundColor: this.state.timeFameIndLabel != '' ?
                        this.getColorTheme("light", '#F7F7F7', "#000") :
                        this.getColorTheme("light", '#fff', "#000")
                }}
            >
                {
                    this.state.timeFameIndLabel != '' &&
                    <Text style={[style.subText, {
                        marginLeft: 15,
                        color: this.getColorTheme("light", "#000", "#FFF")
                    }]}>{"Time Frame"}</Text>
                }
                {
                    this.state.timeFameIndLabel === 'Custom' ?
                        <TextInput
                            placeholder={'Custom'}
                            value={this.state.customInd}
                            onChangeText={text => {
                                this.setState({
                                    customInd: text,
                                    isRefresh: !this.state.isRefresh
                                })
                            }}
                            autoCapitalize={'words'}
                            keyboardType={'numeric'}
                            style={{ fontWeight: 'bold', marginLeft: 10 }}
                        />
                        :
                        <NewDropDown
                        testID = {'arraynew'}
                         placeholder={this.state.timeFameIndLabel != '' ? this.state.timeFameIndLabel : "Time Frame"}
                         data={this.state.isBasic === true ?  this.state.timeFameIndArrays : this.state.timeFameIndArray}
                         placeHolderName={'Time Frame'}
                        isBasic={this.state.isBasic}
                        Upgrade={this.screennavigate}
                            onSelect={(selectedItem: any) => {
                                this.setState({
                                    timeFameIndLabel: selectedItem.value,
                                    isRefresh: !this.state.isRefresh,
                                }, () => {
                                    this.props.onSelectTimeFrame1(selectedItem.value)
                                })

                            }}
                            width={width} mode={this.context.mode} />


                }

            </View>
        )
    }

    // rsi main render function  
    rsiRnderPicke() {
        return (
            <>
                <NewDropDown 
                testID = {'asanew'}
                placeholder={this.state.rsiLabel != '' ? this.state.rsiLabel : "Select Option"} data={this.state.rsiArray} 
                Upgrade={this.screennavigate}
                 isBasic={this.state.isBasic}
                onSelect={(selectedItem: any, index: any) => {
                    this.setState({ rsiLabel: selectedItem, }, () => {
                        this.props.onSelectScreen(this.state.rsiLabel, this.state.selectArray)
                    });
                    this.onClickIndicator(index, selectedItem);

                }}
                    width={width} mode={this.context.mode} />

                {this.rsiPeriodPicker()}
                {this.rsiAboveBelowPick()}
                {this.rsiCenterLinePick()}
                {this.rsiShowDivergence()}
                {this.rsiShowTimeFrame()}
            </>
        )
    }

    // mfi render function
    // mfi period 
    mfiPeriodFxn = () => {
        return (
            this.state.isDivergenceMfi &&
            <View style={[style.fromTextInput, { backgroundColor: this.getColorTheme('light', "#F7F7F7", "#000") }]}>
                {
                    this.state.mfiPeriod != '' &&
                    <Text style={[style.subText, { color: this.getColorTheme('light', "gray", "white") }]}>{'Period'}</Text>

                }
                <TextInput
                    placeholder={'Period'}
                    placeholderTextColor={this.getColorTheme('light', "gray", "white")}
                    value={this.state.mfiPeriod.toString()}
                    onChangeText={text => {
                        this.setState({
                            mfiPeriod: text,
                            isRefresh: !this.state.isRefresh
                        }, () => {

                            this.props.MFISelctedScreen(this.state.MfiDivergenceItem, this.state.mfiTimeFrame, this.state.mfiradioAbove, this.state.mfiPeriod, this.state.mfilabel, this.state.mfiLength,)
                        })
                    }}

                    autoCapitalize={'words'}
                    keyboardType={'numeric'}
                    style={{
                        fontWeight: 'bold',
                        color: this.getColorTheme('light', "#000", "white"),
                        backgroundColor: this.getColorTheme('light', "#FFF", "#000")
                    }}
                />
            </View>
        )
    }
    mfiRadioFxn = () => {
        return (
            this.state.mfiRadio &&
            <>
                <View style={{ marginTop: 15 }} />
                <View style={{ marginTop: 10, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: width / 1.1 }}>
                    <View style={style.radioView}>
                        <View style={style.radioViewBtn}>
                            <TouchableOpacity style={[style.radioBtn,
                            { backgroundColor: this.getByCondition(this.state.mfiradioAbove, 'Above', '#4D9C5A', '#F6F6F6') }]}

                                onPress={() => this.setState({ mfiradioAbove: 'Above', isRefresh: !this.state.isRefresh }, () => {
                                    this.props.MFISelctedScreen(this.state.MfiDivergenceItem, this.state.mfiTimeFrame, this.state.mfiradioAbove, this.state.mfiPeriod, this.state.mfilabel, this.state.mfiLength,)

                                })}
                            >

                            </TouchableOpacity>
                        </View>
                        <Text style={style.radioTtext}>{"Above"}</Text>

                    </View>
                    <View style={style.radioView}>
                        <View style={style.radioViewBtn}>
                            <TouchableOpacity style={[style.radioBtn,
                            { backgroundColor: this.getByCondition(this.state.mfiradioAbove, 'Below', '#4D9C5A', '#F6F6F6') }]}
                                onPress={() => this.setState({ mfiradioAbove: 'Below', isRefresh: !this.state.isRefresh }, () => {
                                    this.props.MFISelctedScreen(this.state.MfiDivergenceItem, this.state.mfiTimeFrame, this.state.mfiradioAbove, this.state.mfiPeriod, this.state.mfilabel, this.state.mfiLength,)

                                })}
                            >
                            </TouchableOpacity>
                        </View>
                        <Text style={style.radioTtext}>{"Below"}</Text>
                    </View>
                </View>
                <View style={{ marginTop: 15 }} />
            </>
        )
    }
    mfiCenterLine = () => {
        return (
            this.state.isDivergenceMfi &&
            <>
                <View style={[style.fromTextInput, { backgroundColor: this.getColorTheme('light', "#F7F7F7", "#000") }]}>
                    {
                        this.state.mfiLength != '' &&
                        <Text style={[style.subText, { color: this.getColorTheme('light', "gray", "white") }]}>{this.state.mfilabel == 'Cross Centre Line' ? 'Centre Line' : 'Level'}</Text>

                    }
                    <TextInput
                        placeholderTextColor={this.getColorTheme('light', "gray", "white")}
                        placeholder={this.state.mfilabel == 'Cross Centre Line' ? 'Centre Line' : 'Level'}
                        value={this.state.mfiLength.toString()}
                        onChangeText={text => {
                            this.setState({
                                mfiLength: text,
                                isRefresh: !this.state.isRefresh
                            }, () => {

                                this.props.MFISelctedScreen(this.state.MfiDivergenceItem, this.state.mfiTimeFrame, this.state.mfiradioAbove, this.state.mfiPeriod, this.state.mfilabel, this.state.mfiLength,)
                            })
                        }}
                        autoCapitalize={'words'}
                        keyboardType={'numeric'}
                        style={{
                            fontWeight: 'bold',
                            color: this.getColorTheme('light', "#000", "white"),
                            backgroundColor: this.getColorTheme('light', "#FFF", "#000")
                        }}
                    />
                </View>
            </>
        )
    }
    mfiCrossPick = () => {
        return (
            this.state.isDivergence &&
            <View
                style={{
                    marginTop: 10,
                    backgroundColor: this.state.MfiDivergenceItem != '' ? this.getColorTheme("light", '#F7F7F7', "#000") :
                        this.getColorTheme("light", '#fff', theme.dark.secondaryColor)
                }}
            >
                {
                    this.state.MfiDivergenceItem == '' ? null :
                        <Text style={[style.subText, { marginLeft: 15, color: this.getColorTheme("light", "#000", "#FFF") }]}>{"Divergence"}</Text>
                }
                <NewDropDown placeholder={this.state.MfiDivergenceItem != '' ? this.state.MfiDivergenceItem : "Divergence"}
                Upgrade={this.screennavigate}
                isBasic={this.state.isBasic}
                    data={this.state.MfiDivergencArray}
                    onSelect={(selectedItem: any) => {
                        this.setState({ MfiDivergenceItem: selectedItem, isRefresh: !this.state.isRefresh }, () => {

                            this.props.MFISelctedScreen(this.state.MfiDivergenceItem, this.state.mfiTimeFrame, this.state.mfiradioAbove, this.state.mfiPeriod, this.state.mfilabel, this.state.mfiLength,)
                        })
                    }}
                    width={width} mode={this.context.mode} />

            </View>
        )
    }
    mfiTimeFrame = () => {
        return (
            <View
                style={{
                    marginTop: 10,
                    // height:this.state.timeFameIndLabel != ''?80:50
                    backgroundColor: this.state.mfiTimeFrame != '' ?
                        this.getColorTheme("light", '#F7F7F7', "#000") :
                        this.getColorTheme("light", '#fff', "#000")
                }}
            >
                {
                    this.state.mfiTimeFrame != '' &&
                    <Text style={[style.subText, {
                        color: this.getColorTheme("light", "#000", "#FFF")
                    }]}>{"Time Frame"}</Text>
                }
                {
                    <NewDropDown placeholder={this.state.mfiTimeFrame != '' ? this.state.mfiTimeFrame : "Time Frame"}
                        data={this.state.timeFameIndArray}
                        onSelect={(selectedItem: any) => {
                            this.setState({ mfiTimeFrame: selectedItem.value, isRefresh: !this.state.isRefresh }, () => {
                                this.props.MFISelctedScreen(this.state.MfiDivergenceItem, selectedItem.value, this.state.mfiradioAbove, this.state.mfiPeriod, this.state.mfilabel, this.state.mfiLength,)
                            })
                        }}
                        width={width} mode={this.context.mode} />


                }

            </View>
        )
    }

    // mfi main fxn
    mfiRnderPicke() {
        return (
            <>
                <NewDropDown placeholder={this.state.mfilabel != '' ? this.state.mfilabel : "Select Screen"} data={this.state.mfiArray}
                Upgrade={this.screennavigate}
                isBasic={this.state.isBasic}
                 onSelect={(selectedItem: any, index: any) => {
                    this.setState({ mfilabel: selectedItem }, () => {
                        // alert("yes")
                        this.props.MFISelctedScreen(this.state.MfiDivergenceItem, this.state.mfiTimeFrame, this.state.mfiradioAbove, this.state.mfiPeriod, this.state.mfilabel, this.state.mfiLength,)
                    })
                    this.onClickMfi(index, selectedItem);

                }}
                    width={width} mode={this.context.mode} />

                {this.mfiPeriodFxn()}
                {this.mfiRadioFxn()}
                {this.mfiCenterLine()}
                {this.mfiCrossPick()}
                {this.mfiTimeFrame()}

            </>
        )
    }
    // stochasticRnderPicke
    // stockhasticRnderPicke above and below
    stocScreenSelect = () => {
        return (
            <NewDropDown placeholder={this.state.stochasticlabel != '' ? this.state.stochasticlabel : "Select Screen"} data={this.state.stochasticArray} 
            Upgrade={this.screennavigate}
            isBasic={this.state.isBasic}        
            onSelect={(selectedItem: any, index: any) => {
                this.setState({ stochasticlabel: selectedItem }, () => {

                    this.props.StochasticPicker(this.state.StoDivergenceItem, this.state.stochasticTimeFrame, this.state.stochasticradioAbove, this.state.stochasticPeriod, this.state.stochasticlabel, this.state.stochasticLength,)
                })
                this.onClickStochastic(index, selectedItem);

            }}
                width={width} mode={this.context.mode} />

        )
    }
    stocAbouvBelow = () => {
        return (
            this.state.isDivergencestochastic &&
            <View style={[style.fromTextInput, { backgroundColor: this.getColorTheme('light', "#F7F7F7", "#000") }]}>
                {
                    this.state.stochasticPeriod != '' &&
                    <Text style={[style.subText, { color: this.getColorTheme('light', "gray", "white") }]}>{'Period'}</Text>

                }
                <TextInput
                    placeholderTextColor={this.getColorTheme('light', "gray", "white")}
                    placeholder={'Period'}
                    value={this.state.stochasticPeriod.toString()}
                    onChangeText={text => {
                        this.setState({
                            stochasticPeriod: text,
                            isRefresh: !this.state.isRefresh
                        }, () => {
                            this.props.StochasticPicker(this.state.StoDivergenceItem, this.state.stochasticTimeFrame, this.state.stochasticradioAbove, this.state.stochasticPeriod, this.state.stochasticlabel, this.state.stochasticLength,)
                        })
                    }}
                    autoCapitalize={'words'}
                    keyboardType={'numeric'}
                    style={{
                        fontWeight: 'bold', color: this.getColorTheme('light', "#000", "white"), backgroundColor:
                            this.getColorTheme('light', "#FFF", "#000")
                    }}
                />
            </View>
        )
    }
    stocRadio = () => {
        return (
            this.state.stochasticRadio &&
            <>
                <View style={{ marginTop: 15 }} />
                <View style={{ marginTop: 10, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: width / 1.1 }}>
                    <View style={style.radioView}>
                        <View style={style.radioViewBtn}>
                            <TouchableOpacity style={[style.radioBtn,
                            { backgroundColor: this.getByCondition(this.state.stochasticradioAbove, 'Above', '#4D9C5A', '#F6F6F6') }]}
                                onPress={() =>
                                    this.setState({ stochasticradioAbove: 'Above', isRefresh: !this.state.isRefresh },
                                        () => {
                                            this.props.StochasticPicker(this.state.StoDivergenceItem, this.state.stochasticTimeFrame, this.state.stochasticradioAbove, this.state.stochasticPeriod, this.state.stochasticlabel, this.state.stochasticLength,)
                                        })}
                            >

                            </TouchableOpacity>
                        </View>
                        <Text style={style.radioTtext}>{"Above"}</Text>
                    </View>
                    <View style={style.radioView}>
                        <View style={style.radioViewBtn}>
                            <TouchableOpacity style={[style.radioBtn,
                            { backgroundColor: this.getByCondition(this.state.stochasticradioAbove, 'Below', '#4D9C5A', '#F6F6F6') }]}
                                onPress={() =>
                                    this.setState({ stochasticradioAbove: 'Below', isRefresh: !this.state.isRefresh },
                                        () => {
                                            this.props.StochasticPicker(this.state.StoDivergenceItem, this.state.stochasticTimeFrame, this.state.stochasticradioAbove, this.state.stochasticPeriod, this.state.stochasticlabel, this.state.stochasticLength,)
                                        })}
                            >
                            </TouchableOpacity>
                        </View>
                        <Text style={style.radioTtext}>{"Below"}</Text>
                    </View>
                </View>
                <View style={{ marginTop: 15 }} />
            </>
        )
    }
    aboveLowPer = () => {
        return (
            this.state.isDivergencestochastic &&
            <>
                <View style={[style.fromTextInput, { backgroundColor: this.getColorTheme('light', "#F7F7F7", "#000") }]}>
                    {
                        this.state.stochasticLength != '' &&
                        <Text style={[style.subText, { color: this.getColorTheme('light', "gray", "white") }]}>{this.state.stochasticlabel == '%K Cross Centre Line' || this.state.stochasticlabel == 'K Cross Below Centre Line' ? 'Center Line' : 'Level'}</Text>

                    }
                    <TextInput
                        placeholderTextColor={this.getColorTheme('light', "gray", "white")}
                        placeholder={this.state.stochasticlabel == '%K Cross Centre Line' || this.state.stochasticlabel == 'K Cross Below Centre Line' ? 'Center Line' : 'Level'}
                        value={this.state.stochasticLength.toString()}
                        onChangeText={text => {
                            this.setState({
                                stochasticLength: text,
                                isRefresh: !this.state.isRefresh
                            }, () => {
                                this.props.StochasticPicker(this.state.StoDivergenceItem, this.state.stochasticTimeFrame, this.state.stochasticradioAbove, this.state.stochasticPeriod, this.state.stochasticlabel, this.state.stochasticLength,)
                            })
                        }}
                        autoCapitalize={'words'}
                        keyboardType={'numeric'}
                        style={{
                            fontWeight: 'bold', color: this.getColorTheme('light', "#000", "#FFF"), backgroundColor:
                                this.getColorTheme('light', "#FFF", "#000")
                        }}
                    />
                </View>
            </>
        )
    }
    divergenceShow = () => {
        return (
            this.renderConditionalItem(this.state.StoDivergence,
                <View
                    style={{
                        marginTop: 10,
                        backgroundColor: this.getByCondition(this.state.StoDivergenceItem, '', this.getColorTheme("light", '#fff', "#000"), this.getColorTheme("light", '#F7F7F7', "#000"))
                    }}
                >
                    {
                        this.renderConditionalItem(this.state.StoDivergenceItem == '' ,
                            <Text style={[style.subText, { marginLeft: 15, color: this.getColorTheme("light", "#000", "#FFF") }]}>{"Divergence"}</Text>)
                    }
                    <NewDropDown placeholder={this.getByCondition(this.state.StoDivergenceItem , '' , "Divergence", this.state.StoDivergenceItem )}
                        data={this.state.StocasticDivergencArray} onSelect={(selectedItem: any) => {
                            this.setState({ StoDivergenceItem: selectedItem, isRefresh: !this.state.isRefresh }, () => {

                                this.props.StochasticPicker(this.state.StoDivergenceItem, this.state.stochasticTimeFrame, this.state.stochasticradioAbove, this.state.stochasticPeriod, this.state.stochasticlabel, this.state.stochasticLength,)
                            })
                        }}
                        width={width} mode={this.context.mode} />


                </View>,null))
    }
    // stochastic main fxn
    stochasticRnderPicke() {
        return (
            <>
                {this.stocScreenSelect()}
                {this.stocAbouvBelow()}
                {this.stocRadio()}
                {this.aboveLowPer()}
                {this.divergenceShow()}
                {
                    //   this.state.StochastickTimeFrame == true ? null : 
                    <View
                        style={{
                            marginTop: 10,
                            // height:this.state.timeFameIndLabel != ''?80:50
                            backgroundColor: this.getByCondition(this.state.stochasticTimeFrame , '' ,this.getColorTheme("light", '#fff', "#000"), this.getColorTheme("light", '#F7F7F7', "#000"))
                        }}
                    >
                        {
                            this.renderConditionalItem(this.state.stochasticTimeFrame != '',
                            <Text style={[style.subText, { marginLeft: 15, color: this.getColorTheme("light", "#000", "#FFF") }]}>{"Time Frame"}</Text>)
                        }
                        {
                            this.state.stochasticTimeFrame === 'Custom' ?
                                <TextInput
                                    placeholder={'Custom'}
                                    value={this.state.stochasticCustome.toString()}
                                    onChangeText={text => {
                                        this.setState({
                                            stochasticCustome: text,
                                            isRefresh: !this.state.isRefresh
                                        })
                                    }}
                                    autoCapitalize={'words'}
                                    keyboardType={'numeric'}
                                    style={{ fontWeight: 'bold', marginLeft: 10 }}
                                />
                                :
                                <NewDropDown placeholder={this.getByCondition(this.state.stochasticTimeFrame , '' , "Time Frame", this.state.stochasticTimeFrame )} data={this.state.timeFameIndArray}
                                    onSelect={(selectedItem: any) => {
                                        this.setState({ stochasticTimeFrame: selectedItem.value, isRefresh: !this.state.isRefresh }, () => {

                                            this.props.StochasticPicker(this.state.StoDivergenceItem, selectedItem.value, this.state.stochasticradioAbove, this.state.stochasticPeriod, this.state.stochasticlabel, this.state.stochasticLength,)
                                        })
                                    }}
                                    width={width} mode={this.context.mode} />
                        }
                    </View>
                }
            </>
        )
    }

    // cci fxns
    cciPeriodVisible = () => {
        return (
            this.state.isPeriodVisible &&
            <View style={[style.fromTextInput,
            { backgroundColor: this.getColorTheme('light', "#F7F7F7", "#000") }]}>
                {
                    this.renderConditionalItem(this.state.cciPeriod != '' ,
                    <Text style={[style.subText,
                    { color: this.getColorTheme('light', "gray", "white") }]}>{'Period'}</Text>)

                }
                <TextInput
                    placeholderTextColor={this.getColorTheme('light', "gray", "white")}
                    placeholder={'Period'}
                    value={this.state.cciPeriod.toString()}
                    onChangeText={text => {
                        this.setState({
                            cciPeriod: text,
                            isRefresh: !this.state.isRefresh
                        }, () => {
                            this.props.cciPicker(this.state.cciDivergenceItem, this.state.cciTimeFrame, this.state.cciradioAbove, this.state.ccilabel, this.state.cciPeriod)
                        })
                    }}
                    autoCapitalize={'words'}
                    keyboardType={'numeric'}
                    style={{
                        fontWeight: 'bold',
                        color: this.getColorTheme('light', "#000", "white"),
                        backgroundColor: this.getColorTheme('light', "#FFF", "#000")
                    }}
                />
            </View>
        )
    }
    cciRadioVisible = () => {
        return (
            this.state.cciRadio &&
            <>
                <View style={{ marginTop: 15 }} />
                <View style={{ marginTop: 10, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: width / 1.1 }}>
                    <View style={style.radioView}>
                        <View style={style.radioViewBtn}>
                            <TouchableOpacity
                                onPress={() => {
                                    this.setState({
                                        cciradioAbove: 'Above',
                                        isRefresh: !this.state.isRefresh
                                    }, () => {
                                        this.props.cciPicker(this.state.cciDivergenceItem, this.state.cciTimeFrame, this.state.cciradioAbove, this.state.ccilabel, this.state.cciPeriod)
                                    })
                                }}
                                style={[style.radioBtn,
                                { backgroundColor: this.state.cciradioAbove === 'Above' ? '#4D9C5A' : '#F6F6F6' }]}
                            // cciradioAbove
                            >
                            </TouchableOpacity>
                        </View>
                        <Text style={style.radioTtext}>{"Above"}</Text>
                    </View>
                    <View style={style.radioView}>
                        <View style={style.radioViewBtn}>
                            <TouchableOpacity
                                onPress={() => {
                                    this.setState({
                                        cciradioAbove: 'Below',
                                        isRefresh: !this.state.isRefresh
                                    }, () => {
                                        this.props.cciPicker(this.state.cciDivergenceItem, this.state.cciTimeFrame, this.state.cciradioAbove, this.state.ccilabel, this.state.cciPeriod)
                                    })
                                }}
                                style={[style.radioBtn,
                                { backgroundColor: this.state.cciradioAbove !== 'Below' ? '#F6F6F6' : '#4D9C5A' }]}
                            >
                            </TouchableOpacity>
                        </View>
                        <Text style={style.radioTtext}>{"Below"}</Text>
                    </View>
                </View>
                <View style={{ marginTop: 15 }} />
            </>
        )
    }

    cciInputLength = () => {
        return (
            !this.state.isDivergencecci && !this.state.isCCI &&
            <>
                <View style={[style.fromTextInput, { backgroundColor: this.getColorTheme('light', "#F7F7F7", "#000") }]}>
                    {
                        this.state.cciLength != '' &&
                        <Text style={[style.subText, { color: this.getColorTheme('light', "gray", "white") }]}>{'Length'}</Text>

                    }
                    <TextInput
                        placeholderTextColor={this.getColorTheme('light', "gray", "white")}
                        placeholder={'Length'}
                        value={this.state.cciLength.toString()}
                        onChangeText={text => {
                            this.setState({
                                cciLength: text,
                                isRefresh: !this.state.isRefresh
                            })
                        }}
                        autoCapitalize={'words'}
                        keyboardType={'numeric'}
                        style={{
                            fontWeight: 'bold',
                            color: this.getColorTheme('light', "#000", "white"),
                            backgroundColor: this.getColorTheme('light', "#FFF", "#000")
                        }}
                    />
                </View>
            </>
        )
    }
    cciDivergence = () => {
        return (
            this.state.isDivergencecci &&
            <View
                style={{
                    marginTop: 10,
                    // height:this.state.timeFameIndLabel != ''?80:50
                    backgroundColor: this.state.cciDivergenceItem != '' ? this.getColorTheme("light", '#F7F7F7', "#000") :
                        this.getColorTheme("light", '#fff', "#000")
                }}
            >
                {
                    this.state.cciDivergenceItem == '' ? null :
                        <Text style={[style.subText, { marginLeft: 15, color: this.getColorTheme("light", "#000", "#FFF") }]}>{"Divergence"}</Text>
                }
                <NewDropDown placeholder={this.state.cciDivergenceItem != '' ? this.state.cciDivergenceItem : "Divergence"} data={this.state.CCIDivergencArray}
                    onSelect={(selectedItem: any) => {
                        this.setState({ cciDivergenceItem: selectedItem, isRefresh: !this.state.isRefresh }, () => {

                            this.props.cciPicker(this.state.cciDivergenceItem, this.state.cciTimeFrame, this.state.cciradioAbove, this.state.ccilabel, this.state.cciPeriod)
                        })
                    }}
                    width={width} mode={this.context.mode} />


            </View>
        )
    }
    cciTimeFrame = () => {
        return (
            <View
                style={{
                    marginTop: 10,
                    // height:this.state.timeFameIndLabel != ''?80:50
                    backgroundColor: this.state.timeFameIndLabel != '' ?
                        this.getColorTheme("light", '#F7F7F7', "#000") :
                        this.getColorTheme("light", '#fff', "#000")
                }}
            >
                {
                    this.state.cciTimeFrame != '' &&
                    <Text style={[style.subText, { marginLeft: 15, color: this.getColorTheme("light", "#000", "#FFF") }]}>{"Time Frame"}</Text>
                }
                {
                    <NewDropDown placeholder={this.state.cciTimeFrame != '' ? this.state.cciTimeFrame : "Time Frame"} data={this.state.timeFameIndArray}
                        onSelect={(selectedItem: any) => {
                            this.setState({ cciTimeFrame: selectedItem.value, isRefresh: !this.state.isRefresh }, () => {

                                this.props.cciPicker(this.state.cciDivergenceItem, selectedItem.value, this.state.cciradioAbove, this.state.ccilabel, this.state.cciPeriod)
                            })
                        }}
                        width={width} mode={this.context.mode} />

                }

            </View>
        )
    }
    // cci main fxn
    cciRnderPicke() {
        return (
            <>
                <NewDropDown placeholder={this.state.ccilabel != '' ? this.state.ccilabel : "Select Screen"} data={this.state.cciArray} onSelect={(selectedItem: any, index: any) => {
                    this.setState({ ccilabel: selectedItem }, () => {

                        // this.props.cciPicker(this.state.cciTimeFrame,this.state.cciradioAbove,this.state.ccilabel) })
                        this.props.cciPicker(this.state.cciDivergenceItem, this.state.cciTimeFrame, this.state.cciradioAbove, this.state.ccilabel, this.state.cciPeriod)
                    })

                    this.onClickcci(index, selectedItem);

                }}
                    width={width} mode={this.context.mode} />

                {this.cciPeriodVisible()}
                {
                    this.cciRadioVisible()
                }
                {
                    this.cciInputLength()
                }
                {
                    this.cciDivergence()
                }
                {
                    this.cciTimeFrame()
                }


            </>
        )
    }
    // mvAvg
    movingAvgType = () => {
        return (
            this.state.movingAvgArray.length != 0 &&
            <NewDropDown placeholder={this.state.movingAvgLabel != '' ? this.state.movingAvgLabel : "Select Moving Average Type"} 
            data={this.state.movingAvgArray}
            onSelect={(selectedItem: any, index: any) => {
                this.setState({ movingAvgLabel: selectedItem, mvAvglabel: '' }, () => {
                    this.props.mvAvgPicker(this.state.mvAvgTimeFrame, this.state.mvAvgPeriod, selectedItem, this.state.mvAvglabel, this.state.crossOverValue);
                    this.onClickMvArray(index, selectedItem);
                })
            }}
            width={width}
            mode={this.context.mode} />
        )
    }
    mvScreenType = () => {
        return (
            this.state.showScreens &&
            <NewDropDown
                placeholder={this.state.mvAvglabel != '' ? this.state.mvAvglabel : "Select Screen"}
                selectedOption={this.state.mvAvglabel}
                data={this.state.mvAvgArray}
                onSelect={(selectedItem: any, index: any) => {
                    this.setState({ mvAvglabel: selectedItem }, () => {
                        this.props.mvAvgPicker(this.state.mvAvgTimeFrame, this.state.mvAvgPeriod, this.state.movingAvgLabel, selectedItem, this.state.crossOverValue);
                        this.onClickMvArray(index, selectedItem);
                    })
                }}
                width={width} 
                mode={this.context.mode} 
            /> 
        )
    }
    mvPeriod = () => {
        return (
            <View style={[style.fromTextInput,
            { backgroundColor: this.getColorTheme('light', "#F7F7F7", "#000") }]}>
                {
                    this.state.mvAvgPeriod != '' &&
                    <Text style={[style.subText, { color: this.getColorTheme('light', "gray", "white") }]}>{'Period'}</Text>
                }
                <TextInput
                    placeholderTextColor={this.getColorTheme('light', "gray", "white")}
                    placeholder={'Period'}
                    value={this.state.mvAvgPeriod.toString()}
                    onChangeText={text => {
                        this.setState({
                            mvAvgPeriod: text,
                            isRefresh: !this.state.isRefresh
                        }, () => {
                            this.props.mvAvgPicker(this.state.mvAvgTimeFrame, text, this.state.movingAvgLabel, this.state.mvAvglabel, this.state.crossOverValue);
                        })
                    }}
                    autoCapitalize={'words'}
                    keyboardType={'numeric'}
                    style={{
                        fontWeight: 'bold', color: this.getColorTheme('light', "#000", "white"), backgroundColor:
                            this.getColorTheme('light', "#FFF", "#000")
                    }}
                />
            </View>

        )
    }
    mvRadioVisible = () => {
        return (
            this.state.mvAvgRadio &&
            <View style={{ marginTop: 10, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: width / 1.1 }}>
                <View style={style.radioView}>
                    <View style={style.radioViewBtn}>
                        <TouchableOpacity style={[style.radioBtn,
                        { backgroundColor: this.state.mvAvgradioAbove === 'Above' ? '#4D9C5A' : '#F6F6F6' }]}
                            onPress={() => this.onClickRadio('Above')}
                        >

                        </TouchableOpacity>
                    </View>
                    <Text style={style.radioTtext}>{"Above"}</Text>

                </View>
                <View style={style.radioView}>
                    <View style={style.radioViewBtn}>
                        <TouchableOpacity style={[style.radioBtn,
                        { backgroundColor: this.state.radioAbove === 'Below' ? '#4D9C5A' : '#F6F6F6' }]}
                            onPress={() => this.onClickRadio('Below')}>
                        </TouchableOpacity>
                    </View>
                    <Text style={style.radioTtext}>{"Below"}</Text>
                </View>
            </View>
        )
    }
    mvIdcTimeFrame = () => {
        return (
            <View
                style={{
                    marginTop: 10,
                    backgroundColor: this.state.mvAvgTimeFrame != '' ? this.getColorTheme("light", '#F7F7F7', "#000") :
                        this.getColorTheme("light", '#fff', "#000")
                }}>
                {
                    this.state.mvAvgTimeFrame != '' &&
                    <Text style={[style.subText, { marginLeft: 15, color: this.getColorTheme("light", "#000", "#FFF") }]}>{"Time Frame"}</Text>
                }
                {
                    this.state.mvAvgTimeFrame === 'Custom' ?
                        <TextInput
                            placeholder={'Custom'}
                            value={this.state.mvAvgCustome.toString()}
                            onChangeText={text => {
                                this.setState({
                                    mvAvgCustome: text,
                                    isRefresh: !this.state.isRefresh
                                })
                            }}
                            autoCapitalize={'words'}
                            keyboardType={'numeric'}
                            style={{ fontWeight: 'bold', marginLeft: 10 }}
                        />
                        :
                        <NewDropDown
                            placeholder={this.state.mvAvgTimeFrame != '' ? this.state.mvAvgTimeFrame
                                : "Time Frame"}
                                placeHolderName={'Time Frame'}
                                 data={this.state.isBasic === true ?  this.state.timeFameIndArrays : this.state.timeFameIndArray}
                                isBasic={this.state.isBasic}
                                Upgrade={this.screennavigate}
                            onSelect={(selectedItem: any) => {
                                this.setState({ mvAvgTimeFrame: selectedItem.value, isRefresh: !this.state.isRefresh }, () => {
                                    this.props.mvAvgPicker(selectedItem.value, this.state.mvAvgPeriod, this.state.movingAvgLabel, this.state.mvAvglabel, this.state.crossOverValue);
                                })
                            }}
                            width={width} mode={this.context.mode} />

                }
            </View>
        )
    }
    mvCrossOver = () => {
        return (
            this.state.mvAvglabel === "2 Moving Average Crossovers" &&
            <View
                style={{
                    marginTop: 10,
                    backgroundColor: this.state.mvAvgTimeFrame != '' ? this.getColorTheme("light", '#F7F7F7', "#000") :
                        this.getColorTheme("light", '#fff', "#000")
                }}
            >
                <NewDropDown
                    placeholder={this.state.crossOverValue != '' ? this.state.crossOverValue?.label
                        : "Crosscover"} data={this.state.CrossOverArray}
                    onSelect={(selectedItem: any) => {
                        this.setState({ crossOverValue: selectedItem })
                        this.props.mvAvgPicker(this.state.mvAvgTimeFrame, this.state.mvAvgPeriod, this.state.movingAvgLabel, this.state.mvAvglabel, selectedItem);
                    }}
                    width={width} mode={this.context.mode} />

            </View>
        )
    }
    // mvAvg main fxn
    mvAvgRnderPicke() {
        const crossOver = this.state.mvAvglabel === '2 Moving Average Crossovers';
        return (
            <>
                {this.movingAvgType()}
                <View style={{ marginTop: 15 }} />
                {this.mvScreenType()}
                {!crossOver && this.mvPeriod()}
                {this.mvRadioVisible()}
                {!crossOver && this.mvIdcTimeFrame()}
                {this.mvCrossOver()}


            </>
        )
    }
    // mcad
    mcadRadio = () => {
        return (
            this.state.macdRadio &&
            <>
                <View style={{ marginTop: 10 }} />
                <View style={{ marginTop: 10, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: width / 1.1 }}>
                    <View style={style.radioView}>
                        <View style={style.radioViewBtn}>
                            <TouchableOpacity style={[style.radioBtn,
                            { backgroundColor: this.state.macdradioAbove === 'Above' ? '#4D9C5A' : '#F6F6F6' }]}
                                onPress={() =>
                                    this.setState({ macdradioAbove: 'Above', isRefresh: !this.state.isRefresh }, () => {
                                        this.props.macdPicker(this.state.macdDivergenceitem, this.state.macdTimeFrame, this.state.macdradioAbove, this.state.macdlabel, this.state.macdPeriod,)
                                    })
                                }
                            >

                            </TouchableOpacity>
                        </View>
                        <Text style={style.radioTtext}>{this.state.MacktrndingUpDown ? "Trending Up" : "Above"}</Text>

                    </View>
                    <View style={style.radioView}>
                        <View style={style.radioViewBtn}>
                            <TouchableOpacity style={[style.radioBtn,
                            { backgroundColor: this.state.macdradioAbove === 'Below' ? '#4D9C5A' : '#F6F6F6' }]}
                                onPress={() => this.setState({ macdradioAbove: 'Below', isRefresh: !this.state.isRefresh }, () => {
                                    this.props.macdPicker(this.state.macdDivergenceitem, this.state.macdTimeFrame, this.state.macdradioAbove, this.state.macdlabel, this.state.macdPeriod,)
                                })}>
                            </TouchableOpacity>
                        </View>
                        <Text style={style.radioTtext}>{this.state.MacktrndingUpDown ? "Trending Down" : "Below"}</Text>
                    </View>
                </View>
                <View style={{ marginTop: 10 }} />
            </>
        )
    }
    mcadPeriod = () => {
        return (
            this.state.isDivergencemacd &&
            <View style={[style.fromTextInput,
            { backgroundColor: this.getColorTheme('light', "#F7F7F7", "#000") }]}>
                {
                    this.state.macdPeriod != '' &&
                    <Text style={[style.subText, { color: this.getColorTheme('light', "gray", "white") }]}>{'Period'}</Text>

                }
                <TextInput
                    placeholderTextColor={this.getColorTheme('light', "gray", "white")}
                    placeholder={'Period'}
                    value={this.state.macdPeriod.toString()}
                    onChangeText={text => {
                        this.setState({
                            macdPeriod: text,
                            isRefresh: !this.state.isRefresh
                        }, () => {

                            this.props.macdPicker(this.state.macdDivergenceitem, this.state.macdTimeFrame, this.state.macdradioAbove, this.state.macdlabel, this.state.macdPeriod,)
                        })
                    }}
                    autoCapitalize={'words'}
                    keyboardType={'numeric'}

                    style={{
                        fontWeight: 'bold', color: this.getColorTheme('light', "#000", "white"), backgroundColor:
                            this.getColorTheme('light', "#FFF", "#000")
                    }}
                />
            </View>
        )
    }
    mcadDivergence = () => {
        return (
            this.state.MckDivergence &&
            <View
                style={{
                    marginTop: 10,
                    // height:this.state.timeFameIndLabel != ''?80:50
                    backgroundColor: this.state.macdDivergenceitem != '' ? this.getColorTheme("light", '#F7F7F7', "#000") :
                        this.getColorTheme("light", '#fff', theme.dark.secondaryColor)
                }}
            >

                {
                    this.state.macdDivergenceitem == '' ? null :
                        <Text style={[style.subText, { marginLeft: 15, color: this.getColorTheme("light", "#000", "#FFF") }]}>{"Divergence"}</Text>
                }

                <NewDropDown placeholder={this.state.macdDivergenceitem != '' ? this.state.macdDivergenceitem : "Divergence"} data={this.state.MckDivergenceArray}
                    onSelect={(selectedItem: any) => {
                        this.setState({ macdDivergenceitem: selectedItem, isRefresh: !this.state.isRefresh }, () => {

                            this.props.macdPicker(this.state.macdDivergenceitem, this.state.macdTimeFrame, this.state.macdradioAbove, this.state.macdlabel, this.state.macdPeriod,)
                        })
                    }}
                    width={width} mode={this.context.mode} />


            </View>
        )
    }
    mcadTimeFrame = () => {
        return (
            <View
                style={{
                    marginTop: 10,
                    backgroundColor: this.state.macdTimeFrame != '' ? this.getColorTheme("light", '#F7F7F7', "#000") :
                        this.getColorTheme("light", '#fff', "#000")
                }}
            >
                {
                    this.state.macdTimeFrame != '' &&
                    <Text style={[style.subText, { marginLeft: 15, color: this.getColorTheme("light", "#000", "#FFF") }]}>{"Time Frame"}</Text>
                }
                {
                    this.state.macdTimeFrame === 'Custom' ?
                        <TextInput
                            placeholder={'Custom'}
                            value={this.state.macdCustome.toString()}
                            onChangeText={text => {
                                this.setState({
                                    macdCustome: text,
                                    isRefresh: !this.state.isRefresh
                                })
                            }}
                            autoCapitalize={'words'}
                            keyboardType={'numeric'}

                            style={{ fontWeight: 'bold', marginLeft: 10 }}
                        />
                        :
                        <NewDropDown placeholder={this.state.macdTimeFrame != '' ? this.state.macdTimeFrame : "Time Frame"} data={this.state.timeFameIndArray}
                            onSelect={(selectedItem: any) => {
                                this.setState({ macdTimeFrame: selectedItem.value, isRefresh: !this.state.isRefresh }, () => {

                                    this.props.macdPicker(this.state.macdDivergenceitem, selectedItem.value, this.state.macdradioAbove, this.state.macdlabel, this.state.macdPeriod,)
                                })
                            }}
                            width={width} mode={this.context.mode} />


                }

            </View>
        )
    }
    // mcad main fxn
    macdRnderPicke() {
        return (
            <>
                <NewDropDown placeholder={this.state.macdlabel != '' ? this.state.macdlabel : "Select Screen"} data={this.state.macdArray}
                    onSelect={(selectedItem: any, index: any) => {
                        this.setState({ macdlabel: selectedItem }, () => {
                            this.props.macdPicker(this.state.macdDivergenceitem, this.state.macdTimeFrame, this.state.macdradioAbove, this.state.macdlabel, this.state.macdPeriod,)
                        })
                        this.onClickMacd(index, selectedItem);
                    }}
                    width={width} mode={this.context.mode} />

                {this.mcadRadio()}
                {this.mcadPeriod()}
                {this.mcadDivergence()}
                {this.mcadTimeFrame()}
            </>
        )

    }
    // adx 
    adxRadio = () => {
        return (
            this.state.adxRadio &&
            <>
                <View style={{ marginTop: 15 }} />
                <View style={{ marginTop: 10, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: width / 1.1 }}>
                    <View style={style.radioView}>
                        <View style={style.radioViewBtn}>
                            <TouchableOpacity style={[style.radioBtn,
                            { backgroundColor: this.state.adxradioAbove === 'Above' ? '#4D9C5A' : '#F6F6F6' }]}
                                onPress={() => this.setState({ adxradioAbove: 'Above', isRefresh: !this.state.isRefresh }, () => {

                                    this.props.adxPicker(this.state.adxTimeFrame, this.state.adxradioAbove, this.state.adxlabel, this.state.adxPeriod, this.state.adxLength)
                                })}
                            >

                            </TouchableOpacity>
                        </View>
                        <Text style={style.radioTtext}>{"Above"}</Text>

                    </View>
                    <View style={style.radioView}>
                        <View style={style.radioViewBtn}>
                            <TouchableOpacity style={[style.radioBtn,
                            { backgroundColor: this.state.adxradioAbove === 'Below' ? '#4D9C5A' : '#F6F6F6' }]}
                                onPress={() => this.setState({ adxradioAbove: 'Below', isRefresh: !this.state.isRefresh }, () => {

                                    this.props.adxPicker(this.state.adxTimeFrame, this.state.adxradioAbove, this.state.adxlabel, this.state.adxPeriod, this.state.adxLength)
                                })}>
                            </TouchableOpacity>
                        </View>
                        <Text style={style.radioTtext}>{"Below"}</Text>
                    </View>
                </View>
                <View style={{ marginTop: 15 }} />
            </>
        )
    }
    adxLevel = () => {
        return (
            !this.state.isDivergenceadx &&
            <>
                <View style={[style.fromTextInput,
                { backgroundColor: this.getColorTheme('light', "#F7F7F7", "#000") }]}>
                    {
                        this.state.adxLength != '' &&
                        <Text style={[style.subText, { color: this.getColorTheme('light', "gray", "white") }]}>{'Level'}</Text>

                    }
                    <TextInput
                        placeholderTextColor={this.getColorTheme('light', "gray", "white")}
                        placeholder={'Level'}
                        value={this.state.adxLength.toString()}
                        onChangeText={text => {
                            this.setState({
                                adxLength: text,
                                isRefresh: !this.state.isRefresh
                            }, () => {

                                this.props.adxPicker(this.state.adxTimeFrame, this.state.adxradioAbove, this.state.adxlabel, this.state.adxPeriod, this.state.adxLength)
                            })
                        }}
                        autoCapitalize={'words'}
                        keyboardType={'numeric'}
                        style={{
                            fontWeight: 'bold', color: this.getColorTheme('light', "#000", "white"), backgroundColor:
                                this.getColorTheme('light', "#FFF", "#000")
                        }}
                    />
                </View>
            </>

        )
    }
    adxTimeFrame = () => {
        return (
            !this.state.isTimeFrameIdc &&
            <View
                style={{
                    marginTop: 10,
                    backgroundColor: this.state.adxTimeFrame != '' ? this.getColorTheme("light", '#F7F7F7', "#000") :
                        this.getColorTheme("light", '#fff', "#000")

                }}
            >
                {
                    this.state.adxTimeFrame != '' &&
                    <Text style={[style.subText, { marginLeft: 15, color: this.getColorTheme("light", "#000", "#FFF") }]}>{"Time Frame"}</Text>
                }
                {
                    this.state.adxTimeFrame === 'Custom' ?
                        <TextInput
                            placeholder={'Custom'}
                            value={this.state.adxCustome}
                            onChangeText={text => {
                                this.setState({
                                    adxCustome: text,
                                    isRefresh: !this.state.isRefresh
                                })
                            }}
                            autoCapitalize={'words'}
                            keyboardType={'numeric'}
                            style={{ fontWeight: 'bold', marginLeft: 10 }}
                        />
                        :
                        <NewDropDown placeholder={this.state.adxTimeFrame != '' ? this.state.adxTimeFrame : "Time Frame"} data={this.state.timeFameIndArray}
                            onSelect={(selectedItem: any) => {
                                this.setState({ adxTimeFrame: selectedItem.value, isRefresh: !this.state.isRefresh }, () => {

                                    this.props.adxPicker(selectedItem.value, this.state.adxradioAbove, this.state.adxlabel, this.state.adxPeriod, this.state.adxLength)
                                })

                            }}
                            width={width} mode={this.context.mode} />

                }

            </View>
        )
    }
    // adx main function
    adxRender() {
        return (
            <>
                <NewDropDown placeholder={this.state.adxlabel != '' ? this.state.adxlabel : "Select Screen"} data={this.state.adxArray}
                    onSelect={(selectedItem: any, index: any) => {
                        this.setState({ adxlabel: selectedItem }, () => {

                            this.props.adxPicker(this.state.adxTimeFrame, this.state.adxradioAbove, this.state.adxlabel, this.state.adxPeriod, this.state.adxLength)
                        })

                        this.onClickAdx(index, selectedItem);

                    }}
                    width={width} mode={this.context.mode} />

                <View style={[style.fromTextInput,
                { backgroundColor: this.getColorTheme('light', "#F7F7F7", "#000") }]}>
                    {
                        this.state.adxPeriod != '' &&
                        <Text style={[style.subText, { color: this.getColorTheme('light', "gray", "white") }]}>{'Period'}</Text>

                    }
                    <TextInput
                        placeholderTextColor={this.getColorTheme('light', "gray", "white")}
                        placeholder={'Period'}
                        value={this.state.adxPeriod.toString()}
                        onChangeText={text => {
                            this.setState({
                                adxPeriod: text,
                                isRefresh: !this.state.isRefresh
                            }, () => {

                                this.props.adxPicker(this.state.adxTimeFrame, this.state.adxradioAbove, this.state.adxlabel, this.state.adxPeriod, this.state.adxLength)
                            })
                        }}
                        autoCapitalize={'words'}
                        keyboardType={'numeric'}
                        style={{
                            fontWeight: 'bold', color: this.getColorTheme('light', "#000", "white"), backgroundColor:
                                this.getColorTheme('light', "#FFF", "#000")
                        }}
                    />
                </View>
                {this.adxRadio()}
                {this.adxLevel()}
                {this.adxTimeFrame()}
            </>
        )
    }
    // obv
    obvSelectScreen = () => {
        return (
            this.state.derivativesArray1.length != 0 && <>
                <View style={{ marginTop: 15 }} />
                <NewDropDown placeholder={this.state.divergenceLable != '' ? this.state.divergenceLable :
                    "Select Screen"} data={this.state.derivativesArray1}
                    onSelect={(selectedItem: any) => {
                        this.setState({ divergenceLable: selectedItem, isRefresh: !this.state.isRefresh }, () => {

                            this.props.obvPicker(this.state.obvTimeFrame, this.state.divergenceLable, this.state.obvlabel, this.state.obvPeriod)
                        })
                    }}
                    width={width} mode={this.context.mode} />

            </>
        )
    }
    obvPeriod = () => {
        return (
            this.state.isTimePeriod && <View style={[style.fromTextInput,
            { backgroundColor: this.getColorTheme('light', "#F7F7F7", "#000") }]}>
                {
                    this.state.obvPeriod != '' &&
                    <Text style={[style.subText, { color: this.getColorTheme('light', "gray", "white") }]}>{'Period'}</Text>

                }
                <TextInput
                    placeholderTextColor={this.getColorTheme('light', "gray", "white")}
                    placeholder={'Period'}
                    value={this.state.obvPeriod.toString()}
                    onChangeText={text => {
                        this.setState({
                            obvPeriod: text,
                            isRefresh: !this.state.isRefresh
                        }, () => {

                            this.props.obvPicker(this.state.obvTimeFrame, this.state.divergenceLable, this.state.obvlabel, this.state.obvPeriod)
                        })
                    }}
                    autoCapitalize={'words'}
                    keyboardType={'numeric'}
                    style={{
                        fontWeight: 'bold', color: this.getColorTheme('light', "#000", "white"), backgroundColor:
                            this.getColorTheme('light', "#FFF", "#000")
                    }}
                />
            </View>
        )
    }
    obvTimeFrame = () => {
        return (
            !this.state.isTimeFrameIdc &&
            <View
                style={{
                    marginTop: 10,
                    backgroundColor: this.state.obvTimeFrame != '' ? this.getColorTheme("light", '#F7F7F7', "#000") :
                        this.getColorTheme("light", '#fff', "#000")
                }}
            >
                {
                    this.state.obvTimeFrame != '' &&
                    <Text style={[style.subText, { marginLeft: 15, color: this.getColorTheme("light", "#000", "#FFF") }]}>{"Time Frame"}</Text>
                }
                {
                    this.state.obvTimeFrame === 'Custom' ?
                        <TextInput
                            placeholder={'Custom'}
                            value={this.state.obvCustome}
                            onChangeText={text => {
                                this.setState({
                                    obvCustome: text,
                                    isRefresh: !this.state.isRefresh
                                })
                            }}
                            autoCapitalize={'words'}
                            keyboardType={'numeric'}
                            style={{ fontWeight: 'bold', marginLeft: 10 }}
                        />
                        :
                        <NewDropDown placeholder={this.state.obvTimeFrame != '' ? this.state.obvTimeFrame : "Time Frame"} 
                        data={this.state.isBasic === true ?  this.state.timeFameIndArrays :this.state.timeFameIndArray}
                        placeHolderName={'Time Frame'}
                        Upgrade={this.screennavigate}
                        isBasic={this.state.isBasic}
                            onSelect={(selectedItem: any) => {
                                this.setState({ obvTimeFrame: selectedItem.value, isRefresh: !this.state.isRefresh }, () => {

                                    this.props.obvPicker(selectedItem.value, this.state.divergenceLable, this.state.obvlabel, this.state.obvPeriod)
                                })
                            }}
                            width={width} mode={this.context.mode} />

                }

            </View>
        )
    }
    // obv main function
    obvRender() {
        return (
            <>
                <NewDropDown placeholder={this.state.obvlabel != '' ? this.state.obvlabel : "Select Screen"} data={this.state.obvArray}
                    onSelect={(selectedItem: any, index: any) => {
                        this.setState({ obvlabel: selectedItem, isRefresh: !this.state.isRefresh }, () => {

                            this.props.obvPicker(this.state.obvTimeFrame, this.state.divergenceLable, this.state.obvlabel, this.state.obvPeriod)
                        })

                        this.onClickobv(index, selectedItem);

                    }}
                    width={width} mode={this.context.mode} />

                {
                    this.obvSelectScreen()
                }
                {this.obvPeriod()}


                {
                    this.obvTimeFrame()
                }


            </>
        )
    }
    // cmf
    cmfDivergence = () => {
        return (
            this.state.isDivergencecmf &&
            <>
                <View style={{ marginTop: 15 }} />
                <NewDropDown placeholder={this.state.divergenceLablecmf != '' ?
                    this.state.divergenceLablecmf : "Divergence"} data={this.state.derivativesArray1}
                    onSelect={(selectedItem: any) => {
                        this.setState({ divergenceLablecmf: selectedItem, isRefresh: !this.state.isRefresh }, () => {

                            this.props.cmfPicker(this.state.cmfTimeFrame, this.state.cmfradio, this.state.divergenceLablecmf, this.state.cmflabel, this.state.cmfPeriod)
                        })
                    }}
                    width={width} mode={this.context.mode} />

            </>
        )
    }
    cmfTimeFrame = () => {
        return (
            this.state.cmfTimeFrameVisible &&
            <View
                style={{
                    marginTop: 10,
                    backgroundColor: this.state.cmfTimeFrame != '' ? this.getColorTheme("light", '#F7F7F7', "#000") :
                        this.getColorTheme("light", '#fff', "#000")
                }}
            >
                {
                    this.state.cmfTimeFrame != '' &&
                    <Text style={[style.subText, { marginLeft: 15, color: this.getColorTheme("light", "#000", "#FFF") }]}>{"Time Frame"}</Text>
                }
                {
                    this.state.cmfTimeFrame === 'Custom' ?
                        <TextInput
                            placeholder={'Custom'}
                            value={this.state.obvCustome}
                            // {...this.txtInputCvv}
                            onChangeText={text => {
                                this.setState({
                                    obvCustome: text,
                                    isRefresh: !this.state.isRefresh
                                })
                            }}
                            autoCapitalize={'words'}
                            keyboardType={'numeric'}
                            style={{ fontWeight: 'bold', marginLeft: 10 }}
                        />
                        :
                        <NewDropDown placeholder={this.state.cmfTimeFrame != '' ? this.state.cmfTimeFrame : "Time Frame"}                         
                        data={ this.state.timeFameIndArray}
                            onSelect={(selectedItem: any) => {
                                this.setState({ cmfTimeFrame: selectedItem.value, isRefresh: !this.state.isRefresh }, () => {

                                    this.props.cmfPicker(selectedItem.value, this.state.cmfradio, this.state.divergenceLablecmf, this.state.cmflabel, this.state.cmfPeriod)
                                })
                            }}
                            width={width} mode={this.context.mode} />


                }

            </View>
        )
    }
    cmfPeriod = () => {
        return (
            this.state.cmfPeriodVisible && <View style={[style.fromTextInput,
            { backgroundColor: this.getColorTheme('light', "#F7F7F7", "#000") }]}>
                {
                    this.state.cmfPeriod != '' &&
                    <Text style={[style.subText,
                    { color: this.getColorTheme('light', "gray", "white") }]}>{'Period'}</Text>

                }
                <TextInput
                    placeholderTextColor={this.getColorTheme('light', "gray", "white")}
                    placeholder={'Period'}
                    value={this.state.cmfPeriod.toString()}
                    // {...this.txtInputCvv}
                    onChangeText={text => {
                        this.setState({
                            cmfPeriod: text,
                            isRefresh: !this.state.isRefresh
                        }, () => {

                            this.props.cmfPicker(this.state.cmfTimeFrame, this.state.cmfradio, this.state.divergenceLablecmf, this.state.cmflabel, this.state.cmfPeriod)
                        })
                    }}
                    autoCapitalize={'words'}
                    keyboardType={'numeric'}
                    style={{
                        fontWeight: 'bold', color: this.getColorTheme('light', "#000", "white"), backgroundColor:
                            this.getColorTheme('light', "#FFF", "#000")
                    }}
                />
            </View>
        )
    }
    // cmf main fxn
    cmfRender() {
        return (
            <>
                <NewDropDown placeholder={this.state.cmflabel != '' ? this.state.cmflabel : "Select Screen"} data={this.state.cmfArray}
                    onSelect={(selectedItem: any, index: any) => {
                        this.setState({ cmflabel: selectedItem, isRefresh: !this.state.isRefresh }, () => {

                            this.props.cmfPicker(this.state.cmfTimeFrame, this.state.cmfradio, this.state.divergenceLablecmf, this.state.cmflabel, this.state.cmfPeriod)
                        })
                        this.onClickcmf(index, selectedItem);

                    }}
                    width={width} mode={this.context.mode} />

                {
                    this.cmfPeriod()
                }
                {
                    this.cmfDivergence()
                }
                {
                    this.cmfTimeFrame()
                }
            </>
        )
    }

    bollingerRender() {
        return (
            <>
                <NewDropDown placeholder={this.state.bollingerlabel != '' ? this.state.bollingerlabel : "Select Screen"} data={this.state.bollingerArray}
                    onSelect={(selectedItem: any, index: any) => {
                        this.setState({ bollingerlabel: selectedItem }, () => {

                            this.props.BollingPicker(this.state.bollingerTimeFrame, this.state.bollingerRadio, this.state.bollingerlabel)
                        })
                        this.onClickBollinger(index, selectedItem);

                    }}
                    width={width} mode={this.context.mode} />

                {
                    this.renderConditionalItem(!this.state.iscmf,
                        <>
                            {
                                this.getByCondition(this.state.derivativesArray1.length, 0,
                                    <>
                                        <View style={[style.fromTextInput,]}>
                                            {
                                                this.getByCondition(this.state.bollingerPeriod, '', null,
                                                    <Text style={style.subText}>{'Period'}</Text>)

                                            }
                                            <TextInput
                                                placeholder={'Period'}
                                                value={this.state.bollingerPeriod.toString()}
                                                onChangeText={text => {
                                                    this.setState({
                                                        bollingerPeriod: text,
                                                        isRefresh: !this.state.isRefresh
                                                    })
                                                }}
                                                autoCapitalize={'words'}
                                                keyboardType={'numeric'}
                                                style={{ fontWeight: 'bold', }}
                                            />
                                        </View>
                                        <SelectDropdown
                                            data={this.state.derivativesArray1}
                                            onSelect={(selectedItem, index) => { }}
                                            defaultButtonText={this.getByCondition(this.state.rsiLabel, '', "Select Screen", this.state.rsiLabel)}
                                            buttonTextAfterSelection={(selectedItem, index) => {
                                                return selectedItem;
                                            }}
                                            rowTextForSelection={(item, index) => {
                                                return item;
                                            }}
                                            renderCustomizedRowChild={(item, index) => { return <Text adjustsFontSizeToFit numberOfLines={3} style={{ fontSize: 18, paddingHorizontal: 15 }}>{item}</Text> }}

                                            buttonStyle={style.dropdown1BtnStyle}
                                            buttonTextStyle={{ color: this.getByCondition(this.state.timeFameIndLabel, '', 'gray', '#000'), textAlign: "left" }}
                                            renderDropdownIcon={(isOpened) => {

                                                return (
                                                    <>
                                                        <Icon
                                                            name={this.getByCondition(isOpened, true, "caretup", "caretdown")}
                                                            color={"#444"}
                                                            size={10}
                                                        />

                                                    </>

                                                );
                                            }}
                                            dropdownIconPosition={"right"}
                                            dropdownStyle={style.dropdown1DropdownStyle}
                                            rowStyle={style.dropdown1RowStyle}
                                            rowTextStyle={style.dropdown1RowTxtStyle}

                                        />
                                    </>
                                    ,
                                    <View style={[style.fromTextInput,
                                    { backgroundColor: this.getColorTheme('light', "#F7F7F7", "#000") }]}>
                                        {
                                            this.state.bollingerPeriod != '' &&
                                            <Text style={[style.subText,
                                            { color: this.getColorTheme('light', "gray", "white") }]}>{'Period'}</Text>

                                        }
                                        <TextInput
                                            placeholderTextColor={this.getColorTheme('light', "gray", "white")}
                                            placeholder={'Period'}
                                            value={this.state.bollingerPeriod.toString()}
                                            // {...this.txtInputCvv}
                                            onChangeText={text => {
                                                this.setState({
                                                    bollingerPeriod: text,
                                                    isRefresh: !this.state.isRefresh
                                                })
                                            }}
                                            autoCapitalize={'words'}
                                            keyboardType={'numeric'}
                                            style={{
                                                fontWeight: 'bold', color: this.getColorTheme('light', "#000", "white"), backgroundColor:
                                                    this.getColorTheme('light', "#FFF", "#000")
                                            }}
                                        />
                                    </View>
                                )
                            }
                        </>)
                }


                {
                    this.renderConditionalItem(this.state.bollingerRadio,
                    <>
                        <View style={{ marginTop: 10 }} />
                        <View style={{ marginTop: 10, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: width / 1.1 }}>
                            <View style={style.radioView}>
                                <View style={style.radioViewBtn}>
                                    <TouchableOpacity style={[style.radioBtn,
                                    { backgroundColor: this.state.radioAbove === 'Above' ? '#4D9C5A' : '#F6F6F6' }]}
                                        onPress={() => this.setState({ radioAbove: 'Above', isRefresh: !this.state.isRefresh }, () => {
                                            this.props.BollingPicker(this.state.bollingerTimeFrame, this.state.radioAbove, this.state.bollingerlabel)
                                        })}
                                    >

                                    </TouchableOpacity>
                                </View>
                                <Text style={style.radioTtext}>{"Above"}</Text>

                            </View>
                            <View style={style.radioView}>
                                <View style={style.radioViewBtn}>
                                    <TouchableOpacity style={[style.radioBtn,
                                    { backgroundColor: this.getByCondition(this.state.radioAbove, 'Below', '#4D9C5A', '#F6F6F6') }]}
                                        onPress={() => this.setState({ radioAbove: 'Below', isRefresh: !this.state.isRefresh }, () => {
                                            this.props.BollingPicker(this.state.bollingerTimeFrame, this.state.radioAbove, this.state.bollingerlabel)
                                        })}>
                                    </TouchableOpacity>
                                </View>
                                <Text style={style.radioTtext}>{"Below"}</Text>
                            </View>
                        </View>
                        <View style={{ marginTop: 10 }} />
                    </>)
                }
                <View
                    style={{
                        marginTop: 10,
                        backgroundColor: this.state.bollingerTimeFrame != '' ? this.getColorTheme("light", '#F7F7F7', "#000") :
                            this.getColorTheme("light", '#fff', "#000")
                    }}
                >
                    {
                        this.renderConditionalItem(this.state.bollingerTimeFrame != '' ,
                        <Text style={[style.subText, { marginLeft: 15, color: this.getColorTheme("light", "#000", "#FFF") }]}>{"Time Frame"}</Text>)
                    }
                    {
                        this.getByCondition(this.state.timeFameIndLabel, 'Custom',
                            <TextInput
                                placeholder={'Custom'}
                                value={this.state.customInd}
                                onChangeText={text => {
                                    this.setState({
                                        customInd: text,
                                        isRefresh: !this.state.isRefresh
                                    })
                                }}
                                autoCapitalize={'words'}
                                keyboardType={'numeric'}
                                style={{ fontWeight: 'bold', marginLeft: 10 }}
                            />
                            ,
                            <NewDropDown placeholder={this.state.bollingerTimeFrame != '' ? this.state.bollingerTimeFrame : "Time Frame"} data={this.state.timeFameIndArray}
                                onSelect={(selectedItem: any) => {
                                    this.setState({ bollingerTimeFrame: selectedItem.value, isRefresh: !this.state.isRefresh }, () => {
                                        this.props.BollingPicker(selectedItem.value, this.state.radioAbove, this.state.bollingerlabel)
                                    })
                                }}
                                width={width} mode={this.context.mode} />
                        )
                    }

                </View>
            </>
        )
    }

    atrRender() {
        return (
            <>
                <NewDropDown placeholder={this.state.atrlabel != '' ? this.state.atrlabel : "Select Screen"}
                 data={this.state.atrArray}
                    onSelect={(selectedItem: any, index: any) => {
                        this.setState({ atrlabel: selectedItem }, () => {

                            this.props.atrPicker(this.state.atrTimeFrame, this.state.atrPeriod, this.state.atrlabel, this.state.trending_period)
                        })
                        this.onClickatr(index, selectedItem);

                    }}
                    width={width} mode={this.context.mode} />


                <View style={[style.fromTextInput,
                { backgroundColor: this.getColorTheme('light', "#F7F7F7", "#000") }]}>
                    {
                        this.state.atrPeriod != '' &&
                        <Text style={[style.subText
                            , { color: this.getColorTheme('light', "gray", "white") }]}>{'Period'}</Text>

                    }
                    <TextInput
                        placeholderTextColor={this.getColorTheme('light', "gray", "white")}
                        placeholder={'Period'}
                        value={this.state.atrPeriod.toString()}
                        onChangeText={text => {
                            this.setState({
                                atrPeriod: text,
                                isRefresh: !this.state.isRefresh
                            }, () => {

                                this.props.atrPicker(this.state.atrTimeFrame, this.state.atrPeriod, this.state.atrlabel, this.state.trending_period)
                            })
                        }}
                        autoCapitalize={'words'}
                        keyboardType={'numeric'}
                        style={{
                            fontWeight: 'bold', color: this.getColorTheme('light', "#000", "white"), backgroundColor:
                                this.getColorTheme('light', "#FFF", "#000")
                        }}
                    />
                </View>
                <View style={[style.fromTextInput,
                { backgroundColor: this.getColorTheme('light', "#F7F7F7", "#000") }]}>
                    {
                        this.state.trending_period != '' &&
                        <Text style={[style.subText, { color: this.getColorTheme('light', "gray", "white") }]}>{'Trending Period'}</Text>

                    }
                    <TextInput
                        placeholderTextColor={this.getColorTheme('light', "gray", "white")}
                        placeholder={'Trending Period'}
                        value={this.state.trending_period.toString()}
                        onChangeText={text => {
                            this.setState({
                                trending_period: text,
                                isRefresh: !this.state.isRefresh
                            }, () => {

                                this.props.atrPicker(this.state.atrTimeFrame, this.state.atrPeriod, this.state.atrlabel, this.state.trending_period)
                            })
                        }}
                        autoCapitalize={'words'}
                        keyboardType={'numeric'}
                        style={{
                            fontWeight: 'bold', color: this.getColorTheme('light', "#000", "white"), backgroundColor:
                                this.getColorTheme('light', "#FFF", "#000")
                        }}
                    />
                </View>
                <View
                    style={{
                        marginTop: 10,
                        backgroundColor: this.state.atrTimeFrame != '' ? this.getColorTheme("light", '#F7F7F7', "#000") :
                            this.getColorTheme("light", '#fff', "#000")
                    }}
                >
                    {
                        this.state.atrTimeFrame != '' &&
                        <Text style={[style.subText, { marginLeft: 15, color: this.getColorTheme("light", "#000", "#FFF") }]}>{"Time Frame"}</Text>
                    }
                    {
                        this.getByCondition(this.state.atrTimeFrame, 'Custom',
                            <TextInput
                                placeholder={'Custom'}
                                value={this.state.atrCustome}
                                onChangeText={text => {
                                    this.setState({
                                        atrCustome: text,
                                        isRefresh: !this.state.isRefresh
                                    })
                                }}
                                autoCapitalize={'words'}
                                keyboardType={'numeric'}
                                style={{ fontWeight: 'bold', marginLeft: 10 }}
                            />
                            ,
                            <NewDropDown placeholder={this.state.atrTimeFrame != '' ? this.state.atrTimeFrame : "Time Frame"} 
                            data={this.state.isBasic === true ?  this.state.timeFameIndArrays :this.state.timeFameIndArray}
                            placeHolderName={'Time Frame'}
                            isBasic={this.state.isBasic}
                            Upgrade={this.screennavigate}
                                onSelect={(selectedItem: any) => {
                                    this.setState({ atrTimeFrame: selectedItem.value, isRefresh: !this.state.isRefresh }, () => {
                                        this.props.atrPicker(selectedItem.value, this.state.atrPeriod, this.state.atrlabel, this.state.trending_period)
                                    })

                                }}
                                width={width} mode={this.context.mode} />
                        )

                    }

                </View>
            </>
        )
    }

    // Customizable Area End
    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <View style={style.container}>
                <View style={{ width: width / 1.05, paddingBottom: 40 }}>
                    <FlatList
                    testID="renderItem"
                        data={this.state.indicatorsArray}
                        renderItem={this.renderItem}
                        extraData={[this.state.isRefresh, this.state.mvAvglabel]}
                    />
                </View>
                <View style={{ flexDirection: "row", marginLeft: "auto", marginRight: "auto", paddingBottom: 25 }} >
                    <TouchableOpacity
                        testID="btnExample"
                        onPress={
                            () => {
                                //@ts-ignore
                                this.props.save()
                            }
                        }
                        style={[style.saveBtn, { marginRight: 15 }]}>
                        <CustomText style={style.saveTxt}>{this.SaveBtn}</CustomText>
                    </TouchableOpacity>
                    <TouchableOpacity
                        onPress={
                            () => {
                                //@ts-ignore
                                this.props.saveApply()
                            }
                        }
                        style={style.saveBtn}>
                        <CustomText style={style.saveTxt}>Save & Apply</CustomText>
                    </TouchableOpacity>


                </View>
            </View>
            // Customizable Area End
        )
    }
}

const style = StyleSheet.create({
    // Customizable Area Start
    saveBtn: { marginTop: 6, height: 35, width: 100, backgroundColor: "green", alignItems: "center", justifyContent: "center" },
    saveTxt: { fontSize: 15, color: "black" },
    container: {
        flex: 1,
        alignItems: 'center'
    },
    rowStyle: {
        flexDirection: 'row',
        alignItems: 'center',
        margin: 10

    },
    boxStyle: {
        height: 24,
        width: 24,
        borderWidth: 1,
        borderRadius: 4,
        borderColor: '#9DA4A2',
        alignItems: 'center', justifyContent: 'center'
    },
    nameStyle: {
        fontSize: 16,
        marginLeft: 15,
        color: '#9DA4A2',
        fontWeight: 'bold'

    },

    dropdowncontainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    dropdownToggle: {
        fontSize: 20,
        color: 'blue',
    },
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    overlay: {
        flex: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    dropdownContent: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: [{ translateX: -50 }, { translateY: -50 }],
        backgroundColor: 'white',
        padding: 20,
        borderRadius: 10,
        elevation: 5,
    },
    dropdownItem: {
        fontSize: 18,
        paddingVertical: 10,
    },

    dropdown1BtnStyle: {
        width: width / 1.05,
        height: 60,
        borderRadius: 8,
    },
    dropdown1BtnTxtStyle: { color: "gray", textAlign: "left" },
    dropdown1DropdownStyle: {
        elevation: 50,
        opacity: 0.9,
        borderRadius: 10,
        shadowColor: 'green',
        shadowOpacity: 0.26,
        shadowOffset: { width: 0, height: 2 },
        shadowRadius: 10,
        backgroundColor: '#fff'
    },
    dropdown1RowStyle: {
        backgroundColor: "#fff",
        paddingVertical: 20,
        borderBottomColor: "#C5C5C5",

    },
    dropdown1RowTxtStyle: { color: "#000", textAlign: "left", },
    fromTextInput: {
        backgroundColor: '#F7F7F7',
        borderRadius: 10,
        padding: 13,
        marginTop: 10
    },
    subText: {
        marginLeft: 5,
    },
    dropDownTimeFram: {
        backgroundColor: '#F7F7F7',
        width: width / 1.1

    },
    radioView: {
        flexDirection: 'row',
    },
    radioViewBtn: {
        height: 22, width: 22,
        borderRadius: 22 / 2,
        backgroundColor: "#F6F6F6",
        alignItems: "center",
        justifyContent: "center"
    },
    radioBtn: {
        height: 16, width: 16,
        borderRadius: 16 / 2,
    },
    radioTtext: {
        fontWeight: 'bold',
        fontSize: 16,
        marginLeft: 5,
        left: 8
    },
    rowTextStyleMv: { fontSize: 16, fontFamily: 'PlusJakartaText-Regular' }
    // Customizable Area End
})

export const logo = require("../assets/logo.png");
export const menu = require("../assets/menu.png");
export const cryptoLogo = require("../assets/cryptoLogo.png");
export const newCryptoLogo = require("../assets/newCryptoLogo.png");
export const cryptoLightLogo = require("../assets/cryptoLightLogo.png");
export const cryptoLogoDark = require("../assets/cryptoLogoDark.png");
export const bitcoinLogo = require("../assets/bitcoin.png");
export const diamondLogo = require("../assets/diamond.png");
export const downLogo = require("../assets/down.png");

export const mobileOnePng = require("../assets/threed_mockup_one.png");
export const mobileTwoPng = require("../assets/threed_mockup.png");
export const screenerPng = require("../assets/scanner.png");
export const newsPng = require("../assets/news.png");
export const education = require("../assets/education.png");
export const educationImage = require("../assets/educationBanner.png");

export const graphOne = require("../assets/graph-one.png");
export const graphTwo = require("../assets/graph-two.png");

export const cryptoKiaImg = require("../assets/cryptoKia.png");
export const playStoreImg = require("../assets/playstore.png")
export const appStoreImg = require("../assets/appStore.png")
export const closeIcon = require("../assets/Close.png")
export const blockChainImg = require("../assets/blockChain.png");
export const ethereumImg = require("../assets/ethereum.png");
export const cryptoImg = require("../assets/crypto.png");
export const technicalImg = require("../assets/technical.png");
export const eduBackgroundImage = require("../assets/Crypto_EducationCentreImage.png");
export const eduBackground = require("../assets/educationCenterBackground.png");
export const paypalImage = require("../assets/paypal.svg");
export const subscriptionImage = require("../assets/My Subscription.png");
export const subscriptionSVG = require("../assets/My_Subscription.png") ;
export const subscribeToPro = require("../assets/subscribeToPro.png");
export const Angry = require("../assets/angrydictatorcb.png") ;
export const confirmImg = require("../assets/check.png");
// menu Icon dark mood
export const menuIcProfile=require("../assets/image_profile-circle.png")
export const menuIcLogout=require("../assets/logout.png")
export const menuIcSub=require("../assets/My Subscription.png")
export const menuIcPortF=require("../assets/Portfolio.png")
export const menuIcOrderS=require("../assets/Order Summary.png")
export const menuIcWatchL=require("../assets/Watchlist.png")
export const menuIcManageC=require("../assets/Manage Cards.png")
export const menuIcContactU=require("../assets/Contact Us.png")
export const menuIcDarkMood=require("../assets/Dark Mode.png")
export const menuIcRefferal=require("../assets/referrals.png");
export const menuIcRewards=require("../assets/rewards.png");
export const darkYouTubeImg = require("../assets/darkYoutube.png")
export const darkLinkdinImg = require("../assets/darkLinkdin.png")
export const darkInstaImg = require("../assets/darkInstagram.png");
export const darkTwitterImg = require("../assets/darkTwitter.png");
export const instaImg = require("../assets/instagram.png");
export const twitterImg = require("../assets/twitter.png");
export const youTubeImg = require("../assets/youtube.png");
export const linkdinImg = require("../assets/linkdin.png");
// menu Icon lite mood 

export const liteIcMood=require("../assets/light mode.png")
export const liteIcContact=require("../assets/lite_contact us.png")
export const liteIcProfile=require("../assets/lite_profile.png")
export const liteIcManageC=require("../assets/lite_manage cards.png")
export const liteIcOrderS=require("../assets/lite_order summary.png")
export const liteIcWatchL=require("../assets/lite_watchlist.png")
export const liteIcPortF=require("../assets/image_portfolio.png")
export const liteIcRefferal=require("../assets/lite_referral.png");
export const liteIcRewards=require("../assets/lite_rewards.png");
export const SubSv = require("../assets/SubSv.png");

export const noAddsImg = require("../assets/no-adds.png");
export const screeningImg = require("../assets/screening.png");
export const realtimeImg = require("../assets/real-time-alert.png");
export const dipAlertImg = require("../assets/dip-alert.png");
export const discordImg = require("../assets/discord.png");
export const tokenImg = require("../assets/tokens.png");
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Alert, AsyncStorage, Keyboard } from 'react-native'
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { CommonContext } from "../../../framework/src/CommonContextProvider";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  route: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  cardNo: any;
  month: string;
  monthListArray: any;
  year: string;
  yearListArray: any;
  cvv: any,
  visible: boolean,
  visibleYeaar: boolean;
  isFocusedCvv: boolean;
  name: any;
  isFoucueName: boolean;
  isFoucueCardNo: boolean;
  nameError: boolean;
  cardnoError: boolean;
  cvvError: boolean;
  monthError: boolean;
  yearError: boolean;
  nameLabel: string;
  cardNoLabel: string;
  cvvLabel: string;
  monthYearLabel: string;
  cvvlength:boolean;
  disableSavebtn:boolean,
  submitted:boolean,
  cvvInValid:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PaymentsController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  static contextType=CommonContext

  apiAddCardId: string;
  apiEditCardId: string;
  emailInputRef: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    // Customizable Area End

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      submitted: false,
      cardNo: '',
      month: '',
      monthListArray:[],
      year:'',
      yearListArray: [],
      cvv: '',
      visible: false,
      visibleYeaar: false,
      isFocusedCvv: false,
      name: "",
      isFoucueName: false,
      isFoucueCardNo: false,
      nameError: false,
      cardnoError: false,
      cvvError: false,
      monthError: false,
      yearError: false,
      cardNoLabel: 'card number is required',
      nameLabel: 'name is required',
      cvvLabel:'cvv is required',
      monthYearLabel: '',
      cvvlength:false,
      disableSavebtn:false,
      cvvInValid:false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);


  }

  async receive(from: string, message: Message) {
// Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }
// Customizable Area End
    // Customizable Area Start
    else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      console.log("errorResponse>>", errorReponse)
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apiAddCardId) {
          if (responseJson) {
            this.setState({disableSavebtn:false})
            Alert.alert(
              "Congratulations",
              "Your have successfully saved card information",
              [
                {
                  text: "OK",
                  onPress: () =>
                  this.props.navigation.navigate('SavedCards')
                }
              ],
              { cancelable: false }
            );
          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);

          }
        } else if (this.apiEditCardId === apiRequestCallId) {

          if (responseJson) {
            this.setState({disableSavebtn:false})
            Alert.alert(
              "",
              "Your have successfully edited card information",
              [
                {
                  text: "OK",
                  onPress: () =>
                    this.props.navigation.navigate('SavedCards')
                }
              ],
              { cancelable: false }
            );
          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);

          }
        }
      }else{
         this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End

  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // Customizable Area Start
  calAddCard = async()=>{
    this.setState({ disableSavebtn: true })
    const token = await AsyncStorage.getItem("userToken")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };
    const attrs = {
      "name": this.state.name,
      "card_number": this.state.cardNo,
      "month": this.state.month,
      "year": this.state.year,
      "cvv": this.state.cvv,
      "is_primary": this.props.route.params ? this.props.route.params.isPrimary ? true : false : false
    };
    const httpBody = {
      data: attrs
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiAddCardId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addCardEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  addCardData = async () => {
    const { cardNo, name, month, year, cvv, } = this.state;
    let noErrorName = name !== '' ? true : false;
    let validCardNumber = this.isCreditCardValid(cardNo);
    let errorCvv = !this.isValidCVV(cvv);
    if(errorCvv){
      this.setState({cvvError:true})
    }
    if(!noErrorName){
      this.setState({nameError:true})
    }
    if (noErrorName && validCardNumber && !errorCvv && month !== '' && year !== '') {
     this.calAddCard();
    }
  }

  callEditCard = async(id:any)=>{
    this.setState({ disableSavebtn: true })
    const token = await AsyncStorage.getItem("userToken")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };
    if (this.props.route.params.primaray) {
      var attrs = {
        "name": this.state.name,
        "card_number": this.state.cardNo,
        "month": this.state.month,
        "year": this.state.year,
        "cvv": this.state.cvv,
        "is_primary": true
      };
    } else {
      var attrs = {
        "name": this.state.name,
        "card_number": this.state.cardNo,
        "month": this.state.month,
        "year": this.state.year,
        "cvv": this.state.cvv,
        "is_primary": false
      };
    }
    const httpBody = {
      data: attrs
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEditCardId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.cardEditApiEndPoint + id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.PutMethos
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  editCardData = async (id: any) => {
    const { cardNo, name, month, year, cvv, } = this.state;
    let noErrorName = name !== '' ? true : false;
    let noErrorCardNo = this.isCreditCardValid(cardNo);
    let errorCvv = !this.isValidCVV(cvv);
    if(errorCvv){
      this.setState({cvvError:true})
    }
    if (noErrorName && noErrorCardNo && !errorCvv && month !== '' && year !== '') {
      this.callEditCard(id);
    }
  }

  txtInputName = {
    onChangeText: (text: string) => {
      this.setState({ name: text.replace(/[^a-zA-Z ]/g, "") },()=>{
        if(this.state.name !== ''){
          this.setState({nameError:false});
        }
      });
    },

  };

  txtInputCardNo = {
    onChangeText: (text: string) => {
     this.validateCreditCard(text);
      this.setState({
        cardNo: text,
      });
    },
  };

  txtInputCvv = {
    onChangeText: (text: string) => {
      let cvvLabel = '';
      let cvvError = false;
      let cvv = text.replace(/\D/g, '');
      if (text === '') {
        cvvLabel = configJSON.cvvRequiredMessage;
      } else if (!this.isValidCVV(text)) {
        cvvLabel = configJSON.cvvInvalidMessage;
        cvvError = true;
      }
      this.setState({ cvvLabel, cvvError, cvv });
    },
  };

  validateEditData=()=>{
    const item = this.props.route.params.item;
    const cardNo = item?.card_number;
    if(this.isCreditCardValid(cardNo)){
      this.setState({cardnoError:false})
    }
  }

  isCreditCardValid(cardNumberVal:any) {
    return this.validateCreditCard(cardNumberVal);
  }
  validateCreditCard(cardNum: any) {
    const cardNumber = cardNum.replace(/ /g, '');

    if (cardNumber === '') {
      this.setState({ cardNoLabel: configJSON.cardNoRequireMessage });
      this.setState({ cardnoError: true });
      return false;
    }
    
    if (cardNumber[0] === '0') {
      this.setState({ cardNoLabel: configJSON.cardNoValidationLabel2 });
      this.setState({ cardnoError: true });
      return false;
    }

    if (cardNumber.length !== 16) {
      this.setState({ cardNoLabel: configJSON.cardNoValidationLabel });
      this.setState({ cardnoError: true });
      return false;
    }
  
    if (!/^\d+$/.test(cardNumber)) {
      this.setState({ cardnoError: true });
      return false;
    }
    console.log("card",cardNumber);
    this.setState({ cardnoError: false });
    return true;
  }

  isValidCVV(cvv:any) {
    const cvvStripped = cvv.replace(/\D/g, '');
    return /^\d{3,4}$/.test(cvvStripped)&& cvv !== '000';
  }

  renderAllMonths = () => {
    const months = [];
    for (let month = 1; month <= 12; month++) {
      const label = month.toString().padStart(2, '0');
      months.push({ label, value: month });
    }
  
    return months;
  };
  generateMonths = () => {
    const { year } = this.state;
    const currentYearLastTwoDigits = new Date().getFullYear().toString();
    const currentMonth = new Date().getMonth() + 1;
    const months = [];
    for (let month = 1; month <= 12; month++) {
      if (parseInt(year) > parseInt(currentYearLastTwoDigits) || (parseInt(year) === parseInt(currentYearLastTwoDigits) && month >= currentMonth)) {
        const monthLabel = month < 10 ? `0${month}` : month.toString();
        months.push({ label: monthLabel, value: month });
      }
    }
    return months;
  };

  generateYears = () => {
    const years = [];
    const currentYear = new Date().getFullYear();
    for (let year = currentYear; year < currentYear + 50; year++) {
      years.push({ label: `${year.toString()}`, value: year.toString() });
    }
    return years;
  };
  // Customizable Area End
  
}

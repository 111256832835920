import React from "react";

import NotificationWebController, {
    Props
} from "./NotificationController.web";

import { withStyles } from "@material-ui/core/styles";

import { Box } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Badge from '@material-ui/core/Badge';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { liteIcProfile, menuIcProfile } from "../../landingpage/src/assets";
import Loader from "../../../components/src/Loader.web";

export class NotificationWeb extends NotificationWebController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    timestamp = (timestamp: any) => {
        const dateObj = new Date(timestamp);
        const date = dateObj.toISOString().split('T')[0];
        const time = dateObj.toTimeString().split(' ')[0];
        return (
            <Typography
                component="span"
                variant="body2"
                color="textPrimary"
                style={{ fontSize: '10px', textAlign: 'right', marginTop: '5px' }}>
                {time}/{date}
            </Typography>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { anchorEl, notificationData, loading } = this.state;
        const { classes } = this.props;
        return (
            <Box>
                <IconButton aria-label="notification" onClick={this.openDialog} style={{ height: '35px' }}>
                    <Badge className={classes.badgeIcon} color="secondary" variant="dot" invisible={this.makeDotInvisible()}>
                        <NotificationsNoneIcon fontSize="small" />
                    </Badge>
                </IconButton>
                {/* Modal Dialogues */}
                <Popover
                    id={Boolean(anchorEl) ? 'simple-popover' : undefined}
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={this.closeDialog}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <List style={{ backgroundColor: localStorage.getItem('appTheme') == 'darkTheme' ? '#232429' : '#f4f8f6' }}
                        subheader={
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <ListSubheader component="div" id="nested-list-subheader"
                                    style={{
                                        fontSize: '16px',
                                        fontWeight: 'bold',
                                        fontFamily: 'Plus Jakarta Text'
                                    }}
                                >
                                    Notifications
                                </ListSubheader>
                                <IconButton aria-label="delete" onClick={this.closeDialog}>
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </div>
                        }
                    >
                        {notificationData?.length > 0 ?
                            <div style={{ height: '450px', overflowY: 'scroll' }}>
                                <Loader loading={loading} />
                                {notificationData.map((item: any) => {
                                    return (
                                        <ListItem
                                            alignItems="flex-start"
                                            style={{
                                                cursor: item.attributes.is_read ? 'default' : 'pointer'
                                            }}
                                            onClick={() => item.attributes.is_read ? console.log('already marked') : this.markAsReadNotification(item.id)}
                                        >
                                            <ListItemIcon style={{ display: 'flex', justifyContent: 'center', paddingTop: '10px' }}>
                                                {localStorage.getItem('appTheme') == 'darkTheme' ?
                                                    <img src={menuIcProfile} style={{ filter: "invert() sepia(100%) saturate(0%) hue-rotate(33deg) brightness(100%)" }} height={22} width={22} /> :
                                                    <img src={liteIcProfile} height={22} width={22} />}
                                            </ListItemIcon>
                                            <ListItemText
                                                style={{ width: '325px' }}
                                                primary={
                                                    <Typography variant="body2" style={{
                                                        fontSize: '14px',
                                                        fontWeight: item.attributes.is_read ? 'initial' : 'bold',
                                                        marginTop: '10px'
                                                    }}>
                                                        {item.attributes.headings}
                                                    </Typography>
                                                }
                                                secondary={
                                                    <div style={{ display: 'flex', flexDirection: 'column'}}>
                                                        <Typography
                                                            component="span"
                                                            variant="body2"
                                                            color="textPrimary"
                                                        >
                                                            {item.attributes.contents}
                                                        </Typography>
                                                        {this.timestamp(item.attributes.created_at)}
                                                    </div>
                                                }
                                            />
                                        </ListItem>
                                    )
                                })}
                            </div> :
                            <ListItem alignItems="flex-start" >
                                <Typography
                                    component="span"
                                    variant="body2"
                                    color="textPrimary"
                                    style={{ fontSize: '18px' }}
                                >
                                    No Notification Available!
                                </Typography>
                            </ListItem>}
                    </List>
                </Popover>
            </Box>
        )
        // Customizable Area End
    }
}


// Customizable Area Start
export const NotificationStyles = (theme: any) => ({
    badgeIcon: {
        "& .MuiBadge-colorSecondary": {
            backgroundColor: 'rgb(1, 162, 78)'
        }
    },
})

//@ts-ignore
export default withStyles(NotificationStyles)(NotificationWeb);
// Customizable Area End
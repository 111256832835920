import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import { cryptoLightLogo, cryptoLogoDark } from "../../blocks/landingpage/src/assets";
import Sidebar from './Sidebar.web';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { toast } from 'react-toastify';
import GlobalSearchWeb from "../../blocks/landingpage/src/GlobalSearch.web";
import NotificationWeb from "../../blocks/info-page/src/Notification.web";

interface myProps {
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '20px 15px',
        justifyContent: 'space-between'
    },
    forLogo: {
        width: '12%',
        [theme.breakpoints.down('sm')]: {
        }
    },
    saLogo: {
        height: '100px',
        width: '100px',
        marginLeft: '15%',
        [theme.breakpoints.down('sm')]: {
            marginLeft: '0%'
        },
        [theme.breakpoints.down(425)]: {
            marginLeft: '-50%'
        },
    },
    forMenues: {
        display: 'flex',
        width: '45%',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down(1140)]: {
            display: 'none',
        }
    },
    forMenuItems: {
        fontSize: 14,
        fontWeight: 600,
        textDecoration: 'none',
        color: '#b4c1bb',
        cursor: 'pointer',
        paddingBottom: '6px'
    },
    activeMenuItem: {
        color: localStorage.getItem('appTheme') == 'darkTheme' ? '#499e5a' : '#181A19',
        position: 'relative',
        '&::after': {
            content: '""',
            textDecoration: 'none',
            position: 'absolute',
            width: '50%',
            height: '100%',
            left: 0,
            marginLeft: '25%',
            borderBottom: localStorage.getItem('appTheme') == 'darkTheme' ? 'solid 3px #499e5a' : 'solid 3px #181A19'
        }
    },
    rightItems: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    loginButton: {
        backgroundColor: 'rgba(1,162,78,255)',
        color: '#ffffff',
        cursor: 'pointer',
        padding: '8px 20px',
        margin: '0px 10px',
        border: 'none',
        borderRadius: '10px',
        textAlign: 'center',
        [theme.breakpoints.down(525)]: {
            padding: '4px 10px',
            margin: '0px 0px 0px 10px'
        },
        [theme.breakpoints.down(375)]: {
            padding: '0px 5px',
            margin: '0px 0px 0px 10px'
        }
    },
    logoutButton: {
        backgroundColor: 'rgba(1,162,78,255)',
        color: '#ffffff',
        cursor: 'pointer',
        padding: '8px 20px',
        margin: '0px 10px',
        border: 'none',
        borderRadius: '10px',
        textAlign: 'center',
        [theme.breakpoints.down(450)]: {
            display: 'none'
        }
    },
    signUpButton: {
        color: '#ffffff',
        cursor: 'pointer',
        padding: '8px 20px',
        margin: '0px 10px',
        whiteSpace: 'nowrap',
        border: 'none',
        borderRadius: '10px',
        textAlign: 'center',
        [theme.breakpoints.down(525)]: {
            padding: '4px 10px',
            margin: '0px 0px 0px 10px'
        },
        [theme.breakpoints.down(425)]: {
            display: 'none',
            margin: '0px 0px 0px 10px'
        }
    },
    menuBar: {
        display: 'none',
        [theme.breakpoints.down(1140)]: {
            display: 'flex',
            justifyContent: 'center',
            width: '25%'
        }
    },
    icon: {
        [theme.breakpoints.down('sm')]: {
            padding: '0px'
        }
    },
    toastCSS: {
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            width: '50%',
        },
        [theme.breakpoints.between('sm', 'md')]: {
            width: '70%',
        },
    },
}));

export default function TopNavBar(props: myProps) {

    const classes = useStyles();
    const [anchorMenu, setAnchorMenu] = React.useState(null);

    const open = Boolean(anchorMenu);

    const handleMenuClick = (event: any) => {
        setAnchorMenu(event.currentTarget);
    };

    const onImgClick = () => {
        if (window.location.pathname !== "/") {
            window.location.href = "/";
        }
    }
    console.log("localStorage000", localStorage.getItem('appTheme'));
    return (
        <Paper elevation={0} square style={{ backgroundColor: localStorage.getItem('appTheme') == 'darkTheme' ? '#232429' : '#F4F8F6' }}>
            <div className={classes.root}>
                <div>
                    <Sidebar />
                </div>
                <div className={classes.forLogo}>
                    <img src={localStorage.getItem('appTheme') == 'darkTheme' ? cryptoLogoDark : cryptoLightLogo } alt="Poster" className={classes.saLogo} onClick={onImgClick} style={{ cursor: 'pointer' }} />
                </div>
                <div className={classes.menuBar}>
                    <Button
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleMenuClick}
                    >
                        Menu
                    </Button>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorMenu}
                        open={open}
                        onClose={() => setAnchorMenu(null)}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem id="home" onClick={(e) => {
                            window.location.href = "/"
                            setAnchorMenu(null)
                        }}>Home</MenuItem>
                        <MenuItem id="screener" onClick={(e) => {
                            window.location.href = "/ScreenerWeb"
                            setAnchorMenu(null)
                        }}>Screener</MenuItem>
                        <MenuItem id="newPortal" onClick={(e) => {
                            window.location.href = "/NewsPortalWeb"
                            setAnchorMenu(null)
                        }}>News Portal</MenuItem>
                        <MenuItem id="educationCenter" onClick={(e) => {
                            window.location.href = "/EducationCenterLandingWeb"
                            setAnchorMenu(null)
                        }}>Education Center</MenuItem>
                        <MenuItem id="App Download" onClick={(e) => {
                            window.open('https://play.google.com/store/apps/details?id=com.coinmarketcap.android&hl=en&gl=US', '_blank');
                            setAnchorMenu(null)
                        }}>App Download</MenuItem>
                        <MenuItem id="subscribeToPro" onClick={(e) => {
                            window.location.href = "/SubscribeToProWeb"
                            setAnchorMenu(null)
                        }}>Subscribe To Pro</MenuItem>
                    </Menu>
                </div>
                <div className={classes.forMenues} id="menuLinks">
                    {window.location.pathname === '/' && <span
                        onClick={(e) => {
                            if (window.location.pathname !== "/") {
                                window.location.href = "/";
                            }
                        }}
                        className={`${classes.forMenuItems} ${window.location.pathname === '/' ? classes.activeMenuItem : undefined}`}
                        id="home"
                    >
                        Home
                    </span>}
                    <span
                        onClick={(e) => {
                            window.location.href = "/ScreenerWeb";
                        }}
                        className={`${classes.forMenuItems} ${window.location.pathname === '/ScreenerWeb' ? classes.activeMenuItem : undefined}`}
                        id="screener"
                    >
                        Screener
                    </span>
                    <span
                        onClick={(e) => {
                            window.location.href = "/NewsPortalWeb";
                        }}
                        className={`${classes.forMenuItems} ${window.location.pathname === '/NewsPortalWeb' ? classes.activeMenuItem : undefined}`}
                        id="newPortal"
                    >
                        News Portal
                    </span>
                    <span
                        onClick={(e) => {
                            window.location.href = "/EducationCenterLandingWeb";
                        }}
                        className={`${classes.forMenuItems} ${window.location.pathname === '/EducationCenterLandingWeb' || window.location.pathname === '/EducationCenterIntermediateWeb' || window.location.pathname === '/EducationCenterWeb' ? classes.activeMenuItem : undefined}`}
                        id="educationCenter"
                    >
                        Education Center
                    </span>
                    <span
                        onClick={(e) => {
                            window.open('https://play.google.com/store/apps/details?id=com.coinmarketcap.android&hl=en&gl=US', '_blank');
                        }}
                        className={`${classes.forMenuItems}`}
                        id="App Download"
                    >
                        App Download
                    </span>
                    <span
                        onClick={(e) => {
                            window.location.href = "/SubscribeToProWeb";
                        }}
                        className={`${classes.forMenuItems} ${window.location.pathname === '/SubscribeToProWeb' || window.location.pathname === '/SubscribeWeb' ? classes.activeMenuItem : undefined}`}
                        id="subscribeToPro"
                    >
                        {window.location.pathname === '/SubscribeToProWeb' ? 'Subscribe To Pro' : 'Subscribe'}
                    </span>
                </div>
                {window.innerWidth <= 600 ? null : <span style={{ flex: '1 1 auto' }}></span>}
                <div className={classes.rightItems}>
                    <GlobalSearchWeb navigation={undefined} id="" />
                    <NotificationWeb navigation={undefined} id="" />
                    {localStorage.getItem('token') == null && <button type="button" className={classes.signUpButton} onClick={(e: any) => {
                        window.location.href = '/EmailAccountRegistrationWeb';
                    }}
                        style={{ backgroundColor: localStorage.getItem('appTheme') == 'ligthTheme' ? 'black' : '#333742' }}
                    >Sign Up</button>}
                    {localStorage.getItem('token') == null && <button type="button" className={classes.loginButton} onClick={(e: any) => {
                        window.location.href = '/EmailAccountLoginWeb';
                    }}>Login</button>}
                    {localStorage.getItem('token') != null && <button type="button" className={classes.logoutButton} onClick={(e: any) => {
                        toast.success('User logged out successfully', { className: classes.toastCSS });
                        setTimeout(() => {
                            localStorage.removeItem('token');
                            window.location.href = '/';
                        }, 2000);
                    }}
                    >
                        Logout
                    </button>
                    }
                </div>
            </div>
        </Paper>
    )
}

import React from "react";
// Customizable Area Start
import  SavedFiltersControllerWeb, { Props } from "./SavedFiltersController.web";
import {
    withStyles, StyleRules
} from "@material-ui/core/styles";
import Loader from "../../../components/src/Loader.web";

import Paper from "@material-ui/core/Paper";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import TablePagination from '@material-ui/core/TablePagination';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import LoginAlertDialogWeb from "../../../components/src/LoginAlertDialog.web";
import { isEmpty } from "../../../framework/src/Utilities";

// Customizable Area End

const styles: StyleRules = {
    // Customizable Area Start
    root: {
        padding: '22px',
    },
    tableHead: {
        fontSize: '12px'
    },
    tableCell: {
        fontSize: '12px'
    },
    noData: {
        textAlign: "center",
        fontSize: "18px",
        fontWeight: "bold"
    },
    dialogTitle: {
        textAlign: 'center', 
        color: 'red', 
        fontWeight: 'bold'
    },
    dialogActions: {
        justifyContent: 'center', 
        marginBottom: 16
    },
    deleteButton: {
        backgroundColor: 'rgba(1,162,78,255)',
        color: '#ffffff',
        cursor: 'pointer',
        padding: '8px 20px',
        margin: '0px 10px',
        border: 'none',
        borderRadius: '10px',
        textAlign: 'center'    
    },
    noDeleteButton: {
        backgroundColor: 'rgba(1,162,78,255)',
        color: '#ffffff',
        cursor: 'pointer',
        padding: '8px 20px',
        margin: '0px 10px',
        whiteSpace: 'nowrap',
        border: 'none',
        borderRadius: '10px',
        textAlign: 'center',
    },
    // Customizable Area End
}

// Customizable Area Start
interface DialogProps {
    classes: any;
    showDialog: boolean;
    handleConfirm: any;
}

const DialogWeb:React.FC<DialogProps>  = ({ classes, showDialog, handleConfirm }: DialogProps) => {
    return (
        <Dialog open={showDialog} data-testid="close-dialog"  aria-labelledby="delete-confirmation-dialog">
            <DialogTitle className={classes.dialogTitle}>
                Delete Filter
            </DialogTitle>
            <DialogContent style={{ textAlign: 'center', maxWidth:300, paddingTop: 0 }}>
                <Typography>Are you sure you want to delete this filter ?</Typography>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <button 
                    className={classes.noDeleteButton}
                    data-testid="cancelDelete"
                    style={{ background: localStorage.getItem('appTheme') == 'darkTheme' ? '#363333' : '#181a19' }} 
                    onClick={(e: any) => handleConfirm(false) }>
                    No, don't delete
                </button>
                <button data-testid="confirmDelete" className={classes.deleteButton} onClick={(e: any) => handleConfirm(true) }>Yes, delete</button>
            </DialogActions>
        </Dialog>
    )
}
// Customizable Area End

export class SavedFiltersWeb extends SavedFiltersControllerWeb {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    FilterTable = (savedFilters: any, heading: string, filterType: string, onPageChange: any, onRowsPerPageChange: any) => {
        const { classes } = this.props;
        const { rowsPerPage, page, customFilterPage, customFilterRowsPerPage, loading } = this.state;

        let perPageRows = filterType === "custom" ? customFilterRowsPerPage : rowsPerPage;
        let currentPage = filterType === "custom" ? customFilterPage : page;

        return (
            <>
                <Box style={{ display: "flex", justifyContent: "space-between", marginBottom: '16px' }}>
                    <Typography variant="h6" style={{ fontWeight: 900, marginLeft: 15 }}>{heading}</Typography>
                </Box>
                { !loading && savedFilters.length === 0 && <Typography className={classes.noData}>No Data Found</Typography>}
                { savedFilters.length > 0  && <> 
                    <TableContainer>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left" className={classes.tableHead}>#</TableCell>
                                    <TableCell align="left" className={classes.tableHead}>Filter Name</TableCell>
                                    <TableCell align="left" className={classes.tableHead}>Filter Type</TableCell>
                                    <TableCell align="left" className={classes.tableHead}>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { savedFilters.slice(currentPage * perPageRows, currentPage * perPageRows + perPageRows).map((filter: any, index: number) => {
                                    const key = Object.keys(filter.attributes)[0];
                                    let filterTypes = '-';
                                    if (filter.attributes) {
                                        filterTypes = Object.keys(filter.attributes)[0];
                                    }
                                    return <>
                                    {
                                    <TableRow key={filter.id}>
                                        <TableCell className={classes.tableCell}>{currentPage * 10 + (index + 1) }</TableCell>
                                        <TableCell className={classes.tableCell}>{ filter.attributes[key]?.filter_name }</TableCell>
                                        <TableCell className={classes.tableCell}>{ filterTypes }</TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <Button data-testid={`execute-filter-${filterType}${filter.id}`} onClick={() => this.executeFilter(filter.id, filterType)} size='small' color="primary">
                                                Load
                                            </Button>
                                            <Button data-testid={`remove-filter-${filterType}${filter.id}`} onClick={() => this.handleDialog(filter.id, filterType)} size='small' color="secondary">
                                                Remove
                                            </Button>
                                        </TableCell>
                                    </TableRow>}</>
                                }) }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={savedFilters.length}
                        rowsPerPage={perPageRows}
                        page={currentPage}
                        onPageChange={onPageChange}
                        onRowsPerPageChange={onRowsPerPageChange}
                    />
                </>}
            </>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { savedFilters, customSavedFilters, showDialog, token } = this.state;
        return (
            <Paper data-testid="save-filter-root" className={classes.root} square style={{ backgroundColor: localStorage.getItem('appTheme') == 'darkTheme' ? '#232429' : '#F4F8F6' }}>
                <Loader loading={this.state.loading} />
                {isEmpty(token) && <LoginAlertDialogWeb />}
                <Paper style={{ padding: '22px' }}>
                    { this.FilterTable(savedFilters, "Saved Filters", "", this.handleChangePage, this.handleChangeRowsPerPage) }
                </Paper>
                <Paper style={{ padding: '22px', marginTop: '10px' }}>
                    { this.FilterTable(customSavedFilters, "Custom Saved Filters", "custom", this.handleCustomFilterChangePage, this.handleCustomFilterChangeRowsPerPage) }
                </Paper>
                <DialogWeb classes={classes} showDialog={showDialog} handleConfirm={this.handleConfirm} />
            </Paper>
        )
        // Customizable Area End
    }
}

export default withStyles(styles)(SavedFiltersWeb);
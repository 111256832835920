import React from 'react';
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from 'react-toastify';
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: boolean;
    contactMethod: string;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class ContactUsWebController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    formik: any;
    contactUsApiCallId: string = '';
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.formik = React.createRef();

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            loading: false,
            contactMethod: "language"
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {

        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            runEngine.debugLog("API Message Recived", message);

            if (apiRequestCallId === this.contactUsApiCallId) {
                this.setState({ loading: false });
                if (responseJson.data) {
                    toast.success("Message sent Successfully");
                } else {
                    if (responseJson.errors) {
                        toast.error(responseJson.errors[0])
                    } else {
                        toast.error("Message sent failed");
                    }
                }
            }
        }
        // Customizable Area End
    }
    async componentDidMount() {
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    handleMailIconClick = () => {
        this.setState({ contactMethod: "mail" });
      };
    
    handlePhoneIconClick = () => {
        this.setState({ contactMethod: "phone" });
      };
    
    handleLanguageIconClick = () => {
        this.setState({ contactMethod: "language" });
      };

    contactUsButtonClick(values: any) {
        this.setState({ loading: true });
        const header = { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*" };
        const httpBody = {
            "data": values
        };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.contactUsApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_contact_us/contacts");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Customizable Area End
}

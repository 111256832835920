import React from "react";
// Customizable Area Start
import EducationCenterLandingWebController, {
    Props
} from "./EducationCenterLandingController.web";

import {
    withStyles, StyleRules
} from "@material-ui/core/styles";

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";

import { eduBackground, playStoreImg, eduBackgroundImage, educationImage } from './assets';
import Collapse from '@material-ui/core/Collapse';
import Link from '@material-ui/core/Link';
import Loader from "../../../components/src/Loader.web";
import { configJSON } from "./EducationCenter.web";
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
// Customizable Area End
const styles: StyleRules = {
    // Customizable Area Start
    root: {
        // overflowX: 'hidden',
    },
    Containor: {
        padding: "10px 40px 50px",
        '@media (max-width: 600px)': {
            padding: '10px',
        }
    },
    moreButton: {
        color: 'rgba(1,162,78,255)',
        cursor: 'pointer',
        padding: '8px 20px',
        marginLeft: '10px',
        border: 'none',
        borderRadius: '10px',
        textAlign: 'center',
        width: '100px'
    },
    subscribeButton: {
        width: 'auto', 
        border: 'none', 
        borderRadius: '10px', 
        textAlign: 'center', 
        padding: '8px 20px', 
        margin: '10px 0px 20px', 
        backgroundColor: 'rgba(1,162,78,255)', 
        color: '#ffffff', 
        cursor: 'pointer'
    },
    educationHeaderTitle: {
        fontFamily: 'Plus Jakarta Text',
        fontWeight: 'bold',
        marginTop: '14%',
        fontSize: '48px'
    },
    educationCenterSubtitle: {
        // color: 'rgba(0, 0, 0, 0.7)',
        fontSize: '20px',
        color: '#777'
    },
    educationCenterCardSubtitle: {
        fontSize: '12px',
        color: '#777'
    },
    educationCenterDescription: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        WebkitLineClamp: 7,
        WebkitBoxOrient: 'vertical',
        display: '-webkit-box',
        fontSize: '16px'
    },
    media: {
        height: '125px'
    },
    content: {
        height: '230px',
    },
    educationCenterTitle: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        display: '-webkit-box',
        fontWeight: 'bold', 
        fontSize: '18px'
    }
    // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
export class EducationCenterLandingWeb extends EducationCenterLandingWebController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    descriptionBlock(_data: any, index: number) {
        const { classes } = this.props;

        const extractTextFromHTML = (inputString: any) => {
            const sanitizedString = inputString.replace(configJSON.removeTagsAndStylesRegex, "");
            return sanitizedString;
        };

        const DescriptionText = extractTextFromHTML(_data.attributes.description || "");

        return (
            <>
                <Typography variant="body2" className={classes.educationCenterDescription} id={`${_data.attributes.title}desc`}>
                    <span dangerouslySetInnerHTML={{ __html: DescriptionText }} />
                </Typography>
            </>)
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { educationData } = this.state;
        return (
            <Paper className={classes.root} elevation={0} data-testid="educationCenterLandingPaper" square style={{ backgroundColor: localStorage.getItem('appTheme') == 'darkTheme' ? '#232429' : '#F4F8F6' }}>
                <Loader loading={this.state.loading} />
                <Grid style={{ padding: "0 40px", backgroundImage: `url(${eduBackground})`,
            backgroundRepeat: 'no-repeat',backgroundSize: 'cover' }} container spacing={2} id="eduMainContainer">
                    <Grid container item xs={12} sm={6} direction='column' id="staticEduRow">
                        <h1 className={classes.educationHeaderTitle} id="welcomeToCrypto">WELCOME TO <br/> KRYPTOKIA`S <br/> EDUCATION CENTER</h1>
                        <Typography variant="h5" className={classes.educationCenterSubtitle} id="solveMysteries">
                            Solve the mysteries of blockchain and cryptocurrency <br /> on our free learning platform
                        </Typography>
                        <img src={playStoreImg} style={{ width: '30%', marginTop: '15px', marginLeft: '-10px' }} />
                    </Grid>
                    <Grid container item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'center' }} id="staticCryptoImg">
                        <img src={educationImage} style={{ width: '75%' }} id="staticCryptoImgOne"></img>
                    </Grid>
                </Grid>
                <Paper className={classes.Containor}>
                   
                    <Typography align="center" style={{ margin: '40px 0px', fontSize: '36px', fontWeight: 'bold', fontFamily: 'Plus Jakarta Text' }} >
                        MODULES
                    </Typography>
                    <Grid container spacing={2}>
                        {educationData.map((data: any, key: number) => (
                            <Grid style={{ marginBottom: 10 }} key={key} xs={6} sm={3} item>
                                <Card>
                                    <CardMedia
                                            className={classes.media}
                                            image={data.attributes.image ? data.attributes.image : eduBackgroundImage}
                                            title={data.attributes.title}
                                            />
                                        <CardContent className={classes.content}>
                                            <Typography align="left" variant="body2"
                                                className={classes.educationCenterTitle}
                                            >
                                                {data.attributes.title}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                className={classes.educationCenterCardSubtitle}
                                                id={data.attributes.title}
                                            >
                                                {`Covers ${data.attributes.children.length | 0} topics`}
                                            </Typography>
                                            {this.descriptionBlock(data, key)}
                                        </CardContent>
                                        <CardActions>
                                            <button
                                                data-testid={`educationButton${key}`}
                                                type="button"
                                                className={classes.moreButton}
                                                style={{ backgroundColor: 'rgba(1,162,78,255)', color: '#ffffff' }}
                                                onClick={() => {
                                                    window.location.href = "/EducationCenterIntermediateWeb?topicId=" + data.id;
                                                }}>
                                                More
                                            </button>
                                        </CardActions>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                    <Grid container spacing={2} direction="column" data-testid="eduMembershipGrid" style={{ marginTop: '40px', alignItems: 'center', backgroundColor: localStorage.getItem('appTheme') == 'darkTheme' ? '#232429' : '#F4F8F6' }} >
                        <Typography variant="h5" style={{ fontSize: '14px', fontWeight: 'bold', margin: '40px 0px 10px', textAlign: 'center' }}>
                            {`Don't have a Kryptokiya Pro Membership yet?`}
                        </Typography>
                        <button
                            data-testid="subscribeButton"
                            type="button"
                            className={classes.subscribeButton}
                            onClick={() => {
                                window.location.href = "/SubscribeToProWeb";
                            }}
                        >
                            Subscribe Now
                        </button>
                    </Grid>
                </Paper>
            </Paper>
        )
        // Customizable Area End
    }
}

export default withStyles(styles)(EducationCenterLandingWeb);

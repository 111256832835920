import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import { instaImg, twitterImg, youTubeImg, linkdinImg, darkTwitterImg, darkInstaImg, darkYouTubeImg, darkLinkdinImg, newCryptoLogo, cryptoLogoDark, playStoreImg, appStoreImg } from '../../blocks/landingpage/src/assets';

import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

interface myProps {
}

const useStyles = makeStyles((theme) => ({
    footerText: {
        margin: '15px',
        fontSize: '16px',
        fontWeight: 'bold',
        cursor: 'pointer'
    },
    root: {
        padding: '22px',
    },
    footerNavigateText: {
        fontSize: '14px',
        marginBottom: '8px',
        cursor: 'pointer'
    },
    footerBox: {
        display: 'flex',
        flexDirection: 'column',
        '@media (max-width: 600px)': {
            textAlign: 'center'
        }
    },
    footerTypography: {
        fontSize: '14px',
        marginBottom: '5px'
    },
    footerHeadText: {
        cursor: 'pointer',
        fontSize: '16px',
        fontWeight: 'bold',
        margin: '15px 0px'
    },
    imgBox: {
        height: '50px',
    },
    socialIcon: {
        height: '35px',
        width: '35px',
        margin: '15px',
        cursor: 'pointer'
    },
    privacyBtn: {
        margin: '50px 05px 15px', 
        fontSize: '14px', 
        cursor: 'pointer',
        '@media (max-width: 425px)': {
            margin: '15px'
        }
    },
    termsBtn: {
        margin: '50px 05px 15px', 
        fontSize: '14px', 
        cursor: 'pointer',
        '@media (max-width: 425px)': {
            margin: '15px'
        }
    },
    break: {
        marginTop: '50px', 
        fontSize: '14px',
        '@media (max-width: 425px)': {
            marginTop: '15px'
        }
    },
    copyRight:{
        margin: '50px 05px 15px 15px', 
        fontSize: '14px',
        '@media (max-width: 425px)': {
            marginTop: '15px'
        }
    }
}));

export default function FooterBar(props: myProps) {

    const classes = useStyles();

    // useEffect(() => {
    //     // inject some code to head element
    //     let head = document.querySelector("head");
    //     // import google ads script if not yet imported
    //     if (!head?.querySelector("#google-adsense")) {
    //         const script = document.createElement("script");
    //         script.id = "google-adsense";
    //         script.async = true;
    //         script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4043537952503060";
    //         head?.appendChild(script);
    //     }
    // });

    return (
        <Paper className={classes.root} square data-testid='landingPaper' style={{ backgroundColor: localStorage.getItem('appTheme') == 'darkTheme' ? '#232429' : '#F4F8F6'  }}>
            <Paper square elevation={2} style={{ display: 'flex', flexWrap: 'wrap', padding: '8px', boxShadow: localStorage.getItem('appTheme') == 'darkTheme' ? '' : 'none' }}>
                <Grid container item xs={12} direction="row" style={{ marginTop: '20px' }} >
                    <Grid container item sm={4} lg={3} style={{ display: 'flex', flexDirection: 'column' }} >
                        <div style={{ display: 'flex', justifyContent: 'center' }} >
                            <img src={localStorage.getItem('appTheme') == 'darkTheme' ? cryptoLogoDark : newCryptoLogo } alt="Poster" style={{ height: '100px' }} />
                        </div>
                        <Typography style={{ fontSize: '14px', fontWeight: 'bold', margin: '15px 0px', textAlign: 'center' }} >
                            {`Aaj Crypto kiya kya?`}
                        </Typography>
                    </Grid>
                    <Grid container item sm={4} lg={2} className={classes.footerBox} >
                        <Typography
                            className={classes.footerHeadText}
                            id="home"
                            onClick={() => {
                                window.location.href = '/'
                            }}
                        >
                            {`Home`}
                        </Typography>
                        <Typography
                            className={classes.footerNavigateText}
                            id="screener"
                            onClick={() => {
                                window.location.href = '/ScreenerWeb'
                            }}
                        >
                            {`Screener`}
                        </Typography>
                        <Typography
                            className={classes.footerNavigateText}
                            id="newPortal"
                            onClick={() => {
                                window.location.href = '/NewsPortalWeb'
                            }}
                        >
                            {`News Portal`}
                        </Typography>
                        <Typography
                            className={classes.footerNavigateText}
                            id="educationCenter"
                            onClick={() => {
                                window.location.href = '/EducationCenterLandingWeb'
                            }}
                        >
                            {`Education Center`}
                        </Typography>
                        <Typography
                            className={classes.footerNavigateText}
                            id="subscribeToPro"
                            onClick={() => {
                                window.location.href = '/SubscribeToProWeb'
                            }}
                        >
                            {`Subscribe`}
                        </Typography>
                    </Grid>
                    <Grid container item sm={4} lg={2} className={classes.footerBox} >
                        <Typography className={classes.footerHeadText} >
                            {`Contact Us`}
                        </Typography>
                        <Typography className={classes.footerTypography} >
                            {`E-mail:`}
                        </Typography>
                        <Typography style={{ fontSize: '14px', marginBottom: '16px' }} >
                            {`contact@kryptokia.com`}
                        </Typography>
                    </Grid>
                    <Grid container item sm={12} lg={5} style={{ display: 'flex', flexDirection: 'column' }} >
                        <Typography style={{ fontSize: '16px', fontWeight: 'bold', margin: '15px 0px', textAlign: 'center' }} >
                            {`Connect with Us`}
                        </Typography>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Avatar src={localStorage.getItem('appTheme') == 'darkTheme' ? darkYouTubeImg : youTubeImg } data-testid='youtubeBtn' className={classes.socialIcon} onClick={() => window.open('https://www.youtube.com/@Kryptokia', '_blank', 'noopener')} aria-label="recipe" />
                            <Avatar src={localStorage.getItem('appTheme') == 'darkTheme' ? darkTwitterImg : twitterImg } data-testid='twitterBtn' className={classes.socialIcon} onClick={() => window.open('https://twitter.com/krypto_kia', '_blank', 'noopener')} aria-label="recipe" />
                            <Avatar src={localStorage.getItem('appTheme') == 'darkTheme' ? darkInstaImg : instaImg } data-testid='instaBtn' className={classes.socialIcon} onClick={() => window.open('https://instagram.com/krypto_kia?igshid=MzRlODBiNWFlZA==', '_blank', 'noopener')} aria-label="recipe" />
                            <Avatar src={localStorage.getItem('appTheme') == 'darkTheme' ? darkLinkdinImg : linkdinImg } data-testid='linkdinBtn' className={classes.socialIcon} onClick={() => window.open('https://www.linkedin.com/company/kryptokia/', '_blank', 'noopener')} aria-label="recipe" />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '15px' }} >
                            <img src={playStoreImg} alt="Poster" className={classes.imgBox} />
                        </div>
                    </Grid>
                </Grid>
                <Grid container item xs={12} justifyContent="center" >
                    <Typography variant="h5" className={classes.copyRight} >
                        {`©2023 kryptokia`}
                    </Typography>
                    <Typography variant="h5" className={classes.break} >
                        {`|`}
                    </Typography>
                    <Typography variant="h5" className={classes.termsBtn} data-testid='TermsBtn' onClick={() => window.location.href = '/TermsAndConditionWeb'} >
                        {`Terms of Service`}
                    </Typography>
                    <Typography variant="h5" className={classes.break} >
                        {`|`}
                    </Typography>
                    <Typography variant="h5" className={classes.privacyBtn} data-testid='privacyPolicyBtn' onClick={() => window.location.href = '/PrivacyPolicyWeb'} >
                        {`Privacy Policy`}
                    </Typography>
                </Grid>
            </Paper>
        </Paper>
    )
}

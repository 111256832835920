import React from "react";
// Customizable Area Start
import NewsPortalWebController, {
    Props
} from "./NewsPortalController.web";

import {
    withStyles, StyleRules
} from "@material-ui/core/styles";

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";

import Loader from "../../../components/src/Loader.web";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';

import './NewsPortal.css'
import { isEmpty } from "../../../framework/src/Utilities";
// Customizable Area End

const styles: StyleRules = {
    // Customizable Area Start
    root: {
        // overflowX: 'hidden',
        // height: '100vh'
        // marginRight: '3px'
    },
    container: {
        padding: "20px",
        borderRadius: '12px',
        '@media (max-width: 600px)': {
            padding: '10px',
            margin: '15px'
        }
    },
    topicsGrid: {
        borderRight: "1px solid #b4c1bb",
        marginRight: "20px",
        maxWidth: "21%",
        flexBasis: "21%",
        '@media (max-width: 600px)': {
            borderBottom: "1px solid #b4c1bb",
            borderRight: "none",
            maxWidth: "100%",
            flexBasis: "100%",
        }
    }
    // Customizable Area End
}
// Customizable Area Start
const breakpointColumnsObj = {
    default: 3,
    1100: 3,
    700: 2,
    500: 1
};
// Customizable Area End

export class NewsPortalWeb extends NewsPortalWebController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { newsData, selectedTrendingTopicName, trendingTopics, loading } = this.state;
        return (
            <Paper className={classes.root} elevation={0} data-testid="newsPortalPaper" square style={{ backgroundColor: localStorage.getItem('appTheme') == 'darkTheme' ? '#232429' : '#FFFFFF' }}>
                <Loader loading={loading} />
                <div className={classes.container} >
                    <Grid container direction="row" spacing={0} >
                        <Grid item xs={12} sm={2} md={2} className={classes.topicsGrid}>
                            <Typography variant="h5" style={{ fontWeight: 'bold', marginBottom: 10 }}>
                                {`Trending Topics`}
                            </Typography>
                            <List component="nav">
                            {
                                trendingTopics.map((_data: any, key: any) =>  {
                                    return (<ListItem style={{ fontSize: 14 }} button data-testid={_data[1]} key={key} onClick={() => this.trendingTopicSelected(_data)}>
                                        {_data[0]}
                                    </ListItem>)
                                })
                            }
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={9} md={9} >
                        {!isEmpty(selectedTrendingTopicName) && 
                            <Chip label={selectedTrendingTopicName} data-testid="selectedTopic" className="chip" variant="outlined" onDelete={() => this.removeTrendingTopic()} />}
                            <Grid container spacing={1}>
                                {newsData.map((data: any, key: number) => (
                                    <Grid style={{ marginBottom: 10 }} key={key} xs={6} sm={3} item>
                                        <Card>
                                            <CardMedia
                                                    className="media"
                                                    image={data.image_url}
                                                    title={data.title}
                                                    />
                                                <CardContent className="content">
                                                    <Box className="subcontent">
                                                        <Typography align="left"  variant="body1" style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                                            {data.source}
                                                        </Typography>
                                                        <Typography align="right" variant="body1" style={{ color: '#777', fontSize: '10px' }}>
                                                            { data.date}
                                                        </Typography>
                                                    </Box>
                                                    <Typography data-testid="news-title" className="title" onClick={() => {
                                                        window.open(data.news_url)
                                                    }}>{data.title}</Typography>
                                                </CardContent>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                            { !loading && newsData.length === 0 && <Typography variant="h5" className="noData">No News Found</Typography>}
                        </Grid>
                    </Grid>
                </div>
            </Paper>
        )
        // Customizable Area End
    }
}

export default withStyles(styles)(NewsPortalWeb);
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { CommonContext } from "../../../framework/src/CommonContextProvider";
import { theme } from "../../../framework/src/theme";
import HelperClass from "../../../components/src/HelperClass";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  route:any
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  arrayHolder:any;
  showDataEdu:any;
  isMoreClick:boolean;
  moreData:any;
  isLoading:boolean;
  childData:any;
  prevMode: any;
  hederTitle:any;
  currentIndex:any;
  isNextDisabled:boolean;
  isPrevDisabled:boolean;
  current_Plan:string;
  plan :string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ContentManagementController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  htmlRegx:any;
  getEduApiByIdCallId:any;
  getEduApiCallId:any;
  flatlistRef:any;
  currentIndex:any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      prevMode: HelperClass.getDarkMode(),
      arrayHolder:[],
      showDataEdu:null,
      isMoreClick:false,
      moreData:null,
      isLoading:true,
      childData:[],
      hederTitle:'',
      currentIndex:0,
      isNextDisabled:false,
      isPrevDisabled:false,
      current_Plan:"",
      plan:""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.getPlan()
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (

      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getEduApiCallId != null &&
      this.getEduApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
 
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors && responseJson.data) {
        responseJson.data.sort(function(a:any, b:any){return a.id-b.id});
       
         this.setState({ arrayHolder: responseJson.data,isLoading:false });
      } else {
        this.setState({isLoading:false})
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        // this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    else  if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getEduApiByIdCallId != null &&
      this.getEduApiByIdCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      
      this.getEduData(message)
    }
    // Customizable Area End

  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // Customizable Area Start
  getEduData(message:any){
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && !responseJson.errors && responseJson.data) {
      this.setState({showDataEdu:responseJson.data,},()=>{
       
      })
      if(responseJson.data.attributes.children.length !=0){
       responseJson.data.attributes.children.sort(function(a:any, b:any){return a.id-b.id});
        this.setState({
          childData:responseJson.data.attributes.children
        },()=>{
          if(this.state.childData.length !=0){
            this.props.navigation.navigate('MorePageInfo',{showData:responseJson.data,})
            this.setState({hederTitle:responseJson.data.attributes.title})
          }
        })
      }
    } else {
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }
  token=()=> { 
   return AsyncStorage.getItem("userToken");
  }

  componentDidMount() : any{
    this.getAllEdu();
    this.getPlan()
   
  }

  getEdById=()=>{ return this.props.route.params.Id }

   getAllEdu(){
    
    let token = this.token
  
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: token
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getEduApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getEduApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  
  getEduById(id:any){
    
    if(this.state.childData.length !=0){
      for(let i in this.state.childData){
        if(this.state.childData[i].id===id){
          if(this.state.current_Plan==="basic" && Number(i) > 1){
            this.state.childData[i].isSelected=false
          }else{
            this.state.childData[i].isSelected=true
          }

        }else{
          this.state.childData[i].isSelected=false
        }
      }
    }
  
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.token()
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getEduApiByIdCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.showEduApiEndPoint+id
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    onClickMore(value:any){
      this.setState({moreData:value,showDataEdu:null})
       
      
      if(value.children !=null && value.children.length !=0){
        this.props.navigation.navigate('MorePageInfo',{Id:value.id})

      }
      else{
        this.setState({showDataEdu:null},()=>{
        this.props.navigation.navigate('MorePageInfo',{Id:value.id})
         })
       
      }
      
    }
    onBackClick(){
      const id=this.state.showDataEdu?.attributes?.parent_id
      if(id !=null){
        this.setState({isNextDisabled:false,isPrevDisabled:false})
        this.getEduById(id)
      }else{
        this.setState({isNextDisabled:false,isPrevDisabled:false})

        this.props.navigation.navigate('ContentManagement')
      }
      //  this.props.navigation.navigate('ContentManagement')
        this.setState({ showDataEdu: null }) 
    }

  onNextClick() {
    if (this.flatlistRef && this.state.childData.length - 1 > this.state.currentIndex) {
      this.flatlistRef?.scrollToIndex({
        animated: true,
        index: this.state.currentIndex + 1,
      });
      this.setState({
        currentIndex: this.state.currentIndex + 1
      })
    }
  }
  onPrevClick() {
    if (this.flatlistRef && this.state.currentIndex > 0) {
      this.flatlistRef.scrollToIndex({
        animated: true,
        index: this.state.currentIndex == 0 ? this.state.currentIndex - 0 : this.state.currentIndex - 1,
      });
      this.setState({
        currentIndex: this.state.currentIndex - 1
      })
    }
    

  }
  handleOnViewableItemsChanged = ({ viewableItems }: any) => {
    const itemsInView = viewableItems.filter(
      ({ item }: any) => item.title
    );
    if (itemsInView.length === 0) {
      return;
    }
    this.setState({
      currentIndex: itemsInView[0].index
    })
    if (this.state.childData.length <= 1) {
      this.setState({
        isNextDisabled: true,
        isPrevDisabled: true
      })
    }
  }
  static contextType=CommonContext
  textColor=()=>{
    if(this.context.mode === "light"){
          return theme.light.textColor
        }
        return theme.dark.textColor
    }
    getColorTheme = (mode: any, color1: any, color2: any) => {
      return this.context.mode === mode ? color1 : color2
    }
    getByCondition = (condition: any, match: any, result1: any, result2: any) => {
      return condition === match ? result1 : result2
    }
    renderByCondition = (condition: any, result1: any, result2?: any) => {
      return condition ? result1 : result2 || null
    }

    async getPlan(){
      let currentPlan:any = await AsyncStorage.getItem("userInfo")
        this.setState({current_Plan : currentPlan})
     
    }
    gotoContentpage(){
      this.props.navigation.navigate("ContentManagement")
    }
  // Customizable Area End
  
}

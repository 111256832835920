import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from 'chart.js';
import { setChartData } from "../../../components/src/chartUtils.web";
import { toast } from "react-toastify";
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    token: any,
    loading: any,
    watchlistPriceChartData: any;
    watchlistVolumeChartData: any;
    watchlistMarketCapData: any;
    searchChartDialog: boolean;
    cryptoData: any;
    searchKeyword: any;
    searchedCrypto: any;
    selectedCrypto: any;
    addDialogErrors: any;
    setDialogLoader: boolean;
    chartLoading: boolean;
    searchChartError: boolean;
    noData: boolean;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class SearchChartWebController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    searchGraphsApiCallId: string = '';
    getSearchApiCallId: string = '';
    getCryptoDetailApiCallId: string = '';
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // Customizable Area End
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            token: localStorage.getItem('token') || "",
            loading: false,
            watchlistPriceChartData: [],
            watchlistVolumeChartData: [],
            watchlistMarketCapData: [],
            searchChartDialog: false,
            cryptoData: undefined,
            searchKeyword: '',
            searchedCrypto: [],
            selectedCrypto: 1,
            addDialogErrors: null,
            setDialogLoader: false,
            chartLoading: false,
            searchChartError: false,
            noData: false
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {

        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            switch (apiRequestCallId) {
                case this.searchGraphsApiCallId:
                    this.setState({ chartLoading: false });
                    const response = setChartData(responseJson.data?.points)
                    this.setState({
                        watchlistPriceChartData: response.chartPriceData,
                        watchlistVolumeChartData: response.chartVolumeData,
                        watchlistMarketCapData: response.chartMarketCapData
                    });
                    break;
                case this.getSearchApiCallId:
                    if (responseJson.crypto_data) {
                        this.setState({ setDialogLoader: false, searchedCrypto: responseJson.crypto_data, loading: false, searchChartError: false });
                    } else if (responseJson.error) {
                        this.setState({ searchChartError: true });
                    }
                    break;
                case this.getCryptoDetailApiCallId:
                    if (responseJson.data) {
                        this.setState({ cryptoData: responseJson.data[0], loading: false });
                    } else {
                        this.setState({ noData: true, loading: false });
                    }
                    break;

            }
            runEngine.debugLog("API Message Recived", message);
        }
        // Customizable Area End
    }
    async componentDidMount() {
        ChartJS.register(
            CategoryScale,
            LinearScale,
            PointElement,
            LineElement,
            Title,
            Tooltip,
            Legend,
            Filler,
        );
        // Customizable Area Start
        this.getSearchedCryptoDetails('');
        this.getCryptoDetail('1')
        // Customizable Area End
    }
    // Customizable Area Start

    getSearchedCryptoDetails = (query: string) => {
        this.setState({ setDialogLoader: true, loading: true });
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getSearchApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_dashboard/news/search_by_keyword?keyword=" + query);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getGraphData = (cryptoId: any, timeFrame: any) => {
        this.setState({ chartLoading: true });
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.searchGraphsApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_dashboard/cryptocurrency_chart?id=${cryptoId}&interval=${timeFrame}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getCryptoDetail(id: any) {
        this.setState({ searchChartDialog: false, loading: true });
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getCryptoDetailApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_dashboard/get_crypto_info?crypto_id=${id}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleSearchKeywordInputChange = (event: any) => {
        const { value } = event.target;
        this.setState({ searchKeyword: value });
        this.getSearchedCryptoDetails(value);
    };

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ addDialogErrors: null });
        this.setState({ selectedCrypto: event.target.value });
    }

    handleSearchChart = () => {
        if(!this.state.searchChartError) {
            this.setState({ searchChartDialog: true }) 
        } else {
            toast.error('We are having some issue. Please try later.')
        }
    }
    // Customizable Area End
}

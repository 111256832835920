Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";

exports.btnExampleTitle = "CLICK ME";
exports.removeTagsAndStylesRegex = /<a\s+[^>]*>.*?<\/a>|style=".*?"/gi;
exports.sanitizedHTMLRegex = /<a\s+[^>]*>.*?<\/a>|<img[^>]*>|style=".*?"/gi;
exports.plainTextRegex = /<[^>]*>/g;
exports.subscribeBtn = "Subscribe to Pro";
exports.subscribeText = "Your free trial is expired If you want to keep enjoying the benefits, Please subscribe to our Pro Plan.";
exports.expiredText = "Your free trial will expire soon.If you want to keep enjoying the benefits, please subscribe to our Pro Plan";
exports.expirePlanText = "If you want to keep enjoying the benefits, please subscribe to our Pro Plan";
// Customizable Area End
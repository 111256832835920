import React, { useState, useRef, useEffect } from 'react';
import { View, TouchableOpacity, Modal, Text, StyleSheet, Dimensions, ScrollView } from 'react-native';
import Icon from "react-native-vector-icons/AntDesign";
const { width, height } = Dimensions.get('screen')
import { scaledSize } from '../../framework/src/Utilities';
import Scale from './Scale';
//import { Ionicons } from '@expo/vector-icons'; // Import Ionicons from Expo icons library

export interface Props {
  navigation: any;
  
}
const Dropdown = (props: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(props?.selectedOption);
  const buttonRef = useRef(null);

  const toggleDropdown = () => {
    calculateDropdownPosition(true)
  };

  const selectOption = (option: any) => {
    setSelectedOption(option);
    setIsOpen(false);
  };
  useEffect(() => {
    console.warn(JSON.stringify(props))
    // calculateDropdownPosition()
  }, [])
 
  // const calculateDropdownPosition = (onPress?:Boolean) => {
  //   if(onPress){
  //       if (!buttonRef.current) return;

  //       const buttonDimensions = buttonRef.current.measure((x, y, width, height, pageX, pageY) => {
  //         setDropdownPosition({ top: pageY + height, left: pageX, width });
  //         setIsOpen(!isOpen);
  //         return;
  //       });
  //   }
  //   if (!buttonRef.current) return;

  //       const buttonDimensions = buttonRef.current.measure((x, y, width, height, pageX, pageY) => {
  //         setDropdownPosition({ top: pageY + height, left: pageX, width });

  //       });

  // };
  const calculateDropdownPosition = (onPress: any) => {
    if (!buttonRef.current) return;
    //@ts-ignore
    const buttonDimensions = buttonRef.current.measure((x, y, width, height, pageX, pageY) => {
      const windowHeight = Dimensions.get('window').height - 40;
      const dropdownHeight = 200; // Change this value according to your dropdown height

      let topPosition = pageY + height; // Default position is below the button

      if (windowHeight - pageY < dropdownHeight) {
        // If there is not enough space below, display dropdown above the button
        topPosition = pageY - 220;
        // setDropdownPosition({ bottom: topPosition, left: pageX, width });
        // return;
      }
      setDropdownPosition({ top: topPosition, left: pageX, width });

      setIsOpen(onPress ? !isOpen : isOpen); // Toggle isOpen only if onPress is true
    });
  };

  const [dropdownPosition, setDropdownPosition] = useState({});

  return (
    <View style={styles.container}>
      <TouchableOpacity
        onPress={toggleDropdown}
        style={[styles.dropdownButton, {
          width: props.width / 1.09,
          height: 60,
          backgroundColor: props.mode === 'light'
            ? "#F7F7F7" : "#000",
        }]}
        ref={buttonRef}
      // onLayout={calculateDropdownPosition}
      >
        <Text style={[styles.dropdownButtonText, {
          color: props.mode === 'light'
            ? "#000" : "#fff",
        }]}>{selectedOption || props.placeholder}</Text>
        <Icon
          name={isOpen ? "caretup" : "caretdown"}
          color={props.mode === 'light'
            ? "#444" : "#fff"}
          size={10}
        />
        {/* //<Ionicons name="chevron-down-outline" size={24} color="#999" /> */}
      </TouchableOpacity>
      <Modal
        visible={isOpen}
        transparent={true}
        animationType="fade"
        onRequestClose={() => setIsOpen(false)}
      >
        <TouchableOpacity
          style={[styles.modalContainer]}
          onPress={() => setIsOpen(false)}
          activeOpacity={1}
        >
          <View style={[styles.dropdownContent, dropdownPosition, {
            backgroundColor: props.mode === "light" ?
              "#FFF" : "#3C3F50"
          }]}>
            <ScrollView style={{ height: 200 }}
            >
              {props.data.map((option: any, index: any) => (
                <TouchableOpacity
                testID={props.testID}
                  key={index}
                  onPress={() => {
                    selectOption(typeof option == "object" ? option.name || option.label : option)
                    props.onSelect(option, index)
                  }}
                  style={[styles.dropdownItem, {
                    borderBottomWidth: 1,
                    borderBottomColor: props.mode === "light" ? index != props.data?.length - 1 ? 'gray' : "white" :
                      index != props.data?.length ? "green" : "black",
                    paddingBottom: 10,
                    // backgroundColor: props.isBasic && index > 1 ? "green" : "red"
                  }]}
                >
                  <Text adjustsFontSizeToFit numberOfLines={3} style={{
                    fontSize: 18, paddingHorizontal: 15,
                    color: props.mode === "light" ? "#000" : "#FFF",
                  
                  }}>
                    {typeof option == "object" ? option.name ? option.name : option.label ? option.label : "" : option}
                  </Text>

                </TouchableOpacity>
              ))}
             
             {(props.placeHolderName === "Select option" || props.placeHolderName === "Time Frame" || props.placeHolderName === "Select Time Option") && props.isBasic ? (
  <View style={{ justifyContent:'center' ,alignItems:'center', }}>
    <Text style={{ textAlign:'center',fontSize: 16, color: props.mode === "light" ? "#000" : "#FFF" }}>
      Upgrade the plan to view all features
    </Text>
    <TouchableOpacity style={styles.button} onPress={() => { setIsOpen(false), props.Upgrade() }}>
      <Text style={{color:'#499E5A',fontSize: 16, fontFamily: 'PlusJakartaText-Bold' }}>Upgrade to Pro</Text>
    </TouchableOpacity>
  </View>
) : null}

             
            </ScrollView>

          </View>
        </TouchableOpacity>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  dropdownButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    // borderWidth: 1,
    // borderColor: '#999',
    // borderRadius: 8,
    paddingHorizontal: 10,
    marginBottom: 10,
  },
  button:{
    borderWidth: 2,
    borderColor: '#499E5A',
    borderRadius: width /2,
    width: width / 2.5,
    height: 35,
    justifyContent: 'center',
    alignItems:'center',
    marginTop:Scale(10)
  },
  dropdownButtonText: {
    fontSize: 16,
    // color: '#333',
  },
  modalContainer: {
    flex: 1,
    // backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'flex-end',
  },
  dropdownContent: {
    position: 'absolute',
    // backgroundColor: 'white',
    padding: 10,
    borderRadius: 8,
    elevation: 5,
  },
  dropdownItem: {
    paddingVertical: 8,
  },
});

export default Dropdown;
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React from "react";
import { toast } from "react-toastify";

// Customizable Area Start
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: boolean;
    token: any;
    CryptoData: any;
    NewsData: any;
    EducationDetails: any;
    showDialog: boolean;
    searchKey: any;
    searchedCryptoData: any;
    seeMoreSection: any;
    searchError: boolean;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class GlobalSearchWebController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    dialogRef: any;
    getSearchApiCallId: string = '';
    getAdsSettingApiCallId: string='';
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            loading: false,
            token: localStorage.getItem('token') || "",
            CryptoData: [],
            NewsData: [],
            EducationDetails: [],
            showDialog: false,
            searchKey: '',
            searchedCryptoData: [],
            seeMoreSection: '',
            searchError: false
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        this.dialogRef = React.createRef();
        this.openDialog = this.openDialog.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
        // Customizable Area End
    }

    async receive(from: string, message: Message) {

        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.getSearchApiCallId) {
                this.setState({ loading: false });
                if (responseJson.crypto_data) {
                    this.setState({
                        CryptoData: responseJson.crypto_data,
                        NewsData: responseJson.news_data,
                        EducationDetails: responseJson.education_data,
                        searchError: false
                    })
                } else if (responseJson.error) {
                    this.setState({
                        searchError: true
                    })
                }
            }

            if (apiRequestCallId === this.getAdsSettingApiCallId) {
                if (responseJson) {
                    localStorage.setItem('showAds', responseJson.display_ads.toString());
                }
            }
        }

        // Customizable Area End
    }

    async componentDidMount() {
        // Customizable Area Start
        document.addEventListener("click", this.handleOutsideClick);
        this.getSearchAPIDetails("");
        this.getAdsSettings();
        // Customizable Area End
    }

    // Customizable Area Start

    async componentWillUnmount() {

        document.removeEventListener("click", this.handleOutsideClick);

    }

    scrollPaperToTop() {
        if (this.dialogRef.current) {
            this.dialogRef.current.scrollTop = 0;
        }
    }        

    openDialog = () => {
        if(!this.state.searchError) {
            setTimeout(() => {
                this.setState({ showDialog: true });
            }, 0);
        } else {
            toast.error('We are having some issue. Please try later.')
        }
    };

    handleOutsideClick = (event: any) => {
        if (this.state.showDialog && this.dialogRef.current && !this.dialogRef.current.contains(event.target)) {
            this.setState({ showDialog: false, searchKey: '' });
            this.getSearchAPIDetails("");
        }
    };

    closeDialog = () => {
        this.setState({ showDialog: false, searchKey: '', seeMoreSection: '' });
        this.getSearchAPIDetails("");
    }

    getSearchAPIDetails(keyword: any) {
        this.setState({ loading: true })
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getSearchApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_dashboard/news/search_by_keyword?keyword=" + keyword);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getAdsSettings() {
        this.setState({ loading: true })
        const header = { "Content-Type": "application/json" };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getAdsSettingApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_googleadsenseintegration/google_ad_sense_settings");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleSearchKeywordInputChange = (event: any) => {
        this.setState({ searchKey: event.target.value });
        this.getSearchAPIDetails(event.target.value)
        this.scrollPaperToTop()
    };

    toggleSeeMore = (section: string) => {
        setTimeout(() => {
            this.setState({
                showDialog: true,
                seeMoreSection: section,
            });
        }, 0);
        this.scrollPaperToTop()
    };

    handleBack = () => {
        setTimeout(() => {
            this.setState({
                showDialog: true,
                seeMoreSection: '',
            });
        }, 0);
    };

    // Customizable Area End
}

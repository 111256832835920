import React from "react";

// Customizable Area Start
import {
  StyleSheet,
  TouchableOpacity,
  View,
  ScrollView,
  TouchableWithoutFeedback,
  Dimensions,
  FlatList,
  ActivityIndicator, Linking, Modal, Text
} from "react-native";
import SubHeader from "../../../components/src/SubHeader";
const { width,height } = Dimensions.get("screen");
import { theme } from "../../../framework/src/theme";
import CustomText from "../../../components/src/text";
import AdsBanner from "../../../components/src/AdsBanner";
import Scale from "../../../components/src/Scale";
import { scaledSize } from "../../../framework/src/Utilities";
// Customizable Area End

import RichTextEditor5Controller, {
  Props,
  configJSON
} from "./RichTextEditor5Controller";

export default class RichTextEditor5 extends RichTextEditor5Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  trendingTopic = ({ item }: any) => {
    return (
      <TouchableOpacity

        style={[
          styles.trendingTopicBtn,
          {
            backgroundColor: item?.isSelected ? "#4b9d5b" : "#f3f3f3"
          }
        ]}
        testID="trendingTopicBtn"
        onPress={() => this.onPressTrendingBtn(item)}
      >
        {/* @ts-ignore */}
        <CustomText style={{ color: item?.isSelected ? "#fff" : "#4b9d5b", padding: 5,}}>
          {item?.name!}
        </CustomText>
      </TouchableOpacity>
    );
  };

  newsArray = ({ item }: any) => {
    return (
      <TouchableOpacity
        testID="btnExample"
        onPress={() => this.showNewsss(item)}
      >
        <View style={{ flexDirection: "row" }}>
          <View
            style={{
              height: 5,
              width: 5,
              borderRadius: 5 / 2,
              backgroundColor:
                this.context.mode === "light"
                  ? theme.light.textColor
                  : theme.dark.textColor,
              marginTop: 5
            }}
          />
          <View>
            {/* @ts-ignore */}
            <CustomText
              style={{
                fontFamily: 'PlusJakartaText-Bold',
                marginLeft: 5,
                color:
                  this.context.mode === "light"
                    ? theme.light.textColor
                    : theme.dark.textColor
              }}
            >
              {item?.title}
            </CustomText>
            {item?.showNews ? (
              <>
                <View style={styles.marginTop15}>
                  {/* @ts-ignore */}
                  <CustomText
                    onPress={() => {
                      Linking.openURL(item.news_url);
                    }}
                    style={[
                      styles.openContentTxt,
                      {
                        color: "blue"
                      }
                    ]}
                  >
                    {item?.content}
                  </CustomText>
                </View>
                <View style={styles.newsSource}>
                  {/* @ts-ignore */}
                  <CustomText style={styles.contentTxt}>{item?.date}</CustomText>
                  {/* @ts-ignore */}
                  <CustomText style={styles.contentTxt}>{" | "}</CustomText>
                  {/* @ts-ignore */}
                  <CustomText

                    style={styles.contentTxt}
                  >
                    {item?.source}
                  </CustomText>
                </View>
              </>
            ) : (
              <View style={styles.newsSource}>
                {/* @ts-ignore */}
                <CustomText style={styles.contentTxt}>{item?.date}</CustomText>
                {/* @ts-ignore */}
                <CustomText style={styles.contentTxt}>{" | "}</CustomText>
                {/* @ts-ignore */}
                <CustomText style={styles.contentTxt}>{item?.source}</CustomText>
              </View>
            )}
          </View>
        </View>
        <View style={styles.bottomBar} />
      </TouchableOpacity>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <View style={{ flex: 1, backgroundColor: this.context.mode === 'light' ? theme.light.headeBg : theme.dark.headeBg }} >
        <View style={{ flex: 1 }} >
          <ScrollView
            nestedScrollEnabled
            keyboardShouldPersistTaps="always"
            style={[styles.container, {
              backgroundColor: this.backGroundColor()
              ,
            }]}
          >
            <TouchableWithoutFeedback
              onPress={() => {
                this.hideKeyboard();
              }}
              testID="hidekeyboard"
            >
              {/* Merge Engine UI Engine Code */}


              <ScrollView style={{ height: Scale(Dimensions.get('window').height) }}>
                {/* @ts-ignore */}
                <SubHeader
                  title={this.labelHeader}
                  onPress={()=>this.props.navigation.goBack()}
                  // onPress={() => console.log("pressed")}
                  testID="headerId"
                />

                <View style={[styles.mainView, { backgroundColor: this.backGroundColor() }]}>
                  <FlatList
                    scrollEnabled
                    style={styles.flatListView}
                    data={this.state.trendingTopics}
                    showsHorizontalScrollIndicator={false}
                    horizontal
                    renderItem={this.trendingTopic}
                    testID="trendingTopic"
                    extraData={this.state}
                  />
                  <View style={styles.bottomBar} />
                  {/* </ScrollView> */}

                  {this.state.newsArray.length === 0 &&
                    <View style={styles.newsContent}>
                      {/* @ts-ignore */}
                      <CustomText style={styles.noNewsTxt}>Oops!!</CustomText>
                      {/* @ts-ignore */}
                      <CustomText style={styles.noNewsTxt}>No News for this Topic</CustomText>
                    </View>
                  }
                  {
                    this.state.newsArray.length > 0 &&
                    <FlatList
                      contentContainerStyle={{ flex: 1 }}
                      data={this.state.newsArray}
                      renderItem={this.newsArray}
                      extraData={this.state}
                      testID="FlatListID"
                    />
                  }

                </View>

                {this.state.loader && <ActivityIndicator
                  animating={true}
                  color="#4b9d5b"
                  size="large"
                  style={styles.activityIndicator}
                />}

              </ScrollView>
              {/* Merge Engine UI Engine Code */}
            </TouchableWithoutFeedback>
            <Modal
            data-testId="onRequestCloser2ok"
    visible={this.state.isVisibleModal}
    transparent={true}
    onRequestClose={() =>
      this.setState({ isVisibleModal: !this.state.isVisibleModal })
    }
  >
    <View style={styles.modalView}>
      <View
        style={[
          styles.modalBackground,
          {
            backgroundColor: this.context.mode === 'light' ? theme.light.headeBg : theme.dark.headeBg ,
           
          }
        ]}
      >
        <View style={styles.modalDetailsC}>
          <Text style={styles.selectOptionText}>Subscription Alert</Text>
          {/* <TouchableOpacity
            onPress={() =>
              this.setState({ openModal: !this.state.openModal })
            }
          >
            <Text style={style.closeButton}>X</Text>
          </TouchableOpacity> */}
        </View>
        <View style={styles.descriptionView}>
          <Text
            style={[
              styles.modalDescription,
              {
                color:
                this.context.mode === "light"
                  ? theme.light.textColor
                  : theme.dark.textColor
              }
            ]}
          >
            You don't have access to this page. {"\n"} To enjoy complete access, please subscribe to the Pro Plan 
          </Text>
        </View>

        
          <View style={styles.modalOptionView}>
            <TouchableOpacity
            testID="onClickNo"
              style={styles.modalNoBtn}
              onPress={()=>[this.setState({isVisibleModal:false}),this.props.navigation.navigate("Dashboard")]}
            >
              <Text style={styles.modalCamTxt}>Go To Home</Text>
            </TouchableOpacity>
            <TouchableOpacity
            testID="getCardData"
              style={styles.modalYesBtn}
              onPress={() =>[this.setState({isVisibleModal:false}),
                this.props.navigation.navigate("Customisableusersubscriptions")]}
            >
              <Text style={styles.modalCamTxt}>Subscribe To Pro</Text>
            </TouchableOpacity>
          </View>
       
      </View>
    </View>
  </Modal>
          </ScrollView>
        </View>
        <View style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
        }} >
          <AdsBanner />
        </View>
      </View>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    backgroundColor: "#F4F8F5"
  },
  activityIndicator: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    height: 80
  },
  mainView: {
    backgroundColor: "#fff",
    flex: 1,
    marginTop: 20,
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    elevation: 5,
    paddingTop: 25,
    paddingHorizontal: 15,
  },
  scrollingView: { height: "auto" },
  flatListView: {
    // flexDirection:"row"
  },
  trendingTopicBtn: {
    marginRight: 5,
    padding: 5,
    justifyContent: "center",
    height: 40,
    borderRadius: 20,
    margin: 4
  },
  newsContent: { flex: 1, justifyContent: "center", alignItems: "center" },
  noNewsTxt: { fontSize: 20, color: "#858585", fontFamily: 'PlusJakartaText-Bold' },
  title: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  body: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    marginBottom: 16,
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    padding: 10,
    borderWidth: 1
  },
  bgMobileInput: {
    flex: 1
  },
  showHide: {
    alignSelf: "center"
  },
  marginTop30: { marginTop: 30 },
  marginTop15: { marginTop: 15 },
  contentTxt: { color: "#858585", fontSize: 13, fontFamily: 'PlusJakartaText-Regular' },
  openContentTxt: { fontSize: 14, fontFamily: 'PlusJakartaText-Regular' },
  newsSource: { flexDirection: "row", marginTop: 15 },
  bottomBar: {
    height: 0.5,
    width: width,
    backgroundColor: "#aaa",
    marginVertical: 15
  },
  trendingTopic: {
    color: "#000", fontSize: 15, fontFamily: 'PlusJakartaText-Regular', fontWeight: "bold", marginLeft: 10
  },
  containerStyle: {
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    flexDirection: 'row',
  },

  modal: {
    width: "100%",
    flex: 1, justifyContent: "center", alignItems: "center" ,
    
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    alignSelf:"center",
   
    height:scaledSize(200)
  },
  ModalBox :{height:scaledSize(200),
    width:"90%",
    backgroundColor:"white",
    alignContent:"center",
    alignItems:"center",
    alignSelf:"center",},
    selectOptionText: {
      textAlign: "center",
      fontFamily:'PlusJakartaText-Bold',
  
      color: "#ED7B5E",
      fontSize: 18,
      marginVertical: 10
    },
    modalView: {
        flex: 1,
        backgroundColor: "rgba(52, 52, 52, 0.8)",
        alignItems: "center",
        justifyContent: "center",
        
      },
      modalBackground: {
        backgroundColor: "#fff",
        borderRadius: 5,
        width:"95%",
        // height: scaledSize(120),
        // width: width - 40
      },
      modalDetailsC: {
        marginHorizontal: 20,
        alignItems: "center",
        padding:20
      },
      
      
      modalOptionView: {
        marginVertical: 20,
        marginHorizontal: 15,
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "space-around"
      },
      modalOkBtn: {
        marginVertical: 20,
        marginHorizontal: 15,
        alignItems: "center"
      },
      modalNoBtn: {
        width: "45%",
        backgroundColor: "#000",
        padding: 13,
        borderRadius: 10
      },
     
      modalDescription: { fontSize: 16, textAlign: "center",fontFamily:'PlusJakartaText-Regular' },
      modalYesBtn: {
        width: "50%",
        backgroundColor: "#499E5A",
        padding: 13,
        borderRadius: 10
      },
     
    description: {
      fontSize: 14,
      fontWeight: "500",
      marginLeft: 15
    },
      myAdsText: {
        fontSize: 22,
        fontWeight: "600",
        marginLeft: 10
      },
      modalCamTxt: { color: "#fff", fontSize: 15, textAlign: "center",fontFamily:'PlusJakartaText-Bold' },
      descriptionView: {
        height: 60,
        justifyContent: "center",
        paddingHorizontal: 15
      },
});
// Customizable Area End

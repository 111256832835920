import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { toast } from 'react-toastify';
import { noAddsImg, screeningImg, realtimeImg, dipAlertImg, discordImg, tokenImg } from './assets';
import React from "react";
import { PricingPlan } from "../../../components/src/types";
import { subscriptionPlanDetailList } from "../../../components/src/chartUtils.web";
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: boolean;
    token: any;
    cardsData: any;
    tableData: any;
    freeTrialData: any;
    subscriptionPlansDetail: PricingPlan | null;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class SubscribeToProController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    tableRef: any
    createFreeTrialApiCallId: string="";
    getSubscriptionPlanDetailsApiCallId: string="";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            loading: false,
            token: localStorage.getItem('token') || "",
            cardsData: [
                {id: 1, title: 'NO ADS', description: 'Ads are very disturbing especially when you are doing research for trading/investing. Upgrade to Pro and go ad-free.', image: noAddsImg },
                {id: 2, title: 'UNLIMITED SCREENING', description: 'Filter cryptos without any limitations, select pr-screened filters or create custom filters.', image: screeningImg },
                {id: 3, title: 'REAL-TIME ALERTS', description: "Don't want to miss out on good opportunities? Get automatic screen/news updates and alerts through email & mobile notifications.", image: realtimeImg },
                {id: 4, title: 'DIP ALERTS', description: "Set a dip alert everytime your currency falls by a certain percentage. Add more during the dip.", image: dipAlertImg },
                {id: 5, title: 'DISCORD COMMUNITY', description: "Be a part of Kryptokia's discord server & interact with crypto traders/investors all around the world.", image: discordImg },
                {id: 6, title: 'VIRTUAL TOKENS', description: "Use our platform & get virtual redeemable tokens which you can redeem for gift coupon of your choice. No more random coupons!", image: tokenImg },
            ],
            tableData: subscriptionPlanDetailList,  
            freeTrialData: [],
            subscriptionPlansDetail: null
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        this.tableRef = React.createRef();
        // Customizable Area End
    }

    async receive(from: string, message: Message) {


        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            
            if (apiRequestCallId === this.getSubscriptionPlanDetailsApiCallId) {
                this.setState({ loading: false, subscriptionPlansDetail: responseJson });
            }

            runEngine.debugLog("API Message Recived", message);
        }
        // Customizable Area End
    }
    async componentDidMount() {
        // Customizable Area Start
      this.getSubscriptionPlansDetail();
        // Customizable Area End
    }
    // Customizable Area Start
    scrollToTable = () => {
      if(this.tableRef.current){
        this.tableRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }

    getSubscriptionPlansDetail() {
      const { token } = this.state;
      this.setState({ loading: true });
      const header = { token };
      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.getSubscriptionPlanDetailsApiCallId = requestMessage.messageId;
      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_custom_user_subs/subscriptions/subscription_plan_details");
      requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Customizable Area End
 }

import React from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const UpgradeAlert = () => (
    <Box style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: '-40px' }}>
        <Typography style={{ fontSize: '12px', fontWeight: 'bold', marginBottom: 5}}  variant="body2">Upgrade the plan to view all features</Typography>
        <Button variant="contained" 
        style={{ padding: '5px 25px',
        fontSize: '12px',
        fontWeight:'bold',
        color: '#01A24E',
        backgroundColor: '#ffffff',
        borderRadius: '20px',
        border: '1px solid #01A24E',
        textTransform: 'none'
     }} onClick={() => window.location.href = "/SubscribeWeb"}>Upgrade to Pro</Button>
    </Box>);

  export { UpgradeAlert }
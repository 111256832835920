import React from "react";
// Customizable Area Start
import DipAlertWebController, {
    Props
} from "./DipAlertController.web";

import {
    withStyles, StyleRules
} from "@material-ui/core/styles";

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import TextField from '@material-ui/core/TextField';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import Loader from "../../../components/src/Loader.web";
import LoginAlertDialogWeb from "../../../components/src/LoginAlertDialog.web";
import { removePlusMinusSymbol } from "../../../components/src/chartUtils.web";
// Customizable Area End

const styles: StyleRules = {
    // Customizable Area Start
    root: {
        padding: '22px',
        // height: '100vh'
    },
    container: {
        borderRadius: '8px',
    },
    headerBtn: {
        margin: '5px 0px 0px 12px',
        borderRadius: '8px',
        textTransform: "none",
        whiteSpace: 'nowrap'
    },
    removeTableBorder: {
        '& .MuiTableCell-root': {
            borderBottom: 'none'
        }
    },
    fullFieldWidth: {
        marginBottom: '15px',
        '& .MuiFormControl-fullWidth': {
            width: '100%'
        },
        '& .MuiSelect-selectMenu': {
            textAlign: 'left'
        }
    },
    portfolioBtn: {
        backgroundColor: 'rgba(1,162,78,255)',
        color: '#ffffff',
        cursor: 'pointer',
        padding: '15px 38px',
        border: 'none',
        borderRadius: '10px',
        textAlign: 'center',
        margin: '15px 0px'
    },
    percentChange: {
        display: "flex",
        alignItems: "center",
        justifyContent: 'flex-end'
    },
    percentChangeItem: {
        fontWeight: "bold",
        fontSize: "12px",
    },
    // Customizable Area End
}
// Customizable Area Start
const dipAlertSchema = Yup.object().shape({
    dip_number: Yup.string()
        .required('Trigger percentage is required.'),
    crypto_id: Yup.string()
        .required('Asset name is required.'),
});

// Customizable Area End
export class DipAlertWeb extends DipAlertWebController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    customDipAlertNumberField = (values: any, handleBlur: any, handleChange: any) => {
        return (
            <Field
                validateOnBlur
                validateOnChange
                name="custom_dip_number"
                render={({ field, form }: any) => (
                    <TextField
                        variant="filled"
                        label={"Enter trigger % here"}
                        type="number"
                        name={"custom_dip_number"}
                        InputProps={{ disableUnderline: true }}
                        fullWidth
                        value={values.custom_dip_number}
                        onBlur={handleBlur}
                        error={
                            Boolean(form.errors.custom_dip_number && form.touched.custom_dip_number)
                        }
                        helperText={
                            form.errors.custom_dip_number &&
                            form.touched.custom_dip_number &&
                            String(form.errors.custom_dip_number)
                        }
                        onChange={handleChange}
                    />
                )}
            />
        )
    }

    dipAlertDialog = () => {
        const { classes } = this.props;
        return (
            <Dialog
                open={this.state.dipAlert}
                onClose={() => this.closeDipAlert()}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="customized-dialog-title" style={{ padding: '16px 24px 0px' }}>
                    <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '20px', textAlign: 'center', color: '#43985e' }}>
                        {this.state.selectedAsset == null ? `Add Dip Alert` : `Edit Dip Alert`}
                    </Typography>
                </DialogTitle>
                <DialogContent
                    style={{
                        maxWidth: "500px",
                        width: "500px",
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <div>
                        <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '14px', textAlign: 'center', color: '#989ca2', width: '400px' }}>
                            {`You will receive an alert once the price of a particular asset drops by trigger % point`}
                        </Typography>
                        <Formik
                            initialValues={{
                                dip_number: this.state.selectedAsset?.attributes?.dip_number || 5,
                                custom_dip_number: this.state.selectedAsset?.attributes?.dip_number || '',
                                crypto_id: this.state.selectedAsset?.attributes?.crypto_id || 0,
                            }}
                            data-testid="dipAlertForm"
                            enableReinitialize
                            validationSchema={dipAlertSchema}
                            onSubmit={(values: any, { resetForm, setSubmitting, setStatus }) => {
                                this.addOrUpdateDip(values);
                                Object.keys(values).forEach(key => (values[key] = ""));
                                resetForm({ values: values });
                                setStatus(true);
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                setFieldValue,
                            }) => (
                                <Form onSubmit={handleSubmit}>
                                    {values.dip_number == 5 && <div className={classes.fullFieldWidth}>
                                        <Field
                                            name="dip_number"
                                            validateOnChange
                                            validateOnBlur
                                            render={({ field, form }: any) => (
                                                <TextField
                                                    name={"dip_number"}
                                                    id="dip_number"
                                                    label="Enter trigger % point"
                                                    select
                                                    onChange={handleChange("dip_number")}
                                                    margin="normal"
                                                    value={values.dip_number}
                                                    variant="filled"
                                                    fullWidth
                                                    error={
                                                        Boolean(form.errors.dip_number && form.touched.dip_number)
                                                    }
                                                    InputProps={{ disableUnderline: true }}
                                                    helperText={
                                                        form.touched.dip_number &&
                                                        form.errors.dip_number &&
                                                        String(form.errors.dip_number)
                                                    }
                                                >
                                                    {this.state.triggerPercentList.map((triggerPercent: any, key: any) => (
                                                        <MenuItem key={key} value={triggerPercent}>
                                                            {triggerPercent == 0 ? 'Custom' : `${triggerPercent} %`}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            )}
                                        />
                                    </div>}
                                    {values.dip_number != 5 &&
                                        <div className={classes.fullFieldWidth}>
                                            {this.customDipAlertNumberField(values, handleBlur, handleChange)}

                                        </div>
                                    }
                                    <div className={classes.fullFieldWidth}>
                                        <Field
                                            validateOnBlur
                                            validateOnChange
                                            name="crypto_id"
                                            render={({ field, form }: any) => (
                                                <TextField
                                                    id="crypto_id"
                                                    select
                                                    label="Choose asset"
                                                    value={values.crypto_id}
                                                    onChange={(event) => {
                                                        setFieldValue("crypto_id", event.target.value);
                                                    }}
                                                    name={"crypto_id"}
                                                    margin="normal"
                                                    fullWidth
                                                    variant="filled"
                                                    InputProps={{ disableUnderline: true }}
                                                    error={
                                                        Boolean(form.errors.crypto_id && form.touched.crypto_id)
                                                    }
                                                    disabled={this.state.selectedAsset !== null}
                                                    helperText={
                                                        form.errors.crypto_id &&
                                                        form.touched.crypto_id &&
                                                        String(form.errors.crypto_id)
                                                    }
                                                >
                                                    {this.state.addAlertList.map((item: any) => (
                                                        <MenuItem key={item.id} value={item.id}>
                                                            {item.name}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            )}
                                        />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                        <button type="button" className={classes.portfolioBtn} style={{ background: '#181a19', marginRight: '10px' }} onClick={(e: any) => this.closeDipAlert()}>Cancel</button>
                                        <button type="submit" className={classes.portfolioBtn}>{`Save`}</button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </DialogContent>
            </Dialog>)
    }

    actionMenu() {
        return (
            <Menu
                id="simple-menu"
                anchorEl={this.state.anchorEl}
                open={Boolean(this.state.anchorEl)}
                onClose={this.handleMenuIconClose}
                className={this.props.classes.menu}
            >
                <MenuItem onClick={(event: any) => this.setState({ dipAlert: true, anchorEl: null })} >
                    <ListItemIcon>
                        <EditIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit"
                        style={{
                            fontFamily: "Plus Jakarta Text",
                            fontSize: "12px",
                            color: "#0E0E0E",
                            textTransform: "capitalize",
                            letterSpacing: 0,
                        }}>
                        Edit
                    </Typography>
                </MenuItem>
                <MenuItem
                    onClick={(e) => {
                        this.deleteAlertDetails();
                    }}
                >
                    <ListItemIcon>
                        <DeleteOutlineIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit"
                        style={{
                            fontFamily: "Plus Jakarta Text",
                            fontSize: "12px",
                            color: "#0E0E0E",
                            textTransform: "capitalize",
                            letterSpacing: 0,
                        }}>
                        Delete
                    </Typography>
                </MenuItem>
            </Menu>
        )
    }

    assetsTable = () => {
        const { classes } = this.props;
        return (
            <div style={{ margin: '26px 0px' }}>
                <TableContainer component={Paper} elevation={0}>
                    {this.state.dipAlertList[0]?.attributes?.crypto_details?.length > 0 ? <Table aria-label="top-scans-one" size="small" className={classes.removeTableBorder}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" style={{ fontSize: '13px', fontWeight: 'bold' }}>Name</TableCell>
                                <TableCell align="right" style={{ fontSize: '13px', fontWeight: 'bold' }}>Price</TableCell>
                                <TableCell align="right" style={{ fontSize: '13px', fontWeight: 'bold' }}>24H</TableCell>
                                <TableCell align="right" style={{ fontSize: '13px', fontWeight: 'bold' }}>Market Cap</TableCell>
                                <TableCell align="right" style={{ fontSize: '13px', fontWeight: 'bold' }}>Total Supply</TableCell>
                                <TableCell align="right" style={{ fontSize: '13px', fontWeight: 'bold', whiteSpace: 'nowrap' }}>Dip Alert</TableCell>
                                <TableCell align="center" style={{ fontSize: '13px', fontWeight: 'bold' }}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.dipAlertList.map((dataRow: any) => {
                                if (dataRow.attributes?.crypto_details?.length > 0) {
                                    return (
                                        <TableRow key={dataRow.id} data-testid={dataRow.id} >
                                            <TableCell align="left">
                                                <CardHeader
                                                    avatar={
                                                        <Avatar
                                                            src={dataRow.attributes.crypto_details[0].image_url}
                                                            alt="Remy Sharp"
                                                            style={{ width: '32px', height: '32px' }}
                                                        >
                                                        </Avatar>
                                                    }
                                                    title={
                                                        <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                            {dataRow.attributes.crypto_details[0].name}
                                                        </Typography>
                                                    }
                                                    style={{ padding: '0px' }}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                    {dataRow.attributes.crypto_details[0].price}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                            <div
                                                    className={classes.percentChange}
                                                >
                                                    {dataRow.attributes.crypto_details[0].percent_change_24h.charAt(0) ===
                                                        "+" ? (
                                                        <ArrowDropUpIcon style={{ color: "#01A24E" }} />
                                                    ) : (
                                                        <ArrowDropDownIcon style={{ color: "#E86A3F" }} />
                                                    )}
                                                    <Typography
                                                        gutterBottom
                                                        variant="body2"
                                                        component="div"
                                                        className={classes.percentChangeItem}
                                                        style={{
                                                            color: dataRow.attributes.crypto_details[0].percent_change_24h
                                                                .toString()
                                                                .charAt(0) === "+"
                                                                ? "#01A24E"
                                                                : "#E86A3F",
                                                        }}
                                                    >
                                                        {removePlusMinusSymbol(dataRow.attributes.crypto_details[0].percent_change_24h)}
                                                    </Typography>
                                                </div>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                    {dataRow.attributes?.crypto_details[0].market_cap}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                    {`${removePlusMinusSymbol(dataRow.attributes?.crypto_details[0].total_supply)}`}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                    {`${dataRow.attributes?.dip_number | 0} %`}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="center">
                                                <IconButton
                                                    onClick={(e) => {
                                                        this.handleMenuIconClick(e, dataRow);
                                                    }}
                                                >
                                                    <MoreVertIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>)
                                }
                            })}
                        </TableBody>
                    </Table> :
                        <div style={{ textAlign: 'center', fontSize: '18px', fontWeight: 'bold' }}>
                            {this.state.dipAlertList.length === 0
                                ? 'Set Dip Alerts for Coins'
                                : !this.state.dipAlertList[0]?.attributes?.crypto_details
                                    ? 'Api is not responding. Please try again after sometime'
                                    : 'Set Dip Alerts for Coins'}
                        </div>
                    }
                </TableContainer>
            </div>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const backgroundval = localStorage.getItem('appTheme') == 'darkTheme' ? '#232429' : '#f4f8f6';

        return (
            <Paper className={classes.root} data-testid='portfolioPaper' elevation={0} square style={{ backgroundColor: backgroundval }}>
                {this.state.loadingArray.length > 0 && <Loader loading={this.state.loading} />}
                {!this.state.token && <LoginAlertDialogWeb />}
                <Grid item xs={12} className={classes.container}>
                    <Paper elevation={3} style={{ padding: '10px 20px' }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Typography variant="h5" style={{ fontWeight: 'bold', padding: '12px 0px' }}>
                                {`Assets`}
                            </Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                data-testid="dipAlert"
                                className={classes.headerBtn}
                                style={{ backgroundColor: 'rgba(1,162,78,255)' }}
                                disableElevation
                                onClick={(event: any) => this.setState({ dipAlert: true })}
                            >
                                Dip Alert
                            </Button>
                        </div>
                        {this.assetsTable()}
                    </Paper>
                </Grid>
                {this.state.dipAlert && this.dipAlertDialog()}
                {this.actionMenu()}
            </Paper>
        )
        // Customizable Area End
    }
}
export default withStyles(styles)(DipAlertWeb);


import React, { Component } from "react";
import { StyleSheet, Text, View, Image, TextInput, Dimensions, TouchableOpacity } from "react-native";
import { COLORS } from "../../framework/src/Globals";
import SearchIcon from 'react-native-vector-icons/Feather'
import { CommonContext } from "../../framework/src/CommonContextProvider";
import { theme } from "../../framework/src/theme";
const { height, width } = Dimensions.get('screen')

interface Props {
  onPress: () => void;
  onClickProfile?: () => void;
  onsearchPress?: () => void;
  testID?: string;
}

interface State {
}

class CustomHeader extends Component<Props, State> {
  static contextType = CommonContext;

  render() {
    const mode = this.context;

    return (
      <View style={[styles.container, { backgroundColor: mode?.mode === 'light' ? theme.light.headeBg : theme.dark.headeBg }]}>
        <View style={{ marginLeft: 10, marginRight: 10, flexDirection: "row", alignItems: "center", justifyContent: 'space-between' }}>
          <TouchableOpacity
            onPress={this.props.onPress}
          >
            <Image source={require('./MenuIcon.png')}
             
              style={[styles.imageStyle, { tintColor: mode?.mode === 'light' ? theme.light.menuColor : theme.dark.menuColor }]}
              resizeMode={'contain'} />
          </TouchableOpacity>
          <TouchableOpacity
           
            style={[styles.inputView, { backgroundColor: mode?.mode === 'light' ? theme.light.highlight : theme.dark.highlight }]}
            onPress={this.props.onsearchPress}
          >
            <SearchIcon name="search" size={20} style={{ marginLeft: 10 }}
             
              color={mode?.mode === 'light' ? theme.light.menuColor : theme.dark.menuColor} />
            <Text
             
              style={[styles.textInput, { color: mode?.mode === 'light' ? theme.light.searchPlaceHolderTxtColor : theme.dark.searchPlaceHolderTxtColor }]}
            >
              Search Stocks,indices,EFTs.....
            </Text>
          </TouchableOpacity>
          {this.props.onClickProfile ?
            <TouchableOpacity
              onPress={this.props.onClickProfile}
            >
              <Image
                style={{
                  height: 35,
                  width: 35,
              
                  tintColor: mode?.mode === 'light' ? theme.light.menuColor : theme.dark.menuColor
                }}
                source={require("./NotificationIcon.png")}
              />
            </TouchableOpacity> :
            <Image
              style={{
                height: 35,
                width: 35,
                tintColor: COLORS.green
              }}
              source={require("./userIcon.png")}
            />
          }
        </View>
      </View>
    );
  }
}

export default CustomHeader;

const styles = StyleSheet.create({
  container: {
    height: height / 8,
    justifyContent: 'center',
    width: width
  },
  textInput: {
    fontFamily: 'PlusJakartaText-Regular',
    fontSize: 11,
    paddingLeft: 5
  },
  item: {
    backgroundColor: "#00000000",
    padding: 18,
    color: "black",
    fontSize: 18,
    fontWeight: "bold",
    flex: 1,
    flexDirection: "row"
  },
  seprator: {
    backgroundColor: "#6200EE",
    color: "#6200EE",
    height: 1
  },
  imageStyle: {
    height: 30,
    width: 30,
  },
  inputView: {
    flexDirection: 'row',
    alignItems: 'center',
    width: "72%",
    height: height / 20,
    borderRadius: 10,
  }
})

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { CommonContext } from "../../../framework/src/CommonContextProvider";
import AsyncStorage from "@react-native-async-storage/async-storage";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  data: any
  total_loyalty_points: string
  texterror: string
  pending_referral : string
  points : string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class LoyaltySystem2Controller extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  static contextType = CommonContext;
  getloyaltydetailsdataApiCallId: string = ""
  getloyaltypointsdetailsApiCallId : string = ""
  getaccountsdetailsApiCallId : string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      data:[],
      total_loyalty_points: '',
      texterror:'',
      pending_referral: "",
      points : ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getloyaltydetailsdataApiCallId) {
        this.setState({ total_loyalty_points: responseJson.total_loyalty_points });
      }
      if (apiRequestCallId === this.getloyaltypointsdetailsApiCallId) {
        if(responseJson.error == 'No loyalty points history found')
        this.setState({ texterror: responseJson.error })
      else { 
        this.setState({ data: responseJson })}
      }

      if (apiRequestCallId === this.getaccountsdetailsApiCallId) {
        this.setState({ pending_referral: responseJson.data.attributes.pending_referrals_count ,points : responseJson.data.attributes.total_pending_referrals_points});
      }
      
      
    }
    // Customizable Area End

  }
      // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount()
    this.getloyaltydetailsdata()
    this.getloyaltypointsdetails()
    this.getaccountsdetails()
  }
     // Customizable Area End

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

        // Customizable Area Start
    getColorTheme = (mode: any, color1: any, color2: any) => {
      return this.context.mode === mode ? color1 : color2
    }
    navigatescreen = () => {
      this.props.navigation.navigate("SearchScreen")
    }
         // Customizable Area End
  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

     // Customizable Area Start
  navigatescreennotifys = () => {
    this.props.navigation.navigate("Pushnotifications")

  }
  navigatepage = () => {
    this.handleNavigation('Referrals');
  }
           // Customizable Area End 
  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };
       // Customizable Area Start
  handleNavigation = (path: string) => {
    let toNavigate = new Message(getName(MessageEnum.NavigationMessage));
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), path);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
  }
       // Customizable Area End

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // Customizable Area Start
  getloyaltydetailsdata = async () => {
    const token = await AsyncStorage.getItem("userToken")
    const header = { "Content-Type": configJSON.validationApiContentType, token: token };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getloyaltydetailsdataApiCallId = requestMessage.messageId;
    const path = configJSON.loyaltypoints;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), path);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getloyaltypointsdetails = async () => {
    const token = await AsyncStorage.getItem("userToken")
    const header = { "Content-Type": configJSON.validationApiContentType, token: token };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getloyaltypointsdetailsApiCallId = requestMessage.messageId;
    const path = configJSON.loyaltypointsdetails;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), path);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getaccountsdetails = async () => {
    const token = await AsyncStorage.getItem("userToken")
    const header = { "Content-Type": configJSON.validationApiContentType, token: token };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getaccountsdetailsApiCallId = requestMessage.messageId;
    const path = configJSON.accountsshow;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), path);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
  
}

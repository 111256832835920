import React from "react";
// Customizable Area Start
import Typography from '@material-ui/core/Typography';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import Button from '@material-ui/core/Button';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Divider from '@material-ui/core/Divider';
import CryptoDetailWebController, { Props } from "./CryptoDetailController.web";
import { StyleRules, withStyles } from "@material-ui/core";
import Loader from "../../../components/src/Loader.web";
import CryptoChart from "../../../components/src/CryptoChart.web";
import { isEmpty } from "../../../framework/src/Utilities";
import { removePlusMinusSymbol } from "../../../components/src/chartUtils.web";
// Customizable Area End

const styles: StyleRules = {
    // Customizable Area Start
    floatingTooltip: {
        width: 180,
        height: 80,
        position: 'absolute',
        padding: 8,
        boxSizing: 'border-box',
        fontSize: 12,
        color: '#131722',
        backgroundColor: '#ffffff',
        textAlign: 'left',
        zIndex: 100000,
        pointerEvents: 'none',
        borderRadius: 6,
        boxShadow: 'rgba(88, 102, 126, 0.08) 0px 1px 1px, rgba(88, 102, 126, 0.1) 0px 8px 16px'
    },
    root: {
        padding: '10px 22px 22px',
        '@media (max-width: 1000px)': {
            height: 'auto'
        },
        '@media (max-width: 768px)': {
            height: 'auto',
            padding: '10px'
        }
    },
    dataCardRoot: {
        padding: '20px'
    },
    loginButton: {
        float: 'right',
        margin: '10px 0px',
        backgroundColor: 'rgba(1,162,78,255)',
        color: '#ffffff',
        cursor: 'pointer',
        padding: '8px 20px',
        border: 'none',
        borderRadius: '10px',
        textAlign: 'center',
        '@media (max-width: 1325px)': {
            margin: '10px 10px 0px 0px',
        },
    },
    moreButton: {
        float: 'right',
        margin: '10px',
        backgroundColor: 'rgba(1,162,78,255)',
        color: '#ffffff',
        cursor: 'pointer',
        padding: '8px 20px',
        border: 'none',
        borderRadius: '10px',
    },
    selectedMarket: {
        fontWeight: 'bold',
        // fontSize: '15px',
        '@media (min-width: 830px) and (max-width: 1325px)': {
            marginTop: '10px',
        }
    },
    selectedCategoryBox: {
        display: 'flex',
        justifyContent: 'space-between',
        '@media (max-width: 830px)': {
            flexDirection: 'column'
        }
    },
    top10Header: {
        color: '#94A59C',
        whiteSpace: 'nowrap'
    },
    percentChange: {
        display: "flex",
        alignItems: "center",
        justifyContent: 'flex-end'
    },
    tableRow: {
        // cursor: "pointer"
    },
    toggleButton: {
        backgroundColor: 'rgb(255, 255, 255)',
        color: 'rgba(1,162,78,255)',
        '&.MuiToggleButton-root.Mui-selected': {
            backgroundColor: 'rgba(1,162,78,255)',
            color: 'rgb(255, 255, 255)'
        }
    }
    // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

class CryptoDetailWeb extends CryptoDetailWebController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.priceActionChart = React.createRef();
        this.priceActionChartContainerRef = React.createRef();
        // Customizable Area End
    }


    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const {
            cryptoData, centerType, category, marketData, ShowMore, loading
        } = this.state;

        const marketDataToShow = ShowMore ? marketData : marketData.slice(0, 10);

        return (
            <Paper className={classes.root} elevation={0} data-testid="CryptoDetailsPaper" square style={{ backgroundColor: localStorage.getItem('appTheme') == 'ligthTheme' ? '#F4F8F6' : '#232429' }}>
                {this.state.loadingArr.length > 0 && <Loader loading={loading} />}
                <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                    <Button
                        variant="contained"
                        data-testid="detail-back-btn"
                        startIcon={<ArrowLeftIcon />}
                        style={{ background: 'rgba(1,162,78,255)', color: '#ffffff', textTransform: 'none', marginBottom: '10px' }}
                        onClick={this.handleBackButtonClick}
                    >
                        Back
                    </Button>
                    {!isEmpty(this.state.token) && <Button
                        variant="contained"
                        data-testid="add-remove-watchlist"
                        startIcon={this.state.isItemInWatchlist ? <ClearIcon /> : <AddIcon />}
                        style={{ background: 'rgba(1,162,78,255)', color: '#ffffff', textTransform: 'none', marginBottom: '10px' }}
                        onClick={() => this.state.isItemInWatchlist ? this.removeFromWatchlist() : this.createWatchlistCrypto()}
                    >
                        {this.state.isItemInWatchlist ? "Remove From Watchlist" : "Add To Watchlist"}
                    </Button>}
                </div>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={7}>
                        {cryptoData && <CryptoChart selectedData={cryptoData}
                            chartPriceData={this.state.priceActionPriceChartData}
                            chartVolumeData={this.state.priceActionVolumeChartData}
                            chartMarketCapData={this.state.priceActionMarketCapChartData}
                            getGraphData={this.getGraphData}
                            classes={classes}
                            datapoints={this.props.datapoints}
                            showHighLowPoints={this.props.showHighLowPoints}
                            loading={this.state.chartLoader} />}
                    </Grid>
                    <Grid item xs={12} md={5}>
                        {cryptoData && <Paper variant="outlined" style={{ border: '2px solid #0000001f', height: 450 }}>
                            <List>
                                <ListItem>
                                    <Avatar
                                        alt="Remy Sharp"
                                        style={{ width: '40px', height: '40px', marginRight: '15px' }}
                                        src={cryptoData.image_url}
                                    >
                                    </Avatar>
                                    <ListItemText primary={cryptoData.name} secondary={cryptoData.symbol} />
                                    <ListItemSecondaryAction style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                                        <Typography variant="body1" style={{ fontSize: 14, fontWeight: 'bold' }}>
                                            {cryptoData.price}
                                        </Typography>
                                        <div
                                            className={classes.percentChange}
                                        >
                                            {cryptoData.volume_percentage_change_24h.toString().charAt(0) ===
                                                "-" ? (
                                                <ArrowDropDownIcon style={{ color: "#E86A3F" }} />
                                            ) : (
                                                <ArrowDropUpIcon style={{ color: "#01A24E" }} />
                                            )}
                                            <Typography
                                                gutterBottom
                                                variant="body2"
                                                component="div"
                                                style={{
                                                    whiteSpace: 'nowrap',
                                                    fontWeight: "bold",
                                                    fontSize: "12px",
                                                    color: cryptoData.volume_percentage_change_24h
                                                        .toString()
                                                        .charAt(0) === "-"
                                                        ? "#E86A3F"
                                                        : "#01A24E",
                                                }}
                                            >
                                                {removePlusMinusSymbol(cryptoData.volume_percentage_change_24h)}
                                            </Typography>
                                        </div>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </List>
                            <Divider />
                            <List >
                                <ListItem>
                                    <ListItemText secondary='Market cap' secondaryTypographyProps={{ style: { fontSize: '14px' } }} />
                                    <Typography variant="body1" style={{ fontSize: 14, fontWeight: 'bold' }}>
                                        {cryptoData.market_cap}
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <ListItemText secondary='Volume (24h)' secondaryTypographyProps={{ style: { fontSize: '14px' } }} />
                                    <Typography variant="body1" style={{ fontSize: 14, fontWeight: 'bold' }}>
                                        {cryptoData.volume_24h}
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <ListItemText secondary='Volume/Market cap (24h)' secondaryTypographyProps={{ style: { fontSize: '14px' } }} />
                                    <Typography variant="body1" style={{ fontSize: 14, fontWeight: 'bold' }}>
                                        {cryptoData.volume_market_cap_24h}
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <ListItemText secondary='Circulating supply' secondaryTypographyProps={{ style: { fontSize: '14px' } }} />
                                    <Typography variant="body1" style={{ fontSize: 14, fontWeight: 'bold' }}>
                                        {cryptoData.circulating_supply}
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <ListItemText secondary='Total supply' secondaryTypographyProps={{ style: { fontSize: '14px' } }} />
                                        <Typography variant="body1" style={{ fontSize: 14, fontWeight: 'bold' }}>
                                            {cryptoData.total_supply}
                                        </Typography>
                                </ListItem>
                                <ListItem>
                                    <ListItemText secondary='Max. supply' secondaryTypographyProps={{ style: { fontSize: '14px' } }} />
                                    <Typography variant="body1" style={{ fontSize: 14, fontWeight: 'bold' }}>
                                        {cryptoData.max_supply !== 'null' ? `${cryptoData.max_supply} ${cryptoData.symbol}` : "∞"}
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <ListItemText secondary='Fully diluted market cap' secondaryTypographyProps={{ style: { fontSize: '14px' } }} />
                                    <Typography variant="body1" style={{ fontSize: 14, fontWeight: 'bold' }}>
                                        {cryptoData.fully_diluted_market_cap}
                                    </Typography>
                                </ListItem>
                            </List>
                        </Paper>}
                    </Grid>
                </Grid>
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: '30px' }}>
                    <div className={classes.selectedCategoryBox}>
                        <div>
                            <Typography variant="h6" className={classes.selectedMarket}>
                                {cryptoData && cryptoData.name} Markets
                            </Typography></div>
                        <div>
                            <ToggleButtonGroup
                                color="primary"
                                style={{ height: '30px', margin: '10px 20px 0px 0px' }}
                                value={centerType}
                                exclusive
                                onChange={this.centerTypeHandleChange}
                                aria-label="Platform"
                            >
                                <ToggleButton className={classes.toggleButton} value="all">ALL</ToggleButton>
                                <ToggleButton className={classes.toggleButton} value="cex">CEX</ToggleButton>
                                <ToggleButton className={classes.toggleButton} value="dex">DEX</ToggleButton>
                            </ToggleButtonGroup>
                            <ToggleButtonGroup
                                color="primary"
                                style={{ height: '30px', marginTop: '10px' }}
                                value={category}
                                exclusive
                                onChange={this.categoryHandleChange}
                                aria-label="Platform"
                            >
                                <ToggleButton className={classes.toggleButton} value="spot">Spots</ToggleButton>
                                <ToggleButton className={classes.toggleButton} value="perpetual">Perpetual</ToggleButton>
                                <ToggleButton className={classes.toggleButton} value="futures">Futures</ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                    </div>
                    <div style={{ marginTop: '15px' }}>
                    {marketDataToShow.length > 0 ? 
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left" className={classes.top10Header}>#</TableCell>
                                        <TableCell align="left" className={classes.top10Header}>Exchange</TableCell>
                                        <TableCell align="left" className={classes.top10Header}>Pair</TableCell>
                                        <TableCell align="right" className={classes.top10Header}>Price</TableCell>
                                        <TableCell align="right" className={classes.top10Header}>+2% Depth</TableCell>
                                        <TableCell align="right" className={classes.top10Header}>-2% Depth</TableCell>
                                        <TableCell align="right" className={classes.top10Header}>Volume 24H</TableCell>
                                        <TableCell align="right" className={classes.top10Header}>Volume %</TableCell>
                                        <TableCell align="right" className={classes.top10Header}>Liquidity Score</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {marketDataToShow.map((row: any, key: any) => (
                                        <TableRow key={row.id} className={classes.tableRow} >
                                            <TableCell component="th" scope="row">
                                                {key + 1}
                                            </TableCell>
                                            <TableCell align="left">
                                                <CardHeader
                                                    avatar={<Avatar
                                                        alt="Remy Sharp"
                                                        style={{ width: '32px', height: '32px' }}
                                                        src={row.image_url}
                                                    >
                                                    </Avatar>}
                                                    title={<Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                        {`${row.exchange}`}
                                                    </Typography>}
                                                    style={{ padding: '0px' }}
                                                />
                                            </TableCell>
                                            <TableCell align="left">
                                                <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                    {row.pairs}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                    {row.price !== null ? row.price : "N/A"}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                    {row.depth_2_percent_positive !== 'null' ? row.depth_2_percent_positive : "N/A"}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                    {row.depth_2_percent_negative !== 'null' ? row.depth_2_percent_negative : "N/A"}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                    {row.volume_24h !== null ? row.volume_24h : "N/A"}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                                <div
                                                    className={classes.percentChange}
                                                >
                                                    {row.volume_percent.toString().charAt(0) ===
                                                        "-" ? (
                                                        <ArrowDropDownIcon style={{ color: "#E86A3F" }} />
                                                    ) : (
                                                        <ArrowDropUpIcon style={{ color: "#01A24E" }} />
                                                    )}
                                                    <Typography
                                                        gutterBottom
                                                        variant="body2"
                                                        component="div"
                                                        style={{
                                                            whiteSpace: 'nowrap',
                                                            fontWeight: "bold",
                                                            fontSize: "12px",
                                                            color: row.volume_percent
                                                                .toString()
                                                                .charAt(0) === "-"
                                                                ? "#E86A3F"
                                                                : "#01A24E",
                                                        }}
                                                    >
                                                        {removePlusMinusSymbol(row.volume_percent)}
                                                    </Typography>
                                                </div>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                    {row.liquidity_score !== null ? row.liquidity_score.toFixed(2) : "N/A"}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer> : 
                        <Paper style={{ textAlign: 'center', fontSize: '20px', fontWeight: 'bold', padding: '30px 0px' }}>
                            There is no data to show for this filters.
                        </Paper>
                        }
                        {marketData.length > 10 && <button data-testid='cryptoDataMoreButton'
                            className={classes.loginButton}
                            onClick={ShowMore ? this.loadLessData : this.loadMoreData}
                        >
                            {ShowMore ? "Less..." : "More..."}</button>}
                    </div>
                </div>
            </Paper>
        )
        // Customizable Area End
    }
}

export default withStyles(styles)(CryptoDetailWeb);
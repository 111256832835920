// Customizable Area Start
//@ts-nocheck
import React from "react";
import {
  StyleSheet,
  Text,
  Image,
  TouchableOpacity,
  View,
  ScrollView,
  TouchableWithoutFeedback,
  Dimensions,
  FlatList,
  ActivityIndicator
} from "react-native";
import CustomeHeader from "../../../components/src/CustomeHeader";
const { height, width } = Dimensions.get("screen");
import { theme } from "../../../framework/src/theme";

import ContentManagementController, {
  Props,
} from "./ContentManagementController";
import AdsBanner from "../../../components/src/AdsBanner";
import Scale from "../../../components/src/Scale";
import  HTMLView from 'react-native-htmlview';

// Customizable Area End
export default class ContentManagement extends ContentManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  getColorTheme = (mode:any, color1:any, color2:any) => {
    return this.context.mode === mode ? color1 : color2
  }

  getList(item: any) {
    let attribute = item?.item;
    let value = attribute?.attributes
    let htmlString = value?.description || '<p></p>';
    let plainString = htmlString?.replace(this.htmlRegx, '');
    return (


      <View style={{ marginLeft: 15, marginRight: 15 }}>
        <View style={styles.subHeaderStyle}>
          <Text style={[styles.subText, { color: this.getColorTheme('light' , theme.light.textColor , theme.dark.textColor) }]}>{item?.index + 1}{"."}{value?.title}</Text>
          <Text style={styles.topicText}>{value?.children != null ? value?.children.length : 0}{"Topics"}</Text>
        </View>
        <View style={{ justifyContent: 'center', alignItems: 'center' }}>
          <Image
            source={{ uri: `${value?.image}` }}
            style={[styles.imageStyle]}
          />
        </View>
        <View style={{maxHeight:Scale(50),overflow:'hidden'}}>
          <HTMLView
          value={plainString}
          stylesheet={{p:{ fontSize: 16,
            color: '#B9BBC0',
            fontFamily:'PlusJakartaText-Regular'}}}
          />
          </View>
        <TouchableOpacity onPress={() => this.onClickMore(value)} style={styles.btnView} testID="btnExample">
          <Text style={styles.moreText}>{"More..."}</Text>

        </TouchableOpacity>
      </View>

    );
  }
  renderEmpty() {
    return (
      <View style={{ alignItems: 'center', justifyContent: 'center', marginTop: 20 }}>
        <Text style={{ color: '#B9BBC0', fontSize: 18,fontFamily:'PlusJakartaText-Bold' }}>{"No Data Found"}</Text>
      </View>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      // Customizable Area Start
      <View style={{flex:1,backgroundColor: this.context.mode === 'light' ? theme.light.headeBg : theme.dark.headeBg }} >
      <View style={{flex:1}} >
      <View  style={[styles.container, { backgroundColor: this.context.mode === 'light' ? theme.light.headeBg : theme.dark.headeBg ,flexGrow:1 }]}
      >
        <TouchableWithoutFeedback
          onPress={() => {
            this.hideKeyboard();
          }}
          testID="withoutFeedback"
        >
          {/* Merge Engine UI Engine Code */}
          <>
            <CustomeHeader
            onsearchPress={() => this.props.navigation.navigate("SearchScreen")}
            onPress={() => this.props.navigation.toggleDrawer()}
            onClickProfile={() => this.props.navigation.navigate("Pushnotifications")}            
            />
            
            {
              this.state.isLoading === true ? <ActivityIndicator size="large" color="green" />
                :
                <View style={[styles.mainView, { backgroundColor: this.getColorTheme('light' , theme.light.highlight , theme.dark.secondaryColor), }]}
                >
                  
                  <FlatList
                  style={{marginBottom:Scale(5)}}
                    testID="FlatListID"
                    data={this.state.arrayHolder}
                    renderItem={(item) => this.getList(item)}
                    extraData={this.state}
                    keyExtractor={(item: any) => item.id}
                    nestedScrollEnabled={true}
                    ListEmptyComponent={this.renderEmpty}
                  />
                 
                </View>
            }
 
          </>
          
          {/* Merge Engine UI Engine Code */}
        </TouchableWithoutFeedback>
        
      </View>
      </View>
        {/* <View 
          style={{ 
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: 0,}}> */}
          <AdsBanner />
        {/* </View> */}
      </View>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  overlay: {
    position: 'absolute',
    top:0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 999,
    justifyContent: 'center', // Ensure AdsBanner is centered vertically
    alignItems: 'center', // Ensure AdsBanner is centered horizontally
},
  container: {
    flex: 1,
    // marginLeft: "auto",
    // marginRight: "auto",
    // width: "100%",
    // maxWidth: 650,
    backgroundColor: "#F7F7F7"
  },
  topContainer: {
    display: "flex",
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
    shadowOffset: { width: 10, height: 10 },
    shadowColor: "black",
    shadowOpacity: 1.0
  },
  topBox: {
    width: "50%",
    paddingVertical: 5,
    marginVertical: 12,
    justifyContent: "center",
    alignItems: "center"
  },
  topText: {
    textAlign: "center",
    fontSize: 16,
    color: "#000"
  },
  rightBorder: {
    borderRightWidth: 1,
    borderRightColor: "#ccc"
  },
  modalContainer: {
    backgroundColor: "white",
    padding: 15,
    minHeight: 260,
    position: "relative"
  },
  modalBox: {
    margin: 0,
    width: "100%",
    justifyContent: "flex-end",
    marginBottom: -20
  },
  closeIcon: {
    position: "absolute",
    right: 15,
    top: 15,
    fontWeight: "normal",
    zIndex: 999
  },
  heading: {
    fontSize: 18,
    color: "#000",
    marginBottom: 15
  },
  sortList: {
    paddingVertical: 8,
    alignItems: "center"
  },
  row: {
    flex: 1,
    justifyContent: "space-between"
  },
  sortText: {
    fontSize: 16,
    color: "#696969"
  },
  sortIcon: {
    marginRight: 12
  },
  flexBox: {
    display: "flex",
    flexDirection: "row"
  },
  productContainer: {
    width: "100%",
    flex: 1,
    justifyContent: "center",
    backgroundColor: "white"
  },
  productBox: {
    height: 250,
    borderBottomWidth: 1,
    flexDirection: "column",
    flex: 0.5,
    borderRightWidth: 1,
    borderColor: "#ccc"
  },
  ImgContainer: {
    //marginBottom: 15,
    height: 150
  },
  productName: {
    paddingVertical: 5,
    fontSize: 16
  },
  price: {
    color: "#444",
    fontSize: 16,
    marginBottom: 10
  },
  productImg: {
    width: "100%",
    height: "100%"
  },
  detailContent: {
    padding: 10
  },
  rating: {
    color: "#000",
    paddingRight: 6
  },
  starBox: {
    alignItems: "center"
  },
  mainView: {

    flex: 1,
    //  marginTop: "10%",
    height: height / 1.2,
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    elevation: 5,
    backgroundColor: '#fff',



  },
  subHeaderStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 10

  },
  subText: {
    color: '#000',
    fontSize: 18,
   fontFamily:'PlusJakartaText-Bold'
  },
  topicText: {
    color: '#B9BBC0',
    fontSize: 16,
    // fontWeight: 'bold'
  },
  imageStyle: {
    height: height/3,
    width: width,
    resizeMode:'contain'
  },
  desTextStyle: {
    fontSize: 16,
    color: '#B9BBC0',
    fontFamily:'PlusJakartaText-Regular'

  },
  btnView: {
    backgroundColor: '#499E5A',
    height: height / 15,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 10

  },
  moreText: {
    fontSize: 18,
    color: '#fff',
    fontFamily:'PlusJakartaText-Bold'

  },
  p: {
    fontSize: 17,
    color: '#A7A8AE',
    // fontWeight: 'bold'
    height: height / 14,
    fontFamily:'PlusJakartaText-Regular'
  },
});
// Customizable Area End

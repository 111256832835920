import React from 'react';
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { toast } from 'react-toastify';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import SortIcon from '@material-ui/icons/Sort';
import ExposureIcon from '@material-ui/icons/Exposure';
import ViewWeekIcon from '@material-ui/icons/ViewWeek';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { isEmpty } from "../../../framework/src/Utilities";
import { fundamentalObjects, indicatorObjects, priceActionMainOptions, indicatorScreenOption, priceActionObjects, volumeObjects, adxOption, macdOption, priceActionCustomOption, priceActionCustomSubOption, timeFrameList, currentPlan, priceActionCustomObjects } from '../../../components/src/chartUtils.web';
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    token: any,
    screenerData: any;
    activeItem: any;
    open: boolean;
    selectedItemData: any;
    anchorEl: any;
    rankList: any;
    rankResponseEmpty: boolean;
    rankResponseData: any;
    listOfSymbolsFromRank: any;
    industryList: any;
    categoriesList: any;
    selectedCategoryResponse: any;
    socialEngagementList: any;
    socialEngagementResponseData: any;
    socialEngagementResponseEmpty: boolean;
    selectedSocialEngagementSymbol: any;
    marketCapitalizationResponseData: any;
    feesPaidList: any;
    volumeOptionList: any;
    volumnPeriodList: any;
    priceActionOptionList: any;
    priceActionSubCategoryOneList: any;
    priceActionTimeFrameList: any;
    priceActionOtherTimeFrameList: any;
    priceActionContinuationPatternTimeFrameList: Array<any>;
    priceActionHighLowSubCategoryList: any;
    priceActionBreakSubCategoryList: any;
    priceActionSupportSubCategoryList: any;
    priceActionNearBreakdownSupportSubCategoryList: any;
    priceActionNearBreakdownResistanceSubCategoryList: any;
    priceActionStandardSubCategoryList: any;
    priceActionStandardChannelSubCategoryList: any;
    priceActionStandardTimeOptionSubCategoryList: any;
    priceActionReversalSubCategoryList: any;
    priceActionReversalBottomSubCategoryList: any;
    priceActionReversalTopSubCategoryList: any;
    priceActionContinuationSubCategoryList: any;
    priceActionCandlesSticksSubCategoryList: any;
    priceActionCandlesBullishSubCategoryList: any;
    priceActionCandlesBearishSubCategoryList: any;
    priceActionCandlesOthersPatternsSubCategoryList: any;
    indicatorsOptionList: any;
    rsiSubCategoryList: any;
    mfiSubCategoryList: any;
    stochasticSubCategoryList: any;
    rsiTimeFrameList: any;
    selectedIndicatorsForFilter: any;
    derivativesCategoryList: any;
    derivativesOptionList: any;
    cciSubCategoryList: any;
    movingAverageSubCategoryList: any;
    simpleMovingAverageSubCategoryList: any;
    exponentialMovingAverageSubCategoryList: any;
    macdSubCategoryList: any;
    adxSubCategoryList: any;
    obvSubCategoryList: any;
    obvDiverganceList: any;
    cmfSubCategoryList: any;
    bollingerBandsSubCategoryList: any;
    atrSubCategoryList: any;
    page: any;
    rowsPerPage: any;
    isExpanded: any;
    priceActionResponseData: any;
    priceActionHighLowResponseData: any;
    priceActionGainLossResponseData: any;
    priceActionBrealoutDownResponseData: any;
    priceActionSupportResistanceResponseData: any;
    priceActionStandardPatternsResponseData: any;
    priceActionCandelSticksResponseData: any;
    priceActionReversalContinualtionResponseData: any;
    indicatorDummyResponseData: any;
    priceOptionCategory: any;
    priceActionBreakSubCategory: any;
    priceOptionSubCategoryOne: any;
    priceActionTimeFrame: any;
    priceActionPercentage: any;
    priceActionLength: any;
    priceActionHighLowSubCategory: any;
    priceActionSupportSubCategory: any;
    priceActionNearBreakdownSubCategory: any;
    priceActionSupportTimeFrameSubCategory: any;
    priceActionStandardSubCategory: any;
    priceActionStandardChannelSubCategory: any;
    priceActionStandardTimeOptionSubCategory: any;
    priceActionReversalSubCategory: any;
    priceActionReversalBottomSubCategory: any;
    priceActionReversalTopSubCategory: any;
    priceActionReversalTimeOptionSubCategory: any;
    priceActionContinuationSubCategory: any;
    priceActionCandlesSticksSubCategory: any;
    priceActionCandlesBullishSubCategory: any;
    priceActionCandlesBearishSubCategory: any;
    priceActionCandleStickTimeOptionSubCategory: any;
    priceActionCandlesOthersPatternsSubCategory: any;
    isLoading: any;
    isMoreData: any;
    priceActionBrealoutDownStart: any;
    priceActionBrealoutDownLimit: any;
    loading: any;
    filteredChip: any;
    handleMoreResult: any;
    moreResultPriceAction: any;
    showHighLowPoints: any;
    lineChartData: any;
    lineOptions: any;
    currentSelectedMoreData: any;
    fundamentalsForm: any;
    paginationPage: any;
    paginationRowsPerPage: any;
    selectedRowObj: any;
    chartTimeFrame: any;
    chartType: any;
    priceActionPriceChartData: any;
    priceActionVolumeChartData: any;
    priceActionMarketCapChartData: any;
    priceActionAreaSeries: any;
    priceActionLineSeries: any;
    priceActionMinimapLineSeries: any;
    priceActionVolumeSeries: any;
    isChartToolTipVisible: any;
    chartToolTipLeft: any;
    chartToolTipTop: any;
    tooltipPrice: any;
    tooltipDate: any;
    tooltipTime: any;
    openCategoryPopup: boolean;
    categoryResponseList: any;
    fundamentalIntervalList: any;
    fundamentalPriceChartData: any;
    fundamentalVolumeChartData: any;
    fundamentalAreaSeries: any;
    fundamentalVolumeSeries: any;
    rankCategoryPaginationPage: any;
    rankCategoryPaginationRowsPerPage: any;
    marketCategoryPaginationPage: any;
    marketCategoryPaginationRowsPerPage: any;
    fundamentalChartTimeFrame: any;
    indicatorResponse: any;

    // for indicator 
    selectedIndicatorsList: any;
    rsiSubCategoryOption: any;
    rsiPeriod: any;
    rsiRadioGroup: any;
    rsiLevel: any;
    centerLine: any;
    rsiTimeFrame: any;
    rsiDivergenceOption: any;
    mfiSubCategoryOption: any;
    mfiPeriod: any;
    mfiRadioGroup: any;
    mfiLevel: any;
    mfiTimeFrame: any;
    mfiDivergenceOption: any;
    stochasticSubCategoryOption: any;
    stochasticPeriod: any;
    stochasticRadioGroup: any;
    stochasticLevel: any;
    stochasticTimeFrame: any;
    stochasticDivergenceOption: any;
    cciSubCategoryOption: any;
    cciPeriod: any;
    cciRadioGroup: any;
    cciTimeFrame: any;
    cciDivergenceOption: any;
    movingAverageSubCategoryOption: any;
    simpleMovingAverageSubCategoryOption: any;
    exponentialMovingAverageSubCategoryOption: any;
    movingAverageTimeFrame: any;
    movingAveragePeriod: any;
    movingAverageCrossoverOption: any;
    macdSubCategoryOption: any;
    macdRadioGroup: any;
    macdTrendingRadioGroup: any;
    macdPeriod: any;
    macdDivergenceOption: any;
    macdTimeFrame: any;
    adxSubCategoryOption: any;
    adxPeriod: any;
    adxRadioGroup: any;
    adxLevel: any;
    adxTimeFrame: any,
    obvSubCategoryOption: any;
    obvLevel: any;
    obvDivergenceOption: any,
    obvTimeFrame: any;
    cmfSubCategoryOption: any;
    cmfPeriod: any;
    cmfTimeFrame: any;
    cmfDivergenceOption: any;
    bollingerBandsSubCategoryOption: any;
    bollingerBandsRadioGroup: any;
    bollingerBandsTimeFrame: any;
    atrSubCategoryOption: any;
    atrPeriod: any;
    atrTrendingPeriod: any;
    atrTimeFrame: any;

    // indicator response data
    indicatorRSIResponseData: any;
    indicatorMFIResponseData: any;
    indicatorStochasticResponseData: any;
    indicatorCCIResponseData: any;
    indicatorMACDResponseData: any;
    indicatorMovingAverageResponseData: any;
    indicatorADXResponseData: any;
    indicatorOBVResponseData: any;
    indicatorCMFResponseData: any;
    indicatorBBResponseData: any;
    indicatorATRResponseData: any;

    // for volume
    volumeOption: any;
    avgVolumnSlider: any;
    volumeValue: any;
    volumnPeriod: any;
    volumnInputLength: any;
    volumeUpResponseData:any;

    indicatorPage: any;
    volumePage: any;
    volumeUpAndDownPopup: boolean;
    lastVolumnPaginationPage: any; 
    lastVolumnPaginationRowsPerPage: any;
    lastVolumeResponseData: any;
    noDataCount: number;
    noIndicatorDataCount: number;
    noData: boolean;
    noIndicatorData: boolean;
    indicatorBtnClicked: boolean;
    navigateCategory: any;

    dataReceived: string;
    clickedBtn: string | null;
    filterNamePopup: boolean;
    filterName: string;
    showCustomFilter: boolean;
    customFilterCoins: any;
    currentCustomFilter: any;
    savedFilters: any;
    customSavedFilters: any;
    customFilterLimitExceed: boolean;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class ScreenerWebController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    getRankApiCallId: string='';
    getAllCategoriesApiCallId: string='';
    getCategoriesUsingSymbolApiCallId: string='';
    getSocialEngagementDetailsApiCallId: string='';
    getSelectedSocialEngagementApiCallId: string='';
    getGainLossPriceApiCallId: string='';
    getHighLowPriceApiCallId: string='';
    getBreakoutBreakdownPriceApiCallId: string='';
    getSupportResistancePriceApiCallId: string='';
    getStandardPatternPriceApiCallId: string='';
    getCandleSticksPriceApiCallId: string='';
    getReversalContinuationPriceApiCallId: string='';
    getGraphsApiCallId: string='';
    indicatorDummyApiCallId: string='';
    indicatorRSIAPICallId: string='';
    indicatorMFIAPICallId: string='';
    indicatorStochasticAPICallId: string='';
    indicatorCCIAPICallId: string='';
    indicatorMACDAPICallId: string='';
    indicatorMovingAverageAPICallId: string='';
    indicatorADXCallId: string='';
    indicatorOBVCallId: string='';
    indicatorCMFCallId: string='';
    indicatorBBCallId: string='';
    indicatorATRCallId: string='';
    averageVolumeCallId: string='';
    volumeUpCallId: string='';
    volumeDownCallId: string='';
    priceActionChart: any;
    priceActionChartContainerRef: any;
    priceActionMinimapChart: any;
    priceActionMinimapChartContainerRef: any;
    floatingTooltip: any;
    getFundamentalGraphsApiCallId: string='';
    saveFiltersCallId: string='';
    saveCustomFiltersCallId: string='';
    getCustomFiltersCoinsCallId: string='';
    getSavedFilterCallId: string='';
    getCustomSavedFilterCallId: string='';
    fundamentalChart: any;
    fundamentalChartContainerRef: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            token: localStorage.getItem('token') || "",
            handleMoreResult: false,
            moreResultPriceAction: false,
            showHighLowPoints: false,
            screenerData: [{
                title: "Fundamentals",
                type: 'root',
                isActive: true,
                icon: <WhatshotIcon fontSize="inherit" />,
                items: [
                ]
            },
            {
                title: "Volumes",
                type: 'root',
                isActive: false,
                icon: <SortIcon fontSize="inherit" />,
                items: [
                ]
            },
            {
                title: "Price Action",
                type: 'root',
                isActive: false,
                icon: <ExposureIcon fontSize="inherit" />,
                items: [
                ]
            },
            {
                title: "Indicators",
                type: 'root',
                isActive: false,
                icon: <ViewWeekIcon fontSize="inherit" />,
                items: [
                ]
            },
        ],
            activeItem: '',
            open: false,
            selectedItemData: '',
            anchorEl: null,
            rankList: [
                { id: 1, name: 'Top 10', value: 10 },
                { id: 2, name: 'Top 50', value: 50 }
            ],
            rankResponseEmpty: true,
            rankResponseData: [],
            listOfSymbolsFromRank: [],
            industryList: [
                { id: 1, name: 'Industry1' },
                { id: 2, name: 'Industry2' },
                { id: 3, name: 'Industry3' }
            ],
            categoriesList: [],
            selectedCategoryResponse: [],
            socialEngagementList: [],
            socialEngagementResponseData: [],
            socialEngagementResponseEmpty: true,
            selectedSocialEngagementSymbol: '',
            marketCapitalizationResponseData: [],
            feesPaidList: [
                { id: 1, name: 'Top 10' },
                { id: 2, name: 'Top 50' },
                { id: 3, name: 'Custom' }
            ],
            volumeOptionList: [
                { id: 1, name: 'Average Volume' },
                { id: 2, name: 'Volume Trending Up' },
                { id: 3, name: 'Volume Trending Down' }
            ],
            volumnPeriodList: [
                { id: 1, name: '5 min' },
                { id: 2, name: '15 min' },
                { id: 3, name: '30 min' },
                { id: 4, name: '1 hr' },
                { id: 5, name: '1 day' },
                { id: 6, name: '1 week' },
                { id: 7, name: 'Custom' },
            ],
            priceActionOptionList: [
                { id: 1, name: 'Gain/Loss' },
                { id: 2, name: 'High/Low' },
                { id: 3, name: 'Breakouts/Breakdowns' },
                { id: 4, name: 'Support/Resistance' },
                { id: 5, name: 'Standard Patterns' },
                { id: 6, name: 'Reversal/Continuation Patterns' },
                { id: 7, name: 'Candlesticks' },
            ],
            priceActionSubCategoryOneList: [
                { id: 1, name: 'Price Up %', value: 'Price Up %', disbled: false },
                { id: 2, name: 'Price Down %', value: 'Price Down %', disbled: false },
                { id: 3, name: 'Price Trending Up', value: 'Price Trending Up', disbled: false },
                { id: 4, name: 'Price Trending Down', value: 'Price Trending Down', disbled: false},
            ],
            priceActionTimeFrameList: timeFrameList,
            priceActionOtherTimeFrameList: [
                { id: 1, name: '1 hr', value: '1h', disabled: false },
                { id: 2, name: '1 day', value: '1d', disabled: false },
                { id: 3, name: '7 days', value: '7d', disabled: false },
                { id: 4, name: '14 days', value: '14d', disabled: false },
                { id: 5, name: '15 days', value: '15d', disabled: false },
                { id: 6, name: '30 days', value: '30d', disabled: false },
            ],

            priceActionContinuationPatternTimeFrameList: [
                { id: 1, name: '1 day', value: '1d', disabled: false },
                { id: 2, name: '7 days', value: '7d', disabled: false },
                { id: 3, name: '15 days', value: '15d', disabled: false },
                { id: 4, name: '30 days', value: '30d', disabled: false },
            ],

            priceActionHighLowSubCategoryList: [
                { id: 1, name: 'Near All Time High', value: 'Near All Time High', disabled: false },
                { id: 2, name: 'Near All Time Low', value: 'Near All Time Low', disabled: false},
                { id: 3, name: 'At All Time High', value: 'At All Time High', disabled: false },
                { id: 4, name: 'At All Time Low', value: 'At All Time Low', disabled: false },
                { id: 5, name: 'Near 52 Weeks High', value: 'Near 52 Weeks High', disabled: false },
                { id: 6, name: 'Near 52 Weeks Low', value: 'Near 52 Weeks Low', disabled: false },
                { id: 7, name: 'Near Past Week High', value: 'Near Past Week High', disabled: false },
                { id: 8, name: 'Near Past Week Low', value: 'Near Past Week Low', disabled: false },
            ],
            priceActionBreakSubCategoryList: [
                { id: 1, name: 'All Time High Breakout', value: 'All Time High Breakout', disabled: false },
                { id: 2, name: 'All Time Low Breakdown', value: 'All Time Low Breakdown', disabled: false },
                { id: 3, name: '52 Weeks High Breakout', value: '52 Weeks High Breakout', disabled: false },
                { id: 4, name: '52 Weeks Low Breakdown', value: '52 Weeks Low Breakdown', disabled: false },
                { id: 5, name: 'Past Week High Breakout', value: 'Past Week High Breakout', disabled: false },
                { id: 6, name: 'Past Week Low Breakdown', value: 'Past Week Low Breakdown', disabled: false },
            ],
            priceActionSupportSubCategoryList: [
                { id: 1, name: 'Standard Support', value: 'Standard Support', disabled: false },
                { id: 2, name: 'Standard Resistance', value: 'Standard Resistance', disabled: false },
                { id: 3, name: 'Fibonacci Support', value: 'Fibonacci Support', disabled: false },
                { id: 4, name: 'Fibonacci Resistance', value: 'Fibonacci Resistance', disabled: false },
            ],
            priceActionNearBreakdownSupportSubCategoryList: [
                { id: 1, name: 'Near S1', value: 'NEAR_S1' },
                { id: 2, name: 'Near S2', value: 'NEAR_S2' },
                { id: 3, name: 'Near S3', value: 'NEAR_S3' },
                { id: 4, name: 'Breakdown S1', value: 'BREAKDOWN_S1' },
                { id: 5, name: 'Breakdown S2', value: 'BREAKDOWN_S2' },
                { id: 6, name: 'Breakdown S3', value: 'BREAKDOWN_S3' },
            ],
            priceActionNearBreakdownResistanceSubCategoryList: [
                { id: 1, name: 'Near R1', value: 'NEAR_R1' },
                { id: 2, name: 'Near R2', value: 'NEAR_R2' },
                { id: 3, name: 'Near R3', value: 'NEAR_R3' },
                { id: 4, name: 'Breakout R1', value: 'BREAKDOWN_R1' },
                { id: 5, name: 'Breakout R2', value: 'BREAKDOWN_R2' },
                { id: 6, name: 'Breakout R3', value: 'BREAKDOWN_R3' },
            ],
            priceActionStandardSubCategoryList: [
                { id: 1, name: 'Flat Channel', value: 'flat channel', disabled: false },
                { id: 2, name: 'Ascending Channel', value: 'ascending channel', disabled: false },
                { id: 3, name: 'Descending Channel', value: 'descending channel', disabled: false },
            ],
            priceActionStandardChannelSubCategoryList: [
                { id: 1, name: 'Inside Channel', value: 'inside channel' },
                { id: 2, name: 'Channel Breakout', value: 'channel breakout' },
                { id: 3, name: 'Channel Breakdown', value: 'channel breakdown' },
            ],
            priceActionStandardTimeOptionSubCategoryList: [
                { id: 1, name: '1 day', value: '1d', disabled: false },
                { id: 2, name: '1 week', value: '7d', disabled: false },
                { id: 3, name: '15 days', value: '15d', disabled: false },
                { id: 4, name: '1 month', value: '30d', disabled: false },
            ],
            priceActionReversalSubCategoryList: [
                { id: 1, name: 'Bullish Reversals', value: 'bullish reversals' },
                { id: 2, name: 'Bearish Reversals', value: 'bearish reversals' },
                { id: 3, name: 'Continuation Patterns', value: 'continuation patterns' },
            ],
            priceActionReversalBottomSubCategoryList: [
                { id: 1, name: 'Double Bottom', value: 'double_bottom_pattern', disabled: false },
                { id: 2, name: 'Triple Bottom', value: 'triple_bottom_pattern', disabled: false },
                { id: 3, name: 'Inverted Head & Shoulder', value: 'inverted_head_shoulder_pattern', disabled: false },
            ],
            priceActionReversalTopSubCategoryList: [
                { id: 1, name: 'Double Top', value: 'double_top_pattern', disabled: false },
                { id: 2, name: 'Triple Top', value: 'triple_top_pattern', disabled: false },
                { id: 3, name: 'Head & Shoulder', value: 'head_shoulder_pattern', disabled: false },
            ],
            priceActionContinuationSubCategoryList: [
                { id: 1, name: 'Symmetrical Triangle', value: 'symmetrical_triangle_pattern', disabled: false },
                { id: 2, name: 'Ascending Triangle', value: 'ascending_triangle_pattern', disabled: false },
                { id: 3, name: 'Descending Triangle', value: 'descending_triangle_pattern', disabled: false },
                { id: 4, name: 'Rising Wedge', value: 'rising_wedge', disabled: false },
                { id: 5, name: 'Falling Wedge', value: 'falling_wedge', disabled: false },
            ],
            priceActionCandlesSticksSubCategoryList: [
                { id: 1, name: 'Bullish Candle Scans', value: 'Bullish Candle Scans' },
                { id: 2, name: 'Bearish Candle Scans', value: 'Bearish Candle Scans' },
                { id: 3, name: 'Other Patterns', value: 'Other Patterns' },
            ],
            priceActionCandlesBullishSubCategoryList: [
                { id: 1, name: 'Bullish Marubozu', value: 'bullish_marubozu', disabled: false },
                { id: 2, name: 'Bullish Engulfing', value: 'bullish_engulfing', disabled: false },
                { id: 3, name: 'Bullish Harami', value: 'bullish_harami', disabled: false },
                { id: 4, name: 'Piercing Line', value: 'piercing_line', disabled: false },
                { id: 5, name: 'Hammer', value: 'hammer', disabled: false },
                { id: 6, name: 'Tweezer Bottoms', value: 'tweezer_bottoms' , disabled: false},
                { id: 7, name: 'Three Advancing Soldiers', value: 'three_advancing_soldiers', disabled: false },
            ],
            priceActionCandlesBearishSubCategoryList: [
                { id: 1, name: 'Bearish Marubozu', value: 'bearish_marubozu', disabled: false },
                { id: 2, name: 'Bearish Engulfing', value: 'bearish_engulfing', disabled: false },
                { id: 3, name: 'Bearish Harami', value: 'bearish_harami', disabled: false },
                { id: 4, name: 'Dark Cloud Cover', value: 'dark_cloud_cover', disabled: false },
                { id: 5, name: 'Hanging Man', value: 'hanging_man', disabled: false },
                { id: 6, name: 'Tweezer Tops', value: 'tweezer_tops', disabled: false },
                { id: 7, name: 'Three Black Crows', value: 'three_black_crows', disabled: false },
            ],
            priceActionCandlesOthersPatternsSubCategoryList: [
                { id: 1, name: 'Spinning Top', value: 'spinning_top', disabled: false },
                { id: 2, name: 'Neutral Doji', value: 'neutral_doji', disabled: false },
                { id: 3, name: 'Dragonfly Doji', value: 'dragonfly_doji', disabled: false },
                { id: 4, name: 'Gravestone Doji', value: 'gravestone_doji', disabled: false },
            ],
            indicatorsOptionList: [
                { id: 1, name: 'RSI', disabled: false },
                { id: 2, name: 'MFI', disabled: false },
                { id: 3, name: 'Stochastic', disabled: false },
                { id: 4, name: 'CCI', disabled: false },
                { id: 5, name: 'Moving Average', disabled: false },
                { id: 6, name: 'MACD', disabled: false },
                { id: 7, name: 'ADX', disabled: false },
                { id: 8, name: 'OBV', disabled: false },
                { id: 9, name: 'CMF', disabled: false },
                { id: 10, name: 'Bollinger Bands', disabled: false },
                { id: 11, name: 'ATR', disabled: false }
            ],
            rsiSubCategoryList: [
                { id: 1, name: 'RSI Above Overbought Level' },
                { id: 2, name: 'RSI Below Oversold Level' },
                { id: 3, name: 'Cross Overbought Level' },
                { id: 4, name: 'Cross Oversold Level' },
                { id: 5, name: 'Cross Center Line' },
                { id: 6, name: 'Divergence' },
            ],
            mfiSubCategoryList: [
                { id: 1, name: 'MFI Above Overbought Level' },
                { id: 2, name: 'MFI Below Oversold Level' },
                { id: 3, name: 'Cross Overbought Level' },
                { id: 4, name: 'Cross Oversold Level' },
                { id: 5, name: 'Cross Center Line' },
                { id: 6, name: 'Divergence' },
            ],
            stochasticSubCategoryList: [
                { id: 1, name: '%K Overbought Level' },
                { id: 2, name: '%K Oversold Level' },
                { id: 3, name: 'Cross Overbought Level' },
                { id: 4, name: 'Cross Oversold Level' },
                { id: 5, name: 'Cross Centerline' },
                { id: 6, name: '%K Cross %D' },
                { id: 8, name: 'Divergence' },
            ],
            rsiTimeFrameList: timeFrameList,
            selectedIndicatorsForFilter: [],
            derivativesCategoryList: [
                { id: 1, name: 'Long Buildup' },
                { id: 2, name: 'Short Buildup' },
                { id: 3, name: 'Short Covering' },
                { id: 4, name: 'Profit Booking' },
                { id: 5, name: 'Put Call Ratio' },
            ],
            derivativesOptionList: [
                { id: 1, name: 'Top 10' },
                { id: 1, name: 'Top 20' },
            ],
            cciSubCategoryList: [
                { id: 1, name: 'CCI Above +100' },
                { id: 2, name: 'CCI Below -100' },
                { id: 3, name: 'CCI Cross +100' },
                { id: 4, name: 'CCI Cross -100' },
                { id: 5, name: 'CCI Cross 0' },
                { id: 6, name: 'Divergence' },
            ],
            movingAverageSubCategoryList: [
                { id: 1, name: 'Simple Moving Average' },
                { id: 2, name: 'Exponential Moving Average' },
            ],
            simpleMovingAverageSubCategoryList: [
                { id: 1, name: 'Price Above Simple Moving Average' },
                { id: 2, name: 'Price Below Simple Moving Average' },
                { id: 3, name: 'Price Crossed Above Simple Moving Average' },
                { id: 4, name: 'Price Crossed Below Simple Moving Average' },
                { id: 5, name: 'Price Bounced Up from Simple Moving Average' },
                { id: 6, name: 'Price Bounced Down from Simple Moving Average' },
                { id: 7, name: 'Moving Average Crossovers' },
            ],
            exponentialMovingAverageSubCategoryList: [
                { id: 1, name: 'Price Above Exponential Moving Average' },
                { id: 2, name: 'Price Below Exponential Moving Average' },
                { id: 3, name: 'Price Crossed Above Exponential Moving Average' },
                { id: 4, name: 'Price Crossed Below Exponential Moving Average' },
                { id: 5, name: 'Price Bounced Up from Exponential Moving Average' },
                { id: 6, name: 'Price Bounced Down from Exponential Moving Average' },
                { id: 7, name: 'Moving Average Crossovers' },
            ],
            macdSubCategoryList: [
                { id: 1, name: 'MACD Centerline(0)' },
                { id: 2, name: 'MACD Crossed Centerline(0)' },
                { id: 3, name: 'MACD Line is Signal Line' },
                { id: 4, name: 'MACD Line is Crossed Signal Line' },
                { id: 5, name: 'MACD Trending' },
                { id: 6, name: 'MACD Divergence' },
            ],
            adxSubCategoryList: [
                { id: 1, name: '+DI Selected Level' },
                { id: 2, name: '+DI Cross Selected Level' },
                { id: 3, name: '+DI Cross -DI' },
                { id: 4, name: '+DI -DI' },
                { id: 5, name: '-DI Selected Level' },
                { id: 6, name: '-DI Cross Selected Level' },
                { id: 7, name: '-DI Cross +DI' },
                { id: 8, name: '-DI +DI' },
                { id: 9, name: 'ADX Selected Level' },
                { id: 10, name: 'ADX Crossed Selected Level' },
                { id: 11, name: 'ADX Trending Up' },
                { id: 12, name: 'ADX Trending Down' },
            ],
            obvSubCategoryList: [
                { id: 1, name: 'On Balance Volume Trending Up' },
                { id: 2, name: 'On Balance Volume Trending Down' },
                { id: 3, name: 'Divergence' },
            ],
            obvDiverganceList: [
                { id: 1, name: 'Bullish' },
                { id: 2, name: 'Bearish' },
            ],
            cmfSubCategoryList: [
                { id: 1, name: 'CMF Above 0' },
                { id: 2, name: 'CMF Below 0' },
                { id: 3, name: 'CMF Crossed Above 0' },
                { id: 4, name: 'CMF Crossed Below 0' },
                { id: 5, name: 'CMF Trending Up' },
                { id: 6, name: 'CMF Trending Down' },
                { id: 7, name: 'Divergence' },
            ],
            bollingerBandsSubCategoryList: [
                { id: 1, name: 'Coin Price Near Upper Band' },
                { id: 2, name: 'Coin Price Near Lower Band' },
                { id: 3, name: 'Coin Price Near Middle Band' },
                { id: 4, name: 'Coin Price Crossed Middle Band' },
                { id: 5, name: 'Coin Price Crossed Upper Band' },
                { id: 6, name: 'Coin Price Crossed Lower Band' },
                { id: 7, name: 'Bollinger Band Squeeze' },
            ],
            atrSubCategoryList: [
                { id: 1, name: 'ATR Trending Up' },
                { id: 2, name: 'ATR Trending Down' },
            ],
            page: 1,
            rowsPerPage: 5,
            paginationPage: 0,
            paginationRowsPerPage: 5,
            isExpanded: false,
            priceActionResponseData: [],
            priceActionGainLossResponseData: [],
            priceActionHighLowResponseData: [],
            priceActionBrealoutDownResponseData: [],
            priceActionSupportResistanceResponseData: [],
            priceActionStandardPatternsResponseData: [],
            priceActionCandelSticksResponseData: [],
            priceActionReversalContinualtionResponseData: [],
            indicatorDummyResponseData: [],
            priceOptionCategory: '',
            priceOptionSubCategoryOne: '',
            priceActionTimeFrame: "",
            priceActionPercentage: '2',
            priceActionLength: 3,
            priceActionHighLowSubCategory: "",
            priceActionBreakSubCategory: "",
            priceActionSupportSubCategory: "",
            priceActionNearBreakdownSubCategory: "",
            priceActionSupportTimeFrameSubCategory: "",
            priceActionStandardSubCategory: "",
            priceActionStandardChannelSubCategory: "",
            priceActionStandardTimeOptionSubCategory: "",
            priceActionReversalSubCategory: "",
            priceActionReversalBottomSubCategory: "",
            priceActionReversalTopSubCategory: "",
            priceActionReversalTimeOptionSubCategory: "",
            priceActionContinuationSubCategory: "",
            priceActionCandlesSticksSubCategory: "",
            priceActionCandlesBullishSubCategory: "",
            priceActionCandlesBearishSubCategory: "",
            priceActionCandleStickTimeOptionSubCategory: "",
            priceActionCandlesOthersPatternsSubCategory: "",
            isLoading: false,
            isMoreData: true,
            dataReceived: "",
            priceActionBrealoutDownStart: 1,
            priceActionBrealoutDownLimit: 10,
            loading: false,
            filteredChip: [],
            currentSelectedMoreData: {},

            // indicators state
            selectedIndicatorsList: [],
            rsiSubCategoryOption: "",
            rsiPeriod: '',
            rsiRadioGroup: "above",
            rsiLevel: '',
            centerLine: '',
            rsiTimeFrame: "",
            rsiDivergenceOption: "",
            mfiSubCategoryOption: "",
            mfiPeriod: '',
            mfiRadioGroup: "above",
            mfiLevel: '',
            mfiTimeFrame: "",
            mfiDivergenceOption: "",
            stochasticSubCategoryOption: "",
            stochasticPeriod: '',
            stochasticRadioGroup: "above",
            stochasticLevel: '',
            stochasticTimeFrame: "",
            stochasticDivergenceOption: "",
            cciSubCategoryOption: "",
            cciPeriod: '',
            cciRadioGroup: "above",
            cciTimeFrame: "",
            cciDivergenceOption: "",
            movingAverageSubCategoryOption: "",
            simpleMovingAverageSubCategoryOption: "",
            exponentialMovingAverageSubCategoryOption: "",
            movingAveragePeriod: "",
            movingAverageTimeFrame: "",
            movingAverageCrossoverOption: "",
            macdSubCategoryOption: "",
            macdRadioGroup: "above",
            macdTrendingRadioGroup: "up",
            macdPeriod: '',
            macdDivergenceOption: "",
            macdTimeFrame: "",
            adxSubCategoryOption: "",
            adxPeriod: '',
            adxRadioGroup: "above",
            adxLevel: '',
            adxTimeFrame: "",
            obvSubCategoryOption: "",
            obvLevel: '',
            obvDivergenceOption: "",
            obvTimeFrame: "",
            cmfSubCategoryOption: "",
            cmfPeriod: "",
            cmfTimeFrame: "",
            cmfDivergenceOption: "",
            bollingerBandsSubCategoryOption: "",
            bollingerBandsRadioGroup: "above",
            bollingerBandsTimeFrame: "",
            atrSubCategoryOption: "",
            atrPeriod: "",
            atrTrendingPeriod: "",
            atrTimeFrame: "",

            // indicator response data state
            indicatorRSIResponseData: [],
            indicatorMFIResponseData: [],
            indicatorStochasticResponseData: [],
            indicatorCCIResponseData: [],
            indicatorMACDResponseData: [],
            indicatorMovingAverageResponseData: [],
            indicatorADXResponseData: [],
            indicatorOBVResponseData: [],
            indicatorCMFResponseData: [],
            indicatorBBResponseData: [],
            indicatorATRResponseData: [],

            // for volume
            volumeOption: "",
            avgVolumnSlider: [0, 100],
            volumeValue: '',
            volumnPeriod: '',
            volumnInputLength: '',
            volumeUpResponseData:[],


            lineChartData: {
                labels: [],
                datasets: [
                    {
                        label: '',
                        data: [],
                        volumn: [],
                        borderColor: 'rgba(1,162,78,255)',
                        backgroundColor: 'rgba(1,162,78,255)',
                        fill: false,
                        datalabels: {
                            display: false
                        }
                    }
                ]
            },
            lineOptions: {
                responsive: true,
                plugins: {
                    title: {
                        display: false,
                        text: 'Chart.js Line Chart',
                    },
                    legend: {
                        display: false,
                        position: 'top' as const,
                    },
                    tooltip: {
                        displayColors: false,
                        callbacks: {
                            title: function (tooltipItem: any, data: any) {
                                return "";
                            },
                            label: function (context: any) {
                                var label = context.dataset.label || '';
                                if (label) {
                                    label += ': ';
                                }
                                if (context.parsed.y !== null) {
                                    label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
                                }
                                return context.dataset.volumn[context.dataIndex];
                            }
                        }
                    }
                },
                scales: {
                    x: {
                        grid: {
                            display: false
                        },
                        ticks: {
                            callback: function (value: any, index: any, values: any) {
                                return '';
                            }
                        }
                    },
                    y: {
                        //beginAtZero: true,
                        grid: {
                            display: false
                        },
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function (value: any, index: any, values: any) {
                                return '$' + value + '.00';
                            }
                        }
                    }
                }
            },
            fundamentalsForm: {
                rank: 10,
                customRankVal: 0,
                category: "",
            },
            selectedRowObj: {},
            chartTimeFrame: '',
            chartType: '',
            openCategoryPopup: false,
            categoryResponseList: [],
            fundamentalIntervalList: [
                { id: 1, name: '1 day', value: '1d' },
                { id: 2, name: '7 days', value: '7d' },
                { id: 3, name: '1 month', value: '1m' },
                { id: 4, name: '3 months', value: '3m' },
                { id: 5, name: '1 year', value: '1y' },
            ],
            priceActionPriceChartData: [],
            priceActionVolumeChartData: [],
            priceActionMarketCapChartData: [],
            priceActionAreaSeries: null,
            priceActionLineSeries: null,
            priceActionMinimapLineSeries: null,
            priceActionVolumeSeries: null,
            isChartToolTipVisible: false,
            chartToolTipLeft: 12,
            chartToolTipTop: 12,
            tooltipPrice: '',
            tooltipDate: '',
            tooltipTime: '',
            fundamentalPriceChartData: [],
            fundamentalVolumeChartData: [],
            fundamentalAreaSeries: null,
            fundamentalVolumeSeries: null,
            rankCategoryPaginationPage: 0,
            rankCategoryPaginationRowsPerPage: 5,
            marketCategoryPaginationPage: 0,
            marketCategoryPaginationRowsPerPage: 5,
            fundamentalChartTimeFrame: '',
            indicatorResponse: '',

            indicatorPage: 1,
            volumePage: 1,
            volumeUpAndDownPopup: false,
            lastVolumnPaginationPage: 0, 
            lastVolumnPaginationRowsPerPage: 5,
            lastVolumeResponseData: [],
            noDataCount: 0,
            noIndicatorDataCount: 0,
            noData: false,
            noIndicatorData: false,
            indicatorBtnClicked: false,
            navigateCategory: 'Screener',
            clickedBtn: null,
            filterNamePopup: false,
            filterName: "",
            showCustomFilter: false,
            customFilterCoins: [],
            currentCustomFilter: {},
            savedFilters: [],
            customSavedFilters: [],
            customFilterLimitExceed: false
            // Customizable Area End
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            
            this.setState({ selectedItemData:  this.state.activeItem });

            switch (apiRequestCallId) {
                case this.getRankApiCallId:
                    this.handleFundamentalResponse(responseJson);
                    break;
                case this.getAllCategoriesApiCallId:
                    this.setState({
                        categoriesList: (!responseJson.data || typeof(responseJson.data) === 'string') ? [] : responseJson.data
                    });
                    break;
                case this.getCategoriesUsingSymbolApiCallId:
                    this.setState({
                        categoryResponseList: responseJson.data || []
                    });
                    break;
                case this.getSocialEngagementDetailsApiCallId:
                    this.setState({
                        socialEngagementList: responseJson.data || []
                    });
                    break;
                case this.getSelectedSocialEngagementApiCallId:
                    this.setState({
                        socialEngagementResponseData: responseJson.data ? [responseJson.data[this.state.selectedSocialEngagementSymbol]] : [],
                        loading: false,
                        socialEngagementResponseEmpty: false
                    });
                    break;
                case this.getGainLossPriceApiCallId:
                    this.handleFiltersResponse('priceActionGainLossResponseData', responseJson, 'page');
                    break;
                case this.getHighLowPriceApiCallId:
                    this.handleFiltersResponse('priceActionHighLowResponseData', responseJson, 'page');
                    break;
                case this.getBreakoutBreakdownPriceApiCallId:
                    this.handleFiltersResponse('priceActionBrealoutDownResponseData', responseJson, 'page');
                    break;
                case this.getSupportResistancePriceApiCallId:
                    this.handleFiltersResponse('priceActionSupportResistanceResponseData', responseJson, 'page');
                    break;
                case this.getStandardPatternPriceApiCallId:
                    this.handleFiltersResponse('priceActionStandardPatternsResponseData', responseJson, 'page');
                    break;
                case this.getFundamentalGraphsApiCallId:
                    this.handleGraphResponse(responseJson);
                    break;
                case this.getCandleSticksPriceApiCallId:
                    this.handleFiltersResponse('priceActionCandelSticksResponseData', responseJson, 'page');
                    break;
                case this.getReversalContinuationPriceApiCallId:
                    this.handleFiltersResponse('priceActionReversalContinualtionResponseData', responseJson, 'page');
                    break;
                case this.indicatorDummyApiCallId:
                    this.handleIndicatorFiltersResponse('indicatorDummyResponseData', responseJson, 'indicatorPage');
                    break;
                case this.indicatorRSIAPICallId:
                    this.handleIndicatorFiltersResponse('indicatorRSIResponseData', responseJson, 'indicatorPage');
                    break;
                case this.indicatorMFIAPICallId:
                    this.handleIndicatorFiltersResponse('indicatorMFIResponseData', responseJson, 'indicatorPage');
                    break;
                case this.indicatorStochasticAPICallId:
                    this.handleIndicatorFiltersResponse('indicatorStochasticResponseData', responseJson, 'indicatorPage');
                    break;
                case this.indicatorCCIAPICallId:
                    this.handleIndicatorFiltersResponse('indicatorCCIResponseData', responseJson, 'indicatorPage');
                    break;
                case this.indicatorMACDAPICallId:
                    this.handleIndicatorFiltersResponse('indicatorMACDResponseData', responseJson, 'indicatorPage');
                    break;
                case this.indicatorMovingAverageAPICallId:
                    this.handleIndicatorFiltersResponse('indicatorMovingAverageResponseData', responseJson, 'indicatorPage');
                    break;
                case this.indicatorADXCallId:
                    this.handleIndicatorFiltersResponse('indicatorADXResponseData', responseJson, 'indicatorPage');
                    break;
                case this.indicatorOBVCallId:
                    this.handleIndicatorFiltersResponse('indicatorOBVResponseData', responseJson, 'indicatorPage');
                    break;
                case this.indicatorCMFCallId:
                    this.handleIndicatorFiltersResponse('indicatorCMFResponseData', responseJson, 'indicatorPage');
                    break;
                case this.indicatorBBCallId:
                    this.handleIndicatorFiltersResponse('indicatorBBResponseData', responseJson, 'indicatorPage');
                    break;
                case this.indicatorATRCallId:
                    this.handleIndicatorFiltersResponse('indicatorATRResponseData', responseJson, 'indicatorPage');
                    break;
                case this.volumeUpCallId:
                    this.handleFiltersResponse('volumeUpResponseData', responseJson, 'volumePage');
                    break;
                case this.saveFiltersCallId:
                    this.handleSaveFiltersResponse(responseJson);
                    break;
                case this.saveCustomFiltersCallId:
                    this.handleSaveFiltersResponse(responseJson, 'custom');
                    break;
                case this.getCustomFiltersCoinsCallId:
                    this.handleCustomFilterCoinListingResponse(responseJson);
                    break;
                case this.getSavedFilterCallId:
                    this.setState({ savedFilters: responseJson.data });
                    break;
                case this.getCustomSavedFilterCallId:
                    this.setState({ customSavedFilters: responseJson.data });
                    break;
                default:
                    break;
            }
        }
        // Customizable Area End
    }
    
    async componentDidMount() {
        // Customizable Area Start
        ChartJS.register(
            CategoryScale,
            LinearScale,
            PointElement,
            LineElement,
            Title,
            Tooltip,
            Legend
        );
        this.getAllCategoryDetails();
        this.getSocialEngagementDetails();
        this.getRankDetails(10, null, null, null);
        this.checkAccessbility();
        window.addEventListener('resize', this.resizeFundamentalChart);
        window.addEventListener('resize', this.resizePriceActionChart);
        if (this.state.token !== "") {
            this.getSavedFilters();
            this.getCustomSavedFilter();
        }
        // Customizable Area End
    }
    // Customizable Area Start
    checkAccessbility() {
        const planAccess = currentPlan();
        const accessibleTimes = planAccess.time_frames;
        const indicatorOptions = planAccess?.indicators;
        const priceActionOptions =  planAccess?.price_action;
        const { indicatorsOptionList } = this.state;
        if (accessibleTimes !== "Unlimited") {
            this.manageTimeFrames(accessibleTimes);
        }
        if (indicatorOptions !== "All") {
            const filterOptions = indicatorsOptionList.filter((option: { id: number, name: string, disabled: boolean }) => {
              if (option.name === 'Moving Average' && indicatorOptions['moving_averages'] === 'Allow') {
                option.disabled = false;
              } else {
                option.disabled = !(Object.keys(indicatorOptions).includes(option.name.toLowerCase()) && indicatorOptions[option.name.toLowerCase()] === 'Allow')
              }
              return option;
            });
            this.setState({ indicatorsOptionList: filterOptions });
        }
        if (priceActionOptions !== "All") {
            this.manageAccessForPriceAction(priceActionOptions);
        }
    }

    manageTimeFrames(accessibleTimes: string) {
        let timeFrames = accessibleTimes.split(" & ");
        timeFrames = timeFrames.map((timeFrame: string) => timeFrame === '1 hour' ? '1 hr' : timeFrame)
        const { priceActionTimeFrameList, priceActionContinuationPatternTimeFrameList } = this.state;
        const patimeFrameList = priceActionTimeFrameList.map((tFrame: {id: number, name: string, value: string, disabled: boolean}) =>
        { 
            tFrame.disabled = !timeFrames.includes(tFrame.name);
            return tFrame;
        })
        const pactimeFrameList = priceActionContinuationPatternTimeFrameList.map((tFrame: {id: number, name: string, value: string, disabled: boolean}) => 
            { 
                tFrame.disabled = !timeFrames.includes(tFrame.name);
                return tFrame;
            })
        this.setState({ priceActionTimeFrameList: patimeFrameList, priceActionOtherTimeFrameList: patimeFrameList, 
            priceActionContinuationPatternTimeFrameList: pactimeFrameList, priceActionStandardTimeOptionSubCategoryList: pactimeFrameList,
        rsiTimeFrameList: patimeFrameList});
    }

    setDisabledForBasicPlan(paOptions: Array<string>, itemList: Array<{id: number, name: string, value: string, disabled: boolean}>) {
        return itemList.map((pasc: {id: number, name: string, value: string, disabled: boolean}) => 
        { 
            pasc.disabled = !paOptions.includes(pasc.name.toLowerCase());
            return pasc;
        });
    }

    manageAccessForPriceAction(priceActionOptions: any) {
        const { priceActionSubCategoryOneList, priceActionHighLowSubCategoryList, 
            priceActionBreakSubCategoryList, priceActionSupportSubCategoryList, 
            priceActionStandardSubCategoryList, priceActionReversalBottomSubCategoryList, 
            priceActionReversalTopSubCategoryList, priceActionContinuationSubCategoryList,
            priceActionCandlesBearishSubCategoryList, priceActionCandlesBullishSubCategoryList,
            priceActionCandlesOthersPatternsSubCategoryList } = this.state;
        const optionKeys = Object.keys(priceActionOptions);
        optionKeys.forEach((optionKey: string) => {
            const paOptions = priceActionOptions[optionKey].split(',').map((paop:string) => paop.toLowerCase().trim());
            switch (optionKey) {
                case 'gain_loss':
                    const filterOptions = this.setDisabledForBasicPlan(paOptions, priceActionSubCategoryOneList);
                    this.setState({ priceActionSubCategoryOneList: filterOptions });
                    break;
                case 'high_low':
                    const filterHighLowOptions = this.setDisabledForBasicPlan(paOptions, priceActionHighLowSubCategoryList);
                    this.setState({ priceActionHighLowSubCategoryList: filterHighLowOptions });
                    break;
                case 'breakout_breakdown':
                    const filterBreakoutdownOptions =this.setDisabledForBasicPlan(paOptions, priceActionBreakSubCategoryList);
                    this.setState({ priceActionBreakSubCategoryList: filterBreakoutdownOptions });
                    break;
                case 'support_resistance':
                    const filterSupportResistance = this.setDisabledForBasicPlan(paOptions, priceActionSupportSubCategoryList);
                    this.setState({ priceActionSupportSubCategoryList: filterSupportResistance });
                    break;
                case 'standard_patterns':
                    const filterStandardPatterns = this.setDisabledForBasicPlan(paOptions, priceActionStandardSubCategoryList);
                    this.setState({ priceActionStandardSubCategoryList: filterStandardPatterns });
                    break;
                case 'reversal_continuation':
                    const filterBottomReversalContiunation = this.setDisabledForBasicPlan(paOptions, priceActionReversalBottomSubCategoryList);
                    const filterTopReversalContinuation = this.setDisabledForBasicPlan(paOptions, priceActionReversalTopSubCategoryList);
                    const filterOtherReversalContinuation = this.setDisabledForBasicPlan(paOptions, priceActionContinuationSubCategoryList);
                    this.setState({ priceActionReversalBottomSubCategoryList: filterBottomReversalContiunation,
                        priceActionReversalTopSubCategoryList: filterTopReversalContinuation, 
                        priceActionContinuationSubCategoryList: filterOtherReversalContinuation});
                    break;
                case 'candlesticks':
                    const filterCandleSticks = this.setDisabledForBasicPlan(paOptions, priceActionCandlesBullishSubCategoryList);
                    const filterBearishCandleSticks = this.setDisabledForBasicPlan(paOptions, priceActionCandlesBearishSubCategoryList);
                    const filterOtherCandleSticks = this.setDisabledForBasicPlan(paOptions, priceActionCandlesOthersPatternsSubCategoryList);
                    this.setState({ priceActionCandlesBullishSubCategoryList: filterCandleSticks, 
                        priceActionCandlesBearishSubCategoryList: filterBearishCandleSticks, 
                        priceActionCandlesOthersPatternsSubCategoryList: filterOtherCandleSticks });
                    break;
            }

        })
        
    }
    handleSaveFiltersResponse = (responseJson: any, filterType:string = "") => {
        this.setState({ filterNamePopup: false, filterName: "", navigateCategory: "Screener"});
        toast.success(responseJson.message);
        if (filterType === "custom") {
            this.setState({
                fundamentalsForm: {
                    rank: "",
                    customRankVal: 0,
                    category: "",
                    marketCapitalizationFrom: "",
                    marketCapitalizationTo: ""
                },
                selectedIndicatorsForFilter: [],
            });    
            this.resetVolumeData();
            this.resetPriceActionData(); 
            this.resetIndicatorDataBeforeAPICall();
            this.getCustomSavedFilter();
            this.setState({ navigateCategory: "SavedFilter" });
        }
    }

    handleCustomFilterCoinListingResponse = (responseJson: any) => {
        this.setState({ loading: false });
        if (responseJson.filtered_coins) {
            this.setState({ customFilterCoins: responseJson.filtered_coins, showCustomFilter: true });
        } else {
            this.setState({ customFilterCoins: [], showCustomFilter: true });
        }
    }

    sortObj = (data: any) => {
        return data.sort((obj1: any, obj2: any) => obj1.cmc_rank - obj2.cmc_rank)
    }

    resizeFundamentalChart = () => {
        if (this.fundamentalChart.current) {
            this.fundamentalChart.current.applyOptions({
                width: this.fundamentalChartContainerRef.current.clientWidth,
                height: this.fundamentalChartContainerRef.current.clientHeight
            });
            this.fundamentalChart.current.timeScale().fitContent();
        }
    };

    resizePriceActionChart = () => {
        if (this.priceActionChart.current) {
            this.priceActionChart.current.applyOptions({
                width: this.priceActionChartContainerRef.current.clientWidth,
                height: this.priceActionChartContainerRef.current.clientHeight
            });
            this.priceActionChart.current.timeScale().fitContent();
        }
    };

    async componentWillUnmount() {
        window.removeEventListener('resize', this.resizeFundamentalChart);
        window.removeEventListener('resize', this.resizePriceActionChart);
    }

    handleGraphResponse = (responseJson: any) => {
        this.setState({ loading: false });
        if (responseJson.data && responseJson.data.points) {
            let chartPriceData: any = []
            let chartVolumeData: any = []

            Object.keys(responseJson.data.points).forEach(function (key, index) {
                chartPriceData.push({ 'time': Number(key), 'value': responseJson.data.points[key].v[0] });
            });
            Object.keys(responseJson.data.points).forEach(function (key, index) {
                chartVolumeData.push({ 'time': Number(key), 'value': responseJson.data.points[key].v[1] });
            });
            this.setState({
                fundamentalPriceChartData: chartPriceData,
                fundamentalVolumeChartData: chartVolumeData
            });
        }
    }

    handleFundamentalResponse = (response: any) => {
        if (response.data) {
            if (response.data.length > 0) {
                response.data.map((res:any) => {
                    let symbol = res.symbol ? res.symbol : res.name.split(" ").pop();
                    res.name =  res.symbol ? res.name : res.name.substring(0, res.name.lastIndexOf(" "));  
                    res.symbol = symbol;
                    return res;
                });
            }
            this.setState({
                rankResponseData: Array.isArray(response.data) ? this.sortObj(response.data) : [],
                loading: false,
                rankResponseEmpty: false
            });
        }
        else {
            if (response.errors) {
                toast.error(response.errors[0]);
            }
            this.setState({
                rankResponseData: [],
                loading: false,
                rankResponseEmpty: true
            }, () => {
            })
        }
    } 

    clearFundamentalsForm = (flag: any) => {
        if (flag == 'rankDetails') {
            this.setState({
                selectedCategoryResponse: [],
                socialEngagementResponseData: [],
                socialEngagementResponseEmpty: true,
                marketCapitalizationResponseData: [],
                filteredChip: ['Fundamental Filter']
            })
        } else if (flag == 'category') {
            this.setState({
                rankResponseData: [],
                rankResponseEmpty: true,
                socialEngagementResponseData: [],
                socialEngagementResponseEmpty: true,
                marketCapitalizationResponseData: [],
                filteredChip: ['Category Filter']
            })
        } else if (flag == 'socialEngagement') {
            this.setState({
                rankResponseData: [],
                selectedCategoryResponse: [],
                marketCapitalizationResponseData: [],
                filteredChip: ['Social Engagement Filter'],
                rankResponseEmpty: true
            })
        } else if (flag == 'marketCapitalization') {
            this.setState({
                rankResponseData: [],
                selectedCategoryResponse: [],
                socialEngagementResponseData: [],
                socialEngagementResponseEmpty: true,
                filteredChip: ['Market Capitalizaation Filter'],
                rankResponseEmpty: true
            })
        }
    }

    handlePriceActionGraphResponse = (responseJson: any) => {
        this.setState({ loading: false });
        if (responseJson.data?.points) {
            let chartPriceData: any = []
            let chartVolumeData: any = []
            let chartMarketCapData: any = []

            Object.keys(responseJson.data.points).forEach(function (key, index) {
                chartPriceData.push({ 'time': Number(key), 'value': responseJson.data.points[key].v[0] });
            });
            Object.keys(responseJson.data.points).forEach(function (key, index) {
                chartVolumeData.push({ 'time': Number(key), 'value': responseJson.data.points[key].v[1] });
            });
            Object.keys(responseJson.data.points).forEach(function (key, index) {
                chartMarketCapData.push({ 'time': Number(key), 'value': responseJson.data.points[key].v[2] });
            });
            this.setState({
                priceActionPriceChartData: chartPriceData,
                priceActionVolumeChartData: chartVolumeData,
                priceActionMarketCapChartData: chartMarketCapData
            })
        }
    }
    
    handleFiltersResponse = (item: keyof S, response: any, page: keyof S) => {        
        this.setState({
            isLoading: false,
            loading: false,
        });
        if (response.data && Array.isArray(response.data)) {
           const data =  Array.isArray(response.data[0]) ? response.data[0] : response.data; 
            if (data.length > 0) {
                this.setState((prevState: Readonly<S>) => ({
                    ...prevState,
                    [item]: [...prevState[item] || [], ...data],
                    noDataCount: 0,
                    noData: false
                  }));                  
            } else if (this.state.noDataCount < 3) {
                this.setState((prevState: Readonly<S>) => ({
                    ...prevState,
                    // isLoading: true,
                    [page]: this.state[page] + 1,
                    noDataCount: this.state.noDataCount + 1,
                }));
            } else {
                this.setState({ noDataCount: 0, noData: true, isMoreData: !this.state[item].length });
            }
        } else {
            this.setState({
                noDataCount: 0,
                noData: true,
                isMoreData: !this.state[item].length
            })
        }
        this.handleDataRefresh()
    }

    handleItemClick = (event: any, item: any) => {
        this.setState({
            activeItem: item.title,
            anchorEl: event.currentTarget,
        });
    }

    handleDataRefresh = () => {
        const { activeItem, priceOptionSubCategoryOne, priceOptionCategory, priceActionSupportSubCategory,
            priceActionHighLowSubCategory, priceActionBreakSubCategory } = this.state;
        let dataReceived;
        if (activeItem === "Price Action") {
            switch (parseInt(priceOptionCategory)) {
                case 1:
                    dataReceived = priceOptionSubCategoryOne;
                    break;
                case 2:
                    dataReceived = priceActionHighLowSubCategory;
                    break;
                case 3:
                    dataReceived = priceActionBreakSubCategory;
                    break;
                case 4:
                    dataReceived = priceActionSupportSubCategory;
                    break;
                default:
                    dataReceived = "";
            }
            this.setState({ dataReceived });
        }

    }

    setNavigation = () => {
        if (this.state.clickedBtn === 'Run') {
            this.setState({
                navigateCategory: 'Screener'
            });
        }

    }

    handleIndicatorFiltersResponse = (item: keyof S, response: any, page: keyof S) => {        
        this.setState({
            loading: false,
            isLoading: false
        });
        if (response.data && Array.isArray(response.data)) {
           const indicatorData =  Array.isArray(response.data[0]) ? response.data[0] : response.data; 
            if (indicatorData.length > 0) {
                this.setState((prevState: Readonly<S>) => ({
                    ...prevState,
                    [item]: [...prevState[item] || [], ...indicatorData],
                    noIndicatorDataCount: 0,
                    noIndicatorData: false,
                    indicatorBtnClicked: false
                  }));                  
            } else if (this.state.noIndicatorDataCount < 3) {
                this.setState((prevState: Readonly<S>) => ({
                    ...prevState,
                    // isLoading: true,
                    [page]: this.state[page] + 1,
                    noIndicatorDataCount: this.state.noIndicatorDataCount + 1,
                }));
            } else {
                this.setState({ noIndicatorDataCount: 0, noIndicatorData: true, isMoreData: !this.state[item].length });
            }
        } else {
            this.setState({
                isMoreData: !this.state[item].length,
                noIndicatorDataCount: 0,
                noIndicatorData: true
            })
        }
    }

    priceActionFilter = (flag: any) => {
        if (flag === 'highlow') {
            this.setState({
                filteredChip: [`${this.state.priceActionHighLowSubCategory}`]
            })
        } else if (flag === 'gainloss') {
            this.setState({
                filteredChip: [`${this.state.priceOptionSubCategoryOne}`]
            })
        } else if (flag === 'breakoutdown') {
            this.setState({
                filteredChip: [`${this.state.priceActionBreakSubCategory}`]
            })
        } else if (flag === 'supportresistance') {
            let priceActionNearBreakdownSubCategory = this.state.priceActionNearBreakdownSubCategory
            let filterName = [...priceActionNearBreakdownSubCategory][0] + [...priceActionNearBreakdownSubCategory.toLowerCase()].slice(1).join('');
            this.setState({
                filteredChip: [`${this.state.priceActionSupportSubCategory} - ${filterName}`]
            })
        } else if (flag === 'standardPatterns') {
            this.setState({
                filteredChip: [`${this.state.priceActionStandardChannelSubCategory} - ${this.state.priceActionStandardSubCategory}`]
            })
        } else if (flag === 'reversalContinuation') {
            if (this.state.priceActionReversalSubCategory === 'bullish reversals') {
                this.setState({
                    filteredChip: [`${this.state.priceActionReversalSubCategory} - ${this.state.priceActionReversalBottomSubCategory}`]
                })
            } else if (this.state.priceActionReversalSubCategory === 'bearish reversals') {
                this.setState({
                    filteredChip: [`${this.state.priceActionReversalSubCategory} - ${this.state.priceActionReversalTopSubCategory}`]
                })
            } else if (this.state.priceActionReversalSubCategory === 'continuation patterns') {
                this.setState({
                    filteredChip: [`${this.state.priceActionReversalSubCategory} - ${this.state.priceActionContinuationSubCategory}`]
                })
            }
        } else if (flag === 'candleSticks') {
            if (this.state.priceActionCandlesSticksSubCategory == 'Bullish Candle Scans') {
                this.setState({
                    filteredChip: [`${this.state.priceActionCandlesSticksSubCategory} - ${this.state.priceActionCandlesBullishSubCategory}`]
                })
            } else if (this.state.priceActionCandlesSticksSubCategory == 'Bearish Candle Scans') {
                this.setState({
                    filteredChip: [`${this.state.priceActionCandlesSticksSubCategory} - ${this.state.priceActionCandlesBearishSubCategory}`]
                })
            } else if (this.state.priceActionCandlesSticksSubCategory == 'Other Patterns') {
                this.setState({
                    filteredChip: [`${this.state.priceActionCandlesSticksSubCategory} - ${this.state.priceActionCandlesOthersPatternsSubCategory}`]
                })
            }
        }
    }

    indicatorFilter = (flag: any) => {
        if (flag === 'rsi') {
            if (this.state.rsiSubCategoryOption === 1) {
                this.setState({
                    filteredChip: [`RSI Above Overbought Level`]
                })
            } else if (this.state.rsiSubCategoryOption === 2) {
                this.setState({
                    filteredChip: [`RSI Below Oversold Level`]
                })
            } else if (this.state.rsiSubCategoryOption === 3) {
                if (this.state.rsiRadioGroup === 'above') {
                    this.setState({
                        filteredChip: [`Cross Above Overbought Level`]
                    })
                } else {
                    this.setState({
                        filteredChip: [`Cross Below Overbought Level`]
                    })
                }
            } else if (this.state.rsiSubCategoryOption === 4) {
                if (this.state.rsiRadioGroup === 'above') {
                    this.setState({
                        filteredChip: [`Cross Above Oversold Level`]
                    })
                } else {
                    this.setState({
                        filteredChip: [`Cross Below Oversold Level`]
                    })
                }
            } else if (this.state.rsiSubCategoryOption === 5) {
                if (this.state.rsiRadioGroup === 'above') {
                    this.setState({
                        filteredChip: [`Cross Above Center Line`]
                    })
                } else {
                    this.setState({
                        filteredChip: [`Cross Below Center Line`]
                    })
                }
            } else if (this.state.rsiSubCategoryOption === 6) {
                if (this.state.rsiDivergenceOption === 'bullish') {
                    this.setState({
                        filteredChip: [`Divergence Bullish`]
                    })
                } else {
                    this.setState({
                        filteredChip: [`Divergence Bearish`]
                    })
                }
            }
        } else if (flag === 'cci') {
            if (this.state.cciSubCategoryOption === 1) {
                this.setState({
                    filteredChip: [`CCI Above +100`]
                })
            } else if (this.state.cciSubCategoryOption === 2) {
                this.setState({
                    filteredChip: [`CCI Below -100`]
                })
            } else if (this.state.cciSubCategoryOption === 3) {
                if (this.state.cciRadioGroup === 'above') {
                    this.setState({
                        filteredChip: [`Cross Above +100`]
                    })
                } else {
                    this.setState({
                        filteredChip: [`Cross Below +100`]
                    })
                }
            } else if (this.state.cciSubCategoryOption === 4) {
                if (this.state.cciRadioGroup === 'above') {
                    this.setState({
                        filteredChip: [`Cross Above -100`]
                    })
                } else {
                    this.setState({
                        filteredChip: [`Cross Below -100`]
                    })
                }
            } else if (this.state.cciSubCategoryOption === 5) {
                if (this.state.cciRadioGroup === 'above') {
                    this.setState({
                        filteredChip: [`Cross Above 0`]
                    })
                } else {
                    this.setState({
                        filteredChip: [`Cross Below 0`]
                    })
                }
            } else if (this.state.cciSubCategoryOption === 6) {
                if (this.state.cciDivergenceOption === 'bullish') {
                    this.setState({
                        filteredChip: [`Divergence Bullish`]
                    })
                } else {
                    this.setState({
                        filteredChip: [`Divergence Bearish`]
                    })
                }
            }
        } else if (flag === 'macd') {
            if (this.state.macdSubCategoryOption === 1) {
                if (this.state.macdRadioGroup === 'above') {
                    this.setState({
                        filteredChip: [`MACD Above Centerline(0)`]
                    })
                } else {
                    this.setState({
                        filteredChip: [`MACD Below Centerline(0)`]
                    })
                }
            } else if (this.state.macdSubCategoryOption === 2) {
                if (this.state.macdRadioGroup === 'above') {
                    this.setState({
                        filteredChip: [`MACD Crossed Above Centerline(0)`]
                    })
                } else {
                    this.setState({
                        filteredChip: [`MACD Crossed Below Centerline(0)`]
                    })
                }
            } else if (this.state.macdSubCategoryOption === 3) {
                if (this.state.macdRadioGroup === 'above') {
                    this.setState({
                        filteredChip: [`MACD Line Is Above Signal Line`]
                    })
                } else {
                    this.setState({
                        filteredChip: [`MACD Line Is Below Signal Line`]
                    })
                }
            } else if (this.state.macdSubCategoryOption === 4) {
                if (this.state.macdRadioGroup === 'above') {
                    this.setState({
                        filteredChip: [`MACD Line Is Crossed Above Signal Line`]
                    })
                } else {
                    this.setState({
                        filteredChip: [`MACD Line Is Crossed Below Signal Line`]
                    })
                }
            } else if (this.state.macdSubCategoryOption === 5) {
                if (this.state.macdTrendingRadioGroup === 'up') {
                    this.setState({
                        filteredChip: [`MACD Trending Up`]
                    })
                } else {
                    this.setState({
                        filteredChip: [`MACD Trending Down`]
                    })
                }
            } else if (this.state.macdSubCategoryOption === 6) {
                if (this.state.macdDivergenceOption === 'bullish') {
                    this.setState({
                        filteredChip: [`MACD Divergence Bullish`]
                    })
                } else {
                    this.setState({
                        filteredChip: [`MACD Divergence Bearish`]
                    })
                }
            }
        } else if (flag === 'mfi') {
            if (this.state.mfiSubCategoryOption === 1) {
                this.setState({
                    filteredChip: [`MFI Above Overbought Level`]
                })
            } else if (this.state.mfiSubCategoryOption === 2) {
                this.setState({
                    filteredChip: [`MFI Below Oversold Level`]
                })
            } else if (this.state.mfiSubCategoryOption === 3) {
                if (this.state.mfiRadioGroup === 'above') {
                    this.setState({
                        filteredChip: [`Cross Above Overbought Level`]
                    })
                } else {
                    this.setState({
                        filteredChip: [`Cross Below Overbought Level`]
                    })
                }
            } else if (this.state.mfiSubCategoryOption === 4) {
                if (this.state.mfiRadioGroup === 'above') {
                    this.setState({
                        filteredChip: [`Cross Above Oversold Level`]
                    })
                } else {
                    this.setState({
                        filteredChip: [`Cross Below Oversold Level`]
                    })
                }
            } else if (this.state.mfiSubCategoryOption === 5) {
                if (this.state.mfiRadioGroup === 'above') {
                    this.setState({
                        filteredChip: [`Cross Above Center Line`]
                    })
                } else {
                    this.setState({
                        filteredChip: [`Cross Below Center Line`]
                    })
                }
            } else if (this.state.mfiSubCategoryOption === 6) {
                if (this.state.mfiDivergenceOption === 'bullish') {
                    this.setState({
                        filteredChip: [`Divergence Bullish`]
                    })
                } else {
                    this.setState({
                        filteredChip: [`Divergence Bearish`]
                    })
                }
            }
        } else if (flag === 'stochastic') {
            // Stochastic Indicator
            if (this.state.stochasticSubCategoryOption === 1) {
                if (this.state.stochasticRadioGroup === 'above') {
                    this.setState({
                        filteredChip: [`%K Above Overbought Level`]
                    })
                } else {
                    this.setState({
                        filteredChip: [`%K Below Overbought Level`]
                    })
                }
            } else if (this.state.stochasticSubCategoryOption === 2) {
                if (this.state.stochasticRadioGroup === 'above') {
                    this.setState({
                        filteredChip: [`%K Above Oversold Level`]
                    })
                } else {
                    this.setState({
                        filteredChip: [`%K Below Oversold Level`]
                    })
                }
            } else if (this.state.stochasticSubCategoryOption === 3) {
                if (this.state.stochasticRadioGroup === 'above') {
                    this.setState({
                        filteredChip: [`Cross Above Overbought Level`]
                    })
                } else {
                    this.setState({
                        filteredChip: [`Cross Below Overbought Level`]
                    })
                }
            } else if (this.state.stochasticSubCategoryOption === 4) {
                if (this.state.stochasticRadioGroup === 'above') {
                    this.setState({
                        filteredChip: [`Cross Above Oversold Level`]
                    })
                } else {
                    this.setState({
                        filteredChip: [`Cross Below Oversold Level`]
                    })
                }
            } else if (this.state.stochasticSubCategoryOption === 5) {
                if (this.state.stochasticRadioGroup === 'above') {
                    this.setState({
                        filteredChip: [`Cross Above Center Line`]
                    })
                } else {
                    this.setState({
                        filteredChip: [`Cross Below Center Line`]
                    })
                }
            } else if (this.state.stochasticSubCategoryOption === 6) {
                if (this.state.stochasticRadioGroup === 'above') {
                    this.setState({
                        filteredChip: [`%K Cross Above %D`]
                    })
                } else {
                    this.setState({
                        filteredChip: [`%K Cross Below %D`]
                    })
                }
            } else if (this.state.stochasticSubCategoryOption === 8) {
                if (this.state.stochasticDivergenceOption === 'bullish') {
                    this.setState({
                        filteredChip: [`Divergence Bullish`]
                    })
                } else {
                    this.setState({
                        filteredChip: [`Divergence Bearish`]
                    })
                }
            }
        } else if (flag === 'movingAverage') {
            if (this.state.movingAverageSubCategoryOption === 1) {
                if (this.state.simpleMovingAverageSubCategoryOption === 1) {
                    this.setState({
                        filteredChip: [`Price Above Simple Moving Average`]
                    })
                } else if (this.state.simpleMovingAverageSubCategoryOption === 2) {
                    this.setState({
                        filteredChip: [`Price Below Simple Moving Average`]
                    })
                } else if (this.state.simpleMovingAverageSubCategoryOption === 3) {
                    this.setState({
                        filteredChip: [`Price Crossed Above Simple Moving Average`]
                    })
                } else if (this.state.simpleMovingAverageSubCategoryOption === 4) {
                    this.setState({
                        filteredChip: [`Price Crossed Below Simple Moving Average`]
                    })
                } else if (this.state.simpleMovingAverageSubCategoryOption === 5) {
                    this.setState({
                        filteredChip: [`Price Bounced Up from Simple Moving Average`]
                    })
                } else if (this.state.simpleMovingAverageSubCategoryOption === 6) {
                    this.setState({
                        filteredChip: [`Price Bounced Down from Simple Moving Average`]
                    })
                } else if (this.state.simpleMovingAverageSubCategoryOption === 7) {
                    if (this.state.movingAverageCrossoverOption === 'bullish') {
                        this.setState({
                            filteredChip: [`Moving Average Crossover Bullish`]
                        })
                    } else {
                        this.setState({
                            filteredChip: [`Moving Average Crossover Bearish`]
                        })
                    }
                }
            } else if (this.state.movingAverageSubCategoryOption === 2) {
                if (this.state.exponentialMovingAverageSubCategoryOption === 1) {
                    this.setState({
                        filteredChip: [`Price Above Exponential Moving Average`]
                    })
                } else if (this.state.exponentialMovingAverageSubCategoryOption === 2) {
                    this.setState({
                        filteredChip: [`Price Below Exponential Moving Average`]
                    })
                } else if (this.state.exponentialMovingAverageSubCategoryOption === 3) {
                    this.setState({
                        filteredChip: [`Price Crossed Above Exponential Moving Average`]
                    })
                } else if (this.state.exponentialMovingAverageSubCategoryOption === 4) {
                    this.setState({
                        filteredChip: [`Price Crossed Below Exponential Moving Average`]
                    })
                } else if (this.state.exponentialMovingAverageSubCategoryOption === 5) {
                    this.setState({
                        filteredChip: [`Price Bounced Up from Exponential Moving Average`]
                    })
                } else if (this.state.exponentialMovingAverageSubCategoryOption === 6) {
                    this.setState({
                        filteredChip: [`Price Bounced Down from Exponential Moving Average`]
                    })
                } else if (this.state.exponentialMovingAverageSubCategoryOption === 7) {
                    if (this.state.movingAverageCrossoverOption === 'bullish') {
                        this.setState({
                            filteredChip: [`Moving Average Crossover Bullish`]
                        })
                    } else {
                        this.setState({
                            filteredChip: [`Moving Average Crossover Bearish`]
                        })
                    }
                }
            }
        } else if (flag === 'adx') {
            if (this.state.adxSubCategoryOption === 1) {
                if (this.state.adxRadioGroup === 'above') {
                    this.setState({
                        filteredChip: [`+DI above selected level`]
                    })
                } else {
                    this.setState({
                        filteredChip: [`+DI below selected level`]
                    })
                }
            } else if (this.state.adxSubCategoryOption === 2) {
                if (this.state.adxRadioGroup === 'above') {
                    this.setState({
                        filteredChip: [`+DI cross above selected level`]
                    })
                } else {
                    this.setState({
                        filteredChip: [`+DI cross below selected level`]
                    })
                }
            } else if (this.state.adxSubCategoryOption === 3) {
                if (this.state.adxRadioGroup === 'above') {
                    this.setState({
                        filteredChip: [`+DI cross above -DI`]
                    })
                } else {
                    this.setState({
                        filteredChip: [`+DI cross below -DI`]
                    })
                }
            } else if (this.state.adxSubCategoryOption === 4) {
                if (this.state.adxRadioGroup === 'above') {
                    this.setState({
                        filteredChip: [`+DI above -DI`]
                    })
                } else {
                    this.setState({
                        filteredChip: [`+DI below -DI`]
                    })
                }
            } else if (this.state.adxSubCategoryOption === 5) {
                if (this.state.adxRadioGroup === 'above') {
                    this.setState({
                        filteredChip: [`-DI above selected level`]
                    })
                } else {
                    this.setState({
                        filteredChip: [`-DI below selected level`]
                    })
                }
            } else if (this.state.adxSubCategoryOption === 6) {
                if (this.state.adxRadioGroup === 'above') {
                    this.setState({
                        filteredChip: [`-DI cross above selected level`]
                    })
                } else {
                    this.setState({
                        filteredChip: [`-DI cross below selected level`]
                    })
                }
            } else if (this.state.adxSubCategoryOption === 7) {
                if (this.state.adxRadioGroup === 'above') {
                    this.setState({
                        filteredChip: [`-DI cross above +DI`]
                    })
                } else {
                    this.setState({
                        filteredChip: [`-DI cross below +DI`]
                    })
                }
            } else if (this.state.adxSubCategoryOption === 8) {
                if (this.state.adxRadioGroup === 'above') {
                    this.setState({
                        filteredChip: [`-DI above +DI`]
                    })
                } else {
                    this.setState({
                        filteredChip: [`-DI below +DI`]
                    })
                }
            } else if (this.state.adxSubCategoryOption === 9) {
                if (this.state.adxRadioGroup === 'above') {
                    this.setState({
                        filteredChip: [`ADX above selected level`]
                    })
                } else {
                    this.setState({
                        filteredChip: [`ADX below selected level`]
                    })
                }
            } else if (this.state.adxSubCategoryOption === 10) {
                if (this.state.adxRadioGroup === 'above') {
                    this.setState({
                        filteredChip: [`ADX crossed above selected level`]
                    })
                } else {
                    this.setState({
                        filteredChip: [`ADX crossed below selected level`]
                    })
                }
            } else if (this.state.adxSubCategoryOption === 11) {
                this.setState({
                    filteredChip: [`ADX trending up`]
                })
            } else if (this.state.adxSubCategoryOption === 12) {
                this.setState({
                    filteredChip: [`ADX trending down`]
                })
            }
        } else if (flag === 'obv') {
            if (this.state.obvSubCategoryOption === 1) {
                this.setState({
                    filteredChip: [`On balance volume trending up`]
                })
            } else if (this.state.obvSubCategoryOption === 2) {
                this.setState({
                    filteredChip: [`On balance volume trending down`]
                })
            } else if (this.state.obvSubCategoryOption === 3) {
                if (this.state.obvDivergenceOption === 'Bullish') {
                    this.setState({
                        filteredChip: [`divergence bullish`]
                    })
                } else {
                    this.setState({
                        filteredChip: [`divergence bearish`]
                    })
                }
            }
        } else if (flag === 'cmf') {
            if (this.state.cmfSubCategoryOption === 1) {
                this.setState({
                    filteredChip: [`CMF Above 0`]
                });
            } else if (this.state.cmfSubCategoryOption === 2) {
                this.setState({
                    filteredChip: [`CMF Below 0`]
                });
            } else if (this.state.cmfSubCategoryOption === 3) {
                this.setState({
                    filteredChip: [`CMF Crossed Above 0`]
                });
            } else if (this.state.cmfSubCategoryOption === 4) {
                this.setState({
                    filteredChip: [`CMF Crossed Below 0`]
                });
            } else if (this.state.cmfSubCategoryOption === 5) {
                this.setState({
                    filteredChip: [`CMF Trending Up`]
                });
            } else if (this.state.cmfSubCategoryOption === 6) {
                this.setState({
                    filteredChip: [`CMF Trending Down`]
                });
            } else if (this.state.cmfSubCategoryOption === 7) {
                if (this.state.cmfDivergenceOption === 'bullish') {
                    this.setState({
                        filteredChip: [`Divergence Bullish`]
                    });
                } else {
                    this.setState({
                        filteredChip: [`Divergence Bearish`]
                    });
                }
            }
        } else if (flag === 'bb') {
            if (this.state.bollingerBandsSubCategoryOption === 1) {
                this.setState({
                    filteredChip: [`Coin price near upper band`]
                });
            } else if (this.state.bollingerBandsSubCategoryOption === 2) {
                this.setState({
                    filteredChip: [`Coin price near lower band`]
                });
            } else if (this.state.bollingerBandsSubCategoryOption === 3) {
                this.setState({
                    filteredChip: [`Coin price near middle band`]
                });
            } else if (this.state.bollingerBandsSubCategoryOption === 4) {
                if (this.state.bollingerBandsRadioGroup === 'above') {
                    this.setState({
                        filteredChip: [`Coin price crossed above middle band`]
                    })
                } else {
                    this.setState({
                        filteredChip: [`Coin price crossed below middle band`]
                    })
                }
            } else if (this.state.bollingerBandsSubCategoryOption === 5) {
                if (this.state.bollingerBandsRadioGroup === 'above') {
                    this.setState({
                        filteredChip: [`Coin price crossed above upper band`]
                    })
                } else {
                    this.setState({
                        filteredChip: [`Coin price crossed below upper band`]
                    })
                }
            } else if (this.state.bollingerBandsSubCategoryOption === 6) {
                if (this.state.bollingerBandsRadioGroup === 'above') {
                    this.setState({
                        filteredChip: [`Coin price crossed above lower band`]
                    })
                } else {
                    this.setState({
                        filteredChip: [`Coin price crossed below lower band`]
                    })
                }
            } else if (this.state.bollingerBandsSubCategoryOption === 7) {
                this.setState({
                    filteredChip: [`Bollinger band squeeze`]
                });
            }
        } else if (flag === 'atr') {
            if (this.state.atrSubCategoryOption === 1) {
                this.setState({
                    filteredChip: [`ATR trending up`]
                });
            } else if (this.state.atrSubCategoryOption === 2) {
                this.setState({
                    filteredChip: [`ATR trending down`]
                });
            }
        }
    }

    volumeFilter = (flag:any) => {
        if (flag === 'avg') {
            this.setState({
                filteredChip: [`Average Volume`]
            })
        } else if (flag === 'volUp') {
            this.setState({
                filteredChip: [`Volume Trending Up`]
            })
        } else if (flag === 'volDown') {
            this.setState({
                filteredChip: [`Volume Trending Down`]
            })
        }
    }

    handleFilterDelete = (chipToDelete: any) => () => {
        this.setState((preState: any) => ({
            filteredChip: preState.filteredChip.filter((chip: any) => chip !== chipToDelete),
            rankResponseData: [],
            rankResponseEmpty: true,
            priceActionGainLossResponseData: [],
            priceActionHighLowResponseData: [],
            priceActionBrealoutDownResponseData: [],
            priceActionSupportResistanceResponseData: [],
            priceActionStandardPatternsResponseData: [],
            priceActionCandelSticksResponseData: [],
            priceActionReversalContinualtionResponseData: [],
            indicatorRSIResponseData: [],
            indicatorMFIResponseData: [],
            indicatorStochasticResponseData: [],
            indicatorCCIResponseData: [],
            indicatorMovingAverageResponseData: [],
            indicatorMACDResponseData: [],
            indicatorADXResponseData: [],
            indicatorOBVResponseData: [],
            indicatorCMFResponseData: [],
            indicatorBBResponseData: [],
            indicatorATRResponseData: [],
            volumeUpResponseData: [],
            navigateCategory: 'Screener',
            activeItem: '',
            selectedIndicatorsForFilter: []
        }))
        this.resetFundamentalData();
        this.resetVolumeData();
        this.resetPriceActionData(); 
        this.resetIndicatorDataBeforeAPICall();
        this.getRankDetails(10, null, null, null);
    }

    getRankDetails = (number: any, categoryId: any, from: any, to: any) => {
        this.updateState();
        this.clearFundamentalsForm('rankDetails');
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getRankApiCallId = requestMessage.messageId;
        let params: { [key: string]: string }[] = [];
        !isEmpty(categoryId) && params.push({crypto_category_id: categoryId });
        !isEmpty(number) && params.push({ limit: number });
        !isEmpty(from) && params.push({ market_cap_min: from });
        !isEmpty(to) && params.push({ market_cap_max: to });
        const queryParams = params
        .map((param: any) => Object.entries(param).map(([key, val]) => `${encodeURIComponent(key)}=${val}`).join('&'))
        .join('&');
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_dashboard/get_ranks?${queryParams}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getAllCategoryDetails = () => {
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getAllCategoriesApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_dashboard/categories_list`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getCategoryUsingSymbol = (symbol: any) => {
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getCategoriesUsingSymbolApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_dashboard/get_category_filter?symbol=${symbol}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getSocialEngagementDetails = () => {
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getSocialEngagementDetailsApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_dashboard/social_engagement_list`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getSelectedSocialEngagementDetails = (symbol: any) => {
        this.setState({
            selectedSocialEngagementSymbol: symbol
        });
        this.clearFundamentalsForm('socialEngagement');
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getSelectedSocialEngagementApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_dashboard/get_social_engagement_filter?symbol=${symbol}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleSelectedFilterValueChange = (flag: any, data: any) => {
        if (flag == 'indicators') {
            var obj = this.state.selectedIndicatorsForFilter.find((item: any) => item == data);
            if (obj === undefined) {
                this.setState((preState) => ({
                    selectedIndicatorsForFilter: [data]
                }))
            } else {
                var objArr = this.state.selectedIndicatorsForFilter.filter((item: any) => item !== data);
                this.setState((preState) => ({
                    selectedIndicatorsForFilter: []
                }))
            }
            this.resetIndicatorDataBeforeAPICall()
        }
    }

    sliderValueText(value: any) {
        return `$ ${value / 2} Bn`;
    }

    updateState = () => {
        this.setState({
            anchorEl: null,
            handleMoreResult: false,
            loading: true,
            rankResponseData: [],
            rankResponseEmpty: true,
            selectedCategoryResponse: [],
            socialEngagementResponseData: [],
            marketCapitalizationResponseData: [],
            priceActionGainLossResponseData: [],
            priceActionHighLowResponseData: [],
            priceActionBrealoutDownResponseData: [],
            priceActionSupportResistanceResponseData: [],
            priceActionCandelSticksResponseData: [],
            priceActionReversalContinualtionResponseData: [],
            paginationPage: 0,
            paginationRowsPerPage: 5
        })
    }

    openCategoryPopup = (row: any) => {
        this.setState({
            openCategoryPopup: true
        }, () => {
            this.getCategoryUsingSymbol(row.symbol);
        })
    }

    cancelCategoryPopup = () => {
        this.setState({ openCategoryPopup: false, rankCategoryPaginationPage: 0, rankCategoryPaginationRowsPerPage: 5 })
    }

    openVolumeUpAndDownPopup = (data: any) => {
        this.setState({
            volumeUpAndDownPopup: true,
            lastVolumeResponseData: data
        }, () => {
        })
    }

    cancelVolumePopup = () => {
        this.setState({ volumeUpAndDownPopup: false, lastVolumeResponseData: [], lastVolumnPaginationPage: 0, lastVolumnPaginationRowsPerPage: 5 })
    }

    handleLastVolumeChangePage = (event: any, newPage: any) => {
        this.setState({
            lastVolumnPaginationPage: newPage
        });
    };

    handleLastVolumeChangeRowsPerPage = (event: any) => {
        this.setState({
            lastVolumnPaginationPage: 0,
            lastVolumnPaginationRowsPerPage: parseInt(event.target.value, 10)
        });
    };

    gainLoss = (url: string) => {
        this.priceActionFilter('gainloss')
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getGainLossPriceApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_dashboard/gain_loss/${url}&page=${this.state.page}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    highLow = (url: string) => {
        this.priceActionFilter('highlow')
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getHighLowPriceApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_dashboard/high_low/${url}?page=${this.state.page}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    breakoutBreakdown = (url: string) => {
        this.priceActionFilter('breakoutdown')
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getBreakoutBreakdownPriceApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_dashboard/breakout_down_all?time_period=${url}&start=${this.state.priceActionBrealoutDownStart}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    supportResistance = (url: string) => {
        this.priceActionFilter('supportresistance')
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getSupportResistancePriceApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_dashboard/support_resistance/${url}&page=${this.state.page}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    standardPatterns = (url: string) => {
        this.priceActionFilter('standardPatterns')
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getStandardPatternPriceApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_dashboard/${url}&page=${this.state.page}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    candleSticks = (url: string) => {
        this.priceActionFilter('candleSticks')
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getCandleSticksPriceApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_dashboard/${url}?page=${this.state.page}&time_frame=${this.state.priceActionCandleStickTimeOptionSubCategory}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    reversalContinuation = (url: string) => {
        this.priceActionFilter('reversalContinuation')
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getReversalContinuationPriceApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_dashboard/reversal_continuation/${url}?page=${this.state.page}&time_frame=${this.state.priceActionReversalTimeOptionSubCategory}`)
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    indicatorDummyAPI = () => {
        this.setState({ loading: true });
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.indicatorDummyApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `/bx_block_dashboard/indicators/dummy?page=${this.state.page}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    indicatorRSI = (url: any) => {
        this.indicatorFilter('rsi')
        this.setState({ loading: true });
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.indicatorRSIAPICallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `/bx_block_dashboard/indicators/rsi?page=${this.state.indicatorPage}&${url}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    indicatorMFIAPI = (url: any) => {
        this.indicatorFilter('mfi');
        this.setState({ loading: true });
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.indicatorMFIAPICallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `/bx_block_dashboard/indicators/mfi?page=${this.state.indicatorPage}&${url}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    indicatorStochasticAPI = (url: any) => {
        this.indicatorFilter('stochastic');
        this.setState({ loading: true });
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.indicatorStochasticAPICallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `/bx_block_dashboard/indicators/stochastic?page=${this.state.indicatorPage}&${url}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    indicatorCCIAPI = (url: any) => {
        this.indicatorFilter('cci')
        this.setState({ loading: true });
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.indicatorCCIAPICallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_dashboard/indicators/cci?page=${this.state.indicatorPage}&${url}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    indicatorMACDAPI = (url: any) => {
        this.indicatorFilter('macd')
        this.setState({ loading: true });
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.indicatorMACDAPICallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_dashboard/indicators/macd?page=${this.state.indicatorPage}&${url}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    
    indicatorMovingAverageAPI = (url: any) => {
        this.indicatorFilter('movingAverage')
        this.setState({ loading: true });
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.indicatorMovingAverageAPICallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_dashboard/indicators/moving_average?page=${this.state.indicatorPage}&${url}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    indicatorADXAPI = (url: any) => {
        this.indicatorFilter('adx')
        this.setState({ loading: true });
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.indicatorADXCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_dashboard/indicators/adx?page=${this.state.indicatorPage}&${url}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    indicatorOBVAPI = (url: any) => {
        this.indicatorFilter('obv')
        this.setState({ loading: true });
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.indicatorOBVCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_dashboard/indicators/obv?${url}&page=${this.state.indicatorPage}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    indicatorCMFAPI = (url: any) => {
        this.indicatorFilter('cmf');
        this.setState({ loading: true });
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.indicatorCMFCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_dashboard/indicators/cmf?page=${this.state.indicatorPage}&${url}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    indicatorBBAPI = (url: any) => {
        this.indicatorFilter('bb')
        this.setState({ loading: true });
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.indicatorBBCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_dashboard/indicators/bollinger_bands?page=${this.state.indicatorPage}&${url}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    indicatorATRAPI = (url: any) => {
        this.indicatorFilter('atr')
        this.setState({ loading: true });
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.indicatorATRCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_dashboard/indicators/atr?page=${this.state.indicatorPage}&${url}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    volumeUpAPI = (filterType: string, url: any) => {
        this.volumeFilter(filterType)
        this.setState({ loading: true });
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.volumeUpCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_dashboard/volume_up?${url}&page=${this.state.volumePage}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    saveFilters = (data: any) => {
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.saveFiltersCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_filter_items/saved_filters`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify({ saved_filter: data.saved_filter }));
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    saveCustomFilters = (data: any) => {
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.saveCustomFiltersCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_filter_items/multi_saved_filters`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify({ multi_saved_filter: data.multi_saved_filter }));
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getCustomFilterCoins = (id: number) => {
        this.setState({ loading: true });
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getCustomFiltersCoinsCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_filter_items/multi_saved_filters/apply_filters`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify({ custom_params: { filter_id: id} }));
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getSavedFilters = () => {
        this.setState({ loading: true });
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getSavedFilterCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_filter_items/saved_filters`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getCustomSavedFilter = () => {
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getCustomSavedFilterCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_filter_items/multi_saved_filters`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    fundamentalsForm = (values: any) => {
        const { clickedBtn, filterName } = this.state;
        const rank = values.customRankVal ? values.customRankVal : values.rank;
        const cat = this.state.categoriesList.find((category: any) => values.category === category.name);

        if (clickedBtn === "Save") {
            const data = { saved_filter:  { ...this.savedFilterParams.saved_filter, filter_name: filterName, filter_type: 'fundamental', rank: rank, rank_category: cat?.id,
            market_cap_max: values.marketCapitalizationTo , market_cap_min: values.marketCapitalizationFrom } }
            this.saveFilters(data);
            this.setState({ clickedBtn: null });
        }
        this.updateState();
        this.getRankDetails(rank, cat?.id, values.marketCapitalizationFrom, values.marketCapitalizationTo);
    }

    prepareDataForVolume = (values: any) => {
        const { filterName } = this.state;
        const filterValues = this.prepareDataForFilterVolume(values);
        const data = { saved_filter: { ...this.savedFilterParams.saved_filter, filter_type: 'volume', filter_name: filterName, ...filterValues }}
        return data;
    }

    prepareDataForFilterVolume = (values: any) => {
        let data;
        const { filterName } = this.state;
        if (values.volumeOption === 1) {
            data = { volume_filter_type: 'volume', 
            volume_option: values.volumeOption.toString(), volume_period: values.volumnPeriod, 
            volume_order: values.volumeValue, volume_from: values.avgVolumnSlider[0] * 1000000000,
            volume_to: values.avgVolumnSlider[1] * 1000000000 }
        } else if (values.volumeOption === 2 || values.volumeOption === 3) {
            data = { volume_filter_type: 'volume', filter_name: filterName,
            volume_option: values.volumeOption.toString(), volume_period: values.volumnPeriod, volume_length: 
            parseInt(values.volumnInputLength) }
        }
        return data;
    }

    volumeForm = (values: any) => {
        const  { clickedBtn } = this.state;
        this.setState({
            isLoading: true
        })
        if (values.volumeOption == 1) {
            this.volumeUpAPI("avg", `value=volume_average&time_frame=${values.volumnPeriod}&from=${values.avgVolumnSlider[0] * 1000000000}&to=${values.avgVolumnSlider[1] * 1000000000}&order=${values.volumeValue}`);
        } else if (values.volumeOption == 2) {
            this.volumeUpAPI("volUp", `value=up&time_frame=${values.volumnPeriod}&length=${values.volumnInputLength}`);
        } else if (values.volumeOption == 3) {
            this.volumeUpAPI("volDown", `value=down&time_frame=${values.volumnPeriod}&length=${values.volumnInputLength}`);
        }
        if (clickedBtn === "Save") {
            const data = this.prepareDataForVolume(values);
            this.saveFilters(data);
            this.setState({ clickedBtn: null });
        }
    }

    prepareDataForPriceActions = (values: any) => {
        const { filterName } = this.state;
        const filterValues = this.prepareDataForFilterPriceAction(values);
        const data = { saved_filter: { ...this.savedFilterParams.saved_filter, filter_type: 'price_action', filter_name: filterName, ...filterValues }}
        return data;
    }

    prepareDataForFilterPriceAction = (values: any) => {
        let data;
        const { filterName } = this.state;
        switch (parseInt(values.priceOptionCategory)) {
            case 1:
                data = { filter_name: filterName, filter_type: 'price_action', 
                price_action_main_option: values.priceOptionCategory,
                price_action_option: values.priceOptionSubCategoryOne,
                price_action_suboption: "",
                price_action_timeframe: values.priceActionTimeFrame,
                gainloss_percentage: ['Price Up %', 'Price Down %'].includes(values.priceOptionSubCategoryOne) 
                ? values.priceActionPercentage : values.priceActionLength }
                break;
            case 2:
                data = { filter_name: filterName, filter_type: 'price_action', 
                    price_action_main_option: values.priceOptionCategory,
                    price_action_option: values.priceActionHighLowSubCategory,
                 }
                break;
            case 3:
                data = { filter_name: filterName, filter_type: 'price_action', 
                    price_action_main_option: values.priceOptionCategory,
                    price_action_option: values.priceActionBreakSubCategory,
                 }
                break;
            case 4:
                data = { filter_name: filterName, filter_type: 'price_action', 
                    price_action_main_option: values.priceOptionCategory,
                    price_action_option: values.priceActionSupportSubCategory,
                    price_action_suboption: values.priceActionNearBreakdownSubCategory,
                    price_action_timeframe: values.priceActionTimeFrame
                 }
                break;
            case 5:
                data = { filter_name: filterName, filter_type: 'price_action', 
                    price_action_main_option: values.priceOptionCategory,
                    price_action_option: values.priceActionStandardChannelSubCategory,
                    price_action_suboption: values.priceActionStandardSubCategory,
                    price_action_timeframe: values.priceActionStandardTimeOptionSubCategory
                 }
                break;
            case 6:
                data = { filter_name: filterName, filter_type: 'price_action', 
                    price_action_main_option: values.priceOptionCategory,
                    price_action_option: values.priceActionReversalSubCategory,
                    price_action_suboption: this.setSubOption(values.priceActionReversalSubCategory, values),
                    price_action_timeframe: values.priceActionReversalTimeOptionSubCategory
                 }
                break;
            case 7:
                data ={ filter_name: filterName, filter_type: 'price_action', 
                    price_action_main_option: values.priceOptionCategory,
                    price_action_option: values.priceActionCandlesSticksSubCategory,
                    price_action_suboption: this.setSubOption(values.priceActionCandlesSticksSubCategory, values),
                    price_action_timeframe: values.priceActionCandleStickTimeOptionSubCategory
                }
                break;
        }
        return data;
    }

    prepareDataForCustomFilterPriceAction = (values: any) => {
        let data;
        const { filterName } = this.state;
        switch (parseInt(values.priceOptionCategory)) {
            case 1:
                data = { filter_name: filterName, filter_type: 'price_action', 
                price_action_main_option: priceActionMainOptions[parseInt(values.priceOptionCategory)],
                price_action_option: priceActionCustomOption(values.priceOptionSubCategoryOne),
                price_action_suboption: priceActionCustomSubOption(values.priceOptionSubCategoryOne),
                price_action_timeframe: values.priceActionTimeFrame,
                gainloss_percentage: ['Price Up %', 'Price Down %'].includes(values.priceOptionSubCategoryOne) 
                ? values.priceActionPercentage : values.priceActionLength }
                break;
            case 2:
                data = { filter_name: filterName, filter_type: 'price_action', 
                    price_action_main_option: priceActionMainOptions[parseInt(values.priceOptionCategory)],
                    price_action_option: priceActionCustomOption(values.priceActionHighLowSubCategory),
                 }
                break;
            case 3:
                data = { filter_name: filterName, filter_type: 'price_action', 
                    price_action_main_option: priceActionMainOptions[parseInt(values.priceOptionCategory)],
                    price_action_option: priceActionCustomOption(values.priceActionBreakSubCategory),
                 }
                break;
            case 4:
                data = { filter_name: filterName, filter_type: 'price_action', 
                    price_action_main_option: priceActionMainOptions[parseInt(values.priceOptionCategory)],
                    price_action_option: priceActionCustomOption(values.priceActionSupportSubCategory),
                    price_action_suboption: values.priceActionNearBreakdownSubCategory,
                    price_action_timeframe: values.priceActionTimeFrame
                 }
                break;
            case 5:
                data = { filter_name: filterName, filter_type: 'price_action', 
                    price_action_main_option: priceActionMainOptions[parseInt(values.priceOptionCategory)],
                    price_action_option: values.priceActionStandardChannelSubCategory,
                    price_action_suboption: values.priceActionStandardSubCategory,
                    price_action_timeframe: values.priceActionStandardTimeOptionSubCategory
                 }
                break;
            case 6:
                data = { filter_name: filterName, filter_type: 'price_action', 
                    price_action_main_option: priceActionMainOptions[parseInt(values.priceOptionCategory)],
                    price_action_suboption: values.priceActionReversalSubCategory,
                    price_action_option: this.setSubOption(values.priceActionReversalSubCategory, values),
                    price_action_timeframe: values.priceActionReversalTimeOptionSubCategory
                 }
                break;
            case 7:
                data ={ filter_name: filterName, filter_type: 'price_action', 
                    price_action_main_option: priceActionMainOptions[parseInt(values.priceOptionCategory)],
                    price_action_suboption: values.priceActionCandlesSticksSubCategory,
                    price_action_option: this.setSubOption(values.priceActionCandlesSticksSubCategory, values),
                    price_action_timeframe: values.priceActionCandleStickTimeOptionSubCategory
                }
                break;
        }
        return data;
    }

    setSubOption = (option: string, values: any) => {
        let result;
        switch (option) {
            case 'bullish reversals':
                result = values.priceActionReversalBottomSubCategory;
                break;
            case 'bearish reversals':
                result = values.priceActionReversalTopSubCategory;
                break;
            case 'continuation patterns':
                result = values.priceActionContinuationSubCategory;
                break;
            case 'Bullish Candle Scans':
                result = values.priceActionCandlesBullishSubCategory;
                break;
            case 'Bearish Candle Scans':
                result = values.priceActionCandlesBearishSubCategory;
                break;
            case 'Other Patterns':
                result = values.priceActionCandlesOthersPatternsSubCategory;
                break;
        }
        return result;
    }

    priceActionForm = (values: any) => {
        const { clickedBtn } = this.state;
        this.setState({
            isLoading: true
        })
        if (values.priceOptionCategory == 1) {
            if (values.priceOptionSubCategoryOne == 'Price Up %') {
                this.gainLoss(`price_up?time_frame=${values.priceActionTimeFrame}&percent_change=${values.priceActionPercentage}&value=up`);
            } else if (values.priceOptionSubCategoryOne == 'Price Down %') {
                this.gainLoss(`price_up?time_frame=${values.priceActionTimeFrame}&percent_change=${values.priceActionPercentage}&value=down`);
            } else if (values.priceOptionSubCategoryOne == 'Price Trending Up') {
                this.gainLoss(`trending_up?time_frame=${values.priceActionTimeFrame}&length=${values.priceActionLength}`);
            } else if (values.priceOptionSubCategoryOne == 'Price Trending Down') {
                this.gainLoss(`trending_down?time_frame=${values.priceActionTimeFrame}&length=${values.priceActionLength}`);
            }
        } else if (values.priceOptionCategory == 2) {
            if (values.priceActionHighLowSubCategory == 'Near All Time High') {
                this.highLow('near_all_time_high');
            } else if (values.priceActionHighLowSubCategory == 'Near All Time Low') {
                this.highLow('near_all_time_low');
            } else if (values.priceActionHighLowSubCategory == 'At All Time High') {
                this.highLow('at_all_time_high');
            } else if (values.priceActionHighLowSubCategory == 'At All Time Low') {
                this.highLow('at_all_time_low');
            } else if (values.priceActionHighLowSubCategory == 'Near 52 Weeks High') {
                this.highLow('near_52_weeks_high');
            } else if (values.priceActionHighLowSubCategory == 'Near 52 Weeks Low') {
                this.highLow('near_52_weeks_low');
            } else if (values.priceActionHighLowSubCategory == 'Near Past Week High') {
                this.highLow('near_past_week_high');
            } else if (values.priceActionHighLowSubCategory == 'Near Past Week Low') {
                this.highLow('near_past_week_low');
            }
        } else if (values.priceOptionCategory == 3) {
            if (values.priceActionBreakSubCategory == 'All Time High Breakout') {
                this.breakoutBreakdown(`all time high breakout`);
            } else if (values.priceActionBreakSubCategory == 'All Time Low Breakdown') {
                this.breakoutBreakdown('all time low breakdown');
            } else if (values.priceActionBreakSubCategory == '52 Weeks High Breakout') {
                this.breakoutBreakdown('52 weeks high breakout');
            } else if (values.priceActionBreakSubCategory == '52 Weeks Low Breakdown') {
                this.breakoutBreakdown('52 weeks low breakdown');
            } else if (values.priceActionBreakSubCategory == 'Past Week High Breakout') {
                this.breakoutBreakdown('past week high breakout');
            } else if (values.priceActionBreakSubCategory == 'Past Week Low Breakdown') {
                this.breakoutBreakdown('past week low breakdown');
            }
        } else if (values.priceOptionCategory == 4) {
            if (values.priceActionSupportSubCategory == 'Standard Support') {
                this.supportResistance(`standard_support?option=${values.priceActionNearBreakdownSubCategory}&time_frame=${values.priceActionTimeFrame}`);
            } else if (values.priceActionSupportSubCategory == 'Standard Resistance') {
                this.supportResistance(`standard_resistance?option=${values.priceActionNearBreakdownSubCategory}&time_frame=${values.priceActionTimeFrame}`);
            } else if (values.priceActionSupportSubCategory == 'Fibonacci Support') {
                this.supportResistance(`fibonacci_support?option=${values.priceActionNearBreakdownSubCategory}&time_frame=${values.priceActionTimeFrame}`);
            } else if (values.priceActionSupportSubCategory == 'Fibonacci Resistance') {
                this.supportResistance(`fibonacci_resistance?option=${values.priceActionNearBreakdownSubCategory}&time_frame=${values.priceActionTimeFrame}`);
            }
        } else if (values.priceOptionCategory == 5) {
            this.standardPatterns(`standard_patterns/flat_channel?option=${this.state.priceActionStandardChannelSubCategory}&time_frame=${this.state.priceActionStandardTimeOptionSubCategory}&channel_type=${this.state.priceActionStandardSubCategory}`)
        } else if (values.priceOptionCategory == 6) {
            if (values.priceActionReversalSubCategory === 'bullish reversals') {
                this.reversalContinuation(`bullish_reversals/${values.priceActionReversalBottomSubCategory}`)
            } else if (values.priceActionReversalSubCategory === 'bearish reversals') {
                this.reversalContinuation(`bearish_reversals/${values.priceActionReversalTopSubCategory}`)
            } else if (values.priceActionReversalSubCategory === 'continuation patterns') {
                this.reversalContinuation(`continuation_patterns/${values.priceActionContinuationSubCategory}`)
            }
        } else if (values.priceOptionCategory == 7) {
            if (values.priceActionCandlesSticksSubCategory == 'Bullish Candle Scans') {
                this.candleSticks(`candlestick/bullish/${this.state.priceActionCandlesBullishSubCategory}`)
            } else if (values.priceActionCandlesSticksSubCategory == 'Bearish Candle Scans') {
                this.candleSticks(`candlestick/bearish/${this.state.priceActionCandlesBearishSubCategory}`)
            } else if (values.priceActionCandlesSticksSubCategory == 'Other Patterns') {
                this.candleSticks(`candlestick/other_patterns/${this.state.priceActionCandlesOthersPatternsSubCategory}`)
            }
        }
        if (clickedBtn === "Save") {
            const data = this.prepareDataForPriceActions(values);
            this.saveFilters(data);
            this.setState({ clickedBtn: null });
        }
    }

    prepareDataForIndicator = (values: any) => {
        const { filterName } = this.state;
        const filterValues = this.prepareDataForFilterIndicator(values);
        const data = { saved_filter: { ...this.savedFilterParams.saved_filter, filter_type: 'indicator', filter_name: filterName, ...filterValues }}
        return data;
    }

    prepareDataForFilterIndicator = (values: any) => {
        let data;
        const { filterName, indicatorsOptionList } = this.state;
        const selectedIndicator = this.state.selectedIndicatorsForFilter[0];
        const selectedOption = indicatorsOptionList.find((option:any) => option.id === selectedIndicator);
        switch (parseInt(selectedIndicator)) {
            case 1:
                data = { filter_name: filterName, filter_type: 'indicator', 
                    indicator_main_option: selectedOption.name, indicator_screen_option: values.rsiSubCategoryOption, indicator_period: values.rsiPeriod, indicator_above_below: values.rsiRadioGroup,
                    indicator_time_frame: values.rsiTimeFrame, indicator_level_centerline: values.rsiSubCategoryOption == 5 ? values.centerLine : values.rsiLevel, indicator_divergence_crossover: values.rsiDivergenceOption  }
                    break;
            case 2:
                data = { filter_name: filterName, filter_type: 'indicator',
                    indicator_main_option: selectedOption.name, indicator_screen_option: values.mfiSubCategoryOption, indicator_period: values.mfiPeriod, indicator_above_below: values.mfiRadioGroup,
                    indicator_time_frame: values.mfiTimeFrame, indicator_level_centerline: values.mfiLevel, indicator_divergence_crossover: values.mfiDivergenceOption }
                    break;
            case 3:
                data = { filter_name: filterName, filter_type: 'indicator',
                indicator_main_option: selectedOption.name, indicator_screen_option: values.stochasticSubCategoryOption, indicator_period: values.stochasticPeriod, 
                    indicator_above_below: values.stochasticRadioGroup, indicator_time_frame: values.stochasticTimeFrame, indicator_level_centerline: values.stochasticLevel, indicator_divergence_crossover: values.stochasticDivergenceOption }
                    break;
            case 4:
                data = { filter_name: filterName, filter_type: 'indicator',
                    indicator_main_option: selectedOption.name, indicator_screen_option: values.cciSubCategoryOption, indicator_period: values.cciPeriod, 
                    indicator_above_below: values.cciRadioGroup,
                    indicator_time_frame: values.cciTimeFrame, indicator_divergence_crossover: values.cciDivergenceOption }
                    break;
            case 5:
                data = { filter_name: filterName, filter_type: 'indicator',
                    indicator_main_option: selectedOption.name, indicator_moving_average_type: values.movingAverageSubCategoryOption, 
                    indicator_screen_option: values.movingAverageSubCategoryOption === 2 ? values.exponentialMovingAverageSubCategoryOption : values.simpleMovingAverageSubCategoryOption, 
                    indicator_period: values.movingAveragePeriod,
                    indicator_time_frame: values.movingAverageTimeFrame, indicator_divergence_crossover: values.movingAverageCrossoverOption }
                    break;
            case 6:
                data = { filter_name: filterName, filter_type: 'indicator',
                    indicator_main_option: selectedOption.name, indicator_screen_option: values.macdSubCategoryOption, indicator_above_below: values.macdRadioGroup,
                    indicator_trending_up_down: values.macdTrendingRadioGroup, indicator_period: values.macdPeriod,
                    indicator_time_frame: values.macdTimeFrame, indicator_divergence_crossover: values.macdDivergenceOption }
                    break;
            case 7:
                data = { filter_name: filterName, filter_type: 'indicator',
                    indicator_main_option: selectedOption.name, indicator_screen_option: values.adxSubCategoryOption, indicator_period: values.adxPeriod,
                    indicator_above_below: values.adxRadioGroup, indicator_time_frame: values.adxTimeFrame, 
                    indicator_level_centerline: values.adxLevel }
                    break;
            case 8:
                data = { filter_name: filterName, filter_type: 'indicator',
                    indicator_main_option: selectedOption.name, indicator_screen_option: values.obvSubCategoryOption, indicator_period: values.obvLevel,
                    indicator_time_frame: values.obvTimeFrame, indicator_divergence_crossover: values.obvDivergenceOption }
                    break;
            case 9:
                data = { filter_name: filterName, filter_type: 'indicator',
                    indicator_main_option: selectedOption.name, indicator_screen_option: values.cmfSubCategoryOption, indicator_period: values.cmfPeriod,
                    indicator_time_frame: values.cmfTimeFrame, indicator_divergence_crossover: values.cmfDivergenceOption }
                    break;
            case 10:
                data = { filter_name: filterName, filter_type: 'indicator',
                    indicator_main_option: selectedOption.name, indicator_screen_option: values.bollingerBandsSubCategoryOption, 
                    indicator_above_below: values.bollingerBandsRadioGroup, indicator_time_frame: values.bollingerBandsTimeFrame }
                    break;
            case 11:
                data = { filter_name: filterName, filter_type: 'indicator',
                    indicator_main_option: selectedOption.name, indicator_screen_option: values.atrSubCategoryOption, indicator_period: values.atrPeriod,
                    indicator_time_frame: values.atrTimeFrame, indicator_trending_period: values.atrTrendingPeriod }
                    break;
        }
        return data;
    }


    prepareDataForCustomFilterIndicator = (values: any) => {
        let data;
        const { filterName, indicatorsOptionList } = this.state;
        const selectedIndicator = this.state.selectedIndicatorsForFilter[0];
        const selectedOption = indicatorsOptionList.find((option:any) => option.id === selectedIndicator);
        switch (parseInt(selectedIndicator)) {
            case 1:
                let rsiScreenOption = indicatorScreenOption(this.state.rsiSubCategoryList, values.rsiSubCategoryOption);
                if ([3, 4, 5].includes(values.rsiSubCategoryOption)) {
                    rsiScreenOption = rsiScreenOption.split(" ");
                    rsiScreenOption.splice(1, 0, values.rsiRadioGroup);
                    rsiScreenOption = rsiScreenOption.join(" ");
                }
                if (values.rsiSubCategoryOption === 6) {
                    rsiScreenOption = rsiScreenOption + ' ' + values.rsiDivergenceOption;
                }
                data = { filter_name: filterName, filter_type: 'indicator', 
                    indicator_main_option: selectedOption.name.toLowerCase(), indicator_screen_option: rsiScreenOption, indicator_period: values.rsiPeriod, indicator_above_below: values.rsiRadioGroup,
                    indicator_time_frame: values.rsiTimeFrame, indicator_level_centerline: values.rsiSubCategoryOption === 5 ? values.centerLine : values.rsiLevel, indicator_divergence_crossover: values.rsiDivergenceOption  }
                    break;
            case 2:
                let mfiScreenOption = indicatorScreenOption(this.state.mfiSubCategoryList, values.mfiSubCategoryOption);
                if ([3, 4, 5].includes(values.mfiSubCategoryOption)) {
                    mfiScreenOption = mfiScreenOption.split(" ");
                    mfiScreenOption.splice(1, 0, values.mfiRadioGroup);
                    mfiScreenOption = mfiScreenOption.join(" ");
                }
                if (values.mfiSubCategoryOption === 6) {
                    mfiScreenOption = mfiScreenOption + ' ' + values.mfiDivergenceOption;
                }
                data = { filter_name: filterName, filter_type: 'indicator',
                    indicator_main_option: selectedOption.name.toLowerCase(), indicator_screen_option: mfiScreenOption, indicator_period: values.mfiPeriod, indicator_above_below: values.mfiRadioGroup,
                    indicator_time_frame: values.mfiTimeFrame, indicator_level_centerline: values.mfiLevel, indicator_divergence_crossover: values.mfiDivergenceOption }
                    break;
            case 3:
                let stockScreenOption = indicatorScreenOption(this.state.stochasticSubCategoryList, values.stochasticSubCategoryOption).replaceAll("%", "").toLowerCase();
                if ([1, 2, 3, 4, 5, 6].includes(values.stochasticSubCategoryOption)) {
                    stockScreenOption = stockScreenOption.split(" ");
                    stockScreenOption.splice(values.stochasticSubCategoryOption === 6 ? 2 : 1, 0, values.stochasticRadioGroup);
                    stockScreenOption = stockScreenOption[0] !== 'k' ? "k " + stockScreenOption.join(" ") : stockScreenOption.join(" ");
                }
                if (values.stochasticSubCategoryOption === 8) {
                    stockScreenOption = stockScreenOption + ' ' + values.stochasticDivergenceOption;
                }
                data = { filter_name: filterName, filter_type: 'indicator',
                indicator_main_option: selectedOption.name.toLowerCase(), 
                    indicator_screen_option: stockScreenOption, indicator_period: values.stochasticPeriod, 
                    indicator_above_below: values.stochasticRadioGroup, indicator_time_frame: values.stochasticTimeFrame, indicator_level_centerline: values.stochasticLevel, indicator_divergence_crossover: values.stochasticDivergenceOption }
                    break;
            case 4:
                let screenOption = indicatorScreenOption(this.state.cciSubCategoryList, values.cciSubCategoryOption).replace("+", "plus ").replace("-", "minus ");
                if ([3, 4, 5].includes(values.cciSubCategoryOption)) {
                    screenOption = screenOption.split(" ");
                    screenOption.splice(2, 0, values.cciRadioGroup);
                    screenOption = screenOption.join(" ");
                }
                if (values.cciSubCategoryOption === 6) {
                    screenOption = screenOption + ' ' + values.cciDivergenceOption;
                }
                data = { filter_name: filterName, filter_type: 'indicator',
                    indicator_main_option: selectedOption.name.toLowerCase(), 
                    indicator_screen_option: screenOption, indicator_period: values.cciPeriod, 
                    indicator_above_below: values.cciRadioGroup,
                    indicator_time_frame: values.cciTimeFrame, indicator_divergence_crossover: values.cciDivergenceOption }
                    break;
            case 5:
                let maScreenOption = values.movingAverageSubCategoryOption === 1 ? 
                indicatorScreenOption(this.state.simpleMovingAverageSubCategoryList, values.simpleMovingAverageSubCategoryOption) : 
                indicatorScreenOption(this.state.exponentialMovingAverageSubCategoryList, values.exponentialMovingAverageSubCategoryOption);
                if (values.movingAverageSubCategoryOption === 1) {
                    if (values.simpleMovingAverageSubCategoryOption === 7)
                        maScreenOption = maScreenOption.replace("moving average", values.movingAverageCrossoverOption);
                } else {
                    if (values.exponentialMovingAverageSubCategoryOption === 7)
                        maScreenOption = maScreenOption.replace("moving average", values.movingAverageCrossoverOption);
                }
                data = { filter_name: filterName, filter_type: 'indicator',
                    indicator_main_option: selectedOption.name.replace(" ", "_").toLowerCase(), indicator_moving_average_type: values.movingAverageSubCategoryOption === 1 ? 'simple' : 'exponential', 
                    indicator_screen_option: maScreenOption, 
                    indicator_period: values.movingAveragePeriod,
                    indicator_time_frame: values.movingAverageTimeFrame, indicator_divergence_crossover: values.movingAverageCrossoverOption }
                    break;
            case 6:
                let macdScreenOption = indicatorScreenOption(this.state.macdSubCategoryList, values.macdSubCategoryOption);
                macdScreenOption = macdOption(values, macdScreenOption);
                data = { filter_name: filterName, filter_type: 'indicator',
                    indicator_main_option: selectedOption.name.toLowerCase(), indicator_screen_option: macdScreenOption, indicator_above_below: values.macdRadioGroup,
                    indicator_trending_up_down: values.macdTrendingRadioGroup, indicator_period: values.macdPeriod,
                    indicator_time_frame: values.macdTimeFrame, indicator_divergence_crossover: values.macdDivergenceOption }
                    break;
            case 7:
                let adxScreenOption = adxOption(values);
                data = { filter_name: filterName, filter_type: 'indicator',
                    indicator_main_option: selectedOption.name.toLowerCase(), indicator_screen_option: adxScreenOption, indicator_period: values.adxPeriod,
                    indicator_above_below: values.adxRadioGroup, indicator_time_frame: values.adxTimeFrame, 
                    indicator_level_centerline: values.adxLevel }
                    break;
            case 8:
                let obvScreenOption = indicatorScreenOption(this.state.obvSubCategoryList, values.obvSubCategoryOption);
                if (values.obvSubCategoryOption === 3)
                    obvScreenOption = obvScreenOption + " " + values.obvDivergenceOption.toLowerCase();

                data = { filter_name: filterName, filter_type: 'indicator',
                    indicator_main_option: selectedOption.name.toLowerCase(), indicator_screen_option: obvScreenOption, indicator_period: values.obvLevel,
                    indicator_time_frame: values.obvTimeFrame, indicator_divergence_crossover: values.obvDivergenceOption }
                    break;
            case 9:
                let cmfScreenOption = indicatorScreenOption(this.state.cmfSubCategoryList, values.cmfSubCategoryOption);
                if (values.cmfSubCategoryOption === 7)
                    cmfScreenOption = cmfScreenOption + " " + values.cmfDivergenceOption;
                else
                    cmfScreenOption = cmfScreenOption.replace("cmf", "chaikin money flow");
                data = { filter_name: filterName, filter_type: 'indicator',
                    indicator_main_option: selectedOption.name.toLowerCase(), indicator_screen_option: cmfScreenOption, indicator_period: values.cmfPeriod,
                    indicator_time_frame: values.cmfTimeFrame, indicator_divergence_crossover: values.cmfDivergenceOption }
                    break;
            case 10:
                let bbScreenOption = indicatorScreenOption(this.state.bollingerBandsSubCategoryList, values.bollingerBandsSubCategoryOption);
                if ([4,5,6].includes(values.bollingerBandsSubCategoryOption)) {
                    bbScreenOption = bbScreenOption.split(" ");
                    bbScreenOption.splice(3, 0, values.bollingerBandsRadioGroup);
                    bbScreenOption = bbScreenOption.join(" ");
                }

                data = { filter_name: filterName, filter_type: 'indicator',
                    indicator_main_option: selectedOption.name.replace(" ", "_").toLowerCase(), indicator_screen_option: bbScreenOption, 
                    indicator_above_below: values.bollingerBandsRadioGroup, indicator_time_frame: values.bollingerBandsTimeFrame }
                    break;
            case 11:
                let atrScreenOption = indicatorScreenOption(this.state.atrSubCategoryList, values.atrSubCategoryOption);
                data = { filter_name: filterName, filter_type: 'indicator',
                    indicator_main_option: selectedOption.name.toLowerCase(), indicator_screen_option: atrScreenOption, indicator_period: values.atrPeriod,
                    indicator_time_frame: values.atrTimeFrame, indicator_trending_period: values.atrTrendingPeriod }
                    break;
        }
        return data;
    }

    indicatorsForm = (values: any) => {
        const { clickedBtn } = this.state;
        this.setState({
            isLoading: true
        })
        // this.indicatorDummyAPI();
        if (values.selectedIndicatorsForFilter > 0 && values.selectedIndicatorsForFilter.length === 1) {
            const selectedIndicator = values.selectedIndicatorsForFilter[0];
            if (selectedIndicator === 1) {
                // RSI Indicator
                if (values.rsiSubCategoryOption === 1) {
                    this.indicatorRSI(`time_frame=${values.rsiTimeFrame}&option=RSI Above Overbought Level&level=${values.rsiLevel}&period=${values.rsiPeriod}`)
                } else if (values.rsiSubCategoryOption === 2) {
                    this.indicatorRSI(`time_frame=${values.rsiTimeFrame}&option=RSI Below Oversold Level&level=${values.rsiLevel}&period=${values.rsiPeriod}`)
                } else if (values.rsiSubCategoryOption === 3) {
                    if (values.rsiRadioGroup.toLowerCase() === 'above') {
                        this.indicatorRSI(`time_frame=${values.rsiTimeFrame}&option=Cross Above Overbought Level&level=${values.rsiLevel}&period=${values.rsiPeriod}`)
                    } else {
                        this.indicatorRSI(`time_frame=${values.rsiTimeFrame}&option=Cross Below Overbought Level&level=${values.rsiLevel}&period=${values.rsiPeriod}`)
                    }
                } else if (values.rsiSubCategoryOption === 4) {
                    if (values.rsiRadioGroup.toLowerCase() === 'above') {
                        this.indicatorRSI(`time_frame=${values.rsiTimeFrame}&option=Cross Above Oversold Level&level=${values.rsiLevel}&period=${values.rsiPeriod}`)
                    } else {
                        this.indicatorRSI(`time_frame=${values.rsiTimeFrame}&option=Cross Below Oversold Level&level=${values.rsiLevel}&period=${values.rsiPeriod}`)
                    }
                } else if (values.rsiSubCategoryOption === 5) {
                    if (values.rsiRadioGroup.toLowerCase() === 'above') {
                        this.indicatorRSI(`time_frame=${values.rsiTimeFrame}&option=Cross Above Center Line&level=${values.rsiLevel}&period=${values.rsiPeriod}`)
                    } else {
                        this.indicatorRSI(`time_frame=${values.rsiTimeFrame}&option=Cross Below Center Line&level=${values.rsiLevel}&period=${values.rsiPeriod}`)
                    }
                } else if (values.rsiSubCategoryOption === 6) {
                    if (values.rsiDivergenceOption === 'bullish') {
                        this.indicatorRSI(`time_frame=${values.rsiTimeFrame}&option=divergence bullish&period=14`)
                    } else {
                        this.indicatorRSI(`time_frame=${values.rsiTimeFrame}&option=divergence bearish&period=14`)
                    }
                }
            } else if (selectedIndicator === 2) {
                // MFI Indicator
                if (values.mfiSubCategoryOption === 1) {
                    this.indicatorMFIAPI(`time_frame=${values.mfiTimeFrame}&period=${values.mfiPeriod}&option=mfi above overbought level&level=${values.mfiLevel}`);
                } else if (values.mfiSubCategoryOption === 2) {
                    this.indicatorMFIAPI(`time_frame=${values.mfiTimeFrame}&period=${values.mfiPeriod}&option=mfi below oversold level&level=${values.mfiLevel}`);
                } else if (values.mfiSubCategoryOption === 3) {
                    if (values.mfiRadioGroup === 'above') {
                        this.indicatorMFIAPI(`time_frame=${values.mfiTimeFrame}&period=${values.mfiPeriod}&option=cross above overbought level&level=${values.mfiLevel}`);
                    } else {
                        this.indicatorMFIAPI(`time_frame=${values.mfiTimeFrame}&period=${values.mfiPeriod}&option=cross below overbought level&level=${values.mfiLevel}`);
                    }
                } else if (values.mfiSubCategoryOption === 4) {
                    if (values.mfiRadioGroup === 'above') {
                        this.indicatorMFIAPI(`time_frame=${values.mfiTimeFrame}&period=${values.mfiPeriod}&option=cross above oversold level&level=${values.mfiLevel}`);
                    } else {
                        this.indicatorMFIAPI(`time_frame=${values.mfiTimeFrame}&period=${values.mfiPeriod}&option=cross below oversold level&level=${values.mfiLevel}`);
                    }
                } else if (values.mfiSubCategoryOption === 5) {
                    if (values.mfiRadioGroup === 'above') {
                        this.indicatorMFIAPI(`time_frame=${values.mfiTimeFrame}&period=${values.mfiPeriod}&option=cross above center line&level=${values.mfiLevel}`);
                    } else {
                        this.indicatorMFIAPI(`time_frame=${values.mfiTimeFrame}&period=${values.mfiPeriod}&option=cross below center line&level=${values.mfiLevel}`);
                    }
                } else if (values.mfiSubCategoryOption === 6) {
                    if (values.mfiDivergenceOption === 'bullish') {
                        this.indicatorMFIAPI(`time_frame=${values.mfiTimeFrame}&period=${'14'}&option=divergence bullish&level=${''}`);
                    } else {
                        this.indicatorMFIAPI(`time_frame=${values.mfiTimeFrame}&period=${'14'}&option=divergence bearish&level=${''}`);
                    }
                } 
            } else if (selectedIndicator === 3) {
                // Stochastic Indicator
                if (values.stochasticSubCategoryOption === 1) {
                    if (values.stochasticRadioGroup === 'above') {
                        this.indicatorStochasticAPI(`time_frame=${values.stochasticTimeFrame}&option=k above overbought level&level=${values.stochasticLevel}&period=${values.stochasticPeriod}`);
                    } else {
                        this.indicatorStochasticAPI(`time_frame=${values.stochasticTimeFrame}&option=k below overbought level&level=${values.stochasticLevel}&period=${values.stochasticPeriod}`);
                    }
                } else if (values.stochasticSubCategoryOption === 2) {
                    if (values.stochasticRadioGroup === 'above') {
                        this.indicatorStochasticAPI(`time_frame=${values.stochasticTimeFrame}&option=k above oversold level&level=${values.stochasticLevel}&period=${values.stochasticPeriod}`);
                    } else {
                        this.indicatorStochasticAPI(`time_frame=${values.stochasticTimeFrame}&option=k below oversold level&level=${values.stochasticLevel}&period=${values.stochasticPeriod}`);
                    }
                } else if (values.stochasticSubCategoryOption === 3) {
                    if (values.stochasticRadioGroup === 'above') {
                        this.indicatorStochasticAPI(`time_frame=${values.stochasticTimeFrame}&option=k cross above overbought level&level=${values.stochasticLevel}&period=${values.stochasticPeriod}`);
                    } else {
                        this.indicatorStochasticAPI(`time_frame=${values.stochasticTimeFrame}&option=k cross below overbought level&level=${values.stochasticLevel}&period=${values.stochasticPeriod}`);
                    }
                } else if (values.stochasticSubCategoryOption === 4) {
                    if (values.stochasticRadioGroup === 'above') {
                        this.indicatorStochasticAPI(`time_frame=${values.stochasticTimeFrame}&option=k cross above oversold level&level=${values.stochasticLevel}&period=${values.stochasticPeriod}`);
                    } else {
                        this.indicatorStochasticAPI(`time_frame=${values.stochasticTimeFrame}&option=k cross below oversold level&level=${values.stochasticLevel}&period=${values.stochasticPeriod}`);
                    }
                } else if (values.stochasticSubCategoryOption === 5) {
                    if (values.stochasticRadioGroup === 'above') {
                        this.indicatorStochasticAPI(`time_frame=${values.stochasticTimeFrame}&option=k cross above centerline&level=${values.stochasticLevel}&period=${values.stochasticPeriod}`);
                    } else {
                        this.indicatorStochasticAPI(`time_frame=${values.stochasticTimeFrame}&option=k cross below centerline&level=${values.stochasticLevel}&period=${values.stochasticPeriod}`);
                    }
                } else if (values.stochasticSubCategoryOption === 6) {
                    if (values.stochasticRadioGroup === 'above') {
                        this.indicatorStochasticAPI(`time_frame=${values.stochasticTimeFrame}&option=k cross above d`);
                    } else {
                        this.indicatorStochasticAPI(`time_frame=${values.stochasticTimeFrame}&option=k cross below d`);
                    }
                } else if (values.stochasticSubCategoryOption === 8) {
                    if (values.stochasticDivergenceOption === 'bullish') {
                        this.indicatorStochasticAPI(`time_frame=${values.stochasticTimeFrame}&option=divergence bullish&period=14`);
                    } else {
                        this.indicatorStochasticAPI(`time_frame=${values.stochasticTimeFrame}&option=divergence bearish&period=14`);
                    }
                }
            } else if (selectedIndicator === 4) {
                if (values.cciSubCategoryOption === 1) {
                    this.indicatorCCIAPI(`time_frame=${values.cciTimeFrame}&option=cci above plus 100&period=${values.cciPeriod}`)
                } else if (values.cciSubCategoryOption === 2) {
                    this.indicatorCCIAPI(`time_frame=${values.cciTimeFrame}&option=cci below minus 100&period=${values.cciPeriod}`)
                } else if (values.cciSubCategoryOption === 3) {
                    if (values.cciRadioGroup === 'above') {
                        this.indicatorCCIAPI(`time_frame=${values.cciTimeFrame}&option=cci cross above plus 100&period=${values.cciPeriod}`)
                    } else {
                        this.indicatorCCIAPI(`time_frame=${values.cciTimeFrame}&option=cci cross below plus 100&period=${values.cciPeriod}`)
                    }
                } else if (values.cciSubCategoryOption === 4) {
                    if (values.cciRadioGroup === 'above') {
                        this.indicatorCCIAPI(`time_frame=${values.cciTimeFrame}&option=cci cross above minus 100&period=${values.cciPeriod}`)
                    } else {
                        this.indicatorCCIAPI(`time_frame=${values.cciTimeFrame}&option=cci cross below minus 100&period=${values.cciPeriod}`)
                    }
                } else if (values.cciSubCategoryOption === 5) {
                    if (values.cciRadioGroup === 'above') {
                        this.indicatorCCIAPI(`time_frame=${values.cciTimeFrame}&option=cci cross above 0&period=${values.cciPeriod}`)
                    } else {
                        this.indicatorCCIAPI(`time_frame=${values.cciTimeFrame}&option=cci cross below 0&period=${values.cciPeriod}`)
                    }
                } else if (values.cciSubCategoryOption === 6) {
                    if (values.cciDivergenceOption === 'bullish') {
                        this.indicatorCCIAPI(`time_frame=${values.cciTimeFrame}&option=divergence bullish&period=20`)
                    } else {
                        this.indicatorCCIAPI(`time_frame=${values.cciTimeFrame}&option=divergence bearish&period=20`)
                    }
                }
            } else if (selectedIndicator === 5) {
                if (values.movingAverageSubCategoryOption === 1) {
                    if (values.simpleMovingAverageSubCategoryOption === 1) {
                        this.indicatorMovingAverageAPI(`time_frame=${values.movingAverageTimeFrame}&option=price above simple moving average&period=${values.movingAveragePeriod}&moving_average=${'simple'}`);
                    } else if (values.simpleMovingAverageSubCategoryOption === 2) {
                        this.indicatorMovingAverageAPI(`time_frame=${values.movingAverageTimeFrame}&option=price below simple moving average&period=${values.movingAveragePeriod}&moving_average=${'simple'}`);
                    } else if (values.simpleMovingAverageSubCategoryOption === 3) {
                        this.indicatorMovingAverageAPI(`time_frame=${values.movingAverageTimeFrame}&option=price crossed above simple moving average&period=${values.movingAveragePeriod}&moving_average=${'simple'}`);
                    } else if (values.simpleMovingAverageSubCategoryOption === 4) {
                        this.indicatorMovingAverageAPI(`time_frame=${values.movingAverageTimeFrame}&option=price crossed below simple moving average&period=${values.movingAveragePeriod}&moving_average=${'simple'}`);
                    } else if (values.simpleMovingAverageSubCategoryOption === 5) {
                        this.indicatorMovingAverageAPI(`time_frame=${values.movingAverageTimeFrame}&option=price bounced up from simple moving average&period=${values.movingAveragePeriod}&moving_average=${'simple'}`);
                    } else if (values.simpleMovingAverageSubCategoryOption === 6) {
                        this.indicatorMovingAverageAPI(`time_frame=${values.movingAverageTimeFrame}&option=price bounced down from simple moving average&period=${values.movingAveragePeriod}&moving_average=${'simple'}`);
                    } else if (values.simpleMovingAverageSubCategoryOption === 7) {
                        if (values.movingAverageCrossoverOption === 'bullish') {
                            this.indicatorMovingAverageAPI(`time_frame=${''}&option=bullish crossovers&period=${''}&moving_average=${'simple'}`);
                        } else {
                            this.indicatorMovingAverageAPI(`time_frame=${''}&option=bearish crossovers&period=${''}&moving_average=${'simple'}`);
                        }
                    }
                } else if (values.movingAverageSubCategoryOption === 2) {
                    if (values.exponentialMovingAverageSubCategoryOption === 1) {
                        this.indicatorMovingAverageAPI(`time_frame=${values.movingAverageTimeFrame}&option=price above exponential moving average&period=${values.movingAveragePeriod}&moving_average=${'exponential'}`);
                    } else if (values.exponentialMovingAverageSubCategoryOption === 2) {
                        this.indicatorMovingAverageAPI(`time_frame=${values.movingAverageTimeFrame}&option=price below exponential moving average&period=${values.movingAveragePeriod}&moving_average=${'exponential'}`);
                    } else if (values.exponentialMovingAverageSubCategoryOption === 3) {
                        this.indicatorMovingAverageAPI(`time_frame=${values.movingAverageTimeFrame}&option=price crossed above exponential moving average&period=${values.movingAveragePeriod}&moving_average=${'exponential'}`);
                    } else if (values.exponentialMovingAverageSubCategoryOption === 4) {
                        this.indicatorMovingAverageAPI(`time_frame=${values.movingAverageTimeFrame}&option=price crossed below exponential moving average&period=${values.movingAveragePeriod}&moving_average=${'exponential'}`);
                    } else if (values.exponentialMovingAverageSubCategoryOption === 5) {
                        this.indicatorMovingAverageAPI(`time_frame=${values.movingAverageTimeFrame}&option=price bounced up from exponential moving average&period=${values.movingAveragePeriod}&moving_average=${'exponential'}`);
                    } else if (values.exponentialMovingAverageSubCategoryOption === 6) {
                        this.indicatorMovingAverageAPI(`time_frame=${values.movingAverageTimeFrame}&option=price bounced down from exponential moving average&period=${values.movingAveragePeriod}&moving_average=${'exponential'}`);
                    } else if (values.exponentialMovingAverageSubCategoryOption === 7) {
                        if (values.movingAverageCrossoverOption === 'bullish') {
                            this.indicatorMovingAverageAPI(`time_frame=${''}&option=bullish crossovers&period=${''}&moving_average=${'exponential'}`);
                        } else {
                            this.indicatorMovingAverageAPI(`time_frame=${''}&option=bearish crossovers&period=${''}&moving_average=${'exponential'}`);
                        }
                    }
                }
            } else if (selectedIndicator === 6) {
                if (values.macdSubCategoryOption === 1) {
                    if (values.macdRadioGroup === 'above') {
                        this.indicatorMACDAPI(`time_frame=${values.macdTimeFrame}&option=macd is above centerline`)
                    } else {
                        this.indicatorMACDAPI(`time_frame=${values.macdTimeFrame}&option=macd is below centerline`)
                    }
                } else if (values.macdSubCategoryOption === 2) {
                    if (values.macdRadioGroup === 'above') {
                        this.indicatorMACDAPI(`time_frame=${values.macdTimeFrame}&option=macd crossed above centerline`)
                    } else {
                        this.indicatorMACDAPI(`time_frame=${values.macdTimeFrame}&option=macd crossed below centerline`)
                    }
                } else if (values.macdSubCategoryOption === 3) {
                    if (values.macdRadioGroup === 'above') {
                        this.indicatorMACDAPI(`time_frame=${values.macdTimeFrame}&option=macd line is above signal line`)
                    } else {
                        this.indicatorMACDAPI(`time_frame=${values.macdTimeFrame}&option=macd line is below signal line`)
                    }
                } else if (values.macdSubCategoryOption === 4) {
                    if (values.macdRadioGroup === 'above') {
                        this.indicatorMACDAPI(`time_frame=${values.macdTimeFrame}&option=macd line crossed above signal line`)
                    } else {
                        this.indicatorMACDAPI(`time_frame=${values.macdTimeFrame}&option=macd line crossed below signal line`)
                    }
                } else if (values.macdSubCategoryOption === 5) {
                    if (values.macdTrendingRadioGroup === 'up') {
                        this.indicatorMACDAPI(`time_frame=${values.macdTimeFrame}&option=macd trending up&period=${values.macdPeriod}`)
                    } else {
                        this.indicatorMACDAPI(`time_frame=${values.macdTimeFrame}&option=macd trending down&period=${values.macdPeriod}`)
                    }
                } else if (values.macdSubCategoryOption === 6) {
                    if (values.macdDivergenceOption === 'bullish') {
                        this.indicatorMACDAPI(`time_frame=${values.macdTimeFrame}&option=divergence bullish`)
                    } else {
                        this.indicatorMACDAPI(`time_frame=${values.macdTimeFrame}&option=divergence bearish`)
                    }
                }
            } else if (selectedIndicator === 7) {
                if (values.adxSubCategoryOption === 1) {
                    if (values.adxRadioGroup === 'above') {
                        this.indicatorADXAPI(`option=positive_di above selected level&time_frame=${values.adxTimeFrame}&level=${values.adxLevel}&period=${values.adxPeriod}`)
                    } else {
                        this.indicatorADXAPI(`option=positive_di below selected level&time_frame=${values.adxTimeFrame}&level=${values.adxLevel}&period=${values.adxPeriod}`)
                    }
                } else if (values.adxSubCategoryOption === 2) {
                    if (values.adxRadioGroup === 'above') {
                        this.indicatorADXAPI(`option=positive_di cross above selected level&time_frame=${values.adxTimeFrame}&level=${values.adxLevel}&period=${values.adxPeriod}`)
                    } else {
                        this.indicatorADXAPI(`option=positive_di cross below selected level&time_frame=${values.adxTimeFrame}&level=${values.adxLevel}&period=${values.adxPeriod}`)
                    }
                } else if (values.adxSubCategoryOption === 3) {
                    if (values.adxRadioGroup === 'above') {
                        this.indicatorADXAPI(`option=positive_di cross above negative_di&time_frame=${values.adxTimeFrame}&period=${values.adxPeriod}`)
                    } else {
                        this.indicatorADXAPI(`option=positive_di cross below negative_di&time_frame=${values.adxTimeFrame}&period=${values.adxPeriod}`)
                    }
                } else if (values.adxSubCategoryOption === 4) {
                    if (values.adxRadioGroup === 'above') {
                        this.indicatorADXAPI(`option=positive_di above negative_di&time_frame=${values.adxTimeFrame}&period=${values.adxPeriod}`)
                    } else {
                        this.indicatorADXAPI(`option=positive_di below negative_di&time_frame=${values.adxTimeFrame}&period=${values.adxPeriod}`)
                    }
                } else if (values.adxSubCategoryOption === 5) {
                    if (values.adxRadioGroup === 'above') {
                        this.indicatorADXAPI(`option=negative_di above selected level&time_frame=${values.adxTimeFrame}&level=${values.adxLevel}&period=${values.adxPeriod}`)
                    } else {
                        this.indicatorADXAPI(`option=negative_di below selected level&time_frame=${values.adxTimeFrame}&level=${values.adxLevel}&period=${values.adxPeriod}`)
                    }
                } else if (values.adxSubCategoryOption === 6) {
                    if (values.adxRadioGroup === 'above') {
                        this.indicatorADXAPI(`option=negative_di cross above selected level&time_frame=${values.adxTimeFrame}&level=${values.adxLevel}&period=${values.adxPeriod}`)
                    } else {
                        this.indicatorADXAPI(`option=negative_di cross below selected level&time_frame=${values.adxTimeFrame}&level=${values.adxLevel}&period=${values.adxPeriod}`)
                    }
                } else if (values.adxSubCategoryOption === 7) {
                    if (values.adxRadioGroup === 'above') {
                        this.indicatorADXAPI(`option=negative_di cross above positive_di&time_frame=${values.adxTimeFrame}&period=${values.adxPeriod}`)
                    } else {
                        this.indicatorADXAPI(`option=negative_di cross below positive_di&time_frame=${values.adxTimeFrame}&period=${values.adxPeriod}`)
                    }
                } else if (values.adxSubCategoryOption === 8) {
                    if (values.adxRadioGroup === 'above') {
                        this.indicatorADXAPI(`option=negative_di above positive_di&time_frame=${values.adxTimeFrame}&period=${values.adxPeriod}`)
                    } else {
                        this.indicatorADXAPI(`option=negative_di below positive_di&time_frame=${values.adxTimeFrame}&period=${values.adxPeriod}`)
                    }
                } else if (values.adxSubCategoryOption === 9) {
                    if (values.adxRadioGroup === 'above') {
                        this.indicatorADXAPI(`option=adx above selected level&time_frame=${values.adxTimeFrame}&level=${values.adxLevel}&period=${values.adxPeriod}`)
                    } else {
                        this.indicatorADXAPI(`option=adx below selected level&time_frame=${values.adxTimeFrame}&level=${values.adxLevel}&period=${values.adxPeriod}`)
                    }
                } else if (values.adxSubCategoryOption === 10) {
                    if (values.adxRadioGroup === 'above') {
                        this.indicatorADXAPI(`option=adx cross above selected level&time_frame=${values.adxTimeFrame}&level=${values.adxLevel}&period=${values.adxPeriod}`)
                    } else {
                        this.indicatorADXAPI(`option=adx cross below selected level&time_frame=${values.adxTimeFrame}&level=${values.adxLevel}&period=${values.adxPeriod}`)
                    }
                } else if (values.adxSubCategoryOption === 11) {
                    this.indicatorADXAPI(`option=adx trending up&time_frame=${values.adxTimeFrame}&period=${values.adxPeriod}`)
                } else if (values.adxSubCategoryOption === 12) {
                    this.indicatorADXAPI(`option=adx trending down&time_frame=${values.adxTimeFrame}&period=${values.adxPeriod}`)
                }
            } else if (selectedIndicator === 8) {
                if (values.obvSubCategoryOption === 1) {
                    this.indicatorOBVAPI(`option=on balance volume trending up&time_frame=${values.obvTimeFrame}&trending_period=${values.obvLevel}`)
                } else if (values.obvSubCategoryOption === 2) {
                    this.indicatorOBVAPI(`option=on balance volume trending down&time_frame=${values.obvTimeFrame}&trending_period=${values.obvLevel}`)
                } else if (values.obvSubCategoryOption === 3) {
                    if (values.obvDivergenceOption === 'Bullish') {
                        this.indicatorOBVAPI(`option=divergence bullish&time_frame=${values.obvTimeFrame}&period=20`)
                    } else {
                        this.indicatorOBVAPI(`option=divergence bearish&time_frame=${values.obvTimeFrame}&period=20`)
                    } 
                }
            } else if (selectedIndicator === 9) {
                if (values.cmfSubCategoryOption === 1) {
                    this.indicatorCMFAPI(`time_frame=${''}&option=chaikin money flow above 0`);
                } else if (values.cmfSubCategoryOption === 2) {
                    this.indicatorCMFAPI(`time_frame=${''}&option=chaikin money flow below 0`);
                } else if (values.cmfSubCategoryOption === 3) {
                    this.indicatorCMFAPI(`time_frame=${''}&option=chaikin money flow crossed above 0`);
                } else if (values.cmfSubCategoryOption === 4) {
                    this.indicatorCMFAPI(`time_frame=${''}&option=chaikin money flow crossed below 0`);
                } else if (values.cmfSubCategoryOption === 5) {
                    this.indicatorCMFAPI(`time_frame=${values.cmfTimeFrame}&option=chaikin money flow trending up&period=${values.cmfPeriod}`);
                } else if (values.cmfSubCategoryOption === 6) {
                    this.indicatorCMFAPI(`time_frame=${values.cmfTimeFrame}&option=chaikin money flow trending down&period=${values.cmfPeriod}`);
                } else if (values.cmfSubCategoryOption === 7) {
                    if (values.cmfDivergenceOption === 'bullish') {
                        this.indicatorCMFAPI(`time_frame=${values.cmfTimeFrame}&option=divergence bullish`);
                    } else {
                        this.indicatorCMFAPI(`time_frame=${values.cmfTimeFrame}&option=divergence bearish`);
                    }
                }
            } else if (selectedIndicator === 10) {
                if (values.bollingerBandsSubCategoryOption === 1) {
                    this.indicatorBBAPI(`time_frame=${values.bollingerBandsTimeFrame}&option=coin price near upper band`)
                } else if (values.bollingerBandsSubCategoryOption === 2) {
                    this.indicatorBBAPI(`time_frame=${values.bollingerBandsTimeFrame}&option=coin price near lower band`)
                } else if (values.bollingerBandsSubCategoryOption === 3) {
                    this.indicatorBBAPI(`time_frame=${values.bollingerBandsTimeFrame}&option=coin price near middle band`)
                } else if (values.bollingerBandsSubCategoryOption === 4) {
                    if(values.bollingerBandsRadioGroup === 'above'){
                        this.indicatorBBAPI(`time_frame=${values.bollingerBandsTimeFrame}&option=coin price crossed above middle band`)
                    } else {
                        this.indicatorBBAPI(`time_frame=${values.bollingerBandsTimeFrame}&option=coin price crossed below middle band`)
                    }
                } else if (values.bollingerBandsSubCategoryOption === 5) {
                    if(values.bollingerBandsRadioGroup === 'above'){
                        this.indicatorBBAPI(`time_frame=${values.bollingerBandsTimeFrame}&option=coin price crossed above upper band`)
                    } else {
                        this.indicatorBBAPI(`time_frame=${values.bollingerBandsTimeFrame}&option=coin price crossed below upper band`)
                    }
                } else if (values.bollingerBandsSubCategoryOption === 6) {
                    if(values.bollingerBandsRadioGroup === 'above'){
                        this.indicatorBBAPI(`time_frame=${values.bollingerBandsTimeFrame}&option=coin price crossed above lower band`)
                    } else {
                        this.indicatorBBAPI(`time_frame=${values.bollingerBandsTimeFrame}&option=coin price crossed below lower band`)
                    }
                } else if (values.bollingerBandsSubCategoryOption === 7) {
                    this.indicatorBBAPI(`time_frame=${values.bollingerBandsTimeFrame}&option=bollinger band squeeze`)
                }
            } else if (selectedIndicator === 11) {
                if (values.atrSubCategoryOption === 1) {
                    this.indicatorATRAPI(`time_frame=${values.atrTimeFrame}&option=atr trending up&trending_period=${values.atrTrendingPeriod}&period=${values.atrPeriod}`)
                } else if (values.atrSubCategoryOption === 2) {
                    this.indicatorATRAPI(`time_frame=${values.atrTimeFrame}&option=atr trending down&trending_period=${values.atrTrendingPeriod}&period=${values.atrPeriod}`)
                }
            }
        } else {
            // multi filter logic here 
        }
        if (clickedBtn === "Save") {
            const data = this.prepareDataForIndicator(values);
            this.saveFilters(data);
            this.setState({ clickedBtn: null });
        }
        this.setState({
            indicatorResponse: true
        });
    }

    derivativesForm = (values: any) => {
    }

    handleFundamentalChangePage = (event: any, newPage: any) => {
        this.setState({
            paginationPage: newPage
        });
    };

    handleFundamentalChangeRowsPerPage = (event: any) => {
        this.setState({
            paginationPage: 0,
            paginationRowsPerPage: parseInt(event.target.value, 10)
        });
    };

    handleChangePage = (event: any, newPage: any) => {
        this.setState({
            page: newPage
        });
    };

    handleChangeRowsPerPage = (event: any) => {
        const resultPerScreen = JSON.parse(localStorage.getItem('currentPlan') as any).result_per_screen;
        this.setState({
            page: 0,
            rowsPerPage: parseInt(event.target.value, resultPerScreen === 'All' ? 10 : resultPerScreen)
        });
    };

    handleBreackoutDownLoadMore = () => {
        this.setState({
            isLoading: true
        });
    };

    handlePageIncrease = () => {
        this.setState({
            page: this.state.page + 1,
            isLoading: true
        });
    };

    handleIndicatorPageIncrease = () => {
        this.setState({
            indicatorPage: this.state.indicatorPage + 1,
            isLoading: true,
            indicatorBtnClicked: true
        });
    };

    handleVolumePageIncrease = () => {
        this.setState({
            volumePage: this.state.volumePage + 1,
            isLoading: true
        });
    };

    handleMorePopup = (data: any) => {
        this.setState({
            handleMoreResult: true,
            selectedRowObj: data
        });
    }

    handleMorePriceAction = (data: any) => {
        if (this.state.priceOptionCategory == 6) {
            this.setState({
                chartTimeFrame: '1M',
            })
        } else {
            this.setState({
                chartTimeFrame: '1D',
            })
        }
        this.setState({
            moreResultPriceAction: true,
            currentSelectedMoreData: data,
            chartType: 'Price',
        })
    }

    handleMorePriceActionWithDataPoints = (data: any) => {
        if (this.state.priceOptionCategory == 6) {
            this.setState({
                chartTimeFrame: '1M',
            })
        } else {
            this.setState({
                chartTimeFrame: '1D',
            })
        }

        this.setState({
            moreResultPriceAction: true,
            currentSelectedMoreData: data,
            chartType: 'Price',
            showHighLowPoints: true
        })
    }

    handleUpdateTimeFramme = (event: any, newAlignment: any) => {
        if (newAlignment !== this.state.chartTimeFrame) {
            this.setState({
                chartTimeFrame: newAlignment
            })
        }
    }

    handleUpdateChartType = (event: any, newAlignment: any) => {
        if (newAlignment !== this.state.chartType) {
            this.setState({
                chartType: newAlignment
            })
        }
    }

    componentDidUpdate(prevProps: any, prevState: any) {
     
        if (this.state.page !== prevState.page && this.state.selectedItemData === 'Price Action' && this.state.page !== 1) {
            this.priceActionForm(this.state);
        }

        if (this.state.indicatorPage !== prevState.indicatorPage && this.state.selectedItemData === 'Indicators' && this.state.indicatorPage !== 1) {
            this.indicatorsForm(this.state);
        }

        if (this.state.volumePage !== prevState.volumePage && this.state.selectedItemData === 'Volumes' && this.state.volumePage !== 1) {
            this.volumeForm(this.state);
        }

        if (this.state.priceActionBrealoutDownStart !== prevState.priceActionBrealoutDownStart && this.state.selectedItemData === 'Price Action') {
            this.priceActionForm(this.state);
        }

    }

    handleClickedBtn = (clickedBtn: string) => {
        if (clickedBtn === "SaveRun" || clickedBtn === "SaveMultiple") {
            let limitExceed = this.checkLimitAccess();
            if (clickedBtn === "SaveMultiple" && limitExceed) {
                this.setState({ customFilterLimitExceed: true})
            } else {
            this.setState({ clickedBtn, filterNamePopup: true });
        }
        } else {
            this.setState({ clickedBtn }); 
        }
    };

    checkLimitAccess = () => {
        const planAccess = currentPlan();
        const { customSavedFilters } = this.state;
        return customSavedFilters.length >= planAccess.custom_screen_saves
    }

    handleRankCategoryChangePage = (event: any, newPage: any) => {
        this.setState({
            rankCategoryPaginationPage: newPage
        });
    };

    closeMore = () => {
        this.setState({ handleMoreResult: false, moreResultPriceAction: false, showHighLowPoints: false });
    }

    handleRankCategoryChangeRowsPerPage = (event: any) => {
        this.setState({
            rankCategoryPaginationPage: 0,
            rankCategoryPaginationRowsPerPage: parseInt(event.target.value, 10)
        });
    };

    handleMultipleFilterSubmit = () => {
        let fundatementalData, volumeData, priceActionData, indicatorData;
        const { filterName, fundamentalsForm: {customRankVal, category, rank, marketCapitalizationTo, marketCapitalizationFrom } } = this.state;
        const finalRank = customRankVal ? customRankVal : rank;
        const cat = this.state.categoriesList.find((cat: any) => category === cat.name);
        if (!this.disableFundamentalSaveButton(this.state.fundamentalsForm)) {
        fundatementalData = { filter_name: filterName, fundamental_filter_type: 'fundamental', rank: finalRank, rank_category: cat?.id,
            market_cap_max: marketCapitalizationTo , market_cap_min: marketCapitalizationFrom } 
        }
        if (!this.disableVolumeSaveButton()) {
            let volOption; 
            if (this.state.volumeOption === 1) {
                volOption = 'volume_average';
            }
            if (this.state.volumeOption === 2) {
                volOption = 'up';
            }
            if (this.state.volumeOption === 3) {
                volOption = 'down';
            }
            volumeData = { volume_filter_type: "volume", filter_name: filterName,  ...this.prepareDataForFilterVolume(this.state), volume_option: volOption };
        }
        if (!this.disablePriceActionsSaveButton()) {
            priceActionData = { price_action_filter_type: 'price_action', filter_name: filterName, ...this.prepareDataForCustomFilterPriceAction(this.state) };
        }
        if (!this.disableIndicatorSaveButton()) {
            indicatorData = { indicator_filter_type: 'indicator', filter_name: filterName, ...this.prepareDataForCustomFilterIndicator(this.state) }; 
        }
        const finalData =  { multi_saved_filter: {...this.customSavedFilterParams.multi_saved_filter, ...fundatementalData, ...volumeData, ...priceActionData, ...indicatorData }}
        this.saveCustomFilters(finalData);
    }

    disableSaveMultiFilterButton = () => {
        let count = 0;
        if (!this.disableFundamentalSaveButton(this.state.fundamentalsForm)) count++;
        if (!this.disableVolumeSaveButton()) count++;
        if (!this.disablePriceActionsSaveButton()) count++;
        if (!this.disableIndicatorSaveButton()) count++;
        return count < 2
    }

    navigateCategoryForSavedFilters = (filter: any) => {
        let filterType =  Object.keys(filter.attributes)[0];
        this.resetFundamentalData();
        this.resetVolumeData();
        this.resetPriceActionData(); 
        this.resetIndicatorDataBeforeAPICall();
        switch(filterType) {
            case 'fundamentals':
                const values = filter.attributes.fundamentals;
                const cat = this.state.categoriesList.find((category: any) => category.id === values.rank_category);
                const funValues = fundamentalObjects(values, cat);                
                this.setState({ fundamentalsForm: { ...funValues }, navigateCategory: 'Screener', activeItem: 'Fundamentals'});
                this.resetDataBeforeAPICall()
                this.fundamentalsForm(funValues);
                break;
            case 'volume':
                const volumeValues = filter.attributes.volume;
                const volValues = volumeObjects(volumeValues);
                this.setState({ ...volValues, navigateCategory: 'Screener', activeItem: 'Volumes' });
                this.resetDataBeforeAPICall();
                this.volumeForm(volValues);
                break;
            case 'price_action':
                const priceActionValues = filter.attributes.price_action
                const paValues = priceActionObjects(priceActionValues);
                this.setState((prevState:any) => ({
                    ...prevState, ...paValues, navigateCategory: 'Screener', activeItem: 'Price Action'
                 }), () => {
                    this.resetDataBeforeAPICall();
                    this.priceActionForm(paValues);
                });
                break;
            case 'indicator':
                const indicatorValues = filter.attributes.indicator;
                const selectedIndicator = isNaN(parseInt(indicatorValues.indicator_main_option)) ?
                this.state.indicatorsOptionList.find((option: any) => option.name.toLowerCase() === indicatorValues.indicator_main_option.toLowerCase())?.id :
                parseInt(indicatorValues.indicator_main_option) 
                const indiValues = indicatorObjects(indicatorValues, selectedIndicator);
                this.setState((prevState:any) => ({
                    ...prevState, ...indiValues, navigateCategory: 'Screener', activeItem: 'Indicators'
                 }), () => {
                    this.resetDataBeforeAPICall();
                    this.indicatorsForm(indiValues);
                });
                break;
        }
    }

    navigateCustomFilterCoinListing = (filter: any) => {
        this.setState({ navigateCategory: 'Screener', currentCustomFilter: filter, activeItem: '' });
        this.resetDataBeforeAPICall();
        this.getCustomFilterCoins(filter.id);
        this.setDataForCustomFilter(filter);
    }

    setDataForCustomFilter = (filter: any) => {
        const filterTypes:string[] = Object.keys(filter.attributes);
        let values = filter.attributes[filterTypes[0]];
        let cat, funValues, volValues, paValues, indiValues, selectedIndicator;
        if(filterTypes[0].includes('fundamental')) {
            cat = this.state.categoriesList.find((category: any) => category.id === values.rank_category);
            funValues = fundamentalObjects(values, cat);
            this.setState({ fundamentalsForm: {...funValues }});
        }
        if(filterTypes[0].includes('volume')) {
            volValues = volumeObjects(values);
            this.setState({ ...volValues });
        }
        if(filterTypes[0].includes('price_action')) {
            paValues = priceActionCustomObjects(values);
            this.setState({ ...paValues });
        }
        if(filterTypes[0].includes('indicator')) {
            selectedIndicator = isNaN(parseInt(values.indicator_main_option)) ?
            this.state.indicatorsOptionList.find((option: any) => option.name.toLowerCase() === values.indicator_main_option.replace("_", " ").toLowerCase())?.id :
            parseInt(values.indicator_main_option) 
            indiValues = indicatorObjects(values, selectedIndicator);
            this.setState({  ...indiValues });
        }
    }

    navigateCategoryHandleChange = ( event: any, newAlignment: string ) => {
        this.setState({
            navigateCategory: newAlignment || this.state.navigateCategory,
            activeItem: '',
            selectedIndicatorsForFilter: []
        });
        this.resetDataBeforeAPICall();
        this.setState({
            fundamentalsForm: {
                rank: "",
                customRankVal: 0,
                category: "",
                marketCapitalizationFrom: "",
                marketCapitalizationTo: ""
            }
        });
        (newAlignment === 'Screener' || this.state.navigateCategory === 'Screener') && this.getRankDetails(10, null, null, null) 
        this.resetVolumeData();
        this.resetPriceActionData(); 
        this.resetIndicatorDataBeforeAPICall();
    };

    disableFundamentalSaveButton = (values: any) => {
        return (values.rank === "" || (values.rank === 0 && values.customRankVal === ""))
    }

    disableVolumeSaveButton = () => {
        const { volumnPeriod, volumnInputLength, volumeOption, volumeValue } = this.state;
        return (volumeOption === "" || (volumeOption=== 1 && (volumnPeriod === "" || volumeValue === "")) || ((volumeOption === 2 || volumeOption === 3) && (volumnInputLength === "" || volumnPeriod === "" ) ))
    }

    disablePriceActionsSaveButton = () => {
        const { priceOptionCategory, priceOptionSubCategoryOne, priceActionTimeFrame, priceActionLength, priceActionPercentage,
        priceActionHighLowSubCategory, priceActionBreakSubCategory, priceActionSupportSubCategory, priceActionNearBreakdownSubCategory,
        priceActionStandardSubCategory, priceActionStandardChannelSubCategory, priceActionStandardTimeOptionSubCategory,
        priceActionReversalSubCategory, priceActionReversalBottomSubCategory, priceActionReversalTopSubCategory, 
        priceActionContinuationSubCategory, priceActionReversalTimeOptionSubCategory, priceActionCandlesSticksSubCategory,
        priceActionCandlesBullishSubCategory, priceActionCandlesBearishSubCategory, priceActionCandlesOthersPatternsSubCategory,
        priceActionCandleStickTimeOptionSubCategory } = this.state;
        return (
            (priceOptionCategory === "") || (priceOptionCategory === 1 && 
                ((priceOptionSubCategoryOne === "") || (["Price Up %", "Price Down %"].includes(priceOptionSubCategoryOne) && (priceActionTimeFrame === "" || priceActionPercentage === "" ))
                || (["Price Trending Up", "Price Trending Down"].includes(priceOptionSubCategoryOne) && (priceActionTimeFrame === "" || priceActionLength === "") ))) ||
                (priceOptionCategory === 2 && priceActionHighLowSubCategory === "") ||
                (priceOptionCategory === 3 && priceActionBreakSubCategory === "") ||
                (priceOptionCategory === 4 && (priceActionSupportSubCategory === "" || priceActionNearBreakdownSubCategory === "" || priceActionTimeFrame === "")) ||
                (priceOptionCategory === 5 && (priceActionStandardSubCategory == "" || priceActionStandardChannelSubCategory === "" || priceActionStandardTimeOptionSubCategory === "" )) ||
                (priceOptionCategory === 6 && ((priceActionReversalSubCategory === "") || 
                (priceActionReversalSubCategory === "bullish reversals" && (priceActionReversalBottomSubCategory === "" || priceActionReversalTimeOptionSubCategory === "")) || 
                (priceActionReversalSubCategory === "bearish reversals" && (priceActionReversalTopSubCategory === "" || priceActionReversalTimeOptionSubCategory === "")) || 
                (priceActionReversalSubCategory === "continuation patterns" && (priceActionContinuationSubCategory === "" || priceActionReversalTimeOptionSubCategory === ""))  )) ||
                (priceOptionCategory === 7 && ((priceActionCandlesSticksSubCategory === "") ||
                (priceActionCandlesSticksSubCategory === "Bullish Candle Scans" && (priceActionCandlesBullishSubCategory === "" || priceActionCandleStickTimeOptionSubCategory === "" )) ||
                (priceActionCandlesSticksSubCategory === "Bearish Candle Scans" && (priceActionCandlesBearishSubCategory === "" || priceActionCandleStickTimeOptionSubCategory === "" )) ||
                (priceActionCandlesSticksSubCategory === "Other Patterns" && (priceActionCandlesOthersPatternsSubCategory === "" || priceActionCandleStickTimeOptionSubCategory === "" ))
            ))
        )
    }

    disableIndicatorSaveButton = () => {
        return(
        this.state.selectedIndicatorsForFilter.length === 0 ||
        ((this.state.selectedIndicatorsForFilter == 1 && this.state.rsiSubCategoryOption == "") ||
        (this.state.selectedIndicatorsForFilter == 2 && this.state.mfiSubCategoryOption == "") ||
        (this.state.selectedIndicatorsForFilter == 3 && this.state.stochasticSubCategoryOption == "") ||
        (this.state.selectedIndicatorsForFilter == 4 && this.state.cciSubCategoryOption == "") ||
        (this.state.selectedIndicatorsForFilter == 5 && this.state.movingAverageSubCategoryOption == 2 && this.state.exponentialMovingAverageSubCategoryOption == "") ||
        (this.state.selectedIndicatorsForFilter == 5 && this.state.movingAverageSubCategoryOption == 1 && this.state.simpleMovingAverageSubCategoryOption == "") ||
        (this.state.selectedIndicatorsForFilter == 6 && this.state.macdSubCategoryOption == "") ||
        (this.state.selectedIndicatorsForFilter == 7 && this.state.adxSubCategoryOption == "") ||
        (this.state.selectedIndicatorsForFilter == 8 && this.state.obvSubCategoryOption == "") ||
        (this.state.selectedIndicatorsForFilter == 9 && this.state.cmfSubCategoryOption == "") ||
        (this.state.selectedIndicatorsForFilter == 10 && this.state.bollingerBandsSubCategoryOption == "") ||
        (this.state.selectedIndicatorsForFilter == 11 && this.state.atrSubCategoryOption == ""))
        )
    }

    resetIndicatorDataBeforeAPICall = () => {
        this.setState({
            rsiPeriod: '',
            selectedIndicatorsList: [],
            rsiSubCategoryOption: "",
            centerLine: '',
            rsiLevel: '',
            rsiRadioGroup: "above",
            mfiSubCategoryOption: "",
            rsiDivergenceOption: "",
            mfiLevel: '',
            rsiTimeFrame: "",
            mfiRadioGroup: "above",
            mfiPeriod: '',
            stochasticSubCategoryOption: "",
            mfiDivergenceOption: "",
            stochasticLevel: '',
            mfiTimeFrame: "",
            stochasticRadioGroup: "above",
            cciSubCategoryOption: "",
            stochasticPeriod: '',
            stochasticDivergenceOption: "",
            cciRadioGroup: "above",
            stochasticTimeFrame: "",
            cciTimeFrame: "",
            simpleMovingAverageSubCategoryOption: "",
            cciPeriod: '',
            movingAverageTimeFrame: "",
            cciDivergenceOption: "",
            movingAveragePeriod: "",
            movingAverageSubCategoryOption: "",
            macdRadioGroup: "above",
            exponentialMovingAverageSubCategoryOption: "",
            macdSubCategoryOption: "",
            macdDivergenceOption: "",
            movingAverageCrossoverOption: "",
            macdPeriod: '',
            adxPeriod: '',
            macdTrendingRadioGroup: "up",
            macdTimeFrame: "",
            adxSubCategoryOption: "",
            adxTimeFrame: "",
            adxLevel: '',
            obvDivergenceOption: "",
            adxRadioGroup: "above",
            obvLevel: '',
            cmfPeriod: "",
            obvSubCategoryOption: "",
            cmfSubCategoryOption: "",
            bollingerBandsSubCategoryOption: "",
            obvTimeFrame: "",
            cmfDivergenceOption: "",
            bollingerBandsTimeFrame: "",
            atrSubCategoryOption: "",
            atrTimeFrame: "",
            atrPeriod: "",
            cmfTimeFrame: "",
            bollingerBandsRadioGroup: "above",
            atrTrendingPeriod: "",
        })
    }

    resetFundamentalData = () => {
        this.setState({
            fundamentalsForm: {
                rank: 10,
                customRankVal: 0,
                category: "",
                marketCapitalizationFrom: "",
                marketCapitalizationTo: ""
            }
        });
    }

    savedFilterParams = {
        "saved_filter": {
            "filter_name": "",
            "filter_type": "",
            "rank": "",
            "rank_category": "",
            "market_cap_min": 0,
            "market_cap_max": 0,
            "volume_option": "",
            "volume_period": "",
            "volume_length": 10,
            "volume_order": "Ascending",
            "volume_from": 0,
            "volume_to": 0,
            "price_action_main_option": "",
            "price_action_option": "",
            "price_action_suboption": "",
            "price_action_timeframe": "",
            "gainloss_percentage": 0,
            "indicator_screen_option": "",
            "indicator_period": 0,
            "indicator_trending_period": 0,
            "indicator_above_below": "",
            "indicator_trending_up_down": "",
            "indicator_level_centerline": 0,
            "indicator_divergence_crossover": "",
            "indicator_moving_average_type": "",
            "indicator_time_frame": ""
        }
    }

    customSavedFilterParams = {
        "multi_saved_filter": {
            "fundamental_filter_type": "",
            "volume_filter_type": "",
            "price_action_filter_type": "",
            "indicator_filter_type": "",
            ...this.savedFilterParams.saved_filter
        }
    }

    resetVolumeData = () => {
        this.setState({ 
            volumeOption: "",
            avgVolumnSlider: [0, 100],
            volumeValue: '',
            volumnPeriod: '',
            volumnInputLength: ''
        });
    }

    resetPriceActionData = () => {
        this.setState({
            priceOptionCategory: "",
            priceOptionSubCategoryOne: "",
            priceActionTimeFrame: "",
            priceActionPercentage: "2",
            priceActionLength: 3,
            priceActionHighLowSubCategory: "",
            priceActionBreakSubCategory: "",
            priceActionSupportSubCategory: "",
            priceActionNearBreakdownSubCategory: "",
            priceActionSupportTimeFrameSubCategory: "",
            priceActionStandardSubCategory: "",
            priceActionStandardChannelSubCategory: "",
            priceActionStandardTimeOptionSubCategory: "",
            priceActionReversalSubCategory: "",
            priceActionReversalBottomSubCategory: "",
            priceActionReversalTopSubCategory: "",
            priceActionContinuationSubCategory: "",
            priceActionCandlesSticksSubCategory: "",
            priceActionCandlesBullishSubCategory: "",
            priceActionCandlesBearishSubCategory: "",
            priceActionCandlesOthersPatternsSubCategory: ""
        })
        
    }

    resetDataBeforeAPICall = () => {
        this.setState({
            rankResponseData: [],
            rankResponseEmpty: true,
            selectedCategoryResponse: [],
            socialEngagementResponseData: [],
            marketCapitalizationResponseData: [],
            priceActionResponseData:[],
            priceActionGainLossResponseData: [],
            priceActionHighLowResponseData: [],
            priceActionBrealoutDownResponseData: [],
            priceActionSupportResistanceResponseData: [],
            priceActionCandelSticksResponseData: [],
            priceActionStandardPatternsResponseData: [],
            priceActionReversalContinualtionResponseData: [],
            indicatorRSIResponseData: [],
            indicatorMFIResponseData: [],
            indicatorStochasticResponseData: [],
            indicatorCCIResponseData: [],
            indicatorMovingAverageResponseData: [],
            indicatorMACDResponseData: [],
            indicatorADXResponseData: [],
            indicatorOBVResponseData: [],
            indicatorCMFResponseData: [],
            indicatorBBResponseData: [],
            indicatorATRResponseData: [],
            volumeUpResponseData:[],
            lastVolumeResponseData: [],
            page: 1,
            indicatorPage:1,
            volumePage:1,
            isLoading: false,
            isMoreData: true,
            priceActionBrealoutDownStart: 1,
            priceActionBrealoutDownLimit: 100,
            moreResultPriceAction: false,
            showHighLowPoints: false,
            handleMoreResult: false,
            anchorEl: null,
            showCustomFilter: false
        })
    }
// Customizable Area End
}

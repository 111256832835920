import React from "react";
// Customizable Area Start
//@ts-nocheck
import {
  Dimensions,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View
} from "react-native";
import Icon from "react-native-vector-icons/AntDesign";
import HelperClass from "../../../components/src/HelperClass";
import CustomText from "../../../components/src/text";
import NewDropDown from "../../../components/src/NewDropDown";
import PriceController, { Props } from './PriceController'
const { width, height } = Dimensions.get('window');
// Customizable Area End
export default class PriceAction extends PriceController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  optionFunc = () => {
    this.setState({ pickerValue: this.props.value.option }, () => { })
    this.onChangePriceAction(0, this.props.value.option)
  }
  sunOption(params: any) {
    const channelArray = ['Inside Channel', 'Channel Breakout', 'Channel Breakdown',
    ]
    const standardarray = [
      'Near R1', 'Near R2', 'Near R3', 'Breakout R1', 'Breakout R2', 'Breakout R3',
      'Breakdown S3', 'Breakdown S2', 'Breakdown S1', 'Near S1', 'Near S2', 'Near S3'
    ]
    const def = (selectedItem: string) => {
      const array = [
        { id: 1, name: 'Near R1', value: 'NEAR_R1' },
        { id: 2, name: 'Near R2', value: 'NEAR_R2' },
        { id: 3, name: 'Near R3', value: 'NEAR_R3' },
        { id: 4, name: 'Breakout R1', value: 'BREAKDOWN_R1' },
        { id: 5, name: 'Breakout R2', value: 'BREAKDOWN_R2' },
        { id: 6, name: 'Breakout R3', value: 'BREAKDOWN_R3' },
        { id: 1, name: 'Near S1', value: 'NEAR_S1' },
        { id: 2, name: 'Near S2', value: 'NEAR_S2' },
        { id: 3, name: 'Near S3', value: 'NEAR_S3' },
        { id: 4, name: 'Breakdown S1', value: 'BREAKDOWN_S1' },
        { id: 5, name: 'Breakdown S2', value: 'BREAKDOWN_S2' },
        { id: 6, name: 'Breakdown S3', value: 'BREAKDOWN_S3' },
      ]
      let element = array.find((item, index) => {
        return item.name == selectedItem
      })
      let temp = ["1 hr", "2 hr", "3 hr", "4 hr", "6 hr", "12 hr", "1 day", "2 days", "3 days", "7 days", "14 days", "15 days", "30 days", "60 days ", "90 days", "365 days"]
      this.setState({ timeFrameArray: temp, standardSupportValue: element })
      this.onChangePriceAction(0, element?.name);
    }
    const channel = (selectedItem: string) => {
      const array = [
        { id: 1, name: 'Inside Channel', value: 'inside channel' },
        { id: 2, name: 'Channel Breakout', value: 'channel breakout' },
        { id: 3, name: 'Channel Breakdown', value: 'channel breakdown' }
      ]
      let element = array.find((item, index) => {
        return item.name == selectedItem
      })
      this.setState({ channelValue: element })
      this.onChangePriceAction(0, element?.value);
    }
    const candle = (selectedItem: string) => {
      this.setState({ reversalOptionValue: selectedItem, })
      this.onChangePriceAction(0, selectedItem);
    }
    const subOptionArray = {
      "standard": def,
      "channel": channel,
      "default": candle,
    }
    standardarray.includes(params) && subOptionArray["standard"](this.props.value.subOption)
    channelArray.includes(params) && subOptionArray["channel"](this.props.value.subOption)
    !standardarray.includes(params) && !channelArray.includes(params) && subOptionArray["default"](this.props.value.subOption)
  }
  timeFrame(params: any) {
    const timeframe = [
      '1 day',
      '1 week',
      '15 days',
      '1 month'
    ]
    const timeOptionArray = ["5 min", "10 min", "15m", "30m", "45m", "1 hr", "2 hr", "3 hr", "4 hr", "6 hr", "12 hr", "1 day", "2 days", "3 days", "7 days", "14 days", "15 days", "30 days", "60 days", "90 days", "365 days"]
    const defaultTimeframe = (selectedItem: string) => {
      const timeframe = [
        { id: 1, name: '1 day', value: '1d' },
        { id: 2, name: '1 week', value: '7d' },
        { id: 3, name: '15 days', value: '15d' },
        { id: 4, name: '1 month', value: '30d' }
      ]
      let element = timeframe.find((item, index) => {
        return item.name == selectedItem
      })
      // timeoptionValue
      this.setState({ timeoptionValue: element })
      this.onChangePriceAction(0, element);

    }

    const channelTimeframe = (selectedItem: string) => {
      if (this.props.value.percentage) {
        this.setState({ isParcentage: true, })
      }
      else {
        this.setState({ isParcentage: false });
      }

      const options = ["Bullish Reversals", "Bearish Reversals", "Continuation Patterns", "Bullish Candle Scans", "Bearish Candle Scans", "Other Patterns"]
      if (options.includes(this.props.value.option)) {
        this.setState({ candaleTimeFrameLabel: selectedItem })
        this.onChangePriceAction(0, selectedItem);
        return
          ;
      }
      this.setState({ timeFrameValue: selectedItem })
      this.onChangePriceAction(0, selectedItem);

    }
    const timeframeArray = {
      "channel": channelTimeframe,
      "default": defaultTimeframe,
    }
    timeframe.includes(params) && timeframeArray["default"](this.props.value.timeFrame)
    timeOptionArray.includes(params) && timeframeArray["channel"](this.props.value.timeFrame)
  }
  percentageFunction(params: any) {
    this.setState({
      parcentageValue: params
    })
  }
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.props.check && this.props.value.mainOption && this.onChangePriceAction(0, this.props.value.mainOption)
    this.props.check && this.props.value.option && this.optionFunc()
    this.props.check && this.props.value.subOption && this.sunOption(this.props.value.subOption)
    this.props.check && this.props.value.timeFrame && this.timeFrame(this.getByCondition(typeof this.props.value.timeFrame, "object", this.props.value.timeFrame.name, this.props.value.timeFrame))
    this.props.check && this.props.value.percentage && this.percentageFunction(this.props.value.percentage)
    // Customizable Area Start
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <View style={styles.container}>
        <View style={{ width: width / 1.1, marginBottom: 10 }}>
          <NewDropDown 
          testID={'asanew'}
          placeholder={this.renderConditionalItem(this.props.value.mainOption, this.props.value.mainOption, "Select Price Action")}
          placeHolderName={"Select Price Action"}
            data={this.state.priceActionArray}
            isBasic={this.state.isBasic}
            onSelect={(selectedItem: any, index: number) => {
              // main option here
              this.onClickClear()
              this.setState({ priceActiveLabel: selectedItem }, () => {
                if (this.state.optionsArray.length > 0)
                  this.onClickClear()
                HelperClass.setPriceMainOption(selectedItem);
                this.props.clearAll();
                this.onChangePriceAction(index, selectedItem);
              });
            }}
            width={width} mode={this.props.mode} />
          {this.renderConditionalItem(this.state.optionsArray.length !== 0,
            <View style={{ marginTop: 10 }}>
              <NewDropDown placeholder={this.renderConditionalItem(this.state.pickerValue
                , this.state.pickerValue
                , 'Select option')}
                placeHolderName={'Select option'}
                data={this.state.optionsArray}
                Upgrade = {()=>this.startBasicPlan()}
                isBasic={this.state.isBasic}
                onSelect={(selectedItem: any, index: any) => {
                  //option here
                  this.setState({ pickerValue: selectedItem,reversalOptionArray: [] , candaleTimeFrameArray: [],timeoptionValue: '',standardSupportArray: [],
                  standardSupportLabel: '',
                  reversalOptionValue: '' }, () => {
                    this.props.clearAll();
                    this.props.onPriceHighLow(this.state.pickerValue);
                    this.onChangePriceAction(index, selectedItem);
                  });
                }}
                testID={'anynews'}
                width={width} mode={this.props.mode} />
            </View>)
          }
          {this.getByCondition(this.state.standardSupportArray.length, 0, null,
            <View style={{ marginTop: 10 }}>
              <NewDropDown placeholder={this.renderConditionalItem(this.state.standardSupportValue , this.state.standardSupportValue.name , 'Select Option')}
              placeHolderName={'Select Option'}
                data={this.state.standardSupportArray}
                Upgrade = {()=>this.startBasicPlan()}
                isBasic={this.state.isBasic}
                onSelect={(selectedItem: any, index: number) => {
                  //suboption here
                  let temp = ["1 hr", "2 hr", "3 hr", "4 hr", "6 hr", "12 hr", "1 day", "2 days", "3 days", "7 days", "14 days", "15 days", "30 days", "60 days", "90 days", "365 days"]
                  let temps = ["1 hr", "1 day"]
                  this.setState({ timeFrameArray:this.state.isBasic === true ? temps :temp, standardSupportValue: selectedItem }, () => {
                    this.props.onSupportResistance(this.state.pickerValue, this.state.standardSupportValue, this.state.timeFrameValue)
                  })
                  this.onChangePriceAction(index, selectedItem.value);
                }}
                testID={'anynew'}
                width={width} mode={this.props.mode} />
            </View>)
          }
          {this.getByCondition(this.state.timeFrameArray.length, 0, null,
            <View style={{ marginTop: 10 }}>
              {this.renderConditionalItem(this.state.timeFrameValue === '',
                <NewDropDown placeholder={this.renderConditionalItem(this.state.timeFrameValue , this.state.timeFrameValue , 'Time Frame')}
                placeHolderName={'Time Frame'}
                  data={this.state.timeFrameArray}
                  Upgrade = {()=>this.startBasicPlan()}
                  isBasic={this.state.isBasic}
                  testID={'timeframe'}
                  onSelect={(selectedItem: any, index: number) => {
                    // time frame
                    this.setState({ timeFrameValue: selectedItem }, () => {
                      if (this.state.standardSupportValue != '') {
                        this.setState({ isParcentage: false });
                        this.props.onSupportResistance(
                          this.state.pickerValue,
                          this.state.standardSupportValue,
                          this.state.timeFrameValue,
                        );
                      } else {
                        this.setState({ isParcentage: true });
                      }
                    });
                    this.onChangePriceAction(index, selectedItem);
                  }}
                  width={width} mode={this.props.mode} />
                ,
                <View style={[styles.dropDownTimeFram, { borderRadius: 0 }]}>
                  <Text style={[styles.frameText, { color: this.getColorTheme("light" , "#000" , "#FFF") }]}>{'Time Frame'}</Text>
                  {
                    this.getByCondition(this.state.timeFrameValue, 'Custom',
                      <View style={styles.customStyle}>
                        <TextInput
                        testID="text"
                          placeholder={'Custom'}
                          value={this.state.customValue}
                          onChangeText={text => {
                            this.setState({
                              customValue: text
                            })
                          }}
                          onSubmitEditing={() =>
                            this.props.onPriceFilter(this.state.timeFrameValue, this.state.customValue, this.state.pickerValue)
                          }
                          autoCapitalize={'words'}
                          style={styles.customInput}
                        />
                        <TouchableOpacity onPress={() => this.setState({ timeFrameValue: '' })} style={{ marginRight: 10 }}>
                          <Icon
                            name={'close'}
                            color={"#444"}
                            size={15}
                          />
                        </TouchableOpacity>
                      </View>
                      ,
                      <NewDropDown placeholder={this.renderConditionalItem(this.state.timeFrameValue , this.state.timeFrameValue , 'Time Frame')}
                      placeHolderName={'Time Frame'}
                        data={this.state.timeFrameArray}
                        testID={'timeframevalue'}
                        Upgrade = {()=>this.startBasicPlan()}
                        isBasic={this.state.isBasic}
                        onSelect={(selectedItem: any, index: number) => {
                          this.setState({ timeFrameValue: selectedItem }, () => {
                            this.props.onSupportResistance(
                              this.state.pickerValue,
                              this.state.standardSupportValue,
                              this.state.timeFrameValue,
                            );
                            if (this.state.standardSupportValue != '') {
                              this.setState({ isParcentage: false });
                            } else {
                              this.setState({ isParcentage: true });
                            }
                          });
                          this.onChangePriceAction(index, selectedItem);
                        }}
                        width={width} mode={this.props.mode} />
                    )
                  }
                </View>)}
            </View>)
          }
          {this.getByCondition(this.state.channelArray.length, 0, null,
            ////suboption
            <View style={{ marginTop: 10 }}>
              <NewDropDown placeholder={this.renderConditionalItem(this.state.channelValue , this.state.channelValue.name , 'Select Channel Name')}
              placeHolderName={'Select Channel Name'}
                data={this.state.channelArray}
                testID={'channelValue'}
                Upgrade = {()=>this.startBasicPlan()}
                isBasic={this.state.isBasic}
                onSelect={(selectedItem: any, index: any) => {
                  this.setState({ channelValue: selectedItem })
                  this.onChangePriceAction(index, selectedItem.value);
                  this.props.onSupportPattern(this.state.pickerValue, selectedItem, this.state.timeoptionValue)
                }}
                width={width} mode={this.props.mode} />
            </View>
          )}
          {this.getByCondition(this.state.timeOptionArray.length, 0, null,
            ///channel time frame
            <View style={{ marginTop: 10 }}>
              {this.getByCondition(this.state.timeoptionValue, 'Custom',
                <View style={styles.customStyle}>
                  <TextInput
                    placeholder={'Custom'}
                    value={this.state.customValue}
                    onChangeText={text => {
                      this.setState({
                        customValue: text
                      })
                    }}
                    autoCapitalize={'words'}
                    keyboardType={'numeric'}
                    style={styles.customInput}
                  />
                  <TouchableOpacity onPress={() => this.setState({ timeoptionValue: '' })} style={{ marginRight: 10 }}>
                    <Icon
                      name={'close'}
                      color={"#444"}
                      size={15}
                    />
                  </TouchableOpacity>
                </View>
                ,
                <NewDropDown placeholder={this.renderConditionalItem(this.state.timeoptionValue , this.state.timeoptionValue.value , 'Select Time Option')}
                placeHolderName={'Select Time Option'}
                  data={this.state.timeOptionArray}
                  testID={'timeoptionValue'}
                  Upgrade = {()=>this.startBasicPlan()}
                  isBasic={this.state.isBasic}
                  onSelect={(selectedItem: any, index: any) => {
                    this.setState({ timeoptionValue: selectedItem }, () => {
                      this.props.onSupportPattern(this.state.pickerValue, this.state.channelValue, this.state.timeoptionValue)
                    })
                    this.onChangePriceAction(index, selectedItem.value);
                  }}
                  width={width} mode={this.props.mode} />

              )}
            </View>)
          }
          {this.getByCondition(this.state.reversalOptionArray.length, 0, null,
            ///candel suboption
            <View style={{ marginTop: 10 }}>
              <NewDropDown placeholder={this.renderConditionalItem(this.state.reversalOptionValue , this.state.reversalOptionValue , 'Select Time Option')}
              placeHolderName={'Select Time Option'}
                data={this.state.reversalOptionArray}
                testID={'reversalOptionArray'}
                Upgrade = {()=>this.startBasicPlan()}
                isBasic={this.state.isBasic}
                onSelect={(selectedItem: any, index: any) => {
                  this.props.clearAll();
                  this.setState({ reversalOptionValue: selectedItem, }, () => {
                    this.props.onCandleStick(this.state.pickerValue, this.state.reversalOptionValue, this.state.candaleTimeFrameLabel)
                  })
                  this.onChangePriceAction(index, selectedItem);
                }}
                width={width} mode={this.props.mode} />

              {
                ///candletime
                this.renderConditionalItem(this.state.candaleTimeFrameArray.length != 0 ,
                <View style={{ marginTop: 10 }}>
                  <NewDropDown placeholder={this.renderConditionalItem(this.state.candaleTimeFrameLabel, this.state.candaleTimeFrameLabel, "Time Frame")}
                  placeHolderName={"Time Frame"}
                    data={this.state.candaleTimeFrameArray}
                    testID={'candaleTimeFrameArray'}
                    Upgrade = {()=>this.startBasicPlan()}
                    isBasic={this.state.isBasic}
                    onSelect={(selectedItem: any, index: any) => {
                      this.setState({ candaleTimeFrameLabel: selectedItem }, () => {
                        this.props.onCandleStick(this.state.pickerValue, this.state.reversalOptionValue, this.state.candaleTimeFrameLabel)
                      })
                    }}
                    width={width} mode={this.props.mode} />

                </View>)
              }
            </View>)
          }
          {this.renderConditionalItem(this.state.isParcentage,
            <View style={[styles.fromTextInput, {}]}>
              {
                this.renderConditionalItem(this.state.parcentageValue != '' ,
                <Text style={[styles.subText, { color: this.getColorTheme('light', "gray", "white") }]}>{this.renderConditionalItem(this.state.isPriceTrandingUP , "Length" , "Percentage")}</Text>)
              }
              <TextInput
                placeholder={this.getByCondition(this.state.isPriceTrandingUP, true, 'Length', 'Percentage')}
                value={this.state.parcentageValue.toString()}
                placeholderTextColor={this.getColorTheme('light' , "gray" , "white")}
                onChangeText={text => {
                  this.setState({
                    parcentageValue: text
                  }, () => {
                    this.props.onPriceFilter(this.getByCondition(this.state.timeFrameValue, 'Custom', this.state.customValue, this.state.timeFrameValue), text, this.state.pickerValue)
                  })
                }}
                autoCapitalize={'words'}
                keyboardType={'numeric'}
                onSubmitEditing={() => this.props.onPriceFilter(this.getByCondition(this.state.timeFrameValue , 'Custom' , this.state.customValue , this.state.timeFrameValue), this.state.parcentageValue, this.state.pickerValue)}
                style={{
                  fontWeight: 'bold', width: "100%", height: height / 13, color: this.getColorTheme('light' , "#000" , "white"), backgroundColor:
                    this.getColorTheme('light' , "#FFF" , "#000")
                }}
              />
            </View>)
          }
        </View>
        <View style={{ flexDirection: "row", marginLeft: "auto", marginRight: "auto" }} >
          <TouchableOpacity
            style={[styles.saveBtn, { marginRight: 15 }]}
            testID="btnExamplean"
            onPress={
              () => {
                this.props.save()
              }
            }
          >
            <CustomText style={styles.saveTxt}>{this.SaveBtn}</CustomText>
          </TouchableOpacity>
          <TouchableOpacity
            testID="btnExamples1"
            style={styles.saveBtn}
            onPress={
              () => {
                this.props.saveApply()
              }
            }
          >
            <CustomText style={styles.saveTxt}>Save & Apply</CustomText>
          </TouchableOpacity>

        </View>
      </View>
      // Customizable Area End
    )
  }
}

const styles = StyleSheet.create({
  // Customizable Area Start
  saveBtn: { marginTop: 6, height: 35, width: 100, backgroundColor: "green", alignItems: "center", justifyContent: "center" },
  saveTxt: { fontSize: 15, color: "black" },
  container: {
    flex: 1,
    alignItems: 'center'
  },
  dropdown1BtnStyle: {
    width: width / 1.1,
    height: 60,
    backgroundColor: "#F7F7F7",
    borderRadius: 8,
    borderWidth: 1,
    borderColor: "#fff",
  },
  fromTextInput: {
    // backgroundColor: '#F7F7F7',
    borderRadius: 0,
    // height: height / 13,
    // width:width-1.05,
    marginTop: 10
  },
  marketCapText: {
    marginLeft: 10,
    // textAlign: 'center',
    fontSize: 18,
    fontWeight: 'bold'
  },
  subText: {
    color: 'gray',
  },
  dropDownTimeFram: {
    // backgroundColor: '#F7F7F7',
    width: width / 1.1

  },
  frameText: {
    marginLeft: 10,
    marginTop: 0
  },
  customStyle: {
    width: width / 1.1,
    backgroundColor: '#F7F7F7',
    flexDirection: 'row', alignItems: 'center'

  },
  customInput: {
    width: width / 1.2,
    marginLeft: 5
  }
  // Customizable Area End
})
import React from "react";
// Customizable Area Start
import EducationCenterIntermediateWebController, {
    Props
} from "./EducationCenterIntermediateController.web";

import {
    withStyles, StyleRules
} from "@material-ui/core/styles";

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import Hidden from '@material-ui/core/Hidden';
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Loader from "../../../components/src/Loader.web";
import Divider from '@material-ui/core/Divider';

import { eduBackgroundImage, playStoreImg } from './assets';
import { configJSON } from "./EducationCenter.web";
import { currentPlan } from "../../../components/src/chartUtils.web";
// Customizable Area End

const styles: StyleRules = {
    // Customizable Area Start
    containor: {
        margin: '00px 22px',
        padding: "30px 50px 10px",
        '@media (max-width: 600px)': {
            padding: '10px'
        }
    }
    // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
export class EducationCenterIntermediateWeb extends EducationCenterIntermediateWebController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    TopicList = (item: any, key: any) => {
        const title = item.attributes?.title || "";
        const description = item.attributes?.description;

        const extractTextWithRegex = (inputHTML: any, maxLength: number) => {
            const sanitizedHTML = inputHTML.replace(configJSON.sanitizedHTMLRegex, "");
            const plainText = sanitizedHTML.replace(configJSON.plainTextRegex, "");
            return plainText.slice(0, maxLength) + (plainText.length > maxLength ? "..." : "");
        };

        const truncatedDescription = extractTextWithRegex(description || '', 350); // Change 300 to your desired character limit
        const plan = currentPlan();
        const lessonsPerModule =  plan && plan.lessons_per_module;
        return (
            <ListItem
                disabled={lessonsPerModule && key >= lessonsPerModule }
                button
                data-testid={`listitem${item.id}`}
                onClick={(event: any) => {
                    window.location.href = "/EducationCenterWeb?topicId=" + item.id;
                }}
            >
                <ListItemText
                    primary={`${key + 1}. ${title}`}
                    secondary={(
                        <span style={{ fontSize: '12px' }} dangerouslySetInnerHTML={{ __html: truncatedDescription }} />
                    )}
                    primaryTypographyProps={{
                        style: {
                            fontSize: "20px",
                            marginLeft: "12px 0px 0px 12px",
                            fontWeight: 'bold',
                            cursor: "pointer"
                        }
                    }}
                    secondaryTypographyProps={{
                        style: {
                            margin: "12px 0px 0px 12px",
                            textAlign: 'justify'
                        },
                    }}
                />
            </ListItem>
        );
    };

    TopicDetails = (item: any) => {
        const title = '1.' + (item?.title || '')
        const description = item?.description

        const ExtractTextFromHTML = (inputString: any) => {
            const sanitizedString = inputString.replace(configJSON.removeTagsAndStylesRegex, "");
            return sanitizedString;
        };

        const descriptionText = ExtractTextFromHTML(description || "");

        return (
            <ListItem
                button
                data-testid={`listitem${item?.id}`}
                onClick={(event: any) => {
                    window.location.href = "/EducationCenterWeb?topicId=" + item.id;
                }}
            >
                <ListItemText
                    primary={title}
                    secondary={
                        <span style={{ fontSize: '12px' }} dangerouslySetInnerHTML={{ __html: descriptionText }} />
                    }
                    primaryTypographyProps={{
                        style: {
                            fontSize: "20px",
                            marginLeft: "12px 0px 0px 12px",
                            fontWeight: 'bold',
                            cursor: "pointer"
                        }
                    }}
                    secondaryTypographyProps={{
                        style: {
                            margin: "12px 0px 0px 12px",
                            textAlign: 'justify'
                        },
                    }}
                />
            </ListItem>
        );
    };
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { selectedItemData } = this.state;

        return (
            <Paper elevation={0} square data-testid="educationCenterIntermediatePaper" style={{ backgroundColor: localStorage.getItem('appTheme') == 'ligthTheme' ? '#F4F8F6' : '#232429'}}>
                <Loader loading={this.state.loading} />
                <Paper className={classes.containor}>
                    <div style={{ margin: '8px 16px', padding: '8px 16px' }}>
                        <Typography variant="h3" style={{ fontWeight: 'bold', fontSize: '30px' }}>
                            {selectedItemData?.attributes.title}
                        </Typography>
                    </div>
                    <div style={{ margin: '8px', padding: '8px' }}>
                        <Grid container direction="row" spacing={0} >
                            <Hidden smDown>
                                <Grid item xs={12} md={7} style={{ marginBottom: '8px' }} >
                                    <Paper elevation={0} data-testid='paperComponentTestId' >
                                        {selectedItemData?.attributes.children.length > 0 ? (
                                            selectedItemData.attributes.children.map((item: any, key: number) =>
                                                this.TopicList(item, key)
                                            )
                                        ) : (
                                            this.TopicDetails(selectedItemData?.attributes)
                                        )}
                                    </Paper>

                                </Grid>
                            </Hidden>
                            <Grid item xs={12} md={5} style={{ marginTop: '8px' }}>
                                <Paper elevation={0} >
                                    <img src={selectedItemData?.attributes.image == null ? eduBackgroundImage : selectedItemData?.attributes.image} style={{ width: '100%', maxHeight: '350px' }} />
                                </Paper>
                            </Grid>
                            <Hidden mdUp>
                                <Grid item xs={12} md={7} style={{ marginBottom: '8px' }} >
                                    <Paper elevation={0}>
                                        {selectedItemData?.attributes.children.length > 0 ? (
                                            selectedItemData.attributes.children.map((item: any, key: number) =>
                                                this.TopicList(item, key)
                                            )
                                        ) : (
                                            this.TopicDetails(selectedItemData?.attributes)
                                        )}
                                    </Paper>
                                </Grid>
                            </Hidden>
                        </Grid>
                        <Divider style={{ height: '2px' }} />
                        <div>
                            <img src={playStoreImg} style={{ width: '20%' }} />
                        </div>
                    </div>
                </Paper>
            </Paper>
        )
        // Customizable Area End
    }
}

export default withStyles(styles)(EducationCenterIntermediateWeb);

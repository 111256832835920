// Customizable Area Start
//@ts-nocheck
import React from "react";
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Dimensions,
  TextInput,
} from "react-native";
const { height, width } = Dimensions.get("screen");
import Icon from "react-native-vector-icons/AntDesign";
import FundamentalController, { Props } from "./FundamentalController";
import CustomDropDown from "../../../components/src/CustomDropDown";
import SelectDropdown from 'react-native-select-dropdown';
import CustomText from "../../../components/src/text";
import { theme } from "../../../framework/src/theme";
import NewDropDown from "../../../components/src/NewDropDown";
// Customizable Area End
export default class Fundamentals extends FundamentalController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const rank10 = () => {

      this.onChange(0, "Top 10");
    }
    const rank50 = () => {

      this.onChange(0, "Top 50");
    }
    const rankCustom = (text: any) => {

      this.setState({
        customValue: text.replace(/\D/g, "")
      })
      this.onChange(0, "Custom");
    }
    const RankFunction = () => {
      const RankValueObject = {
        10: rank10,
        50: rank50,
        Custom: rankCustom,
      }
      this.setState({ defaultButtonText: "Rank" })
      this.onChange(0, "Rank");
      this.props.value.rankValue == "10" || this.props.value.rankValue == "50" ?
        //@ts-ignore
        RankValueObject[this.props.value.rankValue]() : RankValueObject["Custom"](this.props.value.rankValue)
    }
    const markCapital = () => {

      this.setState({ market_from: this.props.value.marketFrom, market_to: this.props.value.marketTo, defaultButtonText: "Market Capitalization" })
      this.onChange(0, "Market Capitalization");
    }
    const category = () => {
      let categoryValue = this.props.value.category
      this.setState({ defaultButtonText: "Category" })
      this.onChange(0, "Category");
      this.props.value.onChangeCategories(categoryValue, 0)
      this.onChangeCategories(categoryValue, 0, true);
    }
    const customdropdownObject = {
      "Rank": RankFunction,
      "MarketCapitalization": markCapital,
      "Category": category,
    }
    if (this.props.value.multipleFilters.length > 1) {
      this.props.check && this.props.value && this.multipleFIlter(customdropdownObject)
    }
    else {
      this.props.check && this.props.value && this.singleFilter(customdropdownObject)
    }
    // Customizable Area Start
  }
  multipleFIlter(customdropdownObject: any) {
    this.props.value.multipleFilters.forEach((i: any) => {
      customdropdownObject[i]()
    })
  }
  singleFilter(customdropdownObject: any) {
    customdropdownObject[this.props.value.nameOfFilter]()
  }
  rankArray() {
    if (this.state.rankArray.length != 0 && this.state.subRankLabel === 'Custom') {
      return (<View style={styles.ViewStyle}>
        <CustomText style={styles.textStyle}>{this.state.rankLabel}</CustomText>
        <View style={[styles.customStyle, { backgroundColor: this.getColorTheme('light', "#FFF", "#000"), }]}>
          <TextInput
            placeholder={'Custom'}
            placeholderTextColor={this.getColorTheme('light', "#000", "#FFF")}
            value={this.state.customValue.toString()}
            onChangeText={text => {
              this.setState({
                customValue: text.replace(/[^0-9]/g, "")
              }, () =>
                this.props.onClikcRank(this.state.customValue, 'Rank')
              )
            }}
            autoCapitalize={'words'}
            keyboardType={'numeric'}
            testID="rankArray-input"
            style={[styles.customInput, {
              backgroundColor: this.getColorTheme('light', "#FFF", "#000"),
              color: this.getColorTheme('light', "#000", "#FFF")
            }]}
          />
          <TouchableOpacity testID="rankArray-clearBtn" onPress={() => this.onClickClearDerivativesFd()} style={{ marginRight: 10 }}>
            <Icon
              name={'close'}
              color={this.getColorTheme('light', "#000", "#FFF")}
              size={15}
            />
          </TouchableOpacity>
        </View>
      </View>
      )
    }
    if (this.state.rankArray.length > 0 && this.state.rankLabel.length > 0) {
      return (
        <View style={[styles.ViewStyle, {
          backgroundColor: this.getColorTheme('light',
            theme.light.headeBg, theme.dark.headeBg)
        }]} testID='dprankArray'>
          <Text style={[styles.textStyle, {
            color: this.getColorTheme('light',
              "#000", "#FFF")
          }]}>{this.state.rankLabel}</Text>
          <CustomDropDown
            data={this.state.rankArray}
            ref={this.dropdownRef}
            onSelect={(selectedItem: any, index: number) => {
              this.onChange(index, selectedItem);

            }}
            defaultButtonText={this.RankLabel()}
            buttonTextAfterSelection={(selectedItem: any) => {
              return selectedItem;
            }}
            rowTextForSelection={(item: any) => {
              return item;
            }}
            buttonStyle={this.state.rankLabel ? [styles.dropdownIndustryStyle, {
              backgroundColor: this.getColorTheme('light',
                theme.light.headeBg, theme.dark.headeBg)
            }] : [styles.dropdown1BtnStyle, {
              backgroundColor: this.getColorTheme('light',
                theme.light.headeBg, theme.dark.headeBg)
            }]}
            buttonTextStyle={{ color: 'yellow', textAlign: "left" }}
            renderDropdownIcon={(isOpened: boolean) => {
              return (
                <View style={{ flexDirection: "row", paddingRight: 15 }}>
                  {
                    this.state.rankLabel.length != 0 &&
                    <TouchableOpacity
                      style={styles.closeIcon}
                      onPress={() => this.onClickClearDerivativesFd()}>
                      <Icon
                        name={'close'}
                        color={"#444"}
                        size={15}
                      />
                    </TouchableOpacity>
                  }
                  <Icon
                    name={this.careDownUp(isOpened)}
                    color={"#444"}
                    size={14}
                  />
                </View>

              );
            }}
          />
        </View>
      )
    }

    return (this.state.rankArray.length != 0) &&
      <CustomDropDown
        data={this.state.rankArray}
        ref={this.dropdownRef}
        onSelect={(selectedItem: any, index: number) => {
          this.onChange(index, selectedItem);
        }}
        defaultButtonText={this.state.subRankLabel != "" ? this.state.subRankLabel : 'Rank'}
        buttonTextAfterSelection={(selectedItem: any, index: number) => {
          return selectedItem;
        }}
        rowTextForSelection={(item: any, index: number) => {
          return item;
        }}
        buttonStyle={[styles.dropdown1BtnStyle, {
          backgroundColor: this.getColorTheme('light',
            theme.light.headeBg, theme.dark.headeBg)
        }]}
        buttonTextStyle={{ color: this.state.backColor, textAlign: "left" }}
        renderDropdownIcon={(isOpened: boolean) => {
          return this.dropDownIcon(isOpened)
        }}
      />
  }

  industoryFxn() {
    return (
      this.state.industryArray.length != 0 && this.state.industryLabel === '' &&
      <View style={{ marginTop: 10 }} testID="industoryFxn">
        <CustomDropDown
          data={this.state.industryArray}
          ref={this.dropdownRef}
          onSelect={(selectedItem: any, index: number) => {
            this.onChange(index, selectedItem);
          }}
          defaultButtonText={this.state.industryLabel.length != '' ? this.state.industryLabel : 'Select filter'}
          buttonTextAfterSelection={(selectedItem: any) => {
            return selectedItem;
          }}
          rowTextForSelection={(item: any) => {
            return item;
          }}
          buttonStyle={styles.dropdown1BtnStyle}
          buttonTextStyle={{ color: this.state.backColor, textAlign: "left" }}
          renderDropdownIcon={(isOpened: boolean) => {
            return (
              <>
                <Icon
                  name={this.careDownUp(isOpened)}
                  color={"#444"}
                  size={10}
                />
                {
                  this.state.subIndusrtyLable != '' &&
                  <TouchableOpacity
                    testID="clearSbIndustry"
                    style={styles.closeIcon}
                    onPress={() => this.onClickClearindustry()}>
                    <Icon
                      name={'close'}
                      color={"#444"}
                      size={15}
                    />
                  </TouchableOpacity>
                }
              </>

            );
          }}
        />
      </View>
    )
  }

  categoryFxn() {
    return (
      this.renderByCondition(this.state.categoryLabel !== '',
        <View style={{
          marginTop: 5,
          backgroundColor: this.getColorTheme("light", "#FFF", "#000"),
          borderRadius: this.renderByCondition(this.state.subCategoryLabel, 8, 0),
        }}
          testID="categoryFxn">
          {
            this.renderByCondition(!!this.state.subCategoryLabel,
              <Text style={[styles.textStyle, { color: this.getColorTheme("light", "black", "white") }]}>{this.state.categoryLabel}</Text>,<></>)
          }
          <NewDropDown placeholder={this.categoryLabel()}
            data={this.state.categoryArray != undefined ? this.state.categoryArray : []}
            onSelect={(selectedItem: any, index: number) => {
              console.warn("dropdown1")
              this.onChangeCategories(selectedItem, index);
              this.props.value.onChangeCategories(selectedItem, index)
            }}
            width={width - 30} mode={this.props.mode} />

        </View>
        ,
        this.renderByCondition((this.state.categoryArray != undefined && this.state.categoryArray.length != 0),
          <SelectDropdown
            data={this.state.categoryArray != undefined ? this.state.categoryArray : []}
            ref={this.dropdownRef}
            onSelect={(selectedItem: any, index: number) => {
              console.warn("dropdown2")
              this.onChangeCategories(selectedItem.name, index);
              this.props.value.onChangeCategories(selectedItem.name, index)
            }}


            defaultButtonText={this.subCategoryLabel()}
            buttonTextAfterSelection={(itemSelected: any) => {
              return itemSelected.name;
            }}
            rowTextForSelection={(item1: any) => {
              return item1.name;
            }}
            buttonStyle={this.state.subCategoryLabel ? styles.dropdownIndustryStyle : styles.dropdown1BtnStyle}
            buttonTextStyle={{ color: this.state.backColor, textAlign: "left" }}
            renderDropdownIcon={(isOpen1: boolean) => {

              return (
                <>

                  <Icon
                    name={this.careDownUp(isOpen1)}
                    color={"#444"}
                    size={10}
                  />
                  {!!this.state.subCategoryLabel &&
                    <TouchableOpacity
                      style={styles.closeIcon}
                      onPress={() => { this.setState({ addMoreBtn: true, subCategoryLabel: '', categoryLabel: '' }) }}
                    >
                      <Icon
                        name={'close'}
                        color={"#444"}
                        size={15}
                      />
                    </TouchableOpacity>
                  }


                </>

              );
            }}
          />, <></>))
    )
  }

  socialEngBySymbolFxn() {
    return (
      this.renderByCondition(this.state.socialEngArray.length !== 0 &&
        this.state.socialEngLabel !== '',
        <View style={{
          marginTop: 5,
          backgroundColor: this.renderByCondition(this.state.socialEngLabel, "#F7F7F7", '#fff'),
          borderRadius: this.renderByCondition(this.state.socialEngLabel, 8, 0)
        }}
          testID="socialEngBySymbolFxn">
          {
            this.renderByCondition(this.state.subsocialEngLabel !== '',
              <Text style={styles.textStyle}>{this.state.socialEngLabel}</Text>,<></>)
          }
          <CustomDropDown
            data={this.state.socialEngArray}
            ref={this.dropdownRef}
            onSelect={(selectedItem: any, index: number) => {
              this.onChangeSocialEng(selectedItem, index);
            }}
            defaultButtonText={this.socialEngText()}
            buttonTextAfterSelection={(selectedItem: any) => {
              return selectedItem;
            }}
            rowTextForSelection={(item: any) => {
              return item;
            }}
            buttonStyle={this.renderByCondition(this.state.subsocialEngLabel, styles.dropdownIndustryStyle, styles.dropdown1BtnStyle)}
            buttonTextStyle={{ color: this.state.backColor, textAlign: "left" }}
            renderDropdownIcon={(isOpened: boolean) => {
              return (
                <>
                  <Icon
                    name={this.careDownUp(isOpened)}
                    color={"#444"}
                    size={10}
                  />
                  {
                    this.renderByCondition(this.state.subsocialEngLabel,
                      <TouchableOpacity
                        style={styles.closeIcon}
                        onPress={() => this.setState({ subsocialEngLabel: '', socialEngLabel: '' })}>
                        <Icon
                          name={'close'}
                          color={"#444"}
                          size={15}
                        />
                      </TouchableOpacity>,<></>)
                  }
                </>
              );
            }}
          />
        </View>, this.renderByCondition(this.state.socialEngArray.length != 0,
          <View testID="dropdownlb">
            <CustomDropDown
              data={this.state.socialEngArray}
              ref={this.dropdownRef}
              onSelect={(selectedItem: any, index: number) => {
                this.onChangeSocialEng(selectedItem, index);

              }}
              defaultButtonText={this.socialEngText()}
              buttonTextAfterSelection={(selectedItem1: any,) => {
                return selectedItem1;
              }}
              rowTextForSelection={(item2: any) => {
                return item2;
              }}
              buttonStyle={this.state.subsocialEngLabel ? styles.dropdownIndustryStyle : styles.dropdown1BtnStyle}
              buttonTextStyle={{ color: this.state.backColor, textAlign: "left" }}
              renderDropdownIcon={(isOpened2: boolean) => {
                return (
                  <>
                    <Icon
                      name={this.careDownUp(isOpened2)}
                      color={"#444"}
                      size={10}
                    />
                    {
                      this.state.subsocialEngLabel !== '' &&

                      <TouchableOpacity
                        style={styles.closeIcon}
                      >
                        <Icon
                          name={'close'}
                          color={"#444"}
                          size={15}
                        />
                      </TouchableOpacity>
                    }
                  </>
                );
              }}
            />
          </View>,<></>))
    )
  }
  marketcapitlization = () => {
    return (this.renderByCondition(this.state.market_capitalization,
      <View style={{ marginTop: 10 }}>
        <View style={[styles.maketCapStyle, {
          backgroundColor: this.getColorTheme('light',
            theme.light.headeBg, theme.dark.headeBg)
        }]}>
          <Text style={[styles.marketCapText, {
            color: this.getColorTheme('light',
              "#000", "#FFF")
          }]}>{"Market Capitalization"}</Text>
          <TouchableOpacity
            testID="market_capitalization_btn"
            onPress={() => this.setState({ market_capitalization: false, fundamentalArray: [...this.state.fundamentalArray, "Market Capitalization"] })}
            style={{ marginRight: 10 }}>
            <Icon
              name={'close'}
              color={"#444"}
              size={15}

            />
          </TouchableOpacity>

        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 10 }}>

          <View style={[styles.fromTextInput, {
            backgroundColor: this.getColorTheme('light',
              "#FFF", "#000")
          }]}>
            {
              this.state.market_from != '' &&
              <Text style={[styles.subText, {
                color: this.getColorTheme('light',
                  "#000", "#FFF")
              }]}>{"From"}</Text>
            }
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              {
                this.state.market_from != "" ?
                  <Text style={{
                    color: this.getColorTheme('light',
                      "#000", "#FFF"), fontSize: 14, alignSelf: "center", fontFamily: 'PlusJakartaText-Bold', marginLeft: 10
                  }}>{"$"}</Text> : null
              }
              <TextInput
                placeholder={"From"}
                placeholderTextColor={this.getColorTheme('light',
                  "#000", "#FFF")}
                value={this.state.market_from.toString()}
                onChangeText={text => {
                  this.setState({
                    market_from: text.replace(/[^0-9]/g, "")
                  })
                }}
                autoCapitalize={'words'}
                keyboardType={'numeric'}
                onSubmitEditing={() => { this.secondTextInput.focus(); }}
                blurOnSubmit={false}
                testID="market_from"
                style={{
                  textAlign: 'center', fontWeight: 'bold', color: this.getColorTheme('light',
                    "#000", "#FFF")
                }}
              />
            </View>
          </View>
          <View style={[styles.fromTextInput, {
            backgroundColor: this.getColorTheme('light',
              "#FFF", "#000")
          }]}>
            {
              this.state.market_to != '' &&
              <Text style={[styles.subText, {
                color: this.getColorTheme('light',
                  "#000", "#FFF")
              }]}>{"To"}</Text>

            }
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              {
                this.state.market_to != "" ?
                  <Text style={{
                    color: this.getColorTheme('light',
                      "#000", "#FFF"), fontSize: 14, alignSelf: "center", fontFamily: 'PlusJakartaText-Bold', marginLeft: 10
                  }}>{"$"}</Text> : null
              }
              <TextInput
                placeholder={'To'}
                placeholderTextColor={this.getColorTheme('light',
                  "#000", "#FFF")}
                value={this.state.market_to.toString()}
                ref={(input) => { this.secondTextInput = input; }}
                onChangeText={text => {
                  this.setState({
                    market_to: text.replace(/[^0-9]/g, "")
                  }, () => { this.props.onClickMarCap(this.state.market_from, this.state.market_to, "MarketCapitalization") })
                }}
                autoCapitalize={'words'}
                keyboardType={'numeric'}
                testID="market_to"
                style={{
                  textAlign: 'center', fontWeight: 'bold', color: this.getColorTheme('light',
                    "#000", "#FFF")
                }}
              />
            </View>
          </View>
        </View>

      </View>,<></>))
  }
  transactionCount() {
    return (

      this.renderByCondition(this.state.transaction_count,
      <View style={{ marginTop: 10 }}>
        <View style={styles.maketCapStyle}>
          <Text style={styles.marketCapText}>{"Transaction Count"}</Text>
          <TouchableOpacity
            testID="transaction_count_Btn"
            onPress={() => this.setState({ transaction_count: false })}
            style={{ marginRight: 10 }}>
            <Icon
              name={'close'}
              color={"#444"}
              size={15}
            />
          </TouchableOpacity>

        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 10 }}>

          <View style={[styles.fromTextInput,]}>
            {
              this.renderByCondition(this.state.transactionFrom != '' ,
              <Text style={styles.subText}>{"From"}</Text>,<></>)
            }
            <TextInput
              placeholder={'From'}
              value={this.state.transactionFrom}
              onChangeText={text => {
                this.setState({
                  transactionFrom: text
                })
              }}
              autoCapitalize={'words'}
              keyboardType={'numeric'}
              maxLength={3}
              testID="transactionFrom"
              style={{ textAlign: 'center', fontWeight: 'bold', }}
            />
          </View>
          <View style={[styles.fromTextInput,]}>
            {
              this.renderByCondition(this.state.transactionTo != '' ,
              <Text style={styles.subText}>{"To"}</Text>,<></>)

            }
            <TextInput
              placeholder={'To'}
              value={this.state.transactionTo}
              onChangeText={text => {
                this.setState({
                  transactionTo: text
                })
              }}
              autoCapitalize={'words'}
              keyboardType={'numeric'}
              maxLength={3}
              testID="transactionTo"
              style={{ textAlign: 'center', fontWeight: 'bold', }}
            />
          </View>
        </View>

      </View>,<></>)
    )
  }

  transactionValue() {
    return (

      this.renderByCondition(this.state.transacion_value,
      <View style={{ marginTop: 10 }}>
        <View style={styles.maketCapStyle}>
          <Text style={styles.marketCapText}>{"Transaction Value"}</Text>
          <TouchableOpacity
            testID="transacion_value_Btn"
            onPress={() => this.setState({ transacion_value: false })}
            style={{ marginRight: 10 }}>
            <Icon
              name={'close'}
              color={"#444"}
              size={15}

            />
          </TouchableOpacity>

        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 10 }}>

          <View style={[styles.fromTextInput,]}>
            {
              this.renderByCondition(this.state.transactionValueFrom != '' ,
              <Text style={styles.subText}>{"Form"}</Text>,<></>)

            }
            <TextInput
              placeholder={'From'}
              value={this.state.transactionValueFrom}
              onChangeText={text => {
                this.setState({
                  transactionValueFrom: text
                })
              }}
              autoCapitalize={'words'}
              keyboardType={'numeric'}
              maxLength={3}
              testID="transactionValueFrom"
              style={{ textAlign: 'center', fontWeight: 'bold', }}
            />
          </View>
          <View style={[styles.fromTextInput,]}>
            {
              this.renderByCondition(this.state.transactionValueTo != '' ,
              <Text style={styles.subText}>{"To"}</Text>,<></>)
            }
            <TextInput
              placeholder={'To'}
              value={this.state.transactionValueTo}
              onChangeText={text => {
                this.setState({
                  transactionValueTo: text
                })
              }}
              autoCapitalize={'words'}
              keyboardType={'numeric'}
              maxLength={3}
              testID="transactionValueTo"
              style={{ textAlign: 'center', fontWeight: 'bold', }}
            />
          </View>
        </View>

      </View>,<></>)
    )
  }

  feesPaidFxn() {
    return (

      this.renderByCondition(this.state.feesPaidArray.length != 0 ,
      <View style={{ marginTop: 10 }} testID="feesPaidFxn">
        <CustomDropDown
          data={this.state.feesPaidArray}
          ref={this.dropdownRef}

          onSelect={(selectedItem: any, index: number) => {
            this.setState({ defaultButtonText: selectedItem, addMoreBtn: true, })
            this.onChange(index, selectedItem);

          }}
          defaultButtonText={'Select filter'}
          buttonTextAfterSelection={(selectedItem: any) => {
            return selectedItem;
          }}
          rowTextForSelection={(item: any) => {
            return item;
          }}
          buttonStyle={styles.dropdown1BtnStyle}
          buttonTextStyle={[styles.dropdown1BtnTxtStyle, { color: 'gray' }]}
          renderDropdownIcon={(isOpened: boolean) => {
            return (
              <>
                <Icon
                  name={this.careDownUp(isOpened)}
                  color={"#444"}
                  size={10}
                />
                {
                  this.state.topText === true &&
                  <Icon
                    name={'close'}
                    color={"#444"}
                    size={10}
                  />
                }
              </>

            );
          }}
        />
      </View>,<></>)
    )
  }

  activeAddressFxn() {
    return (
      this.renderByCondition(this.state.active_address,
      <View style={{ marginTop: 10 }}>
        <View style={styles.maketCapStyle}>
          <Text style={styles.marketCapText}>{"Active address"}</Text>
          <TouchableOpacity
            testID="active_address_btn"
            onPress={() => this.setState({ active_address: false })}
            style={{ marginRight: 10 }}>
            <Icon
              name={'close'}
              color={"#444"}
              size={15}

            />
          </TouchableOpacity>

        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 10 }}>

          <View style={[styles.fromTextInput,]}>
            {
              this.renderByCondition(this.state.activeFrom != '' ,
              <Text style={styles.subText}>{"Form"}</Text>,<></>)

            }
            <TextInput
              placeholder={'From'}
              value={this.state.activeFrom}
              onChangeText={text => {
                this.setState({
                  activeFrom: text
                })
              }}
              autoCapitalize={'words'}
              keyboardType={'numeric'}
              maxLength={3}
              testID="activeFrom"
              style={{ textAlign: 'center', fontWeight: 'bold', }}
            />
          </View>
          <View style={[styles.fromTextInput,]}>
            {
              this.renderByCondition(this.state.activeTo != '' ,
              <Text style={styles.subText}>{"To"}</Text>,<></>)

            }
            <TextInput
              placeholder={'To'}
              value={this.state.activeTo}
              onChangeText={text => {
                this.setState({
                  activeTo: text
                })
              }}
              autoCapitalize={'words'}
              keyboardType={'numeric'}
              maxLength={3}
              testID="activeTo"
              style={{ textAlign: 'center', fontWeight: 'bold', }}
            />
          </View>
        </View>

      </View>,<></>)
    )
  }
  hashRate() {
    return (
      this.renderByCondition(this.state.hash_rate ,
      <View style={{ marginTop: 10 }}>
        <View style={styles.maketCapStyle}>
          <Text style={styles.marketCapText}>{"Hash rate"}</Text>
          <TouchableOpacity
            testID="hashRate_Btn"
            onPress={() => this.setState({ hash_rate: false })}
            style={{ marginRight: 10 }}>
            <Icon
              name={'close'}
              color={"#444"}
              size={15}

            />
          </TouchableOpacity>

        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 10 }}>

          <View style={[styles.fromTextInput,]}>
            {
              this.renderByCondition(this.state.hashrateFrom != '' ,
              <Text style={styles.subText}>{"Form"}</Text>,<></>)

            }
            <TextInput
              placeholder={'From'}
              value={this.state.hashrateFrom}
              onChangeText={text => {
                this.setState({
                  hashrateFrom: text
                })
              }}
              autoCapitalize={'words'}
              keyboardType={'numeric'}
              maxLength={3}
              testID="hashrateFrom"
              style={{ textAlign: 'center', fontWeight: 'bold', }}
            />
          </View>
          <View style={[styles.fromTextInput,]}>
            {
              this.renderByCondition(this.state.hashrateTo != '' ,
              <Text style={styles.subText}>{"To"}</Text>,<></>)

            }
            <TextInput
              placeholder={'To'}
              value={this.state.hashrateTo}
              onChangeText={text => {
                this.setState({
                  hashrateTo: text
                })
              }}
              autoCapitalize={'words'}
              keyboardType={'numeric'}
              maxLength={3}
              testID="hashrateTo"
              style={{ textAlign: 'center', fontWeight: 'bold', }}
            />
          </View>
        </View>

      </View>,<></>)
    )
  }

  marketValueRealizedValueFxn() {
    return (
      this.renderByCondition(this.state.market_value_to_realized_value ,
      <View style={{ marginTop: 10 }}>
        <View style={styles.maketCapStyle}>
          <Text style={styles.marketCapText}>{"Market value to realized value"}</Text>
          <TouchableOpacity
            testID="marketRealized-Btn"
            onPress={() => this.setState({ market_value_to_realized_value: false })}
            style={{ marginRight: 10 }}>
            <Icon
              name={'close'}
              color={"#444"}
              size={15}

            />
          </TouchableOpacity>

        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 10 }}>

          <View style={[styles.fromTextInput,]}>
            {
              this.renderByCondition(this.state.marketValueFrom != '' ,
              <Text style={styles.subText}>{"Form"}</Text>,<></>)

            }
            <TextInput
              placeholder={'From'}
              value={this.state.marketValueFrom}
              onChangeText={text => {
                this.setState({
                  marketValueFrom: text
                })
              }}
              autoCapitalize={'words'}
              keyboardType={'numeric'}
              maxLength={3}
              testID="marketValueFrom"
              style={{ textAlign: 'center', fontWeight: 'bold', }}
            />
          </View>
          <View style={[styles.fromTextInput,]}>
            {
              this.renderByCondition(this.state.marketValueTo != '' ,
              <Text style={styles.subText}>{"To"}</Text>,<></>)

            }
            <TextInput
              placeholder={'To'}
              value={this.state.marketValueTo}
              onChangeText={text => {
                this.setState({
                  marketValueTo: text
                })
              }}
              autoCapitalize={'words'}
              keyboardType={'numeric'}
              maxLength={3}
              testID="marketValueTo"
              style={{ textAlign: 'center', fontWeight: 'bold', }}
            />
          </View>
        </View>
      </View>,<></>)
    )
  }

  netValueTransactionFxn() {
    return (
      this.renderByCondition(this.state.net_value_transaction_ratio ,
      <View style={{ marginTop: 10 }}>
        <View style={styles.maketCapStyle}>
          <Text style={styles.marketCapText}>{"Net value transaction ratio"}</Text>
          <TouchableOpacity
            testID='net-btn'
            onPress={() => this.setState({ net_value_transaction_ratio: false })}
            style={{ marginRight: 10 }}>
            <Icon
              name={'close'}
              color={"#444"}
              size={15}

            />
          </TouchableOpacity>

        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 10 }}>

          <View style={[styles.fromTextInput,]}>
            {
              this.renderByCondition(this.state.nateRatioFrom != '' ,
              <Text style={styles.subText}>{"Form"}</Text>,<></>)

            }
            <TextInput
              placeholder={'From'}
              value={this.state.nateRatioFrom}
              onChangeText={text => {
                this.setState({
                  nateRatioFrom: text
                })
              }}
              autoCapitalize={'words'}
              keyboardType={'numeric'}
              maxLength={3}
              testID="nateRatioFrom"
              style={{ textAlign: 'center', fontWeight: 'bold', }}
            />
          </View>
          <View style={[styles.fromTextInput,]}>
            {
              this.renderByCondition(this.state.nateRatioTo != '' ,
              <Text style={styles.subText}>{"To"}</Text>,<></>)

            }
            <TextInput
              placeholder={'To'}
              value={this.state.nateRatioTo}
              onChangeText={text => {
                this.setState({
                  nateRatioTo: text
                })
              }}
              autoCapitalize={'words'}
              keyboardType={'numeric'}
              maxLength={3}
              testID="nateRatioTo"
              style={{ textAlign: 'center', fontWeight: 'bold', }}
            />
          </View>
        </View>
      </View>,<></>)
    )
  }

  addMoreFilterFxn() {
    return (
      this.renderByCondition(this.state.addMoreFilter ,
      <View style={{ marginTop: 10 }} testID="addMoreFilterFxn">
        <NewDropDown placeholder={'Select filter'}
          data={this.state.fundamentalArray}
          onSelect={(selectedItem: any, index: number) => {
            this.setState({ defaultButtonText: selectedItem, addMoreBtn: false, addMoreFilter: false })
            this.onChange(index, selectedItem);

          }}
          width={width} mode={this.props.mode} />
      </View>,<></>)
    )
  }

  industorylabelFxn() {
    return (
      this.renderByCondition(this.state.industryLabel.length != '' ,
      <View style={{
        marginTop: 5,
        backgroundColor: this.state.subIndusrtyLable ? "#F7F7F7" : '#fff',
        borderRadius: this.state.subIndusrtyLable ? 8 : 0

      }}
        testID="industorylabelFxn">
        {
          this.renderByCondition(this.state.subIndusrtyLable.length != 0 ,
          <Text style={styles.textStyle}>{this.state.industryLabel}</Text>,<></>)

        }
        <CustomDropDown
          data={this.state.industryArray}
          ref={this.dropdownRef}

          onSelect={(selectedItem: any, index: number) => {
            this.onChange(index, selectedItem);
          }}
          defaultButtonText={this.renderByCondition(this.state.subIndusrtyLable != '' , this.state.subIndusrtyLable , this.state.industryLabel)}
          buttonTextAfterSelection={(selectedItem: any) => {
            return selectedItem;
          }}
          rowTextForSelection={(item: any) => {
            return item;
          }}
          buttonStyle={[this.renderByCondition(this.state.industryLabel , styles.dropdownIndustryStyle , styles.dropdown1BtnStyle)]}
          buttonTextStyle={{ color: this.state.backColor, textAlign: "left" }}
          renderDropdownIcon={(isOpen: boolean) => {
            return (
              <>
                <Icon
                  name={this.careDownUp(isOpen)}
                  color={"#444"}
                  size={10}
                />
                {
                  this.renderByCondition(this.state.subIndusrtyLable !== '' ,
                  <TouchableOpacity
                    style={styles.closeIcon}
                    onPress={() => this.onClickClearindustry()}>
                    <Icon
                      name={'close'}
                      color={"#444"}
                      size={15}
                    />
                  </TouchableOpacity>,<></>)
                }
              </>

            );
          }}
        />
      </View>,<></>)
    )
  }

  dropDownIcon = (isOpened3: boolean) => {
    return (
      <View style={{ paddingRight: 15 }}>
        <Icon
          name={this.careDownUp(isOpened3)}
          color={"#444"}
          size={14}
        />
      </View>
    )
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <View style={styles.container}>
        <View style={{ width: width / 1.1, marginBottom: 10 }}>
          {
            this.getByCondition(this.state.defaultButtonText , 'Select a filter' ,
            <NewDropDown placeholder={this.state.defaultButtonText}
              data={this.state.fundamentalArray}
              onSelect={(selectedItem: any, index: number) => {
                this.setState({ defaultButtonText: selectedItem })
                this.onChange(index, selectedItem);
              }}
              width={width} mode={this.props.mode} />,
              <></>)

          }
          {this.rankArray()}
          {this.industoryFxn()}
          {this.industorylabelFxn()}
          {this.categoryFxn()}
          {this.socialEngBySymbolFxn()}
          {this.marketcapitlization()}
          {this.transactionCount()}
          {this.transactionValue()}
          {this.feesPaidFxn()}
          {this.activeAddressFxn()}
          {this.hashRate()}
          {this.netValueTransactionFxn()}
          {this.marketValueRealizedValueFxn()}
          {this.addMoreFilterFxn()}
          {this.renderByCondition(this.state.fundamentalArray.length > 0 &&
            this.state.addMoreBtn ,
            <View style={{ marginVertical: 10, padding: 10 }}>
              <Text testID="addMoreFilterTxt" onPress={() => this.setState({ addMoreFilter: true })} style={styles.plusBtn}>{"+  Add more fundamental filters"}</Text>
            </View>,<></>)
          }
          <View style={{ flexDirection: "row", marginLeft: "auto", marginRight: "auto" }} >
            <TouchableOpacity
              testID="btnExample"
              onPress={
                () => {
                  this.props.save()
                }
              }
              style={[styles.saveBtn, { marginRight: 15 }]}>
              <CustomText style={styles.saveTxt}>{this.SaveBtn}</CustomText>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={
                () => {
                  this.props.saveApply()
                }
              }
              style={styles.saveBtn}>
              <CustomText style={styles.saveTxt}>Save & Apply</CustomText>
            </TouchableOpacity>

          </View>
        </View>
      </View>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center'
  },
  dropdown1BtnStyle: {
    width: width / 1.1,
    height: 60,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: "#fff",
  },
  saveBtn: { marginTop: 6, height: 35, width: 100, backgroundColor: "green", alignItems: "center", justifyContent: "center" },
  saveTxt: { fontSize: 15, color: "black" },
  dropdown1BtnTxtStyle: { color: "gray", textAlign: "left" },
  plusBtn: {
    color: '#5F966C',
    fontSize: 18
  },
  maketCapStyle: {
    height: height / 13,
    borderRadius: 10,
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center'
  },
  fromTextInput: {
    borderRadius: 10,
    width: width / 2.3,
    height: height / 13,
    textAlign: 'center', justifyContent: 'center', alignItems: 'center', padding: 10
  },
  marketCapText: {
    marginLeft: 10, textAlign: 'center',
    fontSize: 18,
    fontFamily: "PlusJakartaText-Regular",
  },
  subText: {
    fontFamily: 'PlusJakartaText-Regular'
  },
  customStyle: {
    width: width / 1.1,
    flexDirection: 'row', alignItems: 'center',
  },
  customInput: {
    width: width / 1.2,
    marginLeft: 5
  },
  ViewStyle: {
    width: width / 1.1,
    borderRadius: 10
  },
  textStyle: {
    marginTop: 5,
    marginLeft: 15,
  },
  dropdownIndustryStyle: {
    width: width / 1.1,
    height: 60,
  },
  closeIcon: {
    marginRight: 10
  },
})
// Customizable Area End
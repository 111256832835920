import React from "react";
// Customizable Area Start
import PrivacyPolicyController, {
    Props
} from "./PrivacyPolicyController.web";

import {
    withStyles, StyleRules
} from "@material-ui/core/styles";

import Paper from '@material-ui/core/Paper';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import Button from '@material-ui/core/Button';

import Typography from '@material-ui/core/Typography';
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

const styles: StyleRules = {
    // Customizable Area Start
    Root: {
        padding: "10px 22px 0px",
    },
    Containor: {
        padding: "30px 50px 10px",
        '@media (max-width: 600px)': {
            padding: '10px'
        }
    },
    MainTitle: {
        fontWeight: 'bold',
        fontSize: '24px',
        display: 'flex',
        justifyContent: 'center'
    },
    Title: {
        fontFamily: 'Plus Jakarta Text',
    },
    Description: {
        fontFamily: 'Plus Jakarta Text',
        margin: '10px 0',
        whiteSpace: 'break-spaces'
    },
    Details: {
        fontFamily: 'Plus Jakarta Text',
        whiteSpace: 'pre-wrap',
        marginBottom: '10px'
    }
    // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
export class PrivacyPolicyWeb extends PrivacyPolicyController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { privacyPolicy, mainContent } = this.state;
        
        return (
            <Paper className={classes.Root} elevation={0} square data-testid='subscriptionPaper' style={{ backgroundColor: localStorage.getItem('appTheme') == 'ligthTheme' ? '#F4F8F6' : '#232429' }}>
                <Loader loading={this.state.Loading} />
                <Paper className={classes.Containor}>
                    <Button
                        variant="contained"
                        data-testid="privacy-btn"
                        startIcon={<ArrowLeftIcon />}
                        style={{ background: 'rgba(1,162,78,255)', color: '#ffffff', textTransform: 'none', marginBottom: '10px' }}
                        onClick={() => window.location.href = "/"}
                    >
                        Back
                    </Button>
                    <Typography variant="body1" className={classes.MainTitle}>
                        {`Privacy Policy`}
                    </Typography>
                    
                    <div>
                        <pre className={classes.Description}>{mainContent?.attributes?.description}</pre>
                        { privacyPolicy.map((policy: any) => 
                            <div className={classes.Details}>
                            <Typography variant="h6" style={{ fontWeight: 'bold' }}>{policy.attributes.title}</Typography>
                            <pre className={classes.Description}>{policy.attributes.description}</pre>
                            </div>
                        ) }
                    </div>
                </Paper>
            </Paper>
        )
        // Customizable Area End
    }
}

export default withStyles(styles)(PrivacyPolicyWeb);

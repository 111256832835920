import Toast from 'react-native-toast-message';

export default class ToastHandler {

    static show = (message: string, isError: boolean) => {
        Toast.show({
            text1: message,
            type: isError ? 'error' : 'success',
            position: 'bottom',
            visibilityTime: 3000,
            autoHide: true,
            bottomOffset: 100
        });
        // NOTE: Usage of this method 
        // ToastHandler.show('Success toast message', false);
    }

    static showTop = (message: string, isError: boolean) => {
        Toast.show({
            text1: message,
            type: isError ? 'error' : 'success',
            position: 'top',
            visibilityTime: 3000,
            autoHide: true,
            topOffset: 100
        });
        // NOTE: Usage of this method 
        // ToastHandler.show('Success toast message', false);
    }

    static error = () => {
        Toast.show({
            text1: 'ERROR: Something went wrong',
            type: 'error',
            position: 'bottom',
            visibilityTime: 3000,
            autoHide: true,
            bottomOffset: 200
        });
        // NOTE: Usage of this method 
        // ToastHandler.error();
    }

    static errorAtTop = () => {
        Toast.show({
            text1: 'ERROR: Something went wrong',
            type: 'error',
            position: 'top',
            visibilityTime: 3000,
            autoHide: true,
            topOffset: 200
        });
        // NOTE: Usage of this method 
        // ToastHandler.error();
    }

}


import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { toast } from 'react-toastify';
import moment from "moment";
import './ToastProvider.web.css';
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: boolean;
    token: any;
    editCardStatus: any;
    expMonth: any;
    expDate: any;
    expYear: any;
    cardInitialState: any;
    cardIsPrimary: boolean;
    cardData: any;
    cardNumberError: any;
    cvvError: any;
    expMonthError: any;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class ManageCardsWebController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    getCardsApiCallId: string="";
    createOrUpdateCardsApiCallId: string="";
    deleteApiCallId: string="";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.createOrUpdateCardDetailsFlag = this.createOrUpdateCardDetailsFlag.bind(this);
        this.deleteCard = this.deleteCard.bind(this);
        this.isCardPrimaryChange = this.isCardPrimaryChange.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            loading: false,
            token: localStorage.getItem('token') || "",
            editCardStatus: 'GRID',
            expMonth: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
            expDate: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
            expYear: this.getYears(),
            cardInitialState: {},
            cardIsPrimary: false,
            cardData: [],
            cardNumberError: null,
            cvvError: null,
            expMonthError: null
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {


        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            runEngine.debugLog("API Message Recived", message);
            
            switch (apiRequestCallId) {
                case this.getCardsApiCallId:
                    this.manageGetCardsResponse(responseJson);
                    break;
                case this.createOrUpdateCardsApiCallId:
                    this.manageCreateUpdateCardResponse(responseJson);
                    break;
                case this.deleteApiCallId:
                    this.setState({ loading: false });
                    if (responseJson.message) {
                        toast.success("Card deleted successfully");
                        this.getCards();
                    } else {
                        if (responseJson.errors && responseJson.errors[0].token) {
                            toast.success(responseJson.errors[0].token);
                        }
                    }
                    break;
                default:
                    console.log("default case");
            }
        }
        // Customizable Area End
    }
    async componentDidMount() {
        // Customizable Area Start
        if (this.state.token != "") {
            this.getCards();
        }
        // Customizable Area End
    }
    // Customizable Area Start
    manageGetCardsResponse = (responseJson: any) => {
        this.setState({ loading: false });
        if (responseJson.data) {
            let tempArr = responseJson.data.filter((item : any) => !item?.attributes?.is_primary) || [];
            if (responseJson?.data.find((item : any) => item?.attributes?.is_primary) != undefined)
                tempArr.unshift(responseJson?.data.find((item : any) => item?.attributes?.is_primary))
            this.setState({
                cardData: tempArr
            })
        } else {
            if (responseJson.errors) {
                if (responseJson.errors[0].token) {
                    toast.error(responseJson?.errors[0].token);
                }
            }
        }
    }

    manageCreateUpdateCardResponse = (responseJson: any) => {
        this.setState({ loading: false });
        if (responseJson.data) {
            if (this.state.editCardStatus == 'ADD')
                toast.success("Card registered successfully");
            else
                toast.success("Card updated successfully");
            this.getCards();
            this.createOrUpdateCardDetailsFlag('GRID', {});
        } else {
            this.handleCardErrors(responseJson);
        }
    }

    handleCardErrors(responseJson: any) {
        if (responseJson.errors) {
            if (responseJson.errors[0].token) {
                toast.success(responseJson.errors[0].token);
            }
            if (responseJson.errors[0].card) {
                const cardErrors = responseJson.errors[0].card;
                cardErrors.forEach((error: string) => {
                    error.includes('Cvv') ? this.setState({ cvvError: error }) :
                    error.includes('Card') ? this.setState({ cardNumberError: error }) :
                    this.setState({ expMonthError: error });
                })
            }
        }
    }

    createOrUpdateCardDetailsFlag(flag: any, selectedCard: any) {
        this.setState({
            cardIsPrimary: selectedCard?.attributes?.is_primary,
            editCardStatus: flag,
            cardInitialState: flag === 'ADD' ? {} : selectedCard
        });
    }

    getCards() {
        this.setState({ loading: true });
        const header = { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getCardsApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_savedcards/cards");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    createOrUpdateCardDetails(values: any) {
        this.setState({ loading: true });
        const header = { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*", token: this.state.token };
        const httpBody = { data: values };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.createOrUpdateCardsApiCallId = requestMessage.messageId;
        if (this.state.editCardStatus == 'ADD') {
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_savedcards/cards");
        } else {
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_savedcards/cards/" + this.state.cardInitialState?.id);
        }
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    isCardPrimaryChange(event: any, selectedCard: any, flag: any) {
        selectedCard.attributes.is_primary = event.currentTarget.checked;
        this.setState({
            cardInitialState: selectedCard,
            editCardStatus: flag
        }, () => {
            if (flag == 'GRID')
                this.createOrUpdateCardDetails(this.state.cardInitialState.attributes);
        });
    }

    getYears() {
        const currentYear = moment().year();
        let yearsArr = [];
        for (let i = currentYear; i < currentYear + 50; i++) {
            yearsArr.push(i);
        }
        return yearsArr;
    }
    
    deleteCard(selectedCard: any) {
        // if (selectedCard?.attributes?.is_primary == true) {
        //     toast.error("Primary card can't be deleted");
        //     return;
        // }
        this.setState({ loading: true });
        const header = { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.deleteApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_savedcards/cards/" + selectedCard?.id);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "DELETE");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    // Customizable Area End
}

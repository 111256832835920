import React from "react";
// Customizable Area Start
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { removePlusMinusSymbol } from '../../../components/src/chartUtils.web';
// Customizable Area End
interface myProps {
    // Customizable Area Start
    rankResponseData: any;
    paginationPage: any;
    paginationRowsPerPage: any;
    openCategoryPopup: any;
    handleMorePopup: any;
    // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
export default function FundamentalRankResponse(props: myProps) {
    // Customizable Area Start
    return (
        <TableContainer>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell align="left" style={{ fontSize: '12px' }}>Name</TableCell>
                        <TableCell align="left" style={{ fontSize: '12px' }}>Price</TableCell>
                        <TableCell align="left" style={{ fontSize: '12px' }}>Category</TableCell>
                        <TableCell align="left" style={{ fontSize: '12px' }}>Market Cap</TableCell>
                        <TableCell align="left" style={{ fontSize: '12px' }}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(props.paginationRowsPerPage > 0
                        ? props.rankResponseData.slice(props.paginationPage * props.paginationRowsPerPage, props.paginationPage * props.paginationRowsPerPage + props.paginationRowsPerPage)
                        : props.rankResponseData
                    ).map((row: any, key: any) => (
                        <TableRow
                            key={row.id}
                        >
                            <TableCell component="th" scope="row">
                                {(props.paginationPage * props.paginationRowsPerPage) + (key + 1)}
                            </TableCell>
                            <TableCell align="left">
                                <CardHeader
                                    avatar={
                                        <Avatar
                                            src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${row.id}.png`}
                                            alt="Remy Sharp"
                                            style={{ width: '32px', height: '32px', cursor: 'pointer' }}
                                            onClick={() => props.handleMorePopup(row)}
                                        >
                                        </Avatar>
                                    }
                                    title={
                                        <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                                            {row?.name}
                                        </Typography>
                                    }
                                    subheader={
                                        <Typography gutterBottom variant="body2" component="div" style={{ color: '#c6cfca', fontWeight: 'bold', fontSize: '12px' }}>
                                            {row.symbol ? row.symbol : row.name.split(" ").pop()}
                                        </Typography>
                                    }
                                    style={{ padding: '0px' }}
                                />
                            </TableCell>
                            <TableCell align="left">
                                <div style={{ display: 'flex', flexDirection: 'column', whiteSpace: 'nowrap' }}>
                                    <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                                        {row.quote.USD.price}
                                    </Typography>
                                    <div style={{ display: 'flex', alignItems: "center" }}>
                                        {row.quote.USD.percent_change_24h.toString().charAt(0) === "-" ? <ArrowDropDownIcon style={{ color: '#E86A3F', fontSize: '12px', fontWeight: 'bold' }} /> : <ArrowDropUpIcon style={{ color: '#01A24E', fontSize: '12px', fontWeight: 'bold' }} />}
                                        <Typography
                                            gutterBottom
                                            variant="body2"
                                            component="div"
                                            style={{ color: row.quote.USD.percent_change_24h.toString().charAt(0) === "-" ? '#E86A3F' : '#01A24E', fontWeight: 'bold',  fontSize: '12px' }}
                                        >
                                            {removePlusMinusSymbol(row.quote.USD.percent_change_24h) + " (24H)"}
                                        </Typography>
                                    </div>
                                </div>
                            </TableCell>
                            <TableCell align="left">
                                <Typography data-testid={`openCategory${key+1}`} gutterBottom variant="body2" component="a" style={{ fontWeight: 'bold', fontSize: '12px', cursor: 'pointer', textDecoration: 'none', color: 'rgba(1,162,78,255)' }} onClick={(event: any) => {
                                    props.openCategoryPopup(row);
                                }}>
                                    {`View Category`}
                                </Typography>
                            </TableCell>
                            <TableCell align="left">
                                <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                                    {row?.quote?.USD?.market_cap}
                                </Typography>
                            </TableCell>
                            <TableCell align="left">
                                <Typography data-testid={`handleMore${key+1}`} gutterBottom variant="body2" component="a" style={{ fontWeight: 'bold', fontSize: '12px', cursor: 'pointer', textDecoration: 'underline', color: 'rgba(1,162,78,255)' }} onClick={(event: any) => {
                                    props.handleMorePopup(row);
                                }}>
                                    {`More`}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
    // Customizable Area End
}

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { toast } from "react-toastify";
import { setChartData } from "../../../components/src/chartUtils.web";
import { isEmpty } from "../../../framework/src/Utilities";
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    closeMore?: any;
    showHighLowPoints?: any;
    datapoints?: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: boolean;
    token: any;
    cryptoData: any;
    activeItem: any;
    open: boolean;
    cryptoId: any;
    chartTimeFrame: any;
    chartType: any;
    isChartToolTipVisible: boolean;
    chartToolTipTop: any;
    tooltipDate: any;
    tooltipTime: any;
    tooltipPrice: any;
    chartToolTipLeft: number;
    currentSelectedMoreData: any;
    priceActionPriceChartData: any;
    priceActionVolumeChartData: any;
    priceActionMarketCapChartData: any;
    priceActionAreaSeries: any;
    priceActionLineSeries: any;
    priceActionVolumeSeries: any;
    isItemInWatchlist: boolean;
    watchlistItemId: any;
    ShowMore: any;
    centerType: any;
    category: any;
    marketData: any;
    loadingArr: any;
    chartLoader: any;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class CryptoDetailWebController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    priceActionChartContainerRef: any;
    getCryptoDetailApiCallId: string = '';
    getGraphsApiCallId: string = '';
    createWathclistApiCallId: string = '';
    getWatchlistApiCallId: string = '';
    removeFromWatchlistApiCallId: string = '';
    priceActionChart: any;
    getSelectedFilterMarketApiCallId: any;
    // Customizable Area End
    
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // Customizable Area End
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];
        this.state = {
            // Customizable Area Start
            loading: true,
            token: localStorage.getItem('token') || "",
            cryptoData: undefined,
            chartTimeFrame: '1D',
            chartType: 'Price',
            activeItem: '',
            open: false,
            cryptoId: null,
            isChartToolTipVisible: false,
            chartToolTipTop: '',
            tooltipPrice: '',
            tooltipDate: '',
            tooltipTime: '',
            chartToolTipLeft: 10,
            currentSelectedMoreData: '',
            priceActionPriceChartData: [],
            priceActionVolumeChartData: [],
            priceActionMarketCapChartData: [],
            priceActionAreaSeries: null,
            priceActionLineSeries: null,
            priceActionVolumeSeries: null,
            isItemInWatchlist: false,
            watchlistItemId: null,
            ShowMore: false,
            centerType: 'all',
            category: 'spot',
            marketData: [],
            loadingArr: [],
            chartLoader: false
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {

        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.getCryptoDetailApiCallId) {
                const newArr = this.state.loadingArr.filter((item: any) => item !== 'cryptoDetails')
                this.setState({ loadingArr: newArr });
                this.setCryptoDetail(responseJson);
            }

            if (apiRequestCallId === this.getWatchlistApiCallId) {
                const newArr = this.state.loadingArr.filter((item: any) => item !== 'getWatchlist')
                this.setState({ loadingArr: newArr });
                this.setWatchlistData(responseJson);
            }

            if (apiRequestCallId === this.createWathclistApiCallId) {
                const newArr = this.state.loadingArr.filter((item: any) => item !== 'createWatchlist')
                this.setState({ loadingArr: newArr });
                this.updateWatchlistState(responseJson, true, `${this.state.cryptoData.name} added to watchlist`);
            }

            if (apiRequestCallId === this.removeFromWatchlistApiCallId) {
                const newArr = this.state.loadingArr.filter((item: any) => item !== 'removeWatchlist')
                this.setState({ loadingArr: newArr });
                this.updateWatchlistState(responseJson, false, `${this.state.cryptoData.name} removed from watchlist`);
            }

            if (apiRequestCallId === this.getGraphsApiCallId) {
                this.setState({ chartLoader: false });
                const response = setChartData(responseJson.data?.points)

                this.setState({
                    priceActionPriceChartData: response.chartPriceData,
                    priceActionVolumeChartData: response.chartVolumeData,
                    priceActionMarketCapChartData: response.chartMarketCapData
                })
            }

            if (apiRequestCallId === this.getSelectedFilterMarketApiCallId) {
                const newArr = this.state.loadingArr.filter((item: any) => item !== 'marketData')
                this.setState({ 
                    marketData: responseJson.data, 
                    loadingArr: newArr 
                });
            }
        }
        // Customizable Area End
    }

    async componentDidMount() {
        // Customizable Area Start
        let cryptoId = this.props.navigation ? this.props.navigation.getParam("id") : this.props.id;
        if (cryptoId != undefined) {
            this.setState({
                cryptoId
            }, () => {
                this.getCryptoDetail(cryptoId);
            })
        }
        this.getSelectedFilterMarketDetails(cryptoId, this.state.category, this.state.centerType)
        // Customizable Area End
    }

    // Customizable Area Start
    setWatchlistData = (responseJson: any) => {
        if (responseJson && responseJson.data) {
            const data = responseJson.data.filter((d: any) => d.attributes.crypto_id);
            const watchlistItem = data.length > 0 && data.find((watchlist: any) => watchlist.attributes.crypto_id.toString() === this.state.cryptoId.toString());
            this.setState({ isItemInWatchlist: !!watchlistItem, watchlistItemId: watchlistItem?.id });
        }
    }

    setCryptoDetail = (responseJson: any) => {
        if (responseJson.data) {
            this.setState({ cryptoData: responseJson.data[0] });
            if (!isEmpty(this.state.token))
                this.getWatchlist();
        }
    }

    updateWatchlistState = (responseJson: any, isItemInWatchlist: boolean, message: string) => {
        if (responseJson) {
            this.setState({ isItemInWatchlist, watchlistItemId: responseJson.data?.id });
            toast.success(message);
        }
    }

    centerTypeHandleChange = ( event: any, newAlignment: string ) => {
        this.setState({
            centerType: newAlignment || this.state.centerType
        });
        this.getSelectedFilterMarketDetails(this.state.cryptoId, this.state.category, newAlignment || this.state.centerType)
    };

    categoryHandleChange = ( event: any, newAlignment: string ) => {
        this.setState({
            category: newAlignment || this.state.category
        });
        this.getSelectedFilterMarketDetails(this.state.cryptoId, newAlignment || this.state.category, this.state.centerType)
    };

    loadMoreData = () => {
        this.setState(() => ({
            ShowMore: true
        }));
    }

    loadLessData = () => {
        this.setState(() => ({
            ShowMore: false
        }));
    }

    createWatchlistCrypto = () => {
        this.setState({ loadingArr: [...this.state.loadingArr, 'createWatchlist'] });
        const header = { "Content-Type": "application/json", token: this.state.token };
        const body = { crypto_id: this.state.cryptoId };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.createWathclistApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `/bx_block_watchlist/crypto_watchlists`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getWatchlist = () => {
        this.setState({ loadingArr: [...this.state.loadingArr, 'getWatchlist'] });
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getWatchlistApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `/bx_block_watchlist/crypto_watchlists`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    removeFromWatchlist() {
        this.setState({ loadingArr: [...this.state.loadingArr, 'removeWatchlist'] });
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.removeFromWatchlistApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `/bx_block_watchlist/crypto_watchlists/${this.state.watchlistItemId}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "DELETE");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getCryptoDetail(id: any) {
        this.setState({ loadingArr: [...this.state.loadingArr, 'cryptoDetails'] });
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getCryptoDetailApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_dashboard/get_crypto_info?crypto_id=${id}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getGraphData = (cryptoId: any, timeFrame: any) => {
        this.setState({ chartLoader: true });
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getGraphsApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_dashboard/cryptocurrency_chart?id=${cryptoId}&interval=${timeFrame}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getSelectedFilterMarketDetails = (id: any, category: any, centerType: any) => {
        this.setState({ ShowMore: false, loadingArr: [...this.state.loadingArr, 'marketData'] });
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getSelectedFilterMarketApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_dashboard/get_market_data?id=${id}&category=${category}&center_type=${centerType}` );
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleBackButtonClick = () => {
        if (this.props.navigation) {
            window.history.back();
        } else {
            this.props.closeMore();
        }
        
    }
    // Customizable Area End
}


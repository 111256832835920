import React from "react";
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { removePlusMinusSymbol } from './chartUtils.web';

export const UpDownIconButton = ({ value }: any) => {
    return (
        <div style={{ display: 'flex', alignItems: "center", marginLeft: '-12px' }}>
        {value.toString().charAt(0) === "-" ? <ArrowDropDownIcon style={{ color: '#E86A3F', fontSize: '12px', fontWeight: 'bold' }} /> : <ArrowDropUpIcon style={{ color: '#01A24E', fontSize: '12px', fontWeight: 'bold' }} />}
        <Typography
            gutterBottom
            variant="body2"
            component="div"
            style={{ color: value.toString().charAt(0) === "-" ? '#E86A3F' : '#01A24E', fontWeight: 'bold', fontSize: '12px' }}
        >
            {`${ removePlusMinusSymbol(value)}`}
        </Typography>
    </div>
    )
}


// Customizable Area Start
//@ts-nocheck
import React from "react";
import {
  Dimensions,
  FlatList,
  Image,
  Modal,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import Icon1 from "react-native-vector-icons/AntDesign";
import CustomeHeader from "../../../components/src/CustomeHeader";
import HelperClass from "../../../components/src/HelperClass";
import SavedFilterListModal from "../../../components/src/SavedFilterListModal";
import CustomText from "../../../components/src/text";
import { COLORS } from "../../../framework/src/Globals";
import Derivatives from './Derivatives';
import Fundamentals from "./Fundamentals";
import Indicators from "./Indicators";
import PriceAction from "./PriceAction";
import Volumes from "./Volumes";
import FilteritemsController, { Props } from "./FilteritemsController";
import ToastHandler from "../../../components/src/ToastHandler";
const { height }: any = Dimensions.get("screen");
// Customizable Area End
export default class Filteritems extends FilteritemsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  filterList = ({ item }: any) => {

    return (
      <>
        <TouchableOpacity
        testID="onPressfilterItem"
          style={styles.optionsView}
          onPress={() => this.onPressfilterItem(item)}
        >
          <View style={[styles.flexRow, styles.paddingHorizontal]}>
            <View style={styles.width50}>
              <View style={styles.flexRow}>
                <Image
                  style={styles.productImg}
                  source={
                    item?.isSelected === true ? item.active : item?.inactive
                  }
                />
                <View style={styles.itemNameView}>
                  <CustomText
                    style={[
                      styles.itemTxt,
                      { color: this.colorTxt(item) }
                    ]}
                  >
                    {item?.name}
                  </CustomText>
                </View>
              </View>
            </View>
            <View style={[styles.width50, styles.dropDownIcon1]}>
              <Icon1
                name={item?.isSelected === true ? "caretup" : "caretdown"}
                size={14}
                style={styles.sortIcon1}
                color={this.colorTxt(item)}
              />
            </View>
          </View>
        </TouchableOpacity>
        {item?.isSelected ? (
          // null
          this.onSelectTab(item)
          // <View style={styles.itemDescription}>
          //   <Text> Opened</Text>
          // </View>
        ) : null}
      </>
    );
  };

  onSelectTab(item: any) {

    switch (item.id) {
      case 1:
        // return   this.fundamentalFun()
        return (<Fundamentals {...this.props}
          //no need to use now

          onClick={(d: any) => this.onClickFilter(d)}
          // this props we are using
          value={{
            nameOfFilter: this.state.nameOfFilter, marketFrom: this.state.market_from, categoryValue: this.state.subCategoryLabel,
            rankValue: this.state.newData, marketTo: this.state.market_to, category: this.state.subCategoryLabel
            , onChangeCategories: this.onChangeCategories.bind(this),
            multipleFilters: this.state.filterTypeMap["fundamentalFilterName"]
          }}
          check={this.state.fundamentalCheck
          }
          save={() => { this.fundamentalSaveButton() }}
          saveApply={() => { this.fundamentalSaveandApplyButton() }}
          onClikcRank={(rank: any, name: any) => this.onClickRank(rank, name)}
          onClickMarCap={(from: any, to: any, name: any) => this.onClickMarCap(from, to, name)}
          mode={this.context.mode}
        />)
      case 2:
        return (<Volumes {...this.props}
          save={() => { this.volumeSaveButton() }}
          saveApply={() => { this.volumeSaveandApplyButton() }}
          value={{
            volumeOption: this.state.defaultVolumeButtonText,
            period: this.state.volumeDownPeriod ? this.state.volumeDownPeriod : this.state.volumeUpPeriod,
            length: this.state.volumeUpPeriodLength ? this.state.volumeUpPeriodLength : this.state.volumeDownPeriodLength,
            priceFrom: this.state.priceFrom,
            avgVolumeMinValue: this.state.avgVolumeMinValue,
            avgVolumeMaxValue: this.state.avgVolumeMaxValue,
          }}
          check={
            this.state.volumeCheck
          }
          mode={this.context.mode}
          onPriceRangePress={this.onPriceRangePress}
          onClickVolume={(name: any) => this.onClickVolume(name)}
          VolumePicker={(defaultVolumeButtonText: any, avgVolumeMinValue: any, avgVolumeMaxValue: any, priceFrom: any, volumeUpPeriodLength: any, volumeUpPeriod: any, volumeDownPeriod: any, volumeDownPeriodLength: any, randerid: any) => this.VolumePicker(defaultVolumeButtonText, avgVolumeMinValue, avgVolumeMaxValue, priceFrom, volumeUpPeriodLength, volumeUpPeriod, volumeDownPeriod, volumeDownPeriodLength, randerid = item.id)}
        />)
      case 3:
        return (<PriceAction {...this.props}
          save={() => { this.priceSaveButton() }}
          mode={
            this.context.mode
          }
          saveApply={() => { this.priceSaveandApplyButton() }}
          value={{
            mainOption: HelperClass.getPriceMainOption(), option: this.state.nameOfFilter, subOption: this.state.subOption
            , timeFrame: this.state.frameValue, percentage: this.state.parcentage,
          }}
          check={
            this.state.priceCheck
          }
          clearAll={() => { this.clearPriceSavedValues(true) }}
          onPriceHighLow={(filterName: any) => this.onPriceHighLow(filterName)}
          onPriceFilter={(frameValue: any, parcentage: any, filterName: any) => this.onPriceFilter(frameValue, parcentage, filterName)}
          onSupportResistance={(filtername: any, subfilter: any, timeFrame: any) => this.onSupportResistance(filtername, subfilter, timeFrame)}
          onSupportPattern={(filtername: any, subfilter: any, timeFrame: any) => this.onSupportPattern(filtername, subfilter, timeFrame)}
          onCandleStick={(filtername: any, subfilter: any, timeFramlable: any) => this.onCandleStick(filtername, subfilter, timeFramlable)}
        // onReservalPattern={()=>this.onReservalPattern()}
        />)
      case 4:
        return (<Indicators {...this.props}
          save={() => { this.indicatorSaveButton() }}
          saveApply={() => { this.indicatorSaveandApplyButton() }}
          onClickBox1={(cehrkBox: any) => this.onClickBox1(cehrkBox)}
          check={this.state.indicator}
          indicatorValue={this.state.apiBodyIndicatorObject}
          onSelectScreen={(filterName: any, selectindicator: any) => this.onSelectScreen(filterName, selectindicator)}
          onClickRadio={(name: any) => this.onClickRadio(name)}
          selectPeriod={(selectPeriodind: any) => this.selectPeriod(selectPeriodind)}
          selectlengthInd1={(selectlength: any) => this.selectlengthInd1(selectlength)}
          onSelectTimeFrame1={(selectTime: any) => this.onSelectTimeFrame1(selectTime)}
          OnClickrsiDivergence1={(selectDivergence: any) => this.OnClickrsiDivergence1(selectDivergence)}
          //MFI
          MFISelctedScreen={(MfiDivergenceItem: any, MFTimeFrame: any, mfiradioAbove: any, MFperiod: any, MFISelScreenName: any, MFlength: any) => this.MFISelctedScreen(MfiDivergenceItem, MFTimeFrame, mfiradioAbove, MFperiod, MFISelScreenName, MFlength)}
          //StochasticPicker
          StochasticPicker={(StoDivergenceItem: any, stochasticTimeFrame: any, stochasticradioAbove: any, stochasticPeriod: any, stochasticlabel: any, stochasticLength: any,) => this.StochasticPicker(StoDivergenceItem, stochasticTimeFrame, stochasticradioAbove, stochasticPeriod, stochasticlabel, stochasticLength)}
          //cciPicker

          cciPicker={(cciDivergenceItem: any, cciTimeFrame: any, cciradioAbove: any, ccilabel: any, cciPeriod: any) => this.cciPicker(cciDivergenceItem, cciTimeFrame, cciradioAbove, ccilabel, cciPeriod)}
          //mvgMvgAv
          mvAvgPicker={(mvAvgTimeFrame: any, mvAvgPeriod: any, movingAvgLabel: any, mvAvglabel: any, crossOverValue: any) => this.mvAvgPicker(mvAvgTimeFrame, mvAvgPeriod, movingAvgLabel, mvAvglabel, crossOverValue)}
          //macd
          macdPicker={(macdDivergenceitem: any, macdTimeFrame: any, macdradioAbove: any, macdlabel: any, macdPeriod: any,) => this.macdPicker(macdDivergenceitem, macdTimeFrame, macdradioAbove, macdlabel, macdPeriod,)}
          //adx
          adxPicker={(adxTimeFrame: any, adxradioAbove: any, adxlabel: any, adxPeriod: any, adxLength: any) => this.adxPicker(adxTimeFrame, adxradioAbove, adxlabel, adxPeriod, adxLength)}
          //obv
          obvPicker={(obvTimeFrame: any, divergenceLable: any, obvlabel: any, obvPeriod: any) => this.obvPicker(obvTimeFrame, divergenceLable, obvlabel, obvPeriod)}
          //cmf
          cmfPicker={(cmfTimeFrame: any, cmfradio: any, divergenceLablecmf: any, cmflabel: any, cmfPeriod: any) => this.cmfPicker(cmfTimeFrame, cmfradio, divergenceLablecmf, cmflabel, cmfPeriod)}
          //bolingerPickere
          BollingPicker={(bollingerTimeFrame: any, radioAbove: any, bollingerlabel: any) => this.BollingPicker(bollingerTimeFrame, radioAbove, bollingerlabel)}
          //atrPicker
          atrPicker={(atrTimeFrame: any, atrPeriod: any, atrlabel: any, trending_period: any) => this.atrPicker(atrTimeFrame, atrPeriod, atrlabel, trending_period)}
        />)
      case 5:
        return (<Derivatives {...this.props} />)
      default:
        alert("NUMBER NOT FOUND");

    }

  }


  separatorLine = () => {
    return (
      <View style={styles.lineSeparate} />
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <SafeAreaView style={styles.container}>
        <View
          style={{ flex: 1, flexGrow: 1, backgroundColor: this.getColorTheme("light", "#fff", "#000") }}
        >
          <View style={styles.topContainer}>
            <CustomeHeader
              {...this.props}
              onPress={() => this.props.navigation.toggleDrawer()}
              onsearchPress={() => this.props.navigation.navigate("SearchScreen")}
              onClickProfile={() =>
                this.props.navigation.navigate("Pushnotifications")
              }
            />
          </View>
          <ScrollView style={[styles.mainView, {
            backgroundColor:
              this.getColorTheme("light", "#fff", "#3C3F50")
          }]}>
            <ScrollView horizontal style={{ padding: 10 }}>
              <View style={{ flexDirection: "row", marginLeft: "auto", marginRight: "auto" }} >
                <View style={{ flexDirection: "row", alignSelf: "flex-end" }} >
                  <TouchableOpacity
                    testID="btnExample"
                    onPress={() => {
                      this.getSavedFilters();
                      this.setState({ showSavedFilterModal: true })
                    }}
                    style={[styles.saveBtn, { marginRight: 15 }]}>
                    <CustomText style={styles.saveTxt}>Reload Filters</CustomText>
                  </TouchableOpacity>
                  <TouchableOpacity
                    testID="btnExamples"
                    onPress={() => {
                      let count = 0
                      let arr = ["fundamental_filter_type","volume_filter_type","price_action_filter_type","indicator_filter_type"]
                      for (let i = 0; i < arr.length; i++) {
                        //@ts-ignore
                        if (this.state.filterTypeMap[arr[i]]) {
                          count = count + 1
                        }
                      }

                      if (count > 1) {
                        this.setState({ filterName: "" })
                        this.setState({ multipleSaveCheck: true })
                        this.setState({ saveFilterPopupVisible: true })
                      }
                      else {
                        ToastHandler.show("Please Select More than 1 filter", true);
                      }
                    }}
                    style={[styles.saveBtn, { marginRight: 15 }]}>
                    <CustomText style={styles.saveTxt}>Save Multiple Filter</CustomText>
                  </TouchableOpacity>
                  <TouchableOpacity
                    testID="btnExamplesa"
                    onPress={() => {
                      this.getSavedFilters(true);
                      this.setState({ showSavedFilterModal: true })
                      this.setState({ multipleSaveFilterReloadCheck: true })

                    }}
                    style={[styles.saveBtn, { marginRight: 15 }]}>
                    <CustomText style={styles.saveTxt}>Reload Multiple Filters</CustomText>
                  </TouchableOpacity>

                </View>
              </View>
            </ScrollView>

            <View style={[styles.flexRow, styles.header]}>

              <View>
                <CustomText style={[styles.filterHead, { color: this.getColorTheme('light', COLORS.black, COLORS.white) }]}>{this.FiltersTxt}</CustomText>
              </View>


            </View>

            <View style={styles.marginTop}>
              <FlatList
              testID="filterList"
                nestedScrollEnabled
                data={this.state.filterItems}
                renderItem={this.filterList}
                extraData={this.context.mode}
                ItemSeparatorComponent={this.separatorLine}
              />
            </View>
            <Modal
              transparent
              animationType="slide"
              visible={this.state.saveFilterPopupVisible}
            >
              <View style={styles.modalContainer}>
                <View style={styles.popup}>
                  <CustomText style={[styles.saveTxt, { marginLeft: "auto", marginRight: "auto", marginBottom: 10 }]}>Enter Filter Name</CustomText>

                  <TextInput
                  testID="Filter Value"
                    style={styles.input}
                    placeholder="Enter Filter Value"
                    value={this.state.filterName}
                    onChangeText={(text) => {
                      this.setState({ displayFilterNameErrText: "none" })
                      this.setState({ filterName: text })
                    }}
                  />
                  <CustomText
                    //@ts-ignore 
                    style={{ fontSize: 10, color: "red", display: this.state.displayFilterNameErrText }}>Please Enter Filter Name</CustomText>
                  <View style={styles.buttonContainer}>
                    <TouchableOpacity
                    testID="displayFilter"
                      style={{ backgroundColor: "green", marginTop: 10, height: 30, width: 60, justifyContent: "center", alignItems: "center", borderRadius: 5 }}
                      onPress={() => {
                        if (!this.state.filterName) {
                          this.setState({ displayFilterNameErrText: "flex" })
                          return;
                        }
                        if (this.state.multipleSaveCheck) {
                          this.saveMultipleFilters()
                        }
                        else {
                          if (this.state.apiBodyIndicatorObject.indicator_main_option != "") {
                            this.onClickSave(true);
                            // this.setState({filterName:""})
                            return;
                          }
                          this.saveFilterApiCall(this.state.filterName)
                          // this.setState({filterName:""})
                        }


                      }}
                    >
                      <CustomText style={{ color: "white" }}>Save</CustomText>
                    </TouchableOpacity>
                    <TouchableOpacity
                    testID="filterName"
                      style={{ backgroundColor: "green", height: 30, marginTop: 10, width: 60, justifyContent: "center", alignItems: "center", borderRadius: 5 }}
                      onPress={() => {
                        this.setState({ filterName: "" })
                        this.setState({ saveFilterPopupVisible: false })
                      }}
                    >
                      <CustomText style={{ color: "white" }}>Cancel</CustomText>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
            </Modal>
            {
              this.renderConditionalItem(this.state.showSavedFilterModal,
                <SavedFilterListModal
                  setmultipleFilterId={(id: any): any => {
                    this.navigateFunction({ multipleFilterId: id })
                  }}
                  setModalData={() => {
                    this.setState({ savedFilters: [] })
                  }}
                  setmultipleFilterCheck={() => {
                    this.setState({ multipleSaveFilterReloadCheck: false })
                  }}
                  multipleFilterCheck={this.state.multipleSaveFilterReloadCheck}
                  apply={this.applySavedFilters.bind(this)} data={this.state.savedFilters}
                  cancel={this.savedFilterCancel.bind(this)}
                  deleteApi={(id)=>this.deleteSavedFilter(id,this.state.multipleSaveFilterReloadCheck)} />
              )
            }
            {/* <AdsBanner /> */}
          </ScrollView>
        </View>
      </SafeAreaView>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    backgroundColor: "#F4F8F5"
  },
  topContainer: {
    display: "flex",
    backgroundColor: "#FFF"
  },
  mainView: {
    backgroundColor: "#fff",
    flex: 1,
    marginTop: "10%",
    height: height / 1.3,
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    elevation: 5,
  },
  padding: { paddingHorizontal: 15, paddingTop: 15 },
  header: { paddingTop: 15, paddingHorizontal: 15, justifyContent: "space-between" },
  paddingHorizontal: { paddingHorizontal: 15 },
  flexRow: { flexDirection: "row" },
  width50: { width: "50%" },
  filterHead: { fontSize: 20, color: "#000", fontFamily: 'PlusJakartaText-Bold' },
  saveBtn: { alignItems: "flex-end", marginTop: 6 },
  saveTxt: { fontSize: 15, color: "green" },
  marginTop: { marginTop: 20, alignItems: 'center' },
  optionsView: {
    height: 80,
    width: "100%",
    justifyContent: "center"
  },
  itemNameView: { paddingLeft: 10, marginTop: 4 },
  itemTxt: { fontSize: 16, fontFamily: 'PlusJakartaText-Regular' },
  dropDownIcon1: {
    justifyContent: "center",
    alignItems: "flex-end",
  },
  itemDescription: {
    justifyContent: "center",
    alignItems: "center",
    height: 50,
    width: "100%"
  },
  row: {
    flex: 1,
    justifyContent: "space-between"
  },
  sortIcon1: {
    marginRight: 12
  },
  price: {
    color: "#444",
    fontSize: 16,
    marginBottom: 10
  },
  productImg: {
    width: 30,
    height: 30
  },
  detailContent: {
    padding: 10
  },
  rating: {
    color: "#000",
    paddingRight: 6
  },
  starBox: {
    alignItems: "center"
  },
  lineSeparate: {
    height: 2,
    width: "100%",
    backgroundColor: '#F9F9F9'
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  popup: {
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 10,
    width: 300,
  },
  input: {
    marginBottom: 10,
    padding: 10,
    borderColor: 'gray',
    borderWidth: 1,
    borderRadius: 5,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});
// Customizable Area End

import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableWithoutFeedback,
  Platform,
  FlatList,
  ActivityIndicator,
  Dimensions,
  TouchableOpacity,
} from "react-native";
const { height, width } = Dimensions.get("screen");
import SubHeader from "../../../components/src/SubHeader";
import { theme } from "../../../framework/src/theme";
import AdsBanner from "../../../components/src/AdsBanner";
// Customizable Area End

import Watchlist2Controller, {
  Props,
  configJSON
} from "./Watchlist2Controller";

export default class Watchlist2 extends Watchlist2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  wishlist = ({ item }: any) => {
    console.log("itrtnj", item)
    let { crypto_details } = item?.attributes || {}
    return (
      crypto_details.length > 0 ?
        <>
          <TouchableOpacity onPress={() => this.renderDetails(crypto_details[0].id, crypto_details[0].name)} style={styles.DataContainer}>
            <View style={styles.coinName}>
              <Text style={[styles.coinShortName, { color: this.textColor() }]} >{crypto_details[0]?.symbol}</Text>
              <Text style={styles.coinFullName}>{crypto_details[0]?.name}</Text>
            </View>
            <View style={{ width: "30%" }}>
              <Text style={[styles.coinShortName, { color: this.textColor() }]}>{crypto_details[0]?.price}</Text>
              <Text style={
                crypto_details[0]?.volume_percentage_change_24h.charAt(0) === "+"
                  ? [styles.coinFullName, { color: "rgb(76,217,100)" }]
                  : [styles.coinFullName, { color: "#CF947D" }]
              }>{`${crypto_details[0]?.volume_change_24h} (${crypto_details[0]?.volume_percentage_change_24h})`}</Text>
            </View>
          </TouchableOpacity>
          {this.seprator()}
        </> :
        <View />
    )
  }

  seprator = () => {
    return (
      <View style={styles.ItemSeprator} />
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <View style={{ flex: 1 }} >
        <View style={{ flex: 1 }} >
          <ScrollView style={[styles.container, { backgroundColor: this.context.mode === 'light' ? theme.light.backgroundColor : theme.dark.backgroundColor }]}>
            <TouchableWithoutFeedback
              onPress={() => {
                this.hideKeyboard();
              }}
              testID="withoutFeedback"
            >
              {/* Merge Engine UI Engine Code */}
              <>

                <SubHeader
                  title={this.labelHeader}
                  testID="subHeaderTest"
                  onPress={() => this.props.navigation.goBack()}
                />
                <View style={[styles.mainView, { backgroundColor: this.context.mode === 'light' ? theme.light.highlight : theme.dark.secondaryColor }]}>
                  {this.state.loader === true &&
                    <ActivityIndicator
                      animating={true}
                      color="#4b9d5b"
                      size="large"
                      style={styles.activityIndicator}
                    />
                  }
                  {!this.state.loader &&
                    <>
                      {this.state.watchlist.length === 0 &&
                        <View style={styles.newsContent}>
                          <Text style={styles.noNewsTxt}>Oops!!</Text>
                          <Text style={styles.noNewsTxt}>No Watchlist has been created</Text>
                        </View>}

                      <FlatList
                        nestedScrollEnabled
                        style={{ height: height }}
                        data={this.state.watchlist}
                        renderItem={this.wishlist}
                        testID="FlatListID"
                      />
                    </>
                  }


                </View>

              </>
              {/* Merge Engine UI Engine Code */}
            </TouchableWithoutFeedback>

          </ScrollView>
        </View>

        <View style={{
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 0,
        }}>
          <AdsBanner />
        </View>
      </View>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  overlay: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.6)', // semi-transparent black overlay
    paddingVertical: 10,
    paddingHorizontal: 20,
    justifyContent: 'center', // Ensure AdsBanner is centered vertically
    alignItems: 'center', // Ensure AdsBanner is centered horizontally
  },
  container: {
    flex: 1,
    width: Platform.OS === "web" ? "75%" : "100%",
  },
  activityIndicator: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    height: 80
  },
  mainView: {
    backgroundColor: "#fff",
    flex: 1,
    marginTop: "10%",
    height: height / 1.2,
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    elevation: 5,
    paddingTop: 25,
    paddingHorizontal: 15
  },
  scrollingView: { height: 50 },
  flatListView: { height: "100%", width: "100%" },

  newsContent: { flex: 1, justifyContent: "center", alignItems: "center" },
  noNewsTxt: { fontSize: 20, color: "#858585", fontFamily: 'PlusJakartaText-Bold' },

  DataContainer: {
    padding: 10,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%"
  },
  coinName: {
    display: "flex",
    flexDirection: "column",
    padding: 5,
    width: "70%"
  },
  coinShortName: {
    fontSize: 14,
    fontFamily: "PlusJakartaText-Regular",
    color: "#1F2122"
  },
  coinFullName: {
    fontSize: 10,
    fontFamily: "PlusJakartaText-Regular",
    color: "#8F99A3",
    paddingTop: 10
  },
  ItemSeprator: {
    height: 1,
    width: width,
    backgroundColor: "#979797"
  }
});
// Customizable Area End
// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";
import TopNavBar from '../../components/src/TopNavBar.web';
import FooterBar from '../../components/src/Footer.web';
import GoogleAd from '../../components/src/GoogleAd';

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Customform from "../../blocks/customform/src/Customform";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import RichTextEditor5 from "../../blocks/RichTextEditor5/src/RichTextEditor5";
import Referrals from "../../blocks/Referrals/src/Referrals";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
//import PortfolioManagement from "../../blocks/PortfolioManagement/src/PortfolioManagement";
import AutomaticRenewals2 from "../../blocks/AutomaticRenewals2/src/AutomaticRenewals2";
import FreeTrial from "../../blocks/FreeTrial/src/FreeTrial";
import ContentManagement from "../../blocks/ContentManagement/src/ContentManagement";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import UploadMedia2 from "../../blocks/UploadMedia2/src/UploadMedia2";
import LoyaltySystem2 from "../../blocks/LoyaltySystem2/src/LoyaltySystem2";
//import Dashboard from "../../blocks/dashboard/src/Dashboard";
import CarouselDisplay from "../../blocks/CarouselDisplay/src/CarouselDisplay";
import A3ApisIntegrationsForNews2 from "../../blocks/A3ApisIntegrationsForNews2/src/A3ApisIntegrationsForNews2";
import Watchlist2 from "../../blocks/Watchlist2/src/Watchlist2";
import DisableAds from "../../blocks/DisableAds/src/DisableAds";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Payments from "../../blocks/Payments/src/Payments";
import VisualAnalytics from "../../blocks/VisualAnalytics/src/VisualAnalytics";
import EmailNotifications from "../../blocks/EmailNotifications/src/EmailNotifications";
import AccountVerificationUsingLink2 from "../../blocks/AccountVerificationUsingLink2/src/AccountVerificationUsingLink2";
import SavedCards from "../../blocks/SavedCards/src/SavedCards";
import AdminConsole3 from "../../blocks/AdminConsole3/src/AdminConsole3";
//import Couponcodegenerator from "../../blocks/couponcodegenerator/src/Couponcodegenerator";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import BreadcrumbNavigation from "../../blocks/BreadcrumbNavigation/src/BreadcrumbNavigation";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import ApiIntegration8 from "../../blocks/ApiIntegration8/src/ApiIntegration8";
import NavigationMenu from "../../blocks/NavigationMenu/src/NavigationMenu";
import ProjectTemplates from "../../blocks/ProjectTemplates/src/ProjectTemplates";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
//import OrderSummary from "../../blocks/OrderSummary/src/OrderSummary";
//import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import GoogleAdsenseIntegration from "../../blocks/GoogleAdsenseIntegration/src/GoogleAdsenseIntegration";
import PaymentAdmin2 from "../../blocks/PaymentAdmin2/src/PaymentAdmin2";
import AdvancedSearch from "../../blocks/AdvancedSearch/src/AdvancedSearch";
import IntegrationWith5PartnersForCoupons2 from "../../blocks/IntegrationWith5PartnersForCoupons2/src/IntegrationWith5PartnersForCoupons2";

import LandingPageWeb from "../../blocks/landingpage/src/LandingPage.web";
import GlobalSearchWeb from "../../blocks/landingpage/src/GlobalSearch.web";
import CryptoAssetTableWeb from "../../blocks/landingpage/src/CryptoAssetTable.web"
import ScreenerWeb from "../../blocks/landingpage/src/Screener.web";
import NewsPortalWeb from "../../blocks/landingpage/src/NewsPortal.web";
import EducationCenterLandingWeb from '../../blocks/landingpage/src/EducationCenterLanding.web';
import EducationCenterIntermediateWeb from "../../blocks/landingpage/src/EducationCenterIntermediate.web";
import EducationCenterWeb from "../../blocks/landingpage/src/EducationCenter.web";
import AppDownloadWeb from "../../blocks/landingpage/src/AppDownload.web";
import SubscribeWeb from "../../blocks/landingpage/src/Subscribe.web";
import SubscribeToProWeb from "../../blocks/landingpage/src/SubscribeToPro.web";
import TermsAndConditionWeb from "../../blocks/info-page/src/TermsAndCondition.web";
import PrivacyPolicyWeb from "../../blocks/info-page/src/PrivacyPolicy.web";

import EmailAccountLoginWeb from "../../blocks/email-account-login/src/EmailAccountLoginBlock.web";
import EmailAccountRegistrationWeb from "../../blocks/email-account-registration/src/EmailAccountRegistration.web";
import ForgotPasswordWeb from "../../blocks/forgot-password/src/ForgotPassword.web";
import ResetPasswordWeb from "../../blocks/forgot-password/src/ResetPassword.web";
import ProfileWeb from "../../blocks/info-page/src/Profile.web";
import SubscriptionWeb from "../../blocks/info-page/src/Subscription.web";
import PortfolioWeb from "../../blocks/info-page/src/Portfolio.web";
import OrderSummaryWeb from "../../blocks/info-page/src/OrderSummary.web";
import WatchListWeb from "../../blocks/Watchlist2/src/WatchList.web";
import ManageCardsWeb from "../../blocks/info-page/src/ManageCards.web";
import ContactUsWeb from "../../blocks/info-page/src/ContactUs.web";
import CryptoDetailWeb from "../../blocks/landingpage/src/CryptoDetail.web";
import DisableAdsWeb from "../../blocks/DisableAds/src/DisableAds.web"
import ReferralsWeb from "../../blocks/Referrals/src/Referrals.web";
import LoyaltySystemWeb from "../../blocks/LoyaltySystem2/src/LoyaltySystem.web";
import { basicPlanAccessibility } from "../../components/src/chartUtils.web";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import CustomThemeProvider, { CustomThemeContext } from '../../framework/src/CustomThemeProvider.web';

const routeMap = {
    Contactus: {
        component: Contactus,
        path: "/Contactus"
    },
    GlobalSearch: {
        component: GlobalSearchWeb,
        path: "/GlobalSearch"
    },
    AddContactus: {
        component: AddContactus,
        path: "/AddContactus"
    },
    Customform: {
        component: Customform,
        path: "/Customform"
    },
    ForgotPassword: {
        component: ForgotPassword,
        path: "/ForgotPassword"
    },
    ForgotPasswordOTP: {
        component: ForgotPasswordOTP,
        path: "/ForgotPasswordOTP"
    },
    NewPassword: {
        component: NewPassword,
        path: "/NewPassword"
    },
    Categoriessubcategories: {
        component: Categoriessubcategories,
        path: "/Categoriessubcategories"
    },
    RichTextEditor5: {
        component: RichTextEditor5,
        path: "/RichTextEditor5"
    },
    Referrals: {
        component: Referrals,
        path: "/Referrals"
    },
    CountryCodeSelector: {
        component: CountryCodeSelector,
        path: "/CountryCodeSelector"
    },
    // PortfolioManagement: {
    //     component: PortfolioManagement,
    //     path: "/PortfolioManagement"
    // },
    AutomaticRenewals2: {
        component: AutomaticRenewals2,
        path: "/AutomaticRenewals2"
    },
    FreeTrial: {
        component: FreeTrial,
        path: "/FreeTrial"
    },
    ContentManagement: {
        component: ContentManagement,
        path: "/ContentManagement"
    },
    EmailAccountLoginBlock: {
        component: EmailAccountLoginBlock,
        path: "/EmailAccountLoginBlock"
    },
    EmailAccountRegistration: {
        component: EmailAccountRegistration,
        path: "/EmailAccountRegistration"
    },
    UploadMedia2: {
        component: UploadMedia2,
        path: "/UploadMedia2"
    },
    LoyaltySystem2: {
        component: LoyaltySystem2,
        path: "/LoyaltySystem2"
    },
    // Dashboard: {
    //     component: Dashboard,
    //     path: "/Dashboard"
    // },
    CarouselDisplay: {
        component: CarouselDisplay,
        path: "/CarouselDisplay"
    },
    A3ApisIntegrationsForNews2: {
        component: A3ApisIntegrationsForNews2,
        path: "/A3ApisIntegrationsForNews2"
    },
    Watchlist2: {
        component: Watchlist2,
        path: "/Watchlist2"
    },
    DisableAds: {
        component: DisableAdsWeb,
        path: "/DisableAds",
        exact: true
    },
    LandingPageWeb: {
        component: LandingPageWeb,
        path: '/DisableAdsHomeScreen',
        exact: true
    },
    Catalogue: {
        component: Catalogue,
        path: "/Catalogue"
    },
    Pushnotifications: {
        component: Pushnotifications,
        path: "/Pushnotifications"
    },
    Payments: {
        component: Payments,
        path: "/Payments"
    },
    VisualAnalytics: {
        component: VisualAnalytics,
        path: "/VisualAnalytics"
    },
    EmailNotifications: {
        component: EmailNotifications,
        path: "/EmailNotifications"
    },
    AccountVerificationUsingLink2: {
        component: AccountVerificationUsingLink2,
        path: "/AccountVerificationUsingLink2"
    },
    SavedCards: {
        component: SavedCards,
        path: "/SavedCards"
    },
    AdminConsole3: {
        component: AdminConsole3,
        path: "/AdminConsole3"
    },
    // Couponcodegenerator: {
    //     component: Couponcodegenerator,
    //     path: "/Couponcodegenerator"
    // },
    LandingPage: {
        component: LandingPage,
        path: "/LandingPage"
    },
    BreadcrumbNavigation: {
        component: BreadcrumbNavigation,
        path: "/BreadcrumbNavigation"
    },
    Customisableusersubscriptions: {
        component: Customisableusersubscriptions,
        path: "/Customisableusersubscriptions"
    },
    SubscriptionDetails: {
        component: SubscriptionDetails,
        path: "/SubscriptionDetails"
    },
    Filteritems: {
        component: Filteritems,
        path: "/Filteritems"
    },
    Filteroptions: {
        component: Filteroptions,
        path: "/Filteroptions"
    },
    SocialMediaAccountLoginScreen: {
        component: SocialMediaAccountLoginScreen,
        path: "/SocialMediaAccountLoginScreen"
    },
    OTPInputAuth: {
        component: OTPInputAuth,
        path: "/OTPInputAuth"
    },
    ApiIntegration8: {
        component: ApiIntegration8,
        path: "/ApiIntegration8"
    },
    NavigationMenu: {
        component: NavigationMenu,
        path: "/NavigationMenu"
    },
    ProjectTemplates: {
        component: ProjectTemplates,
        path: "/ProjectTemplates"
    },
    SocialMediaAccountRegistrationScreen: {
        component: SocialMediaAccountRegistrationScreen,
        path: "/SocialMediaAccountRegistrationScreen"
    },
    // OrderSummary: {
    //     component: OrderSummary,
    //     path: "/OrderSummary"
    // },
    // UserProfileBasicBlock: {
    //     component: UserProfileBasicBlock,
    //     path: "/UserProfileBasicBlock"
    // },
    GoogleAdsenseIntegration: {
        component: GoogleAdsenseIntegration,
        path: "/GoogleAdsenseIntegration"
    },
    PaymentAdmin2: {
        component: PaymentAdmin2,
        path: "/PaymentAdmin2"
    },
    AdvancedSearch: {
        component: AdvancedSearch,
        path: "/AdvancedSearch"
    },
    IntegrationWith5PartnersForCoupons2: {
        component: IntegrationWith5PartnersForCoupons2,
        path: "/IntegrationWith5PartnersForCoupons2"
    },



    LandingPageWeb: {
        component: LandingPageWeb,
        path: '/',
        exact: true
    },
    ScreenerWeb: {
        component: ScreenerWeb,
        path: '/ScreenerWeb',
        exact: true
    },
    NewsPortalWeb: {
        component: NewsPortalWeb,
        path: '/NewsPortalWeb',
        exact: true
    },
    EducationCenterLandingWeb: {
        component: EducationCenterLandingWeb,
        path: '/EducationCenterLandingWeb',
        exact: true
    },
    EducationCenterIntermediateWeb: {
        component: EducationCenterIntermediateWeb,
        path: '/EducationCenterIntermediateWeb',
        exact: true
    },
    EducationCenterWeb: {
        component: EducationCenterWeb,
        path: '/EducationCenterWeb',
        exact: true
    },
    AppDownloadWeb: {
        component: AppDownloadWeb,
        path: '/AppDownloadWeb',
        exact: true
    },
    SubscribeWeb: {
        component: SubscribeWeb,
        path: '/SubscribeWeb',
        exact: true
    },
    SubscribeToProWeb: {
        component: SubscribeToProWeb,
        path: '/SubscribeToProWeb',
        exact: true
    },
    EmailAccountLoginWeb: {
        component: EmailAccountLoginWeb,
        path: '/EmailAccountLoginWeb',
        exact: true
    },
    EmailAccountRegistrationWeb: {
        component: EmailAccountRegistrationWeb,
        path: '/EmailAccountRegistrationWeb',
        exact: true
    },
    ForgotPasswordWeb: {
        component: ForgotPasswordWeb,
        path: '/ForgotPasswordWeb',
        exact: true
    },
    ResetPasswordWeb: {
        component: ResetPasswordWeb,
        path: '/ResetPasswordWeb',
        exact: true
    },
    ProfileWeb: {
        component: ProfileWeb,
        path: '/ProfileWeb',
        exact: true
    },
    SubscriptionWeb: {
        component: SubscriptionWeb,
        path: '/SubscriptionWeb',
        exact: true
    },
    PortfolioWeb: {
        component: PortfolioWeb,
        path: '/PortfolioWeb',
        exact: true
    },
    OrderSummaryWeb: {
        component: OrderSummaryWeb,
        path: '/OrderSummaryWeb',
        exact: true
    },
    WatchListWeb: {
        component: WatchListWeb,
        path: '/WatchListWeb',
        exact: true
    },
    ManageCardsWeb: {
        component: ManageCardsWeb,
        path: '/ManageCardsWeb',
        exact: true
    },
    ContactUsWeb: {
        component: ContactUsWeb,
        path: '/ContactUsWeb',
        exact: true
    },
    InfoPage: {
        component: InfoPage,
        path: '/InfoPage'
    },

    AlertWeb: {
        component: AlertBlock,
        path: "*/AlertWeb",
        modal: true
    },
    CryptoDetailWeb: {
        component: CryptoDetailWeb,
        path: "/CryptoDetailWeb/:id",
        exact: true
    },
    CryptoAssetTableWeb: {
        component: CryptoAssetTableWeb,
        path: '/CryptoAssets',
        exact: true
    },
    TermsAndConditionWeb: {
        component: TermsAndConditionWeb,
        path: "/TermsAndConditionWeb",
        exact: true
    },
    PrivacyPolicyWeb: {
        component: PrivacyPolicyWeb,
        path: '/PrivacyPolicyWeb',
        exact: true
    },
    ReferralsWeb: {
        component: ReferralsWeb,
        path: '/ReferralsWeb',
        exact: true
    },
    LoyaltySystemWeb: {
        component: LoyaltySystemWeb,
        path: '/LoyaltySystemWeb',
        exact: true
    }
};

const firebaseAPI = firebase.initializeApp({
    apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
    authDomain: "rnmasterapp-c11e9.firebaseapp.com",
    databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
    projectId: "rnmasterapp-c11e9",
    storageBucket: "rnmasterapp-c11e9.appspot.com",
    messagingSenderId: "649592030497",
    appId: "1:649592030497:web:7728bee3f2baef208daa60",
    measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {

    /* componentDidMount() {
        // inject some code to head element
        const head = document.querySelector("head");
        // import google ads script if not yet imported
        if (!head.querySelector("#my-google-ads-1")) {
            const script = document.createElement("script");
            script.id = "my-google-ads-1";
            script.async = true;
            script.src = "https://www.google.com/adsense/search/ads.js";
            head.appendChild(script);
        }
        // add another script to head element
        if (!head.querySelector("#my-google-ads-2")) {
            const script = document.createElement("script");
            script.id = "my-google-ads-2";
            script.type = "text/javascript";
            script.charset = "utf-8";
            script.innerHTML = `
            (function(g,o){g[o]=g[o]||function(){(g[o]['q']=g[o]['q']||[]).push(
            arguments)},g[o]['t']=1*new Date})(window,'_googCsa');
          `;
            head.appendChild(script);
        }

        // init ads
        var pageOptions = {
            // pubId: "pub-9616389000213823", // Make sure this the correct client ID!
            // query: "hotels",
            pubId: "pub-4043537952503060",
            query: "krypto",
            adPage: 1,
            //styleId: "1234567891",
        };

        var adblock1 = {
            container: "afscontainer1",
            // width: "700",
            number: 1,
            backgroundColor: localStorage.getItem('appTheme') == 'ligthTheme' ? '#F4F8F6' : '#424242'
        };

        window._googCsa("ads", pageOptions, adblock1);
    } */
    render() {

        const defaultAnalytics = firebaseAPI.analytics();
        defaultAnalytics.logEvent('APP_Loaded');
        
        return (
            <CustomThemeProvider>
                <View style={{ height: '100vh', width: '100vw', overflowX: 'hidden' }}>
                    <ToastContainer />
                    { basicPlanAccessibility() }
                    {(!(window.location.href).includes("EmailAccountLoginWeb") && !(window.location.href).includes("EmailAccountRegistrationWeb") && 
                        !(window.location.href).includes("ForgotPasswordWeb")) && !(window.location.href).includes("ResetPasswordWeb") ? (<TopNavBar />) : <></>}
                    <div style={{overflowX: 'hidden', flex: 1, backgroundColor: localStorage.getItem('appTheme') == 'darkTheme' ? 'rgb(35, 36, 41)' : 'rgb(239, 239, 239)'}}>
                        {WebRoutesGenerator({ routeMap })}
                        { localStorage.getItem("showAds") === "true" && <GoogleAd slot="6303444031" /> }
                        {(!(window.location.href).includes("EmailAccountLoginWeb") && !(window.location.href).includes("EmailAccountRegistrationWeb") &&
                        !(window.location.href).includes("ForgotPasswordWeb") && !(window.location.href).includes("PortfolioWeb") && !(window.location.href).includes("SubscriptionWeb")
                        && !(window.location.href).includes("ProfileWeb") && !(window.location.href).includes("OrderSummaryWeb") && !(window.location.href).includes("WatchListWeb") && !(window.location.href).includes("ResetPasswordWeb")
                        && !(window.location.href).includes("ManageCardsWeb") && !(window.location.href).includes("ContactUsWeb")) ? (<FooterBar />) : <></>}
                    </div>
                    <ModalContainer />
                </View>
            </CustomThemeProvider>
        );
    }
}

export default App;

import React from "react";
// Customizable Area Start
import ProfileWebController, {
    Props
} from "./ProfileController.web";

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import {
    withStyles, StyleRules
} from "@material-ui/core/styles";

import EditIcon from '@material-ui/icons/Edit';
import HttpsIcon from '@material-ui/icons/Https';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import { cryptoLogo } from "../../email-account-login/src/assets";
import Loader from "../../../components/src/Loader.web";
import DeleteAccountDialogWeb from '../../../components/src/DeleteAccountDialog.web';
import DeleteIcon from '@material-ui/icons/Delete';

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

// Customizable Area End

const styles: StyleRules = {
    // Customizable Area Start
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'Plus Jakarta Text',
        // background: '#f4f8f6',
        overflowX: 'hidden',
        // marginRight: '3px',
        paddingBottom: '22px',
        height: '75vh',
    },
    profileCard: {
        width: '400px',
        textAlign: 'center',
        borderRadius: '12px',
        overflow: 'hidden',
        // backgroundColor: '#ffffff',
        padding: '25px',
    },
    cardHeader: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: '18%'
    },
    pic: {
        display: 'inline-block',
        padding: '8px 72px',
        margin: '8px',
        borderRadius: '50%',
        "& img": {
            display: 'block',
            width: '75px',
            height: '75px',
            borderRadius: '50%',
            backgroundColor: 'antiquewhite'
        }
    },
    name: {
        // color: '#2f3130',
        fontSize: '22px',
        fontWeight: 600,
        margin: '10px 0px'
    },
    iconTextContainer: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        justifyContent: 'center'
    },
    desc: {
        // color: '#7b7c7b',
        fontSize: '14px',
        marginLeft: '12px'
    },
    address: {
        // color: '#7b7c7b',
        fontSize: '14px',
        marginLeft: '12px'
    },
    profileButton: {
        backgroundColor: '#f4f8f6',
        color: '#212322',
        cursor: 'pointer',
        padding: '16px',
        marginTop: '20px',
        border: 'none',
        borderRadius: '10px',
        fontSize: '16px',
        fontWeight: 'bold',
        textAlign: 'center',
        width: '100%',
        display: 'block',
        margin: '2% auto'
    },
    fullWidth: {
        '& .MuiFormControl-fullWidth': {
            width: '75%'
        },
        '& .MuiSelect-selectMenu': {
            textAlign: 'left'
        }
    },
    editProfileButtons: {
        backgroundColor: 'rgba(1,162,78,255)',
        color: '#ffffff',
        cursor: 'pointer',
        padding: '15px 38px',
        margin: '0px 10px',
        border: 'none',
        borderRadius: '10px',
        textAlign: 'center'
    },
    inputfile: {
        display: 'none'
    },
    preview: {
        position: 'relative',
        marginBottom: '25px'
    },
    deleteBtn: {
        marginTop: '20px'
    },
    avatar: {
        backgroundColor: 'antiquewhite',
        height: '75px',
        width: '75px',
        // border: '3px solid #0af',
        borderRadius: '50%',
        transition: 'background ease-out 200ms'
    },
    avatarButton: {
        padding: '4px',
        borderRadius: '50%',
        border: 'none',
        cursor: 'pointer',
        backgroundColor: '#ffffff',
        boxShadow: '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)',
        transition: 'background-color ease-out 120ms',
        position: 'absolute',
        right: '-5%',
        bottom: '0%',
        "&:hover": {
            backgroundColor: '#ffffff'
        }
    },
    // responsive css
    '@media (max-width: 600px)': {
        root: {
            padding: '0 25px',
        },
        profileCard: {
            maxWidth: '100%',
        },
        pic: {
            padding: '8px',
            margin: '8px 0',
            "& img": {
                width: '60px',
                height: '60px',
            },
        },
        name: {
            fontSize: '18px',
        },
        desc: {
            marginLeft: '0',
        },
        address: {
            marginLeft: '0',
        },
        profileButton: {
            marginTop: '10px',
            fontSize: '14px',
            padding: '12px',
        },
        fullWidth: {
            '& .MuiFormControl-fullWidth': {
                width: '100%',
            },
        },
        editProfileButtons: {
            padding: '10px 20px',
            margin: '0px 5px',
        },
    }
    // Customizable Area End
}

// Customizable Area Start
const profileSchema = Yup.object().shape({
    // avatar: Yup.string()
    //     .required('profile pic is required.'),
    fullname: Yup.string()
        .required('full name is required.'),
    experience: Yup.string()
        .required('experience is required.'),
    address: Yup.string()
        .required('address is required.'),
});
// Customizable Area End

export class ProfileWeb extends ProfileWebController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    viewProfile() {
        const { classes } = this.props;
        const { userProfile, confirmDeleteAccount } = this.state;
        const backgroundval = localStorage.getItem('appTheme') == 'darkTheme' ? '#232429' : '#f4f8f6';

        return(
            <Paper className={classes.profileCard} elevation={3}>
                <div className={classes.cardHeader}>
                    <div className={classes.pic}>
                        <img src={userProfile?.attributes?.image == null ? cryptoLogo : userProfile?.attributes?.image} alt=""></img>
                    </div>
                    <div>
                        <Button
                            variant="contained"
                            data-testid="edit-profile"
                            size="small"
                            startIcon={<EditIcon />}
                            disableElevation={true}
                            style={{
                                backgroundColor: backgroundval,
                                color: '#6bc897'
                            }}
                            onClick={(e: any) => {
                                this.setState({ editProfileFlag: true });
                                //this.urltoFile(userProfile?.attributes?.avatar == null ? cryptoLogo : userProfile?.attributes?.avatar);
                            }}
                        >
                            Edit
                        </Button>
                    </div>
                </div>
                <div className={classes.name}>{userProfile?.attributes?.fullname}</div>
                <div className={classes.iconTextContainer}>
                    <HttpsIcon style={{ fontSize: '22px', color: '#54a97e' }} />
                    <div className={classes.desc}>Experience: {`${userProfile?.attributes?.experience == null ? 0 : userProfile?.attributes?.experience} Years`}</div>
                </div>
                <div className={classes.iconTextContainer} style={{ marginTop: '12px' }}>
                    <LocationOnIcon style={{ fontSize: '22px', color: '#54a97e' }} />
                    {
                        userProfile?.attributes?.address != null &&
                        <div className={classes.address}>{userProfile?.attributes?.address}</div>
                    }
                </div>
                <div className={classes.deleteBtn}>
                    <Button type="submit" variant="contained" 
                    data-testid="deleteAccount"
                    color="secondary" 
                    startIcon={<DeleteIcon />}
                    onClick={this.confirmDeleteAccount}>Delete Account</Button>
                </div>
                {confirmDeleteAccount && <DeleteAccountDialogWeb open={confirmDeleteAccount} deleteAccount={this.deleteAccount} 
                handleClose={() => this.setState({ confirmDeleteAccount: false })} />}
            </Paper>
        )
    }

    editProfile() {
        const { classes } = this.props;
        const { userProfile } = this.state;

        return (
            <Paper className={classes.profileCard} elevation={3}>
                <span style={{ color: 'rgb(1, 162, 78)' }}>Edit Profile</span>
                <div className={classes.cardHeader} style={{ marginLeft: '0px', marginTop: '16px' }}>
                    <input type="file" name="image" id="image" accept="image/*" ref={this.fileUploadRef} onChange={(e: any) => this.fileChange(e)} className={classes.inputfile} />
                    <div className={classes.preview}>
                        <div className={classes.avatar}>
                            <img src={userProfile?.attributes?.image == null ? cryptoLogo : userProfile?.attributes?.image} alt="profile-img" style={{ width: '75px', height: '75px', borderRadius: '50%' }}></img>
                        </div>
                        <button
                            id="upload-button"
                            aria-labelledby="image"
                            aria-describedby="image"
                            className={classes.avatarButton}
                        >
                            <CameraAltIcon fontSize="small" style={{ color: 'rgba(1,162,78,255)', marginBottom: '-3px' }} onClick={(e: any) => this.fileUploadRef.current.click()} />
                        </button>
                    </div>
                </div>
                <div>
                    <Formik
                        initialValues={{
                            image: userProfile?.attributes?.image, fullname: userProfile?.attributes?.fullname || "",
                            experience: userProfile?.attributes?.experience || 0, address: userProfile?.attributes?.address || ""
                        }}
                        enableReinitialize
                        validationSchema={profileSchema}
                        onSubmit={(values) => {
                            this.editProfileForm(values);
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue,
                        }) => (
                            <Form onSubmit={handleSubmit}>
                                <div className={classes.fullWidth}>
                                    <Field
                                        validateOnBlur
                                        validateOnChange
                                        name="fullname"
                                        render={({ field, form }: any) => (
                                            <TextField
                                                hiddenLabel
                                                placeholder={"Full Name"}
                                                name={"fullname"}
                                                variant="filled"
                                                value={values.fullname}
                                                fullWidth
                                                InputProps={{ disableUnderline: true }}
                                                error={
                                                    Boolean(form.errors.fullname && form.touched.fullname)
                                                }
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                helperText={
                                                    form.errors.fullname &&
                                                    form.touched.fullname &&
                                                    String(form.errors.fullname)
                                                }
                                            />
                                        )}
                                    />
                                </div>
                                <div className={classes.fullWidth}>
                                    <Field
                                        validateOnBlur
                                        validateOnChange
                                        name="experience"
                                        render={({ field, form }: any) => (
                                            <TextField
                                                id="experience"
                                                select
                                                label="Experience"
                                                className={classes.textField}
                                                value={values.experience}
                                                onChange={handleChange("experience")}
                                                name={"experience"}
                                                margin="normal"
                                                fullWidth
                                                variant="filled"
                                                InputProps={{ disableUnderline: true }}
                                                error={
                                                    Boolean(form.errors.experience && form.touched.experience)
                                                }
                                                helperText={
                                                    form.errors.experience &&
                                                    form.touched.experience &&
                                                    String(form.errors.experience)
                                                }
                                                style={{ marginBottom: '15px' }}
                                            >
                                                {this.state.experience.map((exp: number) => (
                                                    <MenuItem key={exp} value={exp}>
                                                        {exp}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        )}
                                    />
                                </div>
                                <div className={classes.fullWidth}>
                                    <Field
                                        validateOnBlur
                                        validateOnChange
                                        name="address"
                                        render={({ field, form }: any) => (
                                            <TextField
                                                hiddenLabel
                                                placeholder={"Location"}
                                                name={"address"}
                                                value={values.address}
                                                variant="filled"
                                                fullWidth
                                                InputProps={{ disableUnderline: true }}
                                                multiline
                                                rows={2}
                                                error={
                                                    Boolean(form.errors.address && form.touched.address)
                                                }
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                helperText={
                                                    form.errors.address &&
                                                    form.touched.address &&
                                                    String(form.errors.address)
                                                }
                                                style={{ marginBottom: '15px' }}
                                            />
                                        )}
                                    />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                    <button type="button" className={classes.editProfileButtons} style={{ background: '#181a19' }} onClick={(e: any) => this.setState({ editProfileFlag: false })}>Cancel</button>
                                    <button type="submit" className={classes.editProfileButtons}>Save Changes</button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Paper>
        )
    }
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes } = this.props;

        return (
            <Paper className={classes.root} elevation={0} data-testid='profilePaper' square style={{backgroundColor: localStorage.getItem('appTheme') == 'ligthTheme' ? '#F4F8F6': '#232429'}}>
                {this.state.token && <>
                {this.state.loading && <Loader loading={this.state.loading} />}
                {this.state.editProfileFlag ? this.editProfile() : this.viewProfile()}
                </>}
            </Paper>
        )
        // Customizable Area End
    }
}
export default withStyles(styles)(ProfileWeb);

export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const editIcon = require("../assets/EDIT.png");
export const deleteIcon = require("../assets/Delete.png");
export const cardImg = require("../assets/cardImg.png");
export const circle = require("../assets/circles.png");





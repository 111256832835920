import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  Image,
  TouchableOpacity,
  View,
  TextInput,
  ScrollView,
  TouchableWithoutFeedback,
  Platform, Dimensions, FlatList,StatusBar
} from "react-native";
import SubHeader from "../../../components/src/SubHeader";
import CustomText from "../../../components/src/text";
import { COLORS } from "../../../framework/src/Globals";
import { TextInputMask } from 'react-native-masked-text'
import Icon from 'react-native-vector-icons/AntDesign';
import { circle } from './assets'
const { width, height } = Dimensions.get('screen')
import { theme } from "../../../framework/src/theme";
const _ = require("lodash")
// Customizable Area End

import PaymentsController, {
  Props,
  configJSON
} from "./PaymentsController";

export default class Payments extends PaymentsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  getColorTheme = (mode:any, color1:any, color2:any) => {
    return this.context.mode === mode ? color1 : color2
  }
  renderItem = (item: any) => {
    return (
      <View style={{ backgroundColor:this.getColorTheme('light',theme.light.lightGray,theme.dark.highlight) }}>
        <TouchableOpacity
          onPress={() => this.state.year ==='' ? alert("Please select year first") :this.setState({ month: item.item.label, visible: !this.state.visible, monthError: false })}

          style={{ borderBottomWidth: 1, padding: 8, marginLeft: 13,borderBottomColor:'#DFE1E0' }}>
          <CustomText style={{color:this.getColorTheme('light',theme.light.textColor,theme.dark.textColor)}}>{item.item.label}</CustomText>
        </TouchableOpacity>
      </View>
    )
  }
  yearRenderItem = (item: any) => {
    return (
      <View style={{ backgroundColor: this.getColorTheme('light' , theme.light.lightGray , theme.dark.highlight) }}>
        <TouchableOpacity
          onPress={() => this.setState({ year: item.item.label, visibleYeaar: !this.state.visibleYeaar, monthError: false },()=> this.setState({month:''}))}
          style={{ borderBottomWidth: 1, padding: 8, marginLeft: 13 ,borderBottomColor:'#DFE1E0'}}>
          <CustomText  style={{color:this.getColorTheme('light',theme.light.textColor,theme.dark.textColor)}}>{item.item.label}</CustomText>
        </TouchableOpacity>
      </View>
    )
  }
  async componentDidMount() {
    if (this.props.route.params !== undefined) {
      if (this.props.route.params.item) {
        this.setState({
          name: this.props.route.params.item.name,
          cardNo: this.props.route.params.item.card_number,
          month: this.props.route.params.item.month,
          year: this.props.route.params.item.year,
          cvv: this.props.route.params.item.cvv.toString()
        }, () => this.validateEditData())
      }
    }
  }
  monthCvvRender() {
    const backgroundColor = this.getColorTheme('light', theme.light.lightGray , theme.dark.highlight);
    const textColor = this.getColorTheme('light', theme.light.textColor , theme.dark.textColor);
    const monthButtonStyle = [
      styles.expBtn,
      {
        borderWidth: this.state.visible ? 1 : 0,
        borderColor: this.state.visible ? '#9FA09F' : '#F7F7F7',
        backgroundColor,
      },
    ];
    const yearButtonStyle = [
      styles.expBtn,
      {
        borderWidth: this.state.visibleYeaar ? 1 : 0,
        borderColor: this.state.visible ? '#9FA09F' : '#F7F7F7',
        backgroundColor,
      },
    ];
    return (
      <View style={styles.btnFlexView}>
        <View style={[styles.hiddenView, { height: this.state.visible ? height / 4.7 : 70, backgroundColor: backgroundColor }]}>
          <TouchableOpacity onPress={() => this.setState({ visible: !this.state.visible })}
            style={[monthButtonStyle]}>
            <CustomText style={styles.expText}>{'Exp.'}</CustomText>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <CustomText style={[styles.mmText, { color: this.getColorTheme('light' , theme.light.textColor , theme.dark.textColor) }]}>{this.state.month ? this.state.month : 'MM'}</CustomText>
              {
                this.state.visible &&
                  <Icon name={"caretup"} size={15} />
              }{
                !this.state.visible  &&
                  <Icon name={"caretdown"} size={15} />
              }

            </View>
          </TouchableOpacity>
          {
            this.state.visible &&
            <FlatList
              data={this.state.year ? this.generateMonths():this.renderAllMonths()}
              renderItem={this.renderItem}
              nestedScrollEnabled
              extraData={[this.state.year]}
            />
          }
        </View>
        <View style={[styles.hiddenView, { height: this.state.visibleYeaar ? height / 4.7 : 70, backgroundColor: this.getColorTheme('light' , theme.light.lightGray , theme.dark.highlight) }]}>
          <TouchableOpacity onPress={() => this.setState({ visibleYeaar: !this.state.visibleYeaar })}
            style={[yearButtonStyle]}>
            <CustomText style={styles.expText}>{'Exp.'}</CustomText>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', }}>
              <CustomText style={[styles.mmText, { color: textColor }]}>{this.state.year ? this.state.year : 'YY'}</CustomText>
              {
                this.state.visibleYeaar ?
                  <Icon name={"caretup"} size={15} /> :
                  <Icon name={"caretdown"} size={15} />
              }
            </View>
          </TouchableOpacity>
          {
            this.state.visibleYeaar &&
            <FlatList
              data={this.generateYears()}
              renderItem={this.yearRenderItem}
              nestedScrollEnabled
            />
          }
        </View>
        <View style={[styles.cvvTextInput, { backgroundColor: backgroundColor }]}>
          <CustomText style={[styles.expText, { marginBottom: -10 }]}>{'CVV'}</CustomText>
          <TextInput
            value={this.state.cvv}
            {...this.txtInputCvv}
            allowFontScaling
            autoCapitalize={'words'}
            keyboardType={'numeric'}
            maxLength={3}
            placeholderTextColor={this.getColorTheme('light' , theme.light.placeHolderColor , theme.dark.lightGray)}
            onFocus={() => {
              this.setState({ isFocusedCvv: true, cvvError: false })
            }}
            onBlur={() => {
              this.state.cvv === '' && this.setState({ isFocusedCvv: false, cvvError: true });
            }}
            style={{ textAlign: 'center', fontWeight: 'bold', color: textColor }}
          />
        </View>

      </View>
    )
  }
  btnRenderView(){
    return(
      <View
        style={{
          width: "100%",
          flexDirection: "row",
          alignItems: "center",
          marginBottom:30,
        }}
      >
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "space-between",
            paddingVertical: 16,
          }}
        >
          <TouchableOpacity
            style={{
              backgroundColor: "#181A19",
              height: 48,
              paddingHorizontal: 10,
              borderRadius: 10,
              borderWidth: 0.4,
              borderColor: "rgb(151,151,151)",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 16,
              flex:9
            }}
            onPress={() => this.props.navigation.goBack()}
          >
            <CustomText
              style={{
                fontFamily: 'PlusJakartaText-Bold',
                fontSize: 18,
                color: '#fff'
              }}
            >
              Cancel
            </CustomText>
          </TouchableOpacity>
          <View style={{flex:1}}/>
          <TouchableOpacity
            style={{
              backgroundColor: '#489F57',
              paddingHorizontal: 10,
              flex:9,
              height: 48,
              borderRadius: 5,
              justifyContent: "center",
              alignItems: "center",
            }}
            disabled={this.state.disableSavebtn}
            onPress={() => { this.setState({ submitted: true }, () => this.props.route.params?.item ? this.editCardData(this.props.route.params.item.id) : this.addCardData()) }}
          >
            <CustomText
              style={{
                fontFamily: 'PlusJakartaText-Bold',
                fontSize: 18,
                color: '#fff',
                textAlign:"center"
              }}
            >
              Save Changes
            </CustomText>
          </TouchableOpacity>
        </View>
      </View>
    )
  }
  cardViewRender(){
    return(
      <View style={styles.cardView}>
        <View style={styles.cardView2}>
          <View style={{ flex: 1 }}>
            <CustomText style={styles.nameCard}>{"name"}</CustomText>
            <View>
              <CustomText style={[styles.nameCard, { color: '#fff', textTransform: 'none' }]}>{this.state.name}</CustomText>
            </View>
          </View>
          <Image source={circle} style={{ height: 30, width: 30, resizeMode: 'contain' }} />
        </View>
        <View>
          {
            this.state.cardNo ?
              <CustomText style={[styles.dotText]}>{".... .... .... "}{this.state.cardNo.slice(-4)}</CustomText>
              :
              <CustomText style={[styles.dotText]}>{".... .... .... ...."}</CustomText>

          }
        </View>
        <View style={{ flexDirection: 'row', marginTop: '5%' }}>
          <View style={{ flex: 0.3 }}>
            <CustomText style={styles.nameCard}>{"exp."}</CustomText>
            <CustomText style={styles.nameCard}>{this.state.month}<CustomText>{this.state.month ? "/" : ''}</CustomText>{this.state.year.toString().slice(-2)}</CustomText>
          </View>
          <View>
            <CustomText style={styles.nameCard}>{"cvv"}</CustomText>
            <CustomText style={[styles.nameCard, { fontSize: 20, marginTop: -5, fontWeight: 'bold' }]}>{this.state.cvv ? '...' : null}</CustomText>
          </View>
        </View>
      </View>
    )
  }
  renderInputName(){
    return(
      <View style={[styles.inputView, { backgroundColor: this.getColorTheme( 'light' , theme.light.lightGray , theme.dark.highlight) }]}>
        <CustomText style={[styles.expText, {marginBottom:-10, marginLeft: 5 }]}>{"Name"}</CustomText>
        <TextInput
          value={this.state.name}
          {...this.txtInputName}
          allowFontScaling={false}
          keyboardType={'default'}
          onFocus={() => {
            this.setState({ isFoucueName: true, nameError: false })
          }}
          onBlur={() => {
            this.state.name === '' && this.setState({ isFoucueName: false, nameError: true });
          }}
          style={[styles.inputeStyle, {  color: this.getColorTheme( 'light' , theme.light.textColor , theme.dark.textColor) }]}
          placeholderTextColor={this.getColorTheme( 'light' , theme.light.placeHolderColor , theme.dark.lightGray)}

        />
      </View>
    )
  }
  renderCardNoInput(){
    return(
      <View style={[styles.inputView, { backgroundColor: this.getColorTheme('light' , theme.light.lightGray , theme.dark.highlight) }]}>
        <CustomText style={[styles.expText, {marginBottom:-10, marginLeft: 5 }]}>{"Card No."}</CustomText>
        <TextInputMask
          type={'credit-card'}
          value={this.state.cardNo}
          {...this.txtInputCardNo}
          allowFontScaling={false}
          onFocus={() => {
            this.setState({ isFoucueCardNo: true, cardnoError: false })
          }}
          onBlur={() => {
            this.state.cardNo === '' && this.setState({ isFoucueCardNo: false, cardnoError: true });
          }}
          placeholderTextColor={this.getColorTheme('light' , theme.light.placeHolderColor , theme.dark.lightGray)}
          style={{ paddingHorizontal:5,color: this.getColorTheme('light' , theme.light.textColor ,theme.dark.textColor), fontFamily: 'PlusJakartaText-Bold' }}
        />

      </View>
    )
  }
  renderError = () => {
    return <>
      {!this.state.month && this.state.submitted && <CustomText style={styles.errorText}>{configJSON.monthErrorMessage}</CustomText>}
      {!this.state.year && this.state.submitted && <CustomText style={styles.errorText}>{configJSON.yearErrorMessage}</CustomText>}
      {this.state.cvvError && this.state.submitted && <CustomText style={styles.errorText}>{this.state.cvvLabel}</CustomText>}
    </>
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
          <ScrollView keyboardShouldPersistTaps="always" style={styles.container}>
            <TouchableWithoutFeedback
              onPress={() => {
                this.hideKeyboard();
              }}
            >
              {/* Customizable Area Start */}
              {/* Merge Engine UI Engine Code */}
              <View style={{ backgroundColor: this.getColorTheme('light' , theme.light.headeBg , theme.dark.headeBg) }}>
                <StatusBar
                  barStyle={this.getColorTheme('light' , 'dark-content' , 'light-content')} backgroundColor={this.getColorTheme('light' , theme.light.headeBg , theme.dark.headeBg)}

                />
                <SubHeader
                  title={this.props.route.params?.item ? "Edit Card Details" : "Add Card"}
                  onPress={() => this.props.navigation.goBack()}
                />
                <View style={[styles.mainView,{ backgroundColor: this.getColorTheme('light' , theme.light.highlight , theme.dark.secondaryColor )}  ]}>
                  {this.cardViewRender()}
                  <View style={[styles.lineStyle, { borderColor: this.getColorTheme('light' , theme.light.lightGray , theme.dark.highlight) }]} />
                  <View style={styles.mainView2}>
                    {this.renderInputName()}
                    {
                      this.state.nameError && this.state.submitted &&
                      <CustomText style={styles.errorText}>{this.state.nameLabel}</CustomText>
                    }
                    {this.renderCardNoInput()}
                    {
                      this.state.cardnoError  && this.state.submitted &&
                      <CustomText style={styles.errorText}>{this.state.cardNoLabel}</CustomText>
                    }
                    {this.monthCvvRender()}
                    {this.renderError()}
                  </View>
                  {this.btnRenderView()}

                </View>
              </View>
            {/* <Button
              testID={"btnExample"} //Merge Engine::From BDS
              title={configJSON.btnExampleTitle} //UI Engine::From Sketch
              {...this.btnExampleProps} //Merge Engine::From BDS - {...this.testIDProps}
            /> */}

          {/* Merge Engine UI Engine Code */}
          {/* Customizable Area End */}
        </TouchableWithoutFeedback>
      </ScrollView>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#F4F8F5"
  },
  title: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  body: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    marginBottom: 16,
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    padding: 10,
    borderWidth: Platform.OS === "web" ? 0 : 1
  },
  bgMobileInput: {
    flex: 1
  },
  showHide: {
    alignSelf: "center"
  },
  imgShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {},
  mainView: {
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    elevation: 1,
    padding: 20,
    alignItems: 'center',
  },
  cardView: {
    height: height / 5,
    width: width / 1.1,
    backgroundColor: COLORS.green,
    borderRadius: 10, elevation: 5,
    padding: 15,
    justifyContent: 'center'
  },
  inputView: {
    backgroundColor:"#F4F8F5",
    borderRadius: 10,
    padding:10,
    width:"100%",
    justifyContent: 'center',
    marginTop: 10,
  },
  inputeStyle: {
    fontFamily:'PlusJakartaText-Regular',
    alignItems: 'center'
  },
  expBtn: {
    backgroundColor: '#F4F8F5',
    borderRadius: 10,
    paddingHorizontal:10,
    width:"100%",
    height: 70,
    justifyContent: 'center'
  },
  cvvTextInput: {
    borderRadius: 10,
    height: 70,
    width:"32%",
    backgroundColor:"red",
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical:10,
  },
  cancelBtn: {
    height: height / 14,
    width: width / 2.3,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center', elevation: 1
  },
  cancelText: {
    fontFamily:'PlusJakartaText-Bold',
    fontSize: 18,
    color: '#fff'
  },
  mmText: {
    fontWeight: 'bold'
  },
  expText: {
    fontFamily:'PlusJakartaText-Bold',
    fontSize:14,
    color: 'gray',
    textAlign:"left"
  },
  nameCard: {
    textTransform: 'uppercase',
    color: '#F4F8F6',
    fontSize: 16,
    fontFamily:'PlusJakartaText-Regular'
  },
  errorText: {
    color: 'red',
    fontFamily:'PlusJakartaText-Regular'

  },
  cardView2:{
    flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' 
  },
  dotText:{
    color: '#fff', fontSize: 20, fontFamily:'PlusJakartaText-Bold'
  },
  lineStyle: {
    borderWidth: 1,
    width: width,
    borderColor: '#F4F8F5',
    marginTop: '7%'
  },
  mainView2:{
    width:"100%",
  },
  btnFlexView: {
    flexDirection: 'row', justifyContent: 'space-between', marginTop: 10
  },
  hiddenView:{
    backgroundColor: '#F4F8F5',
    overflow: 'hidden', borderRadius: 10,
    width:"32%"
  },
  btnMainView:{
    flexDirection: 'row', justifyContent: 'space-between'
  }
  
});
// Customizable Area End

import React from "react"
// Customizable Area Start
import Paper from "@material-ui/core/Paper";
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Chip from '@material-ui/core/Chip';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { currentPlan, removePlusMinusSymbol } from "../../../components/src/chartUtils.web";
// Customizable Area End

interface SingleTableType {
    // Customizable Area Start
    data:any,
    header:any,
    handleMorePopup:any,
    isMore:any,
    classes:any,
    handleVolumePageIncrease:any,
    isLoading:any,
    filteredChip:any,
    handleFilterDelete:any,
    openVolumeUpAndDownPopup: any
    // Customizable Area End
}
// Customizable Area Start
// Customizable Area End

const SingleTable = (props:SingleTableType) => {
    // Customizable Area Start
    const plan = currentPlan();

    return(
        <>
            <Paper>
                <Paper component="ul" className={props.classes.filtered} elevation={0}>
                    {props.filteredChip.map((data: any, key: any) => {
                        let icon;
                        return (
                            <li key={data.key}>
                                <Chip
                                    icon={icon}
                                    label={data}
                                    onDelete={props.handleFilterDelete(data)}
                                    className={props.classes.chip}
                                />
                            </li>
                        );
                    })}
                </Paper>
                <TableContainer style={{ maxHeight: '850px' }}>
                    <Table aria-label="simple table">
                        <TableHead data-testid="volumeHeader" style={{ position: 'sticky', top: 0, whiteSpace: 'nowrap', zIndex: 1, backgroundColor: localStorage.getItem('appTheme') == 'ligthTheme' ? 'rgba(255, 255, 255, 255)' : '#333742' }}>
                            <TableRow>
                                {
                                    props.header.map((item:any, key:any) => (
                                        <TableCell key={key} align="left" style={{ fontSize: '12px' }}>{item}</TableCell>
                                    ))
                                }
                                <TableCell align="left" style={{ fontSize: '12px' }}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.data.map((row: any, key: any) => (
                                <TableRow
                                    key={row.id}
                                >
                                    <TableCell component="th" scope="row">
                                        {key + 1}
                                    </TableCell>
                                    <TableCell align="left">
                                        <CardHeader
                                            avatar={
                                                <Avatar
                                                    alt="Remy Sharp"
                                                    style={{ width: '32px', height: '32px', cursor: 'pointer' }}
                                                    src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${row.id}.png`}
                                                    onClick={() => props.handleMorePopup(row)}
                                                >
                                                </Avatar>
                                            }
                                            title={
                                                <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                                                    {row.name}
                                                </Typography>
                                            }
                                            subheader={
                                                <Typography gutterBottom variant="body2" component="div" style={{ color: '#c6cfca', fontWeight: 'bold', fontSize: '12px' }}>
                                                    {row.symbol}
                                                </Typography>
                                            }
                                            style={{ padding: '0px' }}
                                        />
                                    </TableCell>
                                    <TableCell align="left">
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                                                {row.price}
                                            </Typography>
                                            <div
                                                style={{ display: 'flex', alignItems: 'center', marginLeft: '-5px' }}
                                            >
                                                {row.percentage_change_24h?.toString()?.charAt(0) === "+" ? <ArrowDropUpIcon style={{ color: '#01A24E' }} /> : <ArrowDropDownIcon style={{ color: '#E86A3F' }} />}
                                                <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px', color: row.percentage_change_24h?.toString()?.charAt(0) === "+" ? '#01A24E' : '#E86A3F' }}>
                                                    {removePlusMinusSymbol(row.percentage_change_24h)} (24H)
                                                </Typography>
                                            </div>
                                        </div>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                                            {`${row.one_day?.high}`}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                                            {`${row.one_day?.low}`}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                                            {row.market_cap}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                                            {row.volume}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                                            {row.circulation}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        {row['volume_average'] &&
                                        <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                                            {row.volume_average}
                                        </Typography>}
                                        {row['last_volume'] &&
                                        <Typography gutterBottom variant="body2" data-testid="lastVolume" component="div" style={{ fontWeight: 'bold', fontSize: '12px', cursor: 'pointer', textDecoration: 'underline', color: 'rgba(1,162,78,255)' }} onClick={(event: any) => {
                                            if (typeof row['last_volume'] !== 'number')
                                                props.openVolumeUpAndDownPopup(row['last_volume']);
                                        }}>
                                             {row.last_volume[row.last_volume.length-1].volume}
                                        </Typography>}
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography gutterBottom variant="body2" data-testid="moreData" component="a" style={{ fontWeight: 'bold', fontSize: '12px', cursor: 'pointer', textDecoration: 'underline', color: 'rgba(1,162,78,255)' }} onClick={(event: any) => {
                                            props.handleMorePopup(row);
                                        }}>
                                            {`More`}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {!props.isMore && (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                    <Typography style={{ color: "red" }}>
                        *No more data available
                    </Typography>
                    </div>
                )}
                <div style={{ padding: "10px 0px" }}>
                    <Button
                    className={props.classes.loadMoreButton}
                    onClick={props.handleVolumePageIncrease}
                    disabled={props.isLoading || !props.isMore || plan.result_per_screen !== 'Unlimited'}
                    data-testid="viewMore"
                    >
                    {props.isLoading ? "Loading..." : "View More"}
                    </Button>
                </div>
            </Paper>
        </>
    )
    // Customizable Area End
}

export default function VolumeTable(props: any) {
    const {
        // Customizable Area Start
        volumeUpResponseData,
        handleMoreResult,
        noData,
        selectedItemData,
        isMoreData
        // Customizable Area End
    } = props.state
    return(
        // Customizable Area Start
        <>
        { selectedItemData === 'Volumes' && isMoreData && noData && <Typography style={{ textAlign: 'center', fontSize: '18px', fontWeight: 'bold' }}>No Data Found</Typography>}
        {/* Volume Up */}
        {volumeUpResponseData.length > 0 && !handleMoreResult &&
                <SingleTable
                    data={volumeUpResponseData}
                    data-testid="volume-table"
                    header={['#', 'Name', 'Price', '24h High', '24h Low', 'Market Cap', 'Volume', 'Circulation', Object.keys(volumeUpResponseData[0]).includes('volume_average') ? 'Volume Average' : 'Last Volume','']}
                    handleMorePopup={props.handleMorePopup}
                    isMore={isMoreData}
                    classes={props.classes}
                    handleVolumePageIncrease={props.handleVolumePageIncrease}
                    isLoading={props.state.isLoading}
                    filteredChip={props.state.filteredChip}
                    handleFilterDelete={props.handleFilterDelete}
                    openVolumeUpAndDownPopup={props.openVolumeUpAndDownPopup}
                />
            }
         </>
        // Customizable Area End
    )
}

import React, { useState } from 'react';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import getTheme from './Theme.web';

type ContextType = {
  currentTheme: any;
  setTheme: (anything: any) => void;
};

// eslint-disable-next-line no-unused-vars
export const CustomThemeContext = React.createContext<ContextType | null>(null);

const CustomThemeProvider = (props: any) => {
  // eslint-disable-next-line react/prop-types
  const { children } = props

  // Read current theme from localStorage or maybe from an api
  const currentTheme = localStorage.getItem('appTheme') || 'ligthTheme'

  // State to hold the selected theme name
  const [themeName, _setThemeName] = useState(currentTheme)

  // Retrieve the theme object by theme name
  const theme = getTheme(themeName);
  // delete theme.typography;

  // Wrap _setThemeName to store new theme names in localStorage
  const setThemeName = (name: any) => {
    localStorage.setItem('appTheme', name)
    _setThemeName(name)
  }

  const contextValue = {
    currentTheme: themeName,
    setTheme: setThemeName,
  }

  const THEME = createTheme({
    typography: {
      fontFamily: ['"Plus Jakarta Text"', 'sans-serif'].join(',')
     }
   })

   console.log("MY THEME=======", theme);

  return (
    <CustomThemeContext.Provider value={contextValue}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </CustomThemeContext.Provider>
  )
}

export default CustomThemeProvider
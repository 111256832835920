import React from "react";

import {
  StyleSheet,
  Text,
  Image,
  TouchableOpacity,
  View,
  TextInput,
  ScrollView,
  TouchableWithoutFeedback,
  Button,
  Platform,
  // Customizable Area Start
  Dimensions,
  FlatList,
  StatusBar,
  // Customizable Area End
} from "react-native";

import LoyaltySystem2Controller, {
  Props,
  configJSON
} from "./LoyaltySystem2Controller";
// Customizable Area Start
import { COLORS } from "../../../framework/src/Globals";
import SubHeader from "../../../components/src/SubHeader";
import Scale from "../../../components/src/Scale";
import { theme } from "../../../framework/src/theme";
const { height, width } = Dimensions.get("screen");
// Customizable Area End

export default class LoyaltySystem2 extends LoyaltySystem2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  tablelist = ({ item }: any) => {
    return (
      <>
       <View style={{ flex: 1, justifyContent: 'center' }}>
                <View style={{ borderWidth: 1, borderColor: this.getColorTheme('light', '#494949', COLORS.white),marginHorizontal:Scale(10) }}>
                  <View style={{ flexDirection: 'row',justifyContent:'space-between',padding:Scale(10) }}>
                    <Text style={[styles.tabletext,{ color: this.getColorTheme('light', '#494949', COLORS.white)}]}>{item.use_case}</Text>
                    <Text style={[styles.tabletext1,{ color: this.getColorTheme('light', '#494949', COLORS.white)}]}>{item.date}</Text>
                    <Text style={[styles.tables,{color:(item.reward_points) === '-100' ? "red" :'#01a24e'}]}>{item.reward_points}</Text>
        </View>
        </View>
        </View>
      </>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ScrollView keyboardShouldPersistTaps="always" style={[styles.container, { backgroundColor: this.getColorTheme("light", "#fff", "#000") }]}>
        <TouchableWithoutFeedback
        testID="anys"
          onPress={() => {
            this.hideKeyboard();
          }}
        >
          {/* Customizable Area Start */}
          <View>
            <StatusBar barStyle="dark-content" backgroundColor={"#F4F8F5"} />
                <SubHeader
                  title={"Loyalty Rewards"}
                  onPress={() => this.props.navigation.goBack()}
                  testID="any"
                />
            <ScrollView style={{paddingTop:20}}>
              <View style={[styles.mainView, { backgroundColor: this.getColorTheme("light", '#499E5A', '#499E5A') }]}>
                <Text style={styles.textviews}>Total Rewards</Text>
                <Text style={[styles.textviewnext,{marginTop:Scale(5)}]}>{this.state.total_loyalty_points} points earned</Text>
                <View style={{ marginTop: '5%', marginLeft: 20 }}>
                  <TouchableOpacity
                    testID="scrolltoEndBtnqw"
                    style={styles.button}
                  >
                    <Text style={styles.btnText}>{"Redeem Points"}</Text>
                  </TouchableOpacity>
                </View>
              </View>
              <View style={[styles.secondView, { backgroundColor: this.getColorTheme("light", theme.light.headeBg, '#363333') }]}>
                <View>
                  <Text style={[styles.secondtext, { color: this.getColorTheme('light', COLORS.black, COLORS.white) }]}>{this.state.pending_referral} Pending Rewards</Text>
                  <Text style={[styles.secondtext2, { marginTop:Scale(5),paddingBottom: 10, color: this.getColorTheme('light', COLORS.black, COLORS.white) }]}>{this.state.points} Points </Text>
                  <View style={{ flexDirection: 'row' }}>
                    <Text style={[styles.secondtext, { color: this.getColorTheme('light', COLORS.black, COLORS.white) }]}>Refer & Earn More Points</Text>
                  </View>
                </View>
                <View>

                  <View style={{ alignItems: 'center', marginTop: '5%' }}>
                    <TouchableOpacity
                      testID="scrolltoEndBtn" 
                      onPress={this.navigatepage}
                      style={styles.buttonview}
                    >
                      <Text style={styles.btnTexts}>{"Send Invite"}</Text>
                    </TouchableOpacity>
                  </View>
                </View>

              </View>
              <Text style={[styles.secondtextnext, { color: this.getColorTheme('light', COLORS.black, COLORS.white),marginBottom:Scale(10) }]}>Points History</Text>
              <View style={{ flex: 1, justifyContent: 'center' }}>
                <View style={{ borderWidth: 1, borderColor: this.getColorTheme('light', '#494949', COLORS.white),marginHorizontal:Scale(10) }}>
                  <View style={{ flexDirection: 'row',justifyContent:'space-between',padding:Scale(10) }}>
                    <Text style={[styles.table,{ color: this.getColorTheme('light', '#494949', COLORS.white)}]}>TRANSACTION DETAILS</Text>
                    <Text style={[styles.table1,{ color: this.getColorTheme('light', '#494949', COLORS.white)}]}>DATE</Text>
                    <Text style={[styles.table2,{ color: this.getColorTheme('light', '#494949', COLORS.white)}]}>REWARD POINTS</Text>
                  </View>
                </View>
                </View>
                {this.state.texterror === 'No loyalty points history found' ?  
                 <View style={{ borderWidth: 1, borderColor: this.getColorTheme('light', '#494949', COLORS.white),marginLeft:Scale(10),marginRight:Scale(10),alignItems:'center', }}>
                 <Text style={[styles.secondtext, { color: this.getColorTheme('light', COLORS.black, COLORS.white), marginBottom:Scale(10)}]}>{this.state.texterror}</Text>
                 </View>
                :
                <>
                <ScrollView style={{paddingBottom:Scale(30)}}>
                <FlatList
                  testID="tabledata"
                  nestedScrollEnabled
                  data={this.state.data}
                  renderItem={this.tablelist}
                  extraData={this.context.mode}
                />
           </ScrollView>
              </>}
            </ScrollView>
          </View>
          {/* Customizable Area End */}
        </TouchableWithoutFeedback>
      </ScrollView>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
  },
  topContainers: {
    display: "flex",
  },
  mainView: {
    height: 170,
    marginLeft: 10,
    marginRight: 10
  },
  textviews: {
    color: '#fff',
    fontSize: 18,
    fontFamily: 'PlusJakartaText-Bold',
    marginTop: 5,
    marginLeft: 20
  },
  textviewnext: {
    color: '#fff',
    fontSize: 18,
    marginLeft: 20,
  },
  button: {
    width: width / 2,
    height: 50,
    borderRadius: 30,
    backgroundColor: '#ffffff',
    justifyContent: 'center',
    elevation: 10,

  },
  btnText: {
    textAlign: 'center',
    fontSize: 16,
    color: '#01a24e',
    fontFamily: 'PlusJakartaText-Bold',
    letterSpacing: 0.5
  },
  secondView: {
    height: 200,
    backgroundColor: "#F7F7F7",
    borderWidth: 1,
    borderColor: '#000',
    marginLeft: 10,
    marginRight: 10
  },
  secondtext: {
    fontSize: 18,
    color: '#000',
    fontFamily: 'PlusJakartaText-Bold',
    marginLeft: 20,
    marginTop: 10
  },
  secondtext2: {
    fontSize: 18,
    color: '#000',
    marginLeft: 20,

  },
  buttonview: {
    width: width / 1.15,
    height: 50,
    borderRadius: 30,
    backgroundColor: '#499E5A',
    justifyContent: 'center',
    elevation: 10,
  },
  btnTexts: {
    textAlign: 'center',
    fontSize: 16,
    color: '#fff',
    fontFamily: 'PlusJakartaText-Bold',
    letterSpacing: 0.5

  },
  secondtextnext: {
    fontSize: 20,
    color: '#000',
    fontFamily: 'PlusJakartaText-Bold',
    marginLeft: 12,
    marginTop: 10
  },
  table:{
    fontSize:10,
    color:'#494949',
    textAlign:'left',
    flex:1,
  
  },
  table1:{
    fontSize:10,
    color:'#494949',
    textAlign:'center',
    flex:1,
  
  },
  table2:{
    fontSize:10,
    color:'#494949',
    textAlign:'right',
    flex:1,
  
  },
  tabletext:{
    fontSize:10,
    color:'#494949',
    fontFamily: 'PlusJakartaText-Bold', 
    textAlign:'left',
    flex:1

  },
  tabletext1:{
    fontSize:10,
    color:'#494949',
    fontFamily: 'PlusJakartaText-Bold', 
    textAlign:'center',
    flex:1

  },
  tables:{
    fontSize:10,
    color:'#01a24e',
    textAlign:'right',
    flex:1
  },
  

});
// Customizable Area End

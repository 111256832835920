import { StyleSheet, TouchableOpacity, Text, View } from 'react-native'
import React, { useState } from 'react'
import { COLORS } from '../../framework/src/Globals'
interface Props{
    onChange: any
    toogleState:boolean;
}

const CustomSwitch = ({onChange,toogleState}:Props) => {
    return (
        <View style={styles.container}>
            <TouchableOpacity style={[styles.outter, toogleState ? { justifyContent: 'flex-end', backgroundColor:COLORS.green } : { justifyContent: 'flex-start', backgroundColor: "#767577" }]} activeOpacity={1} onPress={onChange}>
                <View style={styles.inner} />
            </TouchableOpacity>
        </View>
    )
}

export default CustomSwitch

const styles = StyleSheet.create({
    container: { flex: 1, alignItems: "center", justifyContent: "center" },
    inner: { width: 24, height: 24, backgroundColor: "#fff", borderRadius: 30, elevation: 8, shadowOffset: { width: 0, height: 0 }, shadowOpacity: 0.15 },
    outter: { width: 52, height: 24, backgroundColor: "gray", borderRadius: 30, alignItems: "center",flexDirection:"row",paddingHorizontal:2 }
})
import React from "react"
// Customizable Area Start
import Paper from "@material-ui/core/Paper";
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Chip from '@material-ui/core/Chip';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { currentPlan, removePlusMinusSymbol } from "../../../components/src/chartUtils.web";
// Customizable Area End

interface SingleTableType {
    // Customizable Area Start
    data: any,
    header: any,
    handleMorePriceAction: any,
    isMore: any,
    classes: any,
    handleIndicatorPageIncrease: any,
    isLoading: any,
    filteredChip: any,
    handleFilterDelete: any,
    activeItem: any;
    moreBtnClicked: any;
    // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
const SingleTable = (props:SingleTableType) => {
    // Customizable Area Start
    const plan = currentPlan();
    return (
        <>
            <Paper data-testid='indicatorPaper' >
                <Paper component="ul" className={props.classes.filtered} elevation={0}>
                    {props.filteredChip.map((data: any, key: any) => {
                        let icon;
                        return (
                            <li key={data.key}>
                                <Chip
                                    icon={icon}
                                    label={data}
                                    onDelete={props.handleFilterDelete(data)}
                                    className={props.classes.chip}
                                />
                            </li>
                        );
                    })}
                </Paper>
                <Divider />
                <TableContainer style={{ maxHeight: '850px' }}>
                    <Table aria-label="simple table">
                        <TableHead style={{ position: 'sticky', top: 0, whiteSpace: 'nowrap', zIndex: 1, backgroundColor: localStorage.getItem('appTheme') == 'ligthTheme' ? 'rgba(255, 255, 255, 255)' : '#333742' }}>
                            <TableRow>
                                {
                                    props.header.map((item: any, key: any) => (
                                        <TableCell key={key} align="left" style={{ fontSize: '12px' }}>{item}</TableCell>
                                    ))
                                }
                                <TableCell align="left" style={{ fontSize: '12px' }}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.data.map((row: any, key: any) => (
                                <TableRow
                                    key={row.id}
                                >
                                    <TableCell component="th" scope="row">
                                        {key + 1}
                                    </TableCell>
                                    <TableCell align="left">
                                        <CardHeader
                                            avatar={
                                                <Avatar
                                                    data-testid="more-detail"
                                                    alt="Remy Sharp"
                                                    style={{ width: '32px', height: '32px', cursor: 'pointer' }}
                                                    src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${row.id}.png`}
                                                    onClick={() => props.handleMorePriceAction(row)}
                                                >
                                                </Avatar>
                                            }
                                            title={
                                                <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                                                    {row.name}
                                                </Typography>
                                            }
                                            subheader={
                                                <Typography gutterBottom variant="body2" component="div" style={{ color: '#c6cfca', fontWeight: 'bold', fontSize: '12px' }}>
                                                    {row.symbol}
                                                </Typography>
                                            }
                                            style={{ padding: '0px' }}
                                        />
                                    </TableCell>
                                    <TableCell align="left">
                                        <div style={{ display: 'flex', flexDirection: 'column', whiteSpace: 'nowrap' }}>
                                            <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                                                {row.price}
                                            </Typography>
                                            <div
                                                style={{ display: 'flex', alignItems: 'center', marginLeft: '-5px' }}
                                            >
                                                {row.percentage_change_24h.toString().charAt(0) === "+" ? <ArrowDropUpIcon style={{ color: '#01A24E' }} /> : <ArrowDropDownIcon style={{ color: '#E86A3F' }} />}
                                                <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px', color: row.percentage_change_24h.toString().charAt(0) === "+" ? '#01A24E' : '#E86A3F' }}>
                                                    {removePlusMinusSymbol(row.percentage_change_24h)} (24H)
                                                </Typography>
                                            </div>
                                        </div>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                                            {row?.one_day?.high}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                                            {row?.one_day?.low}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                                            {row.market_cap}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                                            {row.volume}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                                            {row.circulation}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        {row['relative_strength_index'] &&
                                            <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                                                {row.relative_strength_index.toFixed(6)}
                                            </Typography>}
                                        {row['money_flow_index'] &&
                                            <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                                                {row.money_flow_index.toFixed(6)}
                                            </Typography>}
                                        {row['stochastic_value'] &&
                                            <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                                                {row.stochastic_value.toFixed(6)}
                                            </Typography>}
                                        {row['commodity_channel_index'] &&
                                            <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                                                {row.commodity_channel_index.toFixed(6)}
                                            </Typography>}
                                        {row['simple_moving_average'] &&
                                            <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                                                {row.simple_moving_average.toFixed(6)}
                                            </Typography>}
                                        {row['exponential_moving_average'] &&
                                            <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                                                {row.exponential_moving_average.toFixed(6)}
                                            </Typography>}
                                        {row['moving_average_convergence_divergence'] &&
                                            <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                                                {row.moving_average_convergence_divergence.toFixed(6)}
                                            </Typography>}
                                        {row['average_directional_index'] &&
                                            <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px', wordBreak: 'normal' }}>
                                                {row.average_directional_index.map((v: number) => v.toFixed(6)).join(',\n')}
                                            </Typography>}
                                        {row['on_balance_volume'] &&
                                            <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px', wordBreak: 'normal' }}>
                                                {row.on_balance_volume.map((v: number) => v.toFixed(6)).join(",\n")}
                                            </Typography>}
                                        {row['chaikin_money_flow'] &&
                                            <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px', wordBreak: 'normal' }}>
                                                {
                                                    row.chaikin_money_flow.some((v: any) => v === null)
                                                        ? 'NA'
                                                        : row.chaikin_money_flow
                                                            .map((v: any) => (v !== null ? v.toFixed(6) : 'NA'))
                                                            .join(',\n')
                                                }
                                            </Typography>}
                                        {row['bollinger_band'] &&
                                            <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                                                <div style={{ display: 'flex', flexDirection: 'column', whiteSpace: 'nowrap' }}>
                                                    <span>Lower - {row.bollinger_band.lower_band.toFixed(6)}</span>
                                                    <span>Middle - {row.bollinger_band.middle_band.toFixed(6)}</span>
                                                    <span>Upper - {row.bollinger_band.upper_band.toFixed(6)}</span>
                                                </div>
                                            </Typography>}
                                        {row['average_true_range'] &&
                                            <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px', wordBreak: 'normal' }}>
                                                {row.average_true_range.map((v: string) => Number(v).toFixed(6)).join(',\n')}
                                            </Typography>
                                        }
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography gutterBottom variant="body2" data-testid="more-detail-btn" component="a" style={{ fontWeight: 'bold', fontSize: '12px', cursor: 'pointer', textDecoration: 'underline', color: 'rgba(1,162,78,255)' }} onClick={(event: any) => {
                                            props.handleMorePriceAction(row);
                                        }}>
                                            {`More`}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {!props.isMore && (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <Typography style={{ color: "red" }}>
                            *No more data available
                        </Typography>
                    </div>
                )}
                <div style={{ padding: "10px 0px" }}>
                    <Button
                        data-testid="view-more-btn"
                        className={props.classes.loadMoreButton}
                        onClick={props.handleIndicatorPageIncrease}
                        disabled={props.isLoading || !props.isMore || props.activeItem == '' || plan.result_per_screen !== 'Unlimited'}
                    >
                        {props.isLoading ? "Loading..." : "View More"}
                    </Button>
                </div>
            </Paper>
        </>
    )
    // Customizable Area End
}

export default function IndicatorsTable(props: any) {
    // Customizable Area Start
    const {
        moreResultPriceAction,
        indicatorMFIResponseData,
        indicatorRSIResponseData,
        indicatorStochasticResponseData,
        indicatorCCIResponseData,
        indicatorMovingAverageResponseData,
        indicatorMACDResponseData,
        indicatorADXResponseData,
        indicatorOBVResponseData,
        indicatorCMFResponseData,
        indicatorBBResponseData,
        indicatorATRResponseData,
        noIndicatorData,
        activeItem,
        indiactorBtnClicked,
        isMoreData
    } = props.state;

    const { classes } = props;

    return (
        <>
            {noIndicatorData && isMoreData && <Typography style={{ textAlign: 'center', fontSize: '18px', fontWeight: 'bold' }} >No Data Found</Typography>}
            {/* RSI */}
            {indicatorRSIResponseData.length > 0 && !moreResultPriceAction &&
                <SingleTable
                    data={indicatorRSIResponseData}
                    header={['#', 'Name', 'Price', '24h High', '24h Low', 'Market Cap', 'Volume', 'Circulation', 'Relative Strength Index', '']}
                    handleMorePriceAction={props.handleMorePriceAction}
                    isMore={props.state.isMoreData}
                    classes={classes}
                    handleIndicatorPageIncrease={props.handleIndicatorPageIncrease}
                    isLoading={props.state.isLoading}
                    filteredChip={props.state.filteredChip}
                    handleFilterDelete={props.handleFilterDelete}
                    activeItem={activeItem}
                    moreBtnClicked={indiactorBtnClicked}
                />
            }
            {/* MFI */}
            {indicatorMFIResponseData.length > 0 && !moreResultPriceAction &&
                <SingleTable
                    data={indicatorMFIResponseData}
                    header={['#', 'Name', 'Price', '24h High', '24h Low', 'Market Cap', 'Volume', 'Circulation', 'Money Flow Index ', '']}
                    handleMorePriceAction={props.handleMorePriceAction}
                    isMore={props.state.isMoreData}
                    classes={classes}
                    handleIndicatorPageIncrease={props.handleIndicatorPageIncrease}
                    isLoading={props.state.isLoading}
                    filteredChip={props.state.filteredChip}
                    handleFilterDelete={props.handleFilterDelete}
                    activeItem={activeItem}
                    moreBtnClicked={indiactorBtnClicked}
                />
            }
            {/* Stochastic */}
            {indicatorStochasticResponseData.length > 0 && !moreResultPriceAction &&
                <SingleTable
                    data={indicatorStochasticResponseData}
                    header={['#', 'Name', 'Price', '24h High', '24h Low', 'Market Cap', 'Volume', 'Circulation', 'Stochastic', '']}
                    handleMorePriceAction={props.handleMorePriceAction}
                    isMore={props.state.isMoreData}
                    classes={classes}
                    handleIndicatorPageIncrease={props.handleIndicatorPageIncrease}
                    isLoading={props.state.isLoading}
                    filteredChip={props.state.filteredChip}
                    handleFilterDelete={props.handleFilterDelete}
                    activeItem={activeItem}
                    moreBtnClicked={indiactorBtnClicked}
                />
            }
            {/* CCI */}
            {indicatorCCIResponseData.length > 0 && !moreResultPriceAction &&
                <SingleTable
                    data={indicatorCCIResponseData}
                    header={['#', 'Name', 'Price', '24h High', '24h Low', 'Market Cap', 'Volume', 'Circulation', 'Commodity Channel Index', '']}
                    handleMorePriceAction={props.handleMorePriceAction}
                    isMore={props.state.isMoreData}
                    classes={classes}
                    handleIndicatorPageIncrease={props.handleIndicatorPageIncrease}
                    isLoading={props.state.isLoading}
                    filteredChip={props.state.filteredChip}
                    handleFilterDelete={props.handleFilterDelete}
                    activeItem={activeItem}
                    moreBtnClicked={indiactorBtnClicked}
                />
            }
            {/* Moving Average */}
            {indicatorMovingAverageResponseData.length > 0 && !moreResultPriceAction &&
                <SingleTable
                    data={indicatorMovingAverageResponseData}
                    header={['#', 'Name', 'Price', '24h High', '24h Low', 'Market Cap', 'Volume', 'Circulation', 'Moving Average', '']}
                    handleMorePriceAction={props.handleMorePriceAction}
                    isMore={props.state.isMoreData}
                    classes={classes}
                    handleIndicatorPageIncrease={props.handleIndicatorPageIncrease}
                    isLoading={props.state.isLoading}
                    filteredChip={props.state.filteredChip}
                    handleFilterDelete={props.handleFilterDelete}
                    activeItem={activeItem}
                    moreBtnClicked={indiactorBtnClicked}
                />
            }
            {/* MACD */}
            {indicatorMACDResponseData.length > 0 && !moreResultPriceAction &&
                <SingleTable
                    data={indicatorMACDResponseData}
                    header={['#', 'Name', 'Price', '24h High', '24h Low', 'Market Cap', 'Volume', 'Circulation', 'Moving Average Convergence Divergence', '']}
                    handleMorePriceAction={props.handleMorePriceAction}
                    isMore={props.state.isMoreData}
                    classes={classes}
                    handleIndicatorPageIncrease={props.handleIndicatorPageIncrease}
                    isLoading={props.state.isLoading}
                    filteredChip={props.state.filteredChip}
                    handleFilterDelete={props.handleFilterDelete}
                    activeItem={activeItem}
                    moreBtnClicked={indiactorBtnClicked}
                />
            }
            {/* ADX */}
            {indicatorADXResponseData.length > 0 && !moreResultPriceAction &&
                <SingleTable
                    data={indicatorADXResponseData}
                    header={['#', 'Name', 'Price', '24h High', '24h Low', 'Market Cap', 'Volume', 'Circulation', 'Average Directional Index', '']}
                    handleMorePriceAction={props.handleMorePriceAction}
                    isMore={props.state.isMoreData}
                    classes={classes}
                    handleIndicatorPageIncrease={props.handleIndicatorPageIncrease}
                    isLoading={props.state.isLoading}
                    filteredChip={props.state.filteredChip}
                    handleFilterDelete={props.handleFilterDelete}
                    activeItem={activeItem}
                    moreBtnClicked={indiactorBtnClicked}
                />
            }
            {/* OBV */}
            {indicatorOBVResponseData.length > 0 && !moreResultPriceAction &&
                <SingleTable
                    data={indicatorOBVResponseData}
                    header={['#', 'Name', 'Price', '24h High', '24h Low', 'Market Cap', 'Volume', 'Circulation', 'On Balance Volume', '']}
                    handleMorePriceAction={props.handleMorePriceAction}
                    isMore={props.state.isMoreData}
                    classes={classes}
                    handleIndicatorPageIncrease={props.handleIndicatorPageIncrease}
                    isLoading={props.state.isLoading}
                    filteredChip={props.state.filteredChip}
                    handleFilterDelete={props.handleFilterDelete}
                    activeItem={activeItem}
                    moreBtnClicked={indiactorBtnClicked}
                />
            }
            {/* CMF */}
            {indicatorCMFResponseData.length > 0 && !moreResultPriceAction &&
                <SingleTable
                    data={indicatorCMFResponseData}
                    header={['#', 'Name', 'Price', '24h High', '24h Low', 'Market Cap', 'Volume', 'Circulation', 'Chaikin Money Flow', '']}
                    handleMorePriceAction={props.handleMorePriceAction}
                    isMore={props.state.isMoreData}
                    classes={classes}
                    handleIndicatorPageIncrease={props.handleIndicatorPageIncrease}
                    isLoading={props.state.isLoading}
                    filteredChip={props.state.filteredChip}
                    handleFilterDelete={props.handleFilterDelete}
                    activeItem={activeItem}
                    moreBtnClicked={indiactorBtnClicked}
                />
            }
            {/* Bollinger Bands */}
            {indicatorBBResponseData.length > 0 && !moreResultPriceAction &&
                <SingleTable
                    data={indicatorBBResponseData}
                    header={['#', 'Name', 'Price', '24h High', '24h Low', 'Market Cap', 'Volume', 'Circulation', 'Band', '']}
                    handleMorePriceAction={props.handleMorePriceAction}
                    isMore={props.state.isMoreData}
                    classes={classes}
                    handleIndicatorPageIncrease={props.handleIndicatorPageIncrease}
                    isLoading={props.state.isLoading}
                    filteredChip={props.state.filteredChip}
                    handleFilterDelete={props.handleFilterDelete}
                    activeItem={activeItem}
                    moreBtnClicked={indiactorBtnClicked}
                />
            }
            {/* ATR */}
            {indicatorATRResponseData.length > 0 && !moreResultPriceAction &&
                <SingleTable
                    data={indicatorATRResponseData}
                    header={['#', 'Name', 'Price', '24h High', '24h Low', 'Market Cap', 'Volume', 'Circulation', 'ATR Line', '']}
                    handleMorePriceAction={props.handleMorePriceAction}
                    isMore={props.state.isMoreData}
                    classes={classes}
                    handleIndicatorPageIncrease={props.handleIndicatorPageIncrease}
                    isLoading={props.state.isLoading}
                    filteredChip={props.state.filteredChip}
                    handleFilterDelete={props.handleFilterDelete}
                    activeItem={activeItem}
                    moreBtnClicked={indiactorBtnClicked}
                />
            }
        </>
    )
    // Customizable Area End
}

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import AsyncStorage from "@react-native-async-storage/async-storage";
import HelperClass from "../../../components/src/HelperClass";
import { CommonContext } from "../../../framework/src/CommonContextProvider";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  screenIndex: any;
  userName: any;
  profileImage:any
  userImage: any;
  enable: boolean;
  openModal: boolean;
  openDeleteAccountModal:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NavigationMenuController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  static contextType = CommonContext;
  logoutText: any;
  logoutHead: any;
  profileImageId:any
  deleteApiId:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      screenIndex: 0,
      userName: "",
      userImage: "",
      
      profileImage:"",
      enable: false,
      openModal: false,
      openDeleteAccountModal:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.logoutText = configJSON.logoutText;
    this.logoutHead = configJSON.logoutHead;
    this.deleteApiId = "";
    this.profileImageId = "";
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      // alert(JSON.stringify(responseJson))
      if(apiRequestCallId==this.profileImageId){
        // alert(JSON.stringify(message)+"lol")
      }
      if (responseJson.message == "Account successfully deleted") {
        alert(responseJson.message)
       this.handleDeleteApiResponse();
      }
    }
    // Customizable Area End

  }
  // Customizable Area Start
  async getUser() {
    let token = await AsyncStorage.getItem("userToken");
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.profileImageId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.fetchProfileImage
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      token: token
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
 async handleDeleteApiResponse(){
  // alert("P")
 await AsyncStorage.removeItem("userToken")
  this.setState({openDeleteAccountModal:false})
  this.props.navigation.navigate("EmailAccountLoginBlock")
 }
 // Customizable Area End

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  // Customizable Area Start
  async deleteAccountApi (){
    const tokenData = await AsyncStorage.getItem("userToken");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: tokenData
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteAccount
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
   }
   // Customizable Area End
  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // Customizable Area Start

  async getUserData() {
    let userName = await AsyncStorage.getItem("userName");
    let userImage = await AsyncStorage.getItem("userImage");
    this.setState({
      userName: userName,
      userImage: userImage
    });
  }

  async componentDidMount() {
    this.getUser();
    this.getUserData();
  }

  logoutClick = async () => {
    // alert("logout")
    this.setState({
      openModal: true
    });

  };
  deleteAccountClick = async () => {
    this.setState({
      openDeleteAccountModal: true
    });
  };
  
  onClickNo = () => {
    this.setState({
      openModal: false
    });
  };
  onClickDeleteAccountNo = () => {
    this.setState({
      openDeleteAccountModal: false
    });
  };
  clearUserToken = async () => {
    await AsyncStorage.removeItem("referralId");
    await AsyncStorage.removeItem("referredById");
    await AsyncStorage.removeItem("userToken");
    await AsyncStorage.removeItem("userName");
    await AsyncStorage.removeItem("userImage");
  
    
    this.setState(
      {
        openModal: false
      },
      () => {
        this.props.navigation.reset({
          index: 0,
          routes: [{ name: "EmailAccountLoginBlock" }]
        });
      }
    );
  };

  toggleSwitch(){
    let darkmode = HelperClass.getDarkMode()
    // alert(darkmode+"darkmode")
     HelperClass.setDarkMode(!darkmode)
    // const context=this.context;
    if(this.context.mode=='light'){
     this.context.addData('dark')
     this.setState({enable:!this.state.enable})

    }else{
     this.context.addData('light')
     this.setState({enable:!this.state.enable})

    }
  }
  
  // Customizable Area End
  
}

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {
  activeDerivatives,
  activeFunda,
  activeIndicators,
  activePriceAction,
  activeVolume,
  derivatives,
  funda,
  indicators,
  priceAction,
  volume
} from "./assets";
import React from 'react'
import { AsyncStorage } from "react-native";
import SelectDropdown from 'react-native-select-dropdown';
import HelperClass from "../../../components/src/HelperClass";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  route?:any;
  onClick?:any;
  onPriceFilter?:any;
  onPriceHighLow?:any;
  onSupportResistance?:any;
  onSupportPattern?:any;
  onCandleStick?:any;
  value?:any;
  check?:any;
  save?:any;
  saveApply?:any;
  mode?:any;
  clearAll?:any;
  isBasic:boolean
  // onReservalPattern:any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  data: any;
  arrayHolder: any;
  filterItems: any;
  fundamentalArray: any;
  newData:any;
  priceFrom: any;
  customValue: any;
  derivativesArray: any;
  derivativesLabel: any;
  isPutCallRation: boolean;
  putCallRatioFrom: any;
  putCallRatioTo: any
  buildupArray: any;
  topLable: any;
  timeFameIndLabel:any;
  //Price Action
  price: any;
  // arrayHolder: any;
  // categoryArray: any;
  catHolder: any;
  // defaultButtonText: any;
  priceActionArray: any;
  optionsArray: any
  timeFrameArray: any;
  isParcentage: boolean;
  parcentageValue: any;
  standardSupportArray: any;
  standardSupportLabel: any;
  channelArray: any;
  timeOptionArray: any;
  reversalOptionArray: any;
  pickerValue: any;
  priceActiveLabel: any;
  timeFrameValue: any;
  isClose: boolean
  standardSupportValue: any;
  reversalOptionValue: any;
  timeoptionValue: any;
  channelValue: any;
  isPriceTrandingUP: boolean;
 candaleTimeFrameArray:any;
 candaleTimeFrameLabel:any
 isBasic:boolean;

}

interface SS {
  id: any;
}

export default class FilteritemsController extends BlockComponent<
  Props,
  S,
  SS
> {
  getProductApiCallId: any;
  // Customizable Area Start
  dropdownRef: any;
  priceActionRef: any;  
  SaveBtn: string;  
  categoriesApiId:string;
  getAllcatDataID:string;
  getSocailEngMentID:string;
  FiltersTxt: string;
  rank10apiId:string;
  getMarketCapID:string;
  secondTextInput:any;
  socialEngBySymbolId:any;
  selectOptionTxt: string;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess), 
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      token: "",
      data: "",
      newData:null,
      filterItems:'',
      fundamentalArray:'',
      timeFameIndLabel:'',
      priceFrom: [
        {
          id: 1,
          value: "lowtohigh",
          name: "Volume Low to High",
          isSelected: false
        },
        {
          id: 2,
          value: "hightolow",
          name: "Volume High to Low",
          isSelected: false
        }
      ],
      customValue:'',
      derivativesArray: [
        "Long Buildup", "Short Buildup", "Short Covering", "Profit Booking", "Put Call Ratio"
      ],
      derivativesLabel: 'Select Derivative Option',
      isPutCallRation: false,
      putCallRatioFrom: '',
      putCallRatioTo: '',
      buildupArray: [],
      topLable: '',
      //Price Action
      price: [],
      arrayHolder: [],
      catHolder: [],  
      timeFrameArray: [],
      isParcentage: false,
      parcentageValue: '',
      standardSupportArray: [],
      standardSupportLabel: '',
      channelArray: [],
      timeOptionArray: [],
      reversalOptionArray: [],
      priceActionArray: [
        "Gain/Loss", "High/low", "Breakouts/Breakdowns", "Support/Resistance", "Standard Patterns",
        "Reversal/Continuation Patterns", "Candlesticks"
      ],
      pickerValue: '',
      priceActiveLabel: '',
      timeFrameValue: '',
      isClose: false,
      standardSupportValue: '',
      reversalOptionValue: '',
      timeoptionValue: '',
      channelValue: '',
      isPriceTrandingUP: false,
      candaleTimeFrameArray:[],
      candaleTimeFrameLabel:'',
      isBasic:false,
      optionsArray: []
     
    };
    this.selectOptionTxt = configJSON.selectOptionTxt;
    this.FiltersTxt = configJSON.FiltersTxt;
    this.SaveBtn = configJSON.SaveBtn;

    this.rank10apiId = '';
    this.categoriesApiId='';
    this.getAllcatDataID = '';
    this.getSocailEngMentID='';
    this.getMarketCapID='';
    this.priceActionRef = React.createRef<SelectDropdown>();
    this.getPlan()
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getPlan()
    // Customizable Area Start
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };
  navigateToFilter = () => {
      let arrayHolder = this.state.arrayHolder;
      const priceRange= arrayHolder.map((element:any) => element.attributes.price);
      var min = Math.min.apply(null, priceRange);
      var max = Math.max.apply(null, priceRange);
      let params = this.props.navigation.state.params ;
      if(params != undefined){
        if(params.priceSelectedMin && params.priceSelectedMax){
          this.props.navigation.push("Filteroptions" , {min: min , 
            max:max , 
            priceSelectedMin:params.priceSelectedMin,
            priceSelectedMax:params.priceSelectedMax
          });
        }    
      }else{
        this.props.navigation.push("Filteroptions" , {min: min , max:max});
      }
  }
  
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
      this.getListRequest(token);
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getProductApiCallId != null &&
      this.getProductApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setState({arrayHolder:responseJson.data})
        let params = this.props.navigation.state.params;
        if (params !== undefined) {
          if(params.data){
            if(params.data.length > 0){
              this.setState({ data: params.data });
            }else{
              this.showAlert("Error", "No data Found")  
            }
          }
        } else {
          this.setState({
            data: responseJson.data
          });
        }
       
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.showAlert("Error", errorReponse)  
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if(apiRequestCallId && responseJson){
        
      }

    }
    // Customizable Area End
  }
  getListRequest = (token: any) => {
    
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area Start
  onClickFilter(d:any){

    //  this.setState({newData:d})
   
  }
  onClickSave(){

    // this.props.navigation.navigate('SavePage',{data:this.state.newData})
    
  }
 


  

  onChangeDerivatives(index: any, selectedItem: any) {
    const temp = ["Top 10", "Top 50", "Custom"]
    if (selectedItem === 'Long Buildup') {
      this.setState({
        isPutCallRation: false,
        derivativesLabel: 'Long Buildup',
        buildupArray: temp
 
      })
    }
    else if (selectedItem === 'Short Buildup') {
     
      this.setState({
        derivativesLabel: 'Short Buildup',
        isPutCallRation: false,
        buildupArray: temp
        
      })

    }
    else if (selectedItem === 'Short Covering') {
     
      this.setState({ 
        buildupArray: temp,
        isPutCallRation: false,
        derivativesLabel: 'Short Covering'
      })

    }
    else if (selectedItem === 'Profit Booking') {
    
      this.setState({
        isPutCallRation: false,
        buildupArray: temp,
        derivativesLabel: 'Profit Booking'
      })

    }
    else if (selectedItem === 'Put Call Ratio') {
      this.setState({
        isPutCallRation: true,
        derivativesLabel: '',
        buildupArray: []
      })
    }
    else if (selectedItem === 'Top 10') {
      this.setState({
        topLable: selectedItem
      }, () => {


      })
    }
    else if (selectedItem === 'Top 50') {
      this.setState({
        topLable: selectedItem
      })
    }
    else if (selectedItem === 'Custom') {
      this.setState({
        topLable: selectedItem
      })
    }

  }

  // Price Action start
  onChangePriceAction(item: any, selectitem: any) {
    // alert(selectitem+"price")
    if(this.state.isBasic){
    switch (selectitem) {
        case "Gain/Loss":
            this.setState({
                optionsArray:   ["Price Up%", "Price Down%"],
                standardSupportArray: [],
                timeFrameArray: [],
                channelArray: [],
                timeOptionArray: [],
                reversalOptionArray: [],
                timeFrameValue: '',
                standardSupportLabel: '',
            });
            break;
        case "Price Up%":
        case "Price Down%":
            this.setState({ 
              timeFrameArray: [ "1 hr",  "1 day"],
               standardSupportValue: '', isPriceTrandingUP: false });
            break;
        case "Price Trending UP":
        case "Price Trending Down":
          
            this.setState({ 
              timeFrameArray: [ "1 hr",  "1 day"], 
              isPriceTrandingUP: true, standardSupportValue: '' });
            break;
        case "High/low":
            this.setState({
                optionsArray:  ["Near All Time High", "Near All Time Low", "At All Time High", "At All Time Low"],
                timeFrameArray: [],
                isParcentage: false,
                standardSupportArray: [],
                channelArray: [],
                timeOptionArray: [],
                reversalOptionArray: [],
                pickerValue: '',
                standardSupportValue: ''
            });
            break;
        case "Breakouts/Breakdowns":
            this.setState({
                optionsArray:  ["All Time High Breakout", "All Time Low Breakdown"],
                timeFrameArray: [],
                standardSupportArray: [],
                isParcentage: false,
                channelArray: [],
                timeOptionArray: [],
                reversalOptionArray: [],
                pickerValue: ''
            });
            break;
        case "Support/Resistance":
            this.setState({
                optionsArray:  ["Standard Support", "Standard Resistance"],
                timeFrameArray: [],
                isParcentage: false,
                standardSupportArray: [],
                channelArray: [],
                timeOptionArray: [],
                reversalOptionArray: [],
                pickerValue: '',
                timeFrameValue: ''
            });
            break;
        case "Standard Support":
        case "Fibonacci Support":
            const temp = [
                { id: 1, name: 'Near S1', value: 'NEAR_S1' },
                { id: 2, name: 'Near S2', value: 'NEAR_S2' },
                { id: 3, name: 'Near S3', value: 'NEAR_S3' },
                { id: 4, name: 'Breakdown S1', value: 'BREAKDOWN_S1' },
                { id: 5, name: 'Breakdown S2', value: 'BREAKDOWN_S2' },
                { id: 6, name: 'Breakdown S3', value: 'BREAKDOWN_S3' },
            ];
            this.setState({
                standardSupportValue: '',
                timeFrameValue: '',
                standardSupportArray: temp,
                standardSupportLabel: selectitem
            });
            break;
        case "Standard Resistance":
        case "Fibonacci Resistance":
            this.setState({
                standardSupportValue: '',
                timeFrameValue: '',
                standardSupportArray: [
                  { id: 1, name: 'Near R1', value: 'NEAR_R1' },
                  { id: 2, name: 'Near R2', value: 'NEAR_R2' },
                  { id: 3, name: 'Near R3', value: 'NEAR_R3' },
                  { id: 4, name: 'Breakout R1', value: 'BREAKDOWN_R1' },
                  { id: 5, name: 'Breakout R2', value: 'BREAKDOWN_R2' },
                  { id: 6, name: 'Breakout R3', value: 'BREAKDOWN_R3' },
              ]
            });
            break;
        case "Standard Patterns":
            this.setState({
                optionsArray:  ["Flat Channel"],
                standardSupportArray: [],
                channelArray: [],
                timeOptionArray: [],
                reversalOptionArray: [],
                timeFrameArray: [],
                pickerValue: '',
                isParcentage: false,
            });
            break;
        case "Flat Channel":
        case "Ascending Channel":
        case "Descending Channel":
            this.setState({
                channelArray: [
                  { id: 1, name: 'Inside Channel', value: 'inside channel' },
                  { id: 2, name: 'Channel Breakout', value: 'channel breakout' },
                  { id: 3, name: 'Channel Breakdown', value: 'channel breakdown' }
              ]
            });
            break;
        case "inside channel":
        case "channel breakout":
        case "channel breakdown":
            this.setState({
                timeOptionArray: [
                  { id: 1, name: '1 day', value: '1d' },
                 
              ]
            });
            break;
        case "Reversal/Continuation Patterns":
            this.setState({
                optionsArray: ["Bullish Reversals", "Bearish Reversals", "Continuation Patterns"],
                standardSupportArray: [],
                channelArray: [],
                timeOptionArray: [],
                reversalOptionArray: [],
                timeFrameArray: [],
                pickerValue: '',
                isParcentage: false,
                candaleTimeFrameArray: [],
                candaleTimeFrameLabel: ''
            });
            break;
        case "Bullish Reversals":
            this.setState({
                reversalOptionArray:  ["Double Bottom"],
                candaleTimeFrameArray: ["1 hr",  "1 day"]
            });
            break;
        case "Bearish Reversals":
            this.setState({
                reversalOptionArray: ["Double Top"],
                candaleTimeFrameArray: ["1 hr", "1 day"]
            });
            break;
        case "Continuation Patterns":
            this.setState({
                reversalOptionArray:  ["Symmetrical Triangle"],
                candaleTimeFrameArray: ["1 hr", "1 day"],
                candaleTimeFrameLabel: ''
            });
            break;
        case "Candlesticks":
            this.setState({
                optionsArray: ["Bullish Candle Scans", "Bearish Candle Scans", "Other Patterns"],
                standardSupportArray: [],
                channelArray: [],
                timeOptionArray: [],
                reversalOptionArray: [],
                timeFrameArray: [],
                pickerValue: '',
                isParcentage: false,
                candaleTimeFrameLabel: '',
                candaleTimeFrameArray: []
            });
            break;
        case "Bullish Candle Scans":
            this.setState({
                reversalOptionArray: ["Bullish Marubozu", "Bullish Engulfing", "Bullish Harami"],
                candaleTimeFrameArray: ["1 hr",  "1 day"]
            });
            break;
        case "Bearish Candle Scans":
            this.setState({
                reversalOptionArray:  ["Bearish Marubozu", "Bearish Engulfing", "Bearish Harami"],
                candaleTimeFrameArray: ["1 hr",  "1 day"]
            });
            break;
        case "Other Patterns":
            this.setState({
                reversalOptionArray: [''],
                candaleTimeFrameArray: ["1 hr", "1 day"]
            });
            break;
        default:
            break;
    }
  }else{
    switch (selectitem) {
      case "Gain/Loss":
        this.gainloss()
          
          break;
      case "Price Up%":
      case "Price Down%":
          this.priceDown()
          break;
      case "Price Trending UP":
      case "Price Trending Down":
        
         this.priceTrendDown()
          break;
      case "High/low":
         this.highLow()
          break;
      case "Breakouts/Breakdowns":
         this.breakOuts()
          break;
      case "Support/Resistance":
         this.support()
          break;
      case "Standard Support":
      case "Fibonacci Support":
        this.fibbSupport(selectitem)
          break;
      case "Standard Resistance":
      case "Fibonacci Resistance":
          this.fibbResist()
          break;
      case "Standard Patterns":
        this.standardPattern()
         
          break;
      case "Flat Channel":
      case "Ascending Channel":
      case "Descending Channel":
         this.descChannel()
          break;
      case "inside channel":
      case "channel breakout":
      case "channel breakdown":
          this.channelBreakdown()
          break;
      case "Reversal/Continuation Patterns":
         this.reversalPattern()
          break;
      case "Bullish Reversals":
         this.bullishRev()
          break;
      case "Bearish Reversals":
          this.bearishRev()
          break;
      case "Continuation Patterns":
          this.contiPattern()
          break;
      case "Candlesticks":
          this.candlestick()
          break;
      case "Bullish Candle Scans":
          this.bullishCandle()
          break;
      case "Bearish Candle Scans":
         this.bearishCandle()
          break;
      case "Other Patterns":
          this.otherPattern()
          break;
      default:
          break;
  }
  }
}


  onClickClear() {
    this.priceActionRef?.current?.reset();
    HelperClass.setPriceMainOption("")
    this.setState({
      priceActiveLabel: '',
      standardSupportArray: [],
      channelArray: [],
      timeOptionArray: [],
      reversalOptionArray: [],
      timeFrameArray: [],
      pickerValue: '',
      isClose: true,
      optionsArray: [],
      standardSupportValue: '',
      reversalOptionValue: '',
      timeoptionValue: '',
      channelValue: '',
      isParcentage: false,
      isPriceTrandingUP: false,
      timeFrameValue: '',
      standardSupportLabel: '',
      candaleTimeFrameLabel:'',
      candaleTimeFrameArray:[]

    })
  }
  //Price Action end//
  getByCondition =(condition: any, match:any, result1:any, result2:any) =>{
    return condition === match ? result1 : result2
  }
  renderConditionalItem = (condition: any, result1: any, result2?: any) => {
    return condition ? result1 : result2 || null
  }
  getColorTheme = (mode: any, color1: any, color2: any) => {
    return this.props.mode === mode ? color1 : color2
  }

  async getPlan(){
    let currentPlan = await AsyncStorage.getItem("userInfo")
    if(currentPlan === "basic"){
      this.setState({isBasic:true})
    }
     
  }
  gainloss(){
    this.setState({
      optionsArray:  ["Price Up%", "Price Down%", "Price Trending UP", "Price Trending Down"],
      standardSupportArray: [],
      timeFrameArray: [],
      channelArray: [],
      timeOptionArray: [],
      reversalOptionArray: [],
      timeFrameValue: '',
      standardSupportLabel: '',
  });
  }
  priceDown(){
    this.setState({ 
      timeFrameArray: ["5 min", "10 min", "15 min", "30 min", "45 min", "1 hr", "2 hr", "3 hr", "4 hr", "6 hr", "12 hr", "1 day", "2 days", "3 days", "7 days", "14 days", "15 days", "30 days", "60 days", "90 days", "365 days"],
       standardSupportValue: '', isPriceTrandingUP: false });
  }
  priceTrendDown(){
    this.setState({ 
      timeFrameArray: ["5 min", "10 min", "15 min", "30 min", "45 min", "1 hr", "2 hr", "3 hr", "4 hr", "6 hr", "12 hr", "1 day", "2 days", "3 days", "7 days", "14 days", "15 days", "30 days", "60 days", "90 days", "365 days"], 
      isPriceTrandingUP: true, standardSupportValue: '' });
  }
  highLow(){
    this.setState({
      optionsArray:  ["Near All Time High", "Near All Time Low", "At All Time High", "At All Time Low", "Near 52 Weeks High", "Near 52 Weeks Low", "Near Past Week High", "Near Past Week Low"],
      timeFrameArray: [],
      isParcentage: false,
      standardSupportArray: [],
      channelArray: [],
      timeOptionArray: [],
      reversalOptionArray: [],
      pickerValue: '',
      standardSupportValue: ''
  });
  }
  breakOuts(){
    this.setState({
      optionsArray: ["All Time High Breakout", "All Time Low Breakdown", "52 Week High Breakout", "52 Week Low Breakdown", "Past Week High Breakout", "Past Week Low Breakdown"],
      timeFrameArray: [],
      standardSupportArray: [],
      isParcentage: false,
      channelArray: [],
      timeOptionArray: [],
      reversalOptionArray: [],
      pickerValue: ''
  });
  }
  support(){
    this.setState({
      optionsArray: ["Standard Support", "Standard Resistance", "Fibonacci Support", "Fibonacci Resistance"],
      timeFrameArray: [],
      isParcentage: false,
      standardSupportArray: [],
      channelArray: [],
      timeOptionArray: [],
      reversalOptionArray: [],
      pickerValue: '',
      timeFrameValue: ''
  });
  }
  fibbSupport(selectitem:any){
    const temp = [
      { id: 1, name: 'Near S1', value: 'NEAR_S1' },
      { id: 2, name: 'Near S2', value: 'NEAR_S2' },
      { id: 3, name: 'Near S3', value: 'NEAR_S3' },
      { id: 4, name: 'Breakdown S1', value: 'BREAKDOWN_S1' },
      { id: 5, name: 'Breakdown S2', value: 'BREAKDOWN_S2' },
      { id: 6, name: 'Breakdown S3', value: 'BREAKDOWN_S3' },
  ];
  this.setState({
      standardSupportArray: temp,
      standardSupportLabel: selectitem,
      standardSupportValue: '',
      timeFrameValue: ''
      
  });
  }
  fibbResist(){
    this.setState({
      standardSupportValue: '',
      timeFrameValue: '',
      standardSupportArray: [
        { id: 1, name: 'Near R1', value: 'NEAR_R1' },
        { id: 2, name: 'Near R2', value: 'NEAR_R2' },
        { id: 3, name: 'Near R3', value: 'NEAR_R3' },
        { id: 4, name: 'Breakout R1', value: 'BREAKDOWN_R1' },
        { id: 5, name: 'Breakout R2', value: 'BREAKDOWN_R2' },
        { id: 6, name: 'Breakout R3', value: 'BREAKDOWN_R3' },
    ]
  });
  }
  standardPattern(){
    this.setState({
      optionsArray: ["Flat Channel", "Ascending Channel", "Descending Channel"],
      standardSupportArray: [],
      channelArray: [],
      timeOptionArray: [],
      reversalOptionArray: [],
      timeFrameArray: [],
      pickerValue: '',
      isParcentage: false,
  });
  }
  descChannel(){
    this.setState({
      channelArray: [
        { id: 1, name: 'Inside Channel', value: 'inside channel' },
        { id: 2, name: 'Channel Breakout', value: 'channel breakout' },
        { id: 3, name: 'Channel Breakdown', value: 'channel breakdown' }
    ]
  });
  }
  channelBreakdown(){
    this.setState({
      timeOptionArray: [
        { id: 1, name: '1 day', value: '1d' },
        { id: 2, name: '1 week', value: '7d' },
        { id: 3, name: '15 days', value: '15d' },
        { id: 4, name: '1 month', value: '30d' }
    ]
  });
  }
  reversalPattern(){
    this.setState({
      optionsArray: ["Bullish Reversals", "Bearish Reversals", "Continuation Patterns"],
      standardSupportArray: [],
      channelArray: [],
      timeOptionArray: [],
      reversalOptionArray: [],
      timeFrameArray: [],
      pickerValue: '',
      isParcentage: false,
      candaleTimeFrameArray: [],
      candaleTimeFrameLabel: ''
  });
  
  }
  bullishRev(){
    this.setState({
      reversalOptionArray: ["Double Bottom", "Triple Bottom", "Inverted Head & Shoulder"],
      candaleTimeFrameArray: ["1 hr", "2 hr", "3 hr", "4 hr", "6 hr", "12 hr", "1 day", "2 days", "3 days", "7 days", "14 days", "15 days", "30 days", "60 days", "90 days", "365 days"]
  });
  }
  bearishRev(){
    this.setState({
      reversalOptionArray: ["Double Top", "Triple Top", "Head & Shoulder"],
      candaleTimeFrameArray: ["1 hr", "2 hr", "3 hr", "4 hr", "6 hr", "12 hr", "1 day", "2 days", "3 days", "7 days", "14 days", "15 days", "30 days", "60 days", "90 days", "365 days"]
  });
  }
  contiPattern(){
    this.setState({
      reversalOptionArray: ["Symmetrical Triangle", "Ascending Triangle", "Descending Triangle", "Rising Wedge", "Falling Wedge"],
      candaleTimeFrameArray: ["1 hr", "1 day", "7 days", "14 days", "15 days", "30 days"],
      candaleTimeFrameLabel: ''
  });
  }
  candlestick(){
    this.setState({
      optionsArray: ["Bullish Candle Scans", "Bearish Candle Scans", "Other Patterns"],
      standardSupportArray: [],
      channelArray: [],
      timeOptionArray: [],
      reversalOptionArray: [],
      timeFrameArray: [],
      pickerValue: '',
      isParcentage: false,
      candaleTimeFrameLabel: '',
      candaleTimeFrameArray: []
  });
  }
  bullishCandle(){
    this.setState({
      reversalOptionArray: ["Bullish Marubozu", "Bullish Engulfing", "Bullish Harami", "Piercing Line", "Hammer", "Tweezer Bottoms", "Three Advancing Soldiers"],
      candaleTimeFrameArray: ["1 hr", "2 hr", "3 hr", "4 hr", "6 hr", "12 hr", "1 day", "2 days", "3 days", "7 days", "14 days", "15 days", "30 days", "60 days", "90 days", "365 days"]
  });
  }
  bearishCandle(){
    this.setState({
      reversalOptionArray: ["Bearish Marubozu", "Bearish Engulfing", "Bearish Harami", "Dark Cloud Cover", "Hanging Man", "Tweezer Tops", "Three Black Crows"],
      candaleTimeFrameArray: ["1 hr", "2 hr", "3 hr", "4 hr", "6 hr", "12 hr", "1 day", "2 days", "3 days", "7 days", "14 days", "15 days", "30 days", "60 days", "90 days", "365 days"]
  });
  }
  otherPattern(){
    this.setState({
      reversalOptionArray: ['Spinning Top', 'Neutral Doji', 'Dragonfly Doji', 'Gravestone Doji'],
      candaleTimeFrameArray: ["1 hr", "2 hr", "3 hr", "4 hr", "6 hr", "12 hr", "1 day", "2 days", "3 days", "7 days", "14 days", "15 days", "30 days", "60 days", "90 days", "365 days"]
  });
  }
  navigateToScreen = (path: string) => {
    let toNavigate = new Message(getName(MessageEnum.NavigationMessage));
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), path);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
  }
  startBasicPlan() {
    this.navigateToScreen('Customisableusersubscriptions');
 }
  // Customizable Area End
}

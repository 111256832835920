export const theme = {
    light: {
      color: "#03dac5",
      backgroundColor: "#F7F7F7",
      highlight: "#ffffff",
      textColor:'#000000',
      lightGray:'#F4F8F5',
      placeHolderColor:'#9D9EAC',
      headeBg:'#F4F8F5',
      menuColor: "green",
      searchPlaceHolderTxtColor: "#A0A5A3",
      curvedBoxView: "#fff"
    },
    dark: {
      color: "#bb86fc",
      backgroundColor: "#000",
      highlight: "#343643",
      textColor:'#ffffff',
      lightGray:'#74797E',
      secondaryColor:'#242329',
      placeHolderColor:'#D7DBDD',
      headeBg:'#000',
      menuColor: "#fff",
      searchPlaceHolderTxtColor: "#777987",
      curvedBoxView: "#242329"
    }
  };
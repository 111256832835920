import React from "react";
// Customizable Area Start
import CryptoAssetTableWebController, {
    Props
} from "./CryptoAssetTableController.web";

import { StyleRules, withStyles } from "@material-ui/core/styles";

import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import LinearProgress from '@material-ui/core/LinearProgress';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import "chartjs-plugin-datalabels";
import Loader from "../../../components/src/Loader.web";
import { removePlusMinusSymbol } from "../../../components/src/chartUtils.web";
// Customizable Area End
const styles: StyleRules = {
    // Customizable Area Start
    Root: {
        // height: '100vh',
        // overflowX: 'hidden',
    },
    LoginButton: {
        color: 'rgba(1,162,78,255)',
        cursor: 'pointer',
        padding: '8px 20px',
        marginLeft: '10px',
        border: 'none',
        borderRadius: '10px',
        textAlign: 'center',
        '@media (max-width: 1325px)': {
            margin: '10px 10px 0px 0px',
        }
    },
    SelectedCategoryName: {
        fontWeight: 'bold',
        fontSize: '15px',
        '@media (min-width: 830px) and (max-width: 1325px)': {
            marginTop: '10px',
        }
    },
    SelectedCategoryBox: {
        display: 'flex',
        justifyContent: 'space-between',
        '@media (max-width: 830px)': {
            flexDirection: 'column'
        }
    },
    Top10Header: {
        color: '#94A59C'
    },
    PercentChange: {
        display: "flex",
        alignItems: "center",
        marginLeft: '-12px'
    },
    PercentChangeItem: {
        fontWeight: "bold",
        fontSize: "12px",
    },
    TableRow: {
        cursor: "pointer"
    }
    // Customizable Area End
}

export class CryptoAssetTableWeb extends CryptoAssetTableWebController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const backgroundval = localStorage.getItem('appTheme') == 'darkTheme' ? '#333742' : 'rgb(239, 239, 239)';
        const { CryptoData, ShowMore, SelectedCategoryItem } = this.state;
        const dataToShow = ShowMore ? CryptoData : CryptoData.slice(0, 10);

        return (
            <Paper className={classes.Root} data-testid="rootPaper" square style={{ backgroundColor: localStorage.getItem('appTheme') == 'ligthTheme' ? '#F4F8F6' : '#232429' }}>
                <Loader loading={this.state.loading} />
                <div style={{ display: 'flex', flexDirection: 'column', padding: '22px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        <Button
                            variant="contained"
                            data-testid="back-btn"
                            startIcon={<ArrowLeftIcon />}
                            style={{ background: 'rgba(1,162,78,255)', color: '#ffffff', textTransform: 'none', marginBottom: '10px' }}
                            onClick={() => window.location.href = "/"}
                        >
                            Back
                        </Button>
                    </div>
                    <div className={classes.SelectedCategoryBox}>
                        <div>
                            <Typography variant="h6" className={classes.SelectedCategoryName}>
                                {SelectedCategoryItem.replace(/_/g, " ").toUpperCase()}
                            </Typography>
                        </div>
                        <div>
                            {this.state.CryptoCategories.map((category: any, key: any) => (
                                <button data-testid={`${category.name}`}
                                    className={classes.LoginButton}
                                    style={{ backgroundColor: this.state.SelectedCategoryItem == category.key ? 'rgba(1,162,78,255)' : backgroundval, color: this.state.SelectedCategoryItem == category.key ? '#ffffff' : '' }}
                                    key={key}
                                    onClick={() => this.getSelectedCategoryCryptoDetails(category)} >
                                    {category.name}</button>
                            ))}
                        </div>
                    </div>
                    <div style={{ marginTop: '15px' }}>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        {['#', 'Name', 'Price', '24h', '7d', 'MCAP', 'Volume', 'Circulation'].map((item, key) => (
                                            <TableCell align="left" key={key} className={key !== 0 && classes.Top10Header}>{item}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dataToShow.map((element: any, key: any) => (
                                        <TableRow key={element.id} data-testid={`cryptoDetail-${element.id}`} className={classes.TableRow} onClick={() => {
                                            window.location.href = `/CryptoDetailWeb/${element.id}`;
                                        }}>
                                            <TableCell component="th" scope="row">
                                                {key + 1}
                                            </TableCell>
                                            <TableCell align="left">
                                                <CardHeader
                                                    avatar={<Avatar
                                                        alt="Remy Sharp"
                                                        style={{ width: '32px', height: '32px' }}
                                                    src={element.image_url}
                                                    >
                                                    </Avatar>}
                                                    title={<Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                        {`${element.name}`}
                                                    </Typography>}
                                                    style={{ padding: '0px' }} />
                                            </TableCell>
                                            <TableCell>
                                                <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                    {element.price}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <div
                                                    className={classes.PercentChange}
                                                >
                                                    {element.percentage_change_24h.toString().charAt(0) ===
                                                        "-" ? (
                                                        <ArrowDropDownIcon style={{ color: "#E86A3F" }} />
                                                    ) : (
                                                        <ArrowDropUpIcon style={{ color: "#01A24E" }} />
                                                    )}
                                                    <Typography
                                                        gutterBottom
                                                        variant="body2"
                                                        component="div"
                                                        className={classes.PercentChangeItem}
                                                        style={{
                                                            whiteSpace: 'nowrap',
                                                            color: element.percentage_change_24h
                                                                .toString()
                                                                .charAt(0) === "-"
                                                                ? "#E86A3F"
                                                                : "#01A24E",
                                                        }}
                                                    >
                                                        {removePlusMinusSymbol(element.percentage_change_24h)}
                                                    </Typography>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div
                                                    className={classes.PercentChange}
                                                >
                                                    {element.percent_change_7d.toString().charAt(0) ===
                                                        "-" ? (
                                                        <ArrowDropDownIcon style={{ color: "#E86A3F" }} />
                                                    ) : (
                                                        <ArrowDropUpIcon style={{ color: "#01A24E" }} />
                                                    )}
                                                    <Typography
                                                        gutterBottom
                                                        variant="body2"
                                                        component="div"
                                                        style={{
                                                            whiteSpace: 'nowrap',
                                                            fontWeight: "bold",
                                                            fontSize: "12px",
                                                            color: element.percent_change_7d
                                                                .toString()
                                                                .charAt(0) === "-"
                                                                ? "#E86A3F"
                                                                : "#01A24E",
                                                        }}
                                                    >
                                                        {removePlusMinusSymbol(element.percent_change_7d)}
                                                    </Typography>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                    {element.market_cap}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                    {element.volume}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="left">
                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                        {element.circulation}
                                                    </Typography>
                                                    <BorderLinearProgress variant="determinate" value={parseFloat(element.circulation_percentage?.split(" ")[0]) || 0} style={{ marginTop: '5px' }} />
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <button data-testid='cryptoDataMoreButton'
                            className={classes.LoginButton}
                            style={{ float: 'right', margin: '10px 0px', backgroundColor: 'rgba(1,162,78,255)', color: '#ffffff' }}
                            onClick={ShowMore ? this.loadLessData : this.loadMoreData} 
                            >
                            {ShowMore ? "Less..." : "More..."}</button>
                    </div>
                </div>
            </Paper>
        )
        // Customizable Area End
    }
}

export default withStyles(styles)(CryptoAssetTableWeb);
// Customizable Area Start
const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 6,
        borderRadius: 5,
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 500],
    },
    bar: {
        borderRadius: 5,
        backgroundColor: '#94a59c',
    },
}))(LinearProgress);
// Customizable Area End
// Customizable Area Start
//@ts-nocheck
import React from "react";
import {
  View,
  Text,
  Image,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
  Dimensions,
  FlatList,
  Modal,
  StatusBar,
  Alert
} from "react-native";
import { threed_mockup1, threed_mockup2, rsi, news, eduCenter, noAds, unlimited, bell, Community, Dipchart, token } from './assets'
import { theme } from "../../../framework/src/theme";
import CustomText from "../../../components/src/text";
import AdsBanner from "../../../components/src/AdsBanner";
import Scale from "../../../components/src/Scale";
const { width, height } = Dimensions.get('screen')
import CustomisableusersubscriptionsController from "./CustomisableusersubscriptionsController";
// Customizable Area End

export default class SubscriptionDetails extends CustomisableusersubscriptionsController {
  // Customizable Area Start
  getColorTheme = (mode: any, color1: any, color2: any) => {
    return this.context.mode === mode ? color1 : color2
  }
  colorScheme = (item :any)=>{
    let color
    if(this.context.mode === "light"){
      color = item.id % 2 === 0 ? '#F4F8F5' : 'white'
      return color;
    }else if(this.context.mode === "dark"){
      color = item.id % 2 === 0 ? '#777E7C' : '#333742'
      return color;
    }
  }
  todayItems = ({ item }: any) => {
    return (
      <View style={{backgroundColor :  this.colorScheme(item), flexDirection: "row", paddingHorizontal: 10, height: 50} }>
        {item?.key !== 'cost' &&
          <>
            <View style={styles.todayName1}>
              <CustomText
                style={[
                  styles.todayText1,
                  {
                    color: this.getColorTheme("light", theme.light.textColor, theme.dark.textColor)
                  }
                ]}
              >
                {item?.feature}
              </CustomText>
            </View>
            <View style={styles.todayPrice1}>
              <CustomText
                style={[
                  styles.todayText1,
                  {
                    textAlign: "left",
                    color: this.getColorTheme("light", theme.light.textColor, theme.dark.textColor)
                  }
                ]}
              >
                {this.state.subscriptionArray.basic[item?.key]}
              </CustomText>
            </View>
            <View style={{
              width: "25%",
              justifyContent: "center", marginLeft: '12%'
            }}>
              <View
                style={[styles.todayBackgroundGreen]}
              >
                <CustomText
                  style={[
                    styles.todayText1,
                    {
                      color: this.getColorTheme("light", "#000", theme.dark.textColor)
                    }
                  ]}
                >
                  {this.state.subscriptionArray.pro[item?.key]}
                </CustomText>
              </View>
            </View>
          </>}
        {item?.key === 'cost' &&
          <>
            <View style={styles.todayName1}>
              <View style={{ flexDirection: 'row' }} >
                <TouchableOpacity
                testID="monthly"
                  style={[this.state.costType === 'monthly' && { backgroundColor: '#499E5A' }, { padding: Scale(10), borderRadius: 10 }]}
                  onPress={() => { this.setState({ costType: 'monthly' }) }}
                >
                  <CustomText
                    style={[
                      styles.todayText1,
                      {
                        textAlign: "left",
                        color: this.state.costType === 'monthly' ? "#FFF" : this.getColorTheme("light", theme.light.textColor, theme.dark.textColor)
                      }
                    ]}
                  >
                    {'Monthly'}
                  </CustomText>
                </TouchableOpacity>
                <TouchableOpacity
                testID="yearly"
                  style={[this.state.costType === 'yearly' && { backgroundColor: '#499E5A' }, { padding: Scale(10), borderRadius: 10 }]}
                  onPress={() => { this.setState({ costType: 'yearly' }) }}
                >
                  <CustomText
                    style={[
                      styles.todayText1,
                      {
                        textAlign: "left",
                        color: this.state.costType === 'yearly' ? "#FFF" : this.getColorTheme("light", theme.light.textColor, theme.dark.textColor)
                      }
                    ]}
                  >
                    {'Annualy'}
                  </CustomText>
                </TouchableOpacity>
              </View>
            </View>
            <View style={styles.todayPrice1}>
              <CustomText
                style={[
                  styles.todayText1,
                  {
                    textAlign: "left",
                    color: this.getColorTheme("light", theme.light.textColor, theme.dark.textColor)
                  }
                ]}
              >
                {this.state.subscriptionArray.basic[item.key]}
              </CustomText>
            </View>
            <View style={{
              width: "25%",
              justifyContent: "center", marginLeft: '12%'
            }}>
              <View
                style={[styles.todayBackgroundGreen]}
              >
                <CustomText
                  style={[
                    styles.todayText1,
                    {
                      color: this.getColorTheme("light", "#000", theme.dark.textColor)
                    }
                  ]}
                  testID="customtext"
                >
                {this.state.isreferred 
                ? <> <Text testID={'subscription'} style={{color: this.getColorTheme("light", "#000", theme.dark.textColor),textDecorationLine:'line-through'}}>{this.state.subscriptionArray.pro[`${this.state.costType}_${item.key}`]}</Text> {' '}
                {this.state.costType === 'monthly' 
                ?  this.state.subscriptionArray.pro.monthly_discounted_cost 
                : this.state.subscriptionArray.pro.yearly_discounted_cost}</>
                :this.state.subscriptionArray.pro[`${this.state.costType}_${item.key}`]}  
                </CustomText>
              </View>
            </View>
          </>}
      </View>  
    );
  };
  todayItemSeparator = () => {
    return <View style={styles.todayItemSeperator1} />;
  };

  subscriptionModal = () => {
    return (
      <>
      <Modal
      data-testId={'onRequestClosed'}
        visible={this.state.openModal}
        transparent={true}
        onRequestClose={() =>
          this.setState({ openModal: !this.state.openModal })
        }
      >
        <View style={styles.modalViews}>
          <View
            style={[
              styles.modalBackgrounds,
              {
                backgroundColor:
                  this.context.mode === "light" ? "#fff" : "#3C3F50"
              }
            ]}
          >
            <View style={styles.modalDetails1}>
              <CustomText style={styles.selectOptionTexts}>Subscription</CustomText>
            </View>
            <View style={styles.descriptionViews}>
              <CustomText
                style={[styles.modalDescriptions,
                {
                  color:
                    this.context.mode === "light"
                      ? theme.light.textColor
                      : theme.dark.textColor
                }
                ]}
              >
                {this.state.modalDescription}
              </CustomText>
            </View>

            {this.state.isSubscriptionSelected === true ? (
              <View style={styles.modalOptionView}>
                <TouchableOpacity
                 testID="onClickNoBtn1"
                  style={styles.modalNoBtns}
                  onPress={() => this.onClickNo()}
                >
                  <CustomText style={styles.modalCamTxt}>No</CustomText>
                </TouchableOpacity>
                <TouchableOpacity
                 testID="modalYesBtn"
                  style={styles.modalYesBtn}
                  onPress={() => this.getCardData()}
                >
                  <CustomText style={styles.modalCamTxt}>Yes</CustomText>
                </TouchableOpacity>
              </View>
            ) : (
              <View style={styles.modalOkBtns}>
                <TouchableOpacity
                  testID="onClickNoBtn2"
                  style={styles.modalNoBtns}
                  onPress={() => { this.onClickNo() }}
                >
                  <Text style={styles.modalCamTxt}>Ok</Text>
                </TouchableOpacity>
              </View>
            )}
          </View>
        </View>
      </Modal>
      </>
    );
  };

  cardModal = () => {
    return (
      <Modal
      data-testId={'onRequestClose'}
        visible={this.state.cardModal}
        transparent={true}
        onRequestClose={() =>
          this.setState({ cardModal: !this.state.cardModal })
        }
      >
        <View style={styles.modalViews}>
          <View
            style={[
              styles.modalBackgrounds,
              {
                backgroundColor:
                  this.context.mode === "light" ? "#fff" : "#3C3F50"
              }
            ]}
          >
            <View style={styles.modalDetails1}>
              <CustomText style={styles.selectOptionTexts}>Subscription</CustomText>
            </View>
            <View style={styles.descriptionViews}>
              <CustomText
                style={[
                  styles.modalDescriptions,
                  {
                    color:
                      this.context.mode === "light"
                        ? theme.light.textColor
                        : theme.dark.textColor
                  }
                ]}
              >
                {this.state.modalDescription}
              </CustomText>
            </View>
            <View style={styles.modalOkBtns}>
              <TouchableOpacity
              testID="subScribedBtn"
                style={styles.modalNoBtns}
                onPress={() =>
                  this.state.modalDescription ===
                    "You have subscribed successfully"
                    ? this.onClickCardOk()
                    : this.onNavigateToCardScreen()
                }
              >
                <Text style={styles.modalCamTxt}>Ok</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    );
  };
  getColorText = () => {
    return this.context.mode === "light" ? "#000" : "#fff";
  }

  getFreeTrial = () => {
    const item = this.state.subscriptionList.find((ele: any) => ele.attributes.name === 'Free Trial');
    return item?.attributes?.free_trial_period
  }

  alertMessage = ()=>{
    if(this.state.currentSubscription?.attributes?.subscription_plan?.data?.attributes?.subscription_type === 'pro'){
      Alert.alert("You have already subscribed to pro plan")
    }else{
      this.onPlanSelect(this.state.subscriptionArray['free_trial']) 
    }
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <View style={{ flex: 1, backgroundColor: this.context.mode === 'light' ? theme.light.headeBg : theme.dark.headeBg }} >
        <View style={{ flex: 1, }} >
          <ScrollView
          testID="scrollview"
            ref={ref => {
              this.scrollview_ref = ref;
            }}
            showsVerticalScrollIndicator={false}
            style={[styles.detailsContainer, { backgroundColor: this.context.mode === 'light' ? theme.light.headeBg : theme.dark.headeBg }]}
            contentContainerStyle={{ flexGrow: 1 }}
          >
            <StatusBar
              barStyle={this.context.mode === 'light' ? 'dark-content' : 'light-content'} backgroundColor={this.context.mode === 'light' ? theme.light.headeBg : theme.dark.headeBg}
            />

            <View style={{ flex: 1, backgroundColor: this.context.mode === "light" ? "#fff" : "#3C3F50", paddingTop: 30 }}>
              <View style={{ alignItems: 'center', }}>
                <View style={{}}>
                  <CustomText style={[styles.textWelcome, { color: this.getColorText() }]}>{"welcome to kryptokia"}</CustomText>

                </View>
                <CustomText style={{
                  marginTop: '5%', color: '#BCBFC5', fontSize: 14, fontFamily: 'PlusJakartaText-Bold',
                }}>{"India's first crypto screening app."}</CustomText>
              </View>
              <View style={{ alignItems: 'center', marginTop: '5%' }}>
                <TouchableOpacity 
                   testID="scrolltoEndBtn"
                   onPress={() => this.scrollToEnd()} style={styles.btn}>
                  <CustomText style={styles.btnText}>{"subscribe to pro"}</CustomText>

                </TouchableOpacity>
              </View>
              <View style={{ alignItems: 'center', flexDirection: 'row', justifyContent: 'center', marginVertical: 15 }}>

                <Image source={threed_mockup1} style={styles.imageStyle} />

                <Image source={threed_mockup2} style={styles.imageStyle} />

              </View>
              <View style={{ alignItems: 'center', marginTop: '5%' }}>
                <CustomText style={[styles.textWelcome, { color: this.getColorText(), fontStyle: 'italic', textTransform: 'none', marginVertical: 10 }]}>{"Kya aapne crypto kiya?"}</CustomText>
                <TouchableOpacity style={styles.checkoutBtn} onPress={this.scrollToEnd}>
                  <CustomText style={styles.checkoutBtnText}>{"Checkout Pro Features"}</CustomText>
                </TouchableOpacity>
              </View>
              <CustomText style={[styles.oneText, { color: this.getColorText() }]}>{"One Stop Solution for Crypto"}</CustomText>
              <CustomText style={[styles.oneText, { color: this.getColorText(), marginTop: 0 }]}>{"Traders / Investors"}</CustomText>
              <View style={{ alignItems: 'center', marginTop: '5%' }}>
                <View style={{ width: width / 1.2 }}>
                  <View style={{ backgroundColor: '#fff', alignItems: 'center', borderTopLeftRadius: 10, borderTopRightRadius: 10, elevation: 1 }}>
                    <Image source={rsi} style={styles.rsiImg} />
                  </View>
                  <TouchableOpacity 
                    testID="filterItemBtn"
                    onPress={this.screenfilter} style={{ height: 70, alignItems: 'center', justifyContent: 'center', backgroundColor: '#F7F7F7', marginBottom: 20 }}>
                    <CustomText style={[styles.textWelcome, { textTransform: 'none' }]}>{"Screener"}</CustomText>
                  </TouchableOpacity>
                  <View style={{ backgroundColor: '#fff', alignItems: 'center', borderTopLeftRadius: 10, borderTopRightRadius: 10, elevation: 5 }}>
                    <Image source={news} style={styles.rsiImg} />
                  </View>
                  <TouchableOpacity 
                    testID="RichTxtEditor"
                    onPress={this.screenNews} style={{ height: 70, alignItems: 'center', justifyContent: 'center', backgroundColor: '#F7F7F7', elevation: 5, borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}>
                    <CustomText style={[styles.textWelcome, { textTransform: 'none' }]}>{"News Portal"}</CustomText>
                  </TouchableOpacity>
                </View>
              </View>
              <View style={{ alignItems: 'center', marginTop: '5%' }}>
                <View style={{ width: width / 1.2 }}>
                  <View style={{ backgroundColor: '#fff', alignItems: 'center', borderTopLeftRadius: 10, borderTopRightRadius: 10, elevation: 5 }}>
                    <Image source={eduCenter} style={styles.rsiImg} />


                  </View>
                  <TouchableOpacity 
                   testID="navigateToContentManagementBtn"
                   onPress={ this.screennavigate} style={{ height: 70, alignItems: 'center', justifyContent: 'center', backgroundColor: '#F7F7F7', elevation: 5, borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}>
                    <CustomText style={[styles.textWelcome, { textTransform: 'none' }]}>{"Education Center"}</CustomText>
                  </TouchableOpacity>
                </View>
              </View>
              <View style={{ alignItems: 'center', marginTop: '5%' }}>
                <View style={{ width: width / 1.2, alignItems: 'center' }}>
                  <>
                    <CustomText style={[styles.noAdsTitle, { color: this.getColorText() }]}>{"no ads"}</CustomText>
                    <Image source={noAds} style={styles.noAdsImg} resizeMode={'contain'} />
                    <CustomText style={[styles.noDes, { color: this.getColorText() }]}>{"Ads are very disturbing especially when you are doing research for trading/investing. Upgrade to Pro and go ad-free."}</CustomText>
                  </>
                  <>
                    <CustomText style={[styles.noAdsTitle, { color: this.getColorText() }]}>{"unlimited screening"}</CustomText>
                    <Image source={unlimited} style={styles.noAdsImg} resizeMode={'contain'} />
                    <CustomText style={[styles.noDes, { color: this.getColorText() }]}>{"Filter cryptos without any limitations,select pr-screened filters or create custom filters."}</CustomText>
                  </>
                  <>
                    <CustomText style={[styles.noAdsTitle, { color: this.getColorText() }]}>{"real-time alerts"}</CustomText>
                    <Image source={bell} style={styles.noAdsImg} resizeMode={'contain'} />
                    <CustomText style={[styles.noDes, { color: this.getColorText() }]}>{"Don't want to miss out on good opportunities?Get automatic screen/news updates and alerts through email & mobile notifications."}</CustomText>
                  </>
                  <>
                    <CustomText style={[styles.noAdsTitle, { color: this.getColorText() }]}>{"dip alerts"}</CustomText>
                    <Image source={Dipchart} style={styles.noAdsImg} resizeMode={'contain'} />
                    <CustomText style={[styles.noDes, { color: this.getColorText() }]}>{"Set a dip alert everytime your currency falls by a certain percentage.Add more during the dip."}</CustomText>
                  </>
                  <>
                    <CustomText style={[styles.noAdsTitle, { color: this.getColorText() }]}>{"discord community"}</CustomText>
                    <Image source={Community} style={styles.noAdsImg} resizeMode={'contain'} />
                    <CustomText style={[styles.noDes, { color: this.getColorText() }]}>{"Be a part of Kryptokia's discord server & interact with crypto traders/investors all around the world."}</CustomText>
                  </>
                  <>
                    <CustomText style={[styles.noAdsTitle, { color: this.getColorText() }]}>{"virtual tokens"}</CustomText>
                    <Image source={token} style={styles.noAdsImg} resizeMode={'contain'} />
                    <CustomText style={[styles.noDes, { color: this.getColorText() }]}>{"Use our platform & get virtual redeemable tokens which you can redeem for gift coupon of your choice.No more random coupons!"}</CustomText>
                  </>
                </View>
              </View>
              <View style={{flexDirection:"row",justifyContent:"center",marginTop:20}}>
                  <View style={styles.greenCircle}><Text style={{color:"white",alignSelf:"center"}}>1</Text></View>
                  <View style={styles.greyLine} />
                  <View style={styles.greyCircle}><Text style={{color:"black",alignSelf:"center"}}>2</Text></View>
                  <View style={styles.greyLine} />
                  <View style={styles.greyCircle}><Text style={{color:"black",alignSelf:"center"}}>3</Text></View>
                 </View>
                 <View style={{flexDirection:"row",justifyContent:"center"}}>
                 <Text style={{margin:15,color:this.getColorTheme('light', "#000", "white")}}>Choose Plan</Text>
                 <Text style={{margin:15,color:this.getColorTheme('light', "#000", "white")}}>Payment Details</Text>
                 <Text style={{margin:15,color:this.getColorTheme('light', "#000", "white")}}>Confirm & Pay</Text>
                 </View>
              {!this.state.subscriptionListLoader &&
                <View
                  style={[
                    styles.todayCryptoBox,
                    {
                      backgroundColor:
                        this.context.mode === "light"
                          ? theme.light.highlight
                          : theme.dark.highlight
                    }
                  ]}
                >
                  <View
                    style={[
                      styles.todayHeading1,
                      {
                        backgroundColor: "#499E5A"
                      }
                    ]}
                  >
                    <View style={styles.todayPrice1}>
                      <CustomText
                        style={{
                          color: "#FFF",
                          fontFamily: 'PlusJakartaText-Bold',

                          fontSize: 14
                        }}
                      >
                        {"Features"}
                      </CustomText>
                    </View>
                    {
                      Object.keys(this.state?.subscriptionArray)?.map((item) => {
                        if (this.state.subscriptionArray[item].cost || this.state.subscriptionArray[item].monthly_cost) {
                          return <View style={[styles.todayPrice1]}>
                            <CustomText
                              style={{
                                color: "#FFF",
                                fontFamily: 'PlusJakartaText-Bold',

                                fontSize: 14
                              }}
                            >
                              {this.state.subscriptionArray[item].name}
                            </CustomText>
                          </View>
                        }

                      })
                    }
                  </View>
                  <FlatList
                   testID="todayItemFlatList"
                    nestedScrollEnabled
                    data={PlanDetailList}
                    renderItem={this.todayItems}
                    extraData={[this.context.mode, this.state.costType]}
                    ItemSeparatorComponent={this.todayItemSeparator}
                  />
                  <View style={styles.tableBtnView}>
                    <View style={styles.todayName1}>
                      {!this.state.currentSubscription?.attributes?.has_taken_free_trial && <TouchableOpacity
                        style={styles.subs_table_btn}
                        testID="freetrialBtn"
                        onPress={() => this.alertMessage()}
                      >
                        <CustomText
                          style={[
                            styles.todayText1,
                            {
                              textAlign: "center",
                              color: "#499E5A"
                            }
                          ]}
                        >
                          {`Join ${this.getFreeTrial()} Days Free Trial`}
                        </CustomText>
                      </TouchableOpacity>}
                    </View>
                    <View style={styles.todayPrice1}>
                      <TouchableOpacity
                        testID="navigatetoDashboardBtn"
                        style={styles.subs_table_btn}
                        onPress={() => { this.navigateToDashboard() }}
                      >
                        <CustomText
                          style={[
                            styles.todayText1,
                            {
                              textAlign: "center",
                              color: "#499E5A"
                            }
                          ]}
                        >
                          {'Get Started'}
                        </CustomText>
                      </TouchableOpacity>
                    </View>
                    <View style={styles.todayPrice1}>
                      <TouchableOpacity
                        testID="subscriptionProBtn"
                        disabled={this.state.currentSubscription?.attributes?.subscription_plan?.data?.attributes?.subscription_type === 'pro'}
                        style={styles.subs_table_btn}
                        onPress={() => { this.onPlanSelect(this.state.subscriptionArray['pro']) }}
                      >
                        <CustomText
                          style={[
                            styles.todayText1,
                            {
                              textAlign: "center",
                              color: "#499E5A"
                            }
                          ]}
                        >
                          {this.state.currentSubscription?.attributes?.subscription_plan?.data?.attributes?.subscription_type === 'pro' ? 'Activated' : 'Continue'}
                        </CustomText>
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>}
            </View>
            {this.state.openModal ? this.subscriptionModal() : null}
            {this.state.cardModal ? this.cardModal() : null}

          </ScrollView>
        </View>
        <View style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
        }} >
          <AdsBanner />
        </View>
      </View>

    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff"
  },
  banner: {
    height: 206,
    width: "100%"
  },
  bannerContent: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 20,
    marginLeft: 15
  },
  bannerHeading: {
    fontWeight: "600",
    color: "white",
    fontSize: 22
  },
  planHeadingView: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 20
  },
  planName: {
    fontSize: 26,
    fontWeight: "700",
    maxWidth: "70%"
  },
  price: {
    fontSize: 20,
    fontWeight: "600"
  },
  detailsContainer: {
  },
  subs_table_btn: { padding: Scale(10), borderRadius: 25, borderWidth: 1, borderColor: '#499E5A' },
  tableBtnView: { marginBottom: Scale(40), paddingVertical: Scale(10), flexDirection: 'row', backgroundColor: '#499E5A2a' },
  dateHeading: {
    fontSize: 14,
    fontWeight: "700",
    marginTop: 25
  },
  detailsHeading: {
    fontSize: 14,
    fontWeight: "700",
    marginTop: 25,
    marginBottom: 20
  },
  details: {
    fontSize: 14,
    fontWeight: "500"
  },
  btnView: {
    height: 50,
    backgroundColor: "#cd960c",
    justifyContent: "center",
    marginTop: 50,
    marginBottom: 40
  },
  textWelcome: {
    fontFamily: 'PlusJakartaText-Bold',
    fontSize: 20,
    textTransform: 'uppercase',
    letterSpacing: 0.5,
    textAlign: 'center',
  },
  btn: {
    width: width / 1.1,
    height: 60,
    borderRadius: 10,
    backgroundColor: '#499E5A',
    justifyContent: 'center',
    elevation: 10,
  },
  btnText: {
    textTransform: 'uppercase',
    textAlign: 'center',
    fontSize: 16,
    color: '#fff',
    fontFamily: 'PlusJakartaText-Bold',

    letterSpacing: 0.5

  },
  imageStyle: {
    height: 250,
    width: 120,
  },
  checkoutBtn: {
    borderWidth: 2,
    borderColor: '#499E5A',
    borderRadius: 10,
    width: width / 2,
    height: 45,
    justifyContent: 'center'
  },
  checkoutBtnText: {
    color: '#499E5A',
    fontFamily: 'PlusJakartaText-Bold',

    letterSpacing: 0.5,
    fontSize: 16, textAlign: 'center'
  },
  oneText: {
    textAlign: 'center',
    fontFamily: 'PlusJakartaText-Bold',

    fontSize: 18,
    color: '#000',
    marginTop: '10%'
  },
  rsiImg: {
    width: width / 1.3,
    height: height / 3,
    marginBottom: 40,
  },
  noAdsImg: {
    width: 85,
    height: 55,
    marginVertical: 20

  },
  noAdsTitle: {
    fontSize: 20,
    fontFamily: 'PlusJakartaText-Bold',

    color: '#000', textTransform: 'uppercase', marginTop: '10%'
  },
  noDes: {
    fontSize: 15,
    fontFamily: 'PlusJakartaText-Regular',

    color: '#000', textAlign: 'center', letterSpacing: 0.5
  },
  todayCryptoBox: {
    flex: 1,
    marginTop: 15,
    borderRadius: 7,
    shadowColor: "#c3c3c3",
    shadowOffset: {
      width: 2,
      height: 3
    },
    shadowOpacity: 0.6,
    shadowRadius: 5.62,
    elevation: 6,
    marginBottom: 10
  },
  todayHeading1: {
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
    height: 50,
    width: "100%",
    backgroundColor: "#F7F7F7",
    flexDirection: "row",
    paddingHorizontal: 10,
    justifyContent: 'space-evenly'
  },
  todayName1: {
    width: "35%",
    justifyContent: "center"
  },
  todayPrice1: {
    width: "33%",
    justifyContent: "center",
    // left: 25
    // alignSelf: "center",
    alignItems: "center",
    // flex: 1
  },
  today24H1: {
    width: "25%",
    justifyContent: "center",
    //  alignItems:'center'
    alignItems: "flex-end"
  },
  todayText1: { fontSize: 13, fontFamily: 'PlusJakartaText-Bold' },
  todayItem1: {
    flexDirection: "row",
    paddingHorizontal: 10,
    height: 50
  },
  todayItemSeperator1: {
    height: 1,
    width: "100%",
    backgroundColor: "#858585"
  },
  todayCryptoMarginTop: { marginTop: 15 },
  todayBackgroundGreen: {

    alignItems: 'flex-start'
  },
  todayBackgroundRed: {
    padding: 5,
    borderRadius: 7,

  },
  modalViews: {
    flex: 1,
    backgroundColor: "rgba(52, 52, 52, 0.8)",
    alignItems: "center",
    justifyContent: "center"
  },
  modalBackgrounds: {
    // backgroundColor: "#fff",
    borderRadius: 5,
    height: height / 4,
    width: width - 40
  },
  modalDetails1: {
    marginHorizontal: 20,
    alignItems: "center"
  },
  selectOptionTexts: {
    textAlign: "center",
    fontFamily: 'PlusJakartaText-Bold',

    color: "#ED7B5E",
    fontSize: 18,
    marginVertical: 10
  },
  closeButton: {
    color: "#000",
    fontFamily: 'PlusJakartaText-Bold',
    fontSize: 18
  },
  modalOptionView: {
    marginVertical: 20,
    marginHorizontal: 15,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-around"
  },
  modalOkBtns: {
    marginVertical: 20,
    marginHorizontal: 15,
    alignItems: "center"
  },
  modalNoBtns: {
    width: "45%",
    backgroundColor: "#000",
    padding: 13,
    borderRadius: 10
  },
  modalCamTxt: { color: "#fff", fontSize: 18, textAlign: "center" },
  descriptionViews: {
    height: 60,
    justifyContent: "center",
    paddingHorizontal: 15
  },
  modalDescriptions: { fontSize: 16, textAlign: "center" },
  modalYesBtn: {
    width: "45%",
    backgroundColor: "#499E5A",
    padding: 13,
    borderRadius: 10
  },
  myAdsText: {
    fontSize: 22,
    fontWeight: "600",
    marginLeft: 10
  },
  greyCircle: {backgroundColor:"lightgrey",height:25,width:25,borderRadius:55,justifyContent:"center"},
  greyLine: {width:90,height:3,backgroundColor:"lightgrey",justifyContent:"center",alignSelf:"center",marginHorizontal:5},
  greenLine: {width:90,height:3,backgroundColor:"green",justifyContent:"center",alignSelf:"center",marginHorizontal:5},
  greenCircle :{backgroundColor:"green",height:25,width:25,borderRadius:55,justifyContent:"center"}
});
// Customizable Area End

// Customizable Area Start
const PlanDetailList = [
  {
    id: 0,
    feature: 'Ad Free Experience',
    key: 'ad_free_experience',
  },
  {
    id: 1,
    feature: 'Custom Screen Saves',
    key: 'custom_screen_saves',
  },
  {
    id: 2,
    feature: 'Time Frames',
    key: 'time_frames',
  },
  {
    id: 3,
    feature: 'Result Per Screen',
    key: 'result_per_screen',
  },

  {
    id: 4,
    feature: 'Filters',
    key: 'filters',
  },
  {
    id: 5,
    feature: 'Coins In Watchlist',
    key: 'coins_in_watchlist',
  },
  {
    id: 6,
    feature: 'Virtual Loyalty Tokens',
    key: 'virtual_loyalty_tokens',
  },
  {
    id: 7,
    feature: 'Lessons Per Module',
    key: 'lessons_per_module',
  },
  {
    id: 8,
    feature: 'Course Certificates',
    key: 'course_certificates',
  },
  {
    id: 9,
    feature: 'Premium Discord Community',
    key: 'premium_discord_community',
  },
  {
    id: 10,
    feature: 'Premium Newsletter',
    key: 'premium_newsletter',
  },
  {
    id: 11,
    feature: 'Cost',
    key: 'cost',
  },
]
// Customizable Area End
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import InboxIcon from "@material-ui/icons/MoveToInbox";
import { currentPlan } from "../../../components/src/chartUtils.web";
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: boolean;
    token: any;
    educationData: any;
    activeItem: any;
    open: boolean;
    selectedItemData: any;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class EducationCenterWebController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    getEducationApiCallId: string = '';
    getSelectedTopicDetailsApiCallId: string = '';
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            loading: false,
            token: localStorage.getItem('token') || "",
            educationData: [],
            activeItem: '',
            open: false,
            selectedItemData: null
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {

        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestCallId === this.getEducationApiCallId) {
                this.setState({ loading: false });
                if (responseJson) {
                    responseJson = this.addDynamicProperties(responseJson)
                    this.setState({
                        educationData: responseJson.sort((a: any, b: any) => Number(a.id) - Number(b.id))
                    })
                    this.handlePermission();
                }
            } else if (apiRequestCallId === this.getSelectedTopicDetailsApiCallId) {
                this.setState({ loading: false });
                let arr = this.state.educationData.filter((obj: any) => obj?.data?.id != this.state.selectedItemData.id);
                let obj = this.state.educationData.find((obj: any) => obj?.data?.id == this.state.selectedItemData.id);
                if (obj == undefined) {
                    if (responseJson?.data) {
                        this.setState({
                            selectedItemData: responseJson?.data,
                            activeItem: responseJson?.data?.attributes?.title
                        })
                    }
                } else {
                    obj.data.attributes.isActive = true;
                    arr.push(obj);
                    if (responseJson?.data) {
                        this.setState({
                            selectedItemData: responseJson?.data,
                            activeItem: responseJson?.data?.attributes?.title,
                            educationData: arr.sort((a: any, b: any) => Number(a.data.id) - Number(b.data.id))
                        })
                    }
                }
            }
        }
        // Customizable Area End
    }

    async componentDidMount() {
        // Customizable Area Start
        setTimeout(() => {
            this.getEducationsDetails();
        }, 200);
        let topicId = window.location.search.split("?topicId=")[1];
        if (topicId != undefined) {
            this.setState({
                selectedItemData: { id: topicId }
             }, () => {
                this.getSelectedTopicDetails();
            })
        }
        // Customizable Area End
    }
    // Customizable Area Start
    handlePermission() {
        const { educationData } = this.state;
        const lessonsPerModule = currentPlan()?.lessons_per_module;
        const updateEducationData = educationData.map((eduData: any) => {
            eduData.data.attributes.children.map((child: any, index: number) => {
                child.attributes.disabled = ( index + 1 ) > lessonsPerModule;
                return child;
            })
            return eduData;
        })
        this.setState({ educationData: updateEducationData });
    }

    getEducationsDetails() {
        this.setState({ loading: true });
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getEducationApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_categories/education_centers/index_with_children");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getSelectedTopicDetails = () => {
        this.setState({ loading: true });
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getSelectedTopicDetailsApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_categories/education_centers/" + this.state.selectedItemData?.id);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    addDynamicProperties = (val: any) => {
        for (var i = 0; i < val.length; ++i) {
            if (val[i].data.attributes.parent_id == null) {
                val[i].data.attributes.nodeType = "root";
                val[i].data.attributes.isActive = val[i].data.attributes.id === this.state.selectedItemData.attributes?.id || val[i].data.attributes.id === this.state.selectedItemData.attributes?.parent_id ? true : false;
            }
            for (var j = 0, len = val[i].data.attributes.children.length; j < len; j++) {
                if (val[i].data.attributes.children[j].attributes.children.length > 0) {
                    val[i].data.attributes.children[j].attributes.nodeType = "folder";
                    val[i].data.attributes.children[j].attributes.isActive = val[i].data.attributes.children[j].attributes.id === this.state.selectedItemData.attributes?.id ? true : false;
                    this.addDynamicProperties(val[i].data.attributes.children[j]);
                }
            }
        }
        return val;
    }

    validateVideo = (url: string) => {
        return (url.includes('vimeo') || url.includes('video') || url.includes('youtube'))
    }
    // Customizable Area End
}

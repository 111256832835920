import React from "react";

// Customizable Area Start
import {
  View,
  Button,
  StyleSheet,
  Platform,
  ScrollView,
  Text,
  TextInput,
  Image,
  KeyboardAvoidingView,
  TouchableOpacity, StatusBar, Dimensions,
} from "react-native";
import { Formik } from "formik";
import LockIcon from 'react-native-vector-icons/EvilIcons';
import EyeIcon from 'react-native-vector-icons/Feather'
import { logo } from './assets'
import * as Yup from "yup";
const { width, height } = Dimensions.get('screen')
// Customizable Area End

import ForgotPasswordController, { Props } from "./ForgotPasswordController";

export default class NewPassword extends ForgotPasswordController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.isChangePassword = true;
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      <KeyboardAvoidingView
        behavior={this.isPlatformiOS() ? "padding" : undefined}
        style={{ flex: 1 }}
      >
        <ScrollView
          keyboardShouldPersistTaps="always"
          style={
            Platform.OS === "web" ? styles.containerWeb : styles.containerMobile
          }
        >
          {/* ------------------- HEADER ---------------------- */}
          {/* Customizable Area Start */}
          {/* <View> */}
          <StatusBar
            barStyle='dark-content' backgroundColor={'#F4F8F5'}

          />
          {/* <View style={styles.mainView}>
              <View style={{ alignItems: 'center', marginTop: -30, }}>
                <View
                  style={styles.container1}>
                  <Image source={logo} style={styles.logoStyle} resizeMode={'contain'} />
                </View>
              </View>
              <View style={{ alignItems: 'center', marginVertical: '10%' }}>
                <Text style={styles.resetText}>{"Reset Password"}</Text>
                <Text style={styles.pleaseText}>{"Please create your new password!"}</Text>

              </View>
              <View style={styles.bgPasswordContainer}>
                <LockIcon name="lock" size={30} color={'#323433'} />
                <TextInput
                     testID={"txtInputPassword"}
                     style={
                       Platform.OS === "web"
                         ? styles.passwordInputWeb
                         : styles.bgPasswordInput
                     }
                     placeholder={this.placeholderIsPassword}
                     onChangeText={this.handleChange("password")}
                     onBlur={() => this.setFieldTouched("password")}
                     secureTextEntry={
                       this.state.enablePasswordField ? true : false
                     }
                />

                <TouchableOpacity
                  style={styles.passwordShowHide}
                  onPress={() => {
                    this.setState({
                      enablePasswordField: !this.state.enablePasswordField
                    });
                  }}
                >
                  <Image
                    style={styles.imgPasswordShowhide}
                    source={
                      this.state.enablePasswordField
                        ? this.passwordVisibleImage
                        : this.passwordInvisibleImage
                    }
                  />
                </TouchableOpacity>


              </View> */}
          {/* </View> */}


          {/* -------------------- BODY ----------------------- */}
          <View style={styles.mainView}>
            <View style={{ alignItems: 'center', marginTop: -30, }}>
              <View
                style={styles.container1}>
                <Image source={logo} style={styles.logoStyle} resizeMode={'contain'} />
              </View>
            </View>
            <View style={{ alignItems: 'center', marginVertical: '10%' }}>
              <Text style={styles.resetText}>{"Reset Password"}</Text>
              <Text style={styles.pleaseText}>{"Please create your new password!"}</Text>

            </View>
            {/* <Formik
              initialValues={{ password: "", confirmPassword: "" }}
              validationSchema={Yup.object().shape(this.state.passwordSchema)}
              validateOnMount={true}
              validateOnChange={true}
              onSubmit={(values, actions) => {
                this.goToConfirmationAfterPasswordChange(values);
                actions.setSubmitting(false);
              }}
            >
              {({
                handleChange,
                handleSubmit,
                errors,
                setFieldTouched,
                touched
              }) => ( */}
                <View style={{ marginLeft: 15, marginRight: 15 }}>
                <View
                    style={
                      Platform.OS === "web"
                        ? styles.passwordContainerWeb
                        : styles.bgPasswordContainer
                    }
                  >
                    <LockIcon name="lock" size={30} color={'#323433'} />

                    <TextInput
                      testID={"txtInputPassword"}
                      style={
                        Platform.OS === "web"
                          ? styles.passwordInputWeb
                          : styles.bgPasswordInput
                      }
                      placeholder={'Enter temporary password '}
                      {...this.txtInputNewEmailPasswordProps}
                      // onChangeText={handleChange("password")}
                      // onBlur={() => setFieldTouched("password")}
                      onFocus={()=>this.setState({sentEamilPasswordError:false})}
                      onBlur={()=>this.state.sentEmailPassword==="" && this.setState({sentEamilPasswordError:true})}
                      // secureTextEntry={
                      //   this.state.enablePasswordField ? true : false
                      // }
                    />

                    {/* <TouchableOpacity
                      style={styles.passwordShowHide}
                      onPress={() => {
                        this.setState({
                          enablePasswordField: !this.state.enablePasswordField
                        });
                      }}
                    >
                     
                      {
                        this.state.enablePasswordField ?
                          <EyeIcon name={'eye'} size={28} /> :
                          <EyeIcon name={'eye-off'} size={28} />

                      }
                    </TouchableOpacity> */}
                  </View>
                  { this.state.sentEamilPasswordError ? (
                      <Text style={styles.errorStyle}>{'please enter temporary password '}</Text>
                    ) : null}
                  <View
                    style={
                      Platform.OS === "web"
                        ? styles.passwordContainerWeb
                        : styles.bgPasswordContainer
                    }
                  >
                    <LockIcon name="lock" size={30} color={'#323433'} />

                    <TextInput
                      testID={"txtInputPassword"}
                      style={
                        Platform.OS === "web"
                          ? styles.passwordInputWeb
                          : styles.bgPasswordInput
                      }
                      placeholder={this.placeholderNemIsPassword}
                      {...this.txtInputPasswordProps}
                      // onChangeText={handleChange("password")}
                      // onBlur={() => setFieldTouched("password")}
                      onFocus={()=>this.setState({passwordError:false})}
                      onBlur={()=>this.state.passwordValue==="" && this.setState({passwordError:true})}
                      secureTextEntry={
                        this.state.enablePasswordField ? true : false
                      }
                    />

                    <TouchableOpacity
                      style={styles.passwordShowHide}
                      onPress={() => {
                        this.setState({
                          enablePasswordField: !this.state.enablePasswordField
                        });
                      }}
                    >
                     
                      {
                        this.state.enablePasswordField ?
                          <EyeIcon name={'eye'} size={28} /> :
                          <EyeIcon name={'eye-off'} size={28} />

                      }
                    </TouchableOpacity>
                  </View>
                  <View>
                    { this.state.passwordError ? (
                      <Text style={styles.errorStyle}>{this.state.passwordLabelError}</Text>
                    ) : null}
                    <Text
                      style={
                        Platform.OS === "web" ? styles.passwordRulesStyle : {}
                      }
                    >
                      {this.state.passwordRules}
                    </Text>
                  </View>

                  <View
                    style={
                      Platform.OS === "web"
                        ? styles.passwordContainerWeb
                        : styles.bgPasswordContainer
                    }
                  >
                    <LockIcon name="lock" size={30} color={'#323433'} />

                    <TextInput
                      testID={"txtInputConfirmPassword"}
                      style={
                        Platform.OS === "web"
                          ? styles.passwordInputWeb
                          : styles.bgPasswordInput
                      }
                      placeholder={this.placeholderIsConfirmPassword}
                      {...this.txtInputConfirmPasswordProps}
                       onFocus={()=>this.setState({confirmPasswordError:false})}
                      onBlur={()=>this.state.confirmPasswordValue==="" && this.setState({confirmPasswordError:true})}
                      // onChangeText={handleChange("confirmPassword")}
                      // onBlur={() => setFieldTouched("confirmPassword")}
                      secureTextEntry={
                        this.state.btnConfirmPasswordShowHide ? true : false
                      }
                    />

                    <TouchableOpacity
                      style={styles.passwordShowHide}
                      onPress={() => {
                        this.setState({
                          btnConfirmPasswordShowHide: !this.state
                            .btnConfirmPasswordShowHide
                        });
                      }}
                    >
                      {/* <Image
                          style={styles.imgPasswordShowhide}
                          source={
                            this.state.btnConfirmPasswordShowHide
                              ? this.passwordVisibleImage
                              : this.passwordInvisibleImage
                          }
                        /> */}
                      {
                        this.state.btnConfirmPasswordShowHide ?
                          <EyeIcon name={'eye'} size={28} /> :
                          <EyeIcon name={'eye-off'} size={28} />

                      }
                    </TouchableOpacity>
                  </View>
                  <View>
                    { this.state.confirmPasswordError ? (
                      <Text style={styles.errorStyle}>
                        {this.state.confirmPasswordLabelError}
                      </Text>
                    ) : null}
                  </View>

                  <View style={{ zIndex: -1, padding: 15, alignItems: 'center', marginTop: '20%' }}>
                    {/* <Button
                        testID={"changePasswordButton"}
                        title={this.buttonTextIsNext}
                        color={this.buttonColorForNextButton}
                        onPress={() => handleSubmit()}
                      /> */}
                    <TouchableOpacity
                      // onPress={()=>this.props.navigation.navigate('DrawerNavigation')}
                      //   testID={"changePasswordButton"}
                      // onPress={() => this.emalilof()}
                      onPress={() => this.handleSubmit()}
                      style={styles.btnStyle}>
                      <Text style={styles.btnText}>{'Reset Password'}</Text>
                    </TouchableOpacity>
                  </View>
                </View>
              {/* )}
            </Formik> */}


            {/* {this.state.accountStatus === "Confirmation" ? (
              <View style={{ zIndex: -1, padding: 15 }}>
                <Button
                  testID={"goToHomeButton"}
                  title={this.buttonTitleIsOk}
                  color={this.buttonColorForOkButton}
                  onPress={() => this.goToHome()}
                />
              </View>
            ) : null} */}
          </View>
          {/* Customizable Area End */}
        </ScrollView>
      </KeyboardAvoidingView>
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  containerMobile: {
    flex: 1,
    // padding: 16,
    // marginLeft: "auto",
    // marginRight: "auto",
    // width: "100%",
    // maxWidth: 650,
    backgroundColor: "#F4F8F5"
  },
  containerWeb: {
    padding: 16,
    width: "50%",
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 650
  },
  countryCodeSelector: {
    flex: 3,
    marginTop: 20,
    textAlign: "left",
    textAlignVertical: "center"
  },
  button: {
    marginTop: 16,
    justifyContent: "center",
    alignItems: "center",
    width: "80%",
    zIndex: -1
  },

  flexContainer: {
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "flex-end",
    justifyContent: "flex-start",
    width: "100%"
  },

  headline: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center"
  },

  webInput: {
    marginTop: 20,
    width: "100%",
    zIndex: -1
  },

  inputAfterCountryCode: {
    width: "100%",
    zIndex: -1
  },

  mobileInput: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    marginTop: 20,
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true
  },

  codeInput: {
    marginTop: 20,
    width: "30%"
  },

  phoneInput: {
    flex: 3,
    marginTop: 20
  },

  noBorder: {
    borderBottomWidth: 0
  },

  titleText: {
    fontSize: 32,
    color: "#6200EE",
    fontWeight: "bold"
  },

  stepText: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },

  emailText: {
    marginBottom: 16,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8,
    fontWeight: "bold"
  },

  bgRectBorder: {
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    marginTop: 20,
    minHeight: 40,
    fontSize: 18,
    textAlignVertical: "center",
    padding: 10
  },

  bgRectWeb: {
    marginTop: 40
  },

  errorStyle: {
    color: "red",
    textAlign: 'left'
  },

  passwordShowHide: {
    alignSelf: "center"
  },

  passwordRulesStyle: {
    padding: 15
  },

  bgPasswordContainer: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#F5F8F6",
    marginTop: 15,
    // borderWidth: Platform.OS === "web" ? 0 : 1,
    // marginBottom: 16,
    // borderBottomWidth: 1,
    // borderColor: "#767676",
    borderRadius: 10,
    includeFontPadding: true,
    padding: 10, alignItems: 'center'
  },

  passwordContainerWeb: {
    flexDirection: "row",
    borderBottomWidth: 1,
    backgroundColor: "#00000000",
    borderColor: "#767676",
    marginTop: 20,
    marginLeft: 10,
    marginRight: 10
  },
  bgPasswordInput: {
    flex: 1,
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    minHeight: 40,
    includeFontPadding: true
  },
  passwordInputWeb: {
    flex: 1,
    fontSize: 18,
    textAlign: "left",
    backgroundColor: "#00000000",
    minHeight: 40,
    includeFontPadding: true,
    borderColor: "#767676",
    borderRadius: 2
  },
  imgPasswordShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {},
  mainView: {
    backgroundColor: '#fff',
    //flexGrow:1,
    //  flex: 1,
    // height:'100%',
    marginTop: '18%',
    height: height / 1.2,
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    elevation: 5,
    // justifyContent: 'space-between',

  },
  logoStyle: {
    height: height / 10,
    width: width / 3,
    alignItems: 'center',

  },
  container1: {
    alignItems: 'center', justifyContent: 'center',
    backgroundColor: '#F4F8F5',
    height: 100, width: 100,
    marginTop: -10,
    borderRadius: 100 / 2,
  },
  resetText: {
    fontWeight: 'bold',
    fontSize: 20,
    // marginVertical:10
  },
  pleaseText: {
    color: '#B1B2B1',
    fontSize: 18,
    marginVertical: 15
  },
  btnStyle: {
    backgroundColor: '#489F57',
    height: height / 16,
    width: width / 1.2,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center'
  },
  btnText: {
    color: '#fff',
    fontWeight: 'bold',
    letterSpacing: 1,
    fontSize: 18
  },
});
// Customizable Area End

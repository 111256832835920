
let priceMainOption:string = ""
let darkmode:boolean =false;
function setDarkMode(darmode:boolean) {
    // alert("set")
    darkmode = darmode;
  }
  function getDarkMode() {
   return darkmode; 
  }
  export const getTextColor = (mode:any) => {
    if (mode === "light") {
      return "#000";
    }
    return "#FFF";
  };
  export const safeAreaViewBackgroundColor = (mode:any,theme:any) => {
    if (mode === "light") {
      return theme.light.backgroundColor;
    }
    return theme.dark.backgroundColor;
  };
  export const ResultPageBackgroundColor = (mode:any,theme:any) => {
    if(mode === 'light'){
      return theme.light.highlight
              }
              else{
                  return theme.dark.highlight
              }
  };
  export const statusBarBackgroundColor = (mode:any,theme:any) => {
    if (mode === "light") {
     return theme.light.headeBg ; 
    }
    return theme.dark.headeBg;
  };
  export const statusBarBarStyle = (mode:any) => {
    if (mode === "light") {
     return 'dark-content' ;
    }
    return 'light-content';
  };
  export const mainViewbackgroundcolor = (mode:any,theme:any) => {
    if (mode === "light") {
     return theme.light.curvedBoxView ;
    }
    return "#000";
  };
function setPriceMainOption(selectedItem:string) {
    priceMainOption = selectedItem;
  }
  function getPriceMainOption() {
   return priceMainOption; 
  }
  export default {
    setDarkMode,getDarkMode,
    setPriceMainOption,getPriceMainOption}
Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.fetchProfileImage = "/account_block/accounts/show"
exports.deleteApiMethod = "DELETE";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "NavigationMenu";
exports.labelBodyText = "NavigationMenu Body";
exports.getUser = "/account_block/accounts/show";
exports.deleteAccount = "/account_block/delete_account";
exports.btnExampleTitle = "CLICK ME";
exports.logoutText = "Are you sure want to logout?";
exports.logoutHead = "Logout";
exports.termsofservice = "Terms Of Service";
exports.privacypolicy = "Privacy Policy";
// Customizable Area End
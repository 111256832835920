import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  Text,
  Image,
  TouchableOpacity,
  View,
  Modal,
  ScrollView,
  TouchableWithoutFeedback,
  Dimensions,
  Platform,Switch
} from "react-native";
import {
  logo,
  protfolio,
  logOut,
  profile,
  manageCards,
  contactUs,
  watchList,
  orderSummery,
  userIcon,
  mySubscription,
  modeIcon,
  referral,
  loyalty
} from "./assets";
const { height, width } = Dimensions.get("screen");
import { COLORS } from "../../../framework/src/Globals";
import { theme } from "../../../framework/src/theme";
import CustomSwitch from "../../../components/src/CustomSwitch";
import CustomText from "../../../components/src/text";
import MaterialIcons from "react-native-vector-icons/MaterialIcons";
import AntDesign from "react-native-vector-icons/AntDesign";
// Customizable Area End

import NavigationMenuController, {
  Props,
  configJSON
} from "./NavigationMenuController";

export default class NavigationMenu extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  onPressItem = (name: any, index: any) => {
    this.setState({ screenIndex: index });

    this.props.navigation.navigate(name);
  };

  logoutModal = () => {
    return (
      <Modal
        visible={this.state.openModal}
        transparent={true}
        onRequestClose={() =>
          this.setState({ openModal: !this.state.openModal })
        }
      >
        <View style={styles.modalView}>
          <View style={styles.modalBackground}>
            <View style={styles.modalDetails}>
              <Text style={styles.selectOptionText}>{this.logoutHead}</Text>
              {/* <TouchableOpacity
                onPress={() =>
                  this.setState({ openModal: !this.state.openModal })
                }
              >
                <Text style={styles.closeButton}>X</Text>
              </TouchableOpacity> */}
            </View>
            <View style={styles.descriptionView}>
              <Text style={styles.modalDescription}>{this.logoutText}</Text>
            </View>

            <View style={styles.modalOptionView}>
              <TouchableOpacity
                style={styles.modalNoBtn}
                onPress={this.onClickNo}
              >
                <Text style={styles.modalCamTxt}>No</Text>
              </TouchableOpacity>
              <TouchableOpacity
              testID="clearUserToken"
                style={styles.modalYesBtn}
                onPress={this.clearUserToken}
              >
                <Text style={styles.modalCamTxt}>Yes</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    );
  };
  deleteAccountModal = () => {
    return (
      <Modal
        visible={this.state.openDeleteAccountModal}
        transparent={true}
        onRequestClose={() =>
          this.setState({ openDeleteAccountModal: !this.state.openDeleteAccountModal })
        }
      >
        <View style={styles.modalView}>
          <View style={styles.modalBackground}>
            <View style={styles.modalDetails}>
              <Text style={styles.selectOptionText}>Delete Account</Text>
            
            </View>
            <View style={styles.descriptionView}>
              <Text style={styles.modalDescription}>Are you sure you want to delete your account ?</Text>
            </View>

            <View style={styles.modalOptionView}>
              <TouchableOpacity
                style={styles.modalNoBtn}
                onPress={this.onClickDeleteAccountNo}
              >
                <Text style={styles.modalCamTxt}>No</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.modalYesBtn}
                onPress={this.deleteAccountApi}
              >
                <Text style={styles.modalCamTxt}>Yes</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    );
  };
  getModeColor= ()=>{
    return this.context.mode==="light"?theme.light.textColor:theme.dark.textColor
  }
  getTextColor = (index: any) => {
    const isLightMode = this.context.mode === 'light';
    const textColor =  isLightMode ? theme.light.textColor : theme.dark.textColor;
    return textColor;
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ScrollView keyboardShouldPersistTaps="always" style={[styles.container,{backgroundColor:this.context.mode==='light'?theme.light.highlight:theme.dark.highlight}]} contentContainerStyle={{flexGrow:1}}>
        <TouchableWithoutFeedback
          onPress={() => {
            this.hideKeyboard();
          }}
          testID="feedbackBtn"
        >
          {/* Customizable Area Start */}
          {/* Merge Engine UI Engine Code */}
          <>
            <View style={styles.header}>
              <View style={styles.profileView}>
                {!!this.state.userImage ? (
                  <Image
                    source={{ uri: this.state.userImage }}
                    style={styles.image}
                  />
                ) : (
                  <Image
                    source={userIcon}
                    style={styles.image}
                  />
                )}
              </View>
              <View style={styles.profileName}>
                {/* @ts-ignore */}
                <CustomText style={[styles.profileNameText,{color:this.getModeColor()}]}>
                  {this.state.userName !== "" && this.state.userName}
                </CustomText>
              </View>
              <View style={{flex:1,flexDirection:"row",alignItems:"center"}}>
                <TouchableOpacity onPress={()=>this.props.navigation.closeDrawer()}>
                  <MaterialIcons name="close"  color={this.getModeColor()} size={26}/>
                </TouchableOpacity>
                
              </View>
              
            </View>
            <View style={styles.body}>
              <TouchableOpacity
                testID="btnExample"
                onPress={() => this.onPressItem("UserProfileBasicBlock", 1)}
                style={styles.optionView}
              >
                <View style={styles.iconView}>
                  <Image
                    source={profile}
                    style={[styles.icon,{
                      tintColor:this.getTextColor(1)
                    }]}
                    resizeMode="contain"
                  />
                </View>
                <View style={styles.optionName}>
                  {/* @ts-ignore */}
                  <CustomText
                    style={[
                      styles.iconName,
                      {
                        color: this.getTextColor(1)
                      }
                    ]}
                  >
                    My Profile
                  </CustomText>
                </View>
              </TouchableOpacity>
              <TouchableOpacity
                 onPress={()=>this.onPressItem('UserSubscription',2)}
                 testID="subbtn"   
                style={styles.optionView}
              >
                <View style={styles.iconView}>
                  <Image
                    source={mySubscription}
                    style={[
                      styles.icon,
                      {
                        tintColor:this.getTextColor(2)
                      }
                    ]}
                    resizeMode="contain"
                  />
                </View>
                <View style={styles.optionName}>
                  {/* @ts-ignore */}
                  <CustomText
                    style={[
                      styles.iconName,
                      {
                        color:this.getTextColor(2)
                      }
                    ]}
                  >
                    My Subscriptions
                  </CustomText>
                </View>
              </TouchableOpacity>
              <TouchableOpacity
                 onPress={()=>this.onPressItem('Portfolio',3)}
                 testID="portfolioBtn"   
                style={styles.optionView}
              >
                <View style={styles.iconView}>
                <Image
                    source={protfolio}
                    style={[
                      styles.icon,
                      {
                        tintColor:this.getTextColor(3)
                      }
                    ]}
                    resizeMode="contain"
                  />
                </View>
                <View style={styles.optionName}>
                  {/* @ts-ignore */}
                  <CustomText
                    style={[
                      styles.iconName,
                      {
                        color:this.getTextColor(3)
                      }
                    ]}
                  >
                   My Portfolio
                  </CustomText>
                </View>
              </TouchableOpacity>
              <TouchableOpacity
                 onPress={()=>this.onPressItem('Watchlist2',4)}
                 testID="watchlstBtn"   
                style={styles.optionView}
              >
                <View style={styles.iconView}>
                  <Image
                    source={watchList}
                    style={[
                      styles.icon,
                      {
                        tintColor:this.getTextColor(4)
                      }
                    ]}
                    resizeMode="contain"
                  />
                </View>
                <View style={styles.optionName}>
                  {/* @ts-ignore */}
                  <CustomText
                    style={[
                      styles.iconName,
                      {
                        color:this.getTextColor(4)
                      }
                    ]}
                  >
                    Watchlist
                  </CustomText>
                </View>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => this.onPressItem("SavedCards", 5)}
                // onPress={()=>this.props.navigation.navigate('SavedCards')}
                testID="saveCard"
                style={styles.optionView}
              >
                <View style={styles.iconView}>
                  <Image
                    source={manageCards}
                    style={[
                      styles.icon,
                      {
                        tintColor:this.getTextColor(5)
                      }
                    ]}
                    resizeMode="contain"
                  />
                </View>
                <View style={styles.optionName}>
                  {/* @ts-ignore */}
                  <CustomText
                    style={[
                      styles.iconName,
                      {
                        color: this.getTextColor(5)
                      }
                    ]}
                  >
                    Manage Cards
                  </CustomText>
                </View>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => this.onPressItem("OrderSummary", 6)}
                // onPress={()=>this.props.navigation.navigate('OrderSummary')}
                testID="orderSummary"
                style={styles.optionView}
              >
                <View style={styles.iconView}>
                  <Image
                    source={orderSummery}
                    style={[
                      styles.icon,
                      {
                        tintColor:this.getTextColor(6)
                      }
                    ]}
                    resizeMode="contain"
                  />
                </View>
                <View style={styles.optionName}>
                  {/* @ts-ignore */}
                  <CustomText
                    style={[
                      styles.iconName,
                      {
                        color:this.getTextColor(6)
                      }
                    ]}
                  >
                    Order Summary
                  </CustomText>
                </View>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => this.onPressItem("Contactus", 7)}
                // onPress={()=>this.props.navigation.navigate('Contactus')}
                testID="contactUs"
                style={styles.optionView}
              >
                <View style={styles.iconView}>
                  <Image
                    source={contactUs}
                    style={[
                      styles.icon,
                      {
                        tintColor: this.getTextColor(7)
                      }
                    ]}
                    resizeMode="contain"
                  />
                </View>
                <View style={styles.optionName}>
                  {/* @ts-ignore */}
                  <CustomText
                    style={[
                      styles.iconName,
                      {
                        color: this.getTextColor(7)
                      }
                    ]}
                  >
                    Contact Us
                  </CustomText>
                </View>
              </TouchableOpacity>

              <TouchableOpacity
                onPress={() => this.onPressItem("Referrals", 1)}
                testID="Referrals"
                style={styles.optionView}
              >
                <View style={styles.iconView}>
                  <Image
                    source={referral}
                    style={[
                      styles.icon,
                      {
                        tintColor: this.getTextColor(1)
                      }
                    ]}
                    resizeMode="contain"
                  />
                </View>
                <View style={styles.optionName}>
                
                  <Text
                    style={[
                      styles.iconName,
                      {
                        color: this.getTextColor(8)
                      }
                    ]}
                  >
                   Refer a Friend
                  </Text>
                </View>
              </TouchableOpacity>

              <TouchableOpacity
                onPress={() => this.onPressItem("LoyaltySystem2", 9)}
                testID="Referrals2"
                style={styles.optionView}
              >
                <View style={styles.iconView}>
                  <Image
                    source={loyalty}
                    style={[
                      styles.icon,
                      {
                        tintColor:this.getTextColor(9)
                      }
                    ]}
                    resizeMode="contain"
                  />
                </View>
                <View style={styles.optionName}>
                
                  <Text
                    style={[
                      styles.iconName,
                      {
                        color: this.getTextColor(9)
                      }
                    ]}
                  >
                   Loyalty Rewards
                  </Text>
                </View>
              </TouchableOpacity>

              <TouchableOpacity
                onPress={() => this.onPressItem("TermAndCondition", 8)}
                testID="TermAndCondition"
                style={styles.optionView}
              >
                <View style={styles.iconView}>
                  <AntDesign
                    name="exception1"
                    color={this.getTextColor(8)}
                    size={24}
                  />
                </View>
                <View style={styles.optionName}>
                  {/* @ts-ignore */}
                  <CustomText
                    style={[
                      styles.iconName,
                      {
                        color: this.getTextColor(8)
                      }
                    ]}
                  >
                    {configJSON.termsofservice}
                  </CustomText>
                </View>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => this.onPressItem("PrivacyPolicy", 9)}
                testID="PrivacyPolicy"
                style={styles.optionView}
              >
                <View style={styles.iconView}>
                  <AntDesign
                    name="book"
                    color={this.getTextColor(9)}
                    size={24}
                  />
                </View>
                <View style={styles.optionName}>
                  {/* @ts-ignore */}
                  <CustomText
                    style={[
                      styles.iconName,
                      {
                        color: this.getTextColor(9)
                      }
                    ]}
                  >
                    {configJSON.privacypolicy}
                  </CustomText>
                </View>
              </TouchableOpacity>

              <View style={[styles.optionView,{justifyContent:'space-between'}]} >
                <View style={styles.iconView}>
                <Image
                    source={modeIcon}
                    style={[
                      styles.icon,
                      {
                        tintColor: this.getModeColor()
                      }
                    ]}
                    resizeMode="contain"
                  />
                </View>
                <View style={[styles.optionName,{flexDirection:"row",alignItems:"center",justifyContent:"space-between"}]}>
                  {/* @ts-ignore */}
                  <CustomText style={[styles.iconName, {
                    color: this.context.mode === 'light' ? theme.light.textColor : theme.dark.textColor
                  }]}>
                    {"Dark Mode"}
                  </CustomText>
                   <CustomSwitch toogleState={this.state.enable} onChange={()=>this.toggleSwitch()}/>
                </View>
              </View>
              <TouchableOpacity style={styles.optionView} onPress={this.logoutClick}>
                <View style={styles.iconView}>
                  <Image
                    source={logOut}
                    style={[styles.iconLogout,{
                      tintColor:this.getTextColor(10)
                    }]}
                    resizeMode="contain"
                  />
                </View>
                <View style={styles.optionName}>
                  {/* @ts-ignore */}
                  <CustomText style={[styles.iconName,{
                     color:this.getTextColor(10)
                  }]}>Logout</CustomText>
                </View>
              </TouchableOpacity>
              <TouchableOpacity style={styles.optionView} onPress={this.deleteAccountClick}>
                <View style={styles.iconView}>
                  <Image
                    source={logOut}
                    style={[styles.iconLogout,{
                      tintColor:this.getTextColor(10)
                    }]}
                    resizeMode="contain"
                  />
                </View>
                <View style={styles.optionName}>
                  {/* @ts-ignore */}
                  <CustomText style={[styles.iconName,{
                     color:this.getTextColor(10)
                  }]}>Delete Account</CustomText>
                </View>
              </TouchableOpacity>
            </View>
            {this.state.openModal ? this.logoutModal() : null}
            {this.state.openDeleteAccountModal ? this.deleteAccountModal() : null}
          </>
          {/* Merge Engine UI Engine Code */}
          {/* Customizable Area End */}
        </TouchableWithoutFeedback>
      </ScrollView>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    backgroundColor: "#fff"
  },
  header: {
    flexDirection: "row",
  },
  body: {
    width: "100%",
    flex:1,
    paddingBottom:20
  },
  profileView: {
    flex:2,
    justifyContent: "center",
    alignItems: "center",
  },
  image: { height: 50, width: 50, borderRadius: 25, overflow: "hidden" },
  profileName: { flex: 4,  justifyContent: "center" },
  profileNameText: { fontSize: 15, fontFamily:'PlusJakartaText-Bold' },
  iconView: { flex:1, justifyContent: "center", alignItems: "center" },
  optionView: { height: 55, flexDirection: "row" },
  icon: { width: 23, height: 23, borderRadius: 23 / 2, overflow: "hidden" },
  iconLogout: {
    width: 21,
    height: 20,
    borderRadius: 20 / 2,
    overflow: "hidden"
  },
  optionName: {flex:3,  justifyContent: "center", },
  iconName: { fontSize: 14, fontFamily:'PlusJakartaText-Bold' },
  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    marginBottom: 16,
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    padding: 10,
    borderWidth: Platform.OS === "web" ? 0 : 1
  },
  bgMobileInput: {
    flex: 1
  },
  showHide: {
    alignSelf: "center"
  },
  // Modal Style
  modalView: {
    flex: 1,
    backgroundColor: "rgba(52, 52, 52, 0.8)",
    alignItems: "center",
    justifyContent: "center"
  },
  modalBackground: {
    backgroundColor: "#fff",
    borderRadius: 5,
    height: height / 4,
    width: width - 40
  },
  modalDetails: {
    marginHorizontal: 20,
    alignItems: "center"
  },
  selectOptionText: {
    textAlign: "center",
    fontWeight: "bold",
    color: "#ED7B5E",
    fontSize: 18,
    marginVertical: 10
  },
  closeButton: {
    color: "#000",
    fontWeight: "bold",
    fontSize: 18
  },
  modalOptionView: {
    marginVertical: 20,
    marginHorizontal: 15,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-around"
  },
  modalNoBtn: {
    width: "45%",
    backgroundColor: "#000",
    padding: 13,
    borderRadius: 10
  },
  modalCamTxt: { color: "#fff", fontSize: 18, textAlign: "center" },
  descriptionView: { height: 60, justifyContent: "center" },
  modalDescription: { color: "#000", fontSize: 16, textAlign: "center" },
  modalYesBtn: {
    width: "45%",
    backgroundColor: "green",
    padding: 13,
    borderRadius: 10
  },
  imgShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {}
});

// Customizable Area End
Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.adsDisableApiContentType = "application/json";
exports.updateAdsDisableApiContentType = "application/json";
exports.adsDisableAPiEndMethod = "/bx_block_disable_ad/disable_ads/"
exports.updateAdsDisableAPiEndMethod = "/bx_block_disable_ad/disable_ads/"
exports.adsDisableAPiMethod = "GET"
exports.updateAdsDisableAPiMethod = "PUT"
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "DisableAds";
exports.labelBodyText = "Membership Status";
exports.labelSubscribe = "Paid membership"
exports.btnNextScreenTitle = "Continue";
// Customizable Area End
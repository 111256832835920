import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { CommonContext } from "../../../framework/src/CommonContextProvider";
import { theme } from "../../../framework/src/theme";
import AsyncStorage from "@react-native-async-storage/async-storage";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  trendingTopics: any;
  topic: any;
  newsArray: any;
  loader: boolean;
  mode: string;
  currentPlan:any;
  isVisibleModal:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class RichTextEditor5Controller extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  static contextType = CommonContext;

  labelHeader: any;
  apigetNewsByTopic: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      loader: true,
      mode:"dark",
      trendingTopics: [
        // {
        //   id: 1,
        //   name: "Facebook Diem Coin",
        //   value: "Diem",
        //   isSelected: false
        // },
        {
          id: 2,
          name: "Digital Dollar",
          value: "Digital+Dollar",
          isSelected: false
        },
        {
          id: 3,
          name: "Digital Euro",
          value: "Digital+Euro",
          isSelected: false
        },
        // {
        //   id: 4,
        //   name: "Digital Ruble",
        //   value: "Digital+Ruble",
        //   isSelected: false
        // },
        {
          id: 5,
          name: "Future contracts",
          value: "Future",
          isSelected: false
        },
        // {
        //   id: 6,
        //   name: "Facebook Libra Coin",
        //   value: "Libra",
        //   isSelected: false
        // },
        {
          id: 7,
          name: "Mining Crypto",
          value: "Mining",
          isSelected: false
        },
        {
          id: 8,
          name: "NFT",
          value: "NFT",
          isSelected: false
        },
        {
          id: 9,
          name: "Podcasts",
          value: "podcast",
          isSelected: false
        },
        {
          id: 10,
          name: "Stable Coins",
          value: "Stablecoins",
          isSelected: false
        },
        {
          id: 11,
          name: "Technical Analysis",
          value: "Tanalysis",
          isSelected: false
        },
        {
          id: 12,
          name: "IRS and Taxes",
          value: "Taxes",
          isSelected: false
        },
        // {
        //   id: 13,
        //   name: "Upgrades and Hard Forks",
        //   value: "Upgrade",
        //   isSelected: false
        // },
        {
          id: 14,
          name: "Whale buying and selling",
          value: "Whales",
          isSelected: false
        }
      ],
      topic: "",
      newsArray: [],
      currentPlan:'',
      isVisibleModal:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
   
    this.labelHeader = configJSON.labelHeader;
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId && responseJson) {
        console.log(responseJson,'res11')
        if (apiRequestCallId === this.apigetNewsByTopic) {
         
          if (responseJson.data) {
            
            this.setState({
              newsArray: responseJson.data,
              loader: false
            });
          } else {
            this.setState({
              loader:false
            })
            this.parseApiErrorResponse(responseJson);
          }
        }
      } else {
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End

  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // Customizable Area Start
  async componentDidMount() {
    this.context.mode==='light'?this.setState({mode:theme.light.backgroundColor}) : this.setState({mode:theme.dark.backgroundColor})
    console.log("mode======",this.context.mode)
    this.getNewsByTopic();
    this.props.navigation.addListener('focus', () => {
    this.getPlan()
    })
  }

  async getPlan(){
    let currentPlan = await AsyncStorage.getItem("userInfo")
    if(currentPlan==="basic"){
      this.setState({isVisibleModal:true})
    }
    
    
  }
  async getNewsByTopic() {
    console.log(this.state.topic,'topic11')
    this.setState({ loader: true });
    const token = await AsyncStorage.getItem("userToken");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apigetNewsByTopic = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.newsByTopic(this.state.topic)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  onPressTrendingBtn = (item: any) => {
    let arr = this.state.trendingTopics;
      
        for(let i in arr){
          if(arr[i].id===item.id){
            if(arr[i].isSelected===true){
              arr[i].isSelected=false
              this.setState({
                topic:''
              },()=>{
                this.getNewsByTopic();
              })
            }else{
              this.state.trendingTopics[i].isSelected=true
              this.setState({
                trendingTopics:this.state.trendingTopics,
                topic:this.state.trendingTopics[i].value
              },()=>{
                this.getNewsByTopic();
              })
            }
         
          }else{
            this.state.trendingTopics[i].isSelected=false
            this.setState({trendingTopics:this.state.trendingTopics})
          }
        }
  };

  showNewsss = (item: any) => {
    console.log("pikaa")
    let arr = this.state.newsArray;
    this.setState({
      newsArray: arr.map((el: { title: any; showNews: boolean; }) => ({
        ...el,
        showNews:
          el.title === item.title && el.showNews === true
            ? false
            : el.title === item.title
            ? true
            : false
      }))
    });
  }
  backGroundColor=()=>{
    if(this.context.mode === "light"){
      return theme.light.backgroundColor
    }
     return theme.dark.backgroundColor
  }
  
  // Customizable Area End
  
}

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from 'react-toastify';
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    filterState: any;
    handleItemClick: any;
    fundamentalsForm: any;
    volumeForm: any;
    priceActionForm: any;
    indicatorForm: any;
    navigateCategory: any;
    navigateCustomFilterCoinListing: any;
    getCustomFilterApi: any
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: boolean
    token: any;
    savedFilters: Array<any>;
    page: number;
    rowsPerPage: number;
    customFilterPage: number;
    customFilterRowsPerPage: number;
    showDialog: boolean;
    seletedId: number;
    customSavedFilters: Array<any>;
    filterType: string;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class SavedFiltersControllerWeb extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    getSavedFilterCallId: string="";
    deleteFiltersCallId: string="";
    getCustomSavedFilterCallId: string="";
    deleteCustomFiltersCallId: string="";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // Customizable Area End
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            loading: false,
            token: localStorage.getItem('token') || "",
            savedFilters: [],
            page: 0,
            rowsPerPage: 10,
            showDialog: false,
            seletedId: 0,
            customSavedFilters: [],
            filterType: "",
            customFilterPage: 0,
            customFilterRowsPerPage: 10
            
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {

        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            switch (apiRequestCallId) {
                case this.getSavedFilterCallId:
                    if (responseJson.data) {
                        this.setState({ savedFilters: responseJson.data, loading: false });
                    }
                    break;
                case this.deleteFiltersCallId:
                    this.handleDeleteFilter(responseJson);
                    break;
                case this.getCustomSavedFilterCallId:
                    if (responseJson.data) {
                        this.setState({ customSavedFilters: responseJson.data, loading: false });
                    }
                    break;
                case this.deleteCustomFiltersCallId:
                    this.handleDeleteFilter(responseJson, 'custom');
                    break;
            }   
        }
        // Customizable Area End
    }
    
    async componentDidMount() {
        // Customizable Area Start
        if (this.state.token !== "") {
            this.getSavedFilters();
            this.getCustomSavedFilter();
        }
        // Customizable Area End
    }
    
    // Customizable Area Start
    handleDeleteFilter = (responseJson: any, filterType: string="") => {
        if (responseJson.message) {
            toast.success(responseJson.message);
            if (filterType === "custom") 
            {
                this.getCustomSavedFilter();
                this.props.getCustomFilterApi();
            } else { 
                this.getSavedFilters(); 
            }
        }
        if (responseJson.errors) {
            toast.error(responseJson.errors);
        }
    }

    getSavedFilters = () => {
        this.setState({ loading: true });
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getSavedFilterCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_filter_items/saved_filters`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    deleteFilter = (id: any) => {
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.deleteFiltersCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_filter_items/saved_filters/${id}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "DELETE");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    executeFilter = (id: any, filterType: string) => {
        const { savedFilters, customSavedFilters } = this.state;
        let filter;
        if (filterType === "custom") {
            filter = customSavedFilters.find((filter:any) => filter.id.toString() === id.toString());
        } else {
            filter = savedFilters.find((filter:any) => filter.id.toString() === id.toString());
        }
        if (filter) {
            filterType === "custom" ? this.props.navigateCustomFilterCoinListing(filter) : this.props.navigateCategory(filter); 
        }
    }

    getCustomSavedFilter = () => {
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getCustomSavedFilterCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_filter_items/multi_saved_filters`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    deleteCustomFilter = (id: any) => {
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.deleteCustomFiltersCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_filter_items/multi_saved_filters/${id}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "DELETE");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleChangePage = (event: any, newPage: number) => {
        this.setState({ page: newPage});
    };
    
    handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
    };

    handleCustomFilterChangePage = (event: any, newPage: number) => {
        this.setState({ customFilterPage: newPage});
    };
    
    handleCustomFilterChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ customFilterRowsPerPage: parseInt(event.target.value, 10), page: 0 });
    };

    handleConfirm = (confirm: boolean) => {
        const { filterType } = this.state;
        if (confirm) {
            if (filterType === "custom") {
                this.deleteCustomFilter(this.state.seletedId);
            }
            else {
                this.deleteFilter(this.state.seletedId);
            }
        } 
        this.setState({ showDialog: false });
    };

    handleDialog = (id: number, filterType: string) => {
        this.setState({ showDialog: true, seletedId: id, filterType });
    };
    // Customizable Area End
}

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { toast } from 'react-toastify';
import './ToastProvider.web.css'
import moment from "moment";
import { AccountData, AccountResponse, PricingPlan } from "../../../components/src/types";
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: boolean;
    token: string;
    userProfile: AccountData | null;
    planExpiringInDays: number;
    showConfirmation: boolean;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class SubscriptionController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    getProfileApiCallId: string="";
    cancelSubscriptionApiCallId: string="";
    subscriptionPlanAccessibilityApiCallId: string="";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            loading: false,
            token: localStorage.getItem('token') || "",
            userProfile: null,
            planExpiringInDays: 0,
            showConfirmation: false,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {

        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            runEngine.debugLog("API Message Recived", message);

            switch (apiRequestCallId) {
                case this.getProfileApiCallId:
                    this.setState({ loading: false });
                    this.setProfile(responseJson);
                    break;
                case this.cancelSubscriptionApiCallId:
                    this.setState({ loading: false, showConfirmation: false });
                    this.handleCancelSubscriptionResponse(responseJson);
                    break;
                case this.subscriptionPlanAccessibilityApiCallId:
                    this.setState({ loading: false });
                    this.handlePlanDetails(responseJson);
                    break;
            }
        }
        // Customizable Area End
    }

    async componentDidMount() {
        // Customizable Area Start
        if (this.state.token !== "") {
            this.getProfile();
        }
        // Customizable Area End
    }
    // Customizable Area Start
    handleCancelSubscriptionResponse(responseJson: { message: string, error: string }) {
        if (responseJson.message) {
            this.getProfile();
            toast.success(responseJson.message);
        } if (responseJson.error) {
            toast.error(responseJson.error);
        }
    }

    setProfile(responseJson: AccountResponse) {
        if (responseJson.data) {
            const currentUser = responseJson.data;
            let endDate = moment(currentUser.attributes.trial_end_date).startOf('day');
            let todayDate = moment(new Date(), "YYYY-MM-DD").startOf('day');
            let days = endDate.diff(todayDate, 'days');
            this.setState({
                userProfile: currentUser,
                planExpiringInDays: days,
            });
            this.getSubscriptionPlanAccessibility();
        } else {
            if (responseJson.errors) {
                if (responseJson.errors[0].token) {
                    toast.error(responseJson.errors[0].token);
                }
            }
        }
    }

    handlePlanDetails(data: PricingPlan) {
        const { userProfile } = this.state;
        if (userProfile && userProfile.attributes && userProfile.attributes.subscription_plan && userProfile.attributes.subscription_plan.data) {
            const subsType =userProfile.attributes.subscription_plan.data.attributes.subscription_type;
            localStorage.setItem('currentPlan', JSON.stringify(data[subsType as keyof PricingPlan]));
        }
    }

    getSubscriptionPlanAccessibility() {
        const { token } = this.state;
        this.setState({ loading: true });
        const header = { "Content-Type": "application/json", token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.subscriptionPlanAccessibilityApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_custom_user_subs/subscriptions/subscription_plan_details");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getProfile() {
        this.setState({ loading: true });
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getProfileApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "account_block/accounts/show");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    cancelSubscription() {
        this.setState({ loading: true });
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.cancelSubscriptionApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_custom_user_subs/subscription_plans/cancel_subscription");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleNavigation = (path: string) => {
        let toNavigate = new Message(getName(MessageEnum.NavigationMessage));
        toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), path);
        toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(toNavigate);
    }

    handleClose() {
        this.setState({ showConfirmation: false });
    }

    handleCancelSubscription (subscription_type: string) {
        if (subscription_type === 'basic') {
            this.handleNavigation('SubscribeWeb');
        } else {
            this.setState({ showConfirmation: true });
        }
    }
    // Customizable Area End
}

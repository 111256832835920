import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  Text,
  ScrollView,
  TouchableOpacity,
  View,
  Dimensions,
  StatusBar,
  Image,
  TextInput,
  Linking
} from "react-native";
import { COLORS } from "../../../framework/src/Globals";
import SubHeader from "../../../components/src/SubHeader";
import { mail, globle, phone } from "./assets";
const { height, width } = Dimensions.get('screen')
import { theme } from "../../../framework/src/theme";
import CustomText from "../../../components/src/text";
// Customizable Area End

import ContactusController, { Props } from "./ContactusController";

export default class Contactus extends ContactusController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  getColorTheme = (mode:any, color1:any, color2:any) => {
    return this.context.mode === mode ? color1 : color2
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ScrollView style={[styles.container, { backgroundColor: this.getColorTheme('light' , theme.light.headeBg , theme.dark.headeBg) }]} keyboardShouldPersistTaps="always">

        <StatusBar
          barStyle={this.getColorTheme('light' , 'dark-content' , 'light-content')} backgroundColor={this.getColorTheme('light' , theme.light.headeBg , theme.dark.headeBg)}

        />
        {/* @ts-ignore */}
        <SubHeader
          title={"Contact Us"}
          onPress={()=>this.props.navigation.goBack()}
        />
        <View style={[styles.modalBox, { backgroundColor: this.getColorTheme('light' , theme.light.highlight , theme.dark.secondaryColor) }]}>
          <View style={styles.subView}>
            <TouchableOpacity
              onPress={() => Linking.openURL('https://cryptoscreenerapp-82611-react-native.b82611.stage.eastus.az.svc.builder.ai')}
              style={[styles.glibeView, { backgroundColor: this.getColorTheme('light' , theme.light.lightGray , theme.dark.highlight) }]}>
              <Image source={globle} style={styles.imageStyle} resizeMode={'contain'} />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => Linking.openURL('mailto:contact@kryptokia.com')} style={[styles.glibeView, { backgroundColor: this.getColorTheme('light' , theme.light.lightGray , theme.dark.highlight) }]}>
              <Image source={mail} style={styles.imageStyle} resizeMode={'contain'} />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => this.onClickCall()} style={[styles.glibeView, { backgroundColor: this.getColorTheme('light' , theme.light.lightGray , theme.dark.highlight) }]}>
              <Image source={phone} style={styles.imageStyle} resizeMode={'contain'} />
            </TouchableOpacity>
          </View>
          <View style={{ padding: 10 }}>

            <View style={[styles.inputView, { backgroundColor: this.getColorTheme('light' , theme.light.lightGray , theme.dark.highlight) }]}>
              {
                this.state.isFocusedSubje &&
                //@ts-ignore
                <CustomText style={styles.subText}>{"Enter Subject*"}</CustomText>
              }
              <TextInput
                placeholder={this.state.isFocusedSubje ? '' : "Enter Subject*"}
                {...this.txtInputSubjectProps}
                onFocus={() => {
                  this.setState({ isFocusedSubje: true, subjectError: false })
                }}
                onBlur={() => {
                  this.state.subject === '' && this.setState({ isFocusedSubje: false, subjectError: true });
                }}
                placeholderTextColor={this.getColorTheme('light' , theme.light.placeHolderColor , theme.dark.lightGray)}
                allowFontScaling={false}
                style={[styles.inputeStyle, { color: this.getColorTheme('light' , theme.light.textColor , theme.dark.textColor) }]}
              />
            </View>
            {
              this.state.subjectError ?
              //@ts-ignore
                <CustomText style={styles.textError}>{this.state.subjectErrorLable}</CustomText> : null
            }
            <View style={[styles.inputView, { backgroundColor: this.getColorTheme('light' , theme.light.lightGray , theme.dark.highlight)}]}>
              {
                this.state.isFocusedEmail &&
                //@ts-ignore
                <CustomText style={styles.subText}>{"Enter Email*"}</CustomText>
              }
              <TextInput
                {...this.txtEmailProps}
                allowFontScaling={false}
                placeholder={this.state.isFocusedEmail ? '' : "Enter Email*"}
                onFocus={() => {
                  this.setState({ isFocusedEmail: true, emailError: false })
                }}
                onBlur={() => {
                  this.state.email === '' && this.setState({ isFocusedEmail: false, emailError: true });
                }}
                placeholderTextColor={this.getColorTheme('light' , theme.light.placeHolderColor , theme.dark.lightGray)}

                style={[styles.inputeStyle, { color: this.getColorTheme('light' , theme.light.textColor , theme.dark.textColor) }]}
              />
            </View>
            {
              this.state.emailError ?
              //@ts-ignore
                <CustomText style={styles.textError}>{this.state.errrEmailLable}</CustomText> : null
            }
            <View style={[styles.inputeAreaView, { backgroundColor: this.getColorTheme('light' , theme.light.lightGray , theme.dark.highlight) }]}>
              {
                this.state.isFocuseMessage &&
                //@ts-ignore
                <CustomText style={styles.subText}>{"Write a Message*"}</CustomText>
              }
              <TextInput
                {...this.txtCommentsProps}
              allowFontScaling={false}
                placeholder={this.state.isFocuseMessage ? '' : "Write a Message*"}
                onFocus={() => {
                  this.setState({ isFocuseMessage: true, commentError: false })
                }}
                onBlur={() => {
                  this.state.comments === '' && this.setState({ isFocuseMessage: false, commentError: true });
                }}
                multiline={true}
                numberOfLines={10}
                placeholderTextColor={this.getColorTheme('light' , theme.light.placeHolderColor , theme.dark.lightGray)}
                style={[styles.inputeAreaStyle, { color: this.getColorTheme('light' , theme.light.textColor , theme.dark.textColor) }]}
              />
            </View>
            {
              this.state.commentError ?
                <Text style={styles.textError}>{this.state.errCommentLable}</Text> : null
            }
          </View>
          <View style={{ alignItems: 'center', justifyContent: 'center', marginTop: '30%' }}>
            <TouchableOpacity
              onPress={() => {
                this.addQueryApi()
              }}
              style={styles.btnStyle}>
              <Text style={styles.btnText}>{"Submit"}</Text>
            </TouchableOpacity>
          </View>
        </View>
      </ScrollView>
      // Customizable Area End
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#F4F8F5'
  },
  tableBox: {
    borderWidth: 1,
    borderRadius: 4,
    borderColor: "#ccc",
    padding: 15,
    marginVertical: 10,
    marginBottom: 30
  },
  webviewStyle: {
    height: 200
  },
  infoText: {
    fontSize: 16,
    marginVertical: 4
  },
  labelText: {
    fontWeight: "bold"
  },
  viewBtn: {
    backgroundColor: "blue",
    paddingVertical: 8,
    borderRadius: 4,
    marginTop: 10,
    borderWidth: 1,
    borderColor: "blue"
  },
  viewBtnText: {
    color: "#fff",
    textAlign: "center",
    fontSize: 16
  },
  addtext: {
    fontSize: 16,
    color: "#fff",
    textAlign: "center"
  },
  buttonBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  buttonTop: {
    display: "flex",
    alignSelf: "flex-end",
    alignItems: "flex-end",
    justifyContent: "flex-end"
  },
  addBtn: {
    backgroundColor: "blue",
    marginBottom: 10,
    width: 120,
    height: 40,
    display: "flex",
    justifyContent: "center",
    borderRadius: 4,
    alignSelf: "flex-end"
  },
  modalBox: {
    display: "flex",
    width: "100%",
    height: height / 1.2,
    backgroundColor: "#fff",
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    elevation: 1
  },
  modal: {
    width: "80%",
    borderRadius: 4,
    borderWidth: 1,
    borderColor: "#ccc",
    marginTop: 80,
    marginLeft: 40,
    padding: 15
  },
  viewBtnWidth: {
    width: "48%"
  },
  closeBtnText: {
    color: "#000",
    textAlign: "center",
    fontSize: 16
  },
  closeBtn: {
    backgroundColor: "#fff",
    paddingVertical: 8,
    borderRadius: 4,
    marginTop: 10,
    borderColor: "#ccc",
    borderWidth: 1
  },
  subView: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '10%',
    marginLeft: '20%', marginRight: '20%',

  },
  glibeView: {
    backgroundColor: '#F4F8F5',
    padding: 20,
    borderRadius: 20
  },
  inputView: {
    backgroundColor: '#F4F8F5',
    borderRadius: 10,
    padding: 15,
    justifyContent: 'center',
    marginTop: 10,
    height: height / 13
  },
  inputeStyle: {
    fontSize: 18,
    color: COLORS.black,
    fontFamily:'PlusJakartaText-Regular',
    height: height / 20,
    marginLeft: 10,
    alignItems: 'center'
  },

  subText: {
    color: 'gray',
    marginLeft: 10,
    fontFamily:'PlusJakartaText-Regular',
  },
  inputeAreaStyle: {
    height: height / 8,
    textAlignVertical: 'top',
    marginLeft: 10,
    fontSize: 18,
    fontFamily:'PlusJakartaText-Regular'
  },
  btnStyle: {
    backgroundColor: '#489F57',
    height: height / 16,
    width: width / 1.2,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center', elevation: 5
  },
  btnText: {
    color: COLORS.white,
   fontFamily:'PlusJakartaText-Bold',
    letterSpacing: 1,
    fontSize: 18
  },
  imageStyle: {
    tintColor: COLORS.green,
    width: 30,
    height: 30
  },
  textError: {
    color: 'red',
    marginLeft: 5
  },
  inputeAreaView: {
    backgroundColor: '#F4F8F5',
    borderRadius: 10,
    padding: 10,
    justifyContent: 'center',
    marginTop: 10,
    height: height / 6
  }
});
// Customizable Area End

import React from "react";
// Customizable Area Start
import  ReferralsControllerWeb, { Props } from "./ReferralsController.web";
import {
    withStyles, StyleRules
} from "@material-ui/core/styles";
import Loader from "../../../components/src/Loader.web";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { contentCopyIcon, contentCopyLightIcon } from "./assets";
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import { isEmpty } from "../../../framework/src/Utilities";
// Customizable Area End

const styles: StyleRules = {
    // Customizable Area Start
    root: {
        padding: "50px 100px",
        '@media (max-width: 768px)': {
            padding: "0 35px",
        },
    },
    heading: {
        padding:  '5px 20px 15px 20px',
        '@media (max-width: 768px)': {
            fontSize: "16px",
            paddingLeft: 0
        }
    },
    informationBlock: {
        backgroundColor: '#01a24e',
        color: '#ffffff',
    },
    stepperBlock: {
        backgroundColor: '#01a24e',
        '@media (max-width: 768px)': {
            paddingLeft: 0,
        },
        "& .MuiStepLabel-label": {
            fontSize: '16px',
            color: '#ffffff',
            '@media (max-width: 768px)': {
                fontSize: '14px',
            }
        },
        "& .MuiStepper-root": {
            '@media (max-width: 768px)': {
                paddingLeft: 10,
            }
        },
        "& .MuiStepConnector-line": {
            minHeight: '100px',
            borderWidth: '2px',
            marginLeft: '2px',
            marginTop: '4px',
            borderColor: '#fff',
            '@media (max-width: 768px)': {
                minHeight: 60,
                marginTop: 4
            }
        },
        "& .MuiStepIcon-root": {
            fill: '#49a350',
            stroke: '#ffffff',
        }
    },
    divider: {
        backgroundColor: '#ffffff',
        marginLeft: '-16px',
        marginRight: '-16px',
        marginBottom: 5
    },
    formGrid: {
        marginTop: '-16px',
        "&.MuiGrid-item": {
            '@media (max-width: 768px)': {
                marginTop: 0,
                padding: '16px 0',
            }
        }
    },
    formBlock: {
        border: '1px solid #e5eeea',
        padding: '20px 20px 100px 20px',
        backgroundColor: '#eefdf7',
        marginBottom: '-15px',
        '@media (max-width: 768px)': {
            marginBottom: 0,
            padding: '20px 20px 40px 20px',
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: '#01a24e',
        },
        "& .Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: '#01a24e',
            }
        },
    },
    inviteBtn: {
        borderRadius: 20,
        marginTop: 15,
        backgroundColor: '#01a24e',
        color: '#ffffff',
        "&:hover": {
            backgroundColor: '#01a24e',
            color: '#ffffff',
        }
    },
    stepIcon: {
        height: 12,
        width: 12,
        borderRadius: '50%',
        border: '2px solid rgb(255, 255, 255)',
        padding: 8,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#ffffff'
    }
    // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export class ReferralsWeb extends ReferralsControllerWeb {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    StepIconComponent = (params: {active: boolean, completed: boolean, error: boolean, icon: number}) => {
        const { classes } = this.props;
        return <div className={classes.stepIcon}>{params.icon}</div>
    }    
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const steps = this.getSteps();
        const copyIcon = localStorage.getItem("appTheme") === "darkTheme" ? contentCopyLightIcon : contentCopyIcon;
        const imgWidth = localStorage.getItem("appTheme") === "darkTheme" ? 22 : 15;
        const textFieldBgColor = localStorage.getItem('appTheme') === "darkTheme" ? '#363333' : '#FFFFFF';
        const { errors, fullName, emailAddress, totalPoints, referredPoints } = this.state;
        return (
            <Paper  className={classes.root} data-testid='referralPaper' square elevation={0} style={{ backgroundColor: localStorage.getItem('appTheme') == 'darkTheme' ? '#232429' : '#FFFFFF' }}>
                {this.state.loading && <Loader loading={this.state.loading} />}
                <Grid container sm={12} spacing={4}>
                    <Grid item className={classes.informationBlock} sm={7} xs={12}>
                        <Typography variant="h5" data-testid="heading" className={classes.heading}>
                            Refer a Friend and earn {referredPoints} Points
                        </Typography>
                        <Divider className={classes.divider} />
                        <Stepper className={classes.stepperBlock} orientation="vertical">
                        {steps.map((label:string) => (
                            <Step key={label}>
                                <StepLabel StepIconComponent={this.StepIconComponent}>{label}</StepLabel>
                            </Step>
                        ))}
                        </Stepper>
                    </Grid>
                    <Grid item sm={5} xs={12} spacing={2} className={classes.formGrid}>
                        <Grid className={classes.formBlock} item style={{ backgroundColor: localStorage.getItem('appTheme') == 'darkTheme' ? '#363333' : '#EEFDF7' }}>
                            <Typography variant="h6" style={{ fontWeight: 'bold' }}>Your Rewards</Typography>
                            <Typography variant="caption" data-testid="points">{totalPoints} Points earned</Typography>
                            <Typography style={{ margin: '20px 0', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>Your pending rewards <InfoIcon /></Typography>
                            <Typography style={{ fontWeight: 'bold', marginBottom: 10 }}>Enter your contact's details</Typography>
                            <Grid alignItems="center">
                                <form data-testid="inviteForm" noValidate onSubmit={(event: React.FormEvent<HTMLFormElement>) =>  this.sendInvite(event)}>
                                    <TextField error={!isEmpty(errors.fullName)}
                                    InputProps = {{
                                        style: { backgroundColor: textFieldBgColor }
                                    }}
                                    helperText={errors.fullName} name="fullName"
                                    data-testid="fullName" fullWidth variant="outlined"
                                    value={fullName}
                                     label={false} placeholder="Full Name" onChange={(event: React.ChangeEvent<HTMLInputElement> ) => this.handleInputChange(event) } />
                                    <TextField error={!isEmpty(errors.emailAddress)}
                                    InputProps = {{
                                        style: { backgroundColor: textFieldBgColor }
                                    }}
                                    helperText={errors.emailAddress} name="emailAddress" 
                                    data-testid="emailAddress" fullWidth 
                                    value={emailAddress}
                                    style={{margin: '10px 0' }} variant="outlined" label={false} placeholder="Email Address" onChange={(event: React.ChangeEvent<HTMLInputElement> ) => this.handleInputChange(event) } />
                                    <Button type="submit" data-testid="sendInviteBtn" className={classes.inviteBtn} fullWidth variant="contained">Send Invite</Button>
                                </form>
                                <Typography gutterBottom style={{ display: 'flex', margin: "30px 0 10px 0", color: '#49a350' }} variant="caption">Share through Social Media</Typography>
                                <TextField 
                                    data-testid="socialMedia" fullWidth 
                                    variant="outlined" value={this.state.socialMediaUrl}
                                    style={{ backgroundColor: textFieldBgColor }}
                                    InputProps={{
                                    endAdornment: <InputAdornment position="start">
                                        <IconButton title="copy url" style={{ cursor: 'pointer' }} onClick={(event) => this.copyText() }>
                                            <img src={copyIcon} width={imgWidth} alt="copy url"/>
                                        </IconButton>
                                    </InputAdornment>
                                  }}                        
                                 label={false} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        )
        // Customizable Area End
    }
}

export default withStyles(styles)(ReferralsWeb);

Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Payments";
exports.labelBodyText = "Payments Body";
exports.addCardEndPoint="/bx_block_savedcards/cards";
exports.postApiMethod="POST";
exports.btnExampleTitle = "CLICK ME";
exports.yearErrorMessage = "year is required";
exports.monthErrorMessage = "month is required";
exports.cardInvalidMessage = "card number is invalid";
exports.cardNoRequireMessage = "card number is required";
exports.cvvInvalidMessage = "cvv is invalid";
exports.cvvRequiredMessage = "cvv is require";
exports.nameErrorLable="Please enter name";
exports.cardnoErrorLabel="Please enter card number";
exports.cvvErrorLabel="Please Enter cvv";
exports.monthAndYearError="Please select month and year";
exports.yearError="Please select year";
exports.monthError="Please select month";
exports.cardNoValidationLabel="Minimum card number length is 16";
exports.cardNoValidationLabel2="Card number cannot start with zero";
exports.cardEditApiEndPoint="/bx_block_savedcards/cards/";
exports.PutMethos="PUT"


// Customizable Area End
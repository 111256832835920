import React from "react";
// Customizable Area Start
import ScreenerWebController, {
    Props
} from "./ScreenerController.web";
import {
    withStyles, StyleRules
} from "@material-ui/core/styles";
import { Route } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Paper from "@material-ui/core/Paper";
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import Popover from '@material-ui/core/Popover';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from "@material-ui/core/Checkbox";
import Slider from '@material-ui/core/Slider';
import InputAdornment from '@material-ui/core/InputAdornment';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Chip from '@material-ui/core/Chip';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Button from '@material-ui/core/Button';
import Loader from "../../../components/src/Loader.web";
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from "@material-ui/core/DialogActions";
import CloseIcon from '@material-ui/icons/Close';
import PriceActionTable from "./PriceAction.web";
import FundamentalRankResponse from './FundamentalRankResponse.web';
import MarketCapitalizationResponse from './FundamentalMarketCapitalizationResponse.web';
import IndicatorsTable from "./Indicators.web";
import VolumeTable from "./volume.web";
import CryptoDetailWeb from "./CryptoDetail.web";
import WatchListWeb from "../../Watchlist2/src/WatchList.web";
import SearchChartWeb from "../../info-page/src/SearchChart.web";
import DipAlertWeb from "../../info-page/src/DipAlert.web";
import { removePlusMinusSymbol } from "../../../components/src/chartUtils.web";
import { isEmpty } from "../../../framework/src/Utilities";
import SavedFiltersWeb from "./SavedFilters.web";
import ClearIcon from '@material-ui/icons/Clear';
import CustomFilterScreener from "../../../components/src/CustomFilterScreener.web";
import NoSubscriptionDialogWeb from "../../../components/src/NoSubscriptionDialogWeb";
import { UpgradeAlert } from "../../../components/src/upgradeNow.web";
// Customizable Area End

const styles: StyleRules = {
    // Customizable Area Start
    mainDiv: {
        display: 'flex',
        padding: '22px'
    },
    leftDiv: {
        width: '17%'
    },
    rightDiv: {
        width: "83%"
    },
    leftContainer: {
        flexGrow: 1,
        margin: '0px 16px 8px 0px',
        borderRadius: '12px',
        height: '100vh'
    },
    rightContainer: {
        flexGrow: 1,
        borderRadius: '12px'
    },
    textContent: {
        fontFamily: 'Plus Jakarta Text',
        fontSize: '13px',
        color: '#a5a8ac'
    },
    fullWidth: {
        '& .MuiFormControl-fullWidth': {
        },
        '& .MuiSelect-selectMenu': {
            textAlign: 'left'
        },
        '& .MuiRadio-colorSecondary.Mui-checked': {
            color: 'green'
        }
    },
    input: {
        borderRadius: '12px',
        fontSize: '12px',
        paddingRight: '0px'
    },
    popoverPaper: {
        width: '300px',
        padding: '16px',
        '@media (max-width: 425px)': {
            width: '250px'
        }
    },
    buttonGroup: {
        gap: '10px'
    },
    submitButton: {
        backgroundColor: 'rgba(1,162,78,255)',
        color: '#ffffff',
        cursor: 'pointer',
        border: 'none',
        borderRadius: '10px !important',
        width: '100%',
        display: 'block',
        fontWeight: 600,
        margin: '0 auto',
        '&.MuiButton-root.Mui-disabled': {
            color: 'rgb(198, 207, 202)'
        },
        '&:hover': {
            backgroundColor: 'rgba(1,162,78,255)'
        }
    },
    loadMoreButton: {
        backgroundColor: 'rgba(1,162,78,255)',
        color: '#ffffff',
        cursor: 'pointer',
        padding: '12px 20px',
        border: 'none',
        borderRadius: '10px',
        textAlign: 'center',
        display: 'block',
        margin: '2px auto',
        fontWeight: 600,
        '&:hover': {
            backgroundColor: 'rgba(1,162,78,255)',
        }
    },
    checkBoxColor: {
        width: '100% !important',
        '& .MuiCheckbox-colorSecondary.Mui-checked': {
            color: 'green'
        }
    },
    customTh: {
        textAlign: 'left',
        width: 'auto'
    },
    customTd: {
        textAlign: 'left',
        width: 'auto',
        color: '#b4c1bb'
    },
    filtered: {
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: '10px',
        margin: 0,
    },
    chip: {
        margin: '10px',
        color: 'rgba(1,162,78,255)',
        fontWeight: 'bold',
        textTransform: 'capitalize',
        '& .MuiChip-label': {
            whiteSpace: 'break-spaces',
        },
        '@media (max-width: 425px)': {
            margin: '0px',
            padding: '30px 0px'
        },
    },
    removeBorder: {
        '& .MuiTableCell-root': {
            borderBottom: 'none'
        }
    },
    menuItem: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        pointerEvents: 'auto', // Ensure that pointer events are enabled
      },
      messageContainer: {
        position: 'relative', // Use relative positioning for absolute content
        textAlign: 'center',
        padding: '16px',
        borderRadius: 8,
        pointerEvents: 'auto'
      },
      button: {
        position: 'absolute',
        bottom: '16px',
        left: '50%',
        transform: 'translateX(-50%)',
      },
     
    floatingTooltip: {
        width: 180,
        height: 80,
        position: 'absolute',
        padding: 8,
        boxSizing: 'border-box',
        fontSize: 12,
        color: '#131722',
        backgroundColor: '#ffffff',
        textAlign: 'left',
        zIndex: 100000,
        pointerEvents: 'none',
        borderRadius: 6,
        boxShadow: 'rgba(88, 102, 126, 0.08) 0px 1px 1px, rgba(88, 102, 126, 0.1) 0px 8px 16px'
    },
    tableHead: {
        fontSize: '12px',
        whiteSpace: 'pre'
    },
    tableContent: {
        fontWeight: 'bold',
        fontSize: '12px',
        whiteSpace: 'pre',
    },
    radio: {
        '&checked': {
            color: '#008000'
        }
    },
    toggleBtnDiv: {
        padding: '22px 22px 0px'
    },
    '@media (max-width: 1138px)': {
        leftDiv: {
            width: '25%'
        },
        rightDiv: {
            width: "75%"
        },
    },
    '@media (max-width: 768px)': {
        mainDiv: {
            display: 'block',
            padding: '10px'
        },
        leftDiv: {
            width: '65%'
        },
        rightDiv: {
            width: "100%"
        },
        leftContainer: {
            height: 'auto',
        },
        toggleBtnDiv: {
            padding: '10px'
        }
    },
    toggleButton: {
        backgroundColor: 'rgb(255, 255, 255)',
        color: 'rgba(1,162,78,255)',
        height: '40px',
        marginTop: '5px',
        fontWeight: 'bold',
        '&.MuiToggleButton-root.Mui-selected': {
            backgroundColor: 'rgba(1,162,78,255)',
            color: 'rgb(255, 255, 255)'
        }
    },
    blurItem: {
        filter: 'blur(1.5px)'
    }
    // Customizable Area End
}
// Customizable Area Start
const SaveFilterName = ({ classes, open, filterName, handleChange, handleSubmit, handleCancel, handleClickedBtn }: any) => {
    return (
        <Dialog open={open}>
            <DialogTitle>
                <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                    Enter Filter Name
                </Typography>
            </DialogTitle>
            <DialogContent>
            
            <TextField
                id="filterName"
                data-testid="filterName"
                label={`Filter Name`}
                value={filterName}
                onChange={(event) => handleChange(event) }
                name={"filterName"}
                fullWidth
                variant="filled"
                InputProps={{
                    disableUnderline: true,
                    className: classes.input
                }}
                InputLabelProps={{ shrink: true }}                    
            />
                        
            </DialogContent>
            <DialogActions>
                <Button className={classes.submitButton} onClick={handleCancel} data-testid="cancelSaveFilterBtn">
                    Cancel
                </Button>
                <Button data-testid="saveFilterBtn" type="submit" disabled={isEmpty(filterName)} className={classes.submitButton} onClick={() => {
                    handleClickedBtn('Save');
                    handleSubmit()}}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const FilterChip = ({ filteredChip, classes, handleFilterDelete }: any) => {
    return (
        <Paper component="ul" className={classes.filtered} elevation={0}>
            {filteredChip.map((data: any) => {
                let icon;
                return (
                    <li key={data.key}>
                        <Chip
                            icon={icon}
                            label={data}
                            onDelete={handleFilterDelete(data)}
                            className={classes.chip}
                        />
                    </li>
                );
            })}
        </Paper>
    )
}

const UpDownIconButton = ({ value }: any) => {
    return (
        <div style={{ display: 'flex', alignItems: "center" }}>
        {value.toString().charAt(0) === "-" ? <ArrowDropDownIcon style={{ color: '#E86A3F', fontSize: '12px', fontWeight: 'bold' }} /> : <ArrowDropUpIcon style={{ color: '#01A24E', fontSize: '12px', fontWeight: 'bold' }} />}
        <Typography
            gutterBottom
            variant="body2"
            component="div"
            style={{ color: value.toString().charAt(0) === "-" ? '#E86A3F' : '#01A24E', fontWeight: 'bold', fontSize: '12px' }}
        >
            {`${ removePlusMinusSymbol(value)}`}
        </Typography>
    </div>
    )
}

const VolumePopup = ({ lastVolumeResponseData, lastVolumnPaginationRowsPerPage, lastVolumnPaginationPage, onPageChange, onRowsPerPageChange }: any) => {
    return (
        <>
            <TableContainer>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell align="left" style={{ fontSize: '12px' }}>Volume</TableCell>
                            <TableCell align="left" style={{ fontSize: '12px' }}>Time Stamp</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(lastVolumnPaginationRowsPerPage > 0
                            ? lastVolumeResponseData.slice(lastVolumnPaginationPage * lastVolumnPaginationRowsPerPage, lastVolumnPaginationPage * lastVolumnPaginationRowsPerPage + lastVolumnPaginationRowsPerPage)
                            : lastVolumeResponseData
                        ).map((rowObject: any, index: any) => (
                            <TableRow
                                key={rowObject.id}
                            >
                                <TableCell component="th" scope="row">
                                    {(lastVolumnPaginationPage * lastVolumnPaginationRowsPerPage) + (index + 1)}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                                        {`${rowObject.volume}`}
                                    </Typography>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                                        {`${rowObject.timestamp}`}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={lastVolumeResponseData.length}
                rowsPerPage={lastVolumnPaginationRowsPerPage}
                page={lastVolumnPaginationPage}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
            />
        </>
    )
}

const indicatorsSchema = Yup.object().shape({
});

const derivativesSchema = Yup.object().shape({
    derivativesCategory: Yup.string()
        .required('Derivatives category is required.'),
    derivativesOption: Yup.string()
        .required('Derivatives option is required.'),
});
// Customizable Area End

export class ScreenerWeb extends ScreenerWebController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.priceActionChart = React.createRef();
        this.priceActionChartContainerRef = React.createRef();
        this.fundamentalChart = React.createRef();
        this.fundamentalChartContainerRef = React.createRef();
        // Customizable Area End
    }

    // Customizable Area Start

    hasChildren = (item: any) => {

        const { items } = item;

        if (items === undefined) {
            return false;
        }

        if (items.constructor !== Array) {
            return false;
        }

        if (items.length === 0) {
            return false;
        }

        return true;
    }

    CustomMenuItem = ({ item, keyStr }: any) => {
        return this.hasChildren(item) ? this.MultiLevel({ item, keyStr }) : this.SingleLevel({ item, keyStr });
    };

    SingleLevel = ({ item, keyStr }: any) => {

        const { activeItem } = this.state;
        return (
            <Route
                key={keyStr}
                path={item.to}
                exact={true}
                data-testid="menuOpt"
                children={() => (
                    <>
                        <ListItem
                            button
                            data-testid="menuItem"
                            selected={item.title === activeItem}
                            onClick={(event: any) => {
                                this.handleItemClick(event, item)
                            }}
                            style={{ borderRadius: '12px' }}
                        >
                            <ListItemIcon style={{ minWidth: '0px', color: activeItem == item.title ? '#6bc897' : '#a7abaa' }}>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText
                                primary={item.title}
                                primaryTypographyProps={{ style: { fontSize: '13px', color: activeItem == item.title ? '#6bc897' : '#a7abaa', marginLeft: '12px' } }}
                            />
                            <ArrowRightIcon style={{ color: activeItem == item.title ? '#6bc897' : '#a7abaa' }} />
                        </ListItem>
                        {/* <Divider /> */}
                    </>
                )}
            />
        );
    };

    MultiLevel = ({ item, keyStr }: any) => {
        const { items: children } = item;
        const { screenerData } = this.state;

        const handleClick = () => {
            let obj = findObject(screenerData, item.title);
        };

        const findObject: any = (arr: any, id: any) => {
            let result: any;
            for (let i = 0; i < arr.length; i++) {
                if (arr[i].title === id) {
                    arr[i].isActive = !arr[i].isActive;
                    result = arr[i];
                    break;
                }
                if (arr[i].items) {
                    result = findObject(arr[i].items, id);
                    if (result) break;
                }
            }
            this.setState((preState) => ({
                screenerData: arr
            }), () => {
            })
            return result;
        }

        return (
            <React.Fragment key={keyStr}>
                <ListItem button onClick={handleClick} data-testid={`multilevel-item-#{keyStr}`}>
                    <ListItemText
                        primary={item.title}
                        primaryTypographyProps={{ style: { color: item.type == 'folder' ? '#a7abaa' : '#252525', marginLeft: item.type == 'folder' ? '12px' : '0px', fontSize: item.type == 'folder' ? '13px' : '16px' } }}
                    />
                    {(() => {
                        switch (item.type) {
                            case "root":
                                return item.isActive ? <ArrowDropDownIcon /> : <ArrowRightIcon />;
                            case "folder":
                                return item.isActive ? <ExpandLess /> : <ExpandMore />;
                        }
                    })()}
                </ListItem>
                <Collapse in={(() => {
                    switch (item.type) {
                        case "root":
                            return item.isActive ? true : false;
                        case "folder":
                            return item.isActive ? true : false;
                    }
                })()} timeout="auto" unmountOnExit>
                    <List component="nav" disablePadding key={keyStr}>
                        {children.map((child: any) => (
                            this.CustomMenuItem({ item: child })
                        ))}
                    </List>
                </Collapse>
            </React.Fragment>
        );
    };

    TimeFrameOptions = (timeObj: {name: string, value: string, disabled: boolean}, index: number) => {
        const { classes } = this.props;
        return (
          <MenuItem key={index} className={timeObj.disabled && classes.blurItem} value={timeObj.value} disabled={timeObj.disabled}>
              <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '12px', whiteSpace: 'pre-line' }}>
                  {timeObj.name}
              </Typography>
          </MenuItem>
        )
    }
    TimeFrameField = ({ form }: any) => {
        const { classes } =  this.props;
        return (
            <TextField
                id="priceActionTimeFrame"
                data-testid="priceActionTimeFrame"
                select
                label={`Select Time Frame`}
                value={this.state.priceActionTimeFrame}
                onChange={(e) => {
                    this.setState({
                        priceActionTimeFrame: e.target.value
                    })
                }}
                name={"priceActionTimeFrame"}
                fullWidth
                variant="filled"
                size="small"
                margin="normal"
                InputProps={{
                    disableUnderline: true,
                    className: classes.input,
                    endAdornment:  
                        <InputAdornment position="end" style={{ cursor: 'pointer', position: "absolute", paddingRight: 0, right: 25 }}>
                            <ClearIcon  onClick={() => { this.setState(
                            { priceActionTimeFrame: "" })}}/>
                        </InputAdornment>
                }}
                InputLabelProps={{ shrink: true }}
                error={
                    Boolean(form.errors.priceActionTimeFrame && form.touched.priceActionTimeFrame)
                }
                helperText={
                    form.errors.priceActionTimeFrame &&
                    form.touched.priceActionTimeFrame &&
                    String(form.errors.priceActionTimeFrame)
                }
            >
                {this.state.priceActionTimeFrameList.map((timeObj: any, index: number) => (
                    this.TimeFrameOptions(timeObj, index)
              ))}
              {this.state.priceActionTimeFrameList.find((timeObj: any) => timeObj.disabled) && 
              <div style={{ position: 'absolute', top: 300, left: 40 }}><UpgradeAlert /></div>}
        </TextField>
        )
    };

    VolumePeriodField = ({ classes }: any) => {
        return (
            <div className={classes.fullWidth} >
                <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                    {`Period`}
                </Typography>
                <Field
                    validateOnBlur
                    validateOnChange
                    name="volumnPeriod"
                    render={({ field, form }: any) => (
                        <TextField
                            id="volumnPeriod"
                            select
                            data-testid="volPeriod"
                            label={`Select Period`}
                            value={this.state.volumnPeriod}
                            onChange={(event) => {
                                this.setState({
                                    volumnPeriod: event.target.value
                                })
                            }}
                            name={"volumnPeriod"}
                            fullWidth
                            variant="filled"
                            size="small"
                            margin="normal"
                            // hiddenLabel
                            InputProps={{
                                disableUnderline: true,
                                className: classes.input,
                                endAdornment:  
                                    <InputAdornment position="end" style={{ cursor: 'pointer', position: "absolute", paddingRight: 0, right: 25 }}>
                                        <ClearIcon  onClick={() => { this.setState(
                                        { volumnPeriod: "" })}}/>
                                    </InputAdornment>
                            }}
                            InputLabelProps={{ shrink: true }}
                            error={
                                Boolean(form.errors.volumnPeriod && form.touched.volumnPeriod)
                            }
                            helperText={
                                form.errors.volumnPeriod &&
                                form.touched.volumnPeriod &&
                                String(form.errors.volumnPeriod)
                            }
                        // style={{ marginBottom: '15px' }}
                        >
                            {this.state.priceActionTimeFrameList.map((timeObj: any, index: number) => (
                                this.TimeFrameOptions(timeObj, index)
                            ))}
                            {this.state.priceActionTimeFrameList.find((timeObj: any) => timeObj.disabled) && 
                            <div style={{ position: 'absolute', top: 300, left: 40 }}><UpgradeAlert /></div>}
                        </TextField>
                    )}
                />
            </div>
        )
    }

    handleClose = () => {
        this.setState({
            anchorEl: null,
            activeItem: ''
        })
    }

    fundamentals = () => {
        const { activeItem, filterName, filterNamePopup } = this.state;
        const { classes } = this.props;
        return (
            <Popover
                open={Boolean(this.state.anchorEl) && activeItem == 'Fundamentals'}
                data-testid="fundamentalPopup"
                anchorEl={this.state.anchorEl}
                onClose={this.handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                disableAutoFocus={true}
                disableEnforceFocus={true}
                PaperProps={{
                    className: classes.popoverPaper,
                }}
            >
                <Formik
                    initialValues={{
                        rank: this.state.fundamentalsForm.rank,
                        customRankVal: this.state.fundamentalsForm.customRankVal,
                        category: this.state.fundamentalsForm.category || "",
                        marketCapitalizationFrom: this.state.fundamentalsForm.marketCapitalizationFrom,
                        marketCapitalizationTo: this.state.fundamentalsForm.marketCapitalizationTo,
                    }}
                    enableReinitialize
                    onSubmit={(values) => {
                        const callMethods = async () => {
                            if (this.state.clickedBtn && ['Run', 'Save'].includes(this.state.clickedBtn)) {
                                await Promise.all([this.resetDataBeforeAPICall(), this.fundamentalsForm(values)])
                            }
                            this.setNavigation();
                        }
                        callMethods()
                    }}
                >
                    {({
                        values,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                    }) => (
                        <Form
                            onSubmit={handleSubmit}>
                            <div className={classes.fullWidth}>
                                <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                                    {`Rank`}
                                </Typography>
                                <Field
                                    validateOnBlur
                                    validateOnChange
                                    name="rank"
                                    render={({ form }: any) => (
                                        <TextField
                                            id="rank"
                                            select
                                            label={`Select Rank`}
                                            value={values.rank}
                                            data-testid='fundamentalsRank'
                                            onChange={(event) => {
                                                setFieldValue("rank", event.target.value);
                                                if (Number(event.target.value) == 10 || Number(event.target.value) == 50) {
                                                    setFieldValue("customRankVal", 0);
                                                    this.setState((preState) => ({
                                                        fundamentalsForm: {
                                                            ...preState.fundamentalsForm,
                                                            "rank": event.target.value,
                                                            "customRankVal": 0
                                                        }
                                                    }))
                                                }
                                            }}
                                            name={"rank"}
                                            fullWidth
                                            variant="filled"
                                            size="small"
                                            margin="normal"
                                            // hiddenLabel
                                            InputProps={{
                                                disableUnderline: true,
                                                className: classes.input,
                                                endAdornment:  
                                                    <InputAdornment position="end" style={{ cursor: 'pointer', position: "absolute", paddingRight: 0, right: 25 }}>
                                                        <ClearIcon  onClick={() => { this.setState((preState) =>
                                                        ({fundamentalsForm: { ...preState.fundamentalsForm, rank: ""} }))}}/>
                                                    </InputAdornment>
                                            }}
                                            InputLabelProps={{ shrink: true }}
                                            error={
                                                Boolean(form.errors.rank && form.touched.rank)
                                            }
                                            helperText={
                                                form.errors.rank &&
                                                form.touched.rank &&
                                                String(form.errors.rank)
                                            }
                                        // style={{ marginBottom: '15px' }}
                                        >
                                            {this.state.rankList.map((_data: any, key: number) => (
                                                <MenuItem key={key} value={_data.value}>
                                                    <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '12px', whiteSpace: 'pre-line' }}>
                                                        {_data.name}
                                                    </Typography>
                                                </MenuItem>
                                            ))}
                                            <MenuItem key={0} value={0}>
                                                <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '12px', whiteSpace: 'pre-line' }}>
                                                    {'Custom'}
                                                </Typography>
                                            </MenuItem>
                                        </TextField>
                                    )}
                                />
                            </div>
                            {
                                values.rank === 0 && <div className={classes.fullWidth}>
                                    <Field
                                        validateOnBlur
                                        validateOnChange
                                        name="customRankVal"
                                        render={({ field, form }: any) => (
                                            <TextField
                                                label={"Rank"}
                                                name={"customRankVal"}
                                                variant="filled"
                                                value={values.customRankVal}
                                                fullWidth
                                                size="small"
                                                margin="normal"
                                                type="number"
                                                inputProps={{ min: 0 }}
                                                InputProps={{ disableUnderline: true, style: { fontSize: '12px' },
                                                }}
                                                InputLabelProps={{ shrink: true }}
                                                error={
                                                    Boolean(form.errors.customRankVal && form.touched.customRankVal)
                                                }
                                                onChange={handleChange}
                                                onBlur={(event: any) => {
                                                    handleBlur(event);
                                                    this.setState((preState) => ({
                                                        fundamentalsForm: {
                                                            ...preState.fundamentalsForm,
                                                            "rank": 0,
                                                            "customRankVal": event.target.value
                                                        }
                                                    }))
                                                    // this.updateState();
                                                }}
                                                helperText={
                                                    form.errors.customRankVal &&
                                                    form.touched.customRankVal &&
                                                    String(form.errors.customRankVal)
                                                }
                                            />
                                        )}
                                    />
                                </div>
                            }
                            <div className={classes.fullWidth}>
                                <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                                    {`Category`}
                                </Typography>
                                <Field
                                    validateOnBlur
                                    validateOnChange
                                    name="category"
                                    render={({ field, form }: any) => (
                                        <TextField
                                            id="category"
                                            select
                                            label={`Select Category`}
                                            value={values.category}
                                            data-testid='fundamentalsCategory'
                                            onChange={(event) => {
                                                setFieldValue("category", event.target.value);
                                                this.setState((preState) => ({
                                                    fundamentalsForm: {
                                                        ...preState.fundamentalsForm,
                                                        "category": event.target.value
                                                    }
                                                }))
                                            }}
                                            name={"category"}
                                            fullWidth
                                            variant="filled"
                                            size="small"
                                            margin="normal"
                                            // hiddenLabel
                                            InputProps={{
                                                disableUnderline: true,
                                                className: classes.input,
                                                endAdornment:  
                                                    <InputAdornment position="end" style={{ cursor: 'pointer', position: "absolute", paddingRight: 0, right: 25 }}>
                                                        <ClearIcon  onClick={() => { this.setState((preState) =>
                                                        ({fundamentalsForm: { ...preState.fundamentalsForm, category: "" } }))}}/>
                                                    </InputAdornment>
                                            }}
                                            InputLabelProps={{ shrink: true }}
                                            error={
                                                Boolean(form.errors.category && form.touched.category)
                                            }
                                            helperText={
                                                form.errors.category &&
                                                form.touched.category &&
                                                String(form.errors.category)
                                            }
                                        >
                                            {this.state.categoriesList.map((_data: any, key: number) => (
                                                <MenuItem key={key} value={_data.name}>
                                                    <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '12px', whiteSpace: 'pre-line' }}>
                                                        {_data.name}
                                                    </Typography>
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    )}
                                />
                            </div>
                            <div className={classes.fullWidth}>
                                <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                                    {`Market Capitalization`}
                                </Typography>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div className={classes.fullWidth}>
                                        <Field
                                            validateOnBlur
                                            validateOnChange
                                            id="marketCapitalizationFrom"
                                            name="marketCapitalizationFrom"
                                            render={({ field, form }: any) => (
                                                <TextField
                                                    label={"From"}
                                                    id="marketCapitalizationFrom"
                                                    name={"marketCapitalizationFrom"}
                                                    variant="filled"
                                                    type="number"
                                                    value={values.marketCapitalizationFrom}
                                                    data-testid='fundamentalsMarketCapFrom'
                                                    fullWidth
                                                    size="small"
                                                    margin="normal"
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        startAdornment: <InputAdornment position="start">
                                                            {`$`}
                                                        </InputAdornment>,
                                                        style: { fontSize: '12px' },
                                                    }}
                                                    error={
                                                        Boolean(form.errors.marketCapitalizationFrom && form.touched.marketCapitalizationFrom)
                                                    }
                                                    onChange={handleChange}
                                                    onBlur={(event: any) => {
                                                        handleBlur(event);
                                                        this.setState((preState) => ({
                                                            fundamentalsForm: {
                                                                ...preState.fundamentalsForm,
                                                                "marketCapitalizationFrom": event.target.value
                                                            }
                                                        }))
                                                    }}
                                                    helperText={
                                                        form.errors.marketCapitalizationFrom &&
                                                        form.touched.marketCapitalizationFrom &&
                                                        String(form.errors.marketCapitalizationFrom)
                                                    }
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className={classes.fullWidth} style={{ marginLeft: '15px' }}>
                                        <Field
                                            validateOnBlur
                                            validateOnChange
                                            id="marketCapitalizationTo"
                                            name="marketCapitalizationTo"
                                            render={({ field, form }: any) => (
                                                <TextField
                                                    label={"To"}
                                                    id="marketCapitalizationTo"
                                                    name={"marketCapitalizationTo"}
                                                    variant="filled"
                                                    type="number"
                                                    value={values.marketCapitalizationTo}
                                                    data-testid='fundamentalsMarketCapTo'
                                                    fullWidth
                                                    size="small"
                                                    margin="normal"
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        startAdornment: <InputAdornment position="start">
                                                            {`$`}
                                                        </InputAdornment>,
                                                        style: { fontSize: '12px' }
                                                    }}
                                                    error={
                                                        Boolean(form.errors.marketCapitalizationTo && form.touched.marketCapitalizationTo)
                                                    }
                                                    onChange={handleChange}
                                                    onBlur={(event: any) => {
                                                        handleBlur(event);
                                                        this.setState((preState) => ({
                                                            fundamentalsForm: {
                                                                ...preState.fundamentalsForm,
                                                                "marketCapitalizationTo": event.target.value
                                                            }
                                                        }))
                                                    }}
                                                    helperText={
                                                        form.errors.marketCapitalizationTo &&
                                                        form.touched.marketCapitalizationTo &&
                                                        String(form.errors.marketCapitalizationTo)
                                                    }
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                            { filterNamePopup && <SaveFilterName classes={classes} filterName={filterName} 
                            open={filterNamePopup} handleSubmit={handleSubmit} handleChange={(event: any) => 
                            this.setState({ filterName: event.target.value })} handleClickedBtn={this.handleClickedBtn}
                            handleCancel={() => this.setState({ filterNamePopup: false, filterName: "" })} /> }
                            <ButtonGroup fullWidth className={classes.buttonGroup}>
                                {this.state.token !== '' && <Button
                                    disabled={this.disableFundamentalSaveButton(values)} 
                                    data-testid='fundamentalSaveButton' type="submit" 
                                    className={classes.submitButton}
                                    onClick={() => this.handleClickedBtn('SaveRun')}>
                                Save & Run</Button>}
                                <Button
                                    disabled={this.disableFundamentalSaveButton(values)} 
                                    data-testid='fundamentalRunButton' type="submit" 
                                    className={classes.submitButton}
                                    onClick={() => this.handleClickedBtn('Run')}>
                                Run</Button>
                            </ButtonGroup>
                        </Form>
                    )}
                </Formik>
            </Popover>
        )
    }

    volumes = () => {

        const { activeItem, filterNamePopup, filterName } = this.state;
        const { classes } = this.props;

        return (
            <Popover
                open={Boolean(this.state.anchorEl) && activeItem == 'Volumes'}
                anchorEl={this.state.anchorEl}
                onClose={this.handleClose}
                data-testid="volumePopup"
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                disableAutoFocus={true}
                disableEnforceFocus={true}
                PaperProps={{
                    className: classes.popoverPaper,
                }}
            >
                <Formik
                    initialValues={{
                        volumeOption: "",
                        avgVolumnSlider: [0, 100],
                        volumeValue: '',
                        volumnPeriod: '',
                        volumnInputLength: ''
                    }}
                    enableReinitialize
                    validateOnChange
                    validateOnBlur
                    onSubmit={(values) => {
                        const callMethods = async () => {
                            if (this.state.clickedBtn && ['Run', 'Save'].includes(this.state.clickedBtn)) {
                                await Promise.all([this.resetDataBeforeAPICall(), this.volumeForm(this.state)])
                            }
                            this.setNavigation();
                        }
                        callMethods()
                    }}
                >
                    {({
                        values,
                        errors,
                        handleBlur,
                        handleSubmit,
                    }) => (
                        <Form onSubmit={handleSubmit}>
                            <div className={classes.fullWidth}>
                                <Field
                                    validateOnBlur
                                    validateOnChange
                                    name="volumeOption"
                                    render={({ field, form }: any) => (
                                        <TextField
                                            id="volumeOption"
                                            data-testid="volOption"
                                            select
                                            label={`Select Volume Option`}
                                            value={this.state.volumeOption}
                                            onChange={(event) => {
                                                this.resetVolumeData();
                                                this.setState({
                                                    volumeOption: event.target.value,
                                                })
                                            }}
                                            name={"volumeOption"}
                                            fullWidth
                                            variant="filled"
                                            size="small"
                                            margin="normal"
                                            // hiddenLabel
                                            InputProps={{
                                                disableUnderline: true,
                                                className: classes.input,
                                                endAdornment:  
                                                    <InputAdornment position="end" style={{ cursor: 'pointer', position: "absolute", paddingRight: 0, right: 25 }}>
                                                        <ClearIcon  onClick={() => { this.setState((preState) =>
                                                            ({...preState, volumeOption: "" }))}}/>
                                                    </InputAdornment>
                                            }}
                                            InputLabelProps={{ shrink: true, color: 'primary' }}
                                            error={
                                                Boolean(form.errors.volumeOption && form.touched.volumeOption)
                                            }
                                            helperText={
                                                form.errors.volumeOption &&
                                                form.touched.volumeOption &&
                                                String(form.errors.volumeOption)
                                            }
                                        // style={{ marginBottom: '15px' }}
                                        >
                                            {this.state.volumeOptionList.map((optionObj: any, index: number) => (
                                                 <MenuItem key={index} value={optionObj.id}>
                                                    <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '12px', whiteSpace: 'pre-line' }}>
                                                        {optionObj.name}
                                                </Typography>
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    )}
                                />
                            </div>
                            {
                                this.state.volumeOption == '1' && <div>
                                    <div className={classes.fullWidth} style={{ marginRight: '15px' }}>
                                        <PrettoSlider
                                            name={"avgVolumnSlider"}
                                            defaultValue={0}
                                            data-testid="volSlider"
                                            valueLabelDisplay="auto"
                                            value={this.state.avgVolumnSlider}
                                            onChange={(event, value) => this.setState({
                                                avgVolumnSlider: value
                                            })}
                                            aria-labelledby="range-slider"
                                            getAriaValueText={this.sliderValueText}
                                            // valueLabelFormat={this.sliderValueText}
                                            marks={[
                                                {
                                                    value: 0,
                                                    label: '$0',
                                                },
                                                {
                                                    value: 100,
                                                    label: '$50 Bn',
                                                }
                                            ]}
                                        />
                                    </div>
                                    {this.VolumePeriodField({ classes })}
                                    <div className={classes.fullWidth}>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend"></FormLabel>
                                            <RadioGroup name={"volumeValue"} value={this.state.volumeValue.toString()} onChange={(event) => {
                                                this.setState({
                                                    volumeValue: event.currentTarget.value
                                                })
                                            }}>
                                                <FormControlLabel value={"low_to_high"} control={<Radio />} label={<span style={{ fontSize: '12px' }}>{'Volume Low to High'}</span>} />
                                                <FormControlLabel value={"high_to_low"} control={<Radio />} label={<span style={{ fontSize: '12px' }}>{'Volume High to Low'}</span>} />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                </div>
                            }
                            {
                                (this.state.volumeOption == '2' || this.state.volumeOption == '3') && <div>
                                    {this.VolumePeriodField({ classes })}
                                    <div className={classes.fullWidth}>
                                        <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                                            {`Length`}
                                        </Typography>
                                        <Field
                                            validateOnBlur
                                            validateOnChange
                                            name="volumnInputLength"
                                            render={({ field, form }: any) => (
                                                <TextField
                                                    label={"Input Length"}
                                                    name={"volumnInputLength"}
                                                    data-testid="volInputLength"
                                                    variant="filled"
                                                    value={this.state.volumnInputLength}
                                                    fullWidth
                                                    size="small"
                                                    margin="normal"
                                                    type="number"
                                                    InputProps={{ disableUnderline: true, style: { fontSize: '12px' } }}
                                                    InputLabelProps={{ shrink: true }}
                                                    error={
                                                        Boolean(form.errors.volumnInputLength && form.touched.volumnInputLength)
                                                    }
                                                    onChange={(event) => {
                                                        this.setState({
                                                            volumnInputLength: event.target.value
                                                        })
                                                    }}
                                                    onBlur={handleBlur}
                                                    helperText={
                                                        form.errors.volumnInputLength &&
                                                        form.touched.volumnInputLength &&
                                                        String(form.errors.volumnInputLength)
                                                    }
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            }

                            { filterNamePopup && <SaveFilterName classes={classes} filterName={filterName} 
                            open={filterNamePopup} handleSubmit={handleSubmit} handleChange={(event: any) => 
                            this.setState({ filterName: event.target.value })}
                            handleClickedBtn={this.handleClickedBtn}
                            handleCancel={() => this.setState({ filterNamePopup: false, filterName: "" })} /> }
                            <ButtonGroup fullWidth className={classes.buttonGroup}>
                                { this.state.token !== "" && <Button
                                    disabled={this.disableVolumeSaveButton()} 
                                    data-testid='volumeSaveButton' type="submit" 
                                    className={classes.submitButton}
                                    onClick={() => this.handleClickedBtn('SaveRun')}>
                                Save & Run</Button> }

                                <Button
                                    disabled={this.disableVolumeSaveButton()} 
                                    data-testid='volumeRunButton' 
                                    type="submit" 
                                    className={classes.submitButton}
                                    onClick={() => this.handleClickedBtn('Run')}>
                                Run</Button>
                            </ButtonGroup>
                        </Form>
                    )}
                </Formik>
            </Popover>
        )
    }

    priceActions = () => {

        const { activeItem, filterNamePopup, filterName } = this.state;
        const { classes } = this.props;

        return (
            <Popover
                open={Boolean(this.state.anchorEl) && activeItem == 'Price Action'}
                anchorEl={this.state.anchorEl}
                data-testid="pricePopup"
                onClose={this.handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                disableAutoFocus={true}
                disableEnforceFocus={true}
                PaperProps={{
                    className: classes.popoverPaper,
                }}
            >
                <Formik
                    initialValues={{
                        priceOptionCategory: "",
                        priceOptionSubCategoryOne: "",
                        priceActionTimeFrame: "",
                        priceActionPercentage: '2',
                        priceActionLength: 3,
                        priceActionHighLowSubCategory: "",
                        priceActionBreakSubCategory: "",
                        priceActionSupportSubCategory: "",
                        priceActionNearBreakdownSubCategory: "",
                        priceActionSupportTimeFrameSubCategory: "",
                        priceActionStandardSubCategory: "",
                        priceActionStandardChannelSubCategory: "",
                        priceActionStandardTimeOptionSubCategory: "",
                        priceActionReversalSubCategory: "",
                        priceActionReversalBottomSubCategory: "",
                        priceActionReversalTopSubCategory: "",
                        priceActionContinuationSubCategory: "",
                        priceActionCandlesSticksSubCategory: "",
                        priceActionCandlesBullishSubCategory: "",
                        priceActionCandlesBearishSubCategory: "",
                        priceActionCandlesOthersPatternsSubCategory: ""
                    }}
                    enableReinitialize
                    onSubmit={(values, { resetForm, setSubmitting }) => {
                        const callMethods = async () => {
                            if (this.state.clickedBtn && ['Run', 'Save'].includes(this.state.clickedBtn)) {
                                await Promise.all([this.resetDataBeforeAPICall(), this.priceActionForm(this.state)])
                            }
                            this.setNavigation();
                        }
                        callMethods()
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                    }) => (
                        <Form onSubmit={handleSubmit}>
                            <div className={classes.fullWidth}>
                                <Field
                                    validateOnBlur
                                    validateOnChange
                                    name="priceOptionCategory"
                                    render={({ field, form }: any) => (
                                        <TextField
                                            id="priceOptionCategory"
                                            select
                                            label={`Select Price Action`}
                                            value={this.state.priceOptionCategory}
                                            onChange={(e) => {
                                                this.setState({
                                                    priceOptionCategory: e.target.value,
                                                    priceActionTimeFrame: ""
                                                })
                                            }}
                                            name={"priceOptionCategory"}
                                            fullWidth
                                            variant="filled"
                                            size="small"
                                            margin="normal"
                                            // hiddenLabel
                                            InputProps={{
                                                disableUnderline: true,
                                                className: classes.input,
                                                endAdornment:  
                                                    <InputAdornment position="end" style={{ cursor: 'pointer', position: "absolute", paddingRight: 0, right: 25 }}>
                                                        <ClearIcon  onClick={() => this.resetPriceActionData()}/>
                                                    </InputAdornment>
                                            }}
                                            InputLabelProps={{ shrink: true }}
                                            error={
                                                Boolean(form.errors.priceOptionCategory && form.touched.priceOptionCategory)
                                            }
                                            helperText={
                                                form.errors.priceOptionCategory &&
                                                form.touched.priceOptionCategory &&
                                                String(form.errors.priceOptionCategory)
                                            }
                                        // style={{ marginBottom: '15px' }}
                                        >
                                                
                                            {this.state.priceActionOptionList.map((paOptionObj: any, index: number) => (
                                                <MenuItem key={index} value={paOptionObj.id}>
                                                    <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '12px', whiteSpace: 'pre-line' }}>
                                                        {paOptionObj.name}
                                                    </Typography>
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    )}
                                />
                            </div>
                            {
                                this.state.priceOptionCategory == '1' && <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div className={classes.fullWidth}>
                                        <Field
                                            validateOnBlur
                                            validateOnChange
                                            name="priceOptionSubCategoryOne"
                                            render={({ field, form }: any) => (
                                                <TextField
                                                    id="priceOptionSubCategoryOne"
                                                    select
                                                    label={`Select Option`}
                                                    value={this.state.priceOptionSubCategoryOne}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            priceOptionSubCategoryOne: e.target.value,
                                                        })
                                                    }}
                                                    name={"priceOptionSubCategoryOne"}
                                                    fullWidth
                                                    variant="filled"
                                                    size="small"
                                                    margin="normal"
                                                    // hiddenLabel
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        className: classes.input,
                                                        endAdornment:  
                                                        <InputAdornment position="end" style={{ cursor: 'pointer', position: "absolute", paddingRight: 0, right: 25 }}>
                                                            <ClearIcon  onClick={() => { this.setState((preState) =>
                                                            ( { priceOptionSubCategoryOne: "" }))}}/>
                                                        </InputAdornment>
                                                    }}
                                                    InputLabelProps={{ shrink: true }}
                                                    error={
                                                        Boolean(form.errors.priceOptionSubCategoryOne && form.touched.priceOptionSubCategoryOne)
                                                    }
                                                    helperText={
                                                        form.errors.priceOptionSubCategoryOne &&
                                                        form.touched.priceOptionSubCategoryOne &&
                                                        String(form.errors.priceOptionSubCategoryOne)
                                                    }
                                                // style={{ marginBottom: '15px' }}
                                                >
                                                    {this.state.priceActionSubCategoryOneList.map((pascategoryObj: any, index: number) => (
                                                        <MenuItem key={index} className={pascategoryObj.disabled && classes.blurItem} value={pascategoryObj.value} disabled={pascategoryObj.disabled}>
                                                            <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '12px', whiteSpace: 'pre-line' }}>
                                                                {pascategoryObj.name}
                                                            </Typography>
                                                        </MenuItem>
                                                    ))}
                                                   {this.state.priceActionSubCategoryOneList.find((pascategoryObj: any) => pascategoryObj.disabled) && <UpgradeAlert />}
                                                </TextField>
                                            )}
                                        />
                                    </div>
                                    <div className={classes.fullWidth}>
                                        <Field
                                            validateOnBlur
                                            validateOnChange
                                            name="priceActionTimeFrame"
                                            render={({ field, form }: any) => this.TimeFrameField( { form })}
                                        />
                                    </div>
                                    {(this.state.priceOptionSubCategoryOne == 'Price Up %' || this.state.priceOptionSubCategoryOne == 'Price Down %') && <div className={classes.fullWidth}>
                                        <Field
                                            validateOnBlur
                                            validateOnChange
                                            name="priceActionPercentage"
                                            render={({ field, form }: any) => (
                                                <TextField
                                                    label={"Input Percentage"}
                                                    name={"priceActionPercentage"}
                                                    variant="filled"
                                                    value={`${this.state.priceActionPercentage}`}
                                                    fullWidth
                                                    size="small"
                                                    margin="normal"
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        className: classes.input,
                                                    }}
                                                    error={
                                                        Boolean(form.errors.priceActionPercentage && form.touched.priceActionPercentage)
                                                    }
                                                    onChange={(e) => {
                                                        this.setState({
                                                            priceActionPercentage: e.target.value
                                                        })
                                                    }}
                                                    onBlur={handleBlur}
                                                    helperText={
                                                        form.errors.priceActionPercentage &&
                                                        form.touched.priceActionPercentage &&
                                                        String(form.errors.priceActionPercentage)
                                                    }
                                                />
                                            )}
                                        />
                                    </div>}
                                    {(this.state.priceOptionSubCategoryOne == 'Price Trending Up' || this.state.priceOptionSubCategoryOne == 'Price Trending Down') && <div className={classes.fullWidth}>
                                        <Field
                                            validateOnBlur
                                            validateOnChange
                                            name="priceActionLength"
                                            render={({ field, form }: any) => (
                                                <TextField
                                                    label={"Length"}
                                                    name={"priceActionLength"}
                                                    variant="filled"
                                                    value={this.state.priceActionLength}
                                                    fullWidth
                                                    size="small"
                                                    margin="normal"
                                                    InputProps={{ disableUnderline: true, style: { fontSize: '12px' } }}
                                                    error={
                                                        Boolean(form.errors.priceActionLength && form.touched.priceActionLength)
                                                    }
                                                    onChange={(e) => {
                                                        this.setState({
                                                            priceActionLength: e.target.value
                                                        })
                                                    }}
                                                    onBlur={handleBlur}
                                                    helperText={
                                                        form.errors.priceActionLength &&
                                                        form.touched.priceActionLength &&
                                                        String(form.errors.priceActionLength)
                                                    }
                                                />
                                            )}
                                        />
                                    </div>}
                                </div>
                            }
                            {
                                this.state.priceOptionCategory == '2' && <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div className={classes.fullWidth}>
                                        <Field
                                            validateOnBlur
                                            validateOnChange
                                            name="priceActionHighLowSubCategory"
                                            render={({ field, form }: any) => (
                                                <TextField
                                                    id="priceActionHighLowSubCategory"
                                                    select
                                                    label={`Select Option`}
                                                    value={this.state.priceActionHighLowSubCategory}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            priceActionHighLowSubCategory: e.target.value
                                                        })
                                                    }}
                                                    name={"priceActionHighLowSubCategory"}
                                                    fullWidth
                                                    variant="filled"
                                                    size="small"
                                                    margin="normal"
                                                    // hiddenLabel
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        className: classes.input,
                                                        endAdornment:  
                                                        <InputAdornment position="end" style={{ cursor: 'pointer', position: "absolute", paddingRight: 0, right: 25 }}>
                                                            <ClearIcon  onClick={() => { this.setState(
                                                            { priceActionHighLowSubCategory: "" })}}/>
                                                        </InputAdornment>
                                                    }}
                                                    InputLabelProps={{ shrink: true }}
                                                    error={
                                                        Boolean(form.errors.priceActionHighLowSubCategory && form.touched.priceActionHighLowSubCategory)
                                                    }
                                                    helperText={
                                                        form.errors.priceActionHighLowSubCategory &&
                                                        form.touched.priceActionHighLowSubCategory &&
                                                        String(form.errors.priceActionHighLowSubCategory)
                                                    }
                                                // style={{ marginBottom: '15px' }}
                                                >
                                                    {this.state.priceActionHighLowSubCategoryList.map((highLowObj: any, index: number) => (
                                                         <MenuItem key={index} className={highLowObj.disabled && classes.blurItem} value={highLowObj.value} disabled={highLowObj.disabled}>
                                                            <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '12px', whiteSpace: 'pre-line' }}>
                                                                {highLowObj.name}
                                                            </Typography>
                                                        </MenuItem>
                                                    ))}
                                                    {this.state.priceActionHighLowSubCategoryList.find((highLowObj: any) => highLowObj.disabled) && <UpgradeAlert />}
                                                </TextField>
                                            )}
                                        />
                                    </div>
                                </div>
                            }
                            {
                                this.state.priceOptionCategory == '3' && <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div className={classes.fullWidth}>
                                        <Field
                                            validateOnBlur
                                            validateOnChange
                                            name="priceActionBreakSubCategory"
                                            render={({ field, form }: any) => (
                                                <TextField
                                                    id="priceActionBreakSubCategory"
                                                    select
                                                    label={`Select Option`}
                                                    value={this.state.priceActionBreakSubCategory}
                                                    onChange={(e) => {
                                                        handleChange("priceActionBreakSubCategory")
                                                        this.setState({
                                                            priceActionBreakSubCategory: e.target.value
                                                        })
                                                    }}
                                                    name={"priceActionBreakSubCategory"}
                                                    fullWidth
                                                    variant="filled"
                                                    size="small"
                                                    margin="normal"
                                                    // hiddenLabel
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        className: classes.input,
                                                        endAdornment:  
                                                            <InputAdornment position="end" style={{ cursor: 'pointer', position: "absolute", paddingRight: 0, right: 25 }}>
                                                                <ClearIcon  onClick={() => { this.setState(
                                                                { priceActionBreakSubCategory: "" })}}/>
                                                            </InputAdornment>
                                                    }}
                                                    InputLabelProps={{ shrink: true }}
                                                    error={
                                                        Boolean(form.errors.priceActionBreakSubCategory && form.touched.priceActionBreakSubCategory)
                                                    }
                                                    helperText={
                                                        form.errors.priceActionBreakSubCategory &&
                                                        form.touched.priceActionBreakSubCategory &&
                                                        String(form.errors.priceActionBreakSubCategory)
                                                    }
                                                // style={{ marginBottom: '15px' }}
                                                >
                                                    {this.state.priceActionBreakSubCategoryList.map((paBreakObj: any, index: number) => (
                                                        <MenuItem key={index} className={paBreakObj.disabled && classes.blurItem} value={paBreakObj.value} disabled={paBreakObj.disabled}>
                                                            <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '12px', whiteSpace: 'pre-line' }}>
                                                                {paBreakObj.name}
                                                            </Typography>
                                                        </MenuItem>
                                                    ))}
                                                    {this.state.priceActionBreakSubCategoryList.find((paBreakObj: any) => paBreakObj.disabled) && <UpgradeAlert />}
                                                </TextField>
                                            )}
                                        />
                                    </div>
                                </div>
                            }
                            {
                                this.state.priceOptionCategory == '4' && <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div className={classes.fullWidth}>
                                        <Field
                                            validateOnBlur
                                            validateOnChange
                                            name="priceActionSupportSubCategory"
                                            render={({ field, form }: any) => (
                                                <TextField
                                                    id="priceActionSupportSubCategory"
                                                    select
                                                    label={`Select Option`}
                                                    value={this.state.priceActionSupportSubCategory}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            priceActionSupportSubCategory: e.target.value,
                                                            priceActionNearBreakdownSubCategory: '',
                                                            priceActionTimeFrame: ''
                                                        })
                                                    }}
                                                    name={"priceActionSupportSubCategory"}
                                                    fullWidth
                                                    variant="filled"
                                                    size="small"
                                                    margin="normal"
                                                    // hiddenLabel
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        className: classes.input,
                                                        endAdornment:  
                                                            <InputAdornment position="end" style={{ cursor: 'pointer', position: "absolute", paddingRight: 0, right: 25 }}>
                                                                <ClearIcon  onClick={() => { this.setState(
                                                                { priceActionSupportSubCategory: "" })}}/>
                                                            </InputAdornment>
                                                    }}
                                                    InputLabelProps={{ shrink: true }}
                                                    error={
                                                        Boolean(form.errors.priceActionSupportSubCategory && form.touched.priceActionSupportSubCategory)
                                                    }
                                                    helperText={
                                                        form.errors.priceActionSupportSubCategory &&
                                                        form.touched.priceActionSupportSubCategory &&
                                                        String(form.errors.priceActionSupportSubCategory)
                                                    }
                                                // style={{ marginBottom: '15px' }}
                                                >
                                                    {this.state.priceActionSupportSubCategoryList.map((supportSubCategory: any, index: number) => (
                                                        <MenuItem key={index} className={supportSubCategory.disabled && classes.blurItem} value={supportSubCategory.value} disabled={supportSubCategory.disabled}>
                                                            <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '12px', whiteSpace: 'pre-line' }}>
                                                                {supportSubCategory.name}
                                                            </Typography>
                                                        </MenuItem>
                                                    ))}
                                                    {this.state.priceActionSupportSubCategoryList.find((supportSubCategory: any) => supportSubCategory.disabled) && <UpgradeAlert />}
                                                </TextField>
                                            )}
                                        />
                                    </div>
                                    <div className={classes.fullWidth}>
                                        <Field
                                            validateOnBlur
                                            validateOnChange
                                            name="priceActionNearBreakdownSubCategory"
                                            render={({ field, form }: any) => (
                                                <TextField
                                                    id="priceActionNearBreakdownSubCategory"
                                                    select
                                                    label={`Select Option`}
                                                    value={this.state.priceActionNearBreakdownSubCategory}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            priceActionNearBreakdownSubCategory: e.target.value
                                                        })
                                                    }}
                                                    name={"priceActionNearBreakdownSubCategory"}
                                                    fullWidth
                                                    variant="filled"
                                                    size="small"
                                                    margin="normal"
                                                    // hiddenLabel
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        className: classes.input,
                                                        endAdornment:  
                                                            <InputAdornment position="end" style={{ cursor: 'pointer', position: "absolute", paddingRight: 0, right: 25 }}>
                                                                <ClearIcon  onClick={() => { this.setState(
                                                                { priceActionNearBreakdownSubCategory: "" })}}/>
                                                            </InputAdornment>
                                                    }}
                                                    InputLabelProps={{ shrink: true }}
                                                    error={
                                                        Boolean(form.errors.priceActionNearBreakdownSubCategory && form.touched.priceActionNearBreakdownSubCategory)
                                                    }
                                                    helperText={
                                                        form.errors.priceActionNearBreakdownSubCategory &&
                                                        form.touched.priceActionNearBreakdownSubCategory &&
                                                        String(form.errors.priceActionNearBreakdownSubCategory)
                                                    }
                                                // style={{ marginBottom: '15px' }}
                                                >
                                                    {(this.state.priceActionSupportSubCategory === 'Standard Support' || this.state.priceActionSupportSubCategory === 'Fibonacci Support') && this.state.priceActionNearBreakdownSupportSubCategoryList.map((timeObj: any, index: number) => (
                                                        <MenuItem key={index} value={timeObj.value}>
                                                            <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '12px', whiteSpace: 'pre-line' }}>
                                                                {timeObj.name}
                                                            </Typography>
                                                        </MenuItem>
                                                    ))}
                                                    {(this.state.priceActionSupportSubCategory === 'Standard Resistance' || this.state.priceActionSupportSubCategory === 'Fibonacci Resistance') && this.state.priceActionNearBreakdownResistanceSubCategoryList.map((timeObj: any, index: number) => (
                                                        <MenuItem key={index} value={timeObj.value}>
                                                            <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '12px', whiteSpace: 'pre-line' }}>
                                                                {timeObj.name}
                                                            </Typography>
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            )}
                                        />
                                    </div>
                                    <div className={classes.fullWidth}>
                                        <Field
                                            validateOnBlur
                                            validateOnChange
                                            name="priceActionTimeFrame"
                                            render={({ form }: any) =>  this.TimeFrameField( { form }) }
                                        />
                                    </div>
                                </div>
                            }
                            {
                                this.state.priceOptionCategory == '5' && <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div className={classes.fullWidth}>
                                        <Field
                                            validateOnBlur
                                            validateOnChange
                                            name="priceActionStandardSubCategory"
                                            render={({ field, form }: any) => (
                                                <TextField
                                                    id="priceActionStandardSubCategory"
                                                    select
                                                    label={`Select Option`}
                                                    value={this.state.priceActionStandardSubCategory}
                                                    onChange={(e) => this.setState({
                                                        priceActionStandardSubCategory: e.target.value
                                                    })}
                                                    name={"priceActionStandardSubCategory"}
                                                    fullWidth
                                                    variant="filled"
                                                    size="small"
                                                    margin="normal"
                                                    // hiddenLabel
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        className: classes.input,
                                                        endAdornment:  
                                                            <InputAdornment position="end" style={{ cursor: 'pointer', position: "absolute", paddingRight: 0, right: 25 }}>
                                                                <ClearIcon  onClick={() => { this.setState(
                                                                { priceActionStandardSubCategory: "" })}}/>
                                                            </InputAdornment>
                                                    }}
                                                    InputLabelProps={{ shrink: true }}
                                                    error={
                                                        Boolean(form.errors.priceActionStandardSubCategory && form.touched.priceActionStandardSubCategory)
                                                    }
                                                    helperText={
                                                        form.errors.priceActionStandardSubCategory &&
                                                        form.touched.priceActionStandardSubCategory &&
                                                        String(form.errors.priceActionStandardSubCategory)
                                                    }
                                                // style={{ marginBottom: '15px' }}
                                                >
                                                    {this.state.priceActionStandardSubCategoryList.map((subCategoryObj: any, index: number) => (
                                                        <MenuItem key={index} className={subCategoryObj.disabled && classes.blurItem} value={subCategoryObj.value} disabled={subCategoryObj.disabled}>
                                                            <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '12px', whiteSpace: 'pre-line' }}>
                                                                {subCategoryObj.name}
                                                            </Typography>
                                                        </MenuItem>
                                                    ))}
                                                    {this.state.priceActionStandardSubCategoryList.find((subCategoryObj: any) => subCategoryObj.disabled ) && <UpgradeAlert />}
                                                </TextField>
                                            )}
                                        />
                                    </div>
                                    <div className={classes.fullWidth}>
                                        <Field
                                            validateOnBlur
                                            validateOnChange
                                            name="priceActionStandardChannelSubCategory"
                                            render={({ field, form }: any) => (
                                                <TextField
                                                    id="priceActionStandardChannelSubCategory"
                                                    select
                                                    label={`Select Option`}
                                                    value={this.state.priceActionStandardChannelSubCategory}
                                                    onChange={(e) => this.setState({
                                                        priceActionStandardChannelSubCategory: e.target.value
                                                    })}
                                                    name={"priceActionStandardChannelSubCategory"}
                                                    fullWidth
                                                    variant="filled"
                                                    size="small"
                                                    margin="normal"
                                                    // hiddenLabel
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        className: classes.input,
                                                        endAdornment:  
                                                            <InputAdornment position="end" style={{ cursor: 'pointer', position: "absolute", paddingRight: 0, right: 25 }}>
                                                                <ClearIcon  onClick={() => { this.setState(
                                                                { priceActionStandardChannelSubCategory: "" })}}/>
                                                            </InputAdornment>
                                                    }}
                                                    InputLabelProps={{ shrink: true }}
                                                    error={
                                                        Boolean(form.errors.priceActionStandardChannelSubCategory && form.touched.priceActionStandardChannelSubCategory)
                                                    }
                                                    helperText={
                                                        form.errors.priceActionStandardChannelSubCategory &&
                                                        form.touched.priceActionStandardChannelSubCategory &&
                                                        String(form.errors.priceActionStandardChannelSubCategory)
                                                    }
                                                // style={{ marginBottom: '15px' }}
                                                >
                                                    {this.state.priceActionStandardChannelSubCategoryList.map((channelObj: any, index: number) => (
                                                        <MenuItem key={index} value={channelObj.value}>
                                                            <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '12px', whiteSpace: 'pre-line' }}>
                                                                {channelObj.name}
                                                            </Typography>
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            )}
                                        />
                                    </div>
                                    <div className={classes.fullWidth}>
                                        <Field
                                            validateOnBlur
                                            validateOnChange
                                            name="priceActionStandardTimeOptionSubCategory"
                                            render={({ field, form }: any) => (
                                                <TextField
                                                    id="priceActionStandardTimeOptionSubCategory"
                                                    select
                                                    label={`Select Time Frame`}
                                                    value={this.state.priceActionStandardTimeOptionSubCategory}
                                                    onChange={(e) => this.setState({
                                                        priceActionStandardTimeOptionSubCategory: e.target.value
                                                    })}
                                                    name={"priceActionStandardTimeOptionSubCategory"}
                                                    fullWidth
                                                    variant="filled"
                                                    size="small"
                                                    margin="normal"
                                                    // hiddenLabel
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        className: classes.input,
                                                        endAdornment:  
                                                            <InputAdornment position="end" style={{ cursor: 'pointer', position: "absolute", paddingRight: 0, right: 25 }}>
                                                                <ClearIcon  onClick={() => { this.setState(
                                                                { priceActionStandardTimeOptionSubCategory: "" })}}/>
                                                            </InputAdornment>
                                                    }}
                                                    InputLabelProps={{ shrink: true }}
                                                    error={
                                                        Boolean(form.errors.priceActionStandardTimeOptionSubCategory && form.touched.priceActionStandardTimeOptionSubCategory)
                                                    }
                                                    helperText={
                                                        form.errors.priceActionStandardTimeOptionSubCategory &&
                                                        form.touched.priceActionStandardTimeOptionSubCategory &&
                                                        String(form.errors.priceActionStandardTimeOptionSubCategory)
                                                    }
                                                // style={{ marginBottom: '15px' }}
                                                >
                                                    {this.state.priceActionStandardTimeOptionSubCategoryList.map((timeObj: any, index: number) => (
                                                        this.TimeFrameOptions(timeObj, index)
                                                    ))}
                                                    {this.state.priceActionStandardTimeOptionSubCategoryList.find((timeObj: any) => timeObj.disabled) && <UpgradeAlert />}
                                                </TextField>
                                            )}
                                        />
                                    </div>
                                </div>
                            }
                            {
                                this.state.priceOptionCategory == '6' && <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div className={classes.fullWidth}>
                                        <Field
                                            validateOnBlur
                                            validateOnChange
                                            name="priceActionReversalSubCategory"
                                            render={({ field, form }: any) => (
                                                <TextField
                                                    id="priceActionReversalSubCategory"
                                                    select
                                                    label={`Select Option`}
                                                    value={this.state.priceActionReversalSubCategory}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            priceActionReversalSubCategory: e.target.value
                                                        })
                                                    }}
                                                    name={"priceActionReversalSubCategory"}
                                                    fullWidth
                                                    variant="filled"
                                                    size="small"
                                                    margin="normal"
                                                    // hiddenLabel
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        className: classes.input,
                                                        endAdornment:  
                                                            <InputAdornment position="end" style={{ cursor: 'pointer', position: "absolute", paddingRight: 0, right: 25 }}>
                                                                <ClearIcon  onClick={() => { this.setState(
                                                                { priceActionReversalSubCategory: "" })}}/>
                                                            </InputAdornment>
                                                    }}
                                                    InputLabelProps={{ shrink: true }}
                                                    error={
                                                        Boolean(form.errors.priceActionReversalSubCategory && form.touched.priceActionReversalSubCategory)
                                                    }
                                                    helperText={
                                                        form.errors.priceActionReversalSubCategory &&
                                                        form.touched.priceActionReversalSubCategory &&
                                                        String(form.errors.priceActionReversalSubCategory)
                                                    }
                                                // style={{ marginBottom: '15px' }}
                                                >
                                                    {this.state.priceActionReversalSubCategoryList.map((reversalObj: any, index: number) => (
                                                        <MenuItem key={index} value={reversalObj.value}>
                                                            <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '12px', whiteSpace: 'pre-line' }}>
                                                                {reversalObj.name}
                                                            </Typography>
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            )}
                                        />
                                    </div>
                                    {(this.state.priceActionReversalSubCategory == 'bullish reversals') && <div className={classes.fullWidth}>
                                        <Field
                                            validateOnBlur
                                            validateOnChange
                                            name="priceActionReversalBottomSubCategory"
                                            render={({ field, form }: any) => (
                                                <TextField
                                                    id="priceActionReversalBottomSubCategory"
                                                    select
                                                    label={`Select Option`}
                                                    value={this.state.priceActionReversalBottomSubCategory}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            priceActionReversalBottomSubCategory: e.target.value
                                                        })
                                                    }}
                                                    name={"priceActionReversalBottomSubCategory"}
                                                    fullWidth
                                                    variant="filled"
                                                    size="small"
                                                    margin="normal"
                                                    // hiddenLabel
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        className: classes.input,
                                                        endAdornment:  
                                                            <InputAdornment position="end" style={{ cursor: 'pointer', position: "absolute", paddingRight: 0, right: 25 }}>
                                                                <ClearIcon  onClick={() => { this.setState(
                                                                { priceActionReversalBottomSubCategory: "" })}}/>
                                                            </InputAdornment>
                                                    }}
                                                    InputLabelProps={{ shrink: true }}
                                                    error={
                                                        Boolean(form.errors.priceActionReversalBottomSubCategory && form.touched.priceActionReversalBottomSubCategory)
                                                    }
                                                    helperText={
                                                        form.errors.priceActionReversalBottomSubCategory &&
                                                        form.touched.priceActionReversalBottomSubCategory &&
                                                        String(form.errors.priceActionReversalBottomSubCategory)
                                                    }
                                                // style={{ marginBottom: '15px' }}
                                                >
                                                    {this.state.priceActionReversalBottomSubCategoryList.map((bottomObj: any, index: number) => (
                                                        <MenuItem key={index} className={bottomObj.disabled && classes.blurItem} value={bottomObj.value} disabled={bottomObj.disabled}>
                                                            <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '12px', whiteSpace: 'pre-line' }}>
                                                                {bottomObj.name}
                                                            </Typography>
                                                        </MenuItem>
                                                    ))}
                                                    {this.state.priceActionReversalBottomSubCategoryList.find((bottomObj: any) => bottomObj.disabled) && <UpgradeAlert />}
                                                </TextField>
                                            )}
                                        />
                                    </div>}
                                    {(this.state.priceActionReversalSubCategory == 'bearish reversals') && <div className={classes.fullWidth}>
                                        <Field
                                            validateOnBlur
                                            validateOnChange
                                            name="priceActionReversalTopSubCategory"
                                            render={({ field, form }: any) => (
                                                <TextField
                                                    id="priceActionReversalTopSubCategory"
                                                    select
                                                    label={`Select Option`}
                                                    value={this.state.priceActionReversalTopSubCategory}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            priceActionReversalTopSubCategory: e.target.value
                                                        })
                                                    }}
                                                    name={"priceActionReversalTopSubCategory"}
                                                    fullWidth
                                                    variant="filled"
                                                    size="small"
                                                    margin="normal"
                                                    // hiddenLabel
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        className: classes.input,
                                                        endAdornment:  
                                                            <InputAdornment position="end" style={{ cursor: 'pointer', position: "absolute", paddingRight: 0, right: 25 }}>
                                                                <ClearIcon  onClick={() => { this.setState(
                                                                { priceActionReversalTopSubCategory: "" })}}/>
                                                            </InputAdornment>
                                                    }}
                                                    InputLabelProps={{ shrink: true }}
                                                    error={
                                                        Boolean(form.errors.priceActionReversalTopSubCategory && form.touched.priceActionReversalTopSubCategory)
                                                    }
                                                    helperText={
                                                        form.errors.priceActionReversalTopSubCategory &&
                                                        form.touched.priceActionReversalTopSubCategory &&
                                                        String(form.errors.priceActionReversalTopSubCategory)
                                                    }
                                                // style={{ marginBottom: '15px' }}
                                                >
                                                    {this.state.priceActionReversalTopSubCategoryList.map((categoryObj: any, index: number) => (
                                                        <MenuItem key={index} className={categoryObj.disabled && classes.blurItem} value={categoryObj.value} disabled={categoryObj.disabled}>
                                                            <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '12px', whiteSpace: 'pre-line' }}>
                                                                {categoryObj.name}
                                                            </Typography>
                                                        </MenuItem>
                                                    ))}
                                                    {this.state.priceActionReversalTopSubCategoryList.find((categoryObj: any) => categoryObj.disabled) && <UpgradeAlert />}
                                                </TextField>
                                            )}
                                        />
                                    </div>}
                                    {(this.state.priceActionReversalSubCategory == 'continuation patterns') && <div className={classes.fullWidth}>
                                        <Field
                                            validateOnBlur
                                            validateOnChange
                                            name="priceActionContinuationSubCategory"
                                            render={({ field, form }: any) => (
                                                <TextField
                                                    id="priceActionContinuationSubCategory"
                                                    select
                                                    label={`Select Option`}
                                                    value={this.state.priceActionContinuationSubCategory}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            priceActionContinuationSubCategory: e.target.value
                                                        })
                                                    }}
                                                    name={"priceActionContinuationSubCategory"}
                                                    fullWidth
                                                    variant="filled"
                                                    size="small"
                                                    margin="normal"
                                                    // hiddenLabel
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        className: classes.input,
                                                        endAdornment:  
                                                            <InputAdornment position="end" style={{ cursor: 'pointer', position: "absolute", paddingRight: 0, right: 25 }}>
                                                                <ClearIcon  onClick={() => { this.setState(
                                                                { priceActionContinuationSubCategory: "" })}}/>
                                                            </InputAdornment>
                                                    }}
                                                    InputLabelProps={{ shrink: true }}
                                                    error={
                                                        Boolean(form.errors.priceActionContinuationSubCategory && form.touched.priceActionContinuationSubCategory)
                                                    }
                                                    helperText={
                                                        form.errors.priceActionContinuationSubCategory &&
                                                        form.touched.priceActionContinuationSubCategory &&
                                                        String(form.errors.priceActionContinuationSubCategory)
                                                    }
                                                // style={{ marginBottom: '15px' }}
                                                >
                                                    {this.state.priceActionContinuationSubCategoryList.map((categoryObj: any, index: number) => (
                                                        <MenuItem key={index} value={categoryObj.value} className={categoryObj.disabled && classes.blurItem} disabled={categoryObj.disabled}>
                                                            <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '12px', whiteSpace: 'pre-line' }}>
                                                                {categoryObj.name}
                                                            </Typography>
                                                        </MenuItem>
                                                    ))}
                                                    {this.state.priceActionContinuationSubCategoryList.find((categoryObj: any) => categoryObj.disabled) && <UpgradeAlert />}
                                                </TextField>
                                            )}
                                        />
                                    </div>}
                                    <div className={classes.fullWidth}>
                                        <Field
                                            validateOnBlur
                                            validateOnChange
                                            name="priceActionReversalTimeOptionSubCategory"
                                            render={({ field, form }: any) => (
                                                <TextField
                                                    id="priceActionReversalTimeOptionSubCategory"
                                                    select
                                                    label={`Select Time Frame`}
                                                    value={this.state.priceActionReversalTimeOptionSubCategory}
                                                    onChange={(e) => this.setState({
                                                        priceActionReversalTimeOptionSubCategory: e.target.value
                                                    })}
                                                    name={"priceActionReversalTimeOptionSubCategory"}
                                                    fullWidth
                                                    variant="filled"
                                                    size="small"
                                                    margin="normal"
                                                    // hiddenLabel
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        className: classes.input,
                                                        endAdornment:  
                                                            <InputAdornment position="end" style={{ cursor: 'pointer', position: "absolute", paddingRight: 0, right: 25 }}>
                                                                <ClearIcon  onClick={() => { this.setState(
                                                                { priceActionReversalTimeOptionSubCategory: "" })}}/>
                                                            </InputAdornment>
                                                    }}
                                                    InputLabelProps={{ shrink: true }}
                                                    error={
                                                        Boolean(form.errors.priceActionReversalTimeOptionSubCategory && form.touched.priceActionReversalTimeOptionSubCategory)
                                                    }
                                                    helperText={
                                                        form.errors.priceActionReversalTimeOptionSubCategory &&
                                                        form.touched.priceActionReversalTimeOptionSubCategory &&
                                                        String(form.errors.priceActionReversalTimeOptionSubCategory)
                                                    }
                                                // style={{ marginBottom: '15px' }}
                                                >
                                                    {this.state.priceActionContinuationPatternTimeFrameList.map((timeObj: any, index: number) => (
                                                        this.TimeFrameOptions(timeObj, index)
                                                    ))}
                                                    {this.state.priceActionContinuationPatternTimeFrameList.find((timeObj: any) => timeObj.disabled) && <UpgradeAlert />}
                                                </TextField>
                                            )}
                                        />
                                    </div>
                                </div>
                            }
                            {
                                this.state.priceOptionCategory == '7' && <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div className={classes.fullWidth}>
                                        <Field
                                            validateOnBlur
                                            validateOnChange
                                            name="priceActionCandlesSticksSubCategory"
                                            render={({ field, form }: any) => (
                                                <TextField
                                                    id="priceActionCandlesSticksSubCategory"
                                                    select
                                                    label={`Select Option`}
                                                    value={this.state.priceActionCandlesSticksSubCategory}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            priceActionCandlesSticksSubCategory: e.target.value
                                                        })
                                                    }}
                                                    name={"priceActionCandlesSticksSubCategory"}
                                                    fullWidth
                                                    variant="filled"
                                                    size="small"
                                                    margin="normal"
                                                    // hiddenLabel
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        className: classes.input,
                                                        endAdornment:  
                                                            <InputAdornment position="end" style={{ cursor: 'pointer', position: "absolute", paddingRight: 0, right: 25 }}>
                                                                <ClearIcon  onClick={() => { this.setState(
                                                                { priceActionCandlesSticksSubCategory: "" })}}/>
                                                            </InputAdornment>
                                                    }}
                                                    InputLabelProps={{ shrink: true }}
                                                    error={
                                                        Boolean(form.errors.priceActionCandlesSticksSubCategory && form.touched.priceActionCandlesSticksSubCategory)
                                                    }
                                                    helperText={
                                                        form.errors.priceActionCandlesSticksSubCategory &&
                                                        form.touched.priceActionCandlesSticksSubCategory &&
                                                        String(form.errors.priceActionCandlesSticksSubCategory)
                                                    }
                                                // style={{ marginBottom: '15px' }}
                                                >
                                                    {this.state.priceActionCandlesSticksSubCategoryList.map((categoryObj: any, index: number) => (
                                                        <MenuItem key={index} value={categoryObj.value}>
                                                            <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '12px', whiteSpace: 'pre-line' }}>
                                                                {categoryObj.name}
                                                            </Typography>
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            )}
                                        />
                                    </div>
                                    {(this.state.priceActionCandlesSticksSubCategory == 'Bullish Candle Scans') && <div className={classes.fullWidth}>
                                        <Field
                                            validateOnBlur
                                            validateOnChange
                                            name="priceActionCandlesBullishSubCategory"
                                            render={({ field, form }: any) => (
                                                <TextField
                                                    id="priceActionCandlesBullishSubCategory"
                                                    select
                                                    label={`Select Option`}
                                                    value={this.state.priceActionCandlesBullishSubCategory}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            priceActionCandlesBullishSubCategory: e.target.value
                                                        })
                                                    }}
                                                    name={"priceActionCandlesBullishSubCategory"}
                                                    fullWidth
                                                    variant="filled"
                                                    size="small"
                                                    margin="normal"
                                                    // hiddenLabel
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        className: classes.input,
                                                        endAdornment:  
                                                            <InputAdornment position="end" style={{ cursor: 'pointer', position: "absolute", paddingRight: 0, right: 25 }}>
                                                                <ClearIcon  onClick={() => { this.setState(
                                                                { priceActionCandlesBullishSubCategory: "" })}}/>
                                                            </InputAdornment>
                                                    }}
                                                    InputLabelProps={{ shrink: true }}
                                                    error={
                                                        Boolean(form.errors.priceActionCandlesBullishSubCategory && form.touched.priceActionCandlesBullishSubCategory)
                                                    }
                                                    helperText={
                                                        form.errors.priceActionCandlesBullishSubCategory &&
                                                        form.touched.priceActionCandlesBullishSubCategory &&
                                                        String(form.errors.priceActionCandlesBullishSubCategory)
                                                    }
                                                // style={{ marginBottom: '15px' }}
                                                >
                                                    {this.state.priceActionCandlesBullishSubCategoryList.map((categoryObj: any, index: number) => (
                                                        <MenuItem key={index} className={categoryObj.disabled && classes.blurItem} value={categoryObj.value} disabled={categoryObj.disabled}>
                                                            <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '12px', whiteSpace: 'pre-line' }}>
                                                                {categoryObj.name}
                                                            </Typography>
                                                        </MenuItem>
                                                    ))}
                                                    {this.state.priceActionCandlesBullishSubCategoryList.find((categoryObj: any) => categoryObj.disabled) && <UpgradeAlert />}
                                                </TextField>
                                            )}
                                        />
                                    </div>}
                                    {(this.state.priceActionCandlesSticksSubCategory == 'Bearish Candle Scans') && <div className={classes.fullWidth}>
                                        <Field
                                            validateOnBlur
                                            validateOnChange
                                            name="priceActionCandlesBearishSubCategory"
                                            render={({ field, form }: any) => (
                                                <TextField
                                                    id="priceActionCandlesBearishSubCategory"
                                                    select
                                                    label={`Select Option`}
                                                    value={this.state.priceActionCandlesBearishSubCategory}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            priceActionCandlesBearishSubCategory: e.target.value
                                                        })
                                                    }}
                                                    name={"priceActionCandlesBearishSubCategory"}
                                                    fullWidth
                                                    variant="filled"
                                                    size="small"
                                                    margin="normal"
                                                    // hiddenLabel
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        className: classes.input,
                                                        endAdornment:  
                                                            <InputAdornment position="end" style={{ cursor: 'pointer', position: "absolute", paddingRight: 0, right: 25 }}>
                                                                <ClearIcon  onClick={() => { this.setState(
                                                                { priceActionCandlesBearishSubCategory: "" })}}/>
                                                            </InputAdornment>
                                                    }}
                                                    InputLabelProps={{ shrink: true }}
                                                    error={
                                                        Boolean(form.errors.priceActionCandlesBearishSubCategory && form.touched.priceActionCandlesBearishSubCategory)
                                                    }
                                                    helperText={
                                                        form.errors.priceActionCandlesBearishSubCategory &&
                                                        form.touched.priceActionCandlesBearishSubCategory &&
                                                        String(form.errors.priceActionCandlesBearishSubCategory)
                                                    }
                                                // style={{ marginBottom: '15px' }}
                                                >
                                                    {this.state.priceActionCandlesBearishSubCategoryList.map((categoryObj: any, index: number) => (
                                                        <MenuItem key={index} className={categoryObj.disabled && classes.blurItem} value={categoryObj.value} disabled={categoryObj.disabled}>
                                                            <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '12px', whiteSpace: 'pre-line' }}>
                                                                {categoryObj.name}
                                                            </Typography>
                                                        </MenuItem>
                                                    ))}
                                                    {this.state.priceActionCandlesBearishSubCategoryList.find((categoryObj: any) => categoryObj.disabled) && <UpgradeAlert />}
                                                </TextField>
                                            )}
                                        />
                                    </div>}
                                    {(this.state.priceActionCandlesSticksSubCategory == 'Other Patterns') && <div className={classes.fullWidth}>
                                        <Field
                                            validateOnBlur
                                            validateOnChange
                                            name="priceActionCandlesOthersPatternsSubCategory"
                                            render={({ field, form }: any) => (
                                                <TextField
                                                    id="priceActionCandlesOthersPatternsSubCategory"
                                                    select
                                                    label={`Select Option`}
                                                    value={this.state.priceActionCandlesOthersPatternsSubCategory}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            priceActionCandlesOthersPatternsSubCategory: e.target.value
                                                        })
                                                    }}
                                                    name={"priceActionCandlesOthersPatternsSubCategory"}
                                                    fullWidth
                                                    variant="filled"
                                                    size="small"
                                                    margin="normal"
                                                    // hiddenLabel
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        className: classes.input,
                                                        endAdornment:  
                                                            <InputAdornment position="end" style={{ cursor: 'pointer', position: "absolute", paddingRight: 0, right: 25 }}>
                                                                <ClearIcon  onClick={() => { this.setState(
                                                                { priceActionCandlesOthersPatternsSubCategory: "" })}}/>
                                                            </InputAdornment>
                                                    }}
                                                    InputLabelProps={{ shrink: true }}
                                                    error={
                                                        Boolean(form.errors.priceActionCandlesOthersPatternsSubCategory && form.touched.priceActionCandlesOthersPatternsSubCategory)
                                                    }
                                                    helperText={
                                                        form.errors.priceActionCandlesOthersPatternsSubCategory &&
                                                        form.touched.priceActionCandlesOthersPatternsSubCategory &&
                                                        String(form.errors.priceActionCandlesOthersPatternsSubCategory)
                                                    }
                                                // style={{ marginBottom: '15px' }}
                                                >
                                                    {this.state.priceActionCandlesOthersPatternsSubCategoryList.map((categoryObj: any, index: number) => (
                                                        <MenuItem key={index} className={categoryObj.disabled && classes.blurItem} value={categoryObj.value} disabled={categoryObj.disabled}>
                                                            <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '12px', whiteSpace: 'pre-line' }}>
                                                                {categoryObj.name}
                                                            </Typography>
                                                            
                                                        </MenuItem>
                                                    ))}
                                                    {this.state.priceActionCandlesOthersPatternsSubCategoryList.find((categoryObj: any) => categoryObj.disabled) && <UpgradeAlert />}
                                                </TextField>
                                            )}
                                        />
                                    </div>}
                                    <div className={classes.fullWidth}>
                                        <Field
                                            validateOnBlur
                                            validateOnChange
                                            name="priceActionCandleStickTimeOptionSubCategory"
                                            render={({ field, form }: any) => (
                                                <TextField
                                                    id="priceActionCandleStickTimeOptionSubCategory"
                                                    select
                                                    label={`Select Time Frame`}
                                                    value={this.state.priceActionCandleStickTimeOptionSubCategory}
                                                    onChange={(e) => this.setState({
                                                        priceActionCandleStickTimeOptionSubCategory: e.target.value
                                                    })}
                                                    name={"priceActionCandleStickTimeOptionSubCategory"}
                                                    fullWidth
                                                    variant="filled"
                                                    size="small"
                                                    margin="normal"
                                                    // hiddenLabel
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        className: classes.input,
                                                        endAdornment:  
                                                            <InputAdornment position="end" style={{ cursor: 'pointer', position: "absolute", paddingRight: 0, right: 25 }}>
                                                                <ClearIcon  onClick={() => { this.setState(
                                                                { priceActionCandleStickTimeOptionSubCategory: "" })}}/>
                                                            </InputAdornment>
                                                    }}
                                                    InputLabelProps={{ shrink: true }}
                                                    error={
                                                        Boolean(form.errors.priceActionCandleStickTimeOptionSubCategory && form.touched.priceActionCandleStickTimeOptionSubCategory)
                                                    }
                                                    helperText={
                                                        form.errors.priceActionCandleStickTimeOptionSubCategory &&
                                                        form.touched.priceActionCandleStickTimeOptionSubCategory &&
                                                        String(form.errors.priceActionCandleStickTimeOptionSubCategory)
                                                    }
                                                // style={{ marginBottom: '15px' }}
                                                >
                                                    {this.state.priceActionTimeFrameList.map((categoryObj: any, index: number) => (
                                                        this.TimeFrameOptions(categoryObj, index)
                                                    ))}
                                                    {this.state.priceActionTimeFrameList.find((timeObj: any) => timeObj.disabled) && 
                                                    <div style={{ position: 'absolute', top: 300, left: 40 }}><UpgradeAlert /></div>}
                                                </TextField>
                                            )}
                                        />
                                    </div>
                                </div>
                            }
                            { filterNamePopup && <SaveFilterName classes={classes} filterName={filterName} 
                            open={filterNamePopup} handleSubmit={handleSubmit} handleChange={(event: any) => 
                            this.setState({ filterName: event.target.value })} handleClickedBtn={this.handleClickedBtn}
                            handleCancel={() => this.setState({ filterNamePopup: false, filterName: "" })} /> }
                            <ButtonGroup fullWidth className={classes.buttonGroup}>
                                {this.state.token !== "" && <Button
                                    disabled={this.disablePriceActionsSaveButton()} 
                                    data-testid='priceactionSaveButton' type="submit" 
                                    className={classes.submitButton}
                                    onClick={() => this.handleClickedBtn('SaveRun')}>
                                Save & Run</Button>}
                                <Button
                                    disabled={this.disablePriceActionsSaveButton()} 
                                    data-testid='priceactionRunButton' type="submit" 
                                    className={classes.submitButton}
                                    onClick={() => this.handleClickedBtn('Run')}>
                                Run</Button>
                            </ButtonGroup>
                        </Form>
                    )}
                </Formik>
            </Popover>
        )
    }

    indicators = () => {

        const { activeItem, filterNamePopup, filterName, rsiTimeFrameList, selectedIndicatorsForFilter } = this.state;
        const { classes } = this.props;

        const topForUpgradeMessage = () => {
            let topCss:number = 250;
            if(selectedIndicatorsForFilter[0] === 1) 
                topCss = 400;
            if(selectedIndicatorsForFilter[0] === 5) 
                topCss = 535;
            return topCss;
        }

        const alertBox = () => {
          return (<>
          {rsiTimeFrameList.find((timeFrameObj: any) => timeFrameObj.disabled) && 
            <div style={{ position: 'absolute', top: 300, left: 40 }}><UpgradeAlert /></div>}
          </>)
        }

        return (
            <Popover
                open={Boolean(this.state.anchorEl) && activeItem == 'Indicators'}
                anchorEl={this.state.anchorEl}
                onClose={this.handleClose}
                data-testid="indicatorPopup"
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                disableAutoFocus={true}
                disableEnforceFocus={true}
                PaperProps={{
                    className: classes.popoverPaper,
                }}
            >
                <Formik
                    initialValues={{
                        selectedIndicatorsList: [],
                        rsiSubCategoryOption: "",
                        rsiPeriod: 14,
                        rsiRadioGroup: "",
                        rsiLevel: 70,
                        centerLine: 50,
                        rsiTimeFrame: "",
                        rsiDivergenceOption: "",
                        mfiSubCategoryOption: "",
                        mfiPeriod: 14,
                        mfiRadioGroup: "",
                        mfiLevel: 70,
                        mfiTimeFrame: "",
                        mfiDivergenceOption: "",
                        stochasticSubCategoryOption: "",
                        stochasticPeriod: 14,
                        stochasticRadioGroup: "",
                        stochasticLevel: 70,
                        stochasticTimeFrame: "",
                        stochasticDivergenceOption: "",
                        cciSubCategoryOption: "",
                        cciPeriod: 14,
                        cciRadioGroup: "",
                        cciTimeFrame: "",
                        cciDivergenceOption: "",
                        movingAverageSubCategoryOption: "",
                        simpleMovingAverageSubCategoryOption: "",
                        exponentialMovingAverageSubCategoryOption: "",
                        movingAveragePeriod: "",
                        movingAverageTimeFrame: "",
                        movingAverageCrossoverOption: "",
                        macdSubCategoryOption: "",
                        macdRadioGroup: "",
                        macdPeriod: 12,
                        macdDivergenceOption: "",
                        macdTimeFrame: "",
                        adxSubCategoryOption: "",
                        adxPeriod: 14,
                        adxRadioGroup: "",
                        adxLevel: 20,
                        adxTimeFrame: "",
                        obvSubCategoryOption: "",
                        obvLevel: "",
                        obvDivergenceOption: "",
                        obvTimeFrame: "",
                        cmfSubCategoryOption: "",
                        cmfPeriod: "",
                        cmfTimeFrame: "",
                        cmfDivergenceOption: "",
                        bollingerBandsSubCategoryOption: "",
                        bollingerBandsRadioGroup: "",
                        bollingerBandsTimeFrame: "",
                        atrSubCategoryOption: "",
                        atrPeriod: "",
                        atrTimeFrame: ""
                    }}
                    enableReinitialize
                    validationSchema={indicatorsSchema}
                    onSubmit={(values, { resetForm, setSubmitting }) => {
                        const callMethods = async () => {
                            if (this.state.clickedBtn && ['Run', 'Save'].includes(this.state.clickedBtn)) {
                                await Promise.all([this.resetDataBeforeAPICall(), this.indicatorsForm(this.state)])
                            }
                            this.setNavigation();
                        }
                        callMethods()
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                    }) => (
                        <Form onSubmit={handleSubmit}>
                            <FormControl component="fieldset" style={{ marginTop: '10px' }} className={classes.checkBoxColor}>
                                <FormGroup>
                                    {
                                        this.state.indicatorsOptionList.map((_data: any, key: any) => {
                                            return this.state.selectedIndicatorsForFilter.find((item: any) => item == _data?.id) == undefined ? (
                                                <FormControlLabel
                                                    disabled={_data.disabled}
                                                    className={_data.disabled && classes.blurItem}
                                                    control={<Checkbox onChange={(e) => this.handleSelectedFilterValueChange('indicators', _data?.id)} name="name" value={_data?.name} />}
                                                    label={<span style={{ fontSize: '12px' }}>{_data?.name}</span>}
                                                    key={key}
                                                />
                                            ) : (
                                                <div>
                                                    <FormControlLabel
                                                        control={<Checkbox onChange={(e) => this.handleSelectedFilterValueChange('indicators', _data?.id)} name="name" value={_data?.name} />}
                                                        label={<span style={{ fontSize: '12px', color: '#008000' }}>{_data?.name}</span>}
                                                        key={key}
                                                        checked={true}
                                                    />
                                                    {_data.name === 'RSI' && <div>
                                                        <div className={classes.fullWidth}>
                                                            <Field
                                                                validateOnBlur
                                                                validateOnChange
                                                                name="rsiSubCategoryOption"
                                                                render={({ field, form }: any) => (
                                                                    <TextField
                                                                        id="rsiSubCategoryOption"
                                                                        select
                                                                        label={`Select Screen`}
                                                                        // label={this.state.rsiSubCategoryOption == '' ? `Select Screen` : ''}
                                                                        value={this.state.rsiSubCategoryOption}
                                                                        onChange={(event) => {
                                                                            this.setState({
                                                                                rsiSubCategoryOption: event.target.value
                                                                            })
                                                                        }}
                                                                        name={"rsiSubCategoryOption"}
                                                                        fullWidth
                                                                        variant="filled"
                                                                        size="small"
                                                                        margin="normal"
                                                                        // hiddenLabel
                                                                        InputProps={{
                                                                            disableUnderline: true,
                                                                            className: classes.input,
                                                                            endAdornment:  
                                                                                <InputAdornment position="end" style={{ cursor: 'pointer', position: "absolute", paddingRight: 0, right: 25 }}>
                                                                                    <ClearIcon  onClick={() => { this.setState(
                                                                                    { rsiSubCategoryOption: "" })}}/>
                                                                                </InputAdornment>
                                                                        }}
                                                                        InputLabelProps={{ shrink: true }}
                                                                        error={
                                                                            Boolean(form.errors.rsiSubCategoryOption && form.touched.rsiSubCategoryOption)
                                                                        }
                                                                        helperText={
                                                                            form.errors.rsiSubCategoryOption &&
                                                                            form.touched.rsiSubCategoryOption &&
                                                                            String(form.errors.rsiSubCategoryOption)
                                                                        }
                                                                    // style={{ overflowWrap:'break-word' }}
                                                                    >
                                                                        {this.state.rsiSubCategoryList.map((categoryObj: any, index: number) => (
                                                                            <MenuItem key={index} value={categoryObj.id}>
                                                                                <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '12px', whiteSpace: 'pre-line' }}>
                                                                                    {categoryObj.name}
                                                                                </Typography>
                                                                            </MenuItem>
                                                                        ))}
                                                                    </TextField>
                                                                )}
                                                            />
                                                        </div>
                                                        {(this.state.rsiSubCategoryOption == '1' || this.state.rsiSubCategoryOption == '2' ||
                                                            this.state.rsiSubCategoryOption == '3' || this.state.rsiSubCategoryOption == '4' || this.state.rsiSubCategoryOption == '5') && <div>
                                                                <div className={classes.fullWidth}>
                                                                    <Field
                                                                        validateOnBlur
                                                                        validateOnChange
                                                                        name="rsiPeriod"
                                                                        render={({ field, form }: any) => (
                                                                            <TextField
                                                                                label={"Period"}
                                                                                name={"rsiPeriod"}
                                                                                variant="filled"
                                                                                value={this.state.rsiPeriod}
                                                                                type="number"
                                                                                fullWidth
                                                                                size="small"
                                                                                margin="normal"
                                                                                InputProps={{
                                                                                    disableUnderline: true,
                                                                                    className: classes.input,
                                                                                }}
                                                                                InputLabelProps={{ shrink: true }}
                                                                                error={
                                                                                    Boolean(form.errors.rsiPeriod && form.touched.rsiPeriod)
                                                                                }
                                                                                onChange={(e) => {
                                                                                    this.setState({
                                                                                        rsiPeriod: e.target.value
                                                                                    })
                                                                                }}
                                                                                onBlur={handleBlur}
                                                                                helperText={
                                                                                    form.errors.rsiPeriod &&
                                                                                    form.touched.rsiPeriod &&
                                                                                    String(form.errors.rsiPeriod)
                                                                                }
                                                                            />
                                                                        )}
                                                                    />
                                                                </div>
                                                                {(this.state.rsiSubCategoryOption == '3' || this.state.rsiSubCategoryOption == '4' ||
                                                                    this.state.rsiSubCategoryOption == '5') && <div style={{ marginLeft: '12px' }}>
                                                                        <FormControl component="fieldset">
                                                                            <FormLabel component="legend"></FormLabel>
                                                                            <RadioGroup row name={"rsiRadioGroup"} value={this.state.rsiRadioGroup.toString()} onChange={(event) => {
                                                                                this.setState({
                                                                                    rsiRadioGroup: event.currentTarget.value
                                                                                })
                                                                            }}>
                                                                                <FormControlLabel
                                                                                    value={"above"}
                                                                                    control={<Radio style={{ color: 'green' }} />}
                                                                                    label={<span style={{ fontSize: '12px' }}>{'Above'}</span>}
                                                                                    classes={{ root: classes.radio }}
                                                                                />
                                                                                <FormControlLabel
                                                                                    value={"below"}
                                                                                    control={<Radio style={{ color: 'green' }} />}
                                                                                    label={<span style={{ fontSize: '12px' }}>{'Below'}</span>}

                                                                                />
                                                                            </RadioGroup>
                                                                        </FormControl>
                                                                    </div>
                                                                }
                                                                {this.state.rsiSubCategoryOption != '5' && <div className={classes.fullWidth}>
                                                                    <Field
                                                                        validateOnBlur
                                                                        validateOnChange
                                                                        name="rsiLevel"
                                                                        render={({ field, form }: any) => (
                                                                            <TextField
                                                                                label={"Level"}
                                                                                name={"rsiLevel"}
                                                                                variant="filled"
                                                                                value={this.state.rsiLevel}
                                                                                fullWidth
                                                                                size="small"
                                                                                margin="normal"
                                                                                type="number"
                                                                                InputProps={{
                                                                                    disableUnderline: true,
                                                                                    className: classes.input,
                                                                                }}
                                                                                InputLabelProps={{ shrink: true }}
                                                                                error={
                                                                                    Boolean(form.errors.rsiLevel && form.touched.rsiLevel)
                                                                                }
                                                                                onChange={(e) => {
                                                                                    this.setState({
                                                                                        rsiLevel: e.target.value
                                                                                    })
                                                                                }}
                                                                                onBlur={handleBlur}
                                                                                helperText={
                                                                                    form.errors.rsiLevel &&
                                                                                    form.touched.rsiLevel &&
                                                                                    String(form.errors.rsiLevel)
                                                                                }
                                                                            />
                                                                        )}
                                                                    />
                                                                </div>}
                                                                {this.state.rsiSubCategoryOption == '5' && <div className={classes.fullWidth}>
                                                                    <Field
                                                                        validateOnBlur
                                                                        validateOnChange
                                                                        name="centerLine"
                                                                        render={({ field, form }: any) => (
                                                                            <TextField
                                                                                label={"Center Line"}
                                                                                name={"centerLine"}
                                                                                variant="filled"
                                                                                value={this.state.centerLine}
                                                                                fullWidth
                                                                                size="small"
                                                                                margin="normal"
                                                                                type="number"
                                                                                InputProps={{ disableUnderline: true, style: { fontSize: '12px' } }}
                                                                                error={
                                                                                    Boolean(form.errors.centerLine && form.touched.centerLine)
                                                                                }
                                                                                InputLabelProps={{ shrink: true }}
                                                                                onChange={(e) => {
                                                                                    this.setState({
                                                                                        centerLine: e.target.value
                                                                                    })
                                                                                }}
                                                                                onBlur={handleBlur}
                                                                                helperText={
                                                                                    form.errors.centerLine &&
                                                                                    form.touched.centerLine &&
                                                                                    String(form.errors.centerLine)
                                                                                }
                                                                            />
                                                                        )}
                                                                    />
                                                                </div>}
                                                            </div>
                                                        }
                                                        {(this.state.rsiSubCategoryOption == '6') && <div className={classes.fullWidth}>
                                                            <Field
                                                                validateOnBlur
                                                                validateOnChange
                                                                name="rsiDivergenceOption"
                                                                render={({ field, form }: any) => (
                                                                    <TextField
                                                                        id="rsiDivergenceOption"
                                                                        select
                                                                        label={`Divergence`}
                                                                        value={this.state.rsiDivergenceOption}
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                rsiDivergenceOption: e.target.value
                                                                            })
                                                                        }}
                                                                        name={"rsiDivergenceOption"}
                                                                        fullWidth
                                                                        variant="filled"
                                                                        size="small"
                                                                        margin="normal"
                                                                        // hiddenLabel
                                                                        InputProps={{
                                                                            disableUnderline: true,
                                                                            className: classes.input,
                                                                            endAdornment:  
                                                                                <InputAdornment position="end" style={{ cursor: 'pointer', position: "absolute", paddingRight: 0, right: 25 }}>
                                                                                    <ClearIcon  onClick={() => { this.setState(
                                                                                    { rsiDivergenceOption: "" })}}/>
                                                                                </InputAdornment>
                                                                        }}
                                                                        InputLabelProps={{ shrink: true }}
                                                                        error={
                                                                            Boolean(form.errors.rsiDivergenceOption && form.touched.rsiDivergenceOption)
                                                                        }
                                                                        helperText={
                                                                            form.errors.rsiDivergenceOption &&
                                                                            form.touched.rsiDivergenceOption &&
                                                                            String(form.errors.rsiDivergenceOption)
                                                                        }
                                                                    // style={{ marginBottom: '15px' }}
                                                                    >
                                                                        <MenuItem key={'bullish'} value={'bullish'}>
                                                                            <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '12px', whiteSpace: 'pre-line' }}>
                                                                                {'Bullish'}
                                                                            </Typography>
                                                                        </MenuItem>
                                                                        <MenuItem key={'bearish'} value={'bearish'}>
                                                                            <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '12px', whiteSpace: 'pre-line' }}>
                                                                                {'Bearish'}
                                                                            </Typography>
                                                                        </MenuItem>
                                                                    </TextField>
                                                                )}
                                                            />
                                                        </div>}
                                                        <div className={classes.fullWidth}>
                                                            <Field
                                                                validateOnBlur
                                                                validateOnChange
                                                                name="rsiTimeFrame"
                                                                render={({ field, form }: any) => (
                                                                    <TextField
                                                                        id="rsiTimeFrame"
                                                                        select
                                                                        label={`Select Time Frame`}
                                                                        value={this.state.rsiTimeFrame}
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                rsiTimeFrame: e.target.value
                                                                            })
                                                                        }}
                                                                        name={"rsiTimeFrame"}
                                                                        fullWidth
                                                                        variant="filled"
                                                                        size="small"
                                                                        margin="normal"
                                                                        // hiddenLabel
                                                                        InputProps={{
                                                                            disableUnderline: true,
                                                                            className: classes.input,
                                                                            endAdornment:  
                                                                                <InputAdornment position="end" style={{ cursor: 'pointer', position: "absolute", paddingRight: 0, right: 25 }}>
                                                                                    <ClearIcon  onClick={() => { this.setState(
                                                                                    { rsiTimeFrame: "" })}}/>
                                                                                </InputAdornment>
                                                                        }}
                                                                        InputLabelProps={{ shrink: true }}
                                                                        error={
                                                                            Boolean(form.errors.rsiTimeFrame && form.touched.rsiTimeFrame)
                                                                        }
                                                                        helperText={
                                                                            form.errors.rsiTimeFrame &&
                                                                            form.touched.rsiTimeFrame &&
                                                                            String(form.errors.rsiTimeFrame)
                                                                        }
                                                                    // style={{ marginBottom: '15px' }}
                                                                    >
                                                                        {this.state.rsiTimeFrameList.map((timeObj: any, index: number) => (
                                                                            this.TimeFrameOptions(timeObj, index)
                                                                        ))}
                                                                        {alertBox()}
                                                                    </TextField>
                                                                )}
                                                            />
                                                        </div>
                                                    </div>}
                                                    {_data.name === 'MFI' && <div>
                                                        <div className={classes.fullWidth}>
                                                            <Field
                                                                validateOnBlur
                                                                validateOnChange
                                                                name="mfiSubCategoryOption"
                                                                render={({ field, form }: any) => (
                                                                    <TextField
                                                                        id="mfiSubCategoryOption"
                                                                        select
                                                                        label={`Select Screen`}
                                                                        value={this.state.mfiSubCategoryOption}
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                mfiSubCategoryOption: e.target.value
                                                                            })
                                                                        }}
                                                                        name={"mfiSubCategoryOption"}
                                                                        fullWidth
                                                                        variant="filled"
                                                                        size="small"
                                                                        margin="normal"
                                                                        // hiddenLabel
                                                                        InputProps={{
                                                                            disableUnderline: true,
                                                                            className: classes.input,
                                                                            endAdornment:  
                                                                                <InputAdornment position="end" style={{ cursor: 'pointer', position: "absolute", paddingRight: 0, right: 25 }}>
                                                                                    <ClearIcon  onClick={() => { this.setState(
                                                                                    { mfiSubCategoryOption: "" })}}/>
                                                                                </InputAdornment>
                                                                        }}
                                                                        InputLabelProps={{ shrink: true }}
                                                                        error={
                                                                            Boolean(form.errors.mfiSubCategoryOption && form.touched.mfiSubCategoryOption)
                                                                        }
                                                                        helperText={
                                                                            form.errors.mfiSubCategoryOption &&
                                                                            form.touched.mfiSubCategoryOption &&
                                                                            String(form.errors.mfiSubCategoryOption)
                                                                        }
                                                                    // style={{ marginBottom: '15px' }}
                                                                    >
                                                                        {this.state.mfiSubCategoryList.map((categoryObj: any, index: number) => (
                                                                            <MenuItem key={index} value={categoryObj.id}>
                                                                                <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '12px', whiteSpace: 'pre-line' }}>
                                                                                    {categoryObj.name}
                                                                                </Typography>
                                                                            </MenuItem>
                                                                        ))}
                                                                    </TextField>
                                                                )}
                                                            />
                                                        </div>
                                                        {(this.state.mfiSubCategoryOption == '1' || this.state.mfiSubCategoryOption == '2' ||
                                                            this.state.mfiSubCategoryOption == '3' || this.state.mfiSubCategoryOption == '4' || this.state.mfiSubCategoryOption == '5') && <div>
                                                                <div className={classes.fullWidth}>
                                                                    <Field
                                                                        validateOnBlur
                                                                        validateOnChange
                                                                        name="mfiPeriod"
                                                                        render={({ field, form }: any) => (
                                                                            <TextField
                                                                                id="mfiPeriod"
                                                                                select
                                                                                label={`Period`}
                                                                                value={this.state.mfiPeriod}
                                                                                onChange={(e) => {
                                                                                    this.setState({
                                                                                        mfiPeriod: e.target.value
                                                                                    })
                                                                                }}
                                                                                name={"mfiPeriod"}
                                                                                fullWidth
                                                                                variant="filled"
                                                                                size="small"
                                                                                margin="normal"
                                                                                // hiddenLabel
                                                                                InputProps={{
                                                                                    disableUnderline: true,
                                                                                    className: classes.input,
                                                                                    endAdornment:  
                                                                                        <InputAdornment position="end" style={{ cursor: 'pointer', position: "absolute", paddingRight: 0, right: 25 }}>
                                                                                            <ClearIcon  onClick={() => { this.setState(
                                                                                            { mfiPeriod: "" })}}/>
                                                                                        </InputAdornment>
                                                                                }}
                                                                                InputLabelProps={{ shrink: true }}
                                                                                error={
                                                                                    Boolean(form.errors.mfiPeriod && form.touched.mfiPeriod)
                                                                                }
                                                                                helperText={
                                                                                    form.errors.mfiPeriod &&
                                                                                    form.touched.mfiPeriod &&
                                                                                    String(form.errors.mfiPeriod)
                                                                                }
                                                                            // style={{ marginBottom: '15px' }}
                                                                            >
                                                                                {[14, 20].map((item: any, index: number) => (
                                                                                    <MenuItem key={index} value={item}>
                                                                                        <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '12px', whiteSpace: 'pre-line' }}>
                                                                                            {item}
                                                                                        </Typography>
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </TextField>
                                                                        )}
                                                                    />
                                                                </div>
                                                                {(this.state.mfiSubCategoryOption == '3' || this.state.mfiSubCategoryOption == '4' ||
                                                                    this.state.mfiSubCategoryOption == '5') && <div style={{ marginLeft: '12px' }}>
                                                                        <FormControl component="fieldset">
                                                                            <FormLabel component="legend"></FormLabel>
                                                                            <RadioGroup row name={"mfiRadioGroup"} value={this.state.mfiRadioGroup.toString()} onChange={(event) => {
                                                                                this.setState({
                                                                                    mfiRadioGroup: event.currentTarget.value
                                                                                })
                                                                            }}>
                                                                                <FormControlLabel value={"above"} control={<Radio style={{ color: 'green' }} />} label={<span style={{ fontSize: '12px' }}>{'Above'}</span>} />
                                                                                <FormControlLabel value={"below"} control={<Radio style={{ color: 'green' }} />} label={<span style={{ fontSize: '12px' }}>{'Below'}</span>} />
                                                                            </RadioGroup>
                                                                        </FormControl>
                                                                    </div>
                                                                }
                                                                <div className={classes.fullWidth}>
                                                                    <Field
                                                                        validateOnBlur
                                                                        validateOnChange
                                                                        name="mfiLevel"
                                                                        render={({ field, form }: any) => (
                                                                            <TextField
                                                                                label={this.state.mfiSubCategoryOption == '5' ? 'Center Line' : "Level"}
                                                                                name={"mfiLevel"}
                                                                                variant="filled"
                                                                                value={this.state.mfiLevel}
                                                                                fullWidth
                                                                                size="small"
                                                                                type="number"
                                                                                margin="normal"
                                                                                InputProps={{
                                                                                    disableUnderline: true,
                                                                                    className: classes.input,
                                                                                }}
                                                                                InputLabelProps={{ shrink: true }}
                                                                                error={
                                                                                    Boolean(form.errors.mfiLevel && form.touched.mfiLevel)
                                                                                }
                                                                                onChange={(e) => {
                                                                                    this.setState({
                                                                                        mfiLevel: e.target.value
                                                                                    })
                                                                                }}
                                                                                onBlur={handleBlur}
                                                                                helperText={
                                                                                    form.errors.mfiLevel &&
                                                                                    form.touched.mfiLevel &&
                                                                                    String(form.errors.mfiLevel)
                                                                                }
                                                                            />
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>
                                                        }
                                                        {(this.state.mfiSubCategoryOption == '6') && <div className={classes.fullWidth}>
                                                            <Field
                                                                validateOnBlur
                                                                validateOnChange
                                                                name="mfiDivergenceOption"
                                                                render={({ field, form }: any) => (
                                                                    <TextField
                                                                        id="mfiDivergenceOption"
                                                                        select
                                                                        label={`Divergence`}
                                                                        value={this.state.mfiDivergenceOption}
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                mfiDivergenceOption: e.target.value
                                                                            })
                                                                        }}
                                                                        name={"mfiDivergenceOption"}
                                                                        fullWidth
                                                                        variant="filled"
                                                                        size="small"
                                                                        margin="normal"
                                                                        // hiddenLabel
                                                                        InputProps={{
                                                                            disableUnderline: true,
                                                                            className: classes.input,
                                                                            endAdornment:  
                                                                                <InputAdornment position="end" style={{ cursor: 'pointer', position: "absolute", paddingRight: 0, right: 25 }}>
                                                                                    <ClearIcon  onClick={() => { this.setState(
                                                                                    { mfiDivergenceOption: "" })}}/>
                                                                                </InputAdornment>
                                                                        }}
                                                                        InputLabelProps={{ shrink: true }}
                                                                        error={
                                                                            Boolean(form.errors.mfiDivergenceOption && form.touched.mfiDivergenceOption)
                                                                        }
                                                                        helperText={
                                                                            form.errors.mfiDivergenceOption &&
                                                                            form.touched.mfiDivergenceOption &&
                                                                            String(form.errors.mfiDivergenceOption)
                                                                        }
                                                                    // style={{ marginBottom: '15px' }}
                                                                    >
                                                                        <MenuItem key={'bullish'} value={'bullish'}>
                                                                            <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '12px', whiteSpace: 'pre-line' }}>
                                                                                {'Bullish'}
                                                                            </Typography>
                                                                        </MenuItem>
                                                                        <MenuItem key={'bearish'} value={'bearish'}>
                                                                            <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '12px', whiteSpace: 'pre-line' }}>
                                                                                {'Bearish'}
                                                                            </Typography>
                                                                        </MenuItem>
                                                                    </TextField>
                                                                )}
                                                            />
                                                        </div>}
                                                        <div className={classes.fullWidth}>
                                                            <Field
                                                                validateOnBlur
                                                                validateOnChange
                                                                name="mfiTimeFrame"
                                                                render={({ field, form }: any) => (
                                                                    <TextField
                                                                        id="mfiTimeFrame"
                                                                        select
                                                                        label={`Select Time Frame`}
                                                                        value={this.state.mfiTimeFrame}
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                mfiTimeFrame: e.target.value
                                                                            })
                                                                        }}
                                                                        name={"mfiTimeFrame"}
                                                                        fullWidth
                                                                        variant="filled"
                                                                        size="small"
                                                                        margin="normal"
                                                                        // hiddenLabel
                                                                        InputProps={{
                                                                            disableUnderline: true,
                                                                            className: classes.input,
                                                                            endAdornment:  
                                                                                <InputAdornment position="end" style={{ cursor: 'pointer', position: "absolute", paddingRight: 0, right: 25 }}>
                                                                                    <ClearIcon  onClick={() => { this.setState(
                                                                                    { mfiTimeFrame: "" })}}/>
                                                                                </InputAdornment>
                                                                        }}
                                                                        InputLabelProps={{ shrink: true }}
                                                                        error={
                                                                            Boolean(form.errors.mfiTimeFrame && form.touched.mfiTimeFrame)
                                                                        }
                                                                        helperText={
                                                                            form.errors.mfiTimeFrame &&
                                                                            form.touched.mfiTimeFrame &&
                                                                            String(form.errors.mfiTimeFrame)
                                                                        }
                                                                    // style={{ marginBottom: '15px' }}
                                                                    >
                                                                        {this.state.rsiTimeFrameList.map((timeObj: any, index: number) => (
                                                                          this.TimeFrameOptions(timeObj, index)
                                                                        ))}

                                                                        {alertBox()}
                                                                    </TextField>
                                                                )}
                                                            />
                                                        </div>
                                                    </div>}
                                                    {_data.name === 'Stochastic' && <div>
                                                        <div className={classes.fullWidth}>
                                                            <Field
                                                                validateOnBlur
                                                                validateOnChange
                                                                name="stochasticSubCategoryOption"
                                                                render={({ field, form }: any) => (
                                                                    <TextField
                                                                        id="stochasticSubCategoryOption"
                                                                        select
                                                                        label={`Select Screen`}
                                                                        value={this.state.stochasticSubCategoryOption}
                                                                        onChange={(event) => {
                                                                            this.setState({
                                                                                stochasticSubCategoryOption: event.target.value
                                                                            })
                                                                        }}
                                                                        name={"stochasticSubCategoryOption"}
                                                                        fullWidth
                                                                        variant="filled"
                                                                        size="small"
                                                                        margin="normal"
                                                                        // hiddenLabel
                                                                        InputProps={{
                                                                            disableUnderline: true,
                                                                            className: classes.input,
                                                                            endAdornment:  
                                                                                <InputAdornment position="end" style={{ cursor: 'pointer', position: "absolute", paddingRight: 0, right: 25 }}>
                                                                                    <ClearIcon  onClick={() => { this.setState(
                                                                                    { stochasticSubCategoryOption: "" })}}/>
                                                                                </InputAdornment>
                                                                        }}
                                                                        InputLabelProps={{ shrink: true }}
                                                                        error={
                                                                            Boolean(form.errors.stochasticSubCategoryOption && form.touched.stochasticSubCategoryOption)
                                                                        }
                                                                        helperText={
                                                                            form.errors.stochasticSubCategoryOption &&
                                                                            form.touched.stochasticSubCategoryOption &&
                                                                            String(form.errors.stochasticSubCategoryOption)
                                                                        }
                                                                    // style={{ marginBottom: '15px' }}
                                                                    >
                                                                        {this.state.stochasticSubCategoryList.map((categoryObj: any, index: number) => (
                                                                            <MenuItem key={index} value={categoryObj.id}>
                                                                                <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '12px', whiteSpace: 'pre-line' }}>
                                                                                    {categoryObj.name}
                                                                                </Typography>
                                                                            </MenuItem>
                                                                        ))}
                                                                    </TextField>
                                                                )}
                                                            />
                                                        </div>
                                                            {(this.state.stochasticSubCategoryOption == '1' || this.state.stochasticSubCategoryOption == '2' ||
                                                            this.state.stochasticSubCategoryOption == '3' || this.state.stochasticSubCategoryOption == '4' || this.state.stochasticSubCategoryOption == '5' ) 
                                                            && <div className={classes.fullWidth}>
                                                                    <Field
                                                                        validateOnBlur
                                                                        validateOnChange
                                                                        name="stochasticPeriod"
                                                                        render={({ field, form }: any) => (
                                                                            <TextField
                                                                                label={"Period"}
                                                                                name={"stochasticPeriod"}
                                                                                variant="filled"
                                                                                value={this.state.stochasticPeriod}
                                                                                fullWidth
                                                                                size="small"
                                                                                type="number"
                                                                                margin="normal"
                                                                                InputProps={{
                                                                                    disableUnderline: true,
                                                                                    className: classes.input,
                                                                                }}
                                                                                InputLabelProps={{ shrink: true }}
                                                                                error={
                                                                                    Boolean(form.errors.stochasticPeriod && form.touched.stochasticPeriod)
                                                                                }
                                                                                onChange={(e) => {
                                                                                    this.setState({
                                                                                        stochasticPeriod: e.target.value
                                                                                    })
                                                                                }}
                                                                                onBlur={handleBlur}
                                                                                helperText={
                                                                                    form.errors.stochasticPeriod &&
                                                                                    form.touched.stochasticPeriod &&
                                                                                    String(form.errors.stochasticPeriod)
                                                                                }
                                                                            />
                                                                        )}
                                                                    />
                                                                </div>}
                                                                {(this.state.stochasticSubCategoryOption != '8') && <div style={{ marginLeft: '12px' }}>
                                                                        <FormControl component="fieldset">
                                                                            <FormLabel component="legend"></FormLabel>
                                                                            <RadioGroup row name={"stochasticRadioGroup"} value={this.state.stochasticRadioGroup.toString()} onChange={(event) => {
                                                                                // setFieldValue("stochasticRadioGroup", event.currentTarget.value)
                                                                                this.setState({
                                                                                    stochasticRadioGroup: event.currentTarget.value
                                                                                })
                                                                            }}>
                                                                                <FormControlLabel value={"above"} control={<Radio style={{ color: 'green' }} />} label={<span style={{ fontSize: '12px' }}>{'Above'}</span>} />
                                                                                <FormControlLabel value={"below"} control={<Radio style={{ color: 'green' }} />} label={<span style={{ fontSize: '12px' }}>{'Below'}</span>} />
                                                                            </RadioGroup>
                                                                        </FormControl>
                                                                    </div>
                                                                }
                                                                {(this.state.stochasticSubCategoryOption == '1' || this.state.stochasticSubCategoryOption == '2' ||
                                                                this.state.stochasticSubCategoryOption == '3' || this.state.stochasticSubCategoryOption == '4' || this.state.stochasticSubCategoryOption == '5' ) 
                                                                && <div className={classes.fullWidth}>
                                                                    <Field
                                                                        validateOnBlur
                                                                        validateOnChange
                                                                        name="stochasticLevel"
                                                                        render={({ field, form }: any) => (
                                                                            <TextField
                                                                                label={this.state.stochasticSubCategoryOption == '5' ? 'Center Line' : "Level"}
                                                                                name={"stochasticLevel"}
                                                                                variant="filled"
                                                                                value={this.state.stochasticLevel}
                                                                                fullWidth
                                                                                size="small"
                                                                                type="number"
                                                                                margin="normal"
                                                                                InputProps={{
                                                                                    disableUnderline: true,
                                                                                    className: classes.input,
                                                                                }}
                                                                                InputLabelProps={{ shrink: true }}
                                                                                error={
                                                                                    Boolean(form.errors.stochasticLevel && form.touched.stochasticLevel)
                                                                                }
                                                                                onChange={(e) => {
                                                                                    this.setState({
                                                                                        stochasticLevel: e.target.value
                                                                                    })
                                                                                }}
                                                                                onBlur={handleBlur}
                                                                                helperText={
                                                                                    form.errors.stochasticLevel &&
                                                                                    form.touched.stochasticLevel &&
                                                                                    String(form.errors.stochasticLevel)
                                                                                }
                                                                            />
                                                                        )}
                                                                    />
                                                                </div>}
                                                        {(this.state.stochasticSubCategoryOption == '8') && <div className={classes.fullWidth}>
                                                            <Field
                                                                validateOnBlur
                                                                validateOnChange
                                                                name="stochasticDivergenceOption"
                                                                render={({ field, form }: any) => (
                                                                    <TextField
                                                                        id="stochasticDivergenceOption"
                                                                        select
                                                                        label={`Divergence`}
                                                                        value={this.state.stochasticDivergenceOption}
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                stochasticDivergenceOption: e.target.value
                                                                            })
                                                                        }}
                                                                        name={"stochasticDivergenceOption"}
                                                                        fullWidth
                                                                        variant="filled"
                                                                        size="small"
                                                                        margin="normal"
                                                                        // hiddenLabel
                                                                        InputProps={{
                                                                            disableUnderline: true,
                                                                            className: classes.input,
                                                                            endAdornment:  
                                                                                <InputAdornment position="end" style={{ cursor: 'pointer', position: "absolute", paddingRight: 0, right: 25 }}>
                                                                                    <ClearIcon  onClick={() => { this.setState(
                                                                                    { stochasticDivergenceOption: "" })}}/>
                                                                                </InputAdornment>
                                                                        }}
                                                                        InputLabelProps={{ shrink: true }}
                                                                        error={
                                                                            Boolean(form.errors.stochasticDivergenceOption && form.touched.stochasticDivergenceOption)
                                                                        }
                                                                        helperText={
                                                                            form.errors.stochasticDivergenceOption &&
                                                                            form.touched.stochasticDivergenceOption &&
                                                                            String(form.errors.stochasticDivergenceOption)
                                                                        }
                                                                    // style={{ marginBottom: '15px' }}
                                                                    >
                                                                        <MenuItem key={'bullish'} value={'bullish'}>
                                                                            <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '12px', whiteSpace: 'pre-line' }}>
                                                                                {'Bullish'}
                                                                            </Typography>
                                                                        </MenuItem>
                                                                        <MenuItem key={'bearish'} value={'bearish'}>
                                                                            <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '12px', whiteSpace: 'pre-line' }}>
                                                                                {'Bearish'}
                                                                            </Typography>
                                                                        </MenuItem>
                                                                    </TextField>
                                                                )}
                                                            />
                                                        </div>}
                                                        <div className={classes.fullWidth}>
                                                            <Field
                                                                validateOnBlur
                                                                validateOnChange
                                                                name="stochasticTimeFrame"
                                                                render={({ field, form }: any) => (
                                                                    <TextField
                                                                        id="stochasticTimeFrame"
                                                                        select
                                                                        label={`Select Time Frame`}
                                                                        value={this.state.stochasticTimeFrame}
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                stochasticTimeFrame: e.target.value
                                                                            })
                                                                        }}
                                                                        name={"stochasticTimeFrame"}
                                                                        fullWidth
                                                                        variant="filled"
                                                                        size="small"
                                                                        margin="normal"
                                                                        // hiddenLabel
                                                                        InputProps={{
                                                                            disableUnderline: true,
                                                                            className: classes.input,
                                                                            endAdornment:  
                                                                                <InputAdornment position="end" style={{ cursor: 'pointer', position: "absolute", paddingRight: 0, right: 25 }}>
                                                                                    <ClearIcon  onClick={() => { this.setState(
                                                                                    { stochasticTimeFrame: "" })}}/>
                                                                                </InputAdornment>
                                                                        }}
                                                                        InputLabelProps={{ shrink: true }}
                                                                        error={
                                                                            Boolean(form.errors.stochasticTimeFrame && form.touched.stochasticTimeFrame)
                                                                        }
                                                                        helperText={
                                                                            form.errors.stochasticTimeFrame &&
                                                                            form.touched.stochasticTimeFrame &&
                                                                            String(form.errors.stochasticTimeFrame)
                                                                        }
                                                                    // style={{ marginBottom: '15px' }}
                                                                    >
                                                                        {this.state.rsiTimeFrameList.map((timeObj: any, index: number) => (
                                                                              this.TimeFrameOptions(timeObj, index)
                                                                        ))}
                                                                        {alertBox()}
                                                                    </TextField>
                                                                )}
                                                            />
                                                        </div>
                                                    </div>}
                                                    {_data.name === 'CCI' && <div>
                                                        <div className={classes.fullWidth}>
                                                            <Field
                                                                validateOnBlur
                                                                validateOnChange
                                                                name="cciSubCategoryOption"
                                                                render={({ field, form }: any) => (
                                                                    <TextField
                                                                        id="cciSubCategoryOption"
                                                                        select
                                                                        label={`Select Screen`}
                                                                        value={this.state.cciSubCategoryOption}
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                cciSubCategoryOption: e.target.value
                                                                            })
                                                                        }}
                                                                        name={"cciSubCategoryOption"}
                                                                        fullWidth
                                                                        variant="filled"
                                                                        size="small"
                                                                        margin="normal"
                                                                        // hiddenLabel
                                                                        InputProps={{
                                                                            disableUnderline: true,
                                                                            className: classes.input,
                                                                            endAdornment:  
                                                                                <InputAdornment position="end" style={{ cursor: 'pointer', position: "absolute", paddingRight: 0, right: 25 }}>
                                                                                    <ClearIcon  onClick={() => { this.setState(
                                                                                    { cciSubCategoryOption: "" })}}/>
                                                                                </InputAdornment>
                                                                        }}
                                                                        InputLabelProps={{ shrink: true }}
                                                                        error={
                                                                            Boolean(form.errors.cciSubCategoryOption && form.touched.cciSubCategoryOption)
                                                                        }
                                                                        helperText={
                                                                            form.errors.cciSubCategoryOption &&
                                                                            form.touched.cciSubCategoryOption &&
                                                                            String(form.errors.cciSubCategoryOption)
                                                                        }
                                                                    // style={{ marginBottom: '15px' }}
                                                                    >
                                                                        {this.state.cciSubCategoryList.map((categoryObj: any, index: number) => (
                                                                            <MenuItem key={index} value={categoryObj.id}>
                                                                                <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '12px', whiteSpace: 'pre-line' }}>
                                                                                    {categoryObj.name}
                                                                                </Typography>
                                                                            </MenuItem>
                                                                        ))}
                                                                    </TextField>
                                                                )}
                                                            />
                                                        </div>
                                                        {(this.state.cciSubCategoryOption == '1' || this.state.cciSubCategoryOption == '2' ||
                                                            this.state.cciSubCategoryOption == '3' || this.state.cciSubCategoryOption == '4' || this.state.cciSubCategoryOption == '5') && <div>
                                                                <div className={classes.fullWidth}>
                                                                    <Field
                                                                        validateOnBlur
                                                                        validateOnChange
                                                                        name="cciPeriod"
                                                                        render={({ field, form }: any) => (
                                                                            <TextField
                                                                                label={"Period"}
                                                                                name={"cciPeriod"}
                                                                                variant="filled"
                                                                                value={this.state.cciPeriod}
                                                                                fullWidth
                                                                                size="small"
                                                                                type="number"
                                                                                margin="normal"
                                                                                InputProps={{
                                                                                    disableUnderline: true,
                                                                                    className: classes.input,
                                                                                }}
                                                                                InputLabelProps={{ shrink: true }}
                                                                                error={
                                                                                    Boolean(form.errors.cciPeriod && form.touched.cciPeriod)
                                                                                }
                                                                                onChange={(e) => {
                                                                                    this.setState({
                                                                                        cciPeriod: e.target.value
                                                                                    })
                                                                                }}
                                                                                onBlur={handleBlur}
                                                                                helperText={
                                                                                    form.errors.cciPeriod &&
                                                                                    form.touched.cciPeriod &&
                                                                                    String(form.errors.cciPeriod)
                                                                                }
                                                                            />
                                                                        )}
                                                                    />
                                                                </div>
                                                                {(this.state.cciSubCategoryOption == '3' || this.state.cciSubCategoryOption == '4' ||
                                                                    this.state.cciSubCategoryOption == '5') && <div style={{ marginLeft: '12px' }}>
                                                                        <FormControl component="fieldset">
                                                                            <FormLabel component="legend"></FormLabel>
                                                                            <RadioGroup row name={"cciRadioGroup"} value={this.state.cciRadioGroup.toString()} onChange={(event) => {
                                                                                this.setState({
                                                                                    cciRadioGroup: event.currentTarget.value
                                                                                })
                                                                            }}>
                                                                                <FormControlLabel value={"above"} control={<Radio style={{ color: 'green' }} />} label={<span style={{ fontSize: '12px' }}>{'Above'}</span>} />
                                                                                <FormControlLabel value={"below"} control={<Radio style={{ color: 'green' }} />} label={<span style={{ fontSize: '12px' }}>{'Below'}</span>} />
                                                                            </RadioGroup>
                                                                        </FormControl>
                                                                    </div>
                                                                }
                                                            </div>
                                                        }
                                                        {(this.state.cciSubCategoryOption == '6') && <div className={classes.fullWidth}>
                                                            <Field
                                                                validateOnBlur
                                                                validateOnChange
                                                                name="cciDivergenceOption"
                                                                render={({ field, form }: any) => (
                                                                    <TextField
                                                                        id="cciDivergenceOption"
                                                                        select
                                                                        label={`Divergence`}
                                                                        value={this.state.cciDivergenceOption}
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                cciDivergenceOption: e.target.value
                                                                            })
                                                                        }}
                                                                        name={"cciDivergenceOption"}
                                                                        fullWidth
                                                                        variant="filled"
                                                                        size="small"
                                                                        margin="normal"
                                                                        // hiddenLabel
                                                                        InputProps={{
                                                                            disableUnderline: true,
                                                                            className: classes.input,
                                                                            endAdornment:  
                                                                                <InputAdornment position="end" style={{ cursor: 'pointer', position: "absolute", paddingRight: 0, right: 25 }}>
                                                                                    <ClearIcon  onClick={() => { this.setState(
                                                                                    { cciDivergenceOption: "" })}}/>
                                                                                </InputAdornment>
                                                                        }}
                                                                        InputLabelProps={{ shrink: true }}
                                                                        error={
                                                                            Boolean(form.errors.cciDivergenceOption && form.touched.cciDivergenceOption)
                                                                        }
                                                                        helperText={
                                                                            form.errors.cciDivergenceOption &&
                                                                            form.touched.cciDivergenceOption &&
                                                                            String(form.errors.cciDivergenceOption)
                                                                        }
                                                                    // style={{ marginBottom: '15px' }}
                                                                    >
                                                                        <MenuItem key={'bullish'} value={'bullish'}>
                                                                            <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '12px', whiteSpace: 'pre-line' }}>
                                                                                {'Bullish'}
                                                                            </Typography>
                                                                        </MenuItem>
                                                                        <MenuItem key={'bearish'} value={'bearish'}>
                                                                            <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '12px', whiteSpace: 'pre-line' }}>
                                                                                {'Bearish'}
                                                                            </Typography>
                                                                        </MenuItem>
                                                                    </TextField>
                                                                )}
                                                            />
                                                        </div>}
                                                        <div className={classes.fullWidth}>
                                                            <Field
                                                                validateOnBlur
                                                                validateOnChange
                                                                name="cciTimeFrame"
                                                                render={({ field, form }: any) => (
                                                                    <TextField
                                                                        id="cciTimeFrame"
                                                                        select
                                                                        label={`Select Time Frame`}
                                                                        value={this.state.cciTimeFrame}
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                cciTimeFrame: e.target.value
                                                                            })
                                                                        }}
                                                                        name={"cciTimeFrame"}
                                                                        fullWidth
                                                                        variant="filled"
                                                                        size="small"
                                                                        margin="normal"
                                                                        // hiddenLabel
                                                                        InputProps={{
                                                                            disableUnderline: true,
                                                                            className: classes.input,
                                                                            endAdornment:  
                                                                                <InputAdornment position="end" style={{ cursor: 'pointer', position: "absolute", paddingRight: 0, right: 25 }}>
                                                                                    <ClearIcon  onClick={() => { this.setState(
                                                                                    { cciTimeFrame: "" })}}/>
                                                                                </InputAdornment>
                                                                        }}
                                                                        InputLabelProps={{ shrink: true }}
                                                                        error={
                                                                            Boolean(form.errors.cciTimeFrame && form.touched.cciTimeFrame)
                                                                        }
                                                                        helperText={
                                                                            form.errors.cciTimeFrame &&
                                                                            form.touched.cciTimeFrame &&
                                                                            String(form.errors.cciTimeFrame)
                                                                        }
                                                                    // style={{ marginBottom: '15px' }}
                                                                    >
                                                                        {this.state.rsiTimeFrameList.map((timeObj: any, index: number) => (
                                                                            this.TimeFrameOptions(timeObj, index)
                                                                        ))}

                                                                        {alertBox()}
                                                                    </TextField>
                                                                )}
                                                            />
                                                        </div>
                                                    </div>}
                                                    {_data.name === 'Moving Average' && <div>
                                                        <div className={classes.fullWidth}>
                                                            <Field
                                                                validateOnBlur
                                                                validateOnChange
                                                                name="movingAverageSubCategoryOption"
                                                                render={({ field, form }: any) => (
                                                                    <TextField
                                                                        id="movingAverageSubCategoryOption"
                                                                        select
                                                                        label={`Select Moving Average Type`}
                                                                        value={this.state.movingAverageSubCategoryOption}
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                movingAverageSubCategoryOption: e.target.value,
                                                                                simpleMovingAverageSubCategoryOption: '',
                                                                                exponentialMovingAverageSubCategoryOption: '',
                                                                                movingAverageCrossoverOption: ''
                                                                            })
                                                                        }}
                                                                        name={"movingAverageSubCategoryOption"}
                                                                        fullWidth
                                                                        variant="filled"
                                                                        size="small"
                                                                        margin="normal"
                                                                        // hiddenLabel
                                                                        InputProps={{
                                                                            disableUnderline: true,
                                                                            className: classes.input,
                                                                            endAdornment:  
                                                                                <InputAdornment position="end" style={{ cursor: 'pointer', position: "absolute", paddingRight: 0, right: 25 }}>
                                                                                    <ClearIcon  onClick={() => { this.setState(
                                                                                    { movingAverageSubCategoryOption: "" })}}/>
                                                                                </InputAdornment>
                                                                        }}
                                                                        InputLabelProps={{ shrink: true }}
                                                                        error={
                                                                            Boolean(form.errors.movingAverageSubCategoryOption && form.touched.movingAverageSubCategoryOption)
                                                                        }
                                                                        helperText={
                                                                            form.errors.movingAverageSubCategoryOption &&
                                                                            form.touched.movingAverageSubCategoryOption &&
                                                                            String(form.errors.movingAverageSubCategoryOption)
                                                                        }
                                                                    // style={{ marginBottom: '15px' }}
                                                                    >
                                                                        {this.state.movingAverageSubCategoryList.map((categoryObj: any, index: number) => (
                                                                            <MenuItem key={index} value={categoryObj.id}>
                                                                                <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '12px', whiteSpace: 'pre-line' }}>
                                                                                    {categoryObj.name}
                                                                                </Typography>
                                                                            </MenuItem>
                                                                        ))}
                                                                    </TextField>
                                                                )}
                                                            />
                                                        </div>
                                                        {(this.state.movingAverageSubCategoryOption == '1') && <div className={classes.fullWidth}>
                                                            <Field
                                                                validateOnBlur
                                                                validateOnChange
                                                                name="simpleMovingAverageSubCategoryOption"
                                                                render={({ field, form }: any) => (
                                                                    <TextField
                                                                        id="simpleMovingAverageSubCategoryOption"
                                                                        select
                                                                        label={`Select Screen`}
                                                                        value={this.state.simpleMovingAverageSubCategoryOption}
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                simpleMovingAverageSubCategoryOption: e.target.value,
                                                                            })
                                                                        }}
                                                                        name={"simpleMovingAverageSubCategoryOption"}
                                                                        fullWidth
                                                                        variant="filled"
                                                                        size="small"
                                                                        margin="normal"
                                                                        // hiddenLabel
                                                                        InputProps={{
                                                                            disableUnderline: true,
                                                                            className: classes.input,
                                                                            endAdornment:  
                                                                                <InputAdornment position="end" style={{ cursor: 'pointer', position: "absolute", paddingRight: 0, right: 25 }}>
                                                                                    <ClearIcon  onClick={() => { this.setState(
                                                                                    { simpleMovingAverageSubCategoryOption: "" })}}/>
                                                                                </InputAdornment>
                                                                        }}
                                                                        InputLabelProps={{ shrink: true }}
                                                                        error={
                                                                            Boolean(form.errors.simpleMovingAverageSubCategoryOption && form.touched.simpleMovingAverageSubCategoryOption)
                                                                        }
                                                                        helperText={
                                                                            form.errors.simpleMovingAverageSubCategoryOption &&
                                                                            form.touched.simpleMovingAverageSubCategoryOption &&
                                                                            String(form.errors.simpleMovingAverageSubCategoryOption)
                                                                        }
                                                                    // style={{ marginBottom: '15px' }}
                                                                    >
                                                                        {this.state.simpleMovingAverageSubCategoryList.map((categoryObj: any, index: number) => (
                                                                            <MenuItem key={index} value={categoryObj.id}>
                                                                                <Typography variant="h5" style={{ width: '95%', fontWeight: 'bold', fontSize: '12px', whiteSpace: 'pre-line' }}>
                                                                                    {categoryObj.name}
                                                                                </Typography>
                                                                            </MenuItem>
                                                                        ))}
                                                                    </TextField>
                                                                )}
                                                            />
                                                        </div>}
                                                        {(this.state.movingAverageSubCategoryOption == '2') && <div className={classes.fullWidth}>
                                                            <Field
                                                                validateOnBlur
                                                                validateOnChange
                                                                name="exponentialMovingAverageSubCategoryOption"
                                                                render={({ field, form }: any) => (
                                                                    <TextField
                                                                        id="exponentialMovingAverageSubCategoryOption"
                                                                        select
                                                                        label={`Select Screen`}
                                                                        value={this.state.exponentialMovingAverageSubCategoryOption}
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                exponentialMovingAverageSubCategoryOption: e.target.value,
                                                                            })
                                                                        }}
                                                                        name={"exponentialMovingAverageSubCategoryOption"}
                                                                        fullWidth
                                                                        variant="filled"
                                                                        size="small"
                                                                        margin="normal"
                                                                        // hiddenLabel
                                                                        InputProps={{
                                                                            disableUnderline: true,
                                                                            className: classes.input,
                                                                            endAdornment:  
                                                                                <InputAdornment position="end" style={{ cursor: 'pointer', position: "absolute", paddingRight: 0, right: 25 }}>
                                                                                    <ClearIcon  onClick={() => { this.setState(
                                                                                    { exponentialMovingAverageSubCategoryOption: "" })}}/>
                                                                                </InputAdornment>
                                                                        }}
                                                                        InputLabelProps={{ shrink: true }}
                                                                        error={
                                                                            Boolean(form.errors.exponentialMovingAverageSubCategoryOption && form.touched.exponentialMovingAverageSubCategoryOption)
                                                                        }
                                                                        helperText={
                                                                            form.errors.exponentialMovingAverageSubCategoryOption &&
                                                                            form.touched.exponentialMovingAverageSubCategoryOption &&
                                                                            String(form.errors.exponentialMovingAverageSubCategoryOption)
                                                                        }
                                                                    // style={{ marginBottom: '15px' }}
                                                                    >
                                                                        {this.state.exponentialMovingAverageSubCategoryList.map((categoryObj: any, index: number) => (
                                                                            <MenuItem key={index} value={categoryObj.id}>
                                                                                <Typography variant="h5" style={{ width: '95%', fontWeight: 'bold', fontSize: '12px', whiteSpace: 'pre-line' }}>
                                                                                    {categoryObj.name}
                                                                                </Typography>
                                                                            </MenuItem>
                                                                        ))}
                                                                    </TextField>
                                                                )}
                                                            />
                                                        </div>}
                                                        {(this.state.simpleMovingAverageSubCategoryOption != '7' && this.state.exponentialMovingAverageSubCategoryOption != '7') && <div>
                                                            <div className={classes.fullWidth}>
                                                                <Field
                                                                    validateOnBlur
                                                                    validateOnChange
                                                                    name="movingAveragePeriod"
                                                                    render={({ field, form }: any) => (
                                                                        <TextField
                                                                            label={"Period"}
                                                                            name={"movingAveragePeriod"}
                                                                            variant="filled"
                                                                            value={this.state.movingAveragePeriod}
                                                                            fullWidth
                                                                            type="number"
                                                                            size="small"
                                                                            margin="normal"
                                                                            InputProps={{ disableUnderline: true, className: classes.input }}
                                                                            error={
                                                                                Boolean(form.errors.movingAveragePeriod && form.touched.movingAveragePeriod)
                                                                            }
                                                                            onChange={(e) => {
                                                                                this.setState({
                                                                                    movingAveragePeriod: e.target.value
                                                                                })
                                                                            }}
                                                                            InputLabelProps={{ shrink: true }}
                                                                            onBlur={handleBlur}
                                                                            helperText={
                                                                                form.errors.movingAveragePeriod &&
                                                                                form.touched.movingAveragePeriod &&
                                                                                String(form.errors.movingAveragePeriod)
                                                                            }
                                                                        />
                                                                    )}
                                                                />
                                                            </div>
                                                            <div className={classes.fullWidth}>
                                                                <Field
                                                                    validateOnBlur
                                                                    validateOnChange
                                                                    name="movingAverageTimeFrame"
                                                                    render={({ field, form }: any) => (
                                                                        <TextField
                                                                            id="movingAverageTimeFrame"
                                                                            select
                                                                            label={`Select Time Frame`}
                                                                            value={this.state.movingAverageTimeFrame}
                                                                            onChange={(e) => {
                                                                                this.setState({
                                                                                    movingAverageTimeFrame: e.target.value
                                                                                })
                                                                            }}
                                                                            name={"movingAverageTimeFrame"}
                                                                            fullWidth
                                                                            variant="filled"
                                                                            size="small"
                                                                            margin="normal"
                                                                            // hiddenLabel
                                                                            InputProps={{
                                                                                disableUnderline: true,
                                                                                className: classes.input,
                                                                                endAdornment:  
                                                                                <InputAdornment position="end" style={{ cursor: 'pointer', position: "absolute", paddingRight: 0, right: 25 }}>
                                                                                    <ClearIcon  onClick={() => { this.setState(
                                                                                    { movingAverageTimeFrame: "" })}}/>
                                                                                </InputAdornment>
                                                                            }}
                                                                            InputLabelProps={{ shrink: true }}
                                                                            error={
                                                                                Boolean(form.errors.movingAverageTimeFrame && form.touched.movingAverageTimeFrame)
                                                                            }
                                                                            helperText={
                                                                                form.errors.movingAverageTimeFrame &&
                                                                                form.touched.movingAverageTimeFrame &&
                                                                                String(form.errors.movingAverageTimeFrame)
                                                                            }
                                                                        // style={{ marginBottom: '15px' }}
                                                                        >
                                                                            {this.state.rsiTimeFrameList.map((timeObj: any, index: number) => (
                                                                                this.TimeFrameOptions(timeObj, index)
                                                                            ))}
                                                                            {alertBox()}
                                                                        </TextField>
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>}
                                                        {(this.state.simpleMovingAverageSubCategoryOption == '7' || this.state.exponentialMovingAverageSubCategoryOption == '7') && <div className={classes.fullWidth}>
                                                            <Field
                                                                validateOnBlur
                                                                validateOnChange
                                                                name="movingAverageCrossoverOption"
                                                                render={({ field, form }: any) => (
                                                                    <TextField
                                                                        id="movingAverageCrossoverOption"
                                                                        select
                                                                        label={`Crossover`}
                                                                        value={this.state.movingAverageCrossoverOption}
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                movingAverageCrossoverOption: e.target.value
                                                                            })
                                                                        }}
                                                                        name={"movingAverageCrossoverOption"}
                                                                        fullWidth
                                                                        variant="filled"
                                                                        size="small"
                                                                        margin="normal"
                                                                        // hiddenLabel
                                                                        InputProps={{
                                                                            disableUnderline: true,
                                                                            className: classes.input,
                                                                            endAdornment:  
                                                                                <InputAdornment position="end" style={{ cursor: 'pointer', position: "absolute", paddingRight: 0, right: 25 }}>
                                                                                    <ClearIcon  onClick={() => { this.setState(
                                                                                    { movingAverageCrossoverOption: "" })}}/>
                                                                                </InputAdornment>
                                                                        }}
                                                                        InputLabelProps={{ shrink: true }}
                                                                        error={
                                                                            Boolean(form.errors.movingAverageCrossoverOption && form.touched.movingAverageCrossoverOption)
                                                                        }
                                                                        helperText={
                                                                            form.errors.movingAverageCrossoverOption &&
                                                                            form.touched.movingAverageCrossoverOption &&
                                                                            String(form.errors.movingAverageCrossoverOption)
                                                                        }
                                                                    // style={{ marginBottom: '15px' }}
                                                                    >
                                                                        <MenuItem key={'bullish'} value={'bullish'}>
                                                                            <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '12px', whiteSpace: 'pre-line' }}>
                                                                                {'Bullish'}
                                                                            </Typography>
                                                                        </MenuItem>
                                                                        <MenuItem key={'bearish'} value={'bearish'}>
                                                                            <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '12px', whiteSpace: 'pre-line' }}>
                                                                                {'Bearish'}
                                                                            </Typography>
                                                                        </MenuItem>
                                                                    </TextField>
                                                                )}
                                                            />
                                                        </div>}
                                                    </div>}
                                                    {_data.name === 'MACD' && <div>
                                                        <div className={classes.fullWidth}>
                                                            <Field
                                                                validateOnBlur
                                                                validateOnChange
                                                                name="macdSubCategoryOption"
                                                                render={({ field, form }: any) => (
                                                                    <TextField
                                                                        id="macdSubCategoryOption"
                                                                        select
                                                                        label={`Select Screen`}
                                                                        value={this.state.macdSubCategoryOption}
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                macdSubCategoryOption: e.target.value
                                                                            })
                                                                        }}
                                                                        name={"macdSubCategoryOption"}
                                                                        fullWidth
                                                                        variant="filled"
                                                                        size="small"
                                                                        margin="normal"
                                                                        // hiddenLabel
                                                                        InputProps={{
                                                                            disableUnderline: true,
                                                                            className: classes.input,
                                                                            endAdornment:  
                                                                                <InputAdornment position="end" style={{ cursor: 'pointer', position: "absolute", paddingRight: 0, right: 25 }}>
                                                                                    <ClearIcon  onClick={() => { this.setState(
                                                                                    { macdSubCategoryOption: "" })}}/>
                                                                                </InputAdornment>
                                                                        }}
                                                                        InputLabelProps={{ shrink: true }}
                                                                        error={
                                                                            Boolean(form.errors.macdSubCategoryOption && form.touched.macdSubCategoryOption)
                                                                        }
                                                                        helperText={
                                                                            form.errors.macdSubCategoryOption &&
                                                                            form.touched.macdSubCategoryOption &&
                                                                            String(form.errors.macdSubCategoryOption)
                                                                        }
                                                                    // style={{ marginBottom: '15px' }}
                                                                    >
                                                                        {this.state.macdSubCategoryList.map((categoryObj: any, index: number) => (
                                                                            <MenuItem key={index} value={categoryObj.id}>
                                                                                <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '12px', whiteSpace: 'pre-line' }}>
                                                                                    {categoryObj.name}
                                                                                </Typography>
                                                                            </MenuItem>
                                                                        ))}
                                                                    </TextField>
                                                                )}
                                                            />
                                                        </div>
                                                        {(this.state.macdSubCategoryOption == '1' || this.state.macdSubCategoryOption == '2' ||
                                                            this.state.macdSubCategoryOption == '3' || this.state.macdSubCategoryOption == '4') && <div style={{ marginLeft: '12px' }}>
                                                                <FormControl component="fieldset">
                                                                    <FormLabel component="legend"></FormLabel>
                                                                    <RadioGroup row name={"macdRadioGroup"} value={this.state.macdRadioGroup.toString()} onChange={(event) => {
                                                                        this.setState({
                                                                            macdRadioGroup: event.currentTarget.value
                                                                        })
                                                                    }}>
                                                                        <FormControlLabel value={"above"} control={<Radio style={{ color: 'green' }} />} label={<span style={{ fontSize: '12px' }}>{'Above'}</span>} />
                                                                        <FormControlLabel value={"below"} control={<Radio style={{ color: 'green' }} />} label={<span style={{ fontSize: '12px' }}>{'Below'}</span>} />
                                                                    </RadioGroup>
                                                                </FormControl>
                                                            </div>
                                                        }
                                                        {(this.state.macdSubCategoryOption == '5') && <div style={{ marginLeft: '12px' }}>
                                                            <FormControl component="fieldset">
                                                                <FormLabel component="legend"></FormLabel>
                                                                <RadioGroup row name={"macdTrendingRadioGroup"} value={this.state.macdTrendingRadioGroup.toString()} onChange={(event) => {
                                                                    this.setState({
                                                                        macdTrendingRadioGroup: event.currentTarget.value
                                                                    })
                                                                }}>
                                                                    <FormControlLabel value={"up"} control={<Radio style={{ color: 'green' }} />} label={<span style={{ fontSize: '12px' }}>{'Trending Up'}</span>} />
                                                                    <FormControlLabel value={"down"} control={<Radio style={{ color: 'green' }} />} label={<span style={{ fontSize: '12px' }}>{'Trending Down'}</span>} />
                                                                </RadioGroup>
                                                            </FormControl>
                                                        </div>
                                                        }
                                                        {(this.state.macdSubCategoryOption == '5') && <div className={classes.fullWidth}>
                                                            <Field
                                                                validateOnBlur
                                                                validateOnChange
                                                                name="macdPeriod"
                                                                render={({ field, form }: any) => (
                                                                    <TextField
                                                                        label={"Period"}
                                                                        name={"macdPeriod"}
                                                                        variant="filled"
                                                                        type="number"
                                                                        value={this.state.macdPeriod}
                                                                        fullWidth
                                                                        size="small"
                                                                        margin="normal"
                                                                        style={{ fontSize: 12 }}
                                                                        InputProps={{
                                                                            disableUnderline: true,
                                                                            className: classes.input,
                                                                        }}
                                                                        InputLabelProps={{ shrink: true }}
                                                                        error={
                                                                            Boolean(form.errors.macdPeriod && form.touched.macdPeriod)
                                                                        }
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                macdPeriod: e.target.value
                                                                            })
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                        helperText={
                                                                            form.errors.macdPeriod &&
                                                                            form.touched.macdPeriod &&
                                                                            String(form.errors.macdPeriod)
                                                                        }
                                                                    />
                                                                )}
                                                            />
                                                        </div>}
                                                        {(this.state.macdSubCategoryOption == '6') && <div className={classes.fullWidth}>
                                                            <Field
                                                                validateOnBlur
                                                                validateOnChange
                                                                name="macdDivergenceOption"
                                                                render={({ field, form }: any) => (
                                                                    <TextField
                                                                        id="macdDivergenceOption"
                                                                        select
                                                                        label={`Divergence`}
                                                                        value={this.state.macdDivergenceOption}
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                macdDivergenceOption: e.target.value
                                                                            })
                                                                        }}
                                                                        name={"macdDivergenceOption"}
                                                                        fullWidth
                                                                        variant="filled"
                                                                        size="small"
                                                                        margin="normal"
                                                                        // hiddenLabel
                                                                        InputProps={{
                                                                            disableUnderline: true,
                                                                            className: classes.input,
                                                                            endAdornment:  
                                                                                <InputAdornment position="end" style={{ cursor: 'pointer', position: "absolute", paddingRight: 0, right: 25 }}>
                                                                                    <ClearIcon  onClick={() => { this.setState(
                                                                                    { macdDivergenceOption: "" })}}/>
                                                                                </InputAdornment>
                                                                        }}
                                                                        InputLabelProps={{ shrink: true }}
                                                                        error={
                                                                            Boolean(form.errors.macdDivergenceOption && form.touched.macdDivergenceOption)
                                                                        }
                                                                        helperText={
                                                                            form.errors.macdDivergenceOption &&
                                                                            form.touched.macdDivergenceOption &&
                                                                            String(form.errors.macdDivergenceOption)
                                                                        }
                                                                    // style={{ marginBottom: '15px' }}
                                                                    >
                                                                        <MenuItem key={'bullish'} value={'bullish'}>
                                                                            <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '12px', whiteSpace: 'pre-line' }}>
                                                                                {'Bullish'}
                                                                            </Typography>
                                                                        </MenuItem>
                                                                        <MenuItem key={'bearish'} value={'bearish'}>
                                                                            <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '12px', whiteSpace: 'pre-line' }}>
                                                                                {'Bearish'}
                                                                            </Typography>
                                                                        </MenuItem>
                                                                    </TextField>
                                                                )}
                                                            />
                                                        </div>}
                                                        <div className={classes.fullWidth}>
                                                            <Field
                                                                validateOnBlur
                                                                validateOnChange
                                                                name="macdTimeFrame"
                                                                render={({ field, form }: any) => (
                                                                    <TextField
                                                                        id="macdTimeFrame"
                                                                        select
                                                                        label={`Select Time Frame`}
                                                                        value={this.state.macdTimeFrame}
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                macdTimeFrame: e.target.value
                                                                            })
                                                                        }}
                                                                        name={"macdTimeFrame"}
                                                                        fullWidth
                                                                        variant="filled"
                                                                        size="small"
                                                                        margin="normal"
                                                                        // hiddenLabel
                                                                        InputProps={{
                                                                            disableUnderline: true,
                                                                            className: classes.input,
                                                                            endAdornment:  
                                                                                <InputAdornment position="end" style={{ cursor: 'pointer', position: "absolute", paddingRight: 0, right: 25 }}>
                                                                                    <ClearIcon  onClick={() => { this.setState(
                                                                                    { macdTimeFrame: "" })}}/>
                                                                                </InputAdornment>
                                                                        }}
                                                                        InputLabelProps={{ shrink: true }}
                                                                        error={
                                                                            Boolean(form.errors.macdTimeFrame && form.touched.macdTimeFrame)
                                                                        }
                                                                        helperText={
                                                                            form.errors.macdTimeFrame &&
                                                                            form.touched.macdTimeFrame &&
                                                                            String(form.errors.macdTimeFrame)
                                                                        }
                                                                    // style={{ marginBottom: '15px' }}
                                                                    >
                                                                        {this.state.rsiTimeFrameList.map((timeObj: any, index: number) => (
                                                                          this.TimeFrameOptions(timeObj, index)
                                                                        ))}
                                                                        { alertBox() }
                                                                    </TextField>
                                                                )}
                                                            />
                                                        </div>
                                                    </div>}
                                                    {_data.name === 'ADX' && <div>
                                                        <div className={classes.fullWidth}>
                                                            <Field
                                                                validateOnBlur
                                                                validateOnChange
                                                                name="adxSubCategoryOption"
                                                                render={({ field, form }: any) => (
                                                                    <TextField
                                                                        id="adxSubCategoryOption"
                                                                        select
                                                                        label={`Select Screen`}
                                                                        value={this.state.adxSubCategoryOption}
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                adxSubCategoryOption: e.target.value
                                                                            })
                                                                        }}
                                                                        name={"adxSubCategoryOption"}
                                                                        fullWidth
                                                                        variant="filled"
                                                                        size="small"
                                                                        margin="normal"
                                                                        // hiddenLabel
                                                                        InputProps={{
                                                                            disableUnderline: true,
                                                                            className: classes.input,
                                                                            endAdornment:  
                                                                                <InputAdornment position="end" style={{ cursor: 'pointer', position: "absolute", paddingRight: 0, right: 25 }}>
                                                                                    <ClearIcon  onClick={() => { this.setState(
                                                                                    { adxSubCategoryOption: "" })}}/>
                                                                                </InputAdornment>
                                                                        }}
                                                                        InputLabelProps={{ shrink: true }}
                                                                        error={
                                                                            Boolean(form.errors.adxSubCategoryOption && form.touched.adxSubCategoryOption)
                                                                        }
                                                                        helperText={
                                                                            form.errors.adxSubCategoryOption &&
                                                                            form.touched.adxSubCategoryOption &&
                                                                            String(form.errors.adxSubCategoryOption)
                                                                        }
                                                                    // style={{ marginBottom: '15px' }}
                                                                    >
                                                                        {this.state.adxSubCategoryList.map((categoryObj: any, index: number) => (
                                                                            <MenuItem key={index} value={categoryObj.id}>
                                                                                <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '12px', whiteSpace: 'pre-line' }}>
                                                                                    {categoryObj.name}
                                                                                </Typography>
                                                                            </MenuItem>
                                                                        ))}
                                                                    </TextField>
                                                                )}
                                                            />
                                                        </div>
                                                        <div className={classes.fullWidth}>
                                                            <Field
                                                                validateOnBlur
                                                                validateOnChange
                                                                name="adxPeriod"
                                                                render={({ field, form }: any) => (
                                                                    <TextField
                                                                        label={"Period"}
                                                                        name={"adxPeriod"}
                                                                        variant="filled"
                                                                        value={this.state.adxPeriod}
                                                                        fullWidth
                                                                        size="small"
                                                                        margin="normal"
                                                                        type="number"
                                                                        InputProps={{
                                                                            disableUnderline: true,
                                                                            className: classes.input,
                                                                        }}
                                                                        InputLabelProps={{ shrink: true }}
                                                                        error={
                                                                            Boolean(form.errors.adxPeriod && form.touched.adxPeriod)
                                                                        }
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                adxPeriod: e.target.value
                                                                            })
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                        helperText={
                                                                            form.errors.adxPeriod &&
                                                                            form.touched.adxPeriod &&
                                                                            String(form.errors.adxPeriod)
                                                                        }
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                        {this.state.adxSubCategoryOption != '11' && this.state.adxSubCategoryOption != '12' && <div>
                                                            <div style={{ marginLeft: '12px' }}>
                                                                <FormControl component="fieldset">
                                                                    <FormLabel component="legend"></FormLabel>
                                                                    <RadioGroup row name={"adxRadioGroup"} value={this.state.adxRadioGroup.toString()} onChange={(event) => {
                                                                        setFieldValue("adxRadioGroup", event.currentTarget.value)
                                                                        this.setState({adxRadioGroup:event.currentTarget.value})
                                                                    }}>
                                                                        <FormControlLabel value={"above"} control={<Radio style={{ color: 'green' }} />} label={<span style={{ fontSize: '12px' }}>{'Above'}</span>} />
                                                                        <FormControlLabel value={"below"} control={<Radio style={{ color: 'green' }} />} label={<span style={{ fontSize: '12px' }}>{'Below'}</span>} />
                                                                    </RadioGroup>
                                                                </FormControl>
                                                            </div>
                                                            {( this.state.adxSubCategoryOption == '1' || this.state.adxSubCategoryOption == '2' || this.state.adxSubCategoryOption == '5' ||
                                                            this.state.adxSubCategoryOption == '6' || this.state.adxSubCategoryOption == '9' || this.state.adxSubCategoryOption == '10' )
                                                             && <div className={classes.fullWidth}>
                                                                <Field
                                                                    validateOnBlur
                                                                    validateOnChange
                                                                    name="adxLevel"
                                                                    render={({ field, form }: any) => (
                                                                        <TextField
                                                                            label={"Level"}
                                                                            name={"adxLevel"}
                                                                            variant="filled"
                                                                            value={this.state.adxLevel}
                                                                            fullWidth
                                                                            size="small"
                                                                            type="number"
                                                                            margin="normal"
                                                                            InputProps={{
                                                                                disableUnderline: true,
                                                                                className: classes.input,
                                                                            }}
                                                                            InputLabelProps={{ shrink: true }}
                                                                            error={
                                                                                Boolean(form.errors.adxLevel && form.touched.adxLevel)
                                                                            }
                                                                            onChange={(e) => {
                                                                                this.setState({
                                                                                    adxLevel: e.target.value
                                                                                })
                                                                            }}
                                                                            onBlur={handleBlur}
                                                                            helperText={
                                                                                form.errors.adxLevel &&
                                                                                form.touched.adxLevel &&
                                                                                String(form.errors.adxLevel)
                                                                            }
                                                                        />
                                                                    )}
                                                                />
                                                            </div>}
                                                        </div>}
                                                        <div className={classes.fullWidth}>
                                                            <Field
                                                                validateOnBlur
                                                                validateOnChange
                                                                name="adxTimeFrame"
                                                                render={({ field, form }: any) => (
                                                                    <TextField
                                                                        id="adxTimeFrame"
                                                                        select
                                                                        label={`Select Time Frame`}
                                                                        value={this.state.adxTimeFrame}
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                adxTimeFrame: e.target.value
                                                                            })
                                                                        }}
                                                                        name={"adxTimeFrame"}
                                                                        fullWidth
                                                                        variant="filled"
                                                                        size="small"
                                                                        margin="normal"
                                                                        // hiddenLabel
                                                                        InputProps={{
                                                                            disableUnderline: true,
                                                                            className: classes.input,
                                                                            endAdornment:  
                                                                                <InputAdornment position="end" style={{ cursor: 'pointer', position: "absolute", paddingRight: 0, right: 25 }}>
                                                                                    <ClearIcon  onClick={() => { this.setState(
                                                                                    { adxTimeFrame: "" })}}/>
                                                                                </InputAdornment>
                                                                        }}
                                                                        InputLabelProps={{ shrink: true }}
                                                                        error={
                                                                            Boolean(form.errors.adxTimeFrame && form.touched.adxTimeFrame)
                                                                        }
                                                                        helperText={
                                                                            form.errors.adxTimeFrame &&
                                                                            form.touched.adxTimeFrame &&
                                                                            String(form.errors.adxTimeFrame)
                                                                        }
                                                                    // style={{ marginBottom: '15px' }}
                                                                    >
                                                                        { this.state.rsiTimeFrameList.map(( timeObj: any, index: number ) => (
                                                                            this.TimeFrameOptions(timeObj, index)
                                                                        ))}
                                                                        {alertBox()}
                                                                    </TextField>
                                                                )}
                                                            />
                                                        </div>
                                                    </div>}
                                                    {_data.name === 'OBV' && <div>
                                                        <div className={classes.fullWidth}>
                                                            <Field
                                                                validateOnBlur
                                                                validateOnChange
                                                                name="obvSubCategoryOption"
                                                                render={({ field, form }: any) => (
                                                                    <TextField
                                                                        id="obvSubCategoryOption"
                                                                        select
                                                                        label={`Select Screen`}
                                                                        value={this.state.obvSubCategoryOption}
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                obvSubCategoryOption: e.target.value
                                                                            })
                                                                        }}
                                                                        name={"obvSubCategoryOption"}
                                                                        fullWidth
                                                                        variant="filled"
                                                                        size="small"
                                                                        margin="normal"
                                                                        // hiddenLabel
                                                                        InputProps={{
                                                                            disableUnderline: true,
                                                                            className: classes.input,
                                                                            endAdornment:  
                                                                                <InputAdornment position="end" style={{ cursor: 'pointer', position: "absolute", paddingRight: 0, right: 25 }}>
                                                                                    <ClearIcon  onClick={() => { this.setState(
                                                                                    { obvSubCategoryOption: "" })}}/>
                                                                                </InputAdornment>
                                                                        }}
                                                                        InputLabelProps={{ shrink: true }}
                                                                        error={
                                                                            Boolean(form.errors.obvSubCategoryOption && form.touched.obvSubCategoryOption)
                                                                        }
                                                                        helperText={
                                                                            form.errors.obvSubCategoryOption &&
                                                                            form.touched.obvSubCategoryOption &&
                                                                            String(form.errors.obvSubCategoryOption)
                                                                        }
                                                                    // style={{ marginBottom: '15px' }}
                                                                    >
                                                                        {this.state.obvSubCategoryList.map((categoryObj: any, index: number) => (
                                                                            <MenuItem key={index} value={categoryObj.id}>
                                                                                <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '12px', whiteSpace: 'pre-line' }}>
                                                                                    {categoryObj.name}
                                                                                </Typography>
                                                                            </MenuItem>
                                                                        ))}
                                                                    </TextField>
                                                                )}
                                                            />
                                                        </div>
                                                        {(this.state.obvSubCategoryOption == '1' || this.state.obvSubCategoryOption == '2') && <div className={classes.fullWidth}>
                                                            <Field
                                                                validateOnBlur
                                                                validateOnChange
                                                                name="obvLevel"
                                                                render={({ field, form }: any) => (
                                                                    <TextField
                                                                        label={"Period"}
                                                                        name={"obvLevel"}
                                                                        variant="filled"
                                                                        type="number"
                                                                        value={this.state.obvLevel}
                                                                        fullWidth
                                                                        size="small"
                                                                        margin="normal"
                                                                        InputProps={{
                                                                            disableUnderline: true,
                                                                            className: classes.input,
                                                                        }}
                                                                        InputLabelProps={{ shrink: true }}
                                                                        error={
                                                                            Boolean(form.errors.obvLevel && form.touched.obvLevel)
                                                                        }
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                obvLevel: e.target.value
                                                                            })
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                        helperText={
                                                                            form.errors.obvLevel &&
                                                                            form.touched.obvLevel &&
                                                                            String(form.errors.obvLevel)
                                                                        }
                                                                    />
                                                                )}
                                                            />
                                                        </div>}
                                                        {(this.state.obvSubCategoryOption == '3') && <div className={classes.fullWidth}>
                                                            <Field
                                                                validateOnBlur
                                                                validateOnChange
                                                                name="obvDivergenceOption"
                                                                render={({ field, form }: any) => (
                                                                    <TextField
                                                                        id="obvDivergenceOption"
                                                                        select
                                                                        label={`Divergence`}
                                                                        value={this.state.obvDivergenceOption}
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                obvDivergenceOption: e.target.value
                                                                            })
                                                                        }}
                                                                        name={"obvDivergenceOption"}
                                                                        fullWidth
                                                                        variant="filled"
                                                                        size="small"
                                                                        margin="normal"
                                                                        // hiddenLabel
                                                                        InputProps={{
                                                                            disableUnderline: true,
                                                                            className: classes.input,
                                                                            endAdornment:  
                                                                                <InputAdornment position="end" style={{ cursor: 'pointer', position: "absolute", paddingRight: 0, right: 25 }}>
                                                                                    <ClearIcon  onClick={() => { this.setState(
                                                                                    { obvDivergenceOption: "" })}}/>
                                                                                </InputAdornment>
                                                                        }}
                                                                        InputLabelProps={{ shrink: true }}
                                                                        error={
                                                                            Boolean(form.errors.obvDivergenceOption && form.touched.obvDivergenceOption)
                                                                        }
                                                                        helperText={
                                                                            form.errors.obvDivergenceOption &&
                                                                            form.touched.obvDivergenceOption &&
                                                                            String(form.errors.obvDivergenceOption)
                                                                        }
                                                                    // style={{ marginBottom: '15px' }}
                                                                    >
                                                                        {this.state.obvDiverganceList.map((categoryObj: any, index: number) => (
                                                                            <MenuItem key={index} value={categoryObj.name}>
                                                                                <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '12px', whiteSpace: 'pre-line' }}>
                                                                                    {categoryObj.name}
                                                                                </Typography>
                                                                            </MenuItem>
                                                                        ))}
                                                                        {/* <MenuItem key={'bullish'} value={'bullish'}>
                                                                            <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '12px', whiteSpace: 'pre-line' }}>
                                                                                {'Bullish'}
                                                                            </Typography>
                                                                        </MenuItem> */}
                                                                    </TextField>
                                                                )}
                                                            />
                                                        </div>}
                                                        <div className={classes.fullWidth}>
                                                            <Field
                                                                validateOnBlur
                                                                validateOnChange
                                                                name="obvTimeFrame"
                                                                render={({ field, form }: any) => (
                                                                    <TextField
                                                                        id="obvTimeFrame"
                                                                        select
                                                                        label={`Select Time Frame`}
                                                                        value={this.state.obvTimeFrame}
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                obvTimeFrame: e.target.value
                                                                            })
                                                                        }}
                                                                        name={"obvTimeFrame"}
                                                                        fullWidth
                                                                        variant="filled"
                                                                        size="small"
                                                                        margin="normal"
                                                                        // hiddenLabel
                                                                        InputProps={{
                                                                            disableUnderline: true,
                                                                            className: classes.input,
                                                                            endAdornment:  
                                                                                <InputAdornment position="end" style={{ cursor: 'pointer', position: "absolute", paddingRight: 0, right: 25 }}>
                                                                                    <ClearIcon  onClick={() => { this.setState(
                                                                                    { obvTimeFrame: "" })}}/>
                                                                                </InputAdornment>
                                                                        }}
                                                                        InputLabelProps={{ shrink: true }}
                                                                        error={
                                                                            Boolean(form.errors.obvTimeFrame && form.touched.obvTimeFrame)
                                                                        }
                                                                        helperText={
                                                                            form.errors.obvTimeFrame &&
                                                                            form.touched.obvTimeFrame &&
                                                                            String(form.errors.obvTimeFrame)
                                                                        }
                                                                    // style={{ marginBottom: '15px' }}
                                                                    >
                                                                        { this.state.rsiTimeFrameList.map((timeObj: any, index: number) => (
                                                                            this.TimeFrameOptions(timeObj, index)
                                                                        )) }

                                                                        {alertBox()}
                                                                    </TextField>
                                                                )}
                                                            />
                                                        </div>
                                                    </div>}
                                                    {_data.name === 'CMF' && <div>
                                                        <div className={classes.fullWidth}>
                                                            <Field
                                                                validateOnBlur
                                                                validateOnChange
                                                                name="cmfSubCategoryOption"
                                                                render={({ field, form }: any) => (
                                                                    <TextField
                                                                        id="cmfSubCategoryOption"
                                                                        select
                                                                        label={`Select Screen`}
                                                                        value={this.state.cmfSubCategoryOption}
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                cmfSubCategoryOption: e.target.value
                                                                            })
                                                                        }}
                                                                        name={"cmfSubCategoryOption"}
                                                                        fullWidth
                                                                        variant="filled"
                                                                        size="small"
                                                                        margin="normal"
                                                                        // hiddenLabel
                                                                        InputProps={{
                                                                            disableUnderline: true,
                                                                            className: classes.input,
                                                                            endAdornment:  
                                                                                <InputAdornment position="end" style={{ cursor: 'pointer', position: "absolute", paddingRight: 0, right: 25 }}>
                                                                                    <ClearIcon  onClick={() => { this.setState(
                                                                                    { cmfSubCategoryOption: "" })}}/>
                                                                                </InputAdornment>
                                                                        }}
                                                                        InputLabelProps={{ shrink: true }}
                                                                        error={
                                                                            Boolean(form.errors.cmfSubCategoryOption && form.touched.cmfSubCategoryOption)
                                                                        }
                                                                        helperText={
                                                                            form.errors.cmfSubCategoryOption &&
                                                                            form.touched.cmfSubCategoryOption &&
                                                                            String(form.errors.cmfSubCategoryOption)
                                                                        }
                                                                    // style={{ marginBottom: '15px' }}
                                                                    >
                                                                        {this.state.cmfSubCategoryList.map((categoryObj: any, index: number) => (
                                                                            <MenuItem key={index} value={categoryObj.id}>
                                                                                <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '12px', whiteSpace: 'pre-line' }}>
                                                                                    {categoryObj.name}
                                                                                </Typography>
                                                                            </MenuItem>
                                                                        ))}
                                                                    </TextField>
                                                                )}
                                                            />
                                                        </div>
                                                        {(this.state.cmfSubCategoryOption == '5' || this.state.cmfSubCategoryOption == '6') && <div className={classes.fullWidth}>
                                                            <Field
                                                                validateOnBlur
                                                                validateOnChange
                                                                name="cmfPeriod"
                                                                render={({ field, form }: any) => (
                                                                    <TextField
                                                                        label={"Period"}
                                                                        name={"cmfPeriod"}
                                                                        variant="filled"
                                                                        type="number"
                                                                        value={this.state.cmfPeriod}
                                                                        fullWidth
                                                                        size="small"
                                                                        margin="normal"
                                                                        InputProps={{
                                                                            disableUnderline: true,
                                                                            className: classes.input,
                                                                        }}
                                                                        InputLabelProps={{ shrink: true }}
                                                                        error={
                                                                            Boolean(form.errors.cmfPeriod && form.touched.cmfPeriod)
                                                                        }
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                cmfPeriod: e.target.value
                                                                            })
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                        helperText={
                                                                            form.errors.cmfPeriod &&
                                                                            form.touched.cmfPeriod &&
                                                                            String(form.errors.cmfPeriod)
                                                                        }
                                                                    />
                                                                )}
                                                            />
                                                        </div>}
                                                        {(this.state.cmfSubCategoryOption == '7') && <div className={classes.fullWidth}>
                                                            <Field
                                                                validateOnBlur
                                                                validateOnChange
                                                                name="cmfDivergenceOption"
                                                                render={({ field, form }: any) => (
                                                                    <TextField
                                                                        id="cmfDivergenceOption"
                                                                        select
                                                                        label={`Divergence`}
                                                                        value={this.state.cmfDivergenceOption}
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                cmfDivergenceOption: e.target.value
                                                                            })
                                                                        }}
                                                                        name={"cmfDivergenceOption"}
                                                                        fullWidth
                                                                        variant="filled"
                                                                        size="small"
                                                                        margin="normal"
                                                                        // hiddenLabel
                                                                        InputProps={{
                                                                            disableUnderline: true,
                                                                            className: classes.input,
                                                                            endAdornment:  
                                                                                <InputAdornment position="end" style={{ cursor: 'pointer', position: "absolute", paddingRight: 0, right: 25 }}>
                                                                                    <ClearIcon  onClick={() => { this.setState(
                                                                                    { cmfDivergenceOption: "" })}}/>
                                                                                </InputAdornment>
                                                                        }}
                                                                        InputLabelProps={{ shrink: true }}
                                                                        error={
                                                                            Boolean(form.errors.cmfDivergenceOption && form.touched.cmfDivergenceOption)
                                                                        }
                                                                        helperText={
                                                                            form.errors.cmfDivergenceOption &&
                                                                            form.touched.cmfDivergenceOption &&
                                                                            String(form.errors.cmfDivergenceOption)
                                                                        }
                                                                    // style={{ marginBottom: '15px' }}
                                                                    >
                                                                        <MenuItem key={'bullish'} value={'bullish'}>
                                                                            <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '12px', whiteSpace: 'pre-line' }}>
                                                                                {'Bullish'}
                                                                            </Typography>
                                                                        </MenuItem>
                                                                        <MenuItem key={'bearish'} value={'bearish'}>
                                                                            <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '12px', whiteSpace: 'pre-line' }}>
                                                                                {'Bearish'}
                                                                            </Typography>
                                                                        </MenuItem>
                                                                    </TextField>
                                                                )}
                                                            />
                                                        </div>}
                                                        {(this.state.cmfSubCategoryOption == '5' || this.state.cmfSubCategoryOption == '6' || this.state.cmfSubCategoryOption == '7') && <div className={classes.fullWidth}>
                                                            <Field
                                                                validateOnBlur
                                                                validateOnChange
                                                                name="cmfTimeFrame"
                                                                render={({ field, form }: any) => (
                                                                    <TextField
                                                                        id="cmfTimeFrame"
                                                                        select
                                                                        label={`Select Time Frame`}
                                                                        value={this.state.cmfTimeFrame}
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                cmfTimeFrame: e.target.value
                                                                            })
                                                                        }}
                                                                        name={"cmfTimeFrame"}
                                                                        fullWidth
                                                                        variant="filled"
                                                                        size="small"
                                                                        margin="normal"
                                                                        // hiddenLabel
                                                                        InputProps={{
                                                                            disableUnderline: true,
                                                                            className: classes.input,
                                                                            endAdornment:  
                                                                                <InputAdornment position="end" style={{ cursor: 'pointer', position: "absolute", paddingRight: 0, right: 25 }}>
                                                                                    <ClearIcon  onClick={() => { this.setState(
                                                                                    { cmfTimeFrame: "" })}}/>
                                                                                </InputAdornment>
                                                                        }}
                                                                        InputLabelProps={{ shrink: true }}
                                                                        error={
                                                                            Boolean(form.errors.cmfTimeFrame && form.touched.cmfTimeFrame)
                                                                        }
                                                                        helperText={
                                                                            form.errors.cmfTimeFrame &&
                                                                            form.touched.cmfTimeFrame &&
                                                                            String(form.errors.cmfTimeFrame)
                                                                        }
                                                                    // style={{ marginBottom: '15px' }}
                                                                    >
                                                                        {this.state.rsiTimeFrameList.map((timeFrameObj: any, indx: number) => (
                                                                            this.TimeFrameOptions(timeFrameObj, indx)
                                                                        ))}
                                                                        {alertBox()}
                                                                    </TextField>
                                                                )}
                                                            />
                                                        </div>}
                                                    </div>}
                                                    {_data.name === 'Bollinger Bands' && <div>
                                                        <div className={classes.fullWidth}>
                                                            <Field
                                                                validateOnBlur
                                                                validateOnChange
                                                                name="bollingerBandsSubCategoryOption"
                                                                render={({ field, form }: any) => (
                                                                    <TextField
                                                                        id="bollingerBandsSubCategoryOption"
                                                                        select
                                                                        label={`Select Screen`}
                                                                        value={this.state.bollingerBandsSubCategoryOption}
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                bollingerBandsSubCategoryOption: e.target.value
                                                                            })
                                                                        }}
                                                                        name={"bollingerBandsSubCategoryOption"}
                                                                        fullWidth
                                                                        variant="filled"
                                                                        size="small"
                                                                        margin="normal"
                                                                        // hiddenLabel
                                                                        InputProps={{
                                                                            disableUnderline: true,
                                                                            className: classes.input,
                                                                            endAdornment:  
                                                                                <InputAdornment position="end" style={{ cursor: 'pointer', position: "absolute", paddingRight: 0, right: 25 }}>
                                                                                    <ClearIcon  onClick={() => { this.setState(
                                                                                    { bollingerBandsSubCategoryOption: "" })}}/>
                                                                                </InputAdornment>
                                                                        }}
                                                                        InputLabelProps={{ shrink: true }}
                                                                        error={
                                                                            Boolean(form.errors.bollingerBandsSubCategoryOption && form.touched.bollingerBandsSubCategoryOption)
                                                                        }
                                                                        helperText={
                                                                            form.errors.bollingerBandsSubCategoryOption &&
                                                                            form.touched.bollingerBandsSubCategoryOption &&
                                                                            String(form.errors.bollingerBandsSubCategoryOption)
                                                                        }
                                                                    // style={{ marginBottom: '15px' }}
                                                                    >
                                                                        {this.state.bollingerBandsSubCategoryList.map((categoryObj: any, index: number) => (
                                                                            <MenuItem key={index} value={categoryObj.id}>
                                                                                <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '12px', whiteSpace: 'pre-line' }}>
                                                                                    {categoryObj.name}
                                                                                </Typography>
                                                                            </MenuItem>
                                                                        ))}
                                                                    </TextField>
                                                                )}
                                                            />
                                                        </div>
                                                        {(this.state.bollingerBandsSubCategoryOption == '4' || this.state.bollingerBandsSubCategoryOption == '5' ||
                                                            this.state.bollingerBandsSubCategoryOption == '6') && <div style={{ marginLeft: '12px' }}>
                                                                <FormControl component="fieldset">
                                                                    <FormLabel component="legend"></FormLabel>
                                                                    <RadioGroup row name={"bollingerBandsRadioGroup"} value={this.state.bollingerBandsRadioGroup.toString()} onChange={(event) => {
                                                                        this.setState({ bollingerBandsRadioGroup: event.currentTarget.value })
                                                                        setFieldValue("bollingerBandsRadioGroup", event.currentTarget.value)
                                                                    }}>
                                                                        <FormControlLabel value={"above"} control={<Radio style={{ color: 'green' }} />} label={<span style={{ fontSize: '12px' }}>{'Above'}</span>} />
                                                                        <FormControlLabel value={"below"} control={<Radio style={{ color: 'green' }} />} label={<span style={{ fontSize: '12px' }}>{'Below'}</span>} />
                                                                    </RadioGroup>
                                                                </FormControl>
                                                            </div>
                                                        }
                                                        <div className={classes.fullWidth}>
                                                            <Field
                                                                validateOnBlur
                                                                validateOnChange
                                                                name="bollingerBandsTimeFrame"
                                                                render={({ field, form }: any) => (
                                                                    <TextField
                                                                        id="bollingerBandsTimeFrame"
                                                                        select
                                                                        label={`Select Time Frame`}
                                                                        value={this.state.bollingerBandsTimeFrame}
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                bollingerBandsTimeFrame: e.target.value
                                                                            })
                                                                        }}
                                                                        name={"bollingerBandsTimeFrame"}
                                                                        fullWidth
                                                                        variant="filled"
                                                                        size="small"
                                                                        margin="normal"
                                                                        // hiddenLabel
                                                                        InputProps={{
                                                                            disableUnderline: true,
                                                                            className: classes.input,
                                                                            endAdornment:  
                                                                                <InputAdornment position="end" style={{ cursor: 'pointer', position: "absolute", paddingRight: 0, right: 25 }}>
                                                                                    <ClearIcon  onClick={() => { this.setState(
                                                                                    { bollingerBandsTimeFrame: "" })}}/>
                                                                                </InputAdornment>
                                                                        }}
                                                                        InputLabelProps={{ shrink: true }}
                                                                        error={
                                                                            Boolean(form.errors.bollingerBandsTimeFrame && form.touched.bollingerBandsTimeFrame)
                                                                        }
                                                                        helperText={
                                                                            form.errors.bollingerBandsTimeFrame &&
                                                                            form.touched.bollingerBandsTimeFrame &&
                                                                            String(form.errors.bollingerBandsTimeFrame)
                                                                        }
                                                                    // style={{ marginBottom: '15px' }}
                                                                    >
                                                                        {this.state.rsiTimeFrameList.map((timeObj: any, index: number) => (
                                                                            this.TimeFrameOptions(timeObj, index)
                                                                        ))}

                                                                        {alertBox()}
                                                                    </TextField>
                                                                )}
                                                            />
                                                        </div>
                                                    </div>}
                                                    {_data.name === 'ATR' && <div>
                                                        <div className={classes.fullWidth}>
                                                            <Field
                                                                validateOnBlur
                                                                validateOnChange
                                                                name="atrSubCategoryOption"
                                                                render={({ field, form }: any) => (
                                                                    <TextField
                                                                        id="atrSubCategoryOption"
                                                                        select
                                                                        label={`Select Screen`}
                                                                        value={this.state.atrSubCategoryOption}
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                atrSubCategoryOption: e.target.value
                                                                            })
                                                                        }}
                                                                        name={"atrSubCategoryOption"}
                                                                        fullWidth
                                                                        variant="filled"
                                                                        size="small"
                                                                        margin="normal"
                                                                        // hiddenLabel
                                                                        InputProps={{
                                                                            disableUnderline: true,
                                                                            className: classes.input,
                                                                            endAdornment:  
                                                                                <InputAdornment position="end" style={{ cursor: 'pointer', position: "absolute", paddingRight: 0, right: 25 }}>
                                                                                    <ClearIcon  onClick={() => { this.setState(
                                                                                    { atrSubCategoryOption: "" })}}/>
                                                                                </InputAdornment>
                                                                        }}
                                                                        InputLabelProps={{ shrink: true }}
                                                                        error={
                                                                            Boolean(form.errors.atrSubCategoryOption && form.touched.atrSubCategoryOption)
                                                                        }
                                                                        helperText={
                                                                            form.errors.atrSubCategoryOption &&
                                                                            form.touched.atrSubCategoryOption &&
                                                                            String(form.errors.atrSubCategoryOption)
                                                                        }
                                                                    // style={{ marginBottom: '15px' }}
                                                                    >
                                                                        {this.state.atrSubCategoryList.map((categoryObj: any, index: number) => (
                                                                            <MenuItem key={index} value={categoryObj.id}>
                                                                                <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '12px', whiteSpace: 'pre-line' }}>
                                                                                    {categoryObj.name}
                                                                                </Typography>
                                                                            </MenuItem>
                                                                        ))}
                                                                    </TextField>
                                                                )}
                                                            />
                                                        </div>
                                                        <div className={classes.fullWidth}>
                                                            <Field
                                                                validateOnBlur
                                                                validateOnChange
                                                                name="atrPeriod"
                                                                render={({ field, form }: any) => (
                                                                    <TextField
                                                                        label={"Period"}
                                                                        name={"atrPeriod"}
                                                                        type="number"
                                                                        variant="filled"
                                                                        value={this.state.atrPeriod}
                                                                        fullWidth
                                                                        size="small"
                                                                        margin="normal"
                                                                        InputProps={{ disableUnderline: true, className: classes.input }}
                                                                        error={
                                                                            Boolean(form.errors.atrPeriod && form.touched.atrPeriod)
                                                                        }
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                atrPeriod: e.target.value
                                                                            })
                                                                        }}
                                                                        InputLabelProps={{ shrink: true }}
                                                                        onBlur={handleBlur}
                                                                        helperText={
                                                                            form.errors.atrPeriod &&
                                                                            form.touched.atrPeriod &&
                                                                            String(form.errors.atrPeriod)
                                                                        }
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                        <div className={classes.fullWidth}>
                                                            <Field
                                                                validateOnBlur
                                                                validateOnChange
                                                                name="atrTrendingPeriod"
                                                                render={({ field, form }: any) => (
                                                                    <TextField
                                                                        label={"Trending Period"}
                                                                        name={"atrTrendingPeriod"}
                                                                        variant="filled"
                                                                        value={this.state.atrTrendingPeriod}
                                                                        fullWidth
                                                                        type="number"
                                                                        size="small"
                                                                        margin="normal"
                                                                        InputProps={{
                                                                            disableUnderline: true,
                                                                            className: classes.input,
                                                                        }}
                                                                        error={
                                                                            Boolean(form.errors.atrPeriod && form.touched.atrPeriod)
                                                                        }
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                atrTrendingPeriod: e.target.value
                                                                            })
                                                                        }}
                                                                        InputLabelProps={{ shrink: true }}
                                                                        onBlur={handleBlur}
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                        <div className={classes.fullWidth}>
                                                            <Field
                                                                validateOnBlur
                                                                validateOnChange
                                                                name="atrTimeFrame"
                                                                render={({ field, form }: any) => (
                                                                    <TextField
                                                                        id="atrTimeFrame"
                                                                        select
                                                                        label={`Select Time Frame`}
                                                                        value={this.state.atrTimeFrame}
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                atrTimeFrame: e.target.value
                                                                            })
                                                                        }}
                                                                        name={"atrTimeFrame"}
                                                                        fullWidth
                                                                        variant="filled"
                                                                        size="small"
                                                                        margin="normal"
                                                                        // hiddenLabel
                                                                        InputProps={{
                                                                            disableUnderline: true,
                                                                            className: classes.input,
                                                                            endAdornment:  
                                                                                <InputAdornment position="end" style={{ cursor: 'pointer', position: "absolute", paddingRight: 0, right: 25 }}>
                                                                                    <ClearIcon  onClick={() => { this.setState(
                                                                                    { atrTimeFrame: "" })}}/>
                                                                                </InputAdornment>
                                                                        }}
                                                                        InputLabelProps={{ shrink: true }}
                                                                        error={
                                                                            Boolean(form.errors.atrTimeFrame && form.touched.atrTimeFrame)
                                                                        }
                                                                        helperText={
                                                                            form.errors.atrTimeFrame &&
                                                                            form.touched.atrTimeFrame &&
                                                                            String(form.errors.atrTimeFrame)
                                                                        }
                                                                    // style={{ marginBottom: '15px' }}
                                                                    >
                                                                        {this.state.rsiTimeFrameList.map((timeFrameObj: any, index: number) => (
                                                                            this.TimeFrameOptions(timeFrameObj, index)
                                                                        ))}
                                                                        {alertBox()}
                                                                    </TextField>
                                                                )}
                                                            />
                                                        </div>
                                                    </div>}
                                                </div>
                                            )
                                        })
                                    }
                                    {this.state.indicatorsOptionList.find((option: any) => option.disabled) && <div style={{ position: 'absolute', top: topForUpgradeMessage(), left: 25}}><UpgradeAlert /></div>}
                                </FormGroup>
                            </FormControl>
                            { filterNamePopup && <SaveFilterName classes={classes} filterName={filterName} 
                            open={filterNamePopup} handleSubmit={handleSubmit} handleChange={(event: any) => 
                            this.setState({ filterName: event.target.value })} handleClickedBtn={this.handleClickedBtn}
                            handleCancel={() => this.setState({ filterNamePopup: false, filterName: "" })} /> }
                            <ButtonGroup fullWidth className={classes.buttonGroup}>
                                {this.state.token !== "" && <Button
                                    disabled={this.disableIndicatorSaveButton()} 
                                    data-testid='indicatorSaveButton' type="submit" 
                                    className={classes.submitButton}
                                    onClick={() => this.handleClickedBtn('SaveRun')}>
                                Save & Run</Button>}
                                <Button
                                    disabled={this.disableIndicatorSaveButton()} 
                                    data-testid='indicatorRunButton' type="submit" 
                                    className={classes.submitButton}
                                    onClick={() => this.handleClickedBtn('Run')}>
                                Run</Button>
                            </ButtonGroup>
                        </Form>
                    )}
                </Formik>
            </Popover>
        )
    }

    derivatives = () => {

        const { activeItem } = this.state;
        const { classes } = this.props;

        return (
            <Popover
                open={Boolean(this.state.anchorEl) && activeItem == 'Derivatives'}
                anchorEl={this.state.anchorEl}
                onClose={this.handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                disableAutoFocus={true}
                disableEnforceFocus={true}
                PaperProps={{
                    className: classes.popoverPaper,
                }}
            >
                <Formik
                    initialValues={{
                        derivativesCategory: "",
                        derivativesOption: "",
                        customDerivativesOptionVal: "",
                        putCallRatioFrom: "",
                        putCallRationTo: ""
                    }}
                    enableReinitialize
                    validationSchema={derivativesSchema}
                    onSubmit={(values, { resetForm, setSubmitting }) => {
                        this.derivativesForm(values);
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                    }) => (
                        <Form onSubmit={handleSubmit}>
                            <div className={classes.fullWidth}>
                                <Field
                                    validateOnBlur
                                    validateOnChange
                                    name="derivativesCategory"
                                    render={({ field, form }: any) => (
                                        <TextField
                                            id="derivativesCategory"
                                            select
                                            label={`Select Derivatives Category`}
                                            value={values.derivativesCategory}
                                            onChange={handleChange("derivativesCategory")}
                                            name={"derivativesCategory"}
                                            fullWidth
                                            variant="filled"
                                            size="small"
                                            margin="normal"
                                            // hiddenLabel
                                            InputProps={{
                                                disableUnderline: true,
                                                className: classes.input
                                            }}
                                            InputLabelProps={{ shrink: true }}
                                            error={
                                                Boolean(form.errors.derivativesCategory && form.touched.derivativesCategory)
                                            }
                                            helperText={
                                                form.errors.derivativesCategory &&
                                                form.touched.derivativesCategory &&
                                                String(form.errors.derivativesCategory)
                                            }
                                        // style={{ marginBottom: '15px' }}
                                        >
                                            {this.state.derivativesCategoryList.map((categoryObj: any, index: number) => (
                                                <MenuItem key={index} value={categoryObj.id}>
                                                    <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '12px', whiteSpace: 'pre-line' }}>
                                                        {categoryObj.name}
                                                    </Typography>
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    )}
                                />
                            </div>
                            {
                                (values.derivativesCategory == '1' || values.derivativesCategory == '2' ||
                                    values.derivativesCategory == '3' || values.derivativesCategory == '4') && <div className={classes.fullWidth}>
                                    <Field
                                        validateOnBlur
                                        validateOnChange
                                        name="derivativesOption"
                                        render={({ field, form }: any) => (
                                            <TextField
                                                id="derivativesOption"
                                                select
                                                label="Select Derivatie Option"
                                                value={values.derivativesOption}
                                                onChange={(event) => {
                                                    setFieldValue("derivativesOption", event.target.value);
                                                }}
                                                fullWidth
                                                variant="filled"
                                                size="small"
                                                margin="normal"
                                                // hiddenLabel
                                                InputProps={{
                                                    disableUnderline: true,
                                                    className: classes.input
                                                }}
                                                InputLabelProps={{ shrink: true }}
                                                error={
                                                    Boolean(form.errors.derivativesOption && form.touched.derivativesOption)
                                                }
                                                helperText={
                                                    form.errors.derivativesOption &&
                                                    form.touched.derivativesOption &&
                                                    String(form.errors.derivativesOption)
                                                }
                                            >
                                                {this.state.derivativesOptionList.map((_data: any, key: any) => (
                                                    <MenuItem key={key} value={_data.id}>
                                                        <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '12px', whiteSpace: 'pre-line' }}>
                                                            {`${_data.name}`}
                                                        </Typography>
                                                    </MenuItem>
                                                ))}
                                                <MenuItem key={0} value={0}>
                                                    <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '12px', whiteSpace: 'pre-line' }}>
                                                        {`Custom`}
                                                    </Typography>
                                                </MenuItem>
                                            </TextField>
                                        )}
                                    />
                                </div>
                            }
                            {
                                values.derivativesOption == '0' && <div className={classes.fullWidth}>
                                    <Field
                                        validateOnBlur
                                        validateOnChange
                                        name="customDerivativesOptionVal"
                                        render={({ field, form }: any) => (
                                            <TextField
                                                label={"Rank"}
                                                name={"customDerivativesOptionVal"}
                                                variant="filled"
                                                value={values.customDerivativesOptionVal}
                                                fullWidth
                                                size="small"
                                                margin="normal"
                                                InputProps={{ disableUnderline: true, style: { fontSize: '12px' } }}
                                                // error={
                                                //     Boolean(form.errors.customDerivativesOptionVal && form.touched.customDerivativesOptionVal)
                                                // }
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                helperText={
                                                    form.errors.customDerivativesOptionVal &&
                                                    form.touched.customDerivativesOptionVal &&
                                                    String(form.errors.customDerivativesOptionVal)
                                                }
                                            />
                                        )}
                                    />
                                </div>
                            }
                            {
                                values.derivativesCategory == '5' && <div className={classes.fullWidth}>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div className={classes.fullWidth}>
                                            <Field
                                                validateOnBlur
                                                validateOnChange
                                                name="putCallRatioFrom"
                                                render={({ field, form }: any) => (
                                                    <TextField
                                                        label={"From"}
                                                        name={"putCallRatioFrom"}
                                                        variant="filled"
                                                        value={values.putCallRatioFrom}
                                                        fullWidth
                                                        size="small"
                                                        margin="normal"
                                                        InputProps={{ disableUnderline: true, style: { fontSize: '12px' } }}
                                                        error={
                                                            Boolean(form.errors.putCallRatioFrom && form.touched.putCallRatioFrom)
                                                        }
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        helperText={
                                                            form.errors.putCallRatioFrom &&
                                                            form.touched.putCallRatioFrom &&
                                                            String(form.errors.putCallRatioFrom)
                                                        }
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div className={classes.fullWidth} style={{ marginLeft: '15px' }}>
                                            <Field
                                                validateOnBlur
                                                validateOnChange
                                                name="putCallRationTo"
                                                render={({ field, form }: any) => (
                                                    <TextField
                                                        label={"To"}
                                                        name={"putCallRationTo"}
                                                        variant="filled"
                                                        value={values.putCallRationTo}
                                                        fullWidth
                                                        size="small"
                                                        margin="normal"
                                                        InputProps={{ disableUnderline: true, style: { fontSize: '12px' } }}
                                                        error={
                                                            Boolean(form.errors.putCallRationTo && form.touched.putCallRationTo)
                                                        }
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        helperText={
                                                            form.errors.putCallRationTo &&
                                                            form.touched.putCallRationTo &&
                                                            String(form.errors.putCallRationTo)
                                                        }
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                            <button type="submit" className={classes.submitButton}>Save</button>
                        </Form>
                    )}
                </Formik>
            </Popover>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const {
            screenerData,
            selectedItemData,
            rankResponseData,
            socialEngagementResponseData,
            paginationPage,
            paginationRowsPerPage,
            handleMoreResult,
            moreResultPriceAction,
            showHighLowPoints,
            rankCategoryPaginationPage,
            rankCategoryPaginationRowsPerPage,
            lastVolumnPaginationPage,
            lastVolumnPaginationRowsPerPage,
            loading,
            isLoading,
            navigateCategory,
            rankResponseEmpty,
            socialEngagementResponseEmpty,
            filterNamePopup,
            filterName,
            clickedBtn,
            showCustomFilter,
            customFilterLimitExceed
        } = this.state;
        const themeBasedColor: string = localStorage.getItem('appTheme') == 'darkTheme' ? '#232429' : '#F4F8F6' ;
        const showLoader = loading || isLoading;
        return (
            <Paper className={classes.root} square style={{ backgroundColor: themeBasedColor }}>
                { customFilterLimitExceed && <NoSubscriptionDialogWeb
                    message="In free plan, You have limited access to add custom filters, If you want to add more filters please upgrade to pro plan"
                    buttonText="Cancel"
                    handleCancel={() => this.setState({ customFilterLimitExceed: false })}
                />}
                <div className={classes.mainDiv} >
                    <div className={classes.leftDiv}>
                        <Paper className={classes.leftContainer} elevation={2} style={{ backgroundColor: themeBasedColor }}>
                            {
                                screenerData.map((item: any, keyStr: any) => this.CustomMenuItem({ item, keyStr }))
                            }
                            { this.state.token !== "" && <Button
                                onClick={() => this.handleClickedBtn('SaveMultiple')}
                                className={classes.submitButton}
                                style={{ width: '90%' }}
                                disabled={this.disableSaveMultiFilterButton()}
                                data-testid="saveCustomFilter"
                                >Save Multi Filters</Button> }
                                { filterNamePopup && clickedBtn === "SaveMultiple" && <SaveFilterName classes={classes} filterName={filterName} 
                                open={filterNamePopup} handleSubmit={this.handleMultipleFilterSubmit} handleChange={(event: any) => 
                                this.setState({ filterName: event.target.value })} handleClickedBtn={this.handleClickedBtn}
                                handleCancel={() => this.setState({ filterNamePopup: false, filterName: "" })} /> } 
                        </Paper>
                    </div>
                    <div className={classes.rightDiv}>
                        <Paper className={classes.rightContainer} elevation={2} style={{ backgroundColor: themeBasedColor }}>
                            <div className={classes.toggleBtnDiv} >
                                <ToggleButtonGroup
                                    color="primary"
                                    style={{ flexWrap: 'wrap' }}
                                    value={navigateCategory}
                                    exclusive
                                    onChange={this.navigateCategoryHandleChange}
                                    aria-label="Platform"
                                >
                                    <ToggleButton className={classes.toggleButton} value="Screener">Screener</ToggleButton>
                                    <ToggleButton className={classes.toggleButton} value="Watchlist">Watchlist</ToggleButton>
                                    <ToggleButton className={classes.toggleButton} value="Alerts">Alerts</ToggleButton>
                                    <ToggleButton className={classes.toggleButton} value="Charts">Charts</ToggleButton>
                                    <ToggleButton className={classes.toggleButton} value="SavedFilter">Saved Filter</ToggleButton>
                                </ToggleButtonGroup>
                            </div>
                            { navigateCategory === 'Screener' && !handleMoreResult && !moreResultPriceAction && <div style={{ padding: '22px', marginBottom: '16px' }}>
                                <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '18px', marginBottom: '10px' }}>
                                    {selectedItemData}
                                </Typography>
                                {showLoader && <Loader loading={showLoader} />}

                                {/* Price Action Table */}
                                {<PriceActionTable navigation={undefined} id={""} classes={classes} {...this} />}
                                {
                                    !rankResponseEmpty && (rankResponseData.length > 0 ? <Paper>
                                        <FilterChip filteredChip={this.state.filteredChip} classes={classes} handleFilterDelete={this.handleFilterDelete} />
                                        <Divider />
                                        <FundamentalRankResponse
                                            rankResponseData={rankResponseData}
                                            paginationPage={paginationPage}
                                            paginationRowsPerPage={paginationRowsPerPage}
                                            openCategoryPopup={this.openCategoryPopup}
                                            handleMorePopup={this.handleMorePopup}
                                        />
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 25]}
                                            component="div"
                                            count={rankResponseData.length}
                                            rowsPerPage={paginationRowsPerPage}
                                            page={paginationPage}
                                            onPageChange={this.handleFundamentalChangePage}
                                            onRowsPerPageChange={this.handleFundamentalChangeRowsPerPage}
                                        />
                                    </Paper> :
                                    <Typography style={{ textAlign: 'center', fontSize: '18px', fontWeight: 'bold' }} > No Data Found </Typography>)
                                }
                                {/* Indicators Table */}
                                {<IndicatorsTable {...this} classes={classes} />}

                                {/* Volume Table */}
                                {<VolumeTable {...this} classes={classes} />}
                                {showCustomFilter && <CustomFilterScreener {...this} classes={classes} />}
                            </div>}
                            {navigateCategory === 'Watchlist' && <WatchListWeb navigation={null} id={this.state.selectedRowObj.id} />}
                            {navigateCategory === 'Alerts' && <DipAlertWeb navigation={null} id='' />}
                            {navigateCategory === 'Charts' && <SearchChartWeb navigation={null} id='' />}
                            {navigateCategory === 'SavedFilter' && <SavedFiltersWeb navigation={null} id='' 
                            handleItemClick={this.handleItemClick} fundamentalsForm={this.fundamentalsForm} 
                            volumeForm={this.volumeForm} priceActionForm={this.priceActionForm}
                            indicatorForm={this.indicatorsForm} filterState={this.state}
                            navigateCustomFilterCoinListing={this.navigateCustomFilterCoinListing}
                             navigateCategory={this.navigateCategoryForSavedFilters} getCustomFilterApi={this.getCustomSavedFilter} />}
                            {handleMoreResult && <CryptoDetailWeb navigation={null} id={this.state.selectedRowObj.id} closeMore={this.closeMore} />}
                            {moreResultPriceAction && <CryptoDetailWeb navigation={null} id={this.state.currentSelectedMoreData.crypto_data ? this.state.currentSelectedMoreData.crypto_data.id : this.state.currentSelectedMoreData.id} closeMore={this.closeMore} showHighLowPoints={showHighLowPoints} datapoints={this.state.currentSelectedMoreData} />}
                        </Paper>
                    </div>
                </div>
                {(() => {
                    switch (this.state.activeItem) {
                        case "Fundamentals":
                            return this.fundamentals();
                        case "Volumes":
                            return this.volumes();
                        case "Price Action":
                            return this.priceActions()
                        case "Indicators":
                            return this.indicators();
                        case "Derivatives":
                            return this.derivatives();
                    }
                })()}
                <Dialog
                    open={this.state.openCategoryPopup}
                    onClose={this.cancelCategoryPopup}
                    aria-labelledby="customized-dialog-title"
                    fullWidth={true}
                    maxWidth={'lg'}
                >
                    <DialogTitle id="customized-dialog-title">
                        {"Category Details"}
                        <span style={{ position: "absolute", right: "16px", cursor: 'pointer' }}>
                            <CloseIcon onClick={this.cancelCategoryPopup} />
                        </span>
                    </DialogTitle>
                    <DialogContent>
                        <TableContainer>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>#</TableCell>
                                        <TableCell align="left" style={{ fontSize: '12px', whiteSpace: 'nowrap' }}>Name</TableCell>
                                        <TableCell align="left" style={{ fontSize: '12px', whiteSpace: 'nowrap' }}>Avg Price</TableCell>
                                        <TableCell align="left" style={{ fontSize: '12px', whiteSpace: 'nowrap' }}>Volume</TableCell>
                                        <TableCell align="left" style={{ fontSize: '12px', whiteSpace: 'nowrap' }}>Volume Change</TableCell>
                                        <TableCell align="left" style={{ fontSize: '12px', whiteSpace: 'nowrap' }}>Market Cap</TableCell>
                                        <TableCell align="left" style={{ fontSize: '12px', whiteSpace: 'nowrap' }}>Market Cap Change</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(rankCategoryPaginationRowsPerPage > 0
                                        ? this.state.categoryResponseList.slice(rankCategoryPaginationPage * rankCategoryPaginationRowsPerPage, rankCategoryPaginationPage * rankCategoryPaginationRowsPerPage + rankCategoryPaginationRowsPerPage)
                                        : this.state.categoryResponseList
                                    ).map((rowObject: any, keyStr: any) => (
                                        <TableRow
                                            key={rowObject.id}
                                        >
                                            <TableCell component="th" scope="row">
                                                {(rankCategoryPaginationPage * rankCategoryPaginationRowsPerPage) + (keyStr + 1)}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                                                    {`${rowObject?.name}`}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="left">
                                                <UpDownIconButton value={rowObject.avg_price_change} />
                                            </TableCell>
                                            <TableCell align="left">
                                                <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                                                    {`${rowObject?.volume}`}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="left">
                                                <UpDownIconButton value={rowObject.volume_change} />
                                            </TableCell>
                                            <TableCell align="left">
                                                <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                                                    {`${rowObject?.market_cap}`}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="left">
                                                <UpDownIconButton value={rowObject.market_cap_change} />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={this.state.categoryResponseList.length}
                            rowsPerPage={rankCategoryPaginationRowsPerPage}
                            page={rankCategoryPaginationPage}
                            onPageChange={this.handleRankCategoryChangePage}
                            onRowsPerPageChange={this.handleRankCategoryChangeRowsPerPage}
                        />
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.volumeUpAndDownPopup}
                    onClose={this.cancelVolumePopup}
                    aria-labelledby="customized-dialog-title"
                    fullWidth={true}
                    maxWidth={'lg'}
                >
                    <DialogTitle id="customized-dialog-title">
                        {"Last Volume"}
                        <span style={{ position: "absolute", right: "16px", cursor: 'pointer' }}>
                            <CloseIcon onClick={this.cancelVolumePopup} />
                        </span>
                    </DialogTitle>
                    <DialogContent>
                        <VolumePopup lastVolumeResponseData={this.state.lastVolumeResponseData}
                            lastVolumnPaginationRowsPerPage={lastVolumnPaginationRowsPerPage}
                            lastVolumnPaginationPage={lastVolumnPaginationPage}
                            onPageChange={this.handleLastVolumeChangePage}
                            onRowsPerPageChange={this.handleLastVolumeChangeRowsPerPage} />
+                    </DialogContent>
                </Dialog>
            </Paper>
        )
        // Customizable Area End
    }
}

export default withStyles(styles)(ScreenerWeb);
// Customizable Area Start
const PrettoSlider = withStyles({
    root: {
        color: '#52af77',
        height: 8,
    },
    thumb: {
        height: 16,
        width: 16,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        marginTop: -4,
        marginLeft: 0,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 8,
        borderRadius: 4,
    },
    rail: {
        height: 8,
        borderRadius: 4,
    },
})(Slider);
// Customizable Area End

import React from "react";
// Customizable Area Start
import AppDownloadWebController, {
    Props
} from "./AppDownloadController.web";

import {
    withStyles, StyleRules
} from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
// Customizable Area End
const styles: StyleRules = {
    // Customizable Area Start
    root: {
        height: '100vh'
    }
    // Customizable Area End
}
// Customizable Area Start
// Customizable Area End

export class AppDownloadWeb extends AppDownloadWebController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        return (
            <Paper className={classes.root} square>
                App Download web page
            </Paper>
        )
        // Customizable Area End
    }
}

export default withStyles(styles)(AppDownloadWeb);

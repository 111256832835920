import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Switch from '@material-ui/core/Switch';
import {
    FormControlLabel,
} from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

interface CardViewProps {
    token: string;
    classes: any;
    cardData: any;
    isCardPrimaryChange(event: any, selectedCard: any, flag: any): void;
    deleteCard: any;
    createOrUpdateCardDetailsFlag(flag: string, selectedCard: any): void;
}

interface DialogProps {
    classes: any;
    showDialog: boolean;
    handleConfirm: any;
}

const DialogWeb:React.FC<DialogProps>  = ({ classes, showDialog, handleConfirm }: DialogProps) => {
    return (
        <Dialog open={showDialog} data-testid="close-dialog"  aria-labelledby="delete-confirmation-dialog">
            <DialogTitle className={classes.dialogTitle}>
                Delete Card
            </DialogTitle>
            <DialogContent style={{ textAlign: 'center', maxWidth:300, paddingTop: 0 }}>
                <Typography>Are you sure you want to delete this card ?</Typography>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <button 
                    className={classes.noDeleteButton} 
                    style={{ background: localStorage.getItem('appTheme') == 'darkTheme' ? '#363333' : '#181a19' }} 
                    onClick={(e: any) =>  handleConfirm(false) }>
                    No, don't delete
                </button>
                <button className={classes.deleteButton} onClick={(e: any) => handleConfirm(true) }>Yes, delete</button>
            </DialogActions>
        </Dialog>
    )
}

const GridView = ({ token, classes, cardData, isCardPrimaryChange, deleteCard, createOrUpdateCardDetailsFlag }: CardViewProps) => {
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [data, setData] = useState<any>();

    const generateColor = () => {
        const randomColor = Math.floor(Math.random() * 16777211)
            .toString(16)
            .padStart(6, '0');
        return `#${randomColor}`;
    };

    const formatMonth = (month: number) => {
        return month < 10 ? `0${month}` : month;
    }

    const handleConfirm = (confirm: boolean) => {
        if (confirm) {
            deleteCard(data);
        } 
        setShowDialog(false)
    };

    const handleDialog = (data: any) => {
        setShowDialog(true);
        setData(data);
    };

    return (
        <Paper elevation={0} className={classes.paper} style={{ backgroundColor: localStorage.getItem('appTheme') == 'ligthTheme' ? '#F4F8F6' : '#232429' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '20px' }}>
            <span className={classes.header}>Manage Cards</span>
           {token && <button data-testid="addCard" type="submit" className={classes.editCardsButton} onClick={(event: any) =>createOrUpdateCardDetailsFlag('ADD', {})}>Add a Card</button>}
        </div>
        <div>
            <Grid container direction="row" spacing={3}>
                {
                    cardData.length > 0 && cardData.map((_data: any, key: any) =>
                        _data?.attributes?.is_primary == true ?
                            (
                                <Grid item container direction="column" spacing={0} xs={12} sm={6} md={3} key={_data.id}>
                                    <Grid item xs style={{ maxHeight: '30px' }}>
                                        <div className={classes.cardsContainer}>
                                            <span className={classes.cardsMainTitle}>
                                                {`Primary Cards`}
                                            </span>
                                        </div>
                                    </Grid>
                                    <Grid item xs>
                                        <Card className={classes.card} style={{ minHeight: '150px', borderRadius: '8px', padding: '8px', background: '#01a24e', color: '#ffffff' }}>
                                            <CardHeader
                                                action={
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={_data?.attributes?.is_primary}
                                                                onChange={(ev: any) => isCardPrimaryChange(ev, _data, 'GRID')}
                                                                name="isPrimary"
                                                                color="primary"
                                                                disabled
                                                            />
                                                        }
                                                        label=""
                                                    />
                                                }
                                                title={
                                                    <Typography gutterBottom variant="body2" component="div" style={{ color: '#58c28b', fontWeight: 'bold' }}>
                                                        {`Name`}
                                                    </Typography>
                                                }
                                                subheader={
                                                    <Typography gutterBottom variant="body2" component="div" style={{ color: '#ffffff', fontSize: '18px', fontWeight: 'bold' }}>
                                                        {_data?.attributes?.name}
                                                    </Typography>
                                                }
                                            />
                                            <CardContent>
                                                <Typography gutterBottom variant="body2" component="div" style={{ color: '#ffffff', fontSize: '18px', fontWeight: 'bold', marginBottom: '12px' }}>
                                                    {`**** **** **** ${_data?.attributes?.card_number.substr(_data?.attributes?.card_number.length - 4)}`}
                                                </Typography>
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <div style={{ paddingRight: '22%' }}>
                                                        <Typography gutterBottom variant="body2" component="div" style={{ color: '#58c28b', fontWeight: 'bold' }}>
                                                            {`EXP`}
                                                        </Typography>
                                                        <Typography variant="body2" style={{ color: '#ffffff', fontWeight: 'bold' }}>
                                                            {`${formatMonth(_data?.attributes?.month)}/${_data?.attributes?.year}`}
                                                        </Typography>
                                                    </div>
                                                    <div>
                                                        <Typography gutterBottom variant="body2" component="div" style={{ color: '#58c28b', fontWeight: 'bold' }}>
                                                            {`CVV`}
                                                        </Typography>
                                                        <Typography variant="body2" style={{ color: '#ffffff', fontWeight: 'bold' }}>
                                                            {/* {_data?.attributes?.cvv} */}
                                                            {`***`}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs style={{ maxHeight: '70x', marginTop: '10px' }}>
                                        <div className={classes.cardsButtonsContainer}>
                                            <button type="button" data-testid={`deleteCard-${_data.id}`}  className={classes.cardButtons} onClick={(event: any) => handleDialog(_data)}>Remove Card</button>
                                            <span style={{ width: '10%' }}></span>
                                            <button type="button" data-testid={`editCard-${_data.id}`} className={classes.cardButtons} onClick={(event: any) => createOrUpdateCardDetailsFlag('EDIT', _data)}>Edit Card</button>
                                        </div>
                                    </Grid>
                                </Grid>
                            )
                            :
                            (
                                <Grid item container direction="column" spacing={0} key={_data?.id} xs={12} sm={6} md={3}>
                                    <Grid item xs style={{ maxHeight: '30px' }}>
                                        <div className={classes.cardsContainer}>
                                            <span className={classes.cardsMainTitle}>
                                                {(cardData.length == 1 || key == 1) ? `Other Cards` : ''}
                                            </span>
                                        </div>
                                    </Grid>
                                    <Grid item xs>
                                        <Card className={classes.card} style={{ minHeight: '150px', borderRadius: '8px', padding: '8px', background: generateColor(), color: '#ffffff' }}>
                                            <CardHeader
                                                action={
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={_data?.attributes?.is_primary}
                                                                onChange={(ev: any) => {
                                                                    isCardPrimaryChange(ev, _data, 'GRID');
                                                                }}
                                                                name="isPrimary"
                                                                color="primary"
                                                            />
                                                        }
                                                        label=""
                                                    />
                                                }
                                                title={
                                                    <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold' }}>
                                                        {`Name`}
                                                    </Typography>
                                                }
                                                subheader={
                                                    <Typography gutterBottom variant="body2" component="div" style={{ color: '#ffffff', fontSize: '18px', fontWeight: 'bold' }}>
                                                        {_data?.attributes?.name}
                                                    </Typography>
                                                }
                                            />
                                            <CardContent>
                                                <Typography gutterBottom variant="body2" component="div" style={{ color: '#ffffff', fontSize: '18px', fontWeight: 'bold', marginBottom: '12px' }}>
                                                    {`**** **** **** ${_data?.attributes?.card_number.substr(_data?.attributes?.card_number.length - 4)}`}
                                                </Typography>
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <div style={{ paddingRight: '22%' }}>
                                                        <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold' }}>
                                                            {`EXP`}
                                                        </Typography>
                                                        <Typography variant="body2" style={{ color: '#ffffff', fontWeight: 'bold' }}>
                                                            {`${formatMonth(_data?.attributes?.month)}/${_data?.attributes?.year}`}
                                                        </Typography>
                                                    </div>
                                                    <div>
                                                        <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold' }}>
                                                            {`CVV`}
                                                        </Typography>
                                                        <Typography variant="body2" style={{ color: '#ffffff', fontWeight: 'bold' }}>
                                                            {/* {_data?.attributes?.cvv} */}
                                                            {`***`}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs style={{ maxHeight: '70x', marginTop: '10px' }}>
                                        <div className={classes.cardsButtonsContainer}>
                                            <button type="button" data-testid={`deleteCard-${_data.id}`} className={classes.cardButtons} onClick={(event: any) => handleDialog(_data)}>Remove Card</button>
                                            <span style={{ width: '10%' }}></span>
                                            <button type="button" data-testid={`editCard-${_data.id}`} className={classes.cardButtons} onClick={(event: any) => createOrUpdateCardDetailsFlag('EDIT', _data)}>Edit Card</button>
                                        </div>
                                    </Grid>
                                </Grid>
                            )
                    )
                }
            </Grid>
        </div>
        <DialogWeb classes={classes} showDialog={showDialog} handleConfirm={handleConfirm} />
        </Paper>)
}

export default GridView;
import React from "react";
// Customizable Area Start
import LandingPageWebController, {
    Props,
    configJSON
} from "./LandingPageController.web";

import { StyleRules, withStyles } from "@material-ui/core/styles";

import "./LandingPage.web.css";

import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';
import Divider from '@material-ui/core/Divider';

import "chartjs-plugin-datalabels";
import TopScansWeb from '../../../components/src/TopScans.web';
import Loader from "../../../components/src/Loader.web";
import Carousel from '../../../components/src/Carousel.web';
import { UpDownIconButton } from '../../../components/src/UpDownIcon.web';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import CloseIcon from '@material-ui/icons/Close';
import DataCard from "../../../components/src/DataCard.web";
import {Box} from "@material-ui/core"
// Customizable Area End
const styles: StyleRules = {
    // Customizable Area Start
    root: {
        // overflowX: 'hidden',
        // marginRight: '3px'
    },
    dataCardRoot: {
        padding: '20px'
    },
    loginButton: {
        // backgroundColor: '#dbeee4',
        color: 'rgba(1,162,78,255)',
        cursor: 'pointer',
        padding: '8px 20px',
        marginLeft: '10px',
        border: 'none',
        borderRadius: '10px',
        textAlign: 'center',
        '@media (max-width: 1325px)': {
            margin: '10px 10px 0px 0px',
        }
    },
    moreButton: {
        float: 'right',
        margin: '10px',
        backgroundColor: 'rgba(1,162,78,255)',
        color: '#ffffff',
        cursor: 'pointer',
        padding: '8px 20px',
        border: 'none',
        borderRadius: '10px',
    },
    selectedCategoryName: {
        fontWeight: 'bold',
        fontSize: '15px',
        '@media (min-width: 830px) and (max-width: 1325px)': {
            marginTop: '10px',
        }
    },
    selectedCategoryBox: {
        display: 'flex',
        justifyContent: 'space-between',
        '@media (max-width: 830px)': {
            flexDirection: 'column'
        }
    },
    mainTitle: {
        //color: '#b5c1ba',
        fontWeight: 'bold'
    },
    newsTitle: {
        fontWeight: 900,
        cursor: 'pointer',
        '&:hover': {
            color: '#4179bd'
        }
    },
    newsText: {
        fontSize: '15px',
        fontWeight: 'bold',
        marginBottom: '25px',
        // fontFamily: 'Plus Jakarta Text'
        fontFamily: 'Plus Jakarta Text'
    },
    topScanContainer: {
        height: "100%", // So that grids 1 & 4 go all the way down
        minHeight: 375, // Give minimum height to a div
        textAlign: "left"
    },
    topScanTableMenuItem: {
        color: '#499e5a',
        fontWeight: 'bold'
    },
    topScanTableButton: {
        backgroundColor: '#f4f8f6 !important',
        color: '#01A24E !important',
        cursor: 'pointer',
        border: 'none',
        textAlign: 'center'
    },
    topScanUnorderedList: {
        listStyle: 'none',
        padding: '8px',
        margin: 0,
        float: 'left',
        marginTop: '16px',
        "& li": {
            borderRight: '2px solid #c4c4c4',
            display: 'inline-block',
            float: 'left',
            padding: '0 8px',
        }
    },
    topScanUnorderedListButton: {
        listStyle: 'none',
        padding: '8px',
        margin: 0,
        marginTop: '10px',
    },
    buttonStyle: {
        margin: '5px',
        backgroundColor: 'rgba(1,162,78,255)',
        color: '#ffffff',
        boxShadow: 'none'
    },
    removeBorder: {
        '& .MuiTableCell-root': {
            borderBottom: 'none'
        }
    },
    dataCard: {
        borderRadius: '10px',
        '& .MuiCardContent-root:last-child': {
            paddingBottom: '0px'
        }
    },
    dataCardTitle: {
        fontWeight: 'bold',
        fontSize: '14px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        maxWidth: '125px'
    },
    dataCardContent: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: '5px',
        cursor: 'pointer'
    },
    top10Header: {
        color: '#94A59C'
    },
    percentChange: {
        display: "flex",
        alignItems: "center",
        marginLeft: '-12px'
    },
    percentChangeItem: {
        fontWeight: "bold",
        fontSize: "12px",
    },
    tableRow: {
        cursor: "pointer"
    },
    viewAllBtn: {
        fontSize: '10px', 
        color: 'rgb(1, 162, 78)', 
        fontWeight: 'bold',
        '&.MuiButton-root.Mui-disabled': {
            color: 'rgb(198, 207, 202)'
        }
    },
    carousalImage: {
        width: '100%',
        borderRadius: '10px'
    },
    closeBtn: {
        position: 'absolute',
        right: 0,
        top: "-5px"
    },
    subscribeText: {
        margin: "10px"
    },
    subscriberTitle: {
        color: "#000000",
        display: "flex",
        fontWeight: "bold",
        alignItems: "center"
    },
    subscriberStyle: {
        color: "#000000",
        display: "flex",
        fontWeight: "bold",
        alignItems: "center"
    },
    subscribeBtn: {
        width: "260px",
        padding: "8px 0",
        textTransform: "none",
        backgroundColor: "#01A24E",
        color: "#ffffff",
        fontSize: "14px"
    }
    // Customizable Area End
}

export class LandingPageWeb extends LandingPageWebController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start    
    bannerTopCrypto = () => {
        const { cryptoData, carousalData } = this.state;
        const { classes } = this.props;
        const backgroundval = localStorage.getItem('appTheme') == 'darkTheme' ? '#333742' : 'rgb(239, 239, 239)';
        let selectedCategory: any;
        if (this.state.selectedCategoryItem != "Top 10 Crypto Details")
            selectedCategory = this.state.cryptoCategories.find((category: any) => category.name == this.state?.selectedCategoryItem);
        return (
            <div style={{ display: 'flex', flexDirection: 'column', padding: '22px' }}>
               { carousalData.length > 0 && <Carousel classes={classes} images={carousalData} /> }
                <div className={classes.selectedCategoryBox}>
                    <div>
                        <Typography variant="h6" className={classes.selectedCategoryName}>
                            { this.state.selectedCategoryItem === 'Top 10 Crypto Details' ? 'Top 10 Crypto for the Day' : this.state.selectedCategoryItem }
                        </Typography></div>
                    <div>
                        <button data-testid='topTenCryptoDetails' type="button" className={classes.loginButton} style={{ backgroundColor: this.state.selectedCategoryItem == 'Top 10 Crypto Details' ? 'rgba(1,162,78,255)' : backgroundval, color: this.state.selectedCategoryItem == 'Top 10 Crypto Details' ? '#ffffff' : '' }} onClick={(event: any) => this.getTopTenCryptoDetails({ name: 'Top 10 Crypto Details' })}>Top 10 Crypto Details</button>
                        {this.state.cryptoCategories.map((category: any) => (
                            <button data-testid={`${category.name}`} type="button" className={classes.loginButton} style={{ backgroundColor: this.state.selectedCategoryItem == category.name ? 'rgba(1,162,78,255)' : backgroundval, color: this.state.selectedCategoryItem == category.name ? '#ffffff' : '' }} key={category.id} onClick={(event: any) => this.getSelectedCategoryCryptoDetails(category)}>{category.name}</button>
                        ))}</div>
                </div>
                <div style={{ marginTop: '15px' }}>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    {['#', 'Name', 'Price', '24h', '7d', 'MCAP', 'Volume', 'Circulation'].map((item, index) => (
                                        <TableCell align="left" key={index} className={index !== 0 && classes.top10Header}>{item}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {cryptoData.map((rowObj: any, index: any) => (
                                    <TableRow key={rowObj.id} data-testid={`cryptoDetail-${rowObj.id}`} className={classes.tableRow} onClick={() => {
                                        window.location.href = `/CryptoDetailWeb/${rowObj.id}`;
                                    }}>
                                        <TableCell component="th" scope="row">
                                            {index + 1}
                                        </TableCell>
                                        <TableCell align="left">
                                            <CardHeader
                                                avatar={<Avatar
                                                    alt="Remy Sharp"
                                                    style={{ width: '32px', height: '32px' }}
                                                    src={rowObj.image_url}
                                                >
                                                </Avatar>}
                                                title={<Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                    {`${rowObj.name}`}
                                                </Typography>}
                                                style={{ padding: '0px' }} />
                                        </TableCell>
                                        <TableCell>
                                            <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                {`${rowObj.price}`}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            <UpDownIconButton value={rowObj.percentage_change_24h} />
                                        </TableCell>
                                        <TableCell align="left">
                                            <UpDownIconButton value={rowObj.percent_change_7d} />
                                        </TableCell>
                                        <TableCell>
                                            <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                {rowObj.market_cap}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                {rowObj.volume}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                    {rowObj.circulation}
                                                </Typography>
                                                <BorderLinearProgress variant="determinate" value={parseFloat(rowObj.circulation_percentage?.split(" ")[0]) || 0} style={{ marginTop: '5px' }} />
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <button data-testid='cryptoDataMoreButton' type="button" className={classes.loginButton} style={{ float: 'right', margin: '10px 0px', backgroundColor: 'rgba(1,162,78,255)', color: '#ffffff' }}
                        onClick={() => this.state.selectedCategoryItem == 'Top 10 Crypto Details' ? this.getTopTenCryptoDetails({ name: 'Top 10 Crypto Details' }, !this.state.showMore) : this.getSelectedCategoryCryptoDetails(selectedCategory, !this.state.showMore)}>{this.state.showMore ? "Less..." : "More..."}</button>
                </div>
            </div>
        )
    }

    topNews = () => {
        const { classes } = this.props;

        return (
            <div style={{ padding: '22px', marginBottom: '10px' }}>
                <Grid container direction="row" spacing={4}>
                    <Grid item container direction="column" xs spacing={2}>
                        <Grid item xs style={{ maxHeight: '35px' }}>
                            <Typography variant="body1" className={classes.mainTitle}>
                                {`Top News`}
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Paper square elevation={2} style={{ display: 'flex', flexWrap: 'wrap', padding: '8px', boxShadow: localStorage.getItem('appTheme') == 'ligthTheme' ? 'none' : '' }}>
                                {this.state.newsData.slice(0, 3).map((_data: any, index: any) => (
                                    <Grid item xs={12} md={4} sm={6} key={index} >
                                        <div style={{ border: `2px solid #ccc`, borderRadius: '10px', margin: '16px', minHeight: '270px', maxHeight: '270px', overflow: 'hidden' }}>
                                            <Grid item xs style={{ margin: '10px' }}>
                                                <Typography variant="body1" className={classes.newsTitle} onClick={(event: any) => {
                                                    window.open(_data.news_url) }}>{_data.title}</Typography>
                                            </Grid>

                                            <Grid item xs style={{ margin: '0px 10px 10px 10px' }}>
                                                <Typography variant="body1" style={{ color: '#777', fontSize: '0.8em' }}>
                                                    {`${_data.date}  | ${_data.source}`}
                                                </Typography>
                                            </Grid>
                                            
                                            <Divider style={{ height: '2px' }} />
                                            
                                            <Grid item xs style={{ margin: '12px 10px' }}>
                                                <Typography variant="body1" style={{ color: localStorage.getItem('appTheme') == 'ligthTheme' ? '#333' : '#fff', fontSize: '0.9em' }}>
                                                    {_data.content?.length <= 200 ? _data.content : _data.content.substring(0, 250).concat("...")}
                                                </Typography>
                                            </Grid>
                                        </div>
                                    </Grid>
                                ))}
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                <button data-testid='viewAllButton' type="submit" className={classes.moreButton} onClick={(event: any) => {
                    window.location.href = "/NewsPortalWeb";
                }}>
                    View All
                </button>
            </div>
        )
    }

    topCoins = () => {
        const { classes } = this.props;
        const { showMoreBearishStock, showMoreBullishStock, bullishStocks, bearishStocks, bearishTimeframe, bullishTimeframe } = this.state;

        return (
        <div style={{ padding: '0px 22px 22px' }}>
            <Grid container direction="row" spacing={4}>
                <Grid item container direction="column" xs spacing={2}>
                    <Grid item xs style={{ maxHeight: '35px' }}>
                        <div className={classes.topScanContainer}>
                            <Typography variant="body1" className={classes.mainTitle} data-testid="coinTestid">
                                {`Top Coins`}
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs>
                        {<TopScansWeb classes={classes} title="Bullish" strengthColor="#01a24e" showMore={showMoreBullishStock} selectedTimeFrame={bullishTimeframe} stockData={bullishStocks} getMoreStocks={this.getMoreBullishStock} handleTimeFrameClick={this.bullishTimeframeButtonClicked} />}
                    </Grid>
                </Grid>
                <Grid item container direction="column" xs spacing={2}>
                    <Grid item xs style={{ maxHeight: '35px' }}>
                        <div className={classes.topScanContainer}>
                            <Typography variant="body1" className={classes.mainTitle}>
                                {``}
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs>
                        {<TopScansWeb classes={classes} title="Bearish" strengthColor="#ea7b54" showMore={showMoreBearishStock} selectedTimeFrame={bearishTimeframe} stockData={bearishStocks} getMoreStocks={this.getMoreBearishStock} handleTimeFrameClick={this.bearishTimeframeButtonClicked} />}
                    </Grid>
                </Grid>
            </Grid>
        </div>)
    }
  
    showUpgradePlanAlert = () => {
        const { classes } = this.props;
        const { showUpgradePlanAlert, planExpiringInDays } = this.state;
        if (showUpgradePlanAlert) {
            return(
                <Dialog
                    open={showUpgradePlanAlert}
                    onClose={this.handleUpdatePlanAlertClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    data-testid="alert-dialog"
                >
                    <DialogContent>
                        <IconButton data-testid="close-alert" className={classes.closeBtn} onClick={() => this.handleUpdatePlanAlertClose()}>
                            <CloseIcon />
                        </IconButton>
                        <Box>
                                <DialogContentText className={classes.subscriberTitle} id="alert-dialog-description"
                                    data-testid="dialogTestid">
                                    <Typography className={classes.subscribeText}>{configJSON.expiredText}</Typography>
                                    <Button variant="contained"
                                        data-testid="subscribeBtnTestid"
                                        onClick={this.handleNavigate}
                                        className={classes.subscribeBtn}>{configJSON.subscribeBtn}
                                    </Button>
                                </DialogContentText>
                        </Box>
                    </DialogContent>
                </Dialog>
            )
        }
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        return (
            <Paper className={classes.root} square data-testid='landingPaper' style={{ backgroundColor: localStorage.getItem('appTheme') == 'ligthTheme' ? '#F4F8F6' : '#232429' }}>
                {this.state.loading && <Loader loading={this.state.loading} />}
                {this.showUpgradePlanAlert()}
                <div className={classes.dataCardRoot}>
                    <Grid
                        container
                        direction="row"
                        spacing={2}
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >
                        <Grid item xs={12} sm={6} lg={3} >
                            <DataCard classes={classes}
                                title="TRENDING"
                                urlParam="trending_coins"
                                testId="Trending"
                                noMessageText="There is no Trending Assets to show at the moment"
                                disabled={!this.state.trendingAsset}
                                data={this.state.trendingAsset} />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3} >
                            <DataCard classes={classes}
                                title="TOP GAINERS"
                                urlParam="top_gainers"
                                testId="TopGainers"
                                noMessageText="There is no Top Gainers Assets to show at the moment"
                                disabled={!this.state.topGainers}
                                data={this.state.topGainers} />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3} >
                            <DataCard classes={classes}
                                title="TOP LOSERS"
                                urlParam="top_losers"
                                testId="TopLosers"
                                noMessageText="There is no Top Losers Assets to show at the moment"
                                disabled={!this.state.topLosers}
                                data={this.state.topLosers} />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3} >
                            <DataCard classes={classes}
                                title="RECENTLY ADDED"
                                urlParam="recently_added"
                                testId="RecentlyAdded"
                                noMessageText="There is no Recently Added Assets to show at the moment"
                                disabled={!this.state.recentlyAdded}
                                data={this.state.recentlyAdded} />
                        </Grid>
                    </Grid>
                </div>
                { this.bannerTopCrypto() }
                { this.topNews() }
                { this.topCoins() }
            </Paper>
        )
        // Customizable Area End
    }
}

export default withStyles(styles)(LandingPageWeb);
// Customizable Area Start
const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 6,
        borderRadius: 5,
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 500],
    },
    bar: {
        borderRadius: 5,
        backgroundColor: '#94a59c',
    },
}))(LinearProgress);
// Customizable Area End
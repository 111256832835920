import React from "react";
// Customizable Area Start
//@ts-nocheck
import {
  StyleSheet,
  Text,
  Dimensions,
  TouchableOpacity,
  FlatList,
  View,
  ScrollView,
  TouchableWithoutFeedback,
  Platform,
  Modal,
  ActivityIndicator
} from "react-native";
import CustomeHeader from "../../../components/src/CustomeHeader";
import { backIcon } from "./assets";
const { height, width } = Dimensions.get("screen");
import { theme } from "../../../framework/src/theme";
// Customizable Area End

import CustomisableusersubscriptionsController, {
  Props,
  configJSON
} from "./CustomisableusersubscriptionsController";

import { SubscriptionListView } from "./SubscriptionList";

export default class Customisableusersubscriptions extends CustomisableusersubscriptionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getColorTheme = (mode:any, color1:any, color2:any) => {
    return this.context.mode === mode ? color1 : color2
  }
  subscriptionItem = ({ item }: any) => {
    return (
      <View
      testID={`subitem-${item.id}`}
        style={[
          styles.subscriptionBox,
          {
            backgroundColor:
              this.getColorTheme("light",theme.light.highlight,theme.dark.highlight)
          }
        ]}
      >
        <View
          style={[
            styles.subscriptionHeader,
            {
              backgroundColor:this.getColorTheme("light","#F4F8F5","#3C3F50")
            }
          ]}
        >
          <Text
            style={[
              styles.headerTxt,
              {
                color:
                  this.getColorTheme("light",theme.light.textColor,theme.dark.textColor)
              }
            ]}
          >
            {item && item.attributes && item.attributes.name}
          </Text>
        </View>
        {item &&
          item.attributes &&
          item.attributes.sub_feature &&
          item.attributes.sub_feature.data.length !== 0 &&
          item.attributes.sub_feature.data.map((sub: any) => (
            <View style={styles.optionView}>
              <View style={styles.point} />
              <Text
                style={[
                  styles.pointTxt,
                  {
                    color:
                      this.getColorTheme("light",theme.light.textColor,theme.dark.textColor)
                  }
                ]}
              >
                {sub.attributes && sub.attributes.name}
              </Text>
            </View>
          ))}
         <>
         {
              item?.attributes?.renew !="" &&
              <View style={{alignItems:'center',marginTop:10}}>
              <Text 
              testID="onClickViewAllFe"
              onPress={()=>this.onClickViewAllFet(item)}
              style={[styles.pointTxt, {textDecorationLine:'underline',}]}>View all features</Text>
              </View>
            }
         </>
           
          
        <View style={styles.borderLine} />
        <View style={styles.amountView}>
          <View style={styles.amountPointView}>
            <TouchableOpacity
            testID="onselectSubscription"
              style={[
                styles.pointBG,
                {
                  backgroundColor: item.isSelected == true ? "#499E5A" : "#fff"
                }
              ]}
              onPress={() => this.onselectSubscription(item)}
            />
          </View>
          <View style={styles.subAmountView}>
            <View style={{ flexDirection: "row" }}>
              <Text style={styles.amtTxt1}>INR</Text>
              <Text style={styles.amtTxt2}>
                {item && item.attributes && item.attributes.price}
              </Text>
            </View>
          </View>
        </View>
        <View
          style={{
            alignItems: "center",
            marginBottom:
              item && item.attributes && item.attributes.renew !== "" ? 20 : 0
          }}
        >
          <Text style={styles.renewTxt}>
            {item && item.attributes && item.attributes.renew}
          </Text>
        </View>
      </View>
    );
  };

  footerBtn = () => {
    return (
      <TouchableOpacity
        testID={this.subscribe}
        onPress={this.addSubscription}
        // disabled={this.state.userData &&
        //   this.state.userData.attributes &&
        //   this.state.userData.attributes.subscription.data.length === 0 }
        style={styles.btnStyle}
      >
        <Text style={styles.btnText}>{this.subscribe}</Text>
      </TouchableOpacity>
    );
  };

  subscriptionModal = () => {
    return (
      <Modal
        visible={this.state.openModal}
        transparent={true}
        onRequestClose={() =>
          this.setState({ openModal: !this.state.openModal })
        }
      >
        <View style={styles.modalView}>
          <View
            style={[
              styles.modalBackground,
              {
                backgroundColor:
                  this.getColorTheme("light","#fff","#3C3F50")
              }
            ]}
          >
            <View style={styles.modalDetails}>
              <Text style={styles.selectOptionText}>Subscription</Text>
              {/* <TouchableOpacity
                onPress={() =>
                  this.setState({ openModal: !this.state.openModal })
                }
              >
                <Text style={styles.closeButton}>X</Text>
              </TouchableOpacity> */}
            </View>
            <View style={styles.descriptionView}>
              <Text
                style={[
                  styles.modalDescription,
                  {
                    color:
                      this.getColorTheme("light",theme.light.textColor,theme.dark.textColor)
                  }
                ]}
              >
                {this.state.modalDescription}
              </Text>
            </View>

            {this.state.isSubscriptionSelected === true ? (
              <View style={styles.modalOptionView}>
                <TouchableOpacity
                testID="onClickNo"
                  style={styles.modalNoBtn}
                  onPress={() => this.onClickNo()}
                >
                  <Text style={styles.modalCamTxt}>No</Text>
                </TouchableOpacity>
                <TouchableOpacity
                testID="getCardData"
                  style={styles.modalYesBtn}
                  onPress={() => this.getCardData()}
                >
                  <Text style={styles.modalCamTxt}>Yes1</Text>
                </TouchableOpacity>
              </View>
            ) : (
              <View style={styles.modalOkBtn}>
                <TouchableOpacity
                testID="onClickNos"
                  style={styles.modalNoBtn}
                  onPress={() => {this.onClickNo()}}
                >
                  <Text style={styles.modalCamTxt}>Ok</Text>
                </TouchableOpacity>
              </View>
            )}
          </View>
        </View>
      </Modal>
    );
  };

  cardModal = () => {
    return (
      <Modal
        visible={this.state.cardModal}
        transparent={true}
        onRequestClose={() =>
          this.setState({ cardModal: !this.state.cardModal })
        }
      >
        <View style={styles.modalView}>
          <View
            style={[
              styles.modalBackground,
              {
                backgroundColor:
                  this.getColorTheme("light","#fff","#3C3F50")
              }
            ]}
          >
            <View style={styles.modalDetails}>
              <Text style={styles.selectOptionText}>Subscription</Text>
            </View>
            <View style={styles.descriptionView}>
              <Text
                style={[
                  styles.modalDescription,
                  {
                    color:
                      this.getColorTheme("light",theme.light.textColor,theme.dark.textColor)
                  }
                ]}
              >
                {this.state.modalDescription}
              </Text>
            </View>
            <View style={styles.modalOkBtn}>
              <TouchableOpacity
              testID="modalDescription"
                style={styles.modalNoBtn}
                onPress={() =>
                  this.state.modalDescription ===
                  "You have subscribed successfully"
                    ? this.onClickCardOk()
                    :this.onNavigateToCardScreen()
                }
              >
                <Text style={styles.modalCamTxt}>Ok</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ScrollView
        keyboardShouldPersistTaps="always"
        style={[
          styles.container,
          {
            backgroundColor:
              this.getColorTheme("light",theme.light.backgroundColor,theme.dark.backgroundColor)
          }
        ]}
        contentContainerStyle={{
          flexGrow: 1
        }}
      >
        <TouchableWithoutFeedback
        testID="hideKeyboard"
          onPress={() => {
            this.hideKeyboard();
          }}
        >
          <>
          {/* @ts-ignore */}
            <CustomeHeader
            testID={'toggleDrawer'}
              {...this.props}
              onPress={() => this.props.navigation.toggleDrawer()}
              onClickProfile={() =>
                this.props.navigation.navigate("Pushnotifications")
              }
            />
            <View
              style={[
                styles.mainView,
                {
                  backgroundColor:
                    this.getColorTheme("light",theme.light.curvedBoxView,theme.dark.curvedBoxView)
                }
              ]}
            >
              {this.state.subscriptionListLoader === true ? (
                <ActivityIndicator
                  animating={true}
                  color="green"
                  size="large"
                  style={styles.activityIndicator}
                />
              ) : (
                <FlatList
                testID="flatlistID"
                  style={{ width: "100%" }}
                  contentContainerStyle={{ alignItems: "center" }}
                  data={this.state.subscriptionArray}
                  renderItem={this.subscriptionItem}
                  extraData={this.state.isRefresh}
                  ListFooterComponent={this.footerBtn}
                />
              )}
            </View>
            {this.state.openModal ? this.subscriptionModal() : null}
            {this.state.cardModal ? this.cardModal() : null}
          </>
        </TouchableWithoutFeedback>
      </ScrollView>
      // Customizable Area End
    );
  }
}

const styles = StyleSheet.create({
  // Customizable Area Start
  container: {
    flex: 1,
    // padding: 16,
    // marginLeft: "auto",
    // marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%"
    // maxWidth: 650,
    // backgroundColor: "#F4F8F5"
  },
  mainView: {
    flex: 1,
    height: height,
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    elevation: 5,
    // backgroundColor: "#fff",
    alignItems: "center"
  },
  subscriptionBox: {
    marginTop: 15,
    width: "90%",
    borderWidth: 1,
    borderColor: "#ddd",
    borderBottomWidth: 2,
    shadowColor: "#000000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.9,
    shadowRadius: 10,
    borderRadius: 15
  },
  subscriptionHeader: {
    borderTopRightRadius: 15,
    borderTopLeftRadius: 15,
    height: 70,
    // backgroundColor: "#F4F8F5",
    alignItems: "center",
    justifyContent: "center"
  },
  headerTxt: { fontSize: 18, fontFamily:'PlusJakartaText-Bold' },
  optionView: { paddingLeft: "30%", marginTop: 15, flexDirection: "row" },
  point: {
    height: 12,
    width: 12,
    borderRadius: 12,
    backgroundColor: "#499E5A",
    marginTop: 5,
    marginRight: 10
  },
  pointTxt: { fontSize: 16,fontFamily:'PlusJakartaText-Regular' },
  pointBG: {
    height: 23,
    width: 23,
    borderRadius: 23,
    borderWidth: 3,
    borderColor: "#ddd",
    marginRight: 10,
    shadowColor: "#000000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.9,
    shadowRadius: 10,
    elevation: 3
  },
  borderLine: {
    height: 1,
    width: width - 30,
    backgroundColor: "#ddd",
    marginTop: 12
  },
  amountView: { flexDirection: "row", marginTop: 5, height: 60 },
  amountPointView: {
    width: "30%",
    justifyContent: "center",
    alignItems: "flex-end"
  },
  subAmountView: {
    width: "70%",
    justifyContent: "center",
    left: 6
  },
  amtTxt1: {
    fontSize: 16,
   fontFamily:'PlusJakartaText-Bold',
    color: "#499E5A",
    top: 4
  },
  amtTxt2: {
    fontSize: 24,
    fontFamily:'PlusJakartaText-Bold',

    color: "#499E5A",
    left: 5
  },
  renewTxt: { fontStyle: "italic", fontSize: 14, color: "#A2AEA7" },
  validView: {
    marginTop: 10,
    flexDirection: "row",
    alignSelf: "center",
    marginBottom: 15
  },
  validText: { fontSize: 16,    fontFamily:'PlusJakartaText-Bold',
  color: "#A2AEA7" },
  btnStyle: {
    backgroundColor: "#499E5A",
    marginTop: 50,
    height: 60,
    width: width - 30,
    borderRadius: 10,
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 40
  },
  btnText: {
    color: "#fff",
    fontFamily:'PlusJakartaText-Bold',

    letterSpacing: 1,
    fontSize: 18
  },
  // Modal Style
  modalView: {
    flex: 1,
    backgroundColor: "rgba(52, 52, 52, 0.8)",
    alignItems: "center",
    justifyContent: "center"
  },
  modalBackground: {
    // backgroundColor: "#fff",
    borderRadius: 5,
    height: height / 4,
    width: width - 40
  },
  modalDetails: {
    marginHorizontal: 20,
    alignItems: "center"
  },
  selectOptionText: {
    textAlign: "center",
    fontFamily:'PlusJakartaText-Bold',

    color: "#ED7B5E",
    fontSize: 18,
    marginVertical: 10
  },
  closeButton: {
    color: "#000",
    fontFamily:'PlusJakartaText-Bold',

    fontSize: 18
  },
  modalOptionView: {
    marginVertical: 20,
    marginHorizontal: 15,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-around"
  },
  modalOkBtn: {
    marginVertical: 20,
    marginHorizontal: 15,
    alignItems: "center"
  },
  modalNoBtn: {
    width: "45%",
    backgroundColor: "#000",
    padding: 13,
    borderRadius: 10
  },
  modalCamTxt: { color: "#fff", fontSize: 18, textAlign: "center",fontFamily:'PlusJakartaText-Bold' },
  descriptionView: {
    height: 60,
    justifyContent: "center",
    paddingHorizontal: 15
  },
  modalDescription: { fontSize: 16, textAlign: "center",fontFamily:'PlusJakartaText-Regular' },
  modalYesBtn: {
    width: "45%",
    backgroundColor: "#499E5A",
    padding: 13,
    borderRadius: 10
  },
  myAdsText: {
    fontSize: 22,
    fontWeight: "600",
    marginLeft: 10
  },
  description: {
    fontSize: 14,
    fontWeight: "500",
    marginLeft: 15
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 10,
    paddingVertical: 20
  },
  leftHeaderView: {
    flexDirection: "row",
    alignItems: "center"
  },
  iconView: {
    paddingHorizontal: 5
  },
  backIcon: {
    width: 7,
    height: 14
  },
  title: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  body: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  activityIndicator: {
    marginTop: "50%",
    justifyContent: "center",
    alignItems: "center",
    height: 80
  }
  // Customizable Area End
});

// Customizable Area Start
export {Customisableusersubscriptions}
// Customizable Area End

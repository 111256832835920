import React from "react"
// Customizable Area Start
import Paper from "@material-ui/core/Paper";
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import {
    PriceActionTableHeadings, CommonFields, NamePriceFields,
    BearishBullishDoublePattern, BearishBullishTriplePattern, BearishBullishHeadShoulderPattern, ContinuationPatterns
} from "../../../components/src/PriceActionTableView.web";
import { currentPlan } from "../../../components/src/chartUtils.web";

const FilterChip = (props: any) => {
    const { classes, filteredChip } = props;
    return (<Paper component="ul" className={classes.filtered} elevation={0}>
        {filteredChip.map((data: any, key: any) => {
            let icon, chipPart;
            let customName = data.split("-");
            if (customName[1]) {
                if (customName[1].trim() === "inverted_head_shoulder_pattern") {
                    chipPart = "Inverted Head & Shoulder Pattern";
                }
                else if (customName[1].trim() === "head_shoulder_pattern") {
                    chipPart = "Head & Shoulder Pattern";
                } else {
                    chipPart = customName[1].replaceAll("_", " ")
                }
            }
           
            const finalLabel = chipPart ? customName[0] + '- ' + chipPart : data;
            return (
                <li key={data.key}>
                    <Chip
                        data-testid="priceChip"
                        icon={icon}
                        label={finalLabel}
                        onDelete={props.handleFilterDelete(data)}
                        className={classes.chip}
                    />
                </li>
            )
        })}
    </Paper>)
}
// Customizable Area End

export default function PriceActionTable(props: any) {
    const {
        // Customizable Area Start
        priceActionGainLossResponseData,
        priceActionHighLowResponseData,
        priceActionBrealoutDownResponseData,
        priceActionSupportResistanceResponseData,
        priceActionStandardPatternsResponseData,
        priceActionReversalContinualtionResponseData,
        priceActionCandelSticksResponseData,
        handleMoreResult,
        filteredChip,
        isMoreData,
        isLoading,
        noData,
        selectedItemData,
        dataReceived
        // Customizable Area End
    } = props.state
    const {
        // Customizable Area Start
        classes,
        handleFilterDelete,
        handleMorePopup,
        handleMorePriceActionWithDataPoints,
        handlePageIncrease,
        // Customizable Area End
    } = props
    // Customizable Area Start
    const renderViewMore = () => {
        const plan = currentPlan();
        return (<>
            {!isMoreData && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <Typography style={{ color: "red" }}>
                        *No more data available
                    </Typography>
                </div>
            )}
            <div style={{ padding: "10px 0px" }}>
                <Button
                    data-testid="viewMore"
                    className={classes.loadMoreButton}
                    onClick={handlePageIncrease}
                    disabled={isLoading || !isMoreData || plan.result_per_screen !== 'Unlimited'}
                >
                    {isLoading ? "Loading..." : "View More"}
                </Button>
            </div></>
        )
    }

    const highLowHeadings = () => {
        let headings = ["Name", "Price"];
        if (dataReceived === 'Near All Time High' || dataReceived === 'Near All Time Low') {
            headings = headings.concat([`Near All Time \nHigh`, `Near All Time \nHigh Date`, `Near All Time \nLow`, `Near All Time \nLow Date`, `Near All Time \nPrice Change`])
        }
        if (dataReceived === 'At All Time High' || dataReceived === 'At All Time Low') {
            headings = headings.concat([`At All Time \nHigh`, `At All Time \nHigh Date`, `At All Time \nLow`, `At All Time \nLow Date`, `At All Time \nPrice Change`])
        }
        if (dataReceived === 'Near 52 Weeks High' || dataReceived === 'Near 52 Weeks Low') {
            headings = headings.concat([`Near 52 Weeks \nHigh`, `Near 52 Weeks \nHigh Date`, `Near 52 Weeks \nLow`, `Near 52 Weeks \nLow Date`, `Near 52 Weeks \nPrice Change`])
        }
        if ((dataReceived === 'Near Past Week High' || dataReceived === 'Near Past Week Low')) {
            headings = headings.concat([`Near Past Weeks \nHigh`, `Near Past Weeks \nHigh Date`, `Near Past Weeks \nLow`, `Near Past Weeks \nLow Date`, `Near Past Weeks \nPrice Change`])
        }
        headings = headings.concat(['24h High', '24h Low', 'Market Cap']);
        return headings;
    }

    const gainLossHeadings = () => {
        let headings = ['Name', 'Price'];
        dataReceived === "Price Up %" && headings.push("Gain %");
        dataReceived === "Price Down %" && headings.push("Fall %");
        (dataReceived === "Price Trending Up" || dataReceived === "Price Trending Down") && headings.push("Min Price Trending Day");
        headings = headings.concat(['24h High', '24h Low', 'Market Cap']);
        return headings;
    }

    const supportResistanceHeadings = () => {
        let headings = ['Name', 'Price'];
        priceActionSupportResistanceResponseData[0].hasOwnProperty('NEAR_S2') && headings.push("Near S2 Price");
        priceActionSupportResistanceResponseData[0].hasOwnProperty('NEAR_S3') && headings.push("Near S3 Price");
        priceActionSupportResistanceResponseData[0].hasOwnProperty('NEAR_S1') && headings.push("Near S1 Price");
        priceActionSupportResistanceResponseData[0].hasOwnProperty('NEAR_R1') && headings.push("Near R1 Price");
        priceActionSupportResistanceResponseData[0].hasOwnProperty('NEAR_R2') && headings.push("Near R2 Price");
        priceActionSupportResistanceResponseData[0].hasOwnProperty('NEAR_R3') && headings.push("Near R3 Price");
        priceActionSupportResistanceResponseData[0].hasOwnProperty('BREAKDOWN_R1') && headings.push("Breakout R1 Price");
        priceActionSupportResistanceResponseData[0].hasOwnProperty('BREAKDOWN_R2') && headings.push("Breakout R2 Price");
        priceActionSupportResistanceResponseData[0].hasOwnProperty('BREAKDOWN_R3') && headings.push("Breakout R3 Price");
        priceActionSupportResistanceResponseData[0].hasOwnProperty('BREAKDOWN_S1') && headings.push("Breakdown S1 Price");
        priceActionSupportResistanceResponseData[0].hasOwnProperty('BREAKDOWN_S2') && headings.push("Breakdown S2 Price");
        priceActionSupportResistanceResponseData[0].hasOwnProperty('BREAKDOWN_S3') && headings.push("Breakdown S3 Price");
        headings = headings.concat(['24h High', '24h Low', 'Market Cap']);
        return headings;
    }

    const reversalHeading = () => {
        let headings = ["Name", "Price"];
        if (filteredChip[0].includes("bullish reversals")) {
            if (filteredChip[0].includes('double_bottom_pattern'))
                headings = headings.concat([`First Bottom/\n Date`, `Mid Point/\n Date`, `Second Bottom/\n Date`])
            if (filteredChip[0].includes('triple_bottom_pattern'))
                headings = headings.concat([`First Bottom/\n Date`, `First Mid Point/\n Date`, `Second Bottom/\n Date`, `Second Mid Point/\n Date`, `Third Bottom/\n Date`])
        }

        if (filteredChip[0].includes("bearish reversals")) {
            if (filteredChip[0].includes('double_top_pattern'))
                headings = headings.concat([`First Top/\n Date`, `Mid Point/\n Date`, `Second Top/\n Date`])
            if (filteredChip[0].includes('triple_top_pattern'))
                headings = headings.concat([`First Top/\n Date`, `First Mid Point/\n Date`, `Second Top/\n Date`, `Second Mid Point/\n Date`, `Third Top/\n Date`])
        }

        if (filteredChip[0].includes('inverted_head_shoulder_pattern') || filteredChip[0].includes('head_shoulder_pattern'))
            headings = headings.concat([`Shoulder 1/\n Date`, `First Mid Point/\n Date`, `Head/\n Date`, `Second Mid Point/\n Date`, `Shoulder 2/\n Date`])
        
        if (filteredChip[0].includes("continuation patterns"))
            headings = headings.concat([`Top First Point/\n Date`, `Top Last Point/\n Date`, `Bottom First Point/\n Date`, `Bottom Last Point/\n Date`]);
        
            headings = headings.concat(['24h High', '24h Low', 'Market Cap']);
        return headings;
    }
    // Customizable Area End
  return (
    // Customizable Area Start
   <>
        { selectedItemData === 'Price Action' && isMoreData && noData && <Typography style={{ textAlign: 'center', fontSize: '18px', fontWeight: 'bold' }} >No Data Found</Typography>}
        {/* Gain/Loss */}
            {priceActionGainLossResponseData.length > 0 && !handleMoreResult &&
                <Paper>
                    <FilterChip classes={classes} filteredChip={filteredChip} handleFilterDelete={handleFilterDelete} />
                    <Divider />
                    <TableContainer style={{maxHeight: "650px"}}>
                        <Table stickyHeader aria-label="simple table">
                            <PriceActionTableHeadings headings={gainLossHeadings()} classes={classes} />
                            <TableBody>
                                {priceActionGainLossResponseData.map((row: any, key: any) => (
                                    <TableRow key={row.id}>
                                        <NamePriceFields idx={key} row={row} handleMorePopup={handleMorePopup} />
                                        <TableCell align="left">
                                            {(dataReceived === "Price Up %" ||
                                                dataReceived === "Price Down %") && (
                                                    <Typography
                                                        gutterBottom
                                                        variant="body2"
                                                        component="div"
                                                        style={{
                                                            fontWeight: "bold",
                                                            fontSize: "12px",
                                                        }}
                                                    >
                                                        {`${row.percent_change}`}
                                                    </Typography>
                                                )}
                                            {(dataReceived === "Price Trending Up" ||
                                                dataReceived ===
                                                "Price Trending Down") && (
                                                    <Typography
                                                        gutterBottom
                                                        variant="body2"
                                                        component="div"
                                                        style={{
                                                            fontWeight: "bold",
                                                            fontSize: "12px",
                                                        }}
                                                    >
                                                        {`${row.min_price_trending_days}`}
                                                    </Typography>
                                                )}
                                        </TableCell>
                                        <CommonFields row={row} handleMorePopup={handleMorePopup} />
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {renderViewMore()}
                </Paper>
            }
            {/* High/Low */}
            {
                priceActionHighLowResponseData.length > 0 && !handleMoreResult &&
                <Paper>
                    <FilterChip classes={classes} filteredChip={filteredChip} handleFilterDelete={handleFilterDelete} />
                    <Divider />
                    <TableContainer  style={{maxHeight: "650px"}}>
                        <Table stickyHeader aria-label="simple table">
                            <PriceActionTableHeadings headings={highLowHeadings()} classes={classes} />
                            <TableBody>
                                {priceActionHighLowResponseData.map((row: any, key: any) => (
                                    <TableRow
                                        key={row.id}
                                    >
                                        <NamePriceFields idx={key} row={row} handleMorePopup={handleMorePopup} />
                                        <TableCell align="left">
                                            <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                                                {`${row.highs_lows?.high}`}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                                                {`${row.highs_lows?.high_timestamp}`}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                                                {`${row.highs_lows?.low}`}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                                                {`${row.highs_lows?.low_timestamp}`}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                                                {`${row.highs_lows?.price_change}`}
                                            </Typography>
                                        </TableCell>
                                        <CommonFields row={row} handleMorePopup={handleMorePopup} />
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {renderViewMore()}
                </Paper>
            }
            {/* Breakout/Breakdown */}
            {
                priceActionBrealoutDownResponseData.length > 0 && !handleMoreResult && <Paper>
                    <FilterChip classes={classes} filteredChip={filteredChip} handleFilterDelete={handleFilterDelete} />
                    <Divider />
                    <TableContainer style={{maxHeight: "650px"}}>
                        <Table stickyHeader aria-label="simple table">
                            <PriceActionTableHeadings headings={["Name", "Price", "24h High", "24h Low", "Market Cap"]} classes={classes} />
                            <TableBody>
                                {priceActionBrealoutDownResponseData.map((row: any, key: any) => (
                                    <TableRow
                                        key={row.id}
                                    >
                                        <NamePriceFields idx={key} row={row} handleMorePopup={handleMorePopup} />
                                        <CommonFields row={row} handleMorePopup={handleMorePopup} />
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            }
            {/* Support/Resistance */}
            {
                priceActionSupportResistanceResponseData.length > 0 && !handleMoreResult && <Paper>
                    <FilterChip classes={classes} filteredChip={filteredChip} handleFilterDelete={handleFilterDelete} />
                    <Divider />
                    <TableContainer style={{maxHeight: "650px"}}>
                        <Table stickyHeader aria-label="simple table">
                            <PriceActionTableHeadings headings={supportResistanceHeadings()} classes={classes} />
                            <TableBody>
                                {priceActionSupportResistanceResponseData.map((row: any, key: any) => (
                                    <TableRow
                                        key={row.id}
                                    >
                                        <NamePriceFields idx={key} row={row} handleMorePopup={handleMorePopup} />
                                        <TableCell>
                                            <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                                                {row['NEAR_S1'] && `${row['NEAR_S1']}`}
                                                {row['NEAR_S2'] && `${row['NEAR_S2']}`}
                                                {row['NEAR_S3'] && `${row['NEAR_S3']}`}
                                                {row['NEAR_R1'] && `${row['NEAR_R1']}`}
                                                {row['NEAR_R2'] && `${row['NEAR_R2']}`}
                                                {row['NEAR_R3'] && `${row['NEAR_R3']}`}
                                                {row['BREAKDOWN_S1'] && `${row['BREAKDOWN_S1']}`}
                                                {row['BREAKDOWN_S2'] && `${row['BREAKDOWN_S2']}`}
                                                {row['BREAKDOWN_S3'] && `${row['BREAKDOWN_S3']}`}
                                                {row['BREAKDOWN_R1'] && `${row['BREAKDOWN_R1']}`}
                                                {row['BREAKDOWN_R2'] && `${row['BREAKDOWN_R2']}`}
                                                {row['BREAKDOWN_R3'] && `${row['BREAKDOWN_R3']}`}
                                            </Typography>
                                        </TableCell>
                                        <CommonFields row={row} handleMorePopup={handleMorePopup} />
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {renderViewMore()}
                </Paper>
            }
            {/* Standard Pattern */}
            {
                priceActionStandardPatternsResponseData.length > 0 && !handleMoreResult && <Paper>
                    <FilterChip classes={classes} filteredChip={filteredChip} handleFilterDelete={handleFilterDelete} />
                    <Divider />
                    <TableContainer  style={{maxHeight: "650px"}}>
                        <Table stickyHeader aria-label="simple table">
                            <PriceActionTableHeadings headings={['Name', 'Price', '24h High', '24h Low', 'Market Cap']} classes={classes} />
                            <TableBody>
                                {priceActionStandardPatternsResponseData.map((row: any, key: any) => (
                                    <TableRow
                                        key={row.id}
                                    >
                                        <NamePriceFields idx={key} row={row} handleMorePopup={handleMorePriceActionWithDataPoints} />
                                        <CommonFields row={row} handleMorePopup={handleMorePriceActionWithDataPoints} />
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {renderViewMore()}
                </Paper>
            }
            {/* Candel Stick */}
            {
                priceActionCandelSticksResponseData.length > 0 && !handleMoreResult && <Paper>
                    <FilterChip classes={classes} filteredChip={filteredChip} handleFilterDelete={handleFilterDelete} />
                    <Divider />
                    <TableContainer  style={{maxHeight: "650px"}}>
                        <Table stickyHeader aria-label="simple table">
                            <PriceActionTableHeadings headings={['Name', 'Price', 'Up Trend', 'Down Trend', '24h High', '24h Low', 'Market Cap']} classes={classes} />
                            <TableBody>
                                {priceActionCandelSticksResponseData.map((row: any, key: any) => (
                                    <TableRow
                                        key={row.id}
                                    >
                                        <NamePriceFields idx={key} row={row} handleMorePopup={handleMorePopup} />
                                        <TableCell align="left">
                                            <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                                                {`${row.uptrend}`}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Typography gutterBottom variant="body2" component="div" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                                                {`${row.downtrend}`}
                                            </Typography>
                                        </TableCell>
                                        <CommonFields row={row} handleMorePopup={handleMorePopup} />
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {renderViewMore()}
                </Paper>
            }
            {/* Reversal/Continuation */}
            {
                priceActionReversalContinualtionResponseData.length > 0 && !handleMoreResult && <Paper>
                    <FilterChip classes={classes} filteredChip={filteredChip} handleFilterDelete={handleFilterDelete} />
                    <Divider />
                    <TableContainer style={{maxHeight: "650px"}}>
                        <Table stickyHeader aria-label="simple table">
                            <PriceActionTableHeadings headings={reversalHeading()} classes={classes} />
                            <TableBody>
                                {priceActionReversalContinualtionResponseData.map((row: any, key: any) => (
                                    <TableRow
                                        key={row.id}
                                    >
                                        <NamePriceFields idx={key} row={row} handleMorePopup={handleMorePriceActionWithDataPoints} />
                                        {
                                            (filteredChip[0].includes('bearish reversals') && filteredChip[0].includes('double_top_pattern'))
                                            && <BearishBullishDoublePattern first={row.points.first_high} firstTimestamp={row.points.first_high_timestamp}
                                                mid={row.points.second_low} midTimestamp={row.points.second_low_timestamp} second={row.points.second_high}
                                                secondTimestamp={row.points.second_high_timestamp} classes={classes} />
                                        }
                                        {
                                            (filteredChip[0].includes('bullish reversals') && filteredChip[0].includes('double_bottom_pattern'))
                                            && <BearishBullishDoublePattern first={row.points.first_low} firstTimestamp={row.points.first_low_timestamp}
                                                mid={row.points.first_high} midTimestamp={row.points.first_high_timestamp} second={row.points.second_low}
                                                secondTimestamp={row.points.second_low_timestamp} classes={classes} />
                                        }
                                        {
                                            (filteredChip[0].includes('bullish reversals') && filteredChip[0].includes('triple_bottom_pattern'))
                                            && <BearishBullishTriplePattern first={row.points.first_low} firstTimestamp={row.points.first_low_timestamp}
                                                firstMid={row.points.first_high} firstMidTimestamp={row.points.first_high_timestamp}
                                                second={row.points.second_low} secondTimestamp={row.points.second_low_timestamp}
                                                secondMid={row.points.second_high} secondMidTimestamp={row.points.second_high_timestamp}
                                                third={row.points.third_low} thirdTimestamp={row.points.third_low_timestamp} classes={classes} />
                                        }
                                        {
                                            (filteredChip[0].includes('bearish reversals') && filteredChip[0].includes('triple_top_pattern'))
                                            && <BearishBullishTriplePattern first={row.points.first_high} firstTimestamp={row.points.first_high_timestamp}
                                                firstMid={row.points.second_low} firstMidTimestamp={row.points.second_low_timestamp}
                                                second={row.points.second_high} secondTimestamp={row.points.second_high_timestamp}
                                                secondMid={row.points.third_low} secondMidTimestamp={row.points.third_low_timestamp}
                                                third={row.points.third_high} thirdTimestamp={row.points.third_high_timestamp} classes={classes} />
                                        }
                                        {
                                            (filteredChip[0].includes('bearish reversals') && filteredChip[0].includes('head_shoulder_pattern')) &&
                                            <BearishBullishHeadShoulderPattern
                                                shoulder1={row.points.first_high} shoulder1Timestamp={row.points.first_high_timestamp}
                                                firstMid={row.points.second_low} firstMidTimestamp={row.points.second_low_timestamp}
                                                head={row.points.second_high} headTimestamp={row.points.second_high_timestamp}
                                                secondMid={row.points.third_low} secondMidTimestamp={row.points.third_low_timestamp}
                                                shoulder2={row.points.third_high} shoulder2Timestamp={row.points.third_high_timestamp}
                                                classes={classes}
                                            />
                                        }
                                        {
                                            (filteredChip[0].includes('bullish reversals') && filteredChip[0].includes('inverted_head_shoulder_pattern'))
                                            &&
                                            <BearishBullishHeadShoulderPattern
                                                shoulder1={row.points.first_low} shoulder1Timestamp={row.points.first_low_timestamp}
                                                firstMid={row.points.first_high} firstMidTimestamp={row.points.first_high_timestamp}
                                                head={row.points.second_low} headTimestamp={row.points.second_low_timestamp}
                                                secondMid={row.points.second_high} secondMidTimestamp={row.points.second_high_timestamp}
                                                shoulder2={row.points.third_low} shoulder2Timestamp={row.points.third_low_timestamp}
                                                classes={classes}
                                            />
                                        }
                                        {
                                            (row.points['upper_first']) &&
                                            <ContinuationPatterns
                                                upper_first={row.points.upper_first} upper_first_timestamp={row.points.upper_first_timestamp}
                                                upper_last={row.points.upper_last} upper_last_timestamp={row.points.upper_last_timestamp}
                                                lower_first={row.points.lower_first} lower_first_timestamp={row.points.lower_first_timestamp}
                                                lower_last={row.points.lower_last} lower_last_timestamp={row.points.lower_last_timestamp}
                                                classes={classes}
                                            />
                                        }
                                        {
                                            (row.points['top_first']) &&
                                            <ContinuationPatterns
                                                upper_first={row.points.top_first} upper_first_timestamp={row.points.top_first_timestamp}
                                                upper_last={row.points.top_last} upper_last_timestamp={row.points.top_last_timestamp}
                                                lower_first={row.points.bottom_first} lower_first_timestamp={row.points.bottom_first_timestamp}
                                                lower_last={row.points.bottom_last} lower_last_timestamp={row.points.bottom_last_timestamp}
                                                classes={classes}
                                            />
                                        }
                                        <CommonFields row={row} handleMorePopup={handleMorePriceActionWithDataPoints} />
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {renderViewMore()}
                </Paper>
            }
    </>
    // Customizable Area End
  )
}

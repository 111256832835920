import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { toast } from "react-toastify";
import { setStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  enabledForm: string;
  showPass: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class EmailAccountLoginWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  emailAccountLoginApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage)

      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      enabledForm: "Login",
      showPass: false,
      loading: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      runEngine.debugLog("API Message Recived", message);

      if (apiRequestCallId === this.emailAccountLoginApiCallId) {
        this.setState({ loading: false });
        if (responseJson && responseJson.meta) {
          const userId = JSON.stringify(responseJson.meta.id);
          await setStorageData("userId", userId);
          window.localStorage.setItem("token", responseJson.meta.token);
          window.localStorage.setItem("firstRender", "true");
          window.location.href = "/";
        } else if (responseJson.errors) {
          if (responseJson.errors[0].failed_login) {
            toast.error(responseJson.errors[0].failed_login);
          }
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  activeForm(flag: string) {
    if (flag.toUpperCase() === "Signup".toUpperCase()) {
      this.setState(
        {
          enabledForm: "Signup"
        },
        () => {
          this.navigationLinkPressed("EmailAccountRegistrationWeb");
        }
      );
    }
  }

  navigationLinkPressed = (path: string) => {
    let toNavigate = new Message(getName(MessageEnum.NavigationMessage));
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), path);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
  };

  onImgClick = () => {
    window.location.href = "/";
  };

  togglePassword = () => {
    this.setState(previousState => ({
      showPass: !previousState.showPass
    }));
  };

  login(data: any) {
    this.setState({ loading: true });
    const header = {
      "Content-Type": "application/json"
    };
    const httpBody = { data: data };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.emailAccountLoginApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_login/logins"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}

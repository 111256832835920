// import React from 'react';

// const defaultVal = {mode: 'light'} //Insert the default value here.
// export const MainContext = React.createContext(defaultVal);

import React, { useState } from 'react';


type ContextType = {
  mode: any;
  addData: (anything: any) => void;
 
};

export const CommonContext:any = React.createContext<ContextType | null>(null);

export const CommonContextProvider = (props: any) => {
  const [mode, setData] = useState('light');


  const addData = (anything: any) => {
    setData(anything);
  };
 

  



  return (
    <CommonContext.Provider
      value={{
        mode,
        addData,
      
      }}
    >
      {props.children}
    </CommonContext.Provider>
  );
};

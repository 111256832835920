import React from "react";
// Customizable Area Start
import ManageCardsWebController, {
    Props
} from "./ManageCardsController.web";

import {
    withStyles, StyleRules
} from "@material-ui/core/styles";

import Paper from '@material-ui/core/Paper';
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import Button from '@material-ui/core/Button';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';

import {
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel
} from '@material-ui/core';

import Loader from "../../../components/src/Loader.web";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import GridView from "../../../components/src/CardView.web";
import LoginAlertDialogWeb from "../../../components/src/LoginAlertDialog.web";

// Customizable Area End
const styles: StyleRules = {
    // Customizable Area Start
    root: {
        // margin: '22px',
        overflowX: 'hidden',
        height: '100vh'
        // marginRight: '3px',
    },
    paper: {
        // background: '#ffffff',
        padding: '24px',
        borderRadius: '12px'
    },
    header: {
        fontFamily: 'Plus Jakarta Text',
        fontWeight: 'bold',
        fontSize: '20px'
    },
    cardsContainer: {
        height: "100%", // So that grids 1 & 4 go all the way down
        minHeight: 250, // Give minimum height to a div
        textAlign: "left"
    },
    cardsMainTitle: {
        // color: '#464746',
        fontWeight: 'bold',
        fontSize: '13px',
        margin: '12px 0px',
    },
    card: {
        '& .MuiCardHeader-root': {
            padding: '8px !important'
        },
        '& .MuiCardContent-root': {
            paddingTop: '0px',
            paddingBottom: '5px !important'
        },
    },
    cardsButtonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    cardButtons: {
        background: '#e0e0e0',
        color: '#000000',
        opacity: '0.8',
        fontWeight: 'bold',
        cursor: 'pointer',
        padding: '15px',
        border: 'none',
        borderRadius: '10px',
        textAlign: 'center',
        display: 'block',
        margin: '2% auto',
        width: '100%'
    },
    editCardsButton: {
        backgroundColor: 'rgba(1,162,78,255)',
        color: '#ffffff',
        cursor: 'pointer',
        padding: '8px 20px',
        marginLeft: '10px',
        border: 'none',
        borderRadius: '10px',
        textAlign: 'center',
        fontWeight: 'bold'
    },
    radio: {
        '& .MuiTypography-root': {
            fontWeight: "bold"
        },
        '& .MuiRadio-colorSecondary.Mui-checked': {
            color: 'green'
        }
    },
    headerDiv: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '22px'
    },
    rightFeilds: {
        display: 'contents',
        '& .MuiInputBase-root': {
            fontWeight: 'bold',
        },
        '& .MuiFormLabel-root.Mui-error': {
            color: "#000",
            opacity: "0.5"
        }
    },
    subRightFeilds: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '8px',
        paddingTop: '8px',
        width: '25%'
    },
    parentDiv: {
        display: 'flex',
        flexDirection: 'row',
        gap: '10px'
    },
    childDiv: {
        width: '23%',
        maxWidth: '450px',
        padding: '7px 0px 0px 12px'
    },
    subParentDiv: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    subLeftDiv: {
        width: '33%',
        paddingRight: '8px'
    },
    subRightDiv: {
        width: '33%',
        padding: '16px 0px'
    },
    dialogTitle: {
        textAlign: 'center', 
        color: 'red', 
        fontWeight: 'bold'
    },
    dialogActions: {
        justifyContent: 'center', 
        marginBottom: 16
    },
    noDeleteButton: {
        backgroundColor: 'rgba(1,162,78,255)',
        color: '#ffffff',
        cursor: 'pointer',
        padding: '8px 20px',
        margin: '0px 10px',
        whiteSpace: 'nowrap',
        border: 'none',
        borderRadius: '10px',
        textAlign: 'center',
    },
    deleteButton: {
        backgroundColor: 'rgba(1,162,78,255)',
        color: '#ffffff',
        cursor: 'pointer',
        padding: '8px 20px',
        margin: '0px 10px',
        border: 'none',
        borderRadius: '10px',
        textAlign: 'center'    
    },
    '@media (min-width: 1650px)': {
        childDiv: {
            padding: '7px 500px 0px 12px'
        },
    },
    '@media (max-width: 768px)': {
        rightFeilds: {
            display: 'block'
        },
        subRightFeilds: {
            width: '100%',
            marginTop: '0px',
            paddingTop: '0px'
        },
        childDiv: {
            width: '45%',
            padding: '0px 0px 16px 0px'
        },
    },
    '@media (max-width: 622px)': {
        parentDiv: {
            display: 'block',
        },
        childDiv: {
            width: '100%',
            padding: '0px 0px 16px 0px'
        },
        headerDiv: {
            flexDirection: 'column'
        }
    },
    '@media (max-width: 375px)': {
        subLeftDiv: {
            width: '40%',
        },
        subRightDiv: {
            width: '20%'
        }
    }
    // Customizable Area End
}
// Customizable Area Start
const editCardsSchema = Yup.object().shape({
    name: Yup.string()
        .required('Name is required')
        .matches(/^[a-zA-Z ]*$/, "Enter valid ame")
        .max(100, 'Maximum name length is 100'),
    card_number: Yup.string()
        .matches(/^\d*$/g, 'Enter only numbers')
        .required('Card Number is required')
        .min(16, 'Minimum Card Number length is 16')
        .max(16, 'Maximum Card Number length is 16'),
    month: Yup.string()
        .required('Expiry Month is required'),
    year: Yup.string()
        .required('Expiry Year is required'),
    cvv: Yup.string()
        .matches(/^\d*$/g, 'Enter only numbers')
        .required('CVV is required')
        .min(3, 'Minimum CVV length is 3')
        .max(3, 'Maximum CVV length is 3')
});
// Customizable Area End
export class ManageCardsWeb extends ManageCardsWebController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    leftSection = (values: any, setFieldValue: any) => {
        const { classes } = this.props;
        const { cardInitialState, cardIsPrimary } = this.state;
        return (
            <div className={classes.childDiv} style={{}}>
            {
                this.state.editCardStatus == 'ADD' ?
                    (
                        <FormControl component="fieldset" className={classes.radio}>
                            <FormLabel component="legend"></FormLabel>
                            <RadioGroup name={'is_primary'} value={values.is_primary} onChange={(event) => {
                                setFieldValue('is_primary', event.currentTarget.value == 'true' ? true : false);
                            }}>
                                <FormControlLabel value={true} control={<Radio />} label="Primary Card" />
                                <FormControlLabel value={false} control={<Radio />} label="Secondary Card" />
                            </RadioGroup>
                        </FormControl>
                    )
                    :
                    (
                        <Card className={classes.card} style={{ minHeight: '150px', borderRadius: '8px', padding: '8px', background: '#01a24e', color: '#ffffff' }}>
                            <CardHeader
                                action={
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={cardInitialState?.attributes?.is_primary || false}
                                                onChange={(ev: any) => {
                                                    setFieldValue("is_primary", ev.currentTarget.checked);
                                                    const initialStateTemp: any = cardInitialState;
                                                    initialStateTemp.attributes.is_primary = ev.currentTarget.checked;
                                                    this.setState({
                                                        cardInitialState: initialStateTemp
                                                    }, () => {
                                                    });
                                                }}
                                                disabled={ cardIsPrimary }
                                                name="isPrimary"
                                                color="primary"
                                            />
                                        }
                                        label=""
                                    />
                                }
                                title={
                                    <Typography gutterBottom variant="body2" component="div" style={{ color: '#58c28b', fontWeight: 'bold' }}>
                                        {`Name`}
                                    </Typography>
                                }
                                subheader={
                                    <Typography gutterBottom variant="body2" component="div" style={{ color: '#ffffff', fontSize: '14px', fontWeight: 'bold', overflowWrap: 'break-word' }}>
                                        {values.name}
                                    </Typography>
                                }
                            />
                            <CardContent>
                                <Typography gutterBottom variant="body2" component="div" style={{ color: '#ffffff', fontSize: '18px', fontWeight: 'bold', marginBottom: '12px' }}>
                                    {`**** **** **** ${values.card_number.toString().substr(values.card_number.toString().length - 4)}`}
                                </Typography>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div style={{ paddingRight: '2rem' }}>
                                        <Typography gutterBottom variant="body2" component="div" style={{ color: '#58c28b', fontWeight: 'bold' }}>
                                            {`EXP`}
                                        </Typography>
                                        <Typography variant="body2" style={{ color: '#ffffff', fontWeight: 'bold' }}>
                                            {`${values.month}/${values.year}`}
                                        </Typography>
                                    </div>
                                    <div>
                                        <Typography gutterBottom variant="body2" component="div" style={{ color: '#58c28b', fontWeight: 'bold' }}>
                                            {`CVV`}
                                        </Typography>
                                        <Typography variant="body2" style={{ color: '#ffffff', fontWeight: 'bold' }}>
                                            {values.cvv}
                                        </Typography>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    )
            }
        </div>

        )
    }

    rightSection = (values: any, handleChange: any, handleBlur: any, errors: any, touched: any) => {
        const { classes } = this.props;
        const { cardNumberError, expMonthError, cvvError } = this.state;
        return (
            <div className={classes.rightFeilds}>
                <div className={classes.subRightFeilds} >
                    <Field
                        validateOnBlur
                        validateOnChange
                        name="name"
                        render={({ field, form }: any) => (
                            <TextField
                                label={"Name on the card"}
                                name={"name"}
                                value={values.name}
                                variant="filled"
                                InputProps={{ disableUnderline: true }}
                                InputLabelProps={{ color: 'primary' }}
                                error={
                                    Boolean(form.errors.name && form.touched.name)
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText={
                                    form.errors.name &&
                                    form.touched.name &&
                                    String(form.errors.name)
                                }
                                style={{ marginBottom: '15px' }}
                            />
                        )}
                    />
                    <Field
                        validateOnBlur
                        validateOnChange
                        name="card_number"
                        render={({ field, form }: any) => (
                            <TextField
                                label={"Card Number"}
                                name={"card_number"}
                                type="text"
                                value={values.card_number}
                                variant="filled"
                                inputProps={{ maxLength: 16 }}
                                InputProps={{ disableUnderline: true }}
                                error={
                                    Boolean((form.errors.card_number && form.touched.card_number) || cardNumberError)
                                }
                                onChange={(e) => { 
                                        this.setState({ cardNumberError: null })
                                        handleChange(e)
                                    }}
                                onBlur={handleBlur}
                                helperText={
                                    (form.errors.card_number &&
                                    form.touched.card_number &&
                                    String(form.errors.card_number))
                                    || cardNumberError
                                }
                            />
                        )}
                    />
                </div>
                <div className={classes.subParentDiv} >
                <div className={classes.subLeftDiv} >
                    <TextField
                        id="month"
                        name="month"
                        select
                        label={`Exp. MM`}
                        value={values.month}
                        onChange={(e) => { 
                            this.setState({ expMonthError: null })
                            handleChange(e, "month")
                        }}
                        className={classes.textField}
                        margin="normal"
                        fullWidth
                        variant="filled"
                        InputProps={{ disableUnderline: true }}
                        SelectProps={{
                            MenuProps: {
                              anchorPosition: {
                                top: 245,
                                left: 500
                              },
                            },
                        }}
                        onBlur={handleBlur}
                        helperText={
                            (errors.month &&
                            touched.month &&
                            String(errors.month)) ||
                            expMonthError
                        }
                        error={
                            Boolean((errors.month && touched.month) || expMonthError)
                         }
                    >
                        {this.state.expMonth.map((month: string) => (
                            <MenuItem key={month} value={month}>
                                {month}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
                {/* <Grid item xs>
                    <TextField
                        id="expDate"
                        select
                        label="DD"
                        className={classes.textField}
                        value={values.expDate}
                        onChange={handleChange("expDate")}
                        margin="normal"
                        fullWidth
                        variant="filled"
                        InputProps={{ disableUnderline: true }}
                    >
                        {this.state.expDate.map((date: number) => (
                            <MenuItem key={date} value={date}>
                                {date}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid> */}
                <div className={classes.subLeftDiv} >
                    <TextField
                        id="year"
                        select
                        label={`Exp. YY`}
                        className={classes.textField}
                        value={values.year}
                        onChange={handleChange("year")}
                        margin="normal"
                        fullWidth
                        variant="filled"
                        InputProps={{ disableUnderline: true }}
                        helperText={
                            errors.year &&
                            touched.year &&
                            String(errors.year)
                        }
                        error={
                            Boolean(errors.year && touched.year)
                        }
                    >
                        {this.state.expYear.map((year: number) => (
                            <MenuItem key={year} value={year}>
                                {year}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
                <div className={classes.subRightDiv} >
                    <Field
                        validateOnBlur
                        validateOnChange
                        name="cvv"
                        render={({ field, form }: any) => (
                            <TextField
                                label={"CVV"}
                                name={"cvv"}
                                type="text"
                                value={values.cvv}
                                variant="filled"
                                InputProps={{ disableUnderline: true }}
                                error={
                                    Boolean((form.errors.cvv && form.touched.cvv) || cvvError)
                                }
                                onChange={(e) => { 
                                    this.setState({ cvvError: null })
                                    handleChange(e);
                                }}
                                onBlur={handleBlur}
                                helperText={
                                    (form.errors.cvv &&
                                    form.touched.cvv &&
                                    String(form.errors.cvv)) ||
                                    cvvError
                                }
                            />
                        )}
                    />
                </div>
                </div>
            </div>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { cardData, cardInitialState } = this.state;
        const bgColor = localStorage.getItem('appTheme') == 'ligthTheme' ? '#F4F8F6' : '#232429';
        return (
            <Paper className={classes.root} data-testid='manageCardPaper' elevation={0} square style={{ backgroundColor: bgColor }}>
                {(!this.state.token || this.state.token==="") && <LoginAlertDialogWeb />}
                {this.state.loading && <Loader loading={this.state.loading} />}
                {this.state.editCardStatus == 'GRID' ?
                    <GridView token={this.state.token} classes={classes} cardData={cardData} createOrUpdateCardDetailsFlag={this.createOrUpdateCardDetailsFlag} 
                    isCardPrimaryChange={this.isCardPrimaryChange} deleteCard={this.deleteCard} />    
                    :
                    <Paper elevation={0} className={classes.paper} style={{ backgroundColor: bgColor }}>
                        <Formik
                            initialValues={{
                                is_primary: cardInitialState?.attributes?.is_primary || false,
                                name: cardInitialState?.attributes?.name || "",
                                card_number: cardInitialState?.attributes?.card_number || "",
                                month: (cardInitialState?.attributes?.month < 9 ? `0${cardInitialState?.attributes?.month}` : cardInitialState?.attributes?.month.toString()) || "",
                                year: cardInitialState?.attributes?.year || "",
                                cvv: cardInitialState?.attributes?.cvv || ""
                            }}
                            validationSchema={editCardsSchema}
                            onSubmit={(values) => {
                                this.createOrUpdateCardDetails(values);
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                setFieldValue,
                            }) => (
                                <Form onSubmit={handleSubmit}>
                                    <div className={classes.headerDiv} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '22px' }}>
                                        <div>
                                            <Button
                                                style={{ textTransform: "none", fontWeight: 'bold' }}
                                                startIcon={<ArrowLeftIcon style={{ fontSize: '28px', fontWeight: 'bold' }} />}
                                                onClick={(event: any) => this.setState({
                                                    editCardStatus: 'GRID'
                                                })}
                                            >
                                                <span style={{ fontSize: '20px', fontWeight: 'bold' }}>{this.state.editCardStatus == 'ADD' ? `Add a Card` : `Edit Card Details`}</span>
                                            </Button>
                                        </div>
                                        <div style={{ display: 'flex' }} >
                                            <button type="button" className={classes.editCardsButton} style={{ background: '#e0e0e0', color: '#444745' }} onClick={(e: any) => 
                                                { this.getCards(); this.setState({ editCardStatus: 'GRID' }); }}>Cancel</button>
                                            <button type="submit" data-testid="save" className={classes.editCardsButton}>Save Changes</button>
                                        </div>
                                    </div>
                                    <div className={classes.parentDiv} >
                                        { this.leftSection(values, setFieldValue) }
                                        { this.rightSection( values, handleChange, handleBlur, errors, touched )}
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </Paper>
                }
            </Paper>
        )
        // Customizable Area End
    }
}

export default withStyles(styles)(ManageCardsWeb);

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    BarController
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { isEmpty } from "../../../framework/src/Utilities";
import moment from "moment";
import { toast } from "react-toastify";
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: boolean;
    token: any;
    trendingAsset: any;
    topGainers: any;
    topLosers: any;
    recentlyAdded: any;
    cryptoCategories: any;
    cryptoData: any;
    newsData: any;
    bullishTimeframe: any;
    bearishTimeframe: any;
    lineOptions: any;
    selectedCategoryItem: any;
    showMore: boolean;
    bullishStocks: any;
    bearishStocks: any;
    showMoreBullishStock: boolean;
    showMoreBearishStock: boolean;
    carousalData: any;
    currentUser: any;
    showUpgradePlanAlert: boolean;
    planExpiringInDays: number | null;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class LandingPageWebController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    getTopTenCryptoApiCallId: string = '';
    getCryptoCategoriesApiCallId: string = '';
    getSelectedCategoryCryptoApiCallId: string = '';
    getTredingDataCardsApiCallId: string = '';
    getTopGainersDataCardsApiCallId: string = '';
    getTopLosersDataCardsApiCallId: string = '';
    getRecentlyAddedDataCardsApiCallId: string = '';
    getNotificationApiCallId: string = '';
    getTopBullishStocksApiCallId: string = '';
    getTopBearishStocksApiCallId: string = '';
    getNewsApiCallId: string = '';
    getCarousalImagesApiCallId: string= '';
    getProfileApiCallId: string='';
    subscriptionAccessibilityApiCallId: string='';
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            getName(MessageEnum.NavigationPayLoadMessage)
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            loading: false,
            token: localStorage.getItem('token') || "",
            trendingAsset: [],
            topGainers: [],
            topLosers: [],
            recentlyAdded: [],
            cryptoCategories: [],
            cryptoData: [],
            newsData: [],
            bullishTimeframe: 'hourly',
            bearishTimeframe: 'hourly',
            showMore: false,
            bullishStocks: [],
            bearishStocks: [],
            showMoreBearishStock: false,
            showMoreBullishStock: false,
            lineOptions: {
                responsive: true,
                elements: {
                    point: {
                        radius: 0
                    }
                },
                plugins: {
                    title: {
                        display: false,
                        text: 'Chart.js Line Chart',
                    },
                    legend: {
                        display: false,
                        position: 'top' as const,
                    },
                    tooltip: {
                        displayColors: false,
                        callbacks: {
                            title: "",
                            label: function (context: any) {
                                let label = context.dataset.label || '';

                                if (label) {
                                    label += ': ';
                                }
                                if (context.parsed.y !== null) {
                                    label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
                                }
                                return label;
                            }
                        }
                    }
                },
                scales: {
                    x: {
                        grid: {
                            display: false,
                            drawBorder: false
                        },
                        ticks: {
                            callback: function (value: any, index: any, values: any) {
                                return '';
                            }
                        }
                    },
                    y: {
                        //beginAtZero: true,
                        grid: {
                            display: false,
                            drawBorder: false
                        },
                        ticks: {
                            //stepSize: 10000,
                            // Include a dollar sign in the ticks
                            callback: function (value: any, index: any, values: any) {
                                // return '$' + value + '.00';
                                return '';
                            }
                        }
                    }
                }
            },
            selectedCategoryItem: 'Top 10 Crypto Details',
            carousalData: [],
            currentUser: null,
            showUpgradePlanAlert: false,
            planExpiringInDays: null
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            switch (apiRequestCallId) {
                case this.getTredingDataCardsApiCallId:
                    this.setState({
                        loading: false,
                        trendingAsset: responseJson.data
                    }); 
                    break;
                case this.getTopGainersDataCardsApiCallId:
                    this.setState({
                        loading: false,
                        topGainers: responseJson.data
                    });
                    break;
                case this.getTopLosersDataCardsApiCallId:
                    this.setState({
                        loading: false,
                        topLosers: responseJson.data
                    });
                    break;
                case this.getRecentlyAddedDataCardsApiCallId:
                    this.setState({
                        loading: false,
                        recentlyAdded: responseJson.data
                    });
                    break;
                case this.getCryptoCategoriesApiCallId:
                    this.setState({ cryptoCategories: responseJson.data || [] });
                    break;
                case this.getTopTenCryptoApiCallId:
                case this.getSelectedCategoryCryptoApiCallId:
                    this.setState({
                        loading: false,
                        cryptoData: responseJson?.data || []
                    });
                    break;
                case this.getNotificationApiCallId:
                    if (responseJson?.data) {
                        localStorage.setItem("notificationData", JSON.stringify(responseJson.data));
                    }
                    break;
                case this.getTopBullishStocksApiCallId:
                    this.setState({ loading: false, bullishStocks: this.state.showMoreBullishStock ? responseJson.data.slice(0, 50) : responseJson.data.slice(0, 5) });
                    break;
                case this.getTopBearishStocksApiCallId:
                    this.setState({ loading: false, bearishStocks: this.state.showMoreBearishStock ? responseJson.data.slice(0, 50) : responseJson.data.slice(0, 5) });
                    break;
                case this.getNewsApiCallId:
                    this.setState({ newsData: responseJson?.data || [] });
                    break;
                case this.getCarousalImagesApiCallId:
                    this.handleCarouselResponse(responseJson);
                    break;
                case this.getProfileApiCallId:
                    this.setState({ currentUser: responseJson.data });
                    this.getSubscriptionAccessibility();
                    this.upgradePlanAlert();
                    break;
                case this.subscriptionAccessibilityApiCallId:
                    this.handlePlanDetails(responseJson);
                    break;
            }

        }
        // Customizable Area End
    }

    async componentDidMount() {
        // Customizable Area Start
        ChartJS.register(
            CategoryScale,
            LinearScale,
            BarElement,
            BarController,
            PointElement,
            LineElement,
            Title,
            Tooltip,
            Legend,
            Filler,
            ChartDataLabels
        );
        this.getTredingDataCards();
        this.getTopGainersDataCards();
        this.getTopLosersDataCards();
        this.getRecentlyAddedDataCards();
        this.getCryptoCategories();
        this.getTopTenCryptoDetails({ name: 'Top 10 Crypto Details' });
        this.getNewsAPIDetails();
        this.getTopBullishStocks("hourly");
        this.getTopBearishStocks("hourly");
        this.getNotificationDetails();
        this.getCarousalImage();
        if (this.state.token)
            this.getProfile();
        if (this.state.token === "")
            this.getSubscriptionAccessibility();
        let theme = localStorage.getItem("appTheme");
        if (theme == undefined || theme == null || theme == "" || theme == "ligthTheme") {
            localStorage.setItem('appTheme', 'ligthTheme');
        } else {
            localStorage.setItem('appTheme', 'darkTheme');
        }
        // (adsbygoogle = (window as any).adsbygoogle || []).push({});
        // Customizable Area End
    }

    // Customizable Area Start
    handlePlanDetails(data: any) {
        const { currentUser } = this.state;
        const subsType = currentUser ? currentUser.attributes.subscription_plan?.data?.attributes?.subscription_type : 'basic';
        localStorage.setItem('currentPlan', JSON.stringify(data[subsType]));
    }

    handleCarouselResponse(responseJson: any) {
        if (responseJson.data) {
            let filterData = responseJson.data.filter((_data:any) => _data.attributes.web_image);
            this.setState({ carousalData: filterData || [] });
        }
    }

    getCarousalImage () {
        this.setState({ loading: true });
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getCarousalImagesApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_carouseldisplay/carouseldisplays");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getTredingDataCards() {
        this.setState({ loading: true });
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getTredingDataCardsApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_dashboard/trending_coins");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getTopGainersDataCards() {
        this.setState({ loading: true });
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getTopGainersDataCardsApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_dashboard/top_gainers");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getTopLosersDataCards() {
        this.setState({ loading: true });
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getTopLosersDataCardsApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_dashboard/top_losers");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getRecentlyAddedDataCards() {
        this.setState({ loading: true });
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getRecentlyAddedDataCardsApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_dashboard/recently_added");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getCryptoCategories() {
        //this.setState({ loading: true });
        const header = { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getCryptoCategoriesApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_dashboard/crypto_categories");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getNewsAPIDetails() {
        this.setState({ loading: true });
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getNewsApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_dashboard/news");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getTopTenCryptoDetails(category: any, showMore: boolean = false) {
        this.setState({ loading: true, selectedCategoryItem: category.name, showMore });
        const header = { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getTopTenCryptoApiCallId = requestMessage.messageId;
        const path = showMore ? "bx_block_dashboard/top_10_crypto_data" : "bx_block_dashboard/top_10_crypto_data?data=10";
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), path);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getSelectedCategoryCryptoDetails = (category: any, showMore: boolean = false) => {
        this.setState({ loading: true, selectedCategoryItem: category.name, showMore });
        const header = { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getSelectedCategoryCryptoApiCallId = requestMessage.messageId;
        const path = showMore ? "bx_block_dashboard/category_wise_crypto?crypto_category_id=" + category.id : "bx_block_dashboard/category_wise_crypto?crypto_category_id=" + category.id + "&data=" + 10
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), path);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getTopBullishStocks(time_frame: string) {
        this.setState({ loading: true});
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getTopBullishStocksApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_dashboard/top_bullish_stocks?time_frame=${time_frame}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getTopBearishStocks (time_frame: string) {
        this.setState({ loading: true});
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getTopBearishStocksApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_dashboard/top_bearish_stocks?time_frame=${time_frame}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getSubscriptionAccessibility() {
        this.setState({ loading: false });
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.subscriptionAccessibilityApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_custom_user_subs/subscriptions/subscription_plan_details");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getProfile () {
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getProfileApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "account_block/accounts/show");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    bullishTimeframeButtonClicked = (event: any) => {
        this.setState({
            bullishTimeframe: event.currentTarget.id,
            showMoreBullishStock: false
        });
        this.getTopBullishStocks(event.currentTarget.id);
    }

    bearishTimeframeButtonClicked = (event: any) => {
        this.setState({
            bearishTimeframe: event.currentTarget.id,
            showMoreBearishStock: false
        });
        this.getTopBearishStocks(event.currentTarget.id);
    }

    getMoreBullishStock = () => {
        let showMoreBullishStock = !this.state.showMoreBullishStock;
        this.getTopBullishStocks(this.state.bullishTimeframe);
        this.setState({ showMoreBullishStock });
    }

    getMoreBearishStock = () => {
        let showMoreBearishStock = !this.state.showMoreBearishStock;
        this.getTopBearishStocks(this.state.bearishTimeframe);
        this.setState({ showMoreBearishStock });
    }

    getNotificationDetails() {
        //this.setState({ loading: true });
        const header = { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getNotificationApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_watchlist2/dip_notifications");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    upgradePlanAlert = () => {
        const { currentUser } = this.state;
        let endDate = moment(currentUser.attributes.trial_end_date).startOf('day');
        let todayDate = moment(new Date(), "YYYY-MM-DD").startOf('day');
        let days = endDate.diff(todayDate, 'days');
        if (localStorage.getItem("firstRender") === "true") {
            toast.success("User logged in successfully");
            localStorage.removeItem("firstRender");
        } 
        this.setState({ showUpgradePlanAlert: currentUser.attributes.free_trial_end_alert_status, planExpiringInDays: days });   
    }
    
    handleUpdatePlanAlertClose() {
        this.setState({ showUpgradePlanAlert: false });
    }

    handleCarousalImageClick(urlString: string) {
        if (!isEmpty(urlString)) {
            window.location.href = urlString;
        }
    }

    handleNavigate = () => {
        const navigation = new Message(getName(MessageEnum.NavigationMessage));
        navigation.addData(getName(MessageEnum.NavigationTargetMessage), "SubscribeWeb");
        navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigation);
    };
    // Customizable Area End
}

import React from "react";

import GlobalSearchWebController, {
    Props
} from "./GlobalSearchController.web";

import { withStyles } from "@material-ui/core/styles";

import { Box } from "@material-ui/core";
import Paper from '@material-ui/core/Paper';
import Loader from "../../../components/src/Loader.web";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import { closeIcon } from './assets';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';

import "chartjs-plugin-datalabels";

export class GlobalSearchWeb extends GlobalSearchWebController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { showDialog, seeMoreSection, CryptoData, NewsData, EducationDetails, searchKey, loading } = this.state;
        const { classes } = this.props;
        return (
            <Box className={classes.searchBar} >
                <div onClick={this.openDialog} >
                    <SearchIcon className={classes.icon} />
                    <InputBase
                        placeholder="Search Coins..."
                        className={classes.headerInputField}
                    />
                </div>
                {showDialog && (
                    <Paper ref={this.dialogRef} onClick={this.handleOutsideClick} className={classes.dialogbox} elevation={0} square style={{ backgroundColor: localStorage.getItem('appTheme') == 'ligthTheme' ? '#F4F8F6' : '#232429' }}>
                        <Box className={classes.containor} style={{ backgroundColor: localStorage.getItem('appTheme') == 'ligthTheme' ? '#F4F8F6' : '#232429' }} >
                            <Box style={{ width: '90%' }} >
                                <SearchIcon className={classes.icon} />
                                <InputBase
                                    placeholder="Ex. Bitcoin, indices, EFTs, etc..."
                                    className={classes.inputField}
                                    value={searchKey}
                                    onChange={this.handleSearchKeywordInputChange}
                                    style={{ width: '100%' }}
                                    autoFocus
                                />
                            </Box>
                            <Box style={{ width: '10%' }} onClick={this.closeDialog} > <img src={closeIcon} style={{ width: '30px', cursor: 'pointer', margin: '5px' }} /> </Box>
                        </Box>
                        <Box style={{ padding: '0px 20px 20px' }}>
                            <Loader loading={loading} />
                            {seeMoreSection !== "" ? (
                                <Grid>
                                    {seeMoreSection === "crypto" && <Card className={classes.dataCard}>
                                        <Typography component="div" className={classes.moreHeader} >
                                            <Button
                                                startIcon={<ArrowLeftIcon />}
                                                className={classes.headerButton}
                                                onClick={this.handleBack} >Back</Button>
                                            Crypto Assets
                                        </Typography>
                                        <Divider style={{ height: '1px' }} />
                                        <CardContent>
                                            {CryptoData.length > 0 ? [...CryptoData].map((element: any, index: number) =>
                                                <div key={index} className={classes.dataCardContent} data-testid={`cryptoDataBox-${element.id}`} onClick={() => { this.closeDialog(); window.location.href = `/CryptoDetailWeb/${element.id}`}}>
                                                    <div style={{ display: "flex", alignItems: 'center', justifyContent: 'space-between' }} >
                                                        <Avatar src={element.image_url} style={{ marginRight: '10%', height: '25px', width: '25px' }} aria-label="recipe" />
                                                        <Typography style={{ marginRight: '5%', fontWeight: 'bold', fontSize: '14px' }}>
                                                            {element.name.split(" ")[1]}
                                                        </Typography>
                                                        <Typography style={{ color: '#c6cfca', fontWeight: 'bold', fontSize: '12px' }}>
                                                            {element.name.split(" ")[0]}
                                                        </Typography>
                                                    </div>
                                                    <div style={{ display: 'flex', alignItems: 'center' }} >
                                                        <Typography style={{ color: '#c6cfca', fontWeight: 'bold', fontSize: '14px' }}>
                                                            # {element.id}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className={classes.dataCardContent} >
                                                    <div style={{ display: "flex", alignItems: 'center', justifyContent: 'space-between' }} >
                                                        <Typography style={{ fontSize: '14px' }}>
                                                            No Crypto Asset for this keyword
                                                        </Typography>
                                                    </div>
                                                </div>)
                                            }
                                        </CardContent>
                                    </Card>}
                                    {seeMoreSection === "news" && <Card className={classes.dataCard}>
                                        <Typography component="div" className={classes.moreHeader} >
                                            <Button
                                                startIcon={<ArrowLeftIcon />}
                                                className={classes.headerButton}
                                                onClick={this.handleBack} >Back</Button>
                                            Top News
                                        </Typography>
                                        <Divider style={{ height: '1px' }} />
                                        <CardContent>
                                            {NewsData.length > 0 ? [...NewsData].map((element: any, index: number) =>
                                                <div key={index} className={classes.dataCardContent} >
                                                    <div>
                                                        <Typography className={classes.title} data-testid={element.source} onClick={() => { this.closeDialog(); window.open(element.news_url)} }>
                                                            {element.title}
                                                        </Typography>
                                                        <Typography style={{ textAlign: 'end', color: '#c6cfca', fontWeight: 'bold', fontSize: '10px' }}>
                                                            {element.date}-{element.source}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className={classes.dataCardContent} >
                                                    <div style={{ display: "flex", alignItems: 'center', justifyContent: 'space-between' }} >
                                                        <Typography style={{ fontSize: '14px' }}>
                                                            No News for this keyword
                                                        </Typography>
                                                    </div>
                                                </div>
                                            )}
                                        </CardContent>
                                    </Card>}
                                    {seeMoreSection === "education" && <Card className={classes.dataCard}>
                                        <Typography component="div" className={classes.moreHeader} >
                                            <Button
                                                startIcon={<ArrowLeftIcon />}
                                                className={classes.headerButton}
                                                onClick={this.handleBack} >Back</Button>
                                            Education Details
                                        </Typography>
                                        <Divider style={{ height: '1px' }} />
                                        <CardContent>
                                            {EducationDetails.length > 0 ? [...EducationDetails].map((element: any, index: number) =>
                                                <div key={index} className={classes.dataCardContent} >
                                                    <div>
                                                        <Typography className={classes.title} data-testid={`educationDetailsBox-${element.id}`} onClick={() => { this.closeDialog(); window.location.href = "/EducationCenterIntermediateWeb?topicId=" + element.id} }>
                                                            {element.title}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className={classes.dataCardContent} >
                                                    <div style={{ display: "flex", alignItems: 'center', justifyContent: 'space-between' }} >
                                                        <Typography style={{ fontSize: '14px' }}>
                                                            No Education Detail for this keyword
                                                        </Typography>
                                                    </div>
                                                </div>
                                            )}
                                        </CardContent>
                                    </Card>}
                                </Grid>
                            ) : (
                                <>
                                    <Grid>
                                        <Card className={classes.dataCard}>
                                            <Typography component="div" style={{ fontWeight: 'bold', fontSize: '14px', margin: '8px 16px' }}>
                                                Crypto Assets
                                            </Typography>
                                            <Divider style={{ height: '1px' }} />
                                            <CardContent>
                                                {CryptoData.length > 0 ? [...CryptoData].splice(0, 5).map((item: any, index: number) =>
                                                    <div key={index} className={classes.dataCardContent} onClick={() => { this.closeDialog(); window.location.href = `/CryptoDetailWeb/${item.id}`}}>
                                                        <div style={{ display: "flex", alignItems: 'center', justifyContent: 'space-between' }} >
                                                            <Avatar src={item.image_url} style={{ marginRight: '10%', height: '25px', width: '25px' }} aria-label="recipe" />
                                                            <Typography style={{ marginRight: '5%', fontWeight: 'bold', fontSize: '14px' }}>
                                                                {item.name.split(" ")[1]}
                                                            </Typography>
                                                            <Typography style={{ color: '#c6cfca', fontWeight: 'bold', fontSize: '12px' }}>
                                                                {item.name.split(" ")[0]}
                                                            </Typography>
                                                        </div>
                                                        <div style={{ display: 'flex', alignItems: 'center' }} >
                                                            <Typography style={{ color: '#c6cfca', fontWeight: 'bold', fontSize: '14px' }}>
                                                                # {item.id}
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className={classes.dataCardContent} >
                                                        <div style={{ display: "flex", alignItems: 'center', justifyContent: 'space-between' }} >
                                                            <Typography style={{ fontSize: '14px' }}>
                                                                No Crypto Asset for this keyword
                                                            </Typography>
                                                        </div>
                                                    </div>)
                                                }
                                                <Button
                                                    className={classes.seeMoreButton}
                                                    disabled={CryptoData.length < 5}
                                                    onClick={() => this.toggleSeeMore('crypto')}>
                                                    See More
                                                </Button>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid>
                                        <Card className={classes.dataCard}>
                                            <Typography component="div" style={{ fontWeight: 'bold', fontSize: '14px', margin: '8px 16px' }}>
                                                Top News
                                            </Typography>
                                            <Divider style={{ height: '1px' }} />
                                            <CardContent>
                                                {NewsData.length > 0 ? [...NewsData].splice(0, 4).map((data: any, index: number) =>
                                                    <div key={index} className={classes.dataCardContent} >
                                                        <div>
                                                            <Typography className={classes.title} onClick={() => { this.closeDialog(); window.open(data.news_url) }}>
                                                                {data.title}
                                                            </Typography>
                                                            <Typography style={{ textAlign: 'end', color: '#c6cfca', fontWeight: 'bold', fontSize: '10px' }}>
                                                                {data.date}-{data.source}
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className={classes.dataCardContent} >
                                                        <div style={{ display: "flex", alignItems: 'center', justifyContent: 'space-between' }} >
                                                            <Typography style={{ fontSize: '14px' }}>
                                                                No News for this keyword
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                )}
                                                <Button
                                                    className={classes.seeMoreButton}
                                                    disabled={NewsData.length < 4}
                                                    onClick={() => this.toggleSeeMore('news')}>
                                                    See More
                                                </Button>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid>
                                        <Card className={classes.dataCard}>
                                            <Typography component="div" style={{ fontWeight: 'bold', fontSize: '14px', margin: '8px 16px' }}>
                                                Education Details
                                            </Typography>
                                            <Divider style={{ height: '1px' }} />
                                            <CardContent>
                                                {EducationDetails.length > 0 ? [...EducationDetails].splice(0, 4).map((element: any, index: number) =>
                                                    <div key={index} className={classes.dataCardContent} >
                                                        <div>
                                                            <Typography className={classes.title} onClick={() => { this.closeDialog(); window.location.href = "/EducationCenterIntermediateWeb?topicId=" + element.id}}>
                                                                {element.title}
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className={classes.dataCardContent} >
                                                        <div style={{ display: "flex", alignItems: 'center', justifyContent: 'space-between' }} >
                                                            <Typography style={{ fontSize: '14px' }}>
                                                                No Education Detail for this keyword
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                )}
                                                <Button
                                                    className={classes.seeMoreButton}
                                                    disabled={EducationDetails.length < 4}
                                                    onClick={() => this.toggleSeeMore('education')}>
                                                    See More
                                                </Button>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </>
                            )}
                        </Box>
                    </Paper>
                )}
            </Box>
        )
        // Customizable Area End
    }
}


// Customizable Area Start
export const globalSearchStyles = (theme: any) => ({
    searchBar: {
        // cursor: 'pointer',
        margin: '0px 10px'
    },
    dataCard: {
        borderRadius: '10px',
        '& .MuiCardContent-root:last-child': {
            paddingBottom: '0px'
        },
        margin: '0px 0px 16px'
    },
    moreHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontWeight: 'bold',
        fontSize: '14px',
        margin: '8px 16px'
    },
    headerButton: {
        background: 'rgba(1,162,78,255)',
        color: '#ffffff',
        textTransform: 'none',
        height: '30px',
        '&:hover': {
            color: '#ffffff',
            background: 'rgba(1,162,78,255)'
        }
    },
    dataCardContent: {
        display: 'flex',
        cursor: 'pointer',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        marginBottom: '15px'
    },
    dataCardRoot: {
        padding: '20px'
    },
    icon: {
        height: '18px',
        width: '18px',
        backgroundSize: '18px',
        position: 'absolute',
        backgroundRepeat: 'no-repeat',
        cursor: 'pointer',
        margin: '7px',
        zIndex: 9,
        color: 'rgba(1,162,78,255)'
    },
    headerInputField: {
        border: '1px solid #d1d1d1',
        outline: 'none',
        paddingLeft: '28px',
        borderRadius: '4px',
        boxShadow: 'inset 1px 2px 8px rgb(0 0 0 / 7%)',
        "& .MuiInputBase-input": {
            fontFamily: `"Plus Jakarta Text", sans-serif`,
            fontSize: '14px',
            cursor: 'pointer'
        }
    },
    inputField: {
        border: '1px solid #d1d1d1',
        outline: 'none',
        paddingLeft: '28px',
        borderRadius: '4px',
        boxShadow: 'inset 1px 2px 8px rgb(0 0 0 / 7%)',
        "& .MuiInputBase-input": {
            fontFamily: `"Plus Jakarta Text", sans-serif`,
            fontSize: '14px'
        }
    },
    dialogbox: {
        backgroundColor: "#fff",
        borderRadius: "15px",
        width: "380px",
        height: "500px",
        position: "absolute",
        zIndex: 101,
        top: "4%",
        right: "9%",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
        overflowX: "auto",
        '@media (min-width: 1800px)': {
            top: '3%',
            right: '6%',
            width: '500px'
        },
        '@media (max-width: 1800px) and (min-width: 1440px)': {
            top: '3%',
        },
        '@media (max-width: 780px)': {
            width: "320px"
        },
        '@media (max-width: 420px)': {
            width: "250px",
            top: '3%'
        }
    },
    containor: {
        padding: '20px 20px 0px',
        display: "flex",
        alignItems: "center",
        marginBottom: "10px",
        position: 'sticky',
        top: 0,
        zIndex: 100
    },
    title: {
        '&:hover': {
            color: '#4179bd'
        },
        fontSize: '14px'
    },
    seeMoreButton: {
        fontSize: '12px',
        float: 'right',
        color: 'rgb(1, 162, 78)',
        fontWeight: 'bold',
        '&.MuiButton-root.Mui-disabled': {
            color: 'rgb(198, 207, 202)'
        }
    }
})

//@ts-ignore
export default withStyles(globalSearchStyles)(GlobalSearchWeb);
// Customizable Area End
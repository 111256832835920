import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {
    ClassNameMap,
    ClassKeyOfStyles,
  } from '@material-ui/styles/withStyles';
import { AccountData, LoyaltyHistory, LoyaltyPoints } from "../../../components/src/types";

interface ErrorResponse {
    error: string;
  }
  
type HistoryResponse = LoyaltyHistory[] | ErrorResponse;
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes: ClassNameMap<ClassKeyOfStyles<string>>;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    token: string | null;
    loading: boolean;
    loyaltyPoints: LoyaltyHistory[];
    totalBalance: LoyaltyPoints | null;
    historyError: string;
    userProfile: AccountData | null;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class LoyaltySystemControllerWeb extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    getLoyaltyPointsApiCallId: string=""
    getTotalBalanceApiCallId: string=""
    getUserProfileApiCallId: string=""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // Customizable Area End
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            token: localStorage.getItem('token'),
            loading: false,
            loyaltyPoints: [],
            totalBalance: null,
            historyError: "",
            userProfile: null
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {

        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId === this.getLoyaltyPointsApiCallId) {
                this.setState({ loading: false });
                this.handleHistoryResponse(responseJson);
            }
            if (apiRequestCallId === this.getTotalBalanceApiCallId) {
                this.setState({ loading: false, totalBalance: responseJson });
            }
            if (apiRequestCallId === this.getUserProfileApiCallId) {
                this.setState({ loading: false, userProfile: responseJson.data });
            }
        }
        // Customizable Area End
    }
    
    async componentDidMount() {
        // Customizable Area Start
        this.getUserProfile();
        this.getTotalBalance();
        this.getLoyaltyPoints();
        // Customizable Area End
    }
    
    // Customizable Area Start
    handleHistoryResponse (responseJson: HistoryResponse) {
        if ('error' in responseJson) {
            this.setState({ historyError: responseJson.error });
        } else {
            this.setState({loyaltyPoints: responseJson });
        }
    }

    getUserProfile() {
        this.setState({ loading: true });
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getUserProfileApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "account_block/accounts/show");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    
    getTotalBalance() {
        this.setState({ loading: true });
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getTotalBalanceApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "account_block/wallets");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getLoyaltyPoints() {
        this.setState({ loading: true });
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getLoyaltyPointsApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_loyaltysystem2/loyalty_points_histories");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleBack() {
        this.handleNavigation('LandingPageWeb');
    }

    handleNavigation = (path: string) => {
        let toNavigate = new Message(getName(MessageEnum.NavigationMessage));
        toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), path);
        toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(toNavigate);
    }
    // Customizable Area End
}

Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "SavedCards";
exports.labelBodyText = "SavedCards Body";
exports.deleteApiMethod="DELETE";
exports.btnExampleTitle = "CLICK ME";
exports.showCardApiEndPoint="/bx_block_savedcards/cards";
exports.deleteCardApiEndPoint="/bx_block_savedcards/cards/";
exports.cardEditApiEndPoint="/bx_block_savedcards/cards/";
exports.PutMethos="PUT"
// Customizable Area End
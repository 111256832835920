import React from "react";
// Customizable Area Start
import EmailAccountLoginWebController, {
  Props,
  configJSON
} from "./EmailAccountLoginController.web";

import { withStyles, StyleRules } from "@material-ui/core/styles";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import Loader from "../../../components/src/Loader.web";

import "./EmailAccountLogin.web.css";

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Button from '@material-ui/core/Button';

import { cryptoLogo } from "./assets";
// Customizable Area End

const styles: StyleRules = {
  // Customizable Area Start
  root: {
    minHeight: "100vh",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: localStorage.getItem('appTheme') == 'darkTheme' ? '#232429' : 'rgba(244,248,246,255)'
  },
  emailLoginContainer: {
    margin: '15px',
    position: "relative",
    backgroundColor: localStorage.getItem('appTheme') == 'darkTheme' ?  '333742' : 'rgba(255,255,255,255)',
    width: "550px",
    padding: "70px 50px 40px",
    borderRadius: "12px",
    boxShadow: "0 0 60px 5px rgba(0, 0, 0, 0.2)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  imageContainer: {
    width: "85px",
    height: "85px",
    overflow: "hidden",
    border: "8px solid #e7e7e7",
    borderRadius: "50%",
    position: "absolute",
    top: 0,
    left: "50%",
    transform: "translate(-50%, -50%)"
  },
  tabs: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "12px",
    whiteSpace: 'nowrap'
  },
  customInputGroup: {
    position: "relative",
    width: "100%"
  },
  customInput: {
    width: "85%",
    padding: "4%",
    margin: "3% 0%",
    borderRadius: "15px",
    background: "rgba(244,248,246,255)",
    color: "#8f92a1",
    border: "none",
    outline: "none",
    paddingLeft: "38px"
  },
  customLoginPasswordInput: {
    width: "73%",
    padding: "4%",
    margin: "3% 0%",
    borderRadius: "15px",
    background: "rgba(244,248,246,255)",
    color: "#8f92a1",
    border: "none",
    outline: "none",
    paddingLeft: "38px",
    paddingRight: "38px"
  },
  error: {
    fontSize: '12px',
    fontWeight: "bold",
    color: "rgb(255, 20, 20)",
    marginLeft: "3%",
    width: '201px'
  },
  forgotText: {
    display: "flex",
    justifyContent: "end",
    cursor: "pointer",
    padding: "8px",
    fontFamily: "Plus Jakarta Text",
    color: "#a1b0a9",
    fontSize: "14px"
  },
  loginButton: {
    backgroundColor: "rgba(1,162,78,255)",
    color: "#ffffff",
    cursor: "pointer",
    padding: "15px 20px",
    border: "none",
    borderRadius: "10px",
    fontFamily: "Plus Jakarta Text",
    fontSize: "14px",
    fontWeight: "bold",
    textAlign: "center",
    width: "100%",
    display: "block",
    margin: "28px auto 8px"
  },
  cryptoStyle: {
    width: "100%",
    height: "100%",
    cursor: 'pointer'
  },
  visibilityIcon: {
    height: '18px',
    width: '18px'
  }
  // Customizable Area End
};

// Customizable Area Start
const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email address.")
    .required("Email is required."),
  password: Yup.string()
    .min(8, "Minimum Password length is 8.")
    .max(16, "Maximum Password length is 16")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~#^()<>{}@$!%*?&])[A-Za-z\d~#^()<>{}@$!%*?&]{8,}$/,
      "Password must contain atleast a capital letter, a lowercase letter, a number and a special character."
    )
    .required("Password is required.")
});
// Customizable Area End
export class EmailAccountLoginWeb extends EmailAccountLoginWebController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        {this.state.loading && <Loader loading={this.state.loading} />}
        <div className={classes.emailLoginContainer}>
          <Button
            variant="contained"
            data-testid="back-btn"
            style={{ background: 'rgba(1,162,78,255)', color: '#ffffff', textTransform: 'none', marginBottom: '10px', top: '15px', left: '15px', position: 'absolute' }}
            onClick={() => window.location.href = '/'}
          > <ArrowBackIcon />
          </Button>
          <div className={classes.imageContainer}>
            <img
              src={cryptoLogo}
              alt="profile-image"
              className={classes.cryptoStyle}
              onClick={this.onImgClick}
            />
          </div>
          <div className={classes.tabs} id="tabsContainer">
            <button
              type="button"
              data-testid="login"
              style={{ backgroundColor: localStorage.getItem('appTheme') == 'ligthTheme' ? 'rgba(255, 255, 255, 255)' : '#333742' }}
              className={
                this.state.enabledForm === "Login"
                  ? "tabButton active"
                  : "tabButton"
              }
              onClick={() => this.activeForm("Login")}
            >
              Login
            </button>
            <button
              type="button"
              data-testid="signup"
              style={{ backgroundColor: localStorage.getItem('appTheme') == 'ligthTheme' ? 'rgba(255, 255, 255, 255)' : '#333742' }}
              className={
                this.state.enabledForm === "Signup"
                  ? "tabButton active"
                  : "tabButton"
              }
              onClick={() => this.activeForm("Signup")}
            >
              Sign Up
            </button>
          </div>
          <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={loginSchema}
            onSubmit={values => {
              this.login({
                attributes: {
                  email: values.email,
                  password: values.password
                }
              });
            }}
          >
            {({ errors, values, touched, handleSubmit }) => (
              <Form
                onSubmit={handleSubmit}
              >
                <div>
                  <div className={classes.customInputGroup}>
                    <span className={"left-icon email-icon"} />
                    <Field
                      name="email"
                      type="text"
                      placeholder="Enter your email"
                      className={classes.customInput}
                      data-testid="txtInputEmail"
                    />
                    {/* Applies the proper error message from validateSchema when the user has
								clicked the element and there is an error, also applies the .error-message
								CSS class for styling */}
                    {touched.email && errors.email ? (
                      <div className={classes.error}>{errors.email}</div>
                    ) : null}
                  </div>
                </div>
                <div>
                  <div className={classes.customInputGroup}>
                    <span className={"left-icon password-icon"} />
                    <Field
                      name="password"
                      type={this.state.showPass ? "text" : "password"}
                      id="password"
                      placeholder="Enter your password"
                      className={classes.customLoginPasswordInput}
                      data-testid="txtInputPassword"
                    />
                    <span
                      className="right-icon"
                      onClick={() => this.togglePassword()}
                      data-testid="togglePassword">
                        {this.state.showPass ? <VisibilityOffIcon className={classes.visibilityIcon} /> : <VisibilityIcon className={classes.visibilityIcon} /> }
                      </span>
                  </div>
                  {/* Applies the proper error message from validateSchema when the user has
                          clicked the element and there is an error, also applies the .error-message
                          CSS class for styling */}
                  {values.password || touched.password && errors.password ? (
                    <div className={classes.error}>{errors.password}</div>
                  ) : null}
                </div>
                <span
                  className={classes.forgotText}
                  data-testid="forgotPassword"
                  onClick={() =>
                    this.navigationLinkPressed("ForgotPasswordWeb")
                  }
                >
                  {configJSON.forgotPasswordText}
                </span>
                <button
                  type="submit"
                  className={classes.loginButton}
                  data-testid="btnEmailLogIn"
                >
                  Login
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
    // Customizable Area End
  }
}

export default withStyles(styles)(EmailAccountLoginWeb);

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from 'react-toastify';
// Customizable Area Start
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    registerables
} from 'chart.js';
import { Line } from 'react-chartjs-2';
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: boolean;
    token: any;
    interval: any;
    avgSum: any;
    assestsList: any;
    selectedAssetListObj: any; 
    anchorEl: any;
    selectedAsset: any;
    triggerPercentList: any;
    selectedDip: any;
    selectedDipAsset: any;
    lineChartData: any;
    lineOptions: any;
    dipAlert: boolean;
    addNewAsset: boolean;
    addAssetsList: any;
    symbolType: any;
    buttonGroupOne: any;
    buttonGroupTwo: any;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class PortfolioWebController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    getGraphsApiCallId: string="";
    getAssetApiCallId: string="";
    getPortfolioCryptosApiCallId: string="";
    addOrUpdateAssetProfileApiCallId: string="";
    getDipApiCallId: string="";
    addOrUpdateDipApiCallId: string="";
    deleteAssetApiCallId: string="";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            loading: false,
            token: localStorage.getItem('token') || "",
            interval: '24h',
            avgSum: 0,
            assestsList: [],
            selectedAssetListObj: null,
            anchorEl: null,
            selectedAsset: null,
            triggerPercentList: [5, 0],
            selectedDip: null,
            selectedDipAsset: null,
            lineChartData: {
                labels: [],
                datasets: [
                    {
                        label: '',
                        data: [],
                        volumn: [],
                        borderColor: 'rgba(1,162,78,255)',
                        backgroundColor: 'rgba(1,162,78,255)',
                        fill: false,
                        datalabels: {
                            display: false
                        }
                    }
                ]
            },
            lineOptions: {
                responsive: true,
                plugins: {
                    title: {
                        display: false,
                        text: 'Chart.js Line Chart',
                    },
                    legend: {
                        display: false,
                        position: 'top' as const,
                    },
                    tooltip: {
                        displayColors: false,
                        callbacks: {
                            title: function (tooltipItem: any, data: any) {
                                return "";
                            },
                            label: function (context: any) {
                                var label = context.dataset.label || '';
                                if (label) {
                                    label += ': ';
                                }
                                if (context.parsed.y !== null) {
                                    label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
                                }
                                return context.dataset.volumn[context.dataIndex];
                            }
                        }
                    }
                },
                scales: {
                    x: {
                        grid: {
                            display: false
                        },
                        ticks: {
                            callback: function (value: any, index: any, values: any) {
                                return '';
                            }
                        }
                    },
                    y: {
                        //beginAtZero: true,
                        grid: {
                            display: false
                        },
                        ticks: {
                            //stepSize: 10000,
                            // Include a dollar sign in the ticks
                            callback: function (value: any, index: any, values: any) {
                                return '$' + value + '.00';
                            }
                        }
                    }
                }
            },
            dipAlert: false,
            addNewAsset: false,
            addAssetsList: [],
            symbolType: '',
            buttonGroupOne: 'chart',
            buttonGroupTwo: '24h'
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {

        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestCallId === this.getAssetApiCallId) {
                if (responseJson?.data) {
                    let sum: number = 0;
                    for (var i = 0; i < responseJson?.data.length; i++) {
                        sum += Number(responseJson?.data[i]?.attributes?.holding_qty * responseJson?.data[i]?.attributes?.buy_price.replace(/,/g, ''));
                    }
                    this.setState({
                        assestsList: responseJson?.data,
                        selectedAssetListObj: responseJson?.data[0],
                        avgSum: sum
                    }, () => {
                        this.getGraphData();
                    })
                } else {
                    this.setState({ loading: false });
                    if (responseJson?.errors) {
                        if (responseJson?.errors[0].token) {
                            toast.error(responseJson?.errors[0].token);
                        } else if (responseJson?.errors[0]) {
                            toast.error(responseJson?.errors[0]);
                        }
                    }
                }
            } else if (apiRequestCallId === this.getGraphsApiCallId) {
                if (responseJson?.data) {
                    let labels = [];
                    let data = [];
                    let volumn = [];
                    for (var i = 0; i < responseJson.data.length; i++) {
                        data.push(parseFloat(responseJson.data[i].price.replace(/,/g, '').substring(1, responseJson.data[i].price.length)));
                        labels.push(parseFloat(responseJson.data[i].timestamp));
                        volumn.push(responseJson.data[i].volume_24h);
                        // volumn.push(parseFloat(responseJson.data[i].volume_24h.replace(/,/g, '').substring(1, responseJson.data[i].volume_24h.length)));
                    }
                    var lineData = { ...this.state.lineChartData }
                    lineData.labels = labels;
                    for (var i = 0; i < lineData.datasets.length; i++) {
                        lineData.datasets[i].data = data;
                        lineData.datasets[i].volumn = volumn;
                    }
                    this.setState({ lineChartData: lineData })
                } else {
                    if (responseJson?.errors) {
                        if (responseJson?.errors[0].token) {
                            //toast.error(responseJson?.errors[0].token);
                        }
                    }
                }
                this.setState({ loading: false });
            } else if (apiRequestCallId === this.getPortfolioCryptosApiCallId) {
                if (responseJson?.data) {
                    this.setState({
                        addAssetsList: responseJson?.data
                    })
                } else {
                    if (responseJson?.errors) {
                        if (responseJson?.errors[0].token) {
                            //toast.error(responseJson?.errors[0].token);
                        }
                    }
                }
            } else if (apiRequestCallId === this.addOrUpdateAssetProfileApiCallId) {
                this.setState({ loading: false, selectedAsset: null });
                if (responseJson?.data) {
                    this.getAssetDetails();
                    toast.success("Asset details saved successfully");
                } else {
                    if (responseJson.errors) {
                        if (responseJson.errors[0]?.card) {
                            toast.error(responseJson?.errors[0].card[0]);
                        }
                        if (responseJson.errors[0]?.asset) {
                            toast.error(responseJson.errors[0].asset);
                        }
                    } else {
                        toast.error("Failed to save asset details");
                    }
                }
            } else if (apiRequestCallId === this.deleteAssetApiCallId) {
                this.setState({ loading: false, anchorEl: null });
                if (responseJson?.message) {
                    this.getAssetDetails();
                    toast.success(responseJson?.message);
                } else {
                    if (responseJson?.errors) {
                        toast.error(responseJson?.errors[0]);
                    } else {
                        toast.error("Failed to delete asset details");
                    }
                }
            } if (apiRequestCallId === this.getDipApiCallId) {
                if (responseJson?.data) {
                    if (responseJson.data.length > 0) {
                        // this.setState({
                        //     selectedDip: responseJson?.data[0]
                        // });
                    }
                } else {
                    if (responseJson?.errors) {
                        if (responseJson?.errors[0].token) {
                            //toast.error(responseJson?.errors[0].token);
                        }
                    }
                }
            } else if (apiRequestCallId === this.addOrUpdateDipApiCallId) {
                this.setState({ loading: false, dipAlert: false });
                if (responseJson?.data) {
                    this.getAssetDetails();
                    toast.success("Dip Alert details saved successfully");
                } else {
                    toast.error("Failed to save dip alert details");
                }
            }
        }
        // Customizable Area End
    }
    // Customizable Area Start
    async componentDidMount() {
        ChartJS.register(
            CategoryScale,
            LinearScale,
            PointElement,
            LineElement,
            Title,
            Tooltip,
            Legend
        );
        this.getDipDetails();
        this.getAssetDetails();
        this.getPortfolioCryptosDetails();
    }

    getDipDetails = () => {
        this.setState({ loading: true });
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getDipApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_watchlist2/dip_alerts");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getAssetDetails = () => {
        this.setState({ loading: true });
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getAssetApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_watchlist2/assets");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    deleteAssetDetails() {
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.deleteAssetApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_watchlist2/assets/" + this.state.selectedAsset.id);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "DELETE");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    closeDipAlert = () => {
        this.setState({
            dipAlert: false
        })
    }

    addOrUpdateAsset = (values: any) => {
        this.setState({ loading: true, addNewAsset: false });
        const header = { "Content-Type": "application/json", "token": this.state.token };
        const httpBody = {
            data: {
                "crypto_id": values.crypto_id.toString(),
                "buy_price": values.buy_price.toString(),
                "holding_qty": values.holding_qty.toString(),
                dip_alert_id: "",
                dip_alert: ""
            }
        };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.addOrUpdateAssetProfileApiCallId = requestMessage.messageId;
        if (this.state.selectedAsset == null) {
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_watchlist2/assets");
        } else {
            if (values.custom_dip_number != 0) {
                httpBody.data.dip_alert_id = "";
                httpBody.data.dip_alert = values.custom_dip_number;
            } else {
                let dip_obj = this.state.selectedAsset?.attributes?.dip_alert_list?.data.find((item: any) => item?.attributes?.id == values.dip_alert);
                httpBody.data.dip_alert_id = dip_obj?.attributes?.id;
                httpBody.data.dip_alert = "";
            }
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_watchlist2/assets/" + this.state.selectedAsset.id);
        }
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    closeNewAsset = () => {
        this.setState({
            addNewAsset: false,
            selectedAsset: null
        });
    }

    addOrUpdateDip = (values: any) => {
        //this.setState({ loading: true });
        const header = { "Content-Type": "application/json", "token": this.state.token };
        const httpBody = {
            data: {
                dip_number: values.dip_number == 0 ? values.custom_dip_number : values.dip_number,
                crypto_id: values.crypto_id,
                // buy_price: this.state.selectedDipAsset.attributes.buy_price
            }
        };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.addOrUpdateDipApiCallId = requestMessage.messageId;
        if (this.state.selectedDip == null) {
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_watchlist2/dip_alerts");
        } else {
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_watchlist2/dip_alerts/" + this.state.selectedDip.id);
        }
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getPortfolioCryptosDetails() {
        this.setState({ loading: true });
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getPortfolioCryptosApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_dashboard/portfolio_cryptos");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleIconClick = (event: any, row: any) => {
        this.setState({
            anchorEl: event.currentTarget,
            selectedAsset: row
        });
    };

    handleIconClose = () => {
        this.setState({
            anchorEl: null,
            selectedAsset: null
        });
    };

    buttonGroupCategory = (flag: any, filterVal: any) => {
        if (flag == 'groupOne') {
            this.setState({
                buttonGroupOne: filterVal
            }, () => {
            });
        } else if (flag == 'groupTwo') {
            this.setState({
                buttonGroupTwo: filterVal
            }, () => {
                this.graphButtonFilter(filterVal);
            });
        }
    }

    graphButtonFilter = (filterVal: any) => {
        this.setState({
            interval: filterVal
        }, () => {
            this.getGraphData();
        });
    }

    getGraphData = () => {
        this.setState({ loading: true });
        const header = { "Content-Type": "application/json", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getGraphsApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_dashboard/graph_data?crypto_id=${this.state.selectedAssetListObj?.attributes?.crypto_id}&interval=${this.state.interval}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Customizable Area End
}

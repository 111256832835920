import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import { StyleRules, withStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import MobileStepper from '@material-ui/core/MobileStepper';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

interface imageProps {
  web_image: string,
  redirect_link: string,
  mobile_image: string,
  redirect_link_mobile: string
  created_at: string,
  updated_at: string
}
interface AttributesProps {
  id: string,
  type: string,
  attributes: imageProps
}

interface CarouselProps {
  images: Array<AttributesProps>;
  classes: any;
}

const styles: StyleRules = {
    stepper: {
        justifyContent: 'center'
    },
    leftArrow: {
        position: 'absolute',
        top: '50%', 
        right: 0, 
        minWidth: '35px',
        height:'35px',
        borderRadius: '50%',
        color: '#6b6b6b',
        '&:hover': {
            backgroundColor: '#a6a6a6',
        }
    },
    rightArrow: {
        position: 'absolute',
        top: '50%', 
        left: 0, 
        minWidth: '35px',
        height: '35px',
        borderRadius: '50%',
        color: '#6b6b6b',
        '&:hover': {
            backgroundColor: '#a6a6a6',
        }
    }
}

const Carousel = ({ classes, images }: CarouselProps) => {
    
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

 useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Auto-play every 3 seconds

    return () => clearInterval(intervalId);
  }, [images.length]);

  const handleClick = () => {
    if (images[currentIndex]?.attributes?.redirect_link)
        window.open(images[currentIndex]?.attributes?.redirect_link, '_blank')
  }

  return (
    <Grid container justify="center" alignItems="center">
      <Grid item xs={12}>
        <Paper elevation={0} style={{ textAlign: 'center', position: 'relative', cursor: 'pointer' }}>
          <img data-testid="carousalImage" src={images[currentIndex]?.attributes?.web_image} alt={`Slide ${currentIndex}`} 
          onClick={handleClick} style={{ width: '100%', borderRadius: '10px' }} />         
            <MobileStepper
                variant="dots"
                steps={images.length}
                className={classes.stepper}
                position="static"
                activeStep={currentIndex}
                nextButton={
                    <Button size="small" onClick={handleNext} className={classes.leftArrow}>
                    <KeyboardArrowRight />
                    </Button>
                }
                backButton={
                    <Button size="small" onClick={handlePrevious} className={classes.rightArrow}>
                    <KeyboardArrowLeft />
                    </Button>
                }
            />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(Carousel);
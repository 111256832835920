import React from "react";
// Customizable Area Start
import ForgotPasswordWebController, {
    Props
} from "./ForgotPasswordController.web";

import {
    withStyles, StyleRules
} from "@material-ui/core/styles";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import Loader from "../../../components/src/Loader.web";

import "./ForgotPassword.web.css";

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';

import { cryptoLogo } from "./assets";
// Customizable Area End

const styles: StyleRules = {
    // Customizable Area Start
    root: {
        minHeight: '100vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: localStorage.getItem('appTheme') == 'ligthTheme' ? 'rgba(244,248,246,255)' : '#232429',
        fontFamily: 'Plus Jakarta Text'
    },
    forgotPasswordContainer: {
        position: 'relative',
        backgroundColor: localStorage.getItem('appTheme') == 'ligthTheme' ? 'rgba(255,255,255,255)' : '#333742',
        width: '550px',
        margin: '0px 10px',
        padding: '70px 50px 40px',
        borderRadius: '12px',
        boxShadow: '0 0 60px 5px rgba(0, 0, 0, 0.2)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    imageContainer: {
        width: '85px',
        height: '85px',
        overflow: 'hidden',
        border: '8px solid #e7e7e7',
        borderRadius: '50%',
        position: 'absolute',
        top: 0,
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '10px'
    },
    subtitle: {
        color: '#a1b0a9',
        fontWeight: 'bold'
    },
    customInputGroup: {
        position: 'relative',
        width: '100%'
    },
    customInput: {
        width: '85%',
        padding: '4%',
        margin: '2% 0%',
        borderRadius: '15px',
        background: 'rgba(244,248,246,255)',
        color: '#8f92a1',
        border: 'none',
        outline: 'none',
        paddingLeft: '38px'
    },
    error: {
        fontSize: '0.9vw',
        fontWeight: 'bold',
        color: 'rgb(255, 20, 20)',
        width: '201px',
        marginLeft: '3%'
    },
    sendLinkButton: {
        backgroundColor: 'rgba(1,162,78,255)',
        color: '#ffffff',
        cursor: 'pointer',
        padding: '8px 20px',
        border: 'none',
        borderRadius: '10px',
        fontFamily: 'Plus Jakarta Text',
        fontSize: '14px',
        fontWeight: 'bold',
        textAlign: 'center',
        width: '100%',
        display: 'block',
        margin: '2% auto'
    }
    // Customizable Area End
}

// Customizable Area Start
const forgotSchema = Yup.object().shape({
    email: Yup.string()
        .email('Please enter a valid email address.')
        .required('Email is required.'),
});
// Customizable Area End
export class ForgotPasswordWeb extends ForgotPasswordWebController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                {this.state.loading && <Loader loading={this.state.loading} />}
                <div className={classes.forgotPasswordContainer}>
                    <Button
                        variant="contained"
                        data-testid="back-btn-to-login"
                        style={{ background: 'rgba(1,162,78,255)', color: '#ffffff', textTransform: 'none', marginBottom: '10px', top: '15px', left: '15px', position: 'absolute' }}
                        onClick={() => window.history.back()}
                    > <ArrowBackIcon />
                    </Button>
                    <div className={classes.imageContainer}>
                        <img src={cryptoLogo} alt="profile-image" style={{ width: '100%', height: '100%' }} />
                    </div>
                    <div className={classes.body}>
                        <span style={{ fontSize: '22px', fontWeight: 'bold', padding: '10px', whiteSpace: 'nowrap' }}>Forgot Password</span>
                        {
                        this.state.forgotPasswordConfirmForm === false ?
                        <>
                        <span className={classes.subtitle}>Please Enter your Email, to receive</span>
                        <span className={classes.subtitle}> the temporary password</span></>:
                        <><span className={classes.subtitle}>Please check your Email inbox, we have sent</span>
                        <span className={classes.subtitle}>you the temporary password <b style={{ color: '#2a2e2c' }}>{this.state.email != '' && this.state.token != '' && `at ` + this.state.email}</b></span></>}
                    </div>
                    {
                        this.state.forgotPasswordConfirmForm === false ?
                            (
                                <Formik
                                    initialValues={{ email: '' }}
                                    validationSchema={forgotSchema}
                                    onSubmit={(values) => {
                                        this.forgotButtonClick(values);
                                    }}
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        isSubmitting,
                                        setFieldValue,
                                    }) => (
                                        <Form onSubmit={handleSubmit} >
                                            <div>
                                                <div className={classes.customInputGroup}>
                                                    <span
                                                        className={'left-icon email-icon'}
                                                    />
                                                    <Field
                                                        name="email"
                                                        type="text"
                                                        placeholder='Enter your email'
                                                        className={classes.customInput}
                                                    />
                                                    {/* Applies the proper error message from validateSchema when the user has
								clicked the element and there is an error, also applies the .error-message
								CSS class for styling */}
                                                    {values.email || touched.email && errors.email ? (<div className={classes.error}>{errors.email}</div>) : null}

                                                </div>
                                            </div>
                                            <button data-testid="send" type="submit" className={classes.sendLinkButton}>Send Link</button>
                                        </Form>
                                    )}
                                </Formik>
                            )
                            :
                            (
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                    <span style={{ color: '#2a2e2c', fontSize: '18px', fontWeight: 'bold', padding: '10px' }}>THANK YOU!</span>
                                </div>
                            )
                    }
                    {
                        this.state.forgotPasswordConfirmForm == true && <button type="button" className={classes.sendLinkButton} onClick={(event: any) => this.navigationLinkPressed("ResetPasswordWeb")}>Go to Reset Password</button>
                    }
                </div>
            </div>
        )
        // Customizable Area End
    }
}

export default withStyles(styles)(ForgotPasswordWeb);

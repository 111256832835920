import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { toast } from 'react-toastify';
import { CountryCodesData, SignupFormValues, SignupResponse } from "../../../components/src/types";
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: boolean;
    enabledForm: string;
    showPass: boolean;
    countryCodes: Array<CountryCodesData>;
    referred: boolean,
    referredById: number | string;
    referralId: number | string;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class EmailAccountRegistrationWebController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    emailAccountRegistrationApiCallId: string = "o";
    countryCodeApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            enabledForm: 'Signup',
            showPass: false,
            loading: false,
            countryCodes: [],
            referred: false,
            referredById: "",
            referralId: ""
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {

        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            runEngine.debugLog("API Message Recived", message);

            if (apiRequestCallId === this.emailAccountRegistrationApiCallId) {
                this.setState({ loading: false });
                this.handleAccountRegistrationResponse(responseJson);
            }
            if (apiRequestCallId === this.countryCodeApiCallId) {
                this.setState({ countryCodes: responseJson.data });
            }
        }
        // Customizable Area End
    }
    async componentDidMount() {
        // Customizable Area Start
        this.getCountriesCodes();
        const queryParams = new URLSearchParams(window.location.search);
        const referred = queryParams.get('referred');
        const referredById = queryParams.get('referred_by_id');
        const referralId = queryParams.get('referral_id');
        if (referred && referredById && referralId) {
            this.setState({ referred: Boolean(referred), referredById, referralId });
        }
        // Customizable Area End
    }
    // Customizable Area Start
    handleAccountRegistrationResponse(responseJson: SignupResponse) {
        if (responseJson.data) {
            toast.success("User registered successfully");
            this.navigationLinkPressed("EmailAccountLoginWeb");
        } else {
            if (responseJson.errors) {
                if (responseJson.errors[0].account) {
                    toast.error(responseJson.errors[0].account);
                } if (responseJson.errors[0].account[0] && responseJson.errors[0].account[0].full_phone_number) {
                    toast.error(responseJson?.errors[0].account[0].full_phone_number);
                }
            } else {
                toast.error("User registration failed");
            }
        }
    }

    activeForm(flag: string) {
        if (flag.toUpperCase() == 'Login'.toUpperCase()) {
            this.setState({
                enabledForm: 'Login'
            }, () => {
                this.navigationLinkPressed("EmailAccountLoginWeb");
            });
        }
    }

    navigationLinkPressed = (path: string) => {
        let toPath = new Message(getName(MessageEnum.NavigationMessage));
        toPath.addData(getName(MessageEnum.NavigationTargetMessage), path);
        toPath.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(toPath);
    }

    onImageClick = () => {
        this.navigationLinkPressed('LandingPageWeb');
    }

    togglePassword = () => {
        this.setState((previousState) => ({
            showPass: !previousState.showPass
        }));
    };

    signUp(values: SignupFormValues) {
        this.setState({ loading: true });
        const { referred, referredById, referralId } = this.state;
        const header = { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*" };
        const signupData = {
            attributes: {
                "fullname": values.fullname,
                "country_code_value": values.country_code,
                "email": values.email,
                "full_phone_number": values.country_code + values.full_phone_number,
                "password": values.password,
                "referred": referred,
                "referred_by_id": referredById
            }
        };
        const httpBody = { data: signupData };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.emailAccountRegistrationApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "account_block/accounts?referral_id="+referralId);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getCountriesCodes = () => {
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.countryCodeApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),"account/accounts/country_code_and_flag");
        const header = { "Content-Type": "application/json" };
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),"GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
      };
    // Customizable Area End
}

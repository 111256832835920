import React from 'react'
// Customizable Area Start
import {View} from 'react-native'


interface S {
    close: boolean;
    adLoaded?:boolean
}
// Customizable Area End

export default class AdsBanner extends React.Component<any,S> {
    // Customizable Area Start
    constructor(props: any) {
        super(props);
        this.state = {
          close: false,
          adLoaded:false,
      }
    
    }
   
    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
                <View style={{ 
              }}>
                </View>
            
            // Customizable Area End
        )
    }
}
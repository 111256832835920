import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { isEmpty } from "../../../framework/src/Utilities";
import { currentPlan, newsPageAccessibility } from "../../../components/src/chartUtils.web";
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: boolean;
    token: any;
    trendingTopics: any;
    selectedTrendingTopic: any;
    selectedTrendingTopicName: string;
    newsData: any;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class NewsPortalWebController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    getNewsApiCallId: string="";
    getTrendingNewsApiCallId: string="";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            loading: false,
            token: localStorage.getItem('token') || "",
            trendingTopics: [],
            selectedTrendingTopic: "",
            selectedTrendingTopicName: "",
            newsData: [],
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {

        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestCallId === this.getNewsApiCallId) {
                this.setState({ loading: false });
                if (responseJson?.data) {
                    const topics = responseJson?.topics.filter((topic: any) => !["Diem", "Digital+Ruble", "Libra", "Upgrade"].includes(topic[1]))
                    this.setState({
                        trendingTopics: topics,
                        newsData: responseJson?.data
                    })
                } 
            } else if (apiRequestCallId === this.getTrendingNewsApiCallId) {
                this.setState({ loading: false });
                if (responseJson?.data) {
                    this.setState({
                        newsData: responseJson?.data
                    })
                }
                if (responseJson?.errors && responseJson.errors[0].news_data) {
                    this.setState({ newsData: [] })
                }
            }
        }
        // Customizable Area End
    }
    
    async componentDidMount() {
        // Customizable Area Start
        let currentActivePlan = currentPlan();
        if (!isEmpty(this.state.token) && currentActivePlan && !newsPageAccessibility('premium_newsletter', currentActivePlan.premium_newsletter) ) {
            this.getNewsAPIDetails();
        }
        // Customizable Area End
    }
        // Customizable Area Start
    async componentWillUnmount() {
    }

    getNewsAPIDetails() {
        this.setState({ loading: true });
        const header = { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getNewsApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_dashboard/news?per_page=20&page=1");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    trendingTopicSelected = (filteredData: any) => {
        this.setState({ loading: true, selectedTrendingTopicName: filteredData[0], selectedTrendingTopic: filteredData[1] });
        const header = { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*", token: this.state.token };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getTrendingNewsApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_dashboard/news_by_topic?topic=" + filteredData[1]);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    removeTrendingTopic = () => {
        this.setState({ selectedTrendingTopicName: '', selectedTrendingTopic: '' });
        this.getNewsAPIDetails();
    }
    // Customizable Area End
}

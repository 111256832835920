import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Alert, AsyncStorage } from 'react-native'
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { CommonContext } from "../../../framework/src/CommonContextProvider";
import { theme } from "../../../framework/src/theme";

const _ = require("lodash")
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // route: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  cardList: any;
  switchValue: boolean;
  isVisible: any;
  primarArray: any;
  isSecondary:boolean;
  isdeleteCard:boolean;
  deleteId:any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SavedCardsController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  apiShowCardListId: string = "";
  apiDeleteId: string = "";
  apiEditCardId: string = "";
  _unsubscribe: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      cardList: [],
      switchValue: false,
      isVisible: 0,
      primarArray: null,
      isSecondary:true,
      isdeleteCard:false,
      deleteId:null
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apiShowCardListId) {
          if (responseJson.data) {
            console.log("responseJson>>>143", responseJson)
           

            // this.setState({
            //   cardList: responseJson.data
            // })
            if (responseJson.data.length === 0) {
              this.setState({isSecondary:true})

              this.setState({ primarArray: null,cardList:responseJson.data })
            }
            var temp;
            var newArray = [];
            for (var i in responseJson.data) {
              console.log("responseJson.data[i]>>", responseJson.data[i].attributes.is_primary)

              if (responseJson.data[i] && responseJson.data[i].attributes) {
                console.log("responseJson.data[i]>>", responseJson.data[i].attributes)

                if (responseJson.data[i].attributes.is_primary === true) {
                  this.setState({isSecondary:false})
                  temp = responseJson.data[i]
                  console.log("temp>>", temp)
                  this.setState({ primarArray: temp }, () => {
                    console.log("this.state.prima>>", this.state.primarArray)
                  })
                } else {
                  newArray.push(responseJson.data[i])
                }
              }
              this.setState({ cardList: newArray }, () => {
                console.log("cardListData>>", this.state.cardList)
                console.log("this.state.prima>>", this.state.primarArray)
              })
            }

          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);

          }


        }
        else if (apiRequestCallId === this.apiDeleteId) {
          if (!responseJson.errors) {
            this.setState({isdeleteCard:false})
            this.displayCardList()

          } else {
            this.parseApiErrorResponse(responseJson);

          }
        }
        else if (apiRequestCallId === this.apiEditCardId) {
          if (!responseJson.errors) {
            this.displayCardList()
          } else {
            this.parseApiErrorResponse(responseJson);

          }
        }
        // this.parseApiCatchErrorResponse(errorReponse);
      }else{
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End

  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // Customizable Area Start
  toggleSwitch = async (item: any) => {
    const token = await AsyncStorage.getItem("userToken")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };

    const attrs = {
      "name": item.attributes.name,
      "card_number": item.attributes.card_number,
      "month": item.attributes.month,
      "year": item.attributes.year,
      "cvv": item.attributes.cvv,
      "is_primary": true
    };
    const httpBody = {
      data: attrs

    };
    console.log("httpBody>>", httpBody)
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    console.log("requestMessage>>", requestMessage)
    this.apiEditCardId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.cardEditApiEndPoint + item.id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.PutMethos
      // configJSON.loginAPiMethod
    );
    console.log("requestMessage>>", requestMessage)
    runEngine.sendMessage(requestMessage.id, requestMessage);

  };
  formatMonth = (month:any)=>{
    let formattedMonth;
    if (month > 0 && month < 10) {
       formattedMonth = `0${month}`;
    } else {
       formattedMonth = month?.toString();
    }
    return formattedMonth ? formattedMonth : '';
  }
  displayCardList = async () => {
    const token = await AsyncStorage.getItem("userToken")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };

    // console.log("httpBody>>", httpBody)
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    console.log("requestMessage>>", requestMessage)
    this.apiShowCardListId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.showCardApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType
      // configJSON.loginAPiMethod
    );
    console.log("requestMessage>>", requestMessage)
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  deleteAlert() {
 
    this.deleteCard(this.state.deleteId)
    // Alert.alert(
    //   "Card",
    //   "Are you sure you want to delete card?",
    //   [
    //     {
    //       text: "Cancel",
    //       onPress: () =>
    //         console.log('cancel')
    //     },
    //     {
    //       text: "OK",
    //       onPress: () => this.deleteCard(id)
    //     }
    //   ],
    //   { cancelable: false }
    // );
  }
  deleteCard = async (id: any) => {
    const token = await AsyncStorage.getItem("userToken")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    console.log("requestMessage>>", requestMessage)
    this.apiDeleteId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteCardApiEndPoint + id
    );
    console.log("configJSON.deleteCardApiEndPoint+id>>", configJSON.deleteCardApiEndPoint + id)
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.deleteApiMethod
      // configJSON.loginAPiMethod
    );
    console.log("requestMessage>>", requestMessage)
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  static contextType=CommonContext
  textColor=()=>{
    if(this.context.mode === "light"){
          return theme.light.textColor
        }
        return theme.dark.textColor
    }

  getColorTheme = (mode:any, color1:any, color2:any) => {
    return this.context.mode === mode ? color1 : color2
  }

  // Customizable Area End
  
}

import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import { withStyles, WithStyles, StyleRules } from "@material-ui/core/styles";

interface MyComponentProps extends WithStyles<typeof styles> {
    message: string;
    buttonText: string;
    buttonURL?: string | undefined;
    handleCancel?: any;
}

const styles: StyleRules = {
    signUpButton: {
        backgroundColor: 'rgba(1,162,78,255)',
        color: '#ffffff',
        cursor: 'pointer',
        padding: '8px 20px',
        margin: '0px 10px',
        whiteSpace: 'nowrap',
        border: 'none',
        borderRadius: '10px',
        textAlign: 'center',
    },
    loginButton: {
        backgroundColor: 'rgba(1,162,78,255)',
        color: '#ffffff',
        cursor: 'pointer',
        padding: '8px 20px',
        margin: '0px 10px',
        border: 'none',
        borderRadius: '10px',
        textAlign: 'center'    },
}

const NoSubscriptionDialogWeb:React.FC<MyComponentProps>  = ({ classes, message, buttonText, 
    buttonURL, handleCancel }) => {
    return (
        <Dialog style={{ minHeight: "185px" }} data-testid="close-dialog" open={true} aria-labelledby="delete-confirmation-dialog">
            <DialogTitle style={{ textAlign: 'center', color: 'red', fontWeight: 'bold' }}>
                Subscription Alert
            </DialogTitle>
            <DialogContent style={{ textAlign: 'center', maxWidth:300, paddingTop: 0 }}>
                <Typography>{message}</Typography>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <button className={classes.signUpButton} style={{ background: localStorage.getItem('appTheme') == 'darkTheme' ? '#363333' : '#181a19' }} onClick={() => { buttonURL ? (window.location.href = buttonURL) : handleCancel(); }}>{buttonText}</button>
                <button className={classes.loginButton} onClick={() => {
                        window.location.href = '/SubscribeWeb';
                }}>Subscribe To Pro</button>
            </DialogActions>
        </Dialog>
    )
}

export default  withStyles(styles)(NoSubscriptionDialogWeb);